import { Injectable } from '@angular/core';

import { environment } from './../../../../environments/environment';

@Injectable()
export class ImagesService {
  apiEndpoint = environment.apiEndpoint;
  attachFilesErrMsg: string;
  allowedImageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
  maxPreviewImageSize = 30 * 1024 * 1024;

  getFileExtension(fileName) {
    return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
  }

  isAllowedImageExtensions(file: File) {
    const fileExtension = this.getFileExtension(file.name);
    return this.allowedImageExtensions.includes(fileExtension);
  }

  isAllowedImageSizes(file: File, maxSize) {
    return file.size < maxSize;
  }

  isValidImageFile(file: File, maxSize = this.maxPreviewImageSize) {
    return this.isAllowedImageExtensions(file) && this.isAllowedImageSizes(file, maxSize);
  }
}
