<div class="spen-ly-stu-menu" data-cy="student-sidebar" [ngClass]="{ 'is-show': slideMenuService.isShowSidebar }">
  <ul class="slide-menu">
    <li (click)="hideSidebar()">
      <a class="closebtn">
        <i class="fa fa-times" aria-hidden="true"></i>
        <span class="spen-util-link">閉じる</span>
      </a>
    </li>
    <li><a routerLink="/my-space" (click)="hideSidebar()" routerLinkActive="active">マイスペース</a></li>
    <li><a routerLink="/spaces/student" (click)="hideSidebar()" routerLinkActive="active">スペース</a></li>
    <li><a routerLink="/stories/student" (click)="hideSidebar()" routerLinkActive="active">ストーリー</a></li>
    <li *ngIf="isDeliveredGraduationMessage">
      <a routerLink="/graduation-messages/student" (click)="hideSidebar()" routerLinkActive="active">ポートフォリオ卒業</a>
    </li>
    <li (click)="sendGAForResultActivity()">
      <a (click)="redirectToRegisterResultSystem()" target="_blank">成果の記録</a>
    </li>
  </ul>
  <div class="shadow-screen" (click)="hideSidebar()" *ngIf="slideMenuService.isShowSidebar"></div>
</div>
