<div class="stu-my-space">
  <div class="list-header">
    <button (click)="sendNewDocumentGAEvent()" class="spen-mod-btn is-primary" routerLink="/documents/new" data-cy="transit-form">
      活動記録の新規作成
    </button>
  </div>

  <div class="comment-count" *ngIf="unread_assessments_count > 0">
    <a data-cy="unread-assessments" (click)="getUnreadAssessments()">{{ unread_assessments_count }}件の活動記録にコメントがありました。</a>
  </div>

  <div class="filtered-category" *ngIf="filterService.filtered; else notFiltered">
    <div class="change-filter">
      <a class="spen-util-link" data-cy="change-filter" (click)="filterService.showFilter()"><i class="fa fa-search"></i>検索条件を変更</a>
    </div>
    <div class="category-detail">
      <span class="category-name" data-cy="text-filter">検索条件：{{ filterService.messageFilter }}</span>
      <a class="remove-filter spen-util-link" data-cy="remove-filter" (click)="removeCategoryFilter()">解除</a>
    </div>
  </div>
  <ng-template #notFiltered>
    <div class="not-filtered-category">
      <a class="spen-util-link" data-cy="show-filter" (click)="filterService.showFilter()"><i class="fa fa-search"></i>検索</a>
    </div>
  </ng-template>

  <div class="activity-records-list">
    <ng-container *ngIf="dataLoaded; else loadingIcon">
      <ng-container *ngIf="activityRecordsCount > 0; else emptyList">
        <div class="list-amount">
          <span data-cy="list-item-count">{{ activityRecordsCount }}件</span>
        </div>
        <div class="space-content">
          <ul class="space-flex-list">
            <li
              class="list-item"
              data-cy="list-item"
              (click)="clickItem(activityRecord)"
              *ngFor="
                let activityRecord of activityRecords
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      };
                first as isFirst
              "
              [class.is-first]="isFirst"
            >
              <app-student-activity-item [activityRecord]="activityRecord"></app-student-activity-item>
            </li>
          </ul>
          <div class="block-footer">
            <div class="pagination" *ngIf="showPagination">
              <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
              </app-pagination-controls>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #emptyList>
        <div class="null-content">
          <img src="/assets/images/empty-content.png" />
          <div class="empty-message">
            <p>まずは『<a href="https://ed.classi.jp/student_howtouse_classi#mokuhyotateru">Classi活用ナビサイト</a>』</p>
            <p>で利用シーンを見てみましょう！</p>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingIcon>
      <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
    </ng-template>
  </div>
  <a href="{{ classiUrl }}" class="spen-mod-btn prev-button" data-cy="back-home" *ngIf="!isMobileApp">ホームへ戻る</a>
</div>

<app-filter-by-multiple *ngIf="filterService.isShowingFilter" [showManaVisionOption]="true" (submitFilter)="changeFilter($event)">
</app-filter-by-multiple>
<app-student-mana-vision-detail
  *ngIf="!!currentManaVision"
  [manaVision]="currentManaVision"
  [isPageLayout]="true"
  (closeEvent)="hideManaVisionModal()"
>
</app-student-mana-vision-detail>
