import { Component, Input } from '@angular/core';
import { Document } from '../../../../components/document/document';
import { Mark } from '@models/mark';
import { AuthUserService } from '../../../services/auth-user.service';
import { DocumentAnswer } from '@models/document-answer';

@Component({
  selector: 'app-document-detail-student',
  templateUrl: './document-detail-student.component.html',
  styleUrls: ['./document-detail-student.component.scss'],
})
export class DocumentDetailStudentComponent {
  @Input() document: Document;
  @Input() categoryName: string;
  @Input() workType: string;
  @Input() isCompassAnswer: boolean;
  @Input() document_answers: Array<DocumentAnswer>;
  @Input() marks: Array<Mark> = [];
  @Input() option = {};

  constructor(public authUserService: AuthUserService) {}

  get isMultipleWork() {
    return !!this.document_answers;
  }
}
