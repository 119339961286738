import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { environment } from '@environments/environment';
import { Mark } from '@models/mark';
import { map, catchError, finalize } from 'rxjs/operators';

interface MarksResponse {
  marks: Array<Mark>;
}

@Injectable({
  providedIn: 'root',
})
export class TeacherMarksService {
  marksSource = new BehaviorSubject<Array<Mark>>([]);
  isRequested = false;

  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {
    if (this.marksSource.getValue().length === 0 && sessionStorage.getItem('marks')) {
      this.marksSource.next(JSON.parse(sessionStorage.getItem('marks')));
    }
  }

  loadMarks(): Promise<Array<Mark>> | Array<Mark> {
    if (this.marksSource.value.length === 0 && !this.isRequested) {
      return this.doRequest().toPromise();
    } else {
      return this.marksSource.getValue();
    }
  }

  private doRequest(): Observable<Array<Mark>> {
    const url = `${this.apiEndpointV2}/marks`;

    return this.http.get<MarksResponse>(url).pipe(
      map((response) => {
        sessionStorage.setItem('marks', JSON.stringify(response.marks));
        this.marksSource.next(response.marks);
        return response.marks;
      }),
      catchError((_error) => of(<Mark[]>[])),
      finalize(() => (this.isRequested = true)),
    );
  }
}
