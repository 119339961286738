import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';

import { FileUploaderComponent } from './file-uploader.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SharedModule } from '../../components/shared.module';

@NgModule({
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent, FileUploadModule],
  imports: [CommonModule, FileUploadModule, SharedDirectivesModule, SharedPipesModule, SharedModule],
})
export class FileUploaderModule {}
