import { Injectable } from '@angular/core';
import { AuthUserService } from '@services/auth-user.service';
import { UntypedFormGroup } from '@angular/forms';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { Subscription } from 'rxjs';
import { GoogleAnalytics4Service } from './google-analytics-4.service';
import { debounceTime } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';

@Injectable()
export class ContentSavingService {
  storegeKey: string;
  contentEncodingKey: string;
  userId: number;
  url: string;
  recordType: string;
  form: UntypedFormGroup;
  marksSelector: MarksSelectorComponent;
  private formChangeSubcribe: Subscription;
  private confirmedListener = new Subject<boolean>();
  private routerServiceSubcribe: Subscription;

  confirmed$ = this.confirmedListener.asObservable();

  constructor(
    private authUserService: AuthUserService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    private analytic4Service: GoogleAnalytics4Service,
  ) {}

  startService(url: string, form: UntypedFormGroup, marksSelector: MarksSelectorComponent, recordType: string) {
    this.url = url;
    this.userId = this.authUserService.retrieve().id;
    this.storegeKey = `${this.url}_${this.userId}`;
    this.contentEncodingKey = this.authUserService.retrieve().contentEncodingKey;
    this.form = form;
    this.recordType = recordType;
    this.marksSelector = marksSelector;

    this.recoveryDataConfirm();

    this.formChangeSubcribe = this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.updateFormData();
    });

    this.marksSelector.eventChangeMark.subscribe(() => {
      this.updateFormData();
    });

    this.routerServiceSubcribe = this.routerService.backBrowser$.subscribe(() => {
      this.removeTemporaryData();
    });
  }

  stopService() {
    this.formChangeSubcribe?.unsubscribe();
    this.marksSelector?.eventChangeMark.unsubscribe();
    this.routerServiceSubcribe?.unsubscribe();
  }

  removeTemporaryData() {
    localStorage?.removeItem(this.storegeKey);
  }

  private updateFormData() {
    this.form.value.activity_records_marks_attributes = [];
    this.marksSelector.selectedMarks
      .filter((mark) => !!mark.checked)
      .map((mark) => mark.id)
      .forEach((newId) => this.form.value.activity_records_marks_attributes.push({ mark_id: newId }));

    this.setTemporaryData(this.form.value);
  }

  haveTemporaryData() {
    return localStorage && localStorage.getItem(this.storegeKey) !== null;
  }

  private recoveryDataToForm(): void {
    const formData = this.getTemporaryData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key) && key !== 'activity_records_marks_attributes') {
        this.form.controls[key].setValue(formData[key]);
      }
    }

    const mask_selected_ids = formData['activity_records_marks_attributes'].map((obj) => obj.mark_id);
    this.marksSelector.changeMark(mask_selected_ids);
    this.updateFormData();
  }

  private getTemporaryData() {
    const decryptedContent = CryptoJS.AES.decrypt(localStorage.getItem(this.storegeKey), this.contentEncodingKey);
    return JSON.parse(decryptedContent.toString(CryptoJS.enc.Utf8));
  }

  private setTemporaryData(value) {
    if (!localStorage) {
      return;
    }

    const encryptedContent = CryptoJS.AES.encrypt(JSON.stringify(value), this.contentEncodingKey);
    localStorage.setItem(this.storegeKey, encryptedContent);
  }

  private recoveryDataConfirm() {
    if (this.haveTemporaryData()) {
      const confirmOptions = {
        title: '確認',
        content: '保存された下書きがあります。',
        acceptButton: '復元する',
        cancelButton: '破棄する',
      };
      this.confirmDialogService.showConfirm(confirmOptions).subscribe((accept) => {
        if (accept) {
          this.recoveryDataToForm();
          this.analytic4Service.sendEvent('活動記録', '下書き確認モーダル', '復元する');
        } else {
          this.removeTemporaryData();
        }
        this.confirmedListener.next(true);
      });
    }
  }
}
