import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { ToastService } from '@services/toast/toast.service';

import { GraduationMessage } from '@components/graduation-message/graduation-message';

@Component({
  selector: 'app-graduation-message-detail',
  templateUrl: './graduation-message-detail.component.html',
  styleUrls: ['./graduation-message-detail.component.scss'],
})
export class GraduationMessageDetailComponent implements OnInit, OnDestroy {
  graduationMessageDetail: GraduationMessage;
  graduationMessageDetailLoaded = false;
  spacePath: string | null;

  routerSubscriber = null;
  graduationMessageId: number;
  isDeletingGraduationMessage = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private graduationMessageService: GraduationMessageService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.routerSubscriber = this.route.params.subscribe(() => {
      this.graduationMessageId = +this.route.snapshot.paramMap.get('id');
      this.loadGraduationMessageDetail();
    });
  }

  ngOnDestroy() {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }

  loadGraduationMessageDetail() {
    this.graduationMessageDetailLoaded = false;
    this.graduationMessageService.getTeacherGraduationMessageDetail(this.graduationMessageId).subscribe(
      (response) => {
        this.graduationMessageDetail = response;
        this.buildSpacePath();
        this.graduationMessageDetailLoaded = true;
      },
      (error) => {},
    );
  }

  private buildSpacePath() {
    if (!!this.graduationMessageDetail.space_id) {
      this.spacePath = `/spaces/teacher/${this.graduationMessageDetail.space_id}`;
    } else {
      this.spacePath = null;
    }
  }

  onConfirmDeleteGraduationMessage() {
    const confirmOptions = {
      title: '確認',
      content: '生徒画面からも削除されます。<br>一度削除した卒業メッセージは復元することができません。<br>本当に削除しますか？',
      acceptButton: '削除する',
      cancelButton: 'キャンセル',
    };

    this.confirmDialogService.showConfirm(confirmOptions).subscribe((accept) => {
      if (accept) {
        this.deleteGraduationMessage();
      }
    });
  }

  private deleteGraduationMessage() {
    this.isDeletingGraduationMessage = true;

    this.graduationMessageService.deleteGraduationMessage(this.graduationMessageId).subscribe(
      () => {
        this.router.navigateByUrl(`graduation-messages/teacher`, { replaceUrl: true });
        this.toastService.showToast('削除しました');
      },
      (error) => {
        this.isDeletingGraduationMessage = false;
      },
    );
  }
}
