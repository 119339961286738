<ng-container [ngSwitch]="activityRecord.record_type">
  <ng-container *ngSwitchCase="'Questionnaire'">
    <a class="list-item-info">
      <img class="icon" src="assets/images/icons/questionnaire.svg" />
      <div class="text-info" data-cy="text-info">
        <p class="time-space">
          {{ activityRecord.created_at | date: 'yyyy/MM/dd' }}
          <span> ［アンケート］</span>
        </p>
        <p class="activity-name">{{ activityRecord.title }}</p>
        <span *ngIf="!!activityRecord.category_name" class="questionnaire-category">{{ activityRecord.category_name }}</span>
      </div>
    </a>
    <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
  </ng-container>
  <ng-container *ngSwitchCase="'Document'">
    <a class="list-item-info">
      <i class="fa fa-file-text-o"></i>
      <div class="text-info" data-cy="text-info">
        <div class="pickup" *ngIf="activityRecord.has_active_pickup">
          <img src="assets/images/icons/pickup.svg" />
          <span>先生のピックアップ</span>
        </div>
        <p class="time-space">
          {{ activityRecord.created_at | date: 'yyyy/MM/dd' }}
          <span *ngIf="activityRecord.is_answer"> ［課題］</span>
        </p>
        <p class="activity-name">{{ activityRecord.title }}</p>
        <span class="comment-block">
          <a class="badge-noti" data-cy="text-info-badge" *ngIf="activityRecord.has_unread_assessment"></a>
          <i class="fa fa-commenting-o"></i>
        </span>
        {{ activityRecord.assessments_count }}
        <span *ngIf="!!activityRecord.category_name" class="document-category">{{ activityRecord.category_name }}</span>
      </div>
    </a>
    <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
  </ng-container>
  <ng-container *ngSwitchCase="'ManaVision'">
    <a class="list-item-info">
      <i class="fa fa-file-text-o"></i>
      <div class="text-info" data-cy="text-info">
        <p class="time-space">
          {{ activityRecord.created_at | date: 'yyyy/MM/dd' }}
          <span> ［マナビジョン］</span>
        </p>
        <p class="activity-name">{{ activityRecord.title }}</p>
      </div>
    </a>
    <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
  </ng-container>
</ng-container>
