import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';

import { ActivityRecordTemplatesService } from '../activity-record-templates.service';
import { NextPageParams } from '../activity-record-templates-response';
import { WorkTemplate } from '@models/work-template';
import { LayoutService } from '@components/layout/layout.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { pick, pickBy, isEqual, omit, isNil } from 'lodash';
import { User } from '@models/user';
import { AuthUserService } from '@services/auth-user.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { isMobileApp } from '@functions/browser';

const DEFAULT_FORM_OPTIONS = {
  create_user_scope: 'all',
  title: '',
};
const marginFooterPC = 80;
const marginFooterSP = 60;

@Component({
  selector: 'app-activity-record-template-list',
  templateUrl: './activity-record-templates.component.html',
  styleUrls: ['./activity-record-templates.component.scss'],
})
export class ActivityRecordTemplatesComponent implements OnInit {
  createUserScope = [
    { value: 'all', name: 'すべて' },
    { value: 'myself', name: '自分' },
    { value: 'other_teachers', name: '自分以外の先生' },
    { value: 'system', name: 'Classi' },
  ];
  private readonly allowedParamKeys = ['title', 'create_user_scope'];
  private readonly disallowedUrlParamKeys = ['title'];
  meta: NextPageParams = {
    total_count: 0,
    create_user_scope: null,
    next_page: null,
    title: null,
  };
  currentUser: User;
  workTemplates: Array<WorkTemplate>;
  currentWorkTemplate: WorkTemplate;
  isShowFilterWorkTemplate = false;
  form: UntypedFormGroup;
  errorMsg: string;
  workTemplateLoading = false;
  moreWorkTemplateLoading = false;
  filterData = {};
  isShowWorkTemplateSP = [];
  isShowDropdownActionsSP = [];
  messageFilter: string;
  newViewTemplate = false;
  hasScrollBar = false;
  readonly saveConditionKeys = ['create_user_scope'];
  readonly isAppView = isMobileApp();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    private activityRecordTemplatesService: ActivityRecordTemplatesService,
    private authUserService: AuthUserService,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);

    this.currentUser = this.authUserService.retrieve();
  }

  private loadList(params: Params): void {
    this.filterData = pick(params, this.allowedParamKeys);

    const url = this.router.url.split('?')[0];
    if (Object.keys(this.filterData).length === 0) {
      this.filterData = this.searchConditionSavingService.getSearchConditions(url);
    } else {
      const saveConditions = pick(this.filterData, this.saveConditionKeys);
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
    }

    this.setFilter(this.filterData);
    this.getActivityRecordTemplates(this.filterData);
    this.initForm();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  getActivityRecordTemplates(param = {}) {
    this.workTemplateLoading = true;
    this.hasScrollBar = false;
    this.activityRecordTemplatesService.getActivityRecordTemplates(param).subscribe(
      (response) => {
        this.workTemplates = response.work_templates || [];
        this.currentWorkTemplate = this.workTemplates[0];
        this.updateMetaData(response.meta);
        this.workTemplates.forEach((template) => {
          this.isShowWorkTemplateSP.push(false);
          this.isShowDropdownActionsSP.push(false);
        });
        this.workTemplateLoading = false;
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  previewWorkTemplate(workTemplate: WorkTemplate) {
    this.currentWorkTemplate = workTemplate;
    this.newViewTemplate = true;
  }

  getMoreWorkTemplate() {
    this.hasScrollBar = true;
    if (!this.meta.next_page || this.moreWorkTemplateLoading) {
      return;
    }
    this.moreWorkTemplateLoading = true;
    const params = {
      create_user_scope: this.meta.create_user_scope,
      page: this.meta.next_page,
      title: this.meta.title,
    };
    this.activityRecordTemplatesService.getActivityRecordTemplates(params).subscribe(
      (response) => {
        this.workTemplates.push(...response.work_templates);
        this.updateMetaData(response.meta);
        this.moreWorkTemplateLoading = false;
        response.work_templates.forEach((template) => {
          this.isShowWorkTemplateSP.push(false);
          this.isShowDropdownActionsSP.push(false);
        });
      },
      (error) => (this.errorMsg = <any>error),
    );
  }

  initForm() {
    this.form = this.formBuilder.group({
      title: this.filterData['title'] || '',
      create_user_scope: this.filterData['create_user_scope'] || 'all',
    });
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.messageFilter = '';
    this.form.controls['title'].setValue(this.form.controls['title'].value.trim());
    const params = pickBy(Object.assign({}, this.form.value));
    params['request_timestamp'] = Date.now();
    this.navigate(params);
    this.setFilter(this.form.value);
    this.isShowWorkTemplateSP = [];
    this.isShowDropdownActionsSP = [];
  }

  updateMetaData(meta: Object) {
    Object.assign(this.meta, meta);
    if (!meta.hasOwnProperty('next_page')) {
      this.meta['next_page'] = null;
    }
    if (!meta.hasOwnProperty('title')) {
      this.meta['title'] = null;
    }
  }

  onResetFilter() {
    this.form.reset(DEFAULT_FORM_OPTIONS);
    this.onSubmit();
  }

  onChangeShowMoreFilter() {
    this.isShowFilterWorkTemplate = !this.isShowFilterWorkTemplate;
  }

  onChangeShowWorkTemplateSP(i) {
    this.isShowWorkTemplateSP[i] = !this.isShowWorkTemplateSP[i];
  }

  get isDefaultForm(): boolean {
    return isEqual(this.form.value, DEFAULT_FORM_OPTIONS);
  }

  private haveFilterData(params) {
    return this.hasValueAtKeys(params, this.allowedParamKeys);
  }

  private hasValueAtKeys(object, keys: Array<string>): boolean {
    for (const key of keys) {
      if (!!object[key] && object[key] !== 'null') {
        return true;
      }
    }
    return false;
  }

  setFilter(params = {}) {
    if (!this.haveFilterData(params)) {
      this.messageFilter = 'すべて';
      return;
    }
    const pairMessage = [];

    if (params['create_user_scope']) {
      switch (params['create_user_scope']) {
        case 'all':
          pairMessage.push('すべて');
          break;
        case 'myself':
          pairMessage.push('自分');
          break;
        case 'other_teachers':
          pairMessage.push('自分以外の先生');
          break;
        case 'system':
          pairMessage.push('Classi');
          break;
      }
    } else {
      pairMessage.push('すべて');
    }

    if (params['title'] && params['title'].trim()) {
      pairMessage.push(params['title'].trim());
    } else {
      delete params['title'];
    }

    this.messageFilter = pairMessage.join('/');
  }

  @HostListener('click', ['$event'])
  onMouseLeftClick() {
    const templateContainerElement = document.getElementById('template-container');

    if (this.newViewTemplate && !this.layoutService.isTabletDownView.value && templateContainerElement) {
      const templateContainerMargin = templateContainerElement.offsetTop;
      if (window.pageYOffset > templateContainerMargin) {
        window.scrollTo({
          top: templateContainerMargin,
          behavior: 'smooth',
        });
      }
    }
    setTimeout(() => {
      this.newViewTemplate = false;
    }, 200);
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const clientHeight = document.body.clientHeight;
    const templateContainerElement = document.getElementById('template-container');
    const contentSectionElement = document.getElementById('template-list');
    const templateSection = document.getElementById('template-preview');
    const headerOffsetHeight = document.getElementById('global-header')?.offsetHeight || 0;
    const footerOffsetHeight = (document.getElementsByClassName('spen-ly-tea-footer')[0] as HTMLElement)?.offsetHeight || 0;
    const ulItemlist = document.getElementsByClassName('spen-mod-item-list')[0] as HTMLElement;

    if (templateContainerElement) {
      const templateContainerMargin = templateContainerElement.offsetTop;
      const contentSectionHeight = contentSectionElement.offsetHeight;
      if (this.layoutService.isTabletDownView.value) {
        if (window.pageYOffset > clientHeight - window.innerHeight - marginFooterSP) {
          this.getMoreWorkTemplate();
        }
      } else {
        const listFixedTop = ['list-fixed-top'];
        if (this.isAppView) {
          listFixedTop.push('list-fixed-top-app');
        }

        if (window.pageYOffset > templateContainerMargin) {
          templateSection.classList.add('div-offset');
          contentSectionElement.classList.add(...listFixedTop);

          if (window.pageYOffset > clientHeight - contentSectionHeight - headerOffsetHeight - footerOffsetHeight - marginFooterPC) {
            if (ulItemlist.offsetHeight >= window.innerHeight - headerOffsetHeight - footerOffsetHeight - marginFooterPC) {
              contentSectionElement.classList.add('list-fixed-bottom');
              contentSectionElement.classList.remove(...listFixedTop);
            }
          } else {
            contentSectionElement.classList.remove('list-fixed-bottom');
          }
        } else {
          contentSectionElement.classList.remove(...listFixedTop);
          contentSectionElement.classList.remove('list-fixed-bottom');
          templateSection.classList.remove('div-offset');
        }
      }
    }
  }
}
