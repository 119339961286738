// Cloned from: https://github.com/angular/components/blob/master/src/cdk/layout/breakpoints.ts
// Update:
//   - move 600px from Small to XSmall

export const Breakpoints = {
  Mobile: '(max-width: 600px)',
  PhoneDown: '(max-width: 768px)',
  TabletDown: '(max-width: 959px)',
  MediumDesktopDown: '(max-width: 1279px)',
};
