<div class="modal" data-cy="answer-preview-modal">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <span class="top-btn-close" (click)="closeModal()">
            <i class="fa fa-times" data-cy="close-preview-work" aria-hidden="true"></i>
          </span>
          <h2 class="heading-text">課題回答画面プレビュー</h2>
        </div>

        <div class="modal-content" #scrollable>
          <div class="work-info" *ngIf="work">
            <div class="spen-mod-heading lv-2 header-title">
              <h2 class="heading-text" data-cy="work-title">{{ work.title }}</h2>
            </div>
            <div class="user-info">
              <img data-cy="work-creator-avator" [src]="work.create_user.avatar" />
              <div class="text-info">
                <p data-cy="work-creator-name">{{ work.create_user.name }}先生</p>
              </div>
            </div>
            <div class="box-comment" *ngIf="work.comment">
              <span class="tip-up"></span>
              <span class="tip-up-large"></span>
              <p appConvertLink data-cy="work-comment">{{ work.comment }}</p>
            </div>
            <div class="thumb row">
              <ng-container *ngFor="let item of imageFiles; let file_index = index">
                <div class="thumb-item" *ngIf="file_index < 4">
                  <div class="attach">
                    <ng-container *ngIf="item._file; else imageExist">
                      <img appImagePreview [image]="item?._file" />
                    </ng-container>
                    <ng-template #imageExist>
                      <img src="{{ item.thumbnail }}" />
                    </ng-template>
                    <ng-container *ngIf="showShadowBox(file_index)">
                      <div class="overlay"></div>
                      <p class="number-attach">+{{ imageFiles.length - 3 }}</p>
                    </ng-container>
                  </div>
                  <p class="file-name truncate-text" *ngIf="!showShadowBox(file_index)">{{ getFileName(item) }}</p>
                </div>
              </ng-container>
              <div *ngFor="let item of notImageFiles" class="file-item">
                <div class="attach">
                  <div class="file-format"><i [ngClass]="getFileName(item) | fileIconClass"></i></div>
                </div>
                <p class="file-name truncate-text">{{ getFileName(item) }}</p>
              </div>
            </div>
          </div>
          <div class="template" *ngIf="!dataLoading(); else loadingIcon">
            <p class="tem-heading">活動記録作成</p>
            <div class="btn-show-history">
              <p>過去の活動記録を見る</p>
            </div>
            <div class="form-answer-preview">
              <div class="form-group form-group-title">
                <div class="row">
                  <p class="label-input">タイトル</p>
                  <ng-container *ngIf="work.answer_title; else inputText">
                    <p class="answer-title">{{ work.answer_title }}</p>
                  </ng-container>
                  <ng-template #inputText>
                    <p class="input-text">入力してください</p>
                  </ng-template>
                </div>
              </div>
              <ng-container [ngSwitch]="convertWorkType(work.work_type)">
                <div *ngSwitchCase="'single'">
                  <div class="form-group">
                    <div class="row">
                      <p class="label-input">本文</p>
                      <label class="select-input">
                        <div class="select">テンプレートを選択</div>
                        <i class="fa fa-caret-down"></i>
                      </label>
                      <p class="input-text input-textarea">入力してください</p>
                      <p class="text-num pull-right">文字数 <span class="num-character">0</span></p>
                      <label class="stu-add-file">
                        <i class="fa fa-paperclip"></i>
                        <span>ファイルを添付する</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'multiple'">
                  <div class="form-group work-questions" *ngFor="let question of workQuestions; let i = index">
                    <div class="row">
                      <div class="label-input">
                        設問{{ i + 1 }}
                        <span *ngIf="isCompassWork" class="required">必須</span>
                      </div>
                    </div>
                    <div class="row">
                      <p class="work-question-content">{{ question.content.description || question.content }}</p>
                      <div class="question-image" *ngIf="question.image || question.image_attributes">
                        <img src="{{ question.image?.thumbnail || question.image_attributes?.thumbnail }}" />
                      </div>
                    </div>
                    <div class="row">
                      <ng-container *ngIf="!question.content.description; else singleChoiceOptions">
                        <p class="input-text input-textarea">入力してください</p>
                        <p class="text-num pull-right">文字数 <span class="num-character">0</span></p>
                      </ng-container>
                      <ng-template #singleChoiceOptions>
                        <div class="answer-options">
                          <div *ngFor="let option of question.content.options" class="answer-option">
                            <label class="spen-mod-radio">
                              <i class="fa"></i>
                              <div class="option-text">{{ option }}</div>
                            </label>
                          </div>
                        </div>
                      </ng-template>
                      <label class="stu-add-file">
                        <i class="fa fa-paperclip"></i>
                        <span>ファイルを添付する</span>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="form-group" *ngIf="work.category_name; else categorySelect">
                <div class="row">
                  <p class="category-text">{{ work.category_name }}</p>
                </div>
              </div>
              <ng-template #categorySelect>
                <div class="form-group">
                  <div class="row">
                    <p class="label-input">カテゴリー</p>
                    <label class="select-input">
                      <div class="select">すべて</div>
                      <i class="fa fa-caret-down"></i>
                    </label>
                    <label class="select-input">
                      <div class="select select-disabled">すべて</div>
                      <i class="fa fa-caret-down"></i>
                    </label>
                  </div>
                </div>
              </ng-template>
              <div class="form-group group-mark">
                <div class="row">
                  <p class="label-input">マーク</p>
                  <ul class="mark-select">
                    <li class="spen-mod-checkbox" *ngFor="let mark of marks">
                      <i class="fa"></i>
                      <img class="mark-icon" [src]="mark.icon" />
                      <span class="text">{{ mark.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-notice">
            <span>閲覧のみ</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="loading-item" *ngIf="!errorMsg && dataLoading()">
    <div class="spen-mod-flex-loading">
      <div class="loading-icon"></div>
    </div>
  </div>
</ng-template>
