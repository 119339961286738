import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FileItem } from 'ng2-file-upload';
import { getFileExtension } from '@functions/check-string';
import { PolicyParamsResponse } from './policy-params-response';

@Injectable()
export class S3CredentialService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getPolicyParams(fileItems: FileItem[]): Observable<PolicyParamsResponse> {
    const extensions = fileItems.map((fileItem) => getFileExtension(fileItem.file.name));
    return this.http.post<PolicyParamsResponse>(`${this.apiEndpoint}/upload_files/upload_links`, { extensions: extensions });
  }
}
