import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { ActivityPickupService } from '@components/activity-pickup/activity-pickup.service';
import { Space } from '@components/space/space';
import { NextPageParams } from '../spaces-to-pickup-response';

@Component({
  selector: 'app-search-single-space',
  templateUrl: './search-single-space.component.html',
  styleUrls: ['../search-space.component.scss'],
})
export class SearchSingleSpaceComponent implements OnInit, OnDestroy {
  @Output() submitEvent: EventEmitter<Space> = new EventEmitter<Space>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() activityRecordId: number;
  @Input() selectedSpace: Space;
  @Input() spaceEditId: number;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  searchedResults: Array<Space> = new Array<Space>();
  newSelectSpace: Space;
  nextPageParams: NextPageParams;
  isShowError = false;
  isLoading = false;
  errMsg: string;
  spaceSelectUpdated = false;

  constructor(
    private renderer: Renderer2,
    private activityPickupService: ActivityPickupService,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.newSelectSpace = this.selectedSpace;
    this.onSearch();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch(isSubmit = false) {
    if (this.isLoading) {
      return;
    }
    if (isSubmit) {
      this.searchedResults = [];
      this.spaceSelectUpdated = false;
    }

    this.isLoading = true;
    this.nextPageParams = null;
    if (this.isShowError) {
      this.isShowError = false;
    }

    this.searchInput.nativeElement.value = this.searchInput.nativeElement.value.trim();
    const params = {
      keyword: !!this.searchInput ? this.searchInput.nativeElement.value : '',
    };
    this.activityPickupService.getListSpacesToPickup(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults = response.spaces_pickup;
        this.nextPageParams = response.meta;
        this.isLoading = false;
        this.updatedSpaceSelect(response.spaces_pickup);
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;

    const params = {
      keyword: this.nextPageParams.keyword,
      page: this.nextPageParams.next_page,
    };

    this.activityPickupService.getListSpacesToPickup(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults.push(...response.spaces_pickup);
        this.nextPageParams = response.meta;
        this.isLoading = false;
        this.updatedSpaceSelect(response.spaces_pickup);
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  updatedSpaceSelect(data: Array<Space>) {
    if (!this.spaceSelectUpdated) {
      data.forEach((space) => {
        if (this.newSelectSpace && this.newSelectSpace.id === space.id) {
          this.newSelectSpace = space;
          this.spaceSelectUpdated = true;
          return;
        }
      });
    }
  }

  onClose() {
    this.closeEvent.emit();
  }

  /** onSubmit()
   * spaceEditId: not exited if model is create pickup.
   * case 1: check selectSpace empty
   * case 2: selectSpace invalid => this.isShowError = true.
   * Case 3: else => valid => allow submit event
   **/

  onSubmit() {
    if (!this.newSelectSpace) {
      this.isShowError = true;
    } else {
      if (!this.newSelectSpace.teacher_can_pick_up || (this.newSelectSpace.is_picked_up_by_current_teacher && !this.spaceEditId)) {
        this.isShowError = true;
      } else {
        this.submitEvent.emit(this.newSelectSpace);
      }
    }
  }

  selectSpace(space: Space) {
    this.isShowError = false;
    this.newSelectSpace = space;
  }

  /** canNotPickup(space)
   * space: for loop space list in template.
   * spaceEditId: exited if model is edit pickup.
   * case 1: check teacher can pick up space item
   * case 2: teacher can pick up space if (check is edit type && item is spaceEditId or item is newSelectSpace
   * Case 4: if pass condition above or model is create new pickup. check this condition below.
   * can't pick up if item had pick up by current teacher
   **/

  canNotPickup(space: Space) {
    if (!space.teacher_can_pick_up) {
      return true;
    }

    if (this.spaceEditId && (this.spaceEditId === space.id || (this.newSelectSpace && this.newSelectSpace.id === space.id))) {
      return false;
    }
    return space.is_picked_up_by_current_teacher;
  }
}
