<div class="stu-my-space">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <button class="spen-mod-btn is-primary" data-cy="slide-menu" (click)="slideMenu()"><i class="fa fa-reorder"></i></button>マイスペース
    </h1>
  </div>
  <div class="my-space-noti" *ngIf="isNotification">
    <a
      data-cy="request-reviews"
      *ngFor="let requestReview of notificationBadge.request_reviews"
      class="spen-util-link"
      [routerLink]="['/spaces', 'student', requestReview.space_id, 'activity_records', requestReview.activity_record_id]"
    >
      {{ requestReview.create_user.name }}先生から{{ requestReview.activity_record_title }}へのコメントの見直し依頼が来ました。
    </a>
    <a
      data-cy="story-comennts"
      *ngFor="let storyComment of notificationBadge.story_comments"
      class="spen-util-link"
      [routerLink]="['/stories/student']"
      [queryParams]="{ is_read: true, tab: storyComment.grade === null ? 'summary' : 'grade_' + storyComment.grade }"
      fragment="story-comment"
    >
      {{ storyComment.create_user_name }}先生から{{
        storyComment.grade === null ? 'まとめ' : storyComment.grade + '年'
      }}のストーリーにひとことが届いています。
    </a>
    <a
      data-cy="delivered-graduation-messages"
      *ngFor="let deliveredGraduationMessage of notificationBadge.delivered_graduation_messages"
      class="spen-util-link"
      [routerLink]="['/graduation-messages/student', deliveredGraduationMessage.id]"
      (click)="onClickDeliveredGraduationMessageLink()"
    >
      {{ deliveredGraduationMessage.create_user_name }}先生から{{
        deliveredGraduationMessage.space_name
      }}スペースのみなさんに卒業メッセージが配信されました。
    </a>
  </div>
  <div class="tab-view">
    <app-tab-view data-cy="tab-view" [tabOptions]="tabOptions" [tabBadge]="notificationBadge"></app-tab-view>
  </div>
  <ng-container [ngSwitch]="currentTab">
    <app-activity-records-list
      *ngSwitchCase="'activities'"
      [unread_assessments_count]="notificationBadge.unread_assessments_count"
    ></app-activity-records-list>
    <app-albums-list *ngSwitchCase="'albums'"></app-albums-list>
    <app-work-list *ngSwitchCase="'work'"></app-work-list>
  </ng-container>
</div>
