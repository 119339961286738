import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { pick, pickBy, omit, merge } from 'lodash';
import { LayoutService } from '@components/layout/layout.service';
import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { SlideMenuService } from '@services/slide-menu.service';
import { StudentNavigationService } from '@services/navigation/student-navigation.service';
import { StudentSummary } from './student-summary';
import { ErrorService } from '@services/error.service';
import { AttachmentStatus } from './attachment-status';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-portfolio-graduation',
  templateUrl: './portfolio-graduation.component.html',
  styleUrls: ['./portfolio-graduation.component.scss'],
})
export class PortfolioGraduationComponent implements OnInit, OnDestroy {
  contentLoaded = false;
  listLoaded = false;

  queryParams = {};
  private readonly allowedParamKeys = ['page'];

  showActivityDetailModal = false;
  activityId: number;

  graduationMessages: GraduationMessage[] = [];
  pages = {
    current_page: 1,
    next_page: null,
    per_page: 1,
    prev_page: null,
    total_count: 1,
    total_pages: 1,
  };

  routerSubscriber = null;

  studentSummaries: StudentSummary[] = [];
  @ViewChild('portfolioDownloadLink') portfolioDownloadLink: ElementRef<HTMLAnchorElement> = null;
  graduationContentsStatus: AttachmentStatus = 'not_created';
  private graduationContentsStatusTimer: number = null;
  private progressTimer: number = null;
  private progressFinishTimer: number = null;
  progress: number = null;
  progressStatus = '';
  progressCanCancel = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private graduationMessageService: GraduationMessageService,
    public layoutService: LayoutService,
    private analytics4Service: GoogleAnalytics4Service,
    private slideMenuService: SlideMenuService,
    private navigationService: StudentNavigationService,
    private errorService: ErrorService,
    private routerService: RouterService,
  ) {}

  ngOnInit(): void {
    this.routerSubscriber = this.route.queryParams.subscribe((params) => {
      this.queryParams = pick(params, this.allowedParamKeys);
      this.loadGraduationMessages(this.queryParams);
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }

    this.onCancelPortfolioDownload();
  }

  loadGraduationMessages(params: {}) {
    this.listLoaded = false;

    this.graduationMessageService.getStudentGraduationMessages(params).subscribe(
      (response) => {
        if (response.meta['total_count']) {
          Object.assign(this.pages, response.meta);
          this.graduationMessages = response.graduation_messages;

          this.listLoaded = true;

          if (!this.contentLoaded) {
            this.loadStudentSummaries();
          }
        } else {
          this.errorService.addError({
            error_code: 500,
            title: 'エラーが発生しました',
            message: '先生が卒業メッセージを削除しました。',
            internalMessage: 'Data not found',
          });

          this.contentLoaded = false;
        }
      },
      (error) => {},
    );
  }

  onClickGraduationMessage(graduationMessageId: number) {
    this.navigationService.registerNavigator({ screenId: 1, filterData: omit(pickBy(this.queryParams), 'page') });
    this.router.navigateByUrl(`graduation-messages/student/${graduationMessageId}`);
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.router.navigate(['.'], { queryParams: params, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  slideMenu() {
    this.slideMenuService.slideIn();
  }

  loadStudentSummaries() {
    this.graduationMessageService.getStudentSummaries().subscribe(
      (response) => {
        this.studentSummaries = response;

        this.contentLoaded = true;
      },
      (error) => {},
    );
  }

  private setProgress(rate: number): void {
    this.progress = rate;

    let rateString = '';
    if (rate !== null) {
      if (rate < 10) {
        rateString = '0' + rate;
      } else {
        rateString = `${rate}`;
      }
    }

    this.progressStatus = `ダウンロードの準備をしています...${rateString}%<br>処理が終わるまでページを閉じずにお待ちください。`;
  }

  onClickPortfolioDownload() {
    this.setProgress(0);
    this.startProgressTimer();

    this.routerService.registerBackConfirm({});

    this.graduationMessageService.createStudentGraduationContents().subscribe(
      (response) => {
        this.checkGraduationContentsStatus();
      },
      (error) => {
        this.routerService.resetDataConfirm();
        this.stopProgressTimer();
      },
    );
  }

  private checkGraduationContentsStatus() {
    switch (this.graduationContentsStatus) {
      case 'not_created':
      case 'queued':
      case 'in_progress':
        this.startGraduationContentsStatusTimer();
        break;

      case 'created':
        this.stopProgressTimer();
        this.setProgress(100);
        this.getGraduationContents();
        break;

      case 'error':
        this.routerService.resetDataConfirm();
        this.stopProgressTimer();
        this.errorService.addError({
          error_code: 500,
          title: null,
          message: null,
          internalMessage: 'error occurred on sidekiq',
        });
        break;

      default:
        this.onCancelPortfolioDownload();
        break;
    }
  }

  private startGraduationContentsStatusTimer() {
    this.stopGraduationContentsStatusTimer();

    this.graduationContentsStatusTimer = window.setTimeout(() => {
      this.graduationContentsStatusTimer = null;

      this.graduationMessageService.getStudentGraduationContentsStatus().subscribe(
        (response) => {
          this.graduationContentsStatus = response.status;

          this.checkGraduationContentsStatus();
        },
        (error) => {
          this.routerService.resetDataConfirm();
          this.stopProgressTimer();
        },
      );
    }, 10000);
  }

  private stopGraduationContentsStatusTimer() {
    if (this.graduationContentsStatusTimer !== null) {
      window.clearTimeout(this.graduationContentsStatusTimer);
      this.graduationContentsStatusTimer = null;
    }
  }

  private startProgressFinishTimer() {
    this.stopProgressFinishTimer();

    this.setProgress(100);

    this.progressFinishTimer = window.setTimeout(() => {
      this.progressFinishTimer = null;

      this.setProgress(null);
    }, 500);
  }

  private stopProgressFinishTimer() {
    if (this.progressFinishTimer !== null) {
      window.clearTimeout(this.progressFinishTimer);
      this.progressFinishTimer = null;
    }
  }

  private startProgressTimer() {
    this.stopProgressTimer();

    let timeout = 10;
    if (this.progress < 10) {
      timeout = 2;
    } else if (this.progress < 20) {
      timeout = 6;
    }

    this.progressTimer = window.setTimeout(() => {
      this.progressTimer = null;

      this.setProgress(Math.min(this.progress + 1, 99));
      this.startProgressTimer();
    }, timeout * 1000);
  }

  private stopProgressTimer() {
    if (this.progressTimer !== null) {
      window.clearTimeout(this.progressTimer);
      this.progressTimer = null;
    }
  }

  private getGraduationContents() {
    this.graduationMessageService.getStudentGraduationContents().subscribe(
      (response) => {
        this.onCancelPortfolioDownload();

        this.startProgressFinishTimer();

        if (!this.portfolioDownloadLink) return;
        const anchor = this.portfolioDownloadLink.nativeElement;
        anchor.setAttribute('href', response.url);
        anchor.click();
        anchor.removeAttribute('href');

        this.analytics4Service.sendEvent('ポートフォリオ卒業', 'ポートフォリオ卒業画面', 'ポートフォリオをダウンロード');
      },
      (error) => {
        this.progressCanCancel = true;
        this.routerService.resetDataConfirm();
      },
    );
  }

  onCancelPortfolioDownload() {
    this.routerService.resetDataConfirm();

    this.stopProgressTimer();
    this.stopProgressFinishTimer();
    this.stopGraduationContentsStatusTimer();

    this.graduationContentsStatus = 'not_created';

    this.setProgress(null);
    this.progressCanCancel = false;
  }

  eventShowActivity(activityRecordId: number) {
    this.activityId = activityRecordId;
    this.showActivityDetailModal = true;
  }

  eventForBackAction() {
    this.showActivityDetailModal = false;
  }
}
