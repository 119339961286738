import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from './i18n.service';
import { I18N_VALUES } from './datepicker-i18n.values';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class DatepickerI18nService extends NgbDatepickerI18n {
  constructor(private i18n: I18nService) {
    super();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }

  // override i18n functions
  // https://github.com/ng-bootstrap/ng-bootstrap/blob/4f2e4715372d50bebdb1c8aa0df874bddb8bd30a/src/datepicker/datepicker-navigation.ts#L30

  getMonthFullName(month: number, year: number): string {
    return `${year}年${month}月`;
  }

  getYearNumerals(): string {
    return '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}年${date.month}月${date.day}日`;
  }
}
