import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, RoutesRecognized, NavigationStart, ResolveStart } from '@angular/router';

import { Subscription } from 'rxjs';
import { pairwise, filter, map } from 'rxjs/operators';

import { AuthUserService } from '@services/auth-user.service';
import { RouterService } from '@services/router.service';
import { RouterDataService } from '@services/router-data.service';
import * as objectFitImages from 'object-fit-images';
import { NativeInterfaceService } from '@services/native-interface.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app works!';
  historySubscription: Subscription = new Subscription();

  createAnswerRegex = /\/spaces\/student\/\d+\/works\/\d+\/answer/; // match this: spaces/student/3/works/6/answer
  rootRegex = /(\/$|\/spaces$)/; // match this: / or /spaces

  constructor(
    private router: Router,
    private authUserService: AuthUserService,
    private _ngZone: NgZone,
    private routerDataService: RouterDataService,
    private routerService: RouterService,
    public nativeInterfaceService: NativeInterfaceService, // index.htmlから参照 Android生徒アプリの戻るボタン用
  ) {
    window['angularRef'] = { component: this, zone: this._ngZone };
  }

  ngOnInit() {
    this.getRouterData();
    this.historySubscription = this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((e: any[]) => {
        this.routerService.setPreviousHref(e[0].urlAfterRedirects);
        if (
          !this.createAnswerRegex.test(e[1].urlAfterRedirects) &&
          !this.isOnlyQueryParamsChange(e[0].urlAfterRedirects, e[1].urlAfterRedirects)
        ) {
          window.scrollTo(0, 0);
        }
      });
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((e: any) => {
      if (e.url.match(this.rootRegex)) {
        this.routerService.isAccessRootApp = true;
      }
    });
    objectFitImages();
  }

  ngOnDestroy() {
    this.historySubscription.unsubscribe();
    window['angularRef'] = null;
  }

  getRouterData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveStart),
        map((event) => {
          let data = null;
          let route = event['state'].root;
          while (route) {
            data = route.data || data;
            route = route.firstChild;
          }
          return data;
        }),
      )
      .subscribe((data) => {
        this.routerDataService.setData(data);
      });
  }

  private isOnlyQueryParamsChange(before, after) {
    return before.split('?')[0] === after.split('?')[0];
  }
}
