import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Assessment } from '../../assessment';

@Component({
  selector: 'app-assessment-detail-teacher',
  templateUrl: './assessment-detail-teacher.component.html',
  styleUrls: ['./assessment-detail-teacher.component.scss'],
})
export class AssessmentDetailTeacherComponent {
  @Input() assessment: Assessment;
  @Input() canEdit: boolean;
  @Output() editAssessment = new EventEmitter<Assessment>();

  constructor() {}

  isTeacher() {
    return !!this.assessment && !!this.assessment.create_user && this.assessment.create_user.userTypeId === 1;
  }

  onEditAssessment() {
    this.editAssessment.emit();
  }
}
