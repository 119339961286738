<div class="datetime-selector">
  <app-datepicker
    [selectedDate]="dateSelect"
    [disabled]="isDisabled"
    [isPopup]="true"
    [isFixedDropdown]="true"
    [includeResponsive]="true"
    (modelChange)="changeSelectTime('date', $event)"
    #datePicker
  >
  </app-datepicker>

  <div class="time-box">
    <label class="spen-mod-select">
      <select
        class="select"
        (change)="changeSelectTime('hour', $event.target.value)"
        [value]="hourSelect"
        (focus)="onTimeFocus()"
        [disabled]="isDisabled"
      >
        <option *ngFor="let item of [].constructor(24); let i = index" class="select-option" value="{{ i }}">
          {{ format2Digit(i) }}
        </option>
      </select>
      <i class="fa fa-caret-down"></i>
    </label>
    <span>：</span>
    <label class="spen-mod-select">
      <select
        class="select"
        (change)="changeSelectTime('minute', $event.target.value)"
        [value]="minuteSelect"
        (focus)="onTimeFocus()"
        [disabled]="isDisabled"
      >
        <ng-container *ngFor="let item of [].constructor(60); let i = index">
          <option class="select-option" value="{{ i }}" *ngIf="i % minuteSpan === 0">
            {{ format2Digit(i) }}
          </option>
        </ng-container>
      </select>
      <i class="fa fa-caret-down"></i>
    </label>
  </div>
</div>
