import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { ActivityRecordsListResponse } from '@components/activity-record/activity-records-list-response';

@Injectable()
export class StudentService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getActivityRecord(activityRecordId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}`;
    return this.http.get<ActivityRecord>(url);
  }

  getHistoryActivityRecord(params: {}): Observable<ActivityRecordsListResponse> {
    const url = `${this.apiEndpoint}/student/activity_records/search`;
    return this.http.post<ActivityRecordsListResponse>(url, params);
  }
}
