<div class="request-review" data-cy="request-review">
  <div class="assessment-item">
    <div class="user">
      <img data-cy="cross-student-avatar" [src]="crossAssessment.create_user.avatar" alt="Avatar" />
      <div class="user-name">
        <a
          class="truncate-text"
          data-cy="cross-student-name"
          [class.spen-util-link]="crossAssessment.create_user.viewer_permission"
          [routerLink]="crossAssessment.create_user.viewer_permission ? ['/students/teacher', crossAssessment.create_user.id] : []"
        >
          {{ crossAssessment.create_user.name }}
        </a>
        <p class="student-class truncate-text" data-cy="cross-student-class">
          {{ crossAssessment.create_user.class_name }}{{ crossAssessment.create_user.attendance_number }}番
        </p>
        <p class="time-comment" data-cy="cross-created-at">{{ crossAssessment.created_at }}</p>
      </div>
    </div>

    <div class="content-comment">
      <app-read-more [content]="crossAssessment.content"></app-read-more>
    </div>
  </div>

  <form class="request-review-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-content">
      <img data-cy="review-teacher-avatar" [src]="currentUser.avatar" alt="Avatar" />
      <div class="text-review">
        <textarea
          class="spen-mod-input-text"
          data-cy="review-reason"
          #textArea
          [appPlaceholder]="'見直しを依頼する理由があれば入力してください。'"
          formControlName="review_reason"
          appAutoExpand
        ></textarea>
        <div *ngIf="formErrors['review_reason']" class="spen-mod-error-content">
          {{ formErrors['review_reason'].join(' ') }}
        </div>
      </div>
    </div>
    <div class="list-button">
      <button type="submit" class="spen-mod-btn is-primary pull-right" data-cy="submit-button" [disabled]="isSubmitting || form.invalid">
        依頼する
      </button>
      <button [disabled]="isSubmitting" type="button" class="spen-mod-btn pull-right" data-cy="cancel-button" (click)="cancelEdit()">
        キャンセル
      </button>
    </div>
  </form>
</div>
