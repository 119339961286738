<div class="content">
  <div class="filter" [formGroup]="form" *ngIf="formLoaded">
    <div class="filter-options">
      <div *ngIf="layoutService.isTabletDownView.value">
        <div class="sort-info">
          <span class="order">
            <p>検索条件</p>
            <span data-cy="text-filter" *ngIf="dataLoaded">{{ messageFilter }}</span>
          </span>
          <span class="order">
            <p>並び順</p>
            <span data-cy="text-sort-order" *ngIf="dataLoaded">{{ messageSort }}</span>
          </span>
        </div>
        <span class="btn-show-detail" (click)="showMore()">
          <a class="spen-util-link">
            <span>変更</span>
            <i [ngClass]="[isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down']" data-cy="show-filter"></i>
          </a>
        </span>
      </div>
      <div [ngClass]="toggleFilterOnMobileView()">
        <div class="align-center mark class-selector" [ngClass]="isShowFilter ? 'block-filter-inner' : 'block-filter'">
          <p>校種学年組</p>
          <app-school-grade-class-select [parentForm]="form"></app-school-grade-class-select>
          <span class="btn-show-detail" (click)="showMore()" *ngIf="!layoutService.isTabletDownView.value">
            <a class="spen-util-link">
              <span>詳細検索</span>
              <i [ngClass]="[isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down']" data-cy="show-filter"></i>
            </a>
          </span>
        </div>
        <div [ngClass]="isShowFilter ? 'active' : 'inactive'">
          <div class="block-filter-inner input-search align-center">
            <div class="block-inner">
              <p>種別</p>
              <div *ngIf="!layoutService.isTabletDownView.value">
                <label class="spen-mod-radio" data-cy="activity-type" *ngFor="let activity_type of activityType">
                  <input
                    class="radio"
                    data-cy="activity-type-radio"
                    type="radio"
                    [value]="activity_type.value"
                    formControlName="activity_type"
                  />
                  <i class="fa"></i>
                  <span class="text" data-cy="activity-type-name">{{ activity_type.text }}</span>
                </label>
              </div>
              <div>
                <label class="spen-mod-select" *ngIf="layoutService.isTabletDownView.value">
                  <select class="select" data-cy="activity-type" formControlName="activity_type">
                    <option class="select-option" *ngFor="let activity_type of activityType" [ngValue]="activity_type.value">
                      {{ activity_type.text }}
                    </option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>
            <div class="block-inner">
              <p class="keyword-position">キーワード</p>
              <input
                class="spen-mod-input-text"
                data-cy="search-title"
                type="text"
                maxlength="100"
                placeholder="キーワードを入力してください"
                formControlName="keyword"
              />
            </div>
          </div>
          <div class="block-filter-inner align-center">
            <div class="block-inner">
              <p>カテゴリー</p>
              <app-teacher-categories-selection
                formControlName="category_id"
                [categories]="categoriesService.categories"
                [selectOptionWidth]="'160px'"
              >
              </app-teacher-categories-selection>
            </div>
          </div>
          <div class="block-filter-inner align-center">
            <div class="block-inner">
              <p>コメント状況</p>
              <label class="spen-mod-select">
                <select
                  class="select"
                  data-cy="comment-status"
                  formControlName="comment_status"
                  (change)="selectCommentStatus($event.target.value)"
                >
                  <option class="select-option" *ngFor="let comment_status of commentStatuses" [ngValue]="comment_status.value">
                    {{ comment_status.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
              <label class="spen-mod-select sub-comment-position">
                <select class="select" data-cy="sub-comment-status" formControlName="sub_comment_status">
                  <option class="select-option" *ngFor="let sub_comment_status of subCommentStatuses" [ngValue]="sub_comment_status.value">
                    {{ sub_comment_status.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
            <div class="block-inner">
              <p>公開状況</p>
              <label class="spen-mod-select">
                <select
                  class="select"
                  data-cy="public-status"
                  formControlName="public_status"
                  (change)="selectPublicStatus($event.target.value)"
                >
                  <option class="select-option" *ngFor="let public_status of publicStatuses" [ngValue]="public_status.value">
                    {{ public_status.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
              <label class="spen-mod-select">
                <select class="select" data-cy="sub-public-status" formControlName="sub_public_status">
                  <option class="select-option" *ngFor="let sub_public_status of subPublicStatuses" [ngValue]="sub_public_status.value">
                    {{ sub_public_status.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>
          <div class="mark block-filter align-center">
            <p>マーク</p>
            <div>
              <label
                class="spen-mod-checkbox check-box-inline"
                data-cy="mark-ids"
                formArrayName="mark_ids"
                *ngFor="let mark of marks; let i = index"
              >
                <input class="checkbox" data-cy="mark-ids-checkbox" [formControlName]="i" type="checkbox" />
                <i class="fa"></i>
                <img class="mark-icon-fix-width" data-cy="mark-ids-icon" [src]="mark.icon" />
                <span>{{ mark.name }}</span>
              </label>
            </div>
          </div>
          <div class="block-filter" *ngIf="layoutService.isTabletDownView.value">
            <p class="sort-filter">並び順</p>
            <label class="spen-mod-select">
              <select class="select" data-cy="sort-order" formControlName="sort_value" (change)="onChangeSortValue($event.target.value)">
                <option class="select-option" *ngFor="let sortSelection of sortSelections" [value]="sortSelection.value">
                  {{ sortSelection.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>
        <div class="btn-search">
          <button
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            type="button"
            (click)="resetForm()"
            [disabled]="isLoadingData || isDefaultForm"
          >
            選択解除
          </button>
          <button class="spen-mod-btn is-primary" data-cy="submit-filter" type="button" (click)="onSubmit()" [disabled]="isLoadingData">
            {{ layoutService.isTabletDownView.value ? '適用する' : '検索する' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-info" *ngIf="dataLoaded">
    <p
      class="filter-result-info"
      data-cy="text-filter"
      *ngIf="!layoutService.isTabletDownView.value && (isSearching || haveActiveRecordInDB)"
    >
      検索条件：{{ messageFilter }}
    </p>
    <p class="page-info" data-cy="text-page-info" *ngIf="!inProgressSort && !isEmptySearchResults">{{ metaData | paginateText }}</p>
  </div>
  <div class="activity-records-list" *ngIf="dataLoaded; else loadingIcon">
    <ng-container *ngIf="!layoutService.isTabletDownView.value; else spView">
      <div *ngIf="!isEmptySearchResults; else emptyList" class="spen-mod-flex-table table-list-full has-link">
        <div class="flex-table-head">
          <div class="flex-item col-size-6 is-first-col">活動記録名</div>
          <div class="flex-item col-size-4">作成者</div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-sort" (click)="changeBySortColumn('created_at')"
              >作成日時
              <i [class]="sortStatus['created_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-sort" (click)="changeBySortColumn('updated_at')">
              更新日時
              <i [class]="sortStatus['updated_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item redirect-cell"></div>
        </div>
        <a
          class="flex-table-row"
          data-cy="activity-item"
          *ngFor="
            let activity_record of activityRecords
              | paginate
                : {
                    itemsPerPage: this.metaData.per_page,
                    currentPage: this.metaData.current_page,
                    totalItems: this.metaData.total_count,
                  }
          "
          (click)="onClickActivityRecord(activity_record.id, $event)"
          [class.disabled-item]="!activity_record.student_info.viewer_permission"
        >
          <div class="flex-item col-size-6 is-first-col truncate-text" data-cy="activity-title">{{ activity_record.title }}</div>
          <div class="flex-item col-size-4 author">
            <img class="author-img" data-cy="activity-student-avator" src="{{ activity_record.student_info.avatar }}" />
            <div class="author-info truncate-text">
              <a
                [class.spen-util-link]="!!activity_record.student_info.viewer_permission"
                data-cy="activity-student-name"
                (click)="onClickStudent(activity_record.student_info.id, $event)"
              >
                {{ activity_record.student_info.name }}</a
              >
              <p class="truncate-text" data-cy="activity-class-name">
                {{ activity_record.student_info.className }}{{ activity_record.student_info.attendanceNumber }}番
              </p>
            </div>
          </div>
          <div class="flex-item col-size-3" data-cy="activity-created-at">{{ activity_record.created_at }}</div>
          <div class="flex-item col-size-3" data-cy="activity-updated-at">{{ activity_record.updated_at }}</div>
          <div class="flex-item redirect-cell is-icon-item">
            <i *ngIf="activity_record.student_info.viewer_permission" class="fa fa-chevron-right" data-cy="activity-chevron"></i>
          </div>
        </a>
      </div>
    </ng-container>
    <ng-template #spView>
      <div class="list-activity-records" *ngIf="!isEmptySearchResults; else emptyList">
        <div
          class="activity-record-item"
          data-cy="activity-item"
          *ngFor="
            let activity_record of activityRecords
              | paginate
                : {
                    itemsPerPage: this.metaData.per_page,
                    currentPage: this.metaData.current_page,
                    totalItems: this.metaData.total_count,
                  }
          "
          (click)="onClickActivityRecord(activity_record.id, $event)"
          [ngClass]="!!activity_record.student_info.viewer_permission ? 'hover-background' : 'disabled-row'"
        >
          <div class="record-info">
            <p class="name" data-cy="activity-title">{{ activity_record.title }}</p>
            <img data-cy="activity-student-avator" src="{{ activity_record.student_info.avatar }}" />
            <p data-cy="activity-student-name">{{ activity_record.student_info.name }}</p>
            <p class="grade" data-cy="activity-class-name">
              {{ activity_record.student_info.className }}{{ activity_record.student_info.attendanceNumber }}番
            </p>
            <p class="create-time small-text" data-cy="activity-created-at">作成日時：{{ activity_record.created_at }}</p>
            <p class="update-time small-text" data-cy="activity-updated-at">更新日時：{{ activity_record.updated_at }}</p>
          </div>
          <div class="cell-inner">
            <i *ngIf="activity_record.student_info.viewer_permission" class="fa fa-chevron-right" data-cy="activity-chevron"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="pagination" *ngIf="showPagination">
      <app-pagination-controls *ngIf="!layoutService.isTabletDownView.value" (pageChange)="pageChanged($event)"></app-pagination-controls>
      <app-pagination-controls
        *ngIf="layoutService.isTabletDownView.value"
        (pageChange)="pageChanged($event)"
        directionLinks="false"
        [stylePaginate]="'small'"
      ></app-pagination-controls>
    </div>
    <div class="permission-message" *ngIf="!isEmptySearchResults">
      <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
      <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご相談ください。</div>
    </div>
  </div>
</div>

<ng-template #emptyList>
  <div class="null-content" *ngIf="!classFilterService.isLoading">
    <p class="text">{{ isSearching || haveActiveRecordInDB ? '検索結果が0件です。' : 'ここに校内の活動記録が表示されます。' }}</p>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
