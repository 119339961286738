<div class="student-stories">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <button class="spen-mod-btn is-primary" (click)="slideMenu()"><i class="fa fa-reorder"></i></button>ストーリー
    </h1>
  </div>

  <app-tab-view [tabOptions]="tabOptions"></app-tab-view>
  <ng-container [ngSwitch]="currentTab">
    <app-story-detail *ngSwitchCase="'grade_1'" [grade]="'1'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'grade_2'" [grade]="'2'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'grade_3'" [grade]="'3'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'summary'"></app-story-detail>
  </ng-container>
</div>
