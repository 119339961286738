import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StoryComment } from '@models/story-comment';
import { environment } from '@environments/environment';
import { TeacherStoryCommentsResponse } from './story-comment-list/teacher-story-comments.response';

@Injectable()
export class StoryCommentTeacherService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  loadTeacherStoryComments(params = {}): Observable<TeacherStoryCommentsResponse> {
    const url = `${this.apiEndpointV2}/teacher/story_comments`;
    return this.http.get<TeacherStoryCommentsResponse>(url, { params: params });
  }

  createTeacherStoryComment(params): Observable<any> {
    const url = `${this.apiEndpointV2}/teacher/story_comments`;
    return this.http.post(url, { story_comment: params });
  }

  updateTeacherStoryComment(storyCommentId: number, storyComment: StoryComment): Observable<any> {
    return this.http.put(`${this.apiEndpointV2}/teacher/story_comments/${storyCommentId}`, { story_comment: storyComment });
  }

  deleteTeacherStoryComment(storyCommentId: number): Observable<any> {
    return this.http.delete(`${this.apiEndpointV2}/teacher/story_comments/${storyCommentId}`);
  }
}
