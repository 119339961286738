<header class="spen-ly-global-header fixed-header" id="global-header" *ngIf="!isMobileApp">
  <app-teacher-header></app-teacher-header>
  <app-global-menu></app-global-menu>
</header>

<main class="spen-main-layout" [class.has-header]="!isMobileApp">
  <app-teacher-sidebar [isShowSidebar]="isShowSidebar"></app-teacher-sidebar>

  <div class="prod-ly-content" [class.padding-sidebar]="isShowSidebar">
    <div class="content-container" [class.form-page-container]="!routerDataService.routerData.value.isShowSidebar">
      <div class="heading-margin">
        <app-h1-heading-title class="heading-title"></app-h1-heading-title>
        <app-link-horizontal-transition *ngIf="!layoutService.isTabletDownView.value"></app-link-horizontal-transition>
      </div>
      <app-link-horizontal-transition *ngIf="layoutService.isTabletDownView.value"></app-link-horizontal-transition>

      <app-content></app-content>
    </div>

    <footer *ngIf="authUserService.isLoggedIn && !isMobileApp">
      <app-teacher-footer> </app-teacher-footer>
    </footer>
  </div>
</main>
