import { Injectable } from '@angular/core';

import { Category } from '@models/category';
import { CategoriesService } from '@services/categories/categories.service';
import { MarksService } from '@services/marks/marks.service';
import * as _ from 'lodash';

@Injectable()
export class MultipleFilterService {
  FILER_KEYWORDS = [
    'include_mana_vision',
    'category_id',
    'keyword',
    'has_unread_assessment',
    'activity_type',
    'public_status',
    'comment_status',
  ];
  categories: Array<Category> = [];
  categoriesLoaded = false;
  params = {};
  filtered = false;
  messageFilter = '';
  isShowingFilter = false;

  subCategories = [];

  constructor(
    private categoryService: CategoriesService,
    private marksService: MarksService,
  ) {}

  prepareData() {
    this.categoryService.loadCategories().then((categories) => {
      if (!!categories) {
        this.categories = categories;
        this.categoriesLoaded = true;
      }
    });
  }

  async setFilter(params: {}) {
    this.params = params;
    if (this.hasValueAtKeys(params, this.FILER_KEYWORDS) || (params['mark_ids'] && params['mark_ids'].length > 0)) {
      this.filtered = true;
    } else {
      this.filtered = false;
    }
    const pairMessage = [];
    if (params['keyword'] && params['keyword'].trim()) {
      pairMessage.push(params['keyword']);
    } else {
      delete params['keyword'];
    }

    if (params['has_unread_assessment']) {
      pairMessage.push('未読コメントあり');
    }

    if (params['public_status']) {
      switch (params['public_status']) {
        case 'self_publish':
          pairMessage.push('自主公開');
          break;
        case 'pickup':
          pairMessage.push('ピックアップ');
          break;
        case 'shared':
          pairMessage.push('公開');
          break;
        case 'not_shared':
          pairMessage.push('非公開');
          break;
      }
    }

    if (params['activity_type']) {
      switch (params['activity_type']) {
        case 'free':
          pairMessage.push('自主作成');
          break;
        case 'answer':
          pairMessage.push('課題回答');
          break;
        case 'questionnaire':
          pairMessage.push('アンケート回答');
      }
    }

    if (params['comment_status']) {
      switch (params['comment_status']) {
        case 'has_comment':
          pairMessage.push('コメントあり');
          break;
        case 'no_comment':
          pairMessage.push('コメントなし');
          break;
        case 'cross':
          pairMessage.push('生徒コメント');
          break;
        case 'teacher':
          pairMessage.push('先生コメント');
          break;
        case 'include_current_student':
          pairMessage.push('コメントした');
          break;
        case 'exclude_current_student':
          pairMessage.push('コメントしていない');
          break;
      }
    }

    if (params['category_id']) {
      params['category_id'] = parseInt(params['category_id'], 10);
      const categoryName = await this.categoryService.getCategoryName(params['category_id'], 'filter');
      pairMessage.push(categoryName);
    }

    // This is required because params parsed from URL is of type string "true" or "false".
    if (params['include_mana_vision']) {
      params['include_mana_vision'] = /true/i.test(params['include_mana_vision']);
    }

    this.messageFilter = pairMessage.join('/');

    if (params['mark_ids']) {
      if (!(params['mark_ids'] instanceof Array)) {
        params['mark_ids'] = [params['mark_ids']];
      }
      params['mark_ids'] = params['mark_ids'].map((id) => parseInt(id, 10));
      if (params['mark_ids'].length > 0) {
        this.marksService.getMarks().subscribe((response) => {
          const markElements = response['marks'].filter((mark) => _.includes(params['mark_ids'], mark['id']));
          const markElementsText = markElements.map((mark) => mark['name']).join('/');
          const oldMessage = this.messageFilter ? `${this.messageFilter}/` : '';
          this.messageFilter = `${oldMessage}${markElementsText}`;
        });
      }
    }
  }

  private hasValueAtKeys(object, keys: Array<string>): boolean {
    for (const key of keys) {
      if (!!object[key] && object[key] !== 'null') {
        return true;
      }
    }
    return false;
  }

  getFilterData() {
    return this.params;
  }

  showFilter() {
    this.isShowingFilter = true;
  }

  removeFilter() {
    this.isShowingFilter = false;
    this.params = [];
    this.filtered = false;
  }
}
