import { NgModule } from '@angular/core';
import { ConfirmDialogTeacherComponent } from './teacher/confirm-dialog-teacher.component';
import { ConfirmDialogStudentComponent } from './student/confirm-dialog-student.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ConfirmDialogTeacherComponent, ConfirmDialogStudentComponent, ConfirmDialogComponent],
  exports: [ConfirmDialogComponent],
  imports: [CommonModule],
})
export class ConfirmDialogModule {}
