import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { Category } from '@models/category';
import { Mark } from '@models/mark';
import { CategoriesService } from '@services/categories/categories.service';
import { MarksService } from '@services/marks/marks.service';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { omitBy, pick, isNil } from 'lodash';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-filter-activities',
  templateUrl: './dropdown-filter-activities.component.html',
  styleUrls: ['./dropdown-filter-activities.component.scss'],
})
export class DropdownFilterActivitiesComponent implements OnInit {
  @Input() showManaVisionOption = false;
  @Output() submitFilter = new EventEmitter<{}>();
  @Output() closeFilter = new EventEmitter();

  categories: Array<Category> = [];
  marks: Array<Mark> = [];
  form: UntypedFormGroup;

  errMsg: string;
  selectedMarkIds = [];

  categoriesLoaded = false;
  marksLoaded = false;

  @ViewChild(MarksSelectorComponent) marksSelector: MarksSelectorComponent;

  constructor(
    private renderer: Renderer2,
    private categoryService: CategoriesService,
    private marksService: MarksService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.loadCategories();
    this.loadMarks();
    this.initForm();
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        this.marksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadCategories() {
    this.categoryService.loadCategories().then((categories) => {
      if (!!categories) {
        this.categories = categories;
        this.categoriesLoaded = true;
      }
    });
  }

  eventChangeMark(marks: Object) {
    this.onSubmit();
  }

  onCloseFilter() {
    this.closeFilter.emit();
  }

  initForm() {
    this.form = this.formBuilder.group({
      keyword: null,
      category_id: null,
      comment_status: null,
      activity_type: null,
      sub_comment_status: [{ value: 'has_comment', disabled: true }],
    });
    if (this.showManaVisionOption) {
      this.form.addControl('include_mana_vision', new UntypedFormControl(false));
    }
    this.form.get('comment_status').valueChanges.subscribe((data) => {
      this.selectCommentStatus(data);
    });
    this.form.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.onSubmit();
    });
  }

  onSubmit() {
    let params = omitBy(Object.assign({}, this.form.value), (value) => isNil(value));

    if (this.includingManaVisions) {
      params = pick(params, ['category_id', 'include_mana_vision']);
    } else {
      params['mark_ids'] = this.marksSelector.selectedMarks.filter((mark) => !!mark.checked).map((mark) => mark.id);
      if (!!params['comment_status'] && !!params['sub_comment_status'] && params['sub_comment_status'] !== 'has_comment') {
        params['comment_status'] = params['sub_comment_status'];
      }
      delete params['sub_comment_status'];
    }

    this.submitFilter.emit(params);
  }

  selectCommentStatus(value: Object) {
    const options = { emitEvent: false, onlySelf: true };
    if (value !== 'has_comment') {
      this.form.get('sub_comment_status').disable(options);
    } else {
      this.form.get('sub_comment_status').enable(options);
    }
    this.form.get('sub_comment_status').patchValue('has_comment', options);
  }

  get dataLoaded(): boolean {
    return this.categoriesLoaded && this.marksLoaded;
  }

  get includingManaVisions(): boolean {
    return this.showManaVisionOption && this.form.get('include_mana_vision').value;
  }
}
