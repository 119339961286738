import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { SpaceService } from '../../space.service';
import { Space } from '../../space';
import { SlideMenuService } from '@services/slide-menu.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { isMobileApp } from '@functions/browser';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-spaces-list-student',
  templateUrl: './spaces-list-student.component.html',
  styleUrls: ['./spaces-list-student.component.scss'],
})
export class SpacesListStudentComponent implements OnInit {
  spaces: Array<Space>;
  dataLoaded = false;
  readonly classiUrl = environment.platformUrl;
  paginationLoaded = false;
  errorMsg: string;
  pages = {
    per_page: 1,
    current_page: 1,
    total_pages: 1,
    total_count: 0,
    start_item_index: 0,
    end_item_index: 0,
  };

  constructor(
    private spaceService: SpaceService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private slideMenuService: SlideMenuService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const pageIndex = +params['page'] || 1;
      this.getSpacesList({ page: pageIndex });
    });
  }

  getSpacesList(params: Object) {
    this.spaceService.getStudentSpacesList(params).subscribe(
      (response) => {
        this.spaces = response['spaces'];
        Object.assign(this.pages, response['pages']);
        this.dataLoaded = true;
        if (this.spaces.length > 0) {
          this.paginationLoaded = true;
        }
      },
      (error) => {
        this.errorMsg = <any>error;
        this.spaces = [];
      },
    );
  }

  get spacesCount() {
    return this.spaces.length;
  }

  get showPagination(): boolean {
    return this.paginationLoaded && this.pages && this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    this.router.navigate(['.'], { queryParams: { page: page }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  slideMenu() {
    this.slideMenuService.slideIn();
  }

  sendNewDocumentGAEvent() {
    this.googleAnalytics4Service.sendEvent('スペース', '一覧画面', '活動記録作成');
  }

  get isMobileApp() {
    return isMobileApp();
  }
}
