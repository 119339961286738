import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from '../notification.service';
import { Notification } from '../notification';
import { parseJsonList } from '@functions/parse-json-list';

@Component({
  selector: 'app-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.scss'],
})
export class ShowNotificationComponent implements OnInit {
  notifications: Array<Notification>;
  nextUrl: string;
  errorMessages: string;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getFirstNotifications();
  }

  getFirstNotifications() {
    this.notificationService.getNotifications().subscribe(
      (res) => {
        this.notifications = parseJsonList(Notification, res['notifications']);
        this.nextUrl = res['next_url'] || null;
      },
      (error) => {
        this.notifications = [];
        this.errorMessages = <any>error;
      },
    );
  }

  getMoreNotifications() {
    if (this.nextUrl) {
      this.notificationService.getMoreNotifications(this.nextUrl).subscribe(
        (res) => {
          parseJsonList(Notification, res['notifications']).forEach((notification) => this.notifications.push(notification));
          this.nextUrl = res['next_url'] || null;
        },
        (error) => {
          this.errorMessages = <any>error;
        },
      );
    }
  }

  readNotification(notification: Notification) {
    if (notification.isActionNewWork || notification.isActionRemindWork) {
      if (notification.work['status'] === 'answered') {
        this.router.navigate(['/works', notification.work.id]);
      } else {
        this.router.navigate(['/works', notification.work.id, 'answer']);
      }
    }

    if (notification.is_read === false) {
      notification.is_read = true;
    }
  }
}
