import { Component, Input, ViewChild } from '@angular/core';
import { Page, PaginationControlsComponent, PaginationControlsDirective } from 'ngx-pagination';
import { isTouchDevice } from '@functions/browser';

@Component({
  selector: 'app-pagination-controls',
  templateUrl: './app-pagination-controls.component.html',
  styleUrls: ['./app-pagination-controls.component.scss'],
})
export class AppPaginationControlsComponent extends PaginationControlsComponent {
  @Input() stylePaginate = 'normal';
  @ViewChild('p', { static: true }) paginate: PaginationControlsDirective;

  // quantity of number page item, ex: 1,2 3,4 ; not '...'
  LIMIT_PAGE_ITEM = 5;

  get isTouchDevice() {
    return isTouchDevice();
  }

  onPageClick(page: number | string) {
    if (typeof page === 'number') {
      this.paginate.setCurrent(page);
    } else {
      page === 'previous' ? this.paginate.previous() : this.paginate.next();
    }

    window.scrollTo(0, 0);
  }

  get pages(): Array<Page> {
    return this.paginate.pages.filter((page) => page.value !== this.hidePageItem || this.isGap(page));
  }

  isGap(page): boolean {
    if (page.label === '...') {
      return true;
    }

    if (this.ellipsesNummber === 1) {
      return (this.currentPage < 4 && page.value === this.totalPage - 1) || (this.currentPage > this.totalPage - 3 && page.value === 2);
    }

    return this.ellipsesNummber === 2 && Math.abs(page.value - this.currentPage) === 2;
  }

  /*
   * Because in library of ngxPagination limit item includes '...' item
   * Example: 1, ..., 7, x, 9, ..., 20
   * So when have 7 page, library will show 1, 2, 3, 4, 5, 6, 7
   *
   * However in design limit of number page item is 5, mean 1, 2, 3, 4 ,..., 7
   * So i want to delete redundant elements displayed by the library
   */
  get hidePageItem(): number {
    switch (this.ellipsesNummber) {
      case 0:
      case 2:
        return null;
      case 1:
        if (this.currentPage < 4) {
          return this.LIMIT_PAGE_ITEM;
        }
        if (this.currentPage > this.totalPage - 3) {
          return this.totalPage - 4;
        }
    }
  }

  // quantity of '...' item
  get ellipsesNummber(): number {
    // Example : 1 2 3 4 , 1 2 3 4 5
    if (this.totalPage <= this.LIMIT_PAGE_ITEM) {
      return 0;
    }

    /*
     *Example   1 2 x 4 ... 6 , 1 2 x 4 ... 9
     *Example   1 ... 3 x 5 6 , 1 ... 7 8 9 x
     */
    if (this.currentPage < 4 || this.currentPage > this.totalPage - 3) {
      return 1;
    }
    return 2;
  }

  get totalPage(): number {
    return this.paginate.getLastPage();
  }

  get currentPage(): number {
    return this.paginate.getCurrent();
  }
}
