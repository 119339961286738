<div class="stu-add-goal-outline" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page">
    <h1 class="heading-text">
      <a (click)="onBack()"> <i class="fa fa-chevron-left"></i> </a>アルバム編集
    </h1>
  </div>
  <div class="block-standard">
    <div class="header-title">
      <div class="icon">
        <img src="{{ iconAlbumUrl }}" />
      </div>
      <div>
        <h2 class="heading-text">{{ album.title }}</h2>
      </div>
    </div>

    <div class="block-user" *ngIf="isAlbumCreatedByTeacher">
      <div class="user-info">
        <img alt="avatar" src="{{ album.user?.avatar }}" />
        <div class="text-info">
          <p>{{ album.created_at }} 配信</p>
          <p>{{ album.user?.name }} 先生</p>
        </div>
      </div>
      <div class="box-comment" *ngIf="album.comment">
        <span class="tip-up"></span>
        <span class="tip-up-large"></span>
        <p appConvertLink>{{ album.comment }}</p>
      </div>
    </div>

    <div class="block-form">
      <form class="form" [formGroup]="form">
        <div class="form-horizontal">
          <div class="form-group">
            <div class="row">
              <div class="col-md-3 col-xs-12">
                <p class="label-input">目標</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <textarea [appPlaceholder]="'目標'" formControlName="goal"></textarea>
                <div *ngIf="formErrors.goal" class="spen-mod-error-content">
                  {{ formErrors['goal'].join(' ') }}
                </div>
                <p class="text-num pull-right">
                  文字数 <span class="spen-ly-form-document is-num-character">{{ form.get('goal').value.length }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-3 col-xs-12">
                <p class="label-input">概要</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <textarea [appPlaceholder]="'概要'" formControlName="outline"></textarea>
                <div *ngIf="formErrors.outline" class="spen-mod-error-content">
                  {{ formErrors['outline'].join(' ') }}
                </div>
                <p class="text-num pull-right">
                  文字数 <span class="spen-ly-form-document is-num-character">{{ form.get('outline').value.length }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="block-buttons">
      <div class="btn-submit">
        <button class="spen-mod-btn is-primary" (click)="submitForm()" [disabled]="isSubmitting">
          {{ isEdit ? '編集を完了する' : '入力を完了する' }}
        </button>
      </div>
      <div class="btn-cancel">
        <button class="spen-mod-btn" (click)="onBack()">戻る</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
