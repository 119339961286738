<ng-container *ngIf="getWorkType === 'multiple'; else singleWork">
  <ng-container *ngFor="let answer of document.document_answers; let i = index">
    <div class="question-answer" data-cy="work-question-item">
      <div class="question-number" data-cy="work-question-num">
        設問{{ i + 1 }}
        <span *ngIf="isCompassAnswer" class="required">必須</span>
      </div>
      <div class="block-element">
        <p class="preserve-line-break" data-cy="work-question-content" *ngIf="!isReadMore; else readMoreWorkQuestion">
          {{ answer.work_question.content.description || answer.work_question.content }}
        </p>

        <ng-template #readMoreWorkQuestion>
          <app-read-more
            [content]="answer.work_question.content.description || answer.work_question.content"
            [collapsedLength]="149"
          ></app-read-more>
        </ng-template>

        <div *ngIf="answer.work_question.image" class="question-image">
          <img data-cy="work-question-image" src="{{ answer.work_question.image.thumbnail }}" />
        </div>

        <p class="answer-title">回答</p>
        <p class="preserve-line-break" data-cy="work-question-answer" *ngIf="!isReadMore; else readMoreWorkAnswer">{{ answer.content }}</p>

        <ng-template #readMoreWorkAnswer>
          <app-read-more [content]="answer.content" [collapsedLength]="149"></app-read-more>
        </ng-template>

        <div class="answer-images" *ngIf="answer.upload_files.length > 0">
          <app-files-view-teacher [files]="answer.upload_files"></app-files-view-teacher>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #singleWork>
  <p class="content-title">本文</p>
  <div class="block-element">
    <p class="preserve-line-break" data-cy="work-content" appConvertLink *ngIf="!isReadMore; else readMoreSingleWork">
      {{ document.content }}
    </p>

    <ng-template #readMoreSingleWork>
      <app-read-more [content]="document.content" [collapsedLength]="999"></app-read-more>
    </ng-template>

    <div class="thumb">
      <app-files-view-teacher [files]="document?.upload_files"></app-files-view-teacher>
    </div>
  </div>
</ng-template>
<div class="category-marks" *ngIf="hasCategory || hasMarks">
  <div class="category-text" *ngIf="hasCategory">
    <p data-cy="category-name">{{ categoryName }}</p>
  </div>
  <div class="marks" *ngIf="hasMarks">
    <div *ngFor="let mark of marks" class="mark">
      <img data-cy="mark-icon" [src]="mark.icon" />
      <p data-cy="mark-name">{{ mark.name }}</p>
    </div>
  </div>
</div>
