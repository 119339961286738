import { Component, Input } from '@angular/core';
import { PublicActivityRecord } from '@components/activity-record/public-activity-record';

@Component({
  selector: 'app-student-public-activity-item',
  templateUrl: './student-public-activity-item.component.html',
  styleUrls: ['./student-public-activity-item.component.scss'],
})
export class StudentPublicActivityItemComponent {
  @Input() activityRecord: PublicActivityRecord;

  constructor() {}

  get userNameDisplay() {
    let userNameDisplay;
    if (this.activityRecord.user) {
      userNameDisplay = this.activityRecord.user.name;
      if (this.activityRecord.is_owner_anonymous_activity) {
        userNameDisplay += ' (匿名)';
      }
    } else {
      userNameDisplay = '匿名';
    }
    return userNameDisplay;
  }
}
