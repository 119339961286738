import { Component, Input } from '@angular/core';
import { Document } from '../../../../components/document/document';
import { Mark } from '@models/mark';
import { AuthUserService } from '../../../services/auth-user.service';

@Component({
  selector: 'app-document-detail-teacher',
  templateUrl: './document-detail-teacher.component.html',
  styleUrls: ['./document-detail-teacher.component.scss'],
})
export class DocumentDetailTeacherComponent {
  @Input() document: Document;
  @Input() categoryName: string;
  @Input() workType: string;
  @Input() marks: Array<Mark> = [];
  @Input() isCompassAnswer: boolean;
  @Input() isReadMore: boolean;

  constructor(public authUserService: AuthUserService) {}

  get getWorkType(): string {
    if (!this.workType) {
      this.workType = this.document.document_answers && this.document.document_answers.length ? 'multiple' : 'single';
    }
    return this.workType;
  }

  get hasCategory(): boolean {
    return !!this.categoryName;
  }

  get hasMarks(): boolean {
    return !!this.marks && this.marks.length > 0;
  }
}
