import { Injectable } from '@angular/core';

import { checkPresentString } from '@functions/check-string';

import { CategoriesService } from '@services/categories/categories.service';
import { TeacherMarksService } from '@services/marks/teacher-marks.service';

@Injectable({
  providedIn: 'root',
})
export class TeacherFilterService {
  FILER_KEYWORDS = [
    'school_year',
    'grade',
    'class',
    'search_key',
    'distributor',
    'keyword',
    'category_id',
    'activity_type',
    'public_status',
    'comment_status',
    'album_type',
    'title',
    'submission_status',
  ];
  messageFilter = '';

  constructor(
    private categoriesService: CategoriesService,
    private marksService: TeacherMarksService,
  ) {}

  async setFilter(params: {}) {
    if (!this.haveFilterData(params)) {
      this.messageFilter = 'すべて';
      return;
    }

    const pairMessage = [];

    if (params['class']) {
      pairMessage.push(params['school_year'] + '年度', params['grade'], params['class']);
    }

    if (params['distributor']) {
      switch (params['distributor']) {
        case 'myself':
          pairMessage.push('自分');
          break;
        case 'other_teachers':
          pairMessage.push('自分以外の先生');
          break;
      }
    }

    if (params['activity_type']) {
      switch (params['activity_type']) {
        case 'free':
          pairMessage.push('自主作成');
          break;
        case 'answer':
          pairMessage.push('課題回答');
          break;
        case 'questionnaire':
          pairMessage.push('アンケート回答');
      }
    }

    if (params['submission_status']) {
      switch (params['submission_status']) {
        case 'all':
          pairMessage.push('すべて');
          break;
        case 'unanswered':
          pairMessage.push('未回答');
          break;
        case 'draft':
          pairMessage.push('回答中');
          break;
      }
    }

    if (params['album_type']) {
      switch (params['album_type']) {
        case 'all':
          pairMessage.push('すべて');
          break;
        case 'self_create':
          pairMessage.push('自主作成');
          break;
        case 'teacher_create':
          pairMessage.push('先生配信');
          break;
      }
    }

    if (checkPresentString(params['keyword'])) {
      pairMessage.push(params['keyword']);
    } else {
      delete params['keyword'];
    }

    if (params['title'] && params['title'].trim()) {
      pairMessage.push(params['title'].trim());
    } else {
      delete params['title'];
    }

    // TODO: rename all params 'search_key' to `keyword`
    if (params['search_key'] && params['search_key'].trim()) {
      pairMessage.push(params['search_key']);
    } else {
      delete params['search_key'];
    }

    if (params['category_id']) {
      params['category_id'] = parseInt(params['category_id'], 10);
      const categoryName = await this.categoriesService.getCategoryName(params['category_id'], 'filter');
      pairMessage.push(categoryName);
    }

    if (params['comment_status']) {
      switch (params['comment_status']) {
        case 'has_comment':
          pairMessage.push('コメントあり');
          break;
        case 'no_comment':
          pairMessage.push('コメントなし');
          break;
        case 'cross':
          pairMessage.push('生徒コメント');
          break;
        case 'teacher':
          pairMessage.push('先生コメント');
          break;
        case 'include_current_student':
          pairMessage.push('コメントした');
          break;
        case 'exclude_current_student':
          pairMessage.push('コメントしていない');
          break;
      }
    }

    if (params['public_status']) {
      switch (params['public_status']) {
        case 'self_publish':
          pairMessage.push('自主公開');
          break;
        case 'pickup':
          pairMessage.push('ピックアップ');
          break;
        case 'shared':
          pairMessage.push('公開');
          break;
        case 'not_shared':
          pairMessage.push('非公開');
          break;
      }
    }

    if (params['mark_ids']) {
      // convert incase there's only 1 mark in params: `mark_ids: 1` => `mark_ids: [1]`
      if (!(params['mark_ids'] instanceof Array)) {
        params['mark_ids'] = [params['mark_ids']];
      }
      params['mark_ids'] = params['mark_ids'].map((id) => parseInt(id, 10));

      const marksMessages = this.marksService.marksSource.value
        .filter((mark) => params['mark_ids'].includes(mark['id']))
        .map((mark) => mark['name']);
      pairMessage.push(...marksMessages);
    }

    this.messageFilter = pairMessage.join('/');
  }

  private haveFilterData(params) {
    return this.hasValueAtKeys(params, this.FILER_KEYWORDS) || (params['mark_ids'] && params['mark_ids'].length > 0);
  }

  private hasValueAtKeys(object, keys: Array<string>): boolean {
    for (const key of keys) {
      if (!!object[key] && object[key] !== 'null') {
        return true;
      }
    }
    return false;
  }
}
