import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIconClass',
})
export class FileIconClassPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const fileExtension = (value.toLowerCase().match(/\.([^.]*)$/) || [])[1];
    switch (true) {
      case /(txt|csv)/.test(fileExtension):
        return 'fa fa-file-text-o';
      case /(doc|docx)/.test(fileExtension):
        return 'fa fa-file-word-o';
      case /(xls|xlsx)/.test(fileExtension):
        return 'fa fa-file-excel-o';
      case /(ppt|pptx|ppsx)/.test(fileExtension):
        return 'fa fa-file-powerpoint-o';
      case /pdf/.test(fileExtension):
        return 'fa fa-file-pdf-o';
      case /(jpg|jpeg|gif|png)/.test(fileExtension):
        return 'fa fa-file-image-o';
      case /(mp4|mpeg4|3gp|mov|avi)/.test(fileExtension):
        return 'fa fa-file-video-o';
      case /(mp3|m4a|wma)/.test(fileExtension):
        return 'fa fa-file-audio-o';
      case /zip/.test(fileExtension):
        return 'fa fa-file-archive-o';
      default:
        return 'fa fa-file-o';
    }
  }
}
