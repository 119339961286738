import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterService } from '@services/router.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { AlbumService } from '@components/album/album.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

import { Album } from '@models/album';

import { validateForm } from '@functions/validate-form';

@Component({
  selector: 'app-form-add-goal-album',
  templateUrl: './form-add-goal-album.component.html',
  styleUrls: ['./form-add-goal-album.component.scss'],
})
export class FormAddGoalAlbumComponent implements OnInit, OnDestroy {
  album: Album;
  form: UntypedFormGroup;

  isSubmitted = false;
  isSubmitting = false;
  albumLoaded = false;

  albumId: number;

  errMsg: string;
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    goal: {
      maxlength: '目標は1000文字以内で入力してください。',
    },
    outline: {
      maxlength: '概要は1000文字以内で入力してください。',
    },
  };
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private albumService: AlbumService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    private analytics4Service: GoogleAnalytics4Service,
  ) {}

  ngOnInit() {
    this.albumId = +this.activatedRoute.snapshot.params['id'];
    this.getAlbumInfo();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty && !this.isSubmitted) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  get iconAlbumUrl() {
    return this.album.image ? this.album.image.thumbnail : 'assets/images/icons/album.svg';
  }

  get isAlbumCreatedByTeacher() {
    return this.album.creator_type === 'teacher';
  }

  get isEdit() {
    return this.album.student_goal || this.album.student_outline;
  }

  getAlbumInfo() {
    this.albumService.getAlbum(this.albumId).subscribe(
      (response) => {
        this.album = response;
        this.prepareAlbumForm(this.album);
        this.albumLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  submitForm() {
    if (this.form.valid) {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      this.albumService.studentSetAlbumGoal(this.albumId, this.form.value).subscribe(
        (response) => {
          this.analytics4Service.sendEvent('アルバム', '配信アルバム編集画面', this.isEdit ? '編集を完了する' : '入力を完了する');
          this.isSubmitted = true;
          this.routerService.goBackSpecific(`/albums/student/${this.albumId}`);
        },
        (error) => {
          this.errMsg = <any>error;
          this.isSubmitting = false;
        },
      );
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  prepareAlbumForm(album: Album) {
    this.form = this.formBuilder.group({
      goal: [album.student_goal || '', [Validators.maxLength(1000)]],
      outline: [album.student_outline || '', [Validators.maxLength(1000)]],
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  dataLoaded() {
    return this.albumLoaded;
  }

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }
}
