import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Action,
  ActivityRecordTemplateFormService,
} from '@components/activity-record-templates/teacher/form/activity-record-template-form.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImagesService } from '@services/images/images.service';
import { WorkTemplate } from '@models/work-template';
import { WorkTemplateQuestion } from '@models/work-template-question';
import { validateForm } from '@functions/validate-form';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '@components/layout/layout.service';
import { ActivityRecordTemplatesService } from '@components/activity-record-templates/activity-record-templates.service';
import { ToastService } from '@services/toast/toast.service';

const MaxFileSizeInMB = 50;
const MaxFileSizeInByte = MaxFileSizeInMB * 1024 * 1024;

@Component({
  selector: 'app-record-template-step1',
  templateUrl: './record-template-step1.component.html',
  styleUrls: ['../activity-record-template-form.component.scss'],
})
export class RecordTemplateStep1Component implements OnInit, OnDestroy {
  formInitialized = false;
  @Input() workTemplate: WorkTemplate;
  removedWorkTemplates: Array<UntypedFormGroup> = [];
  form: UntypedFormGroup;
  formErrors = {};
  validationMessages = {
    title: {
      required: '活動記録テンプレート名を入力してください。',
      maxlength: '活動記録テンプレート名は50文字以内で入力してください。',
    },
    work_template_questions_attributes: {
      content: {
        required: '設問文を入力してください。',
        maxlength: '設問文は5000文字以内で入力してください。',
      },
    },
  };
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br>破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };
  dataConfirmDelete = {
    title: '確認',
    content: '一度削除した活動記録テンプレートは復元することができません。<br> 本当に削除しますか？',
    acceptButton: '削除する',
    cancelButton: 'キャンセル',
  };
  errorMsg: string;
  isDeleting = false;

  constructor(
    private confirmDialogService: ConfirmDialogService,
    private formBuilder: UntypedFormBuilder,
    private imagesService: ImagesService,
    public formService: ActivityRecordTemplateFormService,
    private routerService: RouterService,
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    private templateService: ActivityRecordTemplatesService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    const previousStep = this.formService.previousStep;
    if (previousStep === 2) {
      this.workTemplate = this.formService.workTemplate;
      this.initForm();
    } else {
      const editTemplateId = +this.route.snapshot.params.id;
      if (editTemplateId) {
        this.formService.action = Action.Edit;
        this.formService.editTemplateId = editTemplateId;
        this.loadWorkTemplateForEdit(editTemplateId);
      } else {
        this.route.queryParams.subscribe((queryParams) => {
          const cloneTemplateId = +queryParams['template_id'];
          if (cloneTemplateId) {
            this.formService.action = Action.Clone;
            this.loadWorkTemplateForClone(cloneTemplateId);
          } else {
            this.formService.action = Action.New;
            this.initWorkTemplate();
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  initForm() {
    const workTemplate = this.workTemplate;
    let workTemplateQuestions = [];
    if (workTemplate.work_template_questions_attributes) {
      workTemplateQuestions = workTemplate.work_template_questions_attributes.map((question, _) => this.initWorkQuestion(question));
    }

    this.form = this.formBuilder.group({
      title: [workTemplate.title || '', [Validators.required, Validators.maxLength(50)]],
      work_template_questions_attributes: this.formBuilder.array(workTemplateQuestions),
    });

    this.form.valueChanges.subscribe(() => {
      this.formErrors = validateForm(this.form, true, this.validationMessages);
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    });
    if (this.formService.previousStep === 2) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    }
    this.formInitialized = true;
  }

  onSelectFile($event, question) {
    const file = $event.target.files[0];
    if (!file) {
      return;
    }

    const isAllowedExtensions = this.imagesService.isAllowedImageExtensions(file);
    const isAllowedImageSizes = this.imagesService.isAllowedImageSizes(file, MaxFileSizeInByte);
    if (isAllowedExtensions && isAllowedImageSizes) {
      delete question['image_error'];
      question.controls.image_local.setValue(file);
      question.controls.image_attributes.setValue(null);
    } else {
      const isImageButTooLarge = isAllowedExtensions && !isAllowedImageSizes;
      question['image_error'] = isImageButTooLarge
        ? `画像の大きさは${MaxFileSizeInMB}MB以下にしてください。`
        : 'ファイル拡張子が未対応です。';
    }

    $event.target.value = null;
  }

  initWorkQuestion(question: WorkTemplateQuestion) {
    if (!this.formService.previousStep && question.image) {
      question.image_attributes = question.image;
      question.image_attributes['_destroy'] = false;
    }

    const itemQuestionForm = {
      id: question.id,
      content: [question.content || '', [Validators.required, Validators.maxLength(5000)]],
      order: [null],
      image_attributes: [question.image_attributes || null],
      image_local: [question.image_local || null],
      _destroy: [question['_destroy'] || false],
    };
    return this.formBuilder.group(itemQuestionForm);
  }

  loadWorkTemplateForClone(id: number) {
    this.templateService.getWorkTemplate(id).subscribe(
      (response) => {
        this.workTemplate = response;
        this.workTemplate.work_template_questions_attributes = this.workTemplate.questions;
        this.initForm();
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  loadWorkTemplateForEdit(id: number) {
    this.templateService.getWorkTemplateEdit(id).subscribe(
      (response) => {
        this.workTemplate = response;
        this.workTemplate.work_template_questions_attributes = this.workTemplate.questions;
        this.initForm();
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  initWorkTemplate() {
    this.workTemplate = new WorkTemplate();
    this.workTemplate.work_template_questions_attributes = [new WorkTemplateQuestion()];
    this.initForm();
  }

  get formQuestions(): Array<UntypedFormGroup> {
    return this.form.get('work_template_questions_attributes')['controls'];
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (!this.isFormInvalid) {
      this.formService.workTemplate = this.form.getRawValue();
      this.formService.removedWorkTemplates.push(...this.removedWorkTemplates);
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  private get isFormInvalid(): boolean {
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name === 'work_template_questions_attributes') {
          return controls[name]['controls'].some((control) => control.invalid && !control.value['_destroy']);
        } else {
          return true;
        }
      }
    }
    return false;
  }

  deleteTemplate() {
    this.confirmDialogService.showConfirm(this.dataConfirmDelete).subscribe((value) => {
      if (value) {
        this.isDeleting = true;
        this.templateService.destroyWorkTemplate(this.formService.editTemplateId).subscribe(
          (response) => {
            this.form.markAsPristine();
            this.router.navigateByUrl(`/activity-record-templates/teacher`, { replaceUrl: true });
            this.toastService.showToast('削除しました');
          },
          (error) => {
            this.errorMsg = <any>error;
            this.isDeleting = false;
          },
        );
      }
    });
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], {
      queryParams: { step: 2 },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
    this.formService.setStep2State();
  }

  goBack() {
    this.routerService.goBack(this.backConfirmMessages);
  }

  removeImage(question: UntypedFormGroup) {
    if (question.controls.image_attributes.value) {
      question.controls.image_attributes.value['_destroy'] = true;
    }
    question.controls.image_local.reset();
  }

  addQuestionNode() {
    const control = <UntypedFormArray>this.form.controls['work_template_questions_attributes'];
    control.push(this.initWorkQuestion(new WorkTemplateQuestion()));
  }

  removeQuestion(index: number) {
    if (this.formQuestions.length > 1) {
      this.formQuestions[index].controls['_destroy'].setValue(true);
      this.removedWorkTemplates.push(this.formQuestions[index].value);
      this.formQuestions.splice(index, 1);
      this.formErrors['work_template_questions_attributes'].splice(index, 1);
    }
  }

  isDeactiveUpCaret(index: number) {
    return index === 0;
  }

  isDeactiveDownCaret(index: number) {
    return index === this.formQuestions.length - 1;
  }

  upPositionQuestion(index: number) {
    if (this.isDeactiveUpCaret(index)) {
      return false;
    }

    const targetQuestionNode = this.formQuestions[index - 1];
    this.formQuestions[index - 1] = this.formQuestions[index];
    this.formQuestions[index] = targetQuestionNode;
    this.formErrors = validateForm(this.form, true, this.validationMessages);
  }

  downPositionQuestion(index: number) {
    if (this.isDeactiveDownCaret(index)) {
      return false;
    }

    const targetQuestionNode = this.formQuestions[index + 1];
    this.formQuestions[index + 1] = this.formQuestions[index];
    this.formQuestions[index] = targetQuestionNode;
    this.formErrors = validateForm(this.form, true, this.validationMessages);
  }

  getFormErrorsQuestionIndex(index: number) {
    const questionsErrors = this.formErrors['work_template_questions_attributes'];
    return questionsErrors && questionsErrors[index] ? questionsErrors[index] : {};
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  hasImageToBeSaved(questionControls: UntypedFormGroup) {
    return questionControls['image_local'].value || this.hasImageToBeUpdated(questionControls);
  }

  hasImageToBeUpdated(questionControls: UntypedFormGroup) {
    return questionControls['image_attributes'].value && !questionControls['image_attributes'].value['_destroy'];
  }
}
