import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

// [NOTE]
// Dateデータに`day`が含まれるプロパティは、NG Bootstrap で定義している NgbDateStruct という型で扱う
// https://github.com/ng-bootstrap/ng-bootstrap/blob/63003f20b59488a2b742c4b7b3a97d0a07898f13/src/datepicker/ngb-date-struct.ts
export interface MonthDateStruct {
  year: number;
  month: number;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'], // , '../../atoms/text-box/text-box.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input()
  maxDate: NgbDateStruct;
  @Input()
  minDate: NgbDateStruct;
  @Input()
  selectedDate: NgbDateStruct;
  @Input()
  selectedWeek: { fromDate: NgbDateStruct; toDate: NgbDateStruct };
  @Input()
  fromDate: NgbDateStruct;
  @Input()
  toDate: NgbDateStruct;
  @Input()
  isWeekly: boolean;
  @Input()
  isPopup: boolean;
  @Input()
  isFixedDropdown: boolean;
  @Input()
  isRightAlignOnMobile: boolean;
  @Input()
  includeResponsive: boolean;
  @Input()
  disabled: boolean;

  @Output()
  modelChange = new EventEmitter<object>();
  @Output()
  selectedWeekChange = new EventEmitter<object>();

  @ViewChild('d') dateInput: NgbInputDatepicker;

  model: NgbDateStruct;
  today: NgbDate;
  startDate: MonthDateStruct;
  currentMonth: MonthDateStruct;
  placehoderText: string;

  constructor(private calendar: NgbCalendar) {}

  ngOnInit() {
    this.today = this.calendar.getToday();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentSelectedDate = changes['selectedDate'];
    if (currentSelectedDate && currentSelectedDate.currentValue) {
      this.model = currentSelectedDate.currentValue;
      this.currentMonth = this.startDate = {
        month: currentSelectedDate.currentValue.month,
        year: currentSelectedDate.currentValue.year,
      };
    }
  }

  onMonthChange($event) {
    this.currentMonth = $event.next;
  }

  onNgModelChange(selectedDay: NgbDateStruct) {
    this.modelChange.emit(this.model);

    if (this.isWeekly) {
      const convertedModel = NgbDate.from(this.model);
      const weekDay = this.calendar.getWeekday(convertedModel);

      this.selectedWeekChange.emit({
        // 選択した週の月曜日を取得
        fromDate: this.calendar.getPrev(convertedModel, 'd', weekDay - 1),
        // 選択した週の日曜日を取得
        toDate: this.calendar.getNext(convertedModel, 'd', 7 - weekDay),
      });
    }
    this.closePicker();
  }

  canGoToNextMonth() {
    if (!this.maxDate) {
      return true;
    }

    const maxDateTime = new Date(this.maxDate.year, this.maxDate.month).getTime();
    const dateTime = new Date(this.currentMonth.year, this.currentMonth.month).getTime();

    return maxDateTime > dateTime;
  }

  canGoToPrevMonth() {
    if (!this.minDate) {
      return true;
    }

    const minDateTime = new Date(this.minDate.year, this.minDate.month).getTime();
    const dateTime = new Date(this.currentMonth.year, this.currentMonth.month).getTime();

    return dateTime > minDateTime;
  }

  isToday(date: NgbDate) {
    return this.today.equals(date);
  }

  isOtherMonthDay(date: NgbDateStruct) {
    return date.month !== this.currentMonth.month;
  }

  isSelectedWeek(date: NgbDate) {
    if (!this.isWeekly) {
      return false;
    }
    return date === this.selectedWeek.fromDate || date === this.selectedWeek.toDate || this.isInside(date);
  }

  isInside(date: NgbDate) {
    return date < this.selectedWeek.fromDate; // && date.before > this.selectedWeek.toDate;
  }

  closePicker() {
    if (this.dateInput.isOpen()) {
      this.dateInput.close();
    }
  }

  disablePicker(placehoder = null) {
    this.disabled = true;
    this.model = null;
    this.placehoderText = placehoder;
  }
}
