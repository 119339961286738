<div class="tea-graduation-message-form">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form [formGroup]="form" *ngIf="formInited; else loadingIcon" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="list-form">
        <div class="form-group">
          <div class="row align-baseline">
            <div class="column-title"><label class="label">タイトル</label><span class="spen-mod-badge is-red">必須</span></div>
            <div class="column-content">
              <input
                class="spen-mod-input-text"
                type="text"
                [appPlaceholder]="'例）ご卒業おめでとうございます'"
                formControlName="title"
                [appFocus]="focusEvent"
              />
              <div class="error-popup" *ngIf="formErrors['title']">
                <div class="spen-mod-error-content">
                  {{ formErrors['title'].join(' ') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="column-title"><label class="label">本文</label><span class="spen-mod-badge is-red">必須</span></div>
            <div class="column-content">
              <textarea
                class="spen-mod-input-text"
                formControlName="message"
                [appPlaceholder]="
                  '例）ご卒業おめでとうございます。学校で学んだことを活かし、皆様がますますご活躍されますことをお祈りいたします。'
                "
              >
              </textarea>
              <div class="error-popup" *ngIf="formErrors['message']">
                <div class="spen-mod-error-content">
                  {{ formErrors['message'].join(' ') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="column-title">
              <label class="label">添付ファイル</label>
            </div>
            <div class="column-content">
              <div class="file-uploader">
                <app-file-uploader
                  [uploader]="creatorService.fileUploader"
                  [attachedFiles]="creatorService.attachedFiles"
                  [removedAttachFiles]="creatorService.removedAttachFiles"
                  (eventSelectFile)="onSelectFile($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-button-submit">
        <button type="button" (click)="goBack()" class="spen-mod-btn">戻る</button>
        <button type="submit" class="spen-mod-btn is-primary">配信設定へ</button>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
