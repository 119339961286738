import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlbumCreatorService } from './album-creator.service';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-form-album-teacher',
  templateUrl: './form-album-teacher.component.html',
  styleUrls: ['./form-album-teacher.component.scss'],
  providers: [AlbumCreatorService],
})
export class FormAlbumTeacherComponent implements OnInit {
  @ViewChild(Step1Component) step1Component: Step1Component;
  @ViewChild(Step2Component) step2Component: Step2Component;
  @ViewChild(Step3Component) step3Component: Step3Component;

  albumId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private creatorService: AlbumCreatorService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.route.queryParams.subscribe((params) => {
      this.albumId = +this.route.snapshot.params.id;
      window.scrollTo(0, 0);
      const step = +params['step'] || 1;
      if (this.creatorService.currentStep === 2 && step === 1) {
        this.creatorService.setStep1State();
      }

      if (this.creatorService.currentStep === 3 && step === 2) {
        this.creatorService.setStep2State();
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.step1Component) {
      return this.step1Component.canDeactivate();
    } else if (this.step2Component) {
      return this.step2Component.canDeactivate();
    } else {
      return this.step3Component.canDeactivate();
    }
  }

  get isStep2() {
    return this.creatorService.currentStep === 2;
  }
}
