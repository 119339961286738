<div class="block-standard">
  <div class="spen-mod-heading lv-2 header-title">
    <h2 class="heading-text">
      <i class="fa fa-file-text-o" *ngIf="option['inAlbum']"></i>
      {{ document?.title }}
    </h2>
  </div>
  <ng-container *ngIf="isMultipleWork; else singleWork">
    <div *ngFor="let answer of document.document_answers; let i = index">
      <div class="question-answer">
        <div class="question-number">
          項目{{ i + 1 }}
          <span *ngIf="isCompassAnswer" class="required">必須</span>
        </div>
        <div class="block-element">
          <p class="question preserve-line-break" appConvertLink>
            {{ answer.work_question.content.description || answer.work_question.content }}
          </p>
          <img src="{{ answer.work_question.image.thumbnail }}" *ngIf="answer.work_question.image" />
          <p class="answer-title">回答</p>
          <p class="preserve-line-break" appConvertLink>{{ answer.content }}</p>
          <div class="answer-images">
            <app-files-view-student [files]="answer.upload_files"></app-files-view-student>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #singleWork>
    <div class="block-element">
      <p class="preserve-line-break" appConvertLink>{{ document?.content }}</p>
    </div>
    <div class="thumb">
      <app-files-view-student [files]="document?.upload_files"></app-files-view-student>
    </div>
  </ng-template>
  <div class="row category-detail" *ngIf="!!categoryName">
    <div class="col-md-4 col-sm-4 col-four-1440 col-xs-6">
      <div class="category-text">
        {{ categoryName }}
      </div>
    </div>
  </div>
  <div class="marks" *ngIf="marks?.length > 0">
    <div *ngFor="let mark of marks" class="row">
      <img [src]="mark.icon" />
      <p>{{ mark.name }}</p>
    </div>
  </div>
</div>
