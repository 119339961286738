import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { SlideMenuService } from '@services/slide-menu.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { LayoutService } from '@components/layout/layout.service';

import { Subscription } from 'rxjs';
import { filter, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-teacher-sidebar',
  templateUrl: './teacher-sidebar.component.html',
  styleUrls: ['./teacher-sidebar.component.scss'],
})
export class TeacherSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly appTabs = [
    { path: '/timeline/teacher', name: 'タイムライン', newTab: false },
    { path: '', name: '生徒の記録', newTab: false },
    { path: '/students/teacher', name: '生徒', newTab: false },
    { path: '/activity-records/teacher', name: '活動記録', newTab: false },
    { path: '/albums/teacher', name: 'アルバム', newTab: false },
    { path: '/stories/teacher', name: 'ストーリー', newTab: false, gaAction: 'ストーリーボタン押下' },
    {
      path: environment.teacherResultConfirmUrl,
      name: '成果の記録',
      newTab: true,
      gaAction: '成果の記録ボタン押下',
      ga4ClickParts: '成果の記録',
    },
    { path: '', name: 'スペースでの活動', newTab: false },
    { path: '/spaces/teacher', name: 'スペース', newTab: false },
    { path: '/works/teacher/delivery', name: '配信課題', newTab: false },
    { path: '/albums/teacher/delivery', name: '配信アルバム', newTab: false },
    { path: '/graduation-messages/teacher', name: '卒業メッセージ', newTab: false },
    { path: '', name: '補助機能', newTab: false },
    { path: '/activity-record-templates/teacher', name: '活動記録テンプレート', newTab: false },
  ];

  readonly sessionKey = 'activeTab';
  readonly defaultUrl = '/timeline/teacher';

  @Input() isShowSidebar;
  menuTabStatus = {};
  currentTab: string;
  routerSub: Subscription;

  constructor(
    private changeDedectionRef: ChangeDetectorRef,
    public slideMenuService: SlideMenuService,
    public layoutService: LayoutService,
    private analytics4Service: GoogleAnalytics4Service,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.reinitTabStatus();

    this.routerSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null as Event), // TODO RxJS7.0.0-beta.13で対応されているのでバージョンアップしたら元に戻す startWith(null as Event) -> startWith(null)
        pairwise(),
      )
      .subscribe((e: any[]) => {
        if (!this.isOnlyQueryParamsChange(e[0], e[1])) {
          this.reinitTabStatus();
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.changeDedectionRef.detectChanges();
  }

  hideSidebar() {
    this.slideMenuService.slideOut();
  }

  reinitTabStatus() {
    const matchTab = this.getCurrentTabFromCurrentPath();
    this.menuTabNames.forEach((tabName) => {
      this.menuTabStatus[tabName] = matchTab === tabName;
    });
    this.currentTab = matchTab;
  }

  sendEvent(ga4ClickParts: string): void {
    if (ga4ClickParts) {
      this.analytics4Service.sendEvent(ga4ClickParts, 'サイドメニュー', ga4ClickParts);
    }
  }

  private get menuTabNames() {
    return this.appTabs.map((tab) => tab.path);
  }

  private getCurrentTabFromCurrentPath() {
    if (!!history.state && !!history.state.navigationFrom) {
      return history.state.navigationFrom;
    }

    const locationPath = location.pathname;
    const matchTabs = this.menuTabNames.filter((_tabName) => {
      return !!_tabName && locationPath.indexOf(_tabName) !== -1;
    });

    if (matchTabs.length === 0) {
      return this.currentTab || this.defaultUrl;
    }

    // get longest tab: e.g 'albums/teacher/delivered' will be chosen over 'albums/teacher' if they both match
    return matchTabs.sort((a, b) => {
      return b.length - a.length;
    })[0];
  }

  isCurrentRoute(tabName: string) {
    return location.pathname === tabName;
  }

  private isOnlyQueryParamsChange(beforeRoute, afterRoute) {
    if (!beforeRoute || !afterRoute) {
      return false;
    }

    return beforeRoute.urlAfterRedirects.split('?')[0] === afterRoute.urlAfterRedirects.split('?')[0];
  }
}
