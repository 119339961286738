<div [ngClass]="layoutService.isTabletDownView.value ? 'action-item-sp' : 'action-item'" [class.is-last]="isLast">
  <div class="user-avatar">
    <img src="{{ action.last_create_user.avatar }}" data-cy="user-avatar" />
  </div>

  <div class="action-info">
    <div class="text-info" [class.label-text]="action.is_involved" data-cy="text-action">
      <p [innerHTML]="sanitized.bypassSecurityTrustHtml(actionTextInfo)"></p>
    </div>

    <div class="subject-info">
      <img class="subject-icon" data-cy="subject-icon" src="{{ actionIcon }}" />
      <a
        class="subject-name truncate-text"
        data-cy="subject-name"
        [ngClass]="canViewSubject ? 'spen-util-link' : 'disable-link'"
        (click)="onSubjectClick($event)"
      >
        <span>{{ actionSubjectName }}</span>
      </a>
    </div>

    <div class="spaces-time-info">
      <p class="time muted-text" data-cy="last-created-at">{{ action.last_created_at }}</p>
      <div class="spaces truncate-text" *ngIf="layoutService.isTabletDownView.value; else pcSpacesList">
        <span class="space-name" data-cy="spaces-name" *ngFor="let space of action.spaces">{{ space.name }}</span>
      </div>
      <ng-template #pcSpacesList>
        <div class="spaces">
          <a class="space-name spen-util-link" data-cy="spaces-name" (click)="onSpaceClick(space.id)" *ngFor="let space of action.spaces">{{
            space.name
          }}</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
