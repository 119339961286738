import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';

import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { StoriesComponent } from '@components/story/student/stories/stories.component';
import { StoryDetailComponent } from './story-detail/story-detail.component';
import { FormStoryComponent } from './form-story/form-story.component';
import { StudentHeadingBarComponent } from './student-heading-bar/student-heading-bar.component';
import { StoryFilesViewModule } from '@components/story/story-files-view/story-files-view.module';
import { StoryFileUploaderModule } from './story-file-uploader/story-file-uploader.module';
import { StoryCommentStudentModule } from '@components/story/student/story-comment/story-comment-student.module';

@NgModule({
  declarations: [StoriesComponent, StoryDetailComponent, FormStoryComponent, StudentHeadingBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedPipesModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    StoryFilesViewModule,
    StoryFileUploaderModule,
    StoryCommentStudentModule,
  ],
  exports: [FormStoryComponent],
})
export class StoryStudentModule {}
