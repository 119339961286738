<div class="spen-ly-modal work-filter" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <h2 class="heading-text">検索</h2>
      </div>

      <div class="modal-content has-footer">
        <div class="content-inner">
          <div class="block-input">
            <input class="spen-mod-input-text" type="text" placeholder="キーワードを入力してください" [(ngModel)]="keyword" />
            <div class="info-tip">課題、アルバム名が検索対象です。</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="modal-actions">
          <a class="spen-mod-btn is-primary" (click)="submitFilter()">検索する</a>
        </div>
        <div class="modal-actions">
          <a class="spen-mod-btn" (click)="cancelFitler()">キャンセル</a>
        </div>
      </div>
    </div>
  </div>
</div>
