<div class="tea-graduation-message-detail" *ngIf="graduationMessageDetailLoaded; else loadingIcon">
  <div class="graduation-message-detail" [class.disable-clicked]="isDeletingGraduationMessage">
    <p class="message-title" data-cy="message-title">{{ graduationMessageDetail.title }}</p>

    <div class="user-info">
      <span data-cy="create-user-name">配信者：{{ graduationMessageDetail.create_user_name }}先生</span>
    </div>

    <div class="additional-info">
      <div class="time-detail">
        <span data-cy="created-at">配信日時：{{ graduationMessageDetail.created_at }}</span>
      </div>
      <div class="space-name" data-cy="space-name">
        <span>配信先：</span>
        <a *ngIf="spacePath" [routerLink]="spacePath" class="spen-util-link">
          {{ graduationMessageDetail.space_name }}
        </a>
      </div>
    </div>

    <div class="message-detail">
      <p class="content-title">本文</p>
      <div class="block-element">
        <p class="preserve-line-break" data-cy="message-detail" appConvertLink>{{ graduationMessageDetail.message }}</p>

        <div class="thumb">
          <app-files-view-teacher [files]="graduationMessageDetail?.upload_files"></app-files-view-teacher>
        </div>
      </div>
    </div>

    <div *ngIf="graduationMessageDetail.can_destroy" class="actions">
      <div class="spen-util-link delete-graduation-message" data-cy="delete-message-button" (click)="onConfirmDeleteGraduationMessage()">
        <i class="fa fa-trash-o action-icon"></i>この卒業メッセージを削除する
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
