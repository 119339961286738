import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TeacherAssessmentsResponse } from './assessment-list/teacher/teacher-assessments.response';
import { Assessment } from './assessment';
import { environment } from '@environments/environment';

@Injectable()
export class AssessmentService {
  apiEndpoint = environment.apiEndpoint;
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  loadTeacherAssessments(activityId: number, params = {}): Observable<TeacherAssessmentsResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityId}/teacher_assessments`;
    return this.http.get<TeacherAssessmentsResponse>(url, { params: params });
  }

  createSelfAssessment(activityRecordId: number, assessment: Assessment): Observable<any> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}/self_assessments`;
    return this.http.post(url, { self_assessment: assessment });
  }

  createTeacherAssessment(activityRecordId: number, assessment: Assessment): Observable<any> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityRecordId}/teacher_assessments`;
    return this.http.post(url, { teacher_assessment: assessment });
  }

  updateSelfAssessment(assessmentId: number, assessment: Assessment): Observable<any> {
    return this.http.put(`${this.apiEndpoint}/self_assessments/${assessmentId}`, { self_assessment: assessment });
  }

  updateTeacherAssessment(assessmentId: number, assessment: Assessment): Observable<any> {
    return this.http.put(`${this.apiEndpointV2}/teacher/teacher_assessments/${assessmentId}`, { teacher_assessment: assessment });
  }

  deleteSelfAssessment(assessmentId: number): Observable<any> {
    return this.http.delete(`${this.apiEndpoint}/self_assessments/${assessmentId}`);
  }

  deleteTeacherAssessment(assessmentId: number): Observable<any> {
    return this.http.delete(`${this.apiEndpointV2}/teacher/teacher_assessments/${assessmentId}`);
  }

  createCrossAssessment(spaceId: number, activityRecordId: number, assessment: Assessment): Observable<any> {
    const url = `${this.apiEndpoint}/student/spaces/${spaceId}/activity_records/${activityRecordId}/cross_assessments`;
    return this.http.post(url, { cross_assessment: assessment });
  }

  updateCrossAssessment(spaceId: number, activityRecordId: number, assessment: Assessment): Observable<any> {
    const url = `${this.apiEndpoint}/student/spaces/${spaceId}/activity_records/${activityRecordId}/cross_assessments/${assessment.id}`;
    return this.http.put(url, { cross_assessment: assessment });
  }

  deleteCrossAssessment(spaceId: number, activityRecordId: number, assessmentId: number): Observable<any> {
    const url = `${this.apiEndpoint}/student/spaces/${spaceId}/activity_records/${activityRecordId}/cross_assessments/${assessmentId}`;
    return this.http.delete(url);
  }

  updateRequestReview(assessmentId, assessment: Assessment): Observable<any> {
    const url = `${this.apiEndpointV2}/teacher/cross_assessments/${assessmentId}`;
    return this.http.put(url, { cross_assessment: assessment });
  }
}
