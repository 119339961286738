import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { StudentStoryCommentsResponse } from '@components/story/student/story-comment/story-comment-list/student-story-comments.response';

@Injectable()
export class StoryCommentStudentService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  loadStudentStoryComments(params = {}): Observable<StudentStoryCommentsResponse> {
    const url = `${this.apiEndpointV2}/student/story_comments`;
    return this.http.get<StudentStoryCommentsResponse>(url, { params: params });
  }
}
