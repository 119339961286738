import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { Category } from '@models/category';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Component({
  selector: 'app-categories-selection',
  templateUrl: './categories-selection.component.html',
  styleUrls: ['./categories-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoriesSelectionComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CategoriesSelectionComponent),
      multi: true,
    },
  ],
})
export class CategoriesSelectionComponent implements ControlValueAccessor, Validator {
  @Input() categories: Array<Category> = [];
  @Input() emptyLabel = 'すべて';

  subCategories = [];
  currentCategory: Category = new Category();
  currentSubCategory: Category = new Category();
  categoryId: number;
  isUnselectCategory = true;
  isUnselectSubCategory = true;

  private propagateChange = (_: any) => {};

  initCurrentCategories() {
    if (!this.categoryId) {
      return;
    }
    this.currentCategory = new Category();
    this.currentSubCategory = new Category();
    for (const category of this.categories) {
      if (category.id === this.categoryId) {
        this.currentCategory = category;
        this.subCategories = category['sub_categories'] || [];
        this.isUnselectCategory = false;
        return;
      } else if (category['sub_categories']) {
        const subCategory = category['sub_categories'].find((obj) => obj.id === this.categoryId);
        if (subCategory) {
          this.currentCategory = category;
          this.currentSubCategory = subCategory;
          this.subCategories = category['sub_categories'];
          this.isUnselectCategory = false;
          this.isUnselectSubCategory = false;
          return;
        }
      }
    }
  }

  onChangeCategory($event) {
    this.isUnselectCategory = $event.target.value === 'null' ? true : false;
    const value = parseInt($event.target.value, 10);
    const category = this.categories.find((item) => item.id === value);
    this.currentCategory = category || new Category();
    this.currentSubCategory = new Category();
    if (this.currentCategory['sub_categories']) {
      this.subCategories = this.currentCategory['sub_categories'];
      this.pushCategorySelected(this.currentCategory);
      this.isUnselectSubCategory = true;
    } else {
      this.subCategories = [];
      this.pushCategorySelected(this.currentCategory);
    }
  }

  onChangeSubCategory($event) {
    this.isUnselectSubCategory = $event.target.value === 'null' ? true : false;
    const value = parseInt($event.target.value, 10);
    this.currentSubCategory = this.subCategories.find((item) => item.id === value) || new Category();
    if (this.currentSubCategory.id) {
      this.pushCategorySelected(this.currentSubCategory);
    } else {
      this.pushCategorySelected(this.currentCategory);
    }
  }

  pushCategorySelected(category: Category) {
    this.categoryId = category.id;
    this.propagateChange(this.categoryId);
  }

  isSelectedCategory(category) {
    if (category === null) {
      return this.currentCategory.id === null;
    } else {
      return category.id === this.currentCategory.id;
    }
  }

  isSelectedSubCategory(category) {
    if (category === null) {
      return this.currentSubCategory.id === null;
    } else {
      return category.id === this.currentSubCategory.id;
    }
  }

  isNoSubCategories() {
    return this.subCategories.length === 0;
  }

  writeValue(obj: any) {
    this.categoryId = obj;
    this.initCurrentCategories();
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {}

  setDisabledState?(isDisabled: boolean) {}

  public validate(c: UntypedFormControl) {
    return null;
  }
}
