import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { pick, pickBy, findKey, omit, isEqual, merge, isNil } from 'lodash';
import { LayoutService } from '@components/layout/layout.service';
import { WorkService } from '@components/work/work.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { checkBlankString } from '@functions/check-string';
import { currentLocaleDate } from '@functions/date-formatter';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

const iconSort = {
  none: 'sort-column none',
  loader: 'sort-loader fa fa-spinner fa-spin',
  up: 'sort-column fa fa-caret-up',
  down: 'sort-column fa fa-caret-down',
};

@Component({
  selector: 'app-teacher-delivery-works-list',
  templateUrl: './delivery-works-list.component.html',
  styleUrls: ['./delivery-works-list.component.scss'],
})
export class TeacherDeliveryWorksListComponent implements OnInit {
  contentLoaded = false;
  form: UntypedFormGroup;

  sortStatus = {
    delivered_at: iconSort.none,
  };

  inProgressSort = false;
  sortKey = null;
  sortOrder = null;
  queryParams = {};
  private readonly allowedParamKeys = ['page', 'keyword', 'distributor', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['keyword'];
  readonly saveConditionKeys = ['distributor', 'sort_key', 'sort_order'];

  delivery_works = [];
  pages = {
    current_page: 1,
    next_page: null,
    per_page: 1,
    prev_page: null,
    total_count: 1,
    total_pages: 1,
  };

  sortMap = {
    '配信日時（降順）': { sort_key: 'delivered_at', sort_order: 'desc' },
    '配信日時（昇順）': { sort_key: 'delivered_at', sort_order: 'asc' },
  };

  distributorOptions = [
    { formValue: null, text: 'すべて' },
    { formValue: 'myself', text: '自分' },
    { formValue: 'other_teachers', text: '自分以外の先生' },
  ];

  isShowFilter = false;
  isSearching = false;
  messageSort: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private workService: WorkService,
    public layoutService: LayoutService,
    public filterService: TeacherFilterService,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit(): void {
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    if (this.inProgressSort === false) {
      this.contentLoaded = false;
    }

    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 0) {
      newParams = this.searchConditionSavingService.getSearchConditions(url);
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
    }

    this.queryParams = pick(newParams, this.allowedParamKeys);
    this.filterService.setFilter(this.queryParams);
    this.sortOrder = this.queryParams['sort_order'];
    this.sortKey = this.queryParams['sort_key'];
    this.buildForm();
    this.loadDeliveryWorksList(this.queryParams);
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  buildForm() {
    this.messageSort = this.getSortValue();
    this.form = this.formBuilder.group({
      keyword: [this.queryParams['keyword'] || '', Validators.maxLength(50)],
      distributor: [this.queryParams['distributor']],
      sort_value: [this.messageSort],
    });
    this.isSearching = !this.isDefaultForm();
  }

  loadDeliveryWorksList(params: {}) {
    if (!this.contentLoaded) {
      this.inProgressSort = true;
    }
    this.workService.getTeacherDeliveryWorks(params).subscribe(
      (response) => {
        Object.assign(this.pages, response.meta);
        this.refreshIconSort();
        this.delivery_works = response.works;
        this.contentLoaded = true;
      },
      (error) => {
        // TODO: add error handling.
      },
    );
  }

  defaultFilterValues() {
    const filterValues = {
      distributor: null,
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = Object.keys(this.sortMap)[0];
    }

    return filterValues;
  }

  isLoadingData() {
    return !this.contentLoaded || this.inProgressSort;
  }

  onClickWork(workId: number) {
    this.router.navigateByUrl(`works/teacher/delivery/${workId}`);
  }

  onClickSpaceName(spaceId: number) {
    this.router.navigateByUrl(`spaces/teacher/${spaceId}`);
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  changeBySortColumn(sortKey: string) {
    if (this.inProgressSort) {
      return false;
    }
    this.inProgressSort = true;

    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = iconSort.none;
      this.sortOrder = 'desc';
      this.sortKey = sortKey;
    }
    this.sortStatus[this.sortKey] = iconSort.loader;

    const params = merge(this.queryParams, this.buildSortParams());
    this.navigate(params, 'merge');
  }

  buildSortParams() {
    return {
      page: 1,
      sort_key: this.sortKey,
      sort_order: this.sortOrder,
    };
  }

  refreshIconSort() {
    this.sortKey = this.sortKey || 'delivered_at';
    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = iconSort[icon];
    this.inProgressSort = false;
  }

  changeShowFilterStatus() {
    this.isShowFilter = !this.isShowFilter;
  }

  getSortValue() {
    const formValue = findKey(this.sortMap, pick(this.queryParams, ['sort_key', 'sort_order']));
    return formValue || Object.keys(this.sortMap)[0];
  }

  removeFilter() {
    if (this.layoutService.isTabletDownView.value) {
      const sortDefault = Object.keys(this.sortMap)[0];
      this.queryParams['sort_value'] = sortDefault;
      Object.assign(this.queryParams, this.sortMap[sortDefault]);
    }

    this.form.reset(this.defaultFilterValues());
    this.onSubmitFilter();
  }

  onSubmitFilter() {
    window.scrollTo(0, 0);
    this.isShowFilter = false;
    this.navigate(this.buildFilterParams());
  }

  buildFilterParams() {
    const params = pickBy(this.form.value);

    if (checkBlankString(params['keyword'])) {
      delete params['keyword'];
      this.form.controls['keyword'].setValue('');
    } else {
      params['keyword'] = params['keyword'].trim();
      this.form.controls['keyword'].setValue(params['keyword']);
    }
    params['request_timestamp'] = Date.now();

    return Object.assign(omit(params, ['sort_value']), this.sortMap[this.getSortValue()]);
  }

  onChangeSortValue(value: string) {
    this.queryParams['sort_value'] = value;
    Object.assign(this.queryParams, this.sortMap[value]);
  }

  isDefaultForm() {
    const compareValues = this.form.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, this.defaultFilterValues());
  }

  isScheduled(delivered_at) {
    const currentTime = currentLocaleDate();
    const deliveredTime = new Date(delivered_at);
    return currentTime < deliveredTime;
  }
}
