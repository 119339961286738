import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { GradesResponse } from '@components/school-grade-class-select/grades-response';
import { Grade } from '@components/school-grade-class-select/grade';
import { ClassesListResponse } from '@components/school-grade-class-select/classes-list-response';
import { Class } from '@components/school-grade-class-select/class';

@Injectable()
export class ClassesFilterService {
  schoolYears = new BehaviorSubject<Array<string>>([]);
  grades = new BehaviorSubject<Array<Grade>>([]);
  classes = new BehaviorSubject<Array<Class>>([]);
  isRequestedSchoolYears = false;
  isRequestedGrades = false;
  isReinitSelectData = false;
  isLoading = false;
  lastRequestData = '';
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {
    const storedSchoolYears = sessionStorage.getItem('schoolYears');
    if (this.schoolYears.getValue().length === 0 && storedSchoolYears) {
      this.schoolYears.next(JSON.parse(storedSchoolYears));
    }

    const storedGrades = sessionStorage.getItem('grades');
    if (this.grades.getValue().length === 0 && storedGrades) {
      this.grades.next(JSON.parse(storedGrades));
    }
  }

  getClasses(params = {}): Promise<Array<Class>> | Array<Class> {
    const currentRequestData = this.buildQueryData(params);

    if (currentRequestData !== this.lastRequestData) {
      return this.doRequestClasses(params).toPromise();
    } else {
      return this.classes.getValue();
    }
  }

  private doRequestClasses(params = {}): Observable<Array<Class>> {
    this.lastRequestData = this.buildQueryData(params);
    this.isLoading = true;

    let url = `${this.apiEndpointV2}/classes?school_year=${params['school_year']}`;
    if (params['grade_id']) {
      url += `&grade_id=${params['grade_id']}`;
    }
    return this.http.get<ClassesListResponse>(url).pipe(
      map((response) => {
        const classes = response.classes || [];
        this.classes.next(classes);
        return classes;
      }),
      catchError((_error) => of(<Array<Class>>[])),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  getSchoolYears(): Promise<Array<string>> | Array<string> {
    if (this.schoolYears.value.length === 0 && !this.isRequestedSchoolYears) {
      return this.doRequestSchoolYears().toPromise();
    } else {
      return this.schoolYears.getValue();
    }
  }

  private doRequestSchoolYears(): Observable<Array<string>> {
    this.isLoading = true;
    const url = `${this.apiEndpointV2}/school_years`;
    return this.http.get<Array<string>>(url).pipe(
      map((response) => {
        sessionStorage.setItem('schoolYears', JSON.stringify(response));
        this.schoolYears.next(response);
        return response;
      }),
      catchError((_error) => of(<Array<string>>[])),
      finalize(() => {
        this.isRequestedSchoolYears = true;
        this.isLoading = false;
      }),
    );
  }

  getGrades(): Promise<Array<Grade>> | Array<Grade> {
    if (this.grades.value.length === 0 && !this.isRequestedGrades) {
      return this.doRequestGrades().toPromise();
    } else {
      return this.grades.getValue();
    }
  }

  private doRequestGrades(): Observable<Array<Grade>> {
    this.isLoading = true;
    const url = `${this.apiEndpointV2}/grades`;
    return this.http.get<GradesResponse>(url).pipe(
      map((response) => {
        sessionStorage.setItem('grades', JSON.stringify(response.grades));
        this.grades.next(response.grades);
        return response.grades;
      }),
      catchError((_error) => of(<Array<Grade>>[])),
      finalize(() => {
        this.isRequestedGrades = true;
        this.isLoading = false;
      }),
    );
  }

  private buildQueryData(params: {}): string {
    return `y_${params['school_year']}-g_${params['grade_id']}`;
  }
}
