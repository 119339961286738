import { isOldAndroid, isMobileApp } from '@functions/browser';

export const IMAGE_TYPES = ['jpg', 'jpeg', 'gif', 'png'];

export const AUDIO_TYPES = [
  'mp3',
  'm4a', // 'wma' type can not played by html5 audio tag.
];

export const VIDEO_TYPES = [
  'mp4',
  'mov', // '3gp' and 'avi' types can not played by html5 video tag;
  // NOTE: mp4 + mpeg4 files with MPEG-4 codec can not played :-s
];

export const PREVIEW_TYPES = [...IMAGE_TYPES, ...AUDIO_TYPES, ...VIDEO_TYPES];

export function isImage(fileExtension: string): boolean {
  return IMAGE_TYPES.includes(fileExtension);
}

export function isAudio(fileExtension: string): boolean {
  return AUDIO_TYPES.includes(fileExtension);
}

export function isVideo(fileExtension: string): boolean {
  return VIDEO_TYPES.includes(fileExtension);
}

export function canPreview(fileExtension: string): boolean {
  return PREVIEW_TYPES.includes(fileExtension) && !(isOldAndroid() && isMobileApp());
}

export function isPdf(fileExtension: string): boolean {
  return fileExtension === 'pdf';
}
