import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorService } from '@services/error.service';
import { environment } from '@environments/environment';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  private apiEndPointV2 = environment.apiEndpointV2;
  private authUrl = environment.authUrl;

  constructor(
    private errorService: ErrorService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          return event.body || {};
        }
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            window.open(this.authUrl, '_self');
            break;
          case 403:
            this.router.navigateByUrl('/403');
            break;
          case 500:
            if (request.url !== `${this.apiEndPointV2}/teacher/word_clouds/fetch_word_cloud`) {
              this.addError(error);
            }
            break;
          case 503:
            this.addError(error);
            this.router.navigateByUrl('/503');
            break;
          case 504:
            this.router.navigateByUrl('/504', {
              skipLocationChange: true,
            });
            break;
          default:
            this.addError(error);
        }
        return observableThrowError(error.error.message);
      }),
    );
  }

  private addError(error) {
    this.errorService.addError({
      error_code: error.error.error_code,
      title: error.error.title,
      message: error.error.message,
      internalMessage: error.message,
    });
  }
}
