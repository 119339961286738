import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppPaginationControlsComponent } from './pagination-controls/app-pagination-controls.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilesViewStudentComponent } from './files-view/student/files-view-student.component';
import { FilesViewTeacherComponent } from './files-view/teacher/files-view-teacher.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LinkHorizontalTransitionComponent } from './link-horizontal-transition/link-horizontal-transition.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { UploadFilesProgressComponent } from './upload-files-progress/upload-files-progress.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { CategoriesSelectionComponent } from './categories-selection/categories-selection.component';
import { DocumentDetailStudentComponent } from './document-detail/student/document-detail-student.component';
import { DocumentDetailTeacherComponent } from './document-detail/teacher/document-detail-teacher.component';
import { TabViewComponent } from './tab-view/tab-view.component';
import { SegmentViewComponent } from './segment-view/segment-view.component';
import { WorkInfoStudentComponent } from './work-info/student/work-info-student.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MarksSelectorComponent } from './marks-selector/marks-selector.component';
import { QuestionnaireDetailTeacherComponent } from './questionnaire-detail/teacher/questionnaire-detail-teacher.component';
import { QuestionnaireDetailStudentComponent } from './questionnaire-detail/student/questionnaire-detail-student.component';
import { QuestionnaireFilePreviewComponent } from './questionnaire-detail/questionnaire-file-preview/questionnaire-file-preview.component';
import { FilterByMultipleComponent } from './filter-by-multiple/filter-by-multiple.component';
import { StudentFilterByCrossReviewComponent } from '@components/filter-by-cross-review/student/student-filter-by-cross-review.component';
import { DropdownFilterActivitiesComponent } from '@components/student/dropdown-filter/dropdown-filter-activities.component';
import { ActivityDetailModalComponent } from './student/activity-detail-modal/activity-detail-modal.component';
import { ListActivityModalComponent } from './student/list-activity-modal/list-activity-modal.component';
import { StudentAssessmentDetailComponent } from './student/assessment-detail/student-assessment-detail.component';
import { StudentActivityItemComponent } from './student/activity-item/student-activity-item.component';
import { StudentManaVisionDetailComponent } from './student/mana-vision-detail/student-mana-vision-detail.component';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { StudentService } from './student/student.service';
import { StudentPublicActivityItemComponent } from '@components/student/public-activity-item/student-public-activity-item.component';
import { PickupItemToggleComponent } from './pickup-item-toggle/pickup-item-toggle.component';
import { TeacherPickupItemComponent } from './teacher/pickup-item/teacher-pickup-item.component';
import { TeacherPickupsListComponent } from './teacher/pickups-list/teacher-pickups-list.component';
import { StudentCrossAssessmentsComponent } from './student/cross-assessments/student-cross-assessments.component';
import { CrossAssessmentListComponent } from './teacher/cross-assessment-list/cross-assessment-list.component';
import { CrossAssessmentItemComponent } from '@components/teacher/cross-assessment-item/cross-assessment-item.component';
import { StepsComponent } from '@components/steps/steps.component';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerI18nService } from '@components/datepicker/datepicker-i18n.service';
import { I18nService } from '@components/datepicker/i18n.service';
import { DatepickerParserFormatter } from '@components/datepicker/datepicker-parser-formatter';
import { RequestReviewAssessmentComponent } from '@components/teacher/request-review-assessment/request-review-assessment.component';
import { TeacherCategoriesSelectionComponent } from '@components/teacher-categories-selection/teacher-categories-selection.component';
import { SchoolGradeClassSelectComponent } from './school-grade-class-select/school-grade-class-select.component';
import { ActionItemComponent } from '@components/timeline/action-item/action-item.component';
import { TimelineDateBoxComponent } from '@components/timeline/timeline-date-box/timeline-date-box.component';
import { DatetimeSelectorComponent } from '@components/datetime-selector/datetime-selector.component';
import { WordCloudModalComponent } from './word-cloud/word-cloud-modal/word-cloud-modal.component';
import { WordCloudErrorModalComponent } from './word-cloud/word-cloud-error-modal/word-cloud-error-modal.component';
import { WordCloudWaitModalComponent } from './word-cloud/word-cloud-wait-modal/word-cloud-wait-modal.component';
import { GatewayTimeoutComponent } from '@components/gateway-timeout/gateway-timeout.component';
import { TeacherSpacePickerComponent } from './teacher/space-picker/teacher-space-picker.component';
import { TeacherSpacePickerService } from './teacher/space-picker/teacher-space-picker.service';

@NgModule({
  declarations: [
    AppPaginationControlsComponent,
    GalleryComponent,
    LinkHorizontalTransitionComponent,
    FilesViewStudentComponent,
    FilesViewTeacherComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ReadMoreComponent,
    UploadFilesProgressComponent,
    ForbiddenComponent,
    CategoriesSelectionComponent,
    DocumentDetailStudentComponent,
    DocumentDetailTeacherComponent,
    DatepickerComponent,
    TabViewComponent,
    SegmentViewComponent,
    WorkInfoStudentComponent,
    NotFoundComponent,
    MarksSelectorComponent,
    QuestionnaireFilePreviewComponent,
    QuestionnaireDetailTeacherComponent,
    QuestionnaireDetailStudentComponent,
    FilterByMultipleComponent,
    StudentFilterByCrossReviewComponent,
    DropdownFilterActivitiesComponent,
    ActivityDetailModalComponent,
    ListActivityModalComponent,
    StudentActivityItemComponent,
    StudentManaVisionDetailComponent,
    StudentPublicActivityItemComponent,
    StudentAssessmentDetailComponent,
    PickupItemToggleComponent,
    TeacherPickupItemComponent,
    TeacherPickupsListComponent,
    StudentCrossAssessmentsComponent,
    CrossAssessmentListComponent,
    CrossAssessmentItemComponent,
    StepsComponent,
    RequestReviewAssessmentComponent,
    TeacherCategoriesSelectionComponent,
    SchoolGradeClassSelectComponent,
    ActionItemComponent,
    TimelineDateBoxComponent,
    DatetimeSelectorComponent,
    WordCloudModalComponent,
    WordCloudErrorModalComponent,
    WordCloudWaitModalComponent,
    GatewayTimeoutComponent,
    TeacherSpacePickerComponent,
  ],
  exports: [
    AppPaginationControlsComponent,
    GalleryComponent,
    LinkHorizontalTransitionComponent,
    FilesViewStudentComponent,
    FilesViewTeacherComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ReadMoreComponent,
    UploadFilesProgressComponent,
    CategoriesSelectionComponent,
    DocumentDetailStudentComponent,
    DocumentDetailTeacherComponent,
    DatepickerComponent,
    TabViewComponent,
    SegmentViewComponent,
    WorkInfoStudentComponent,
    MarksSelectorComponent,
    QuestionnaireDetailTeacherComponent,
    QuestionnaireDetailStudentComponent,
    FilterByMultipleComponent,
    StudentFilterByCrossReviewComponent,
    DropdownFilterActivitiesComponent,
    ActivityDetailModalComponent,
    ListActivityModalComponent,
    StudentActivityItemComponent,
    StudentManaVisionDetailComponent,
    StudentPublicActivityItemComponent,
    StudentAssessmentDetailComponent,
    PickupItemToggleComponent,
    TeacherPickupsListComponent,
    StudentCrossAssessmentsComponent,
    CrossAssessmentListComponent,
    CrossAssessmentItemComponent,
    StepsComponent,
    RequestReviewAssessmentComponent,
    TeacherCategoriesSelectionComponent,
    SchoolGradeClassSelectComponent,
    ActionItemComponent,
    TimelineDateBoxComponent,
    DatetimeSelectorComponent,
    WordCloudModalComponent,
    WordCloudErrorModalComponent,
    WordCloudWaitModalComponent,
    TeacherSpacePickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    NgbModule,
    SharedPipesModule,
    SharedDirectivesModule,
    RouterModule,
  ],
  providers: [
    I18nService,
    { provide: NgbDatepickerI18n, useClass: DatepickerI18nService },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter },
    StudentService,
    TeacherSpacePickerService,
  ],
})
export class SharedModule {}
