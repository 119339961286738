<div class="comment">
  <div class="user">
    <div class="user-info">
      <img [src]="storyComment.create_user.avatar" />
      <div class="user-name">
        <div class="teacher-name">
          <p class="truncate-text">{{ storyComment.create_user.name }}</p>
          <p>先生</p>
        </div>
      </div>
    </div>
    <a class="delete-edit spen-util-link" (click)="onEditStoryComment()" *ngIf="canEdit">
      <i class="fa fa-pencil"></i><span>変更・削除</span>
    </a>
  </div>

  <div class="content-comment">
    <p class="time-comment">{{ storyComment.created_at }}</p>
    <app-read-more [content]="storyComment.content"></app-read-more>
    <span class="updated-text" *ngIf="storyComment.is_updated">（変更済み）</span>
  </div>
</div>
