<div class="spen-ly-modal" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <div class="btn-close" *ngIf="layoutService.isTabletDownView.value">
          <a (click)="onClose()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
        <h2 class="heading-text">公開先の選択</h2>
      </div>

      <div class="scrollable-modal-content">
        <div
          class="modal-content"
          infinite-scroll
          infiniteScrollDistance="1"
          infiniteScrollThrottle="200"
          [infiniteScrollDisabled]="!nextPageParams"
          [scrollWindow]="false"
          (scrolled)="onLoadMore()"
        >
          <div class="spaces-input">
            <input
              class="spen-mod-input-text"
              maxlength="30"
              type="text"
              [placeholder]="layoutService.isTabletDownView.value ? 'キーワードを入力' : 'キーワードを入力してください'"
              #searchInput
            />
            <button class="spen-mod-btn" (click)="onSearch(true)">
              {{ layoutService.isTabletDownView.value ? '検索' : '検索する' }}
            </button>
          </div>
          <div class="spen-mod-error-content error-popup" *ngIf="isShowError">スペースを選択してください</div>

          <div class="list-header">
            <h3>スペースリスト</h3>
          </div>

          <div class="spaces-list" [class.border-bottom-none]="searchedResults.length === 0 && !isLoading">
            <div
              class="space-item"
              *ngFor="let space of searchedResults"
              (change)="selectSpace(space)"
              [class.has-hover-effect]="!layoutService.isTabletDownView.value && !canNotPickup(space)"
            >
              <label class="spen-mod-checkbox" [class.is-disabled]="canNotPickup(space)">
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="isSelectedSpace(space) && !canNotPickup(space)"
                  [disabled]="canNotPickup(space)"
                />
                <i class="fa"></i>
                <div class="block-color" [style.background-color]="space.color_code"></div>
                <p class="truncate-text space-name" *ngIf="!layoutService.isTabletDownView.value; else spViewNote">
                  {{ space.name }}
                  <span *ngIf="!space.teacher_can_pick_up">（ピックアップ制限中）</span>
                  <span *ngIf="space.is_picked_up_by_current_teacher">（ピックアップ表示中）</span>
                </p>

                <ng-template #spViewNote>
                  <div class="fix-width-label-name">
                    <p class="truncate-text space-name">{{ space.name }}</p>
                    <p class="note-small">
                      <span *ngIf="!space.teacher_can_pick_up">（ピックアップ制限中）</span>
                      <span *ngIf="space.is_picked_up_by_current_teacher">（ピックアップ表示中）</span>
                    </p>
                  </div>
                </ng-template>
              </label>
            </div>

            <div class="spen-mod-flex-loading" *ngIf="isLoading">
              <div class="loading-icon"></div>
            </div>
          </div>
          <div class="empty-message" *ngIf="!isLoading && searchedResults.length === 0">
            {{ errorMsg || '検索結果が0件です。' }}
          </div>
        </div>
        <div class="modal-footer">
          <div class="full-width">
            <button class="spen-mod-btn is-default" (click)="onClose()">キャンセル</button>
            <button class="spen-mod-btn is-primary" (click)="onSubmit()" [disabled]="isShowError">選択する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
