<footer class="spen-ly-tea-footer">
  <div class="tea-footer-content">
    <div class="tea-footer-list-link">
      <div class="tea-footer-link">
        <a href="{{ helpPageUrl }}" target="_blank">ヘルプページ</a>
      </div>
      <div class="tea-footer-link-line"></div>
      <div class="tea-footer-link">
        <a href="{{ inquiryUrl }}" target="_blank">お問い合わせ</a>
      </div>
    </div>
    <div class="tea-footer-copyright">
      <p>Copyright © Classi Corp. All Rights Reserved</p>
    </div>
  </div>
</footer>
