import { AfterViewChecked, Component, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { Space } from '@components/space/space';
import { SpaceService } from '@components/space/space.service';
import { SpaceCreatorService } from '../space-creator.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { validateForm } from '@functions/validate-form';
import { TeacherSpaceResponse } from '@components/space/teacher-space-response';
import { minArrayLength } from '../validate-selected-user-count';
import { AuthUserService } from '@services/auth-user.service';
import { ToastService } from '@services/toast/toast.service';
import { User } from '@models/user';
import { ClassService } from '@components/class/class.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherFilterService, StudentFilterService } from '../space-filter/space-filter.service';

@Component({
  selector: 'app-space-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['../form-space.component.scss'],
})
export class Step1Component implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('listColor') listColor: ElementRef;

  space: TeacherSpaceResponse;
  currentSpaceId: number;
  form: UntypedFormGroup;
  currentUser: User;
  isShowingStudentFilter = false;
  isShowingTeacherFilter = false;
  isFocused = false;
  spaceLoaded = false;
  isDeleting = false;

  selectedStudentsSub: Observable<Array<User>>;
  selectedTeachersSub: Observable<Array<User>>;

  COLORS = ['#009fe8', '#1c68bf', '#76b330', '#339c94', '#e87c33', '#d24d4d', '#ad52b0', '#674daa', '#efbf15', '#e87191'];

  CROSS_REVIEW_PERMISSIONS = [
    { title: '先生のピックアップ', key: 'teacher_can_pick_up' },
    { title: '生徒自主公開', key: 'student_can_publish' },
    { title: '相互評価コメント', key: 'student_can_comment_other' },
  ];

  showColorDropdown: boolean;

  errMsg: string;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    name: {
      required: 'スペース名を入力してください。',
      maxlength: 'スペース名は30文字以内で入力してください。',
    },
    space_members_attributes: {
      minArrayLength: '参加する生徒を選択してください。',
    },
    charges_attributes: {
      minArrayLength: '担当する先生を選択してください。',
    },
  };
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  dataConfirmDelete = {
    title: '確認',
    content: '他のユーザの画面からも削除されます。一度削除したスペースは復元することができません。<br> 本当に削除しますか？',
    acceptButton: '削除する',
    cancelButton: 'キャンセル',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spaceService: SpaceService,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private creatorService: SpaceCreatorService,
    public studentFilterService: StudentFilterService,
    public teacherFilterService: TeacherFilterService,
    private authUserService: AuthUserService,
    public classService: ClassService,
    public layoutService: LayoutService,
    private toastService: ToastService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.classService.getSchoolYears();
    this.currentUser = this.authUserService.retrieve();
    this.selectedStudentsSub = this.studentFilterService.selectedUsers$;
    this.selectedTeachersSub = this.teacherFilterService.selectedUsers$;
    const previousStep = this.creatorService.previousStep;
    if (previousStep === 2) {
      this.space = this.creatorService.space;
      this.currentSpaceId = this.space.id;
      this.prepareCreateForm(this.space, true);
    } else {
      this.currentSpaceId = this.route.snapshot.params.spaceId;
      if (!!this.currentSpaceId) {
        this.prepareUpdateForm();
      } else {
        this.space = this.creatorService.space;
        this.prepareCreateForm(this.space);
      }
    }
  }

  ngAfterViewChecked() {
    if (this.spaceLoaded && !this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }

    if (this.listColor) {
      this.listColor.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDeleting && this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  chooseColor(color: string) {
    this.showColorDropdown = !this.showColorDropdown;
    this.form.get('color_code').setValue(color);
  }

  chooseTeacher() {
    this.isShowingTeacherFilter = true;
  }

  chooseStudent() {
    this.isShowingStudentFilter = true;
  }

  closeTeacherForm() {
    this.isShowingTeacherFilter = false;
  }

  closeStudentForm() {
    this.isShowingStudentFilter = false;
  }

  removeSelectedTeacher(teacher: User) {
    this.form.get('charges_attributes').markAsDirty();
    this.teacherFilterService.removeSelectedUser(teacher);
    this.buildTeacherAttributes();
  }

  removeSelectedStudent(student: User) {
    this.form.get('space_members_attributes').markAsDirty();
    this.studentFilterService.removeSelectedUser(student);
    this.buildStudentsAttributes();
  }

  onTeacherFilterSubmit() {
    this.form.get('charges_attributes').markAsDirty();
    this.buildTeacherAttributes();
  }

  onStudentFilterSubmit() {
    this.form.get('space_members_attributes').markAsDirty();
    this.buildStudentsAttributes();
  }

  private prepareCreateForm(space: Space, dirty = false) {
    if (!this.teacherFilterService.selectedUsers.value.length) {
      this.teacherFilterService.selectedUsers.next([this.currentUser]);
    }
    this.spaceLoaded = true;
    this.getFormSpace(space);
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.dataConfirm);
    }
  }

  private prepareUpdateForm() {
    this.spaceService.getTeacherSpaceEdit(this.currentSpaceId).subscribe(
      (obj) => {
        if (!obj.can_be_edited) {
          this.router.navigateByUrl(`/spaces/teacher/${this.currentSpaceId}`);
          return;
        }
        this.space = obj;
        Object.assign(this.creatorService.existedStudents, obj['students']);
        Object.assign(this.creatorService.existedTeachers, obj['teachers']);
        this.studentFilterService.selectedUsers.next(obj['students']);
        this.teacherFilterService.selectedUsers.next(obj['teachers']);
        this.spaceLoaded = true;
        this.getFormSpace(this.space);
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  getFormSpace(space: TeacherSpaceResponse) {
    this.form = this.formBuilder.group({
      id: [space.id || ''],
      name: [space.name || '', [Validators.required, Validators.maxLength(30)]],
      color_code: [space.color_code || this.COLORS[0]],
      school_year: [space.school_year],
      teacher_can_pick_up: [space.teacher_can_pick_up],
      student_can_publish: [space.student_can_publish],
      student_can_comment_other: [space.student_can_comment_other],
      can_be_destroyed: [space.can_be_destroyed],
      create_user_id: [space.create_user_id || this.currentUser.id],
      space_members_attributes: [[], minArrayLength(1, this.studentFilterService.selectedUsers.value)],
      charges_attributes: [[], minArrayLength(1, this.teacherFilterService.selectedUsers.value)],
    });
    this.formErrors = {};
    this.buildStudentsAttributes();
    this.buildTeacherAttributes();
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  buildStudentsAttributes() {
    this.form
      .get('space_members_attributes')
      .setValue(this.buildUserAttributes(this.studentFilterService.selectedUsers.value, this.creatorService.existedStudents, false));
  }

  buildTeacherAttributes() {
    this.form
      .get('charges_attributes')
      .setValue(this.buildUserAttributes(this.teacherFilterService.selectedUsers.value, this.creatorService.existedTeachers, true));
  }

  buildUserAttributes(selectedUsers: Array<User>, existedUsers: Array<User>, isTeacher: boolean) {
    const foreign_table_key = isTeacher ? 'charge_id' : 'space_member_id';
    const foreign_key = isTeacher ? 'teacher_id' : 'user_id';

    if (!existedUsers.length) {
      return selectedUsers.map((user) => ({ [foreign_key]: user.id }));
    }

    const removedUsers = [];
    for (const existedUser of existedUsers) {
      if (this.notContainedInArray(selectedUsers, existedUser)) {
        removedUsers.push({ id: existedUser[foreign_table_key], _destroy: true });
      }
    }
    const newUsers = selectedUsers
      .filter((user) => this.notContainedInArray(existedUsers, user))
      .map((user) => ({ [foreign_key]: user.id }));
    return newUsers.concat(removedUsers);
  }

  notContainedInArray(array: Array<User>, user: User) {
    const index = array.findIndex((_user) => _user.id === user.id);
    return index === -1;
  }

  private onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  private isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.creatorService.space = this.form.value;
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep2State();
  }

  deleteSpace() {
    this.isDeleting = true;
    this.confirmDialogService.showConfirm(this.dataConfirmDelete).subscribe((value) => {
      if (value) {
        this.spaceService.deleteSpace(this.currentSpaceId).subscribe(
          (res) => {
            this.router.navigateByUrl('/spaces/teacher');
            this.toastService.showToast('削除しました');
          },
          (error) => {
            this.errMsg = <any>error;
            this.isDeleting = false;
          },
        );
      } else {
        this.isDeleting = false;
      }
    });
  }

  onOutFocus(target: HTMLElement) {
    if (!target || !target.classList.contains('select-color')) {
      this.showColorDropdown = false;
    }
  }
}
