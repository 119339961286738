import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssessmentModule } from '../assessment/assessment.module';

import { SpacesListTeacherComponent } from './spaces-list/spaces-list-teacher/spaces-list-teacher.component';
import { TeacherSpaceDetailComponent } from './space-detail/teacher-space-detail/teacher-space-detail.component';
import { FormSpaceComponent } from './form-space/form-space.component';
import { Step1Component } from './form-space/step1/step1.component';
import { Step2Component } from './form-space/step2/step2.component';
import { SpaceTimelineComponent } from './space-detail/teacher-space-detail/timeline/space-timeline.component';
import { SpaceDetailInfoComponent } from './space-detail/teacher-space-detail/space-info/space-info.component';
import { CrossReviewDialogComponent } from './space-detail/teacher-space-detail/cross-review-dialog/cross-review-dialog.component';
import { SpaceDeliveredWorksComponent } from './space-detail/teacher-space-detail/works/space-delivered-works.component';
import { ModalParticipantsListComponent } from './space-detail/teacher-space-detail/participants/modal-participants-list.component';
import { SpaceAlbumsComponent } from './space-detail/teacher-space-detail/albums/space-albums.component';
import { SpacePublicActivityRecordsComponent } from './space-detail/teacher-space-detail/activity-records/space-public-activity-records.component';
import { SpaceFilterComponent } from './form-space/space-filter/space-filter.component';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { SpaceGraduationMessageComponent } from './space-detail/teacher-space-detail/graduation-message/space-graduation-message.component';

@NgModule({
  declarations: [
    SpacesListTeacherComponent,
    TeacherSpaceDetailComponent,
    FormSpaceComponent,
    Step1Component,
    Step2Component,
    SpaceTimelineComponent,
    SpaceDetailInfoComponent,
    CrossReviewDialogComponent,
    SpaceDeliveredWorksComponent,
    ModalParticipantsListComponent,
    SpaceAlbumsComponent,
    SpacePublicActivityRecordsComponent,
    SpaceFilterComponent,
    SpaceGraduationMessageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedPipesModule,
    NgxPaginationModule,
    FormsModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    AssessmentModule,
  ],
  providers: [SearchConditionSavingService],
})
export class SpaceTeacherModule {}
