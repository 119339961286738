<div class="teacher-stories" *ngIf="!!this.dataLoaded; else loadingIcon">
  <div class="student-info">
    <div class="align-top">
      <img src="{{ student.avatar }}" />
      <div class="user-info">
        <a class="spen-util-link break-word" [routerLink]="['/students/teacher/', student.id]">{{ student.name }}</a>
        <p class="class-name">{{ student.class_name }}{{ student.attendance_number }}番</p>
      </div>
    </div>
  </div>

  <app-tab-view [tabOptions]="tabOptions"></app-tab-view>
  <ng-container [ngSwitch]="currentTab">
    <app-story-detail *ngSwitchCase="'grade_1'" [grade]="'1'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'grade_2'" [grade]="'2'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'grade_3'" [grade]="'3'"></app-story-detail>
    <app-story-detail *ngSwitchCase="'summary'"></app-story-detail>
  </ng-container>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
