import { Injectable } from '@angular/core';

import { Space } from '@components/space/space';
import { Pickup } from '@models/pickup';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { currentLocaleDate, dateToNgbDate } from '@functions/date-formatter';

@Injectable()
export class FormActivityPickupService {
  steps = ['入力', '確認', '完了'];
  previousStep: number;
  currentStep: number;

  activityPickup: Pickup;

  activityRecordId: number;
  space: Space;
  spaces: Array<Space>;

  editActivityPickupId: number;

  startTime: NgbDateStruct;
  endTime: NgbDateStruct;
  originalSpaceEditId: number;

  constructor() {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.activityPickup = new Pickup();
    this.spaces = [];

    const today = currentLocaleDate();
    this.startTime = dateToNgbDate(today);
    const tomorrow = currentLocaleDate();
    tomorrow.setDate(today.getDate() + 1);
    this.endTime = dateToNgbDate(tomorrow);
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }
}
