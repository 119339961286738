<div class="timestamp">
  <span>作成日時：{{ activityRecord.created_at }}</span>
  <span *ngIf="activityRecord.updated_at !== '-'">更新日時：{{ activityRecord.updated_at }}</span>
  <p class="serial" *ngIf="!layoutService.isTabletDownView.value">[ {{ page }}/{{ totalPage }} ]</p>
</div>
<div class="title">
  <a class="spen-util-link" [routerLink]="['/activity-records/teacher/', activityRecord.id]">{{ activityRecord.title }}</a>
</div>
<app-document-detail-teacher
  *ngIf="isDocumentRecord()"
  [document]="activityRecord.record"
  [categoryName]="activityRecord.category_name"
  [marks]="activityRecord.marks"
  [isCompassAnswer]="false"
  [isReadMore]="true"
>
</app-document-detail-teacher>
<app-questionnaire-detail-teacher
  *ngIf="isQuestionnaireRecord()"
  [categoryName]="activityRecord.category_name"
  [questionnaire]="activityRecord.record"
  [isReadMore]="true"
>
</app-questionnaire-detail-teacher>
<div class="page">- {{ page }} -</div>
