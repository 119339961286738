import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ToastService {
  eventShow: EventEmitter<any>;

  constructor() {
    this.eventShow = new EventEmitter();
  }

  showToast(content: string) {
    this.eventShow.emit(content);
  }
}
