import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ActivityRecordService } from '@components/activity-record/activity-record.service';
import { RouterService } from '@services/router.service';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { Album } from '@models/album';
import { Space } from '@components/space/space';

@Component({
  selector: 'app-activity-record-detail-teacher',
  templateUrl: './activity-record-detail-teacher.component.html',
  styleUrls: ['./activity-record-detail-teacher.component.scss'],
})
export class ActivityRecordDetailTeacherComponent implements OnInit, OnDestroy {
  tabOptions = { cross_assessment: '相互評価', pickup: 'ピックアップ', teacher_assessment: '先生コメント' };
  currentTab = 'cross_assessment';

  activityRecord: ActivityRecord;
  activityRecordLoaded = false;
  displayedAlbums: Array<Album> = [];
  canShowMoreAlbums = false;
  displayedSpaces: Array<Space> = [];
  canShowMoreSpaces = false;
  isUpdated: boolean;
  workPath: string | null;
  activityType: string;

  errMsg: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activityRecordService: ActivityRecordService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((obj) => {
      const activityRecordId = +this.route.snapshot.paramMap.get('id');
      this.loadActivityRecordDetail(activityRecordId);
    });
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  loadActivityRecordDetail(activityRecordId) {
    this.activityRecordLoaded = false;
    this.activityRecordService.getTeacherActivityDetail(activityRecordId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.buildActivityInfo();
        this.registerTabInfo();
        this.activityRecordLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  buildActivityInfo() {
    this.getActivityType();
    this.buildWorkPath();
    this.displayedAlbums = this.activityRecord.albums.slice(0, 2);
    this.canShowMoreAlbums = this.activityRecord.albums.length > this.displayedAlbums.length;
    this.displayedSpaces = this.activityRecord.public_spaces.slice(0, 2);
    this.canShowMoreSpaces = this.activityRecord.public_spaces.length > this.displayedSpaces.length;
    this.isUpdated = this.activityRecord.updated_at !== '-';
  }

  registerTabInfo() {
    if (this.isDocumentRecord()) {
      this.route.queryParams.subscribe((params) => {
        const tab = params['tab'];
        if (tab && Object.keys(this.tabOptions).includes(tab)) {
          this.currentTab = tab;
          this.sendChangeTabGA();
        } else {
          this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
        }
      });
    }
  }

  sendChangeTabGA() {
    const actions = {
      cross_assessment: '活動記録詳細相互評価確認',
      pickup: '活動記録詳細ピックアップ確認',
      teacher_assessment: '活動記録詳細先生コメント確認',
    };
  }

  showMoreAlbums() {
    this.displayedAlbums = this.activityRecord.albums;
    this.canShowMoreAlbums = false;
  }

  showMoreSpaces() {
    this.displayedSpaces = this.activityRecord.public_spaces;
    this.canShowMoreSpaces = false;
  }

  private getActivityType() {
    if (this.activityRecord.record_type === 'Questionnaire') {
      this.activityType = 'アンケート回答';
      return;
    }
    this.activityType = this.activityRecord.is_answer ? '課題回答' : '自主作成';
  }

  private buildWorkPath() {
    if (!!this.activityRecord.work) {
      this.workPath = `/works/teacher/delivery/${this.activityRecord.work.id}`;
    } else {
      this.workPath = null;
    }
  }

  isQuestionnaireRecord() {
    return this.activityRecord.record_type === 'Questionnaire';
  }

  isDocumentRecord() {
    return this.activityRecord.record_type === 'Document';
  }
}
