import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Assessment } from '@components/assessment/assessment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@models/user';
import { AssessmentService } from '@components/assessment/assessment.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { AuthUserService } from '@services/auth-user.service';
import { RouterService } from '@services/router.service';
import { Observable } from 'rxjs';
import { validateForm } from '@functions/validate-form';
import { ToastService } from '@services/toast/toast.service';
import { ErrorService } from '@services/error.service';

@Component({
  selector: 'app-request-review-assessment',
  templateUrl: '../request-review-assessment/request-review-assessment.component.html',
  styleUrls: ['../request-review-assessment/request-review-assessment.component.scss'],
})
export class RequestReviewAssessmentComponent implements OnInit, OnDestroy {
  @Input() crossAssessment: Assessment;

  @Output() cancelEditAssessment = new EventEmitter<any>();
  @Output() updateOldAssessment = new EventEmitter<any>();

  form: UntypedFormGroup;
  formErrors = {};
  validationMessages = {
    review_reason: {
      maxlength: '見直し依頼理由は500文字以内で入力してください。',
    },
  };
  currentUser: User;

  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  @ViewChild('textArea', { static: true }) textArea: ElementRef;

  isSubmitting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assessmentService: AssessmentService,
    private confirmDialogService: ConfirmDialogService,
    private authUserService: AuthUserService,
    private routerService: RouterService,
    private errorService: ErrorService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.currentUser = this.authUserService.retrieve();
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  createForm() {
    this.form = this.formBuilder.group({
      review_reason: ['', [Validators.maxLength(500)]],
    });

    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
    this.textArea.nativeElement.focus();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.confirmDialogService
      .showConfirm({
        title: '確認',
        content: '見直し依頼を修正することはできません。<br>このまま見直しを依頼しますか？',
        acceptButton: '依頼する',
        cancelButton: 'キャンセル',
        isPrimary: true,
      })
      .subscribe((accept) => {
        if (!accept) {
          return;
        }

        this.isSubmitting = true;
        this.assessmentService.updateRequestReview(this.crossAssessment.id, this.form.value).subscribe(
          (response) => {
            this.isSubmitting = false;
            this.updateOldAssessment.emit(response);
            this.toastService.showToast('見直しを依頼しました');
          },
          (error) => {
            this.isSubmitting = false;
            this.errorService.addError(error);
          },
        );
      });
  }

  cancelEdit() {
    this.cancelEditAssessment.emit();
  }
}
