import { FileUploader } from 'ng2-file-upload';

export class WorkTemplateQuestion {
  id: number;
  content: string;
  order: number;
  image_attributes: {};
  image: string;
  image_local?: string;
  fileUploader?: FileUploader;
  created_at: string;
  is_single_choice?: boolean;
}
