import { User } from '@models/user';
import { AuthUserService } from '@services/auth-user.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StoryComment } from '@models/story-comment';
import { StoryCommentTeacherService } from '@components/story/teacher/story-comment/story-comment-teacher.service';
import { LayoutService } from '@components/layout/layout.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { validateForm } from '@functions/validate-form';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-story-comment-form-teacher',
  templateUrl: './story-comment-form-teacher.component.html',
  styleUrls: ['./story-comment-form-teacher.component.scss'],
})
export class StoryCommentFormTeacherComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  errMsg: string;
  formErrors = {};
  validationMessages = {
    content: {
      maxlength: 'コメントは5000文字以内で入力してください。',
    },
  };
  currentUser: User;
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  @Input() storyUserId: number;
  @Input() grade: string;
  @Input() storyComment: StoryComment;
  @Output() saveNewStoryComment = new EventEmitter<StoryComment>();
  @Output() cancelEditStoryComment = new EventEmitter<any>();
  @Output() updateOldStoryComment = new EventEmitter<StoryComment>();
  @Output() deleteOldStoryComment = new EventEmitter<StoryComment>();

  @ViewChild('textArea', { static: true }) textArea: ElementRef;

  isSubmitting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private storyCommentTeacherService: StoryCommentTeacherService,
    private confirmDialogService: ConfirmDialogService,
    public layoutService: LayoutService,
    private authUserService: AuthUserService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.getCreateForm(this.storyComment);
    this.currentUser = this.authUserService.retrieve();
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  onSubmit() {
    if (this.form.invalid || this.isContentInvalid()) {
      return;
    }
    this.isSubmitting = true;
    this.form.controls.content.disable({ onlySelf: true });
    if (this.isEditForm()) {
      this.updateStoryComment();
    } else {
      this.createStoryComment();
    }
  }

  getCreateForm(storyComment: StoryComment = null) {
    if (this.isEditForm()) {
      this.getForm(storyComment);
      this.textArea.nativeElement.focus();
    } else {
      const emptyStoryComment = {
        id: null,
        content: '',
        is_read: false,
        is_updated: false,
        created_at: '',
        updated_at: '',
        create_user_id: null,
        story_user_id: null,
      };
      this.getForm(emptyStoryComment);
    }
  }

  getForm(storyComment: StoryComment) {
    this.form = this.formBuilder.group({
      content: [storyComment.content || '', [Validators.maxLength(5000)]],
    });

    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  createStoryComment() {
    this.storyCommentTeacherService.createTeacherStoryComment(this.defaultParams()).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        if (!response.error_code) {
          this.saveNewStoryComment.emit(response);
          this.getCreateForm();
          this.routerService.resetDataConfirm();
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        this.errMsg = <any>error;
      },
    );
  }

  updateStoryComment() {
    this.storyCommentTeacherService.updateTeacherStoryComment(this.storyComment.id, this.form.value).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        if (!response.error_code) {
          this.updateOldStoryComment.emit(response);
          this.routerService.resetDataConfirm();
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        this.errMsg = <any>error;
      },
    );
  }

  deleteStoryComment() {
    if (this.isSubmitting) {
      return;
    }

    this.confirmDialogService
      .showConfirm({
        title: '削除確認',
        content: 'この内容は削除され、見ることができなくなります。<br> 本当に削除しますか？',
        acceptButton: '削除する',
        cancelButton: 'キャンセル',
      })
      .subscribe((accept) => {
        if (!accept) {
          return;
        }
        this.isSubmitting = true;
        this.storyCommentTeacherService.deleteTeacherStoryComment(this.storyComment.id).subscribe(
          (response) => {
            this.isSubmitting = false;
            this.deleteOldStoryComment.emit(response);
          },
          (error) => {
            this.isSubmitting = false;
            this.errMsg = <any>error;
          },
        );
      });
  }

  cancelEdit() {
    this.cancelEditStoryComment.emit();
  }

  isEditForm() {
    return !!this.storyComment;
  }

  isContentInvalid() {
    const newContent = this.form.value.content.trim();
    return newContent === '' || (!!this.storyComment && newContent === this.storyComment.content);
  }

  get isShowCommentInfo(): boolean {
    return this.isEditForm() && this.layoutService.isTabletDownView.value;
  }

  private defaultParams() {
    const params = { story_user_id: this.storyUserId, content: this.form.value.content };
    if (this.grade) {
      params['grade'] = +this.grade;
    }

    return params;
  }
}
