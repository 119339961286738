import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormActivityPickupComponent } from '@components/activity-pickup/form-activity-pickup/form-activity-pickup.component';
import { ActivityRecordTemplatesComponent } from '@components/activity-record-templates/teacher/activity-record-templates.component';
import { ActivityRecordTemplateFormComponent } from '@components/activity-record-templates/teacher/form/activity-record-template-form.component';
import { ActivityRecordDetailStudentComponent } from '@components/activity-record/activity-record-detail/student/activity-record-detail-student.component';
import { ActivityRecordDetailTeacherComponent } from '@components/activity-record/activity-record-detail/teacher/activity-record-detail-teacher.component';
import { TeacherActivityRecordsListComponent } from '@components/activity-record/activity-records-list/teacher/teacher-activity-records-list.component';
import { FormEditAnswerComponent } from '@components/activity-record/form-edit-answer/form-edit-answer.component';
import { FormEditQuestionnaireComponent } from '@components/activity-record/form-edit-questionnaire/form-edit-questionnaire.component';
import { AlbumDetailStudentComponent } from '@components/album/student/album-detail/album-detail-student.component';
import { FormAddGoalAlbumComponent } from '@components/album/student/form-add-goal-album/form-add-goal-album.component';
import { FormAlbumStudentComponent } from '@components/album/student/form-album/form-album-student.component';
import { AlbumDetailTeacherComponent } from '@components/album/teacher/album-detail-teacher/album-detail-teacher.component';
import { DeliveredAlbumsListComponent } from '@components/album/teacher/delivered-albums-list/delivered-albums-list.component';
import { FormAlbumTeacherComponent } from '@components/album/teacher/form-album/form-album-teacher.component';
import { TeacherAlbumsListComponent } from '@components/album/teacher/teacher-albums-list/teacher-albums-list.component';
import { TeacherDeliveredAlbumDetailComponent } from '@components/album/teacher/teacher-delivered-album-detail/teacher-delivered-album-detail.component';
import { FormDocumentComponent } from '@components/document/form-document/form-document.component';
import { ForbiddenComponent } from '@components/forbidden/forbidden.component';
import { GatewayTimeoutComponent } from '@components/gateway-timeout/gateway-timeout.component';
import { GraduationMessageDetailComponent as StudentGraduationMessageDetailComponent } from '@components/graduation-message/student/graduation-message-detail/graduation-message-detail.component';
import { PortfolioGraduationComponent } from '@components/graduation-message/student/portfolio-graduation/portfolio-graduation.component';
import { GraduationMessageDetailComponent as TeacherGraduationMessageDetailComponent } from '@components/graduation-message/teacher/graduation-message-detail/graduation-message-detail.component';
import { GraduationMessageFormComponent } from '@components/graduation-message/teacher/graduation-message-form/graduation-message-form.component';
import { GraduationMessageListComponent } from '@components/graduation-message/teacher/graduation-message-list/graduation-message-list.component';
import { HomeComponent } from '@components/layout/home/home.component';
import { ManavisionDetailTeacherComponent } from '@components/manavision/detail/teacher/manavision-detail-teacher.component';
import { MySpaceComponent } from '@components/my-space/my-space.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { ShowNotificationComponent } from '@components/notification/show-notification/show-notification.component';
import { FormSpaceComponent } from '@components/space/form-space/form-space.component';
import { StudentActivityDetailComponent } from '@components/space/space-detail/student-activity-detail/student-activity-detail.component';
import { StudentSpaceDetailComponent } from '@components/space/space-detail/student-space-detail/student-space-detail.component';
import { TeacherSpaceDetailComponent } from '@components/space/space-detail/teacher-space-detail/teacher-space-detail.component';
import { SpacesListStudentComponent } from '@components/space/spaces-list/spaces-list-student/spaces-list-student.component';
import { SpacesListTeacherComponent } from '@components/space/spaces-list/spaces-list-teacher/spaces-list-teacher.component';
import { FormStoryComponent } from '@components/story/student/form-story/form-story.component';
import { StoriesComponent as StuendStoriesComponent } from '@components/story/student/stories/stories.component';
import { StoriesListComponent } from '@components/story/teacher/stories-list/stories-list.component';
import { StoriesComponent as TeacherStoriesComponent } from '@components/story/teacher/stories/stories.component';
import { StudentDetailComponent } from '@components/student/student-detail/student-detail.component';
import { StudentsListComponent } from '@components/student/students-list/students-list.component';
import { TeacherTimelineComponent } from '@components/timeline/teacher-timeline/teacher-timeline.component';
import { CreateWorkComponent } from '@components/work/create-work/create-work.component';
import { EditWorkComponent } from '@components/work/edit-work/edit-work.component';
import { FormAnswerWorkComponent } from '@components/work/form-answer-work/form-answer-work.component';
import { TeacherDeliveryWorksListComponent } from '@components/work/teacher/delivery-works-list/delivery-works-list.component';
import { WorkDetailStudentComponent } from '@components/work/work-detail/student/work-detail-student.component';
import { TeacherWorkDetailComponent } from '@components/work/work-detail/teacher-work-detail/teacher-work-detail.component';
import { CanDeactivateGuard } from '@guards/can-deactivate-guard';
import { RedirectSpacesRoutesGuard } from '@guards/redirect-spaces-routes.guard';
import * as StudentRouteGuard from '@guards/student-route.guard';
import * as TeacherRouteGuard from '@guards/teacher-route.guard';
import { AuthUserService } from '@services/auth-user.service';
import { AppCustomPreloader } from '@strategies/app-custom-preloader';

const routes: Routes = [
  {
    path: '',
    canActivate: [() => inject(AuthUserService).isAuthenticated()],
    data: {
      titleH1: '',
      isShowLink: false,
    },
    children: [
      {
        path: 'spaces',
        children: [
          {
            path: 'teacher',
            canLoad: [TeacherRouteGuard.canLoad],
            canActivateChild: [TeacherRouteGuard.canActivateChild],
            children: [
              { path: '', component: SpacesListTeacherComponent, data: { titleH1: 'スペース一覧', isShowLink: false } },
              {
                path: 'new',
                component: FormSpaceComponent,
                data: { titleH1: 'スペース作成', isShowSidebar: false, isShowLink: false },
              },
              { path: ':id', component: TeacherSpaceDetailComponent, data: { titleH1: 'スペース詳細', isShowLink: false } },
              {
                path: ':spaceId',
                children: [
                  {
                    path: 'edit',
                    component: FormSpaceComponent,
                    canDeactivate: [CanDeactivateGuard],
                    data: { titleH1: 'スペース変更', isShowSidebar: false, isShowLink: false },
                  },
                ],
              },
            ],
          },
          {
            path: 'student',
            canLoad: [StudentRouteGuard.canLoad],
            canActivateChild: [StudentRouteGuard.canActivateChild],
            children: [
              { path: '', component: SpacesListStudentComponent },
              { path: ':id', component: StudentSpaceDetailComponent },
              {
                path: ':spaceId',
                children: [
                  {
                    path: 'activity_records/:activityId',
                    component: StudentActivityDetailComponent,
                    canDeactivate: [CanDeactivateGuard],
                  },
                  {
                    path: 'works',
                    children: [
                      { path: ':id', component: WorkDetailStudentComponent },
                      { path: ':workId/answer', component: FormAnswerWorkComponent, canDeactivate: [CanDeactivateGuard] },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '',
            pathMatch: 'full',
            children: [],
            canActivate: [RedirectSpacesRoutesGuard],
          },
        ],
      },
      {
        path: 'documents',
        canLoad: [StudentRouteGuard.canLoad],
        canActivate: [StudentRouteGuard.canActivate],
        children: [
          { path: 'new', component: FormDocumentComponent, canDeactivate: [CanDeactivateGuard] },
          { path: ':document_id/edit', component: FormDocumentComponent, canDeactivate: [CanDeactivateGuard] },
        ],
      },
      {
        path: 'activity-records',
        children: [
          {
            path: 'teacher',
            canLoad: [TeacherRouteGuard.canLoad],
            canActivateChild: [TeacherRouteGuard.canActivateChild],
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: TeacherActivityRecordsListComponent,
                data: { titleH1: '活動記録一覧', isShowLink: false },
              },
              {
                path: ':id',
                component: ActivityRecordDetailTeacherComponent,
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: '活動記録詳細', isShowLink: true },
              },
            ],
          },
          {
            path: 'student',
            canLoad: [StudentRouteGuard.canLoad],
            canActivateChild: [StudentRouteGuard.canActivateChild],
            children: [
              { path: '', redirectTo: '/my-space', pathMatch: 'full' },
              { path: ':id', component: ActivityRecordDetailStudentComponent, canDeactivate: [CanDeactivateGuard] },
              { path: ':id/editAnswer', component: FormEditAnswerComponent, canDeactivate: [CanDeactivateGuard] },
              { path: ':id/editQuestionnaire', component: FormEditQuestionnaireComponent, canDeactivate: [CanDeactivateGuard] },
            ],
          },
        ],
      },
      {
        path: 'graduation-messages',
        children: [
          {
            path: 'teacher',
            canLoad: [TeacherRouteGuard.canLoad],
            canActivateChild: [TeacherRouteGuard.canActivateChild],
            children: [
              {
                path: 'new',
                component: GraduationMessageFormComponent,
                data: {
                  titleH1: '卒業メッセージ配信',
                  isShowSidebar: false,
                  isShowLink: false,
                },
              },
              {
                path: '',
                component: GraduationMessageListComponent,
                data: {
                  titleH1: '卒業メッセージ一覧',
                  isShowLink: false,
                },
              },
              {
                path: ':id',
                component: TeacherGraduationMessageDetailComponent,
                data: {
                  titleH1: '卒業メッセージ詳細',
                  isShowLink: true,
                },
              },
            ],
          },
          {
            path: 'student',
            canLoad: [StudentRouteGuard.canLoad],
            canActivateChild: [StudentRouteGuard.canActivateChild],
            children: [
              {
                path: '',
                component: PortfolioGraduationComponent,
                canDeactivate: [CanDeactivateGuard],
                data: {
                  titleH1: 'ポートフォリオ卒業',
                  isShowLink: false,
                },
              },
              {
                path: ':id',
                component: StudentGraduationMessageDetailComponent,
                data: {
                  titleH1: '卒業メッセージ詳細',
                  isShowLink: true,
                },
              },
            ],
          },
        ],
        data: { preload: true },
      },
      { path: 'my-space', component: MySpaceComponent, canLoad: [StudentRouteGuard.canLoad], canActivate: [StudentRouteGuard.canActivate] },
      {
        path: 'albums',
        children: [
          {
            path: 'teacher',
            canLoad: [TeacherRouteGuard.canLoad],
            canActivateChild: [TeacherRouteGuard.canActivateChild],
            children: [
              {
                path: 'delivery/new',
                component: FormAlbumTeacherComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: 'アルバム配信', isShowSidebar: false, isShowLink: false },
              },
              {
                path: '',
                component: TeacherAlbumsListComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                data: { titleH1: 'アルバム一覧', isShowLink: false },
              },
              {
                path: 'delivery',
                component: DeliveredAlbumsListComponent,
                data: { titleH1: '配信アルバム一覧', isShowLink: false },
              },
              {
                path: ':id/students/:student_id',
                component: AlbumDetailTeacherComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                data: { titleH1: 'アルバム詳細', isShowLink: true },
              },
              {
                path: 'delivery/:id',
                component: TeacherDeliveredAlbumDetailComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                data: { titleH1: '配信アルバム詳細', isShowLink: true },
              },
              {
                path: 'delivery/:id/edit',
                component: FormAlbumTeacherComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: '配信アルバム変更', isShowSidebar: false },
              },
            ],
          },
          {
            path: 'student',
            canLoad: [StudentRouteGuard.canLoad],
            canActivateChild: [StudentRouteGuard.canActivateChild],
            children: [
              { path: 'new', component: FormAlbumStudentComponent, canDeactivate: [CanDeactivateGuard] },
              { path: ':id/edit', component: FormAlbumStudentComponent, canDeactivate: [CanDeactivateGuard] },
              { path: ':id', component: AlbumDetailStudentComponent, canDeactivate: [CanDeactivateGuard] },
              { path: ':id/add-goal-outline', component: FormAddGoalAlbumComponent },
            ],
          },
        ],
      },
      {
        path: 'works',
        canLoad: [TeacherRouteGuard.canLoad],
        canActivate: [TeacherRouteGuard.canActivate],
        children: [
          {
            path: 'teacher/delivery',
            component: TeacherDeliveryWorksListComponent,
            data: { titleH1: '配信課題一覧', isShowLink: false },
          },
          {
            path: 'new',
            component: CreateWorkComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { titleH1: '課題配信', isShowSidebar: false },
          },
          {
            path: ':id/edit',
            component: EditWorkComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { titleH1: '課題配信設定変更', isShowLink: false, isShowSidebar: false },
          },
          {
            path: 'teacher/delivery/:id',
            component: TeacherWorkDetailComponent,
            data: { titleH1: '配信課題詳細', isShowLink: true },
          },
        ],
      },
      {
        path: 'activity-record-templates',
        canLoad: [TeacherRouteGuard.canLoad],
        canActivate: [TeacherRouteGuard.canActivate],
        children: [
          {
            path: 'teacher',
            children: [
              {
                path: '',
                component: ActivityRecordTemplatesComponent,
                data: { titleH1: '活動記録テンプレート一覧', isShowLink: false },
              },
              {
                path: 'new',
                component: ActivityRecordTemplateFormComponent,
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: '活動記録テンプレート作成', isShowSidebar: false, isShowLink: false },
              },
              {
                path: ':id/edit',
                component: ActivityRecordTemplateFormComponent,
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: '活動記録テンプレート変更', isShowSidebar: false, isShowLink: false },
              },
            ],
          },
        ],
      },
      { path: 'notifications', component: ShowNotificationComponent },
      {
        path: 'activity-pickups/teacher',
        canActivate: [TeacherRouteGuard.canActivate],
        canActivateChild: [TeacherRouteGuard.canActivateChild],
        children: [
          {
            path: 'new',
            component: FormActivityPickupComponent,
            data: { titleH1: 'ピックアップ設定', isShowSidebar: false, isShowLink: false },
          },
          {
            path: ':id/edit',
            component: FormActivityPickupComponent,
            data: { titleH1: 'ピックアップ設定変更', isShowSidebar: false, isShowLink: false },
          },
        ],
      },
      {
        path: 'students/teacher',
        canActivate: [TeacherRouteGuard.canActivate],
        canLoad: [TeacherRouteGuard.canLoad],
        children: [
          {
            path: '',
            component: StudentsListComponent,
            data: { titleH1: '生徒一覧', isShowLink: false },
          },
          {
            path: ':id',
            component: StudentDetailComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { titleH1: '生徒詳細', isShowLink: true },
          },
        ],
      },
      {
        path: 'manavisions/teacher',
        canLoad: [TeacherRouteGuard.canLoad],
        canActivate: [TeacherRouteGuard.canActivate],
        canActivateChild: [TeacherRouteGuard.canActivateChild],
        children: [{ path: 'detail', component: ManavisionDetailTeacherComponent, data: { titleH1: 'マナビジョンの記録詳細' } }],
      },
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'timeline/teacher',
        canActivate: [TeacherRouteGuard.canActivate],
        canLoad: [TeacherRouteGuard.canLoad],
        children: [
          {
            path: '',
            component: TeacherTimelineComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { titleH1: 'タイムライン', isShowLink: false },
          },
        ],
      },
      {
        path: 'stories',
        children: [
          {
            path: 'teacher',
            canLoad: [TeacherRouteGuard.canLoad],
            canActivateChild: [TeacherRouteGuard.canActivateChild],
            children: [
              { path: '', component: StoriesListComponent, data: { titleH1: 'ストーリー一覧', isShowLink: false } },
              {
                path: 'students/:student_id',
                component: TeacherStoriesComponent,
                canActivate: [TeacherRouteGuard.canActivate],
                data: { titleH1: 'ストーリー詳細', isShowLink: true },
              },
            ],
          },
          {
            path: 'student',
            canLoad: [StudentRouteGuard.canLoad],
            canActivateChild: [StudentRouteGuard.canActivateChild],
            children: [
              { path: '', component: StuendStoriesComponent, data: { titleH1: 'ストーリー', isShowLink: false } },
              {
                path: 'new',
                component: FormStoryComponent,
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: 'ストーリー作成' },
              },
              {
                path: ':id/edit',
                component: FormStoryComponent,
                canDeactivate: [CanDeactivateGuard],
                data: { titleH1: 'ストーリー編集' },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '403',
    component: ForbiddenComponent,
    data: { title: 'エラー' },
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { title: 'エラー' },
  },
  {
    path: '503',
    component: NotFoundComponent,
    data: { title: 'エラー' },
  },
  {
    path: '504',
    component: GatewayTimeoutComponent,
    data: { title: 'エラー' },
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader })],
  exports: [RouterModule],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule {}
