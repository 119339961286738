import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, Renderer2 } from '@angular/core';
import { User } from '@models/user';
import { LayoutService } from '@components/layout/layout.service';
import { SpaceService } from '@components/space/space.service';

@Component({
  selector: 'app-modal-participants-list',
  templateUrl: './modal-participants-list.component.html',
  styleUrls: ['./modal-participants-list.component.scss'],
})
export class ModalParticipantsListComponent implements OnInit, OnDestroy {
  @Input() spaceId: number;
  @Output() eventCancel = new EventEmitter<boolean>();

  tabs = ['student', 'teacher'];
  currentTab = 'student';
  studentsLoaded = false;
  teachersLoaded = false;
  errMsg: string;
  teachers: Array<User> = [];
  students: Array<User> = [];
  queryParams = {};

  teacherMetaData = {
    current_page: 1,
    next_page: null,
    per_page: 50,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
    students_count: 0,
    teachers_count: 0,
    create_user_id: null,
  };

  studentMetaData = {
    current_page: 1,
    next_page: null,
    per_page: 50,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
    students_count: 0,
    teachers_count: 0,
    create_user_id: null,
  };

  constructor(
    public layoutService: LayoutService,
    public spaceService: SpaceService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.queryParams['page'] = 1;
    this.loadStudentsList();
    this.loadTeachersList();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  loadStudentsList() {
    this.studentsLoaded = false;
    this.queryParams['user_type'] = 'student';
    this.spaceService.getSpaceParticipantsList(this.spaceId, this.queryParams).subscribe(
      (response) => {
        this.students = response.users;
        Object.assign(this.studentMetaData, response.meta);
        this.studentsLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadTeachersList() {
    this.teachersLoaded = false;
    this.queryParams['user_type'] = 'teacher';
    this.spaceService.getSpaceParticipantsList(this.spaceId, this.queryParams).subscribe(
      (response) => {
        this.teachers = response.users;
        Object.assign(this.teacherMetaData, response.meta);
        this.teachersLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  get showTeacherPagination(): boolean {
    return this.teacherMetaData.total_pages > 1 && this.teacherTab;
  }

  get showStudentPagination(): boolean {
    return this.studentMetaData.total_pages > 1 && !this.teacherTab;
  }

  get teacherTab(): boolean {
    return this.currentTab === 'teacher';
  }

  get dataLoaded(): boolean {
    return this.teachersLoaded && this.studentsLoaded;
  }

  pageChanged(page: number) {
    this.queryParams['page'] = page;
    this.teacherTab ? this.loadTeachersList() : this.loadStudentsList();
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
  }

  onClose() {
    this.eventCancel.emit(false);
  }
}
