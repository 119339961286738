import { Component, ElementRef, ViewChild, AfterViewChecked, OnDestroy } from '@angular/core';

import { getFileExtension } from '@functions/check-string';
import { isImage, isAudio, isVideo } from '@functions/file-types';

@Component({
  selector: 'app-questionnaire-file-preview',
  templateUrl: './questionnaire-file-preview.component.html',
  styleUrls: ['./questionnaire-file-preview.component.scss'],
})
export class QuestionnaireFilePreviewComponent implements AfterViewChecked, OnDestroy {
  readonly KEYCODES = {
    escape: 27,
  };

  @ViewChild('gallery_content') gallery_content: ElementRef;
  @ViewChild('gallery_caption') gallery_caption: ElementRef;
  @ViewChild('gallery_inner') gallery_inner: ElementRef;

  currentFileName: string;
  currentFilePath: string;

  isShow = false;
  isLoading = false;

  constructor() {}

  ngAfterViewChecked() {
    this.updateContentSize();
  }

  ngOnDestroy() {
    this.unSubscribeEvent();
  }

  onPreview(filePath: string, fileName: string) {
    this.currentFilePath = filePath;
    this.currentFileName = fileName;
    this.isShow = true;
    this.isLoading = true;
    this.subscribeEvent();
  }

  onClose = () => {
    this.isShow = false;
    this.unSubscribeEvent();
  };

  onContentLoad() {
    this.isLoading = false;
  }

  preventCloseOnContent(event: Event) {
    event.stopPropagation();
  }

  private handleKeydown = (e) => {
    if (!this.isShow) {
      return;
    }
    switch (e.keyCode) {
      case this.KEYCODES.escape:
        this.onClose();
        break;
    }
  };

  private updateContentSize() {
    if (this.isShow) {
      const style = this.gallery_content.nativeElement.style;
      style.width = `${this.gallery_inner.nativeElement.clientWidth}px`;
      style.maxWidth = `${this.gallery_inner.nativeElement.clientWidth}px`;
      style.height = `${this.gallery_inner.nativeElement.clientHeight - this.gallery_caption.nativeElement.clientHeight}px`;
      style.maxHeight = `${this.gallery_inner.nativeElement.clientHeight - this.gallery_caption.nativeElement.clientHeight}px`;
    }
  }

  private subscribeEvent() {
    window.addEventListener('keydown', this.handleKeydown, false);
    window.addEventListener('resize', this.updateContentSize, false);
    window.addEventListener('scroll', this.onClose, false);
  }

  private unSubscribeEvent() {
    window.removeEventListener('keydown', this.handleKeydown, false);
    window.removeEventListener('resize', this.updateContentSize, false);
    window.removeEventListener('scroll', this.onClose, false);
  }

  get currentFileIsImage(): boolean {
    return isImage(getFileExtension(this.currentFileName));
  }

  get currentFileIsVideo(): boolean {
    return isVideo(getFileExtension(this.currentFileName));
  }

  get currentFileIsAudio(): boolean {
    return isAudio(getFileExtension(this.currentFileName));
  }
}
