import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { Space } from '@components/space/space';
import { User } from '@models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthUserService } from '@services/auth-user.service';
import { CategoriesService } from '@services/categories/categories.service';
import { ActivityRecordService } from '@components/activity-record/activity-record.service';
import { Assessment } from '@components/assessment/assessment';
import { ToastService } from '@services/toast/toast.service';
import { Observable } from 'rxjs';
import { AssessmentFormStudentComponent } from '@components/assessment/assessment-form/student/assessment-form-student.component';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-activity-record-detail-student',
  templateUrl: './activity-record-detail-student.component.html',
  styleUrls: ['./activity-record-detail-student.component.scss'],
})
export class ActivityRecordDetailStudentComponent implements OnInit, OnDestroy {
  tabOptions = { self: '自分', cross_assessment: 'みんな', teacher_assessment: '先生' };
  tabBadge = { cross_assessment: false, teacher_assessment: false };
  currentTab = 'self';
  activityRecordLoaded = false;
  currentUser: User;
  edittingAssessment: Assessment;
  activityRecord: ActivityRecord;
  errMsg: string;
  isShowDropdownActions = false;
  showPublishActivityModal = false;
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };
  workPath: string | null;

  @ViewChild(AssessmentFormStudentComponent) assessmentForm: AssessmentFormStudentComponent;

  constructor(
    private categoryService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private activityRecordService: ActivityRecordService,
    private location: Location,
    private authUserService: AuthUserService,
    private toastService: ToastService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const activityRecordId = +this.route.snapshot.paramMap.get('id');
    this.currentUser = this.authUserService.retrieve();
    this.route.queryParams.subscribe((params) => {
      if (params['tab'] && Object.keys(this.tabOptions).includes(params['tab'])) {
        this.currentTab = params['tab'];
        this.checkAssessmentsReadStatus();
      }
    });
    this.loadActivityRecordDetail(activityRecordId);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.assessmentForm ? this.assessmentForm.canDeactivate() : true;
  }

  switchTab(tabKey: string) {
    this.router.navigate(['.'], { queryParams: { tab: tabKey }, replaceUrl: true, relativeTo: this.route });
  }

  loadActivityRecordDetail(activityRecordId) {
    this.activityRecordService.getActivityRecord(activityRecordId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.workPath = this.buildWorkPath(this.activityRecord);
        this.activityRecordLoaded = true;
        this.tabBadge = {
          cross_assessment: this.activityRecord.has_unread_cross_assessment,
          teacher_assessment: this.activityRecord.has_unread_teacher_self_assessment,
        };
        this.loadCategoryName();
        this.checkAssessmentsReadStatus();
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadCategoryName() {
    this.categoryService
      .getCategoryName(this.activityRecord.category_id)
      .then((categoryName) => (this.activityRecord.category_name = categoryName));
  }

  checkAssessmentsReadStatus() {
    if (!Object.keys(this.tabBadge).includes(this.currentTab)) {
      return;
    }

    if (this.tabBadge[this.currentTab] === true) {
      this.activityRecordService.updateReadAssessments(this.activityRecord.id, this.currentTab).subscribe(
        (response) => {
          this.tabBadge[this.currentTab] = false;
        },
        (error) => {
          this.errMsg = <any>error;
        },
      );
    }
  }

  onEditRecord() {
    switch (this.activityRecord.record_type) {
      case 'Document':
        const editUrl = this.activityRecord.is_answer
          ? `activity-records/student/${this.activityRecord.id}/editAnswer`
          : `documents/${this.activityRecord.record.id}/edit`;
        this.router.navigateByUrl(editUrl);
        break;
      case 'Questionnaire':
        const editQuestionnaireUrl = `activity-records/student/${this.activityRecord.id}/editQuestionnaire`;
        this.router.navigateByUrl(editQuestionnaireUrl);
        break;
      // implement later when have more record types
    }
  }

  onPublishRecord() {
    this.showPublishActivityModal = true;
  }

  closePublishActivityModal() {
    this.showPublishActivityModal = false;
  }

  publishedToSpaces(spaces: Array<Space>) {
    this.showPublishActivityModal = false;
    this.activityRecord.public_spaces = spaces;
    this.toastService.showToast('設定を完了しました');
  }

  isQuestionnaireRecord() {
    return this.activityRecord.record_type === 'Questionnaire';
  }

  isDocumentRecord() {
    return this.activityRecord.record_type === 'Document';
  }

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  onsaveNewAssessment(assessment) {
    this.activityRecord.assessments.push(assessment);
  }

  onEditAssessment(assessment) {
    this.edittingAssessment = assessment;
  }

  onCancelEditAssessment() {
    this.edittingAssessment = null;
  }

  onUpdateOldAssessment(newAssessment, oldAssessment) {
    this.edittingAssessment = null;
    Object.assign(oldAssessment, newAssessment);
  }

  onDeleteOldAssessment(assessment) {
    this.edittingAssessment = null;
    const removeIndex = this.activityRecord.assessments.findIndex((item) => item === assessment);
    if (removeIndex === -1) {
      return;
    }
    this.activityRecord.assessments.splice(removeIndex, 1);
  }

  isOwnerRecord() {
    return !!this.activityRecord.user && this.activityRecord.user.id === this.currentUser.id;
  }

  dataLoaded(): boolean {
    return this.activityRecordLoaded;
  }

  canEditAssessment(assessment: Assessment) {
    return !this.edittingAssessment && assessment.create_user_id === this.currentUser.id;
  }

  canShowAdditionalInfoSection() {
    if (!this.dataLoaded) {
      return false;
    }

    switch (this.activityRecord.record_type) {
      case 'Document':
        return this.currentTab === 'self' && (this.activityRecord.is_answer || !!this.activityRecord.albums);
      case 'Questionnaire':
        return !!this.activityRecord.albums;
      default:
        return false;
    }
  }

  canEditRecord() {
    return this.dataLoaded && this.isOwnerRecord();
  }

  canEditQuestionnaire() {
    return this.activityRecord.record_type === 'Questionnaire' && this.activityRecord.albums && this.activityRecord.albums.length > 0;
  }

  private buildWorkPath(activityRecord): string | null {
    if (!activityRecord.work_id) {
      return null;
    }
    return `/spaces/student/${activityRecord.space_id}/works/${activityRecord.work_id}`;
  }

  getDevice() {
    if (navigator.userAgent.match(/android/i)) {
      return 'android-device';
    }
    return 'other-device';
  }
}
