import { Component, Input } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
  @Input() steps: Array<string>;
  @Input() currentStep: number;

  constructor(public layoutService: LayoutService) {}
}
