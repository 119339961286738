import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ActivityRecordTemplatesResponse } from './activity-record-templates-response';
import { WorkTemplate } from '@models/work-template';

@Injectable()
export class ActivityRecordTemplatesService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getActivityRecordTemplates(params: {}): Observable<ActivityRecordTemplatesResponse> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/search`;
    return this.http.post<ActivityRecordTemplatesResponse>(url, params);
  }

  createWorkTemplate(params: {}): Observable<WorkTemplate> {
    const url = `${this.apiEndpointV2}/teacher/work_templates`;
    return this.http.post<WorkTemplate>(url, { work_template: params });
  }

  updateWorkTemplate(templateId: number, workTemplate: WorkTemplate): Observable<WorkTemplate> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/${templateId}`;
    return this.http.put<WorkTemplate>(url, { work_template: workTemplate });
  }

  destroyWorkTemplate(templateId: number): Observable<any> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/${templateId}`;
    return this.http.delete(url);
  }

  getWorkTemplate(templateId: number): Observable<WorkTemplate> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/${templateId}`;
    return this.http.get<WorkTemplate>(url);
  }

  getWorkTemplateEdit(templateId: number): Observable<WorkTemplate> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/${templateId}/edit`;
    return this.http.get<WorkTemplate>(url);
  }
}
