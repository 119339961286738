import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { pick, pickBy, isEqual } from 'lodash';

import { CategoriesService } from '@services/categories/categories.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { StudentService } from '@components/student/student.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

import { User } from '@models/user';

@Component({
  selector: 'app-student-manavision-records',
  templateUrl: './student-manavision-records.component.html',
  styleUrls: ['./student-manavision-records.component.scss'],
})
export class StudentManavisionRecordsComponent implements OnInit, OnDestroy {
  @Input() student: User;

  isShowMoreFilter = false;
  formInited = false;

  filterData = {};
  defaultFilterValue = {};

  private readonly allowedParamKeys = ['page', 'category_id'];

  onDestroy$ = new Subject<void>();
  form: UntypedFormGroup;
  activityRecords = [];
  pages = {
    limit_value: 1,
    current_page: 1,
    next_page: 2,
    prev_page: null,
    total_pages: 1,
    total_count: 0,
  };

  contentLoaded = false;
  isSearching = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    private categoriesService: CategoriesService,
    private filterDataService: TeacherFilterService,
    private studentService: StudentService,
    private googleAnalytics4Service: GoogleAnalytics4Service,
  ) {}

  ngOnInit() {
    this.googleAnalytics4Service.sendEvent('活動記録', '生徒詳細画面', 'マナビジョン');

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(async (params) => {
      if (params['tab'] !== 'manavision') {
        return;
      }

      this.filterData = pick(params, this.allowedParamKeys);

      await this.categoriesService.loadCategories();
      await this.filterDataService.setFilter(this.filterData);

      this.loadStudentManavisionRecords(this.student.id, this.filterData);
      this.initForm();
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      category_id: this.filterData['category_id'],
    });

    this.formInited = true;
    this.isSearching = !this.isDefaultForm;
  }

  loadStudentManavisionRecords(studentId: number, params: {}) {
    this.contentLoaded = false;
    this.studentService.getManavisionRecords(studentId, params).subscribe(
      (response) => {
        Object.assign(this.pages, response.meta);
        this.activityRecords = response.activity_records;
        this.contentLoaded = true;
      },
      (error) => {
        // TODO: error handling.
      },
    );
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.isShowMoreFilter = false;
    const params = pickBy(this.form.value);
    params['request_timestamp'] = Date.now();

    this.router.navigate(['.'], { queryParams: this.makeQueryParams(params), relativeTo: this.route, replaceUrl: true });
  }

  onResetFilter() {
    this.form.reset(this.defaultFilterValue);
    if (this.isSearching) {
      this.onSubmit();
    }
  }

  makeQueryParams(params: {}) {
    return Object.assign(params, { tab: 'manavision' });
  }

  pageChanged(page: number) {
    this.router.navigate(['.'], { queryParams: { page: page }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onChangeShowMoreFilter() {
    this.isShowMoreFilter = !this.isShowMoreFilter;
  }

  onClickActivityRecord(activityRecord) {
    this.router.navigateByUrl('/manavisions/teacher/detail', { state: { activityRecord: activityRecord, student: this.student } });
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  get isContentLoading(): boolean {
    return !this.contentLoaded;
  }

  get isDefaultForm(): boolean {
    return isEqual(pickBy(this.form.value), this.defaultFilterValue);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
