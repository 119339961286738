import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { omit, pick, isEqual, mapValues, merge, pickBy, isNil } from 'lodash';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { SpaceService } from '@components/space/space.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';

const IconSort = {
  none: 'none',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-space-graduation-message',
  templateUrl: './space-graduation-message.component.html',
  styleUrls: ['./space-graduation-message.component.scss'],
})
export class SpaceGraduationMessageComponent implements OnInit {
  graduationMessages: Array<GraduationMessage> = [];
  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };
  spaceId: number;
  isMobileFilterOpen = false;
  filterForm: UntypedFormGroup;
  messageSort: string;
  sortKey = 'created_at';
  sortOrder = 'desc';
  formLoaded = false;
  contentLoaded = false;
  isSorting = false;
  queryParams = {};
  isSearching = false;

  sortStatus = {
    created_at: IconSort.down,
  };
  distributorSelections = [
    { value: null, text: 'すべて' },
    { value: 'myself', text: '自分' },
    { value: 'other_teachers', text: '自分以外の先生' },
  ];
  sortSelections = [
    { value: 'created_at desc', text: '配信日時 (降順)' },
    { value: 'created_at asc', text: '配信日時 (昇順)' },
  ];
  private readonly allowedParamKeys = ['distributor', 'keyword', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['keyword'];
  readonly saveConditionKeys = ['distributor', 'sort_key', 'sort_order'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    private spaceService: SpaceService,
    private formBuilder: UntypedFormBuilder,
    private searchConditionSavingService: SearchConditionSavingService,
    private navigationService: TeacherNavigationService,
  ) {}

  ngOnInit() {
    this.spaceId = +this.route.snapshot.paramMap.get('id');
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 1) {
      newParams = this.searchConditionSavingService.getSearchConditions(url, 'graduation_message');
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions, 'graduation_message');
    }

    this.setQueryParamsFilter(newParams);
    this.initForm();
    this.initSortIcon();
    this.loadSpaceGraduationMessage(this.queryParams);
    this.messageSort = this.sortText();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  setQueryParamsFilter(params = {}) {
    // 空文字も含め不要なパラメータを削除する
    this.queryParams = pick(pickBy(params), this.allowedParamKeys);
    // 配信日時はデフォルト降順で取得する
    this.queryParams['sort_key'] = params['sort_key'] || 'created_at';
    this.queryParams['sort_order'] = params['sort_order'] || 'desc';
    // ページはデフォルト1ページで取得する
    this.queryParams['page'] = +params['page'] || 1;
  }

  toggleFilter() {
    this.isMobileFilterOpen = !this.isMobileFilterOpen;
  }

  get showPagination(): boolean {
    return this.metaData['total_pages'] > 1;
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  private initForm() {
    const params = pick(this.queryParams, this.allowedParamKeys);
    this.filterForm = this.formBuilder.group({
      distributor: params['distributor'] || this.distributorSelections[0].value,
      keyword: params['keyword'] || '',
      sort: params['sort_key'] ? `${params['sort_key']} ${params['sort_order']}` : this.sortSelections[0].value,
    });
    this.formLoaded = true;
    this.isSearching = !this.isDefaultForm;
  }

  initSortIcon() {
    this.sortKey = this.queryParams['sort_key'] || this.sortKey;
    this.sortOrder = this.queryParams['sort_order'] || this.sortOrder;
  }

  defaultFilterValue() {
    const filterValues = {
      distributor: null,
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort'] = this.sortSelections[0].value;
    }

    return filterValues;
  }

  onGraduationMessageClick(graduationMessage: GraduationMessage) {
    let params = pickBy(this.queryParams);
    params = omit(params, 'page');
    params = merge(params, { space_id: this.spaceId });

    this.navigationService.registerNavigator({ screenId: 6, filterData: params });
    this.router.navigateByUrl(`graduation-messages/teacher/${graduationMessage.id}`);
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.trimTextInput();
    this.isMobileFilterOpen = false;
    const params = omit(this.filterForm.value, 'sort');

    this.buildOrderParams(params);
    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    params['request_timestamp'] = Date.now();
    this.navigate(params, 'merge');
  }

  private trimTextInput() {
    const keyword = this.filterForm.value.keyword;
    this.filterForm.get('keyword').setValue(keyword.trim());
  }

  resetForm() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[0].value.split(' ');
    }

    this.filterForm.patchValue(this.defaultFilterValue());
    this.onSubmit();
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  onChangeOrder(field) {
    if (this.isSorting) {
      return;
    }

    this.sortKey = field;
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.updateSortStatus();
    this.filterForm.get('sort').setValue(`${this.sortKey} ${this.sortOrder}`);
    const params = pick(this.queryParams, this.allowedParamKeys);
    this.buildOrderParams(params);
    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    this.navigate(params, 'merge');
  }

  private updateSortStatus() {
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);

    if (!this.contentLoaded) {
      // when the list was just loaded
      this.contentLoaded = true;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else if (this.isSorting) {
      // when the list was just sorted
      this.isSorting = false;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else {
      // when the list is being sorted
      this.isSorting = true;
      this.sortStatus[this.sortKey] = IconSort.loader;
    }
  }

  private loadSpaceGraduationMessage(params = {}) {
    if (!this.isSorting) {
      this.contentLoaded = false;
    }

    this.spaceService.getTeacherGraduationMessagesOfSpace(this.spaceId, params).subscribe(
      (response) => {
        this.graduationMessages = response.graduation_messages;
        Object.assign(this.metaData, response.meta);

        this.updateSortStatus();
      },
      (error) => {
        this.updateSortStatus();
      },
    );
  }

  private buildOrderParams(params) {
    params['sort_key'] = this.sortKey || 'created_at';
    params['sort_order'] = this.sortOrder || 'desc';
  }

  filterText() {
    const match = this.distributorSelections.filter((item) => {
      return item.value === this.queryParams['distributor'];
    });
    const distributor = (match[0] && match[0].text) || this.distributorSelections[0].text;
    const keyword = this.queryParams['keyword'];
    return keyword ? `${distributor}/${keyword}` : distributor;
  }

  sortText() {
    const match = this.sortSelections.filter((item) => {
      return item.value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match[0] && match[0].text;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.filterForm.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  get isLoadingData(): boolean {
    return this.isSorting || !this.contentLoaded;
  }
}
