import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SpaceService } from './../../space.service';
import { TeacherSpaceResponse } from './../../teacher-space-response';

@Component({
  selector: 'app-teacher-space-detail',
  templateUrl: './teacher-space-detail.component.html',
  styleUrls: ['./teacher-space-detail.component.scss'],
})
export class TeacherSpaceDetailComponent implements OnInit {
  tabOptions = {
    timeline: 'アクティビティ',
    works: '配信課題',
    albums: '配信アルバム',
    activity_records: '公開中の活動記録',
    graduation_message: '卒業メッセージ',
  };
  currentTab = 'timeline';

  spaceDetail: TeacherSpaceResponse;
  errMsg: string;
  spaceLoaded = false;
  pages = {
    per_page: 1,
    current_page: 1,
    total_pages: 1,
    total_count: 0,
    start_item_index: 0,
    end_item_index: 0,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spaceService: SpaceService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((_obj) => {
      const spaceId = +this.route.snapshot.paramMap.get('id');
      this.loadSpaceDetail(spaceId);
    });

    this.route.queryParams.subscribe((params) => {
      if (params['tab'] && Object.keys(this.tabOptions).includes(params['tab'])) {
        this.currentTab = params['tab'];
      } else {
        this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
      }
    });
  }

  loadSpaceDetail(spaceId) {
    this.spaceLoaded = false;
    this.spaceService.getTeacherSpace(spaceId).subscribe(
      (response) => {
        this.spaceDetail = response;
        Object.assign(this.pages, response.pages);
        this.spaceLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }
}
