import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/components/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormDocumentComponent } from './form-document/form-document.component';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { FileUploaderModule } from '../../shared/components/file-uploader/file-uploader.module';
import { AssessmentModule } from '@components/assessment/assessment.module';
import { ContentSavingService } from '@services/content-saving.service';

@NgModule({
  declarations: [FormDocumentComponent],
  exports: [FormDocumentComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    FileUploaderModule,
    NgxPaginationModule,
    AssessmentModule,
    InfiniteScrollModule,
  ],
  providers: [ContentSavingService],
})
export class DocumentModule {}
