import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';

import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { AuthUserService } from '@services/auth-user.service';

import * as loadImage from 'blueimp-load-image';
import * as Sentry from '@sentry/angular';
import { extractCharBetween } from '@functions/extractCharBetween';

const AllowedFileExtensions = [
  'txt',
  'csv',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'ppsx',
  'pdf',
  'jpg',
  'jpeg',
  'gif',
  'png',
  'mp4',
  'mpeg4',
  '3gp',
  'mov',
  'avi',
  'mp3',
  'm4a',
  'wma',
  'zip',
  'pages',
  'numbers',
  'key',
];
const NeedProcessingTypes = ['jpg', 'jpeg', 'png'];
const AllowedImageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
const MaxFileSizeInMB = 300;
const MaxImageFileSizeInMB = 50;
const MaxFileSizeInByte = MaxFileSizeInMB * 1024 * 1024;
const MaxImageFileSizeInByte = MaxImageFileSizeInMB * 1024 * 1024;
const MaxPreviewImageSize = 30 * 1024 * 1024;
const MaxThumbHeight = 1280;
const MaxThumbWidth = 1280;

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [S3CredentialService, UploadFilesService],
})
export class FileUploaderComponent implements OnInit, AfterViewChecked {
  @Input() uploader: FileUploader = new FileUploader({ url: '' });
  @Input() option = {};
  @Input() canEdit = true;
  @Input() index = 0;
  @Input() attachedFiles: Array<any> = [];
  @Output() uploadFilesStatus: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventSelectFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  attachFilesErrMsg: string;
  serverResponseErrMsg: string;
  @Input() removedAttachFiles = [];
  showProgressbar = false;
  canceledUploadFiles: boolean;

  @ViewChild('upload_div', { static: true }) upload_div;

  constructor(
    protected s3CredentialService: S3CredentialService,
    protected uploadFilesService: UploadFilesService,
    public authUserService: AuthUserService,
    protected renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.uploader.onAfterAddingFile = async (fileItem) => {
      const isImageTypeNeedsProcessing = NeedProcessingTypes.includes(this.getFileExtension(fileItem.file.name));
      if (!isImageTypeNeedsProcessing || (isImageTypeNeedsProcessing && fileItem.file.size > MaxImageFileSizeInByte)) {
        return;
      }

      const { exif } = await loadImage.parseMetaData(fileItem._file);
      const options = { canvas: true, maxWidth: MaxThumbWidth, maxHeight: MaxThumbHeight };
      const haveOrientation = exif && exif.get('Orientation');
      if (haveOrientation) {
        options['orientation'] = exif.get('Orientation');
      }
      const img = new Image();
      const url = URL.createObjectURL(fileItem._file);
      img.src = url;

      img
        .decode()
        .then(() => {
          if (img.naturalWidth <= MaxThumbWidth && img.naturalHeight <= MaxThumbHeight && !haveOrientation) return;

          loadImage(
            fileItem._file,
            (canvas) => {
              canvas.toBlob((blob) => {
                blob.name = fileItem._file.name;
                fileItem._file = blob;
                this.updateFileToBlob(fileItem);
                this.changeDetectorRef.detectChanges();
              }, fileItem._file.type);
            },
            options,
          );
        })
        .finally(() => {
          URL.revokeObjectURL(url);
        });
    };

    this.uploader.onAfterAddingAll = (fileItems) => {
      if (this.option['singleSelector']) {
        if (this.attachedFiles) {
          this.attachedFiles.forEach((file) => this.removeAttachedFile(file));
          this.attachedFiles = [];
        }
        this.uploader.queue = [fileItems[0]];
      }
      if (this.isNotAllowedFileExtensions(fileItems) || this.isNotAllowedFileSizes(fileItems)) {
        this.removeItemsFromQueue(fileItems);
      } else {
        this.attachFilesErrMsg = null;
      }
    };

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const uploadFileParams = fileItem['uploadFileParams'] || {};
      for (const key of Object.keys(uploadFileParams)) {
        form.append(key, uploadFileParams[key]);
      }
    };

    this.uploader.onCompleteAll = () => {
      window.setTimeout(() => {
        this.showProgressbar = false;
        if (this.uploader.queue.every((fileItem) => fileItem.isSuccess)) {
          this.uploadFilesStatus.emit('completed');
        } else {
          this.uploadFilesStatus.emit('failed');
        }
      }, 500);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (!Sentry) return;

      const formDataArray: any[] = [];
      const formData: FormData = item.formData;
      formData.forEach((value: FormDataEntryValue, key: string) => {
        let val = value;
        if (value instanceof File) {
          val = `ファイル名：${val['name']} / タイプ：${val['type']}`;
        }
        formDataArray.push(key + ' ' + val);
      });
      // 下記のようなresponseが返ってくる想定
      // ex. <Error><Code>AccessDenied</Code><Message>Invalid according to Policy: Policy expired.</Message>...
      const code = extractCharBetween(response, '<Code>', '</Code>');
      const message = extractCharBetween(response, '<Message>', '</Message>');
      const s3key: string = item?.['uploadFileParams']?.['key'] ?? '';
      const scope = new Sentry.Scope().setContext('Error context', {
        S3Key: s3key,
        statusCode: status,
        file_type: item._file.type,
        file_size: item._file.size,
      });
      Sentry.captureException(new Error(`file upload error: ${code}. ${message}`), scope);
    };
  }

  ngAfterViewChecked() {
    this.resize();
  }

  isNotAllowedFileExtensions(fileItems) {
    for (const fileItem of fileItems) {
      const fileExtension = this.getFileExtension(fileItem.file.name);
      fileItem.fileExtension = fileExtension;
      const listAllowExtension = this.option['onlyImage'] ? AllowedImageExtensions : AllowedFileExtensions;
      if (!listAllowExtension.includes(fileExtension)) {
        this.attachFilesErrMsg = `ファイル拡張子が未対応です。`;
        return true;
      }
    }
    return false;
  }

  isNotAllowedFileSizes(fileItems) {
    let statusTypeError = null;

    for (const fileItem of fileItems) {
      if (NeedProcessingTypes.includes(this.getFileExtension(fileItem.file.name))) {
        if (fileItem.file.size > MaxImageFileSizeInByte) {
          statusTypeError = statusTypeError === 'normal' ? 'both' : 'image';
        }
      } else {
        if (fileItem.file.size > MaxFileSizeInByte) {
          statusTypeError = statusTypeError === 'image' ? 'both' : 'normal';
        }
      }

      if (statusTypeError === 'both') {
        break;
      }
    }

    switch (statusTypeError) {
      case 'both':
        this.attachFilesErrMsg = `画像の大きさは${MaxImageFileSizeInMB}MB以下にしてください。
          \nファイルの大きさは${MaxFileSizeInMB}MB以下にしてください。`;
        break;
      case 'image':
        this.attachFilesErrMsg = `画像の大きさは${MaxImageFileSizeInMB}MB以下にしてください。`;
        break;
      case 'normal':
        this.attachFilesErrMsg = this.option['onlyImage']
          ? `画像の大きさは${MaxFileSizeInMB}MB以下にしてください。`
          : `ファイルの大きさは${MaxFileSizeInMB}MB以下にしてください。`;
        break;
    }

    return !!statusTypeError;
  }

  isShowPreview(fileItem) {
    return this.isImage(fileItem) && fileItem.file.size < MaxPreviewImageSize;
  }

  isImage(fileItem) {
    const fileExtension = this.getFileExtension(fileItem.file.name);
    return AllowedImageExtensions.includes(fileExtension);
  }

  sendUploadedFiles() {
    const uploadedFiles = this.uploader.queue
      .filter((fileItem) => fileItem.isSuccess)
      .map((fileItem) => {
        return {
          uuid: fileItem['uuid'],
          file_name: fileItem.file.name,
          file_extension: fileItem['fileExtension'],
        };
      });
    return uploadedFiles.concat(this.removedAttachFiles);
  }

  uploadAll() {
    if (this.uploader.queue.length === 0 || this.uploader.queue.every((fileItem) => fileItem.isSuccess)) {
      this.showProgressbar = false;
      this.uploadFilesStatus.emit('completed');
      return;
    }

    const uploadFileItems = this.prepareFilesToUpload();
    if (uploadFileItems.length > 0) {
      this.uploadFilesStatus.emit('uploading');
      this.s3CredentialService.getPolicyParams(uploadFileItems).subscribe(
        (response) => {
          const uploadFilesParams = response.upload_links;
          uploadFileItems.forEach((fileItem, index) => {
            fileItem.url = uploadFilesParams[index].endpoint_url;
            fileItem['uuid'] = uploadFilesParams[index].uuid;
            fileItem['uploadFileParams'] = uploadFilesParams[index].url_properties;
          });
          if (!this.canceledUploadFiles) {
            this.uploadFiles(uploadFileItems);
          }
        },
        (error) => {
          this.serverResponseErrMsg = <any>error;
          if (!this.canceledUploadFiles) {
            this.uploadFiles(uploadFileItems);
          }
        },
      );
    }
  }

  prepareFilesToUpload() {
    this.showProgressbar = true;
    this.uploader.progress = 0;
    let uploadingFiles;
    if (this.canceledUploadFiles) {
      this.canceledUploadFiles = false;
      uploadingFiles = this.uploader.queue;
    } else {
      uploadingFiles = this.uploader.queue.filter((fileItem) => !fileItem.isUploaded || fileItem.isError);
    }
    uploadingFiles.forEach((fileItem) => {
      fileItem.isCancel = false;
      fileItem.isError = false;
      fileItem.isSuccess = false;
      fileItem.isUploaded = false;
    });
    return uploadingFiles;
  }

  uploadFiles(fileItems) {
    fileItems.forEach((fileItem) => fileItem.upload());
  }

  getFileExtension(fileName) {
    return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
  }

  removeAttachedFile(file) {
    if (!!this.attachedFiles) {
      this.attachedFiles = this.attachedFiles.filter((fileItem) => fileItem.id !== file.id);
      this.removedAttachFiles.push({
        id: file.id,
        _destroy: true,
      });
    }
  }

  removeItemsFromQueue(fileItems) {
    fileItems.forEach((fileItem) => this.uploader.removeFromQueue(fileItem));
  }

  cancelUploadingFiles() {
    this.showProgressbar = false;
    this.canceledUploadFiles = true;
    this.uploader.cancelAll();
    this.uploadFilesStatus.emit('pending');
    const uploadedFileNames = this.uploader.queue
      .filter((fileItem) => fileItem.isUploaded && fileItem.isSuccess)
      .map((fileItem) => fileItem['uploadFileParams']['key']);

    if (uploadedFileNames.length > 0) {
      this.deleteCanceledFiles(uploadedFileNames);
    }
  }

  deleteCanceledFiles(fileNames: Array<string>) {
    this.uploadFilesService.deleteCanceledFiles(fileNames).subscribe(
      (response) => {},
      (error) => (this.serverResponseErrMsg = <any>error),
    );
  }

  resize() {
    if (!this.upload_div) {
      return;
    }
    const imageDiv = this.upload_div.nativeElement.querySelectorAll('.attach-file');
    for (const div of imageDiv) {
      this.renderer.setStyle(div, 'height', getComputedStyle(div).width);
    }
  }

  onSelectFile($event) {
    this.eventSelectFile.emit(true);
  }

  get acceptTypes(): string {
    return this.option['onlyImage'] ? 'image/png,image/gif,image/jpeg' : null;
  }

  protected updateFileToBlob(blobItem: FileItem): void {}
}
