import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { AlbumService } from '@components/album/album.service';
import { TeacherDeliveredAlbumDetail } from '@components/album/teacher/teacher-delivered-album-detail/models/teacher-delivered-album-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherAlbumsStudent } from '@components/album/teacher/teacher-delivered-album-detail/models/teacher-albums-student';
import { mapValues, pick, omit, isEqual } from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// TODO: make this const reusable
const IconSort = {
  none: 'none',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};
const DefaultFilterValue = {
  sort: 'class_name desc',
};

@Component({
  selector: 'app-teacher-delivered-album-detail',
  templateUrl: './teacher-delivered-album-detail.component.html',
  styleUrls: ['./teacher-delivered-album-detail.component.scss'],
})
export class TeacherDeliveredAlbumDetailComponent implements OnInit, OnDestroy {
  albumId: number;
  albumDetail: TeacherDeliveredAlbumDetail;
  albumsStudents: Array<TeacherAlbumsStudent>;
  errMsg: string;
  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };
  filterForm: UntypedFormGroup;
  routerSubscriber = null;

  albumLoaded = false;
  studentsListLoaded = false;
  isMobileFilterOpen = false;

  showCommandsBox = false;
  isSorting = false;
  formLoaded = false;

  sortSelections = [
    { value: 'class_name desc', text: '校種学年組番 (降順)' },
    { value: 'class_name asc', text: '校種学年組番 (昇順)' },
    { value: 'last_activity_at desc', text: '更新日時 (降順)' },
    { value: 'last_activity_at asc', text: '更新日時 (昇順)' },
    { value: 'activity_records_count desc', text: '活動記録数 (降順)' },
    { value: 'activity_records_count asc', text: '活動記録数 (昇順)' },
  ];
  sortStatus = {
    class_name: IconSort.down,
    last_activity_at: IconSort.none,
    activity_records_count: IconSort.none,
  };
  sortKey = 'class_name';
  sortOrder = 'desc';
  queryParams = {};
  private readonly allowedParamKeys = ['sort_key', 'sort_order'];

  constructor(
    public layoutService: LayoutService,
    private albumService: AlbumService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.albumId = +this.route.snapshot.paramMap.get('id');
    this.loadTeacherDeliveredAlbumDetail();
    this.routerSubscriber = this.route.queryParams.subscribe((params) => {
      Object.assign(this.queryParams, params);
      this.queryParams['page'] = +params['page'] || 1;
      this.loadTeacherDeliveredAlbumsStudents(this.queryParams);
    });
  }

  ngOnDestroy() {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }

  loadTeacherDeliveredAlbumDetail() {
    this.albumLoaded = false;
    this.albumService.getTeacherDeliveredAlbumDetail(this.albumId).subscribe(
      (response) => {
        this.albumDetail = response;
        this.albumLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadTeacherDeliveredAlbumsStudents(params: {}) {
    if (!this.isSorting) {
      this.studentsListLoaded = false;
    }

    this.albumService.getTeacherDeliveredAlbumsStudents(this.albumId, params).subscribe(
      (response) => {
        this.albumsStudents = response.albums_students;
        Object.assign(this.metaData, response.meta);

        if (!this.formLoaded) {
          this.initForm();
          this.initSortIcon();
        }
        this.updateSortStatus();
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  toggleFilter() {
    this.isMobileFilterOpen = !this.isMobileFilterOpen;
  }

  toggleCommandsBox() {
    this.showCommandsBox = !this.showCommandsBox;
  }

  pageChanged(page: number) {
    this.reloadList({ page: page });
  }

  sortText() {
    const match = this.sortSelections.filter((item) => {
      return item.value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match[0] && match[0].text;
  }

  get showPagination(): boolean {
    return this.metaData['total_pages'] > 1;
  }

  onChangeOrder(field) {
    if (this.isSorting) {
      return;
    }

    if (this.sortKey === field) {
      this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortKey = field;
      this.sortOrder = 'desc';
    }

    this.updateSortStatus();
    this.filterForm.get('sort').setValue(`${this.sortKey} ${this.sortOrder}`);
    const params = pick(this.queryParams, this.allowedParamKeys);
    this.buildOrderParams(params);

    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    this.reloadList(params);
  }

  onClickStudent(albumId: number, studentId: number) {
    this.router.navigateByUrl(`/albums/teacher/${albumId}/students/${studentId}`);
  }

  private updateSortStatus() {
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);

    if (!this.studentsListLoaded) {
      // when the list was just loaded
      this.studentsListLoaded = true;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else if (this.isSorting) {
      // when the list was just sorted
      this.isSorting = false;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else {
      // when the list is being sorted
      this.isSorting = true;
      this.sortStatus[this.sortKey] = IconSort.loader;
    }
  }

  private initForm() {
    const params = pick(this.queryParams, this.allowedParamKeys);
    this.filterForm = this.formBuilder.group({
      sort: params['sort_key'] ? `${params['sort_key']} ${params['sort_order']}` : this.sortSelections[0].value,
    });
    this.formLoaded = true;
  }

  private initSortIcon() {
    this.sortKey = this.queryParams['sort_key'] || this.sortKey;
    this.sortOrder = this.queryParams['sort_order'] || this.sortOrder;
    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = IconSort[icon];
  }

  private reloadList(params) {
    this.router.navigate(['.'], { queryParams: params, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.isMobileFilterOpen = false;
    const params = omit(this.filterForm.value, 'sort');
    [this.sortKey, this.sortOrder] = this.filterForm.get('sort').value.split(' ');
    this.buildOrderParams(params);
    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    this.reloadList(params);
  }

  resetForm() {
    this.filterForm.patchValue(DefaultFilterValue);
    this.onSubmit();
  }

  private buildOrderParams(params) {
    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
  }

  isDefaultForm() {
    return isEqual(this.filterForm.value, DefaultFilterValue);
  }

  stopOuterLinkEvent(event: Event) {
    event.stopPropagation();
  }

  createWork() {
    const space = this.albumDetail.space;
    space['has_album'] = true;
    this.router.navigateByUrl('/works/new', { state: { album: this.albumDetail, space: space } });
  }
}
