import { Injectable } from '@angular/core';

@Injectable()
export class NativeInterfaceService {
  clickBackKeyDeferred: Promise<any>;
  private resolvePromise;

  getClickBackKeyPromise() {
    if (!this.clickBackKeyDeferred) {
      this.clickBackKeyDeferred = new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
      });
    }
    return this.clickBackKeyDeferred;
  }

  destructionClickBackKeyPromise() {
    this.clickBackKeyDeferred = null;
    this.resolvePromise = null;
  }

  clickBackKey() {
    if (this.clickBackKeyDeferred) {
      this.resolvePromise();
      this.clickBackKeyDeferred = null;
      return false;
    }
    return true;
  }
}
