import { Injectable } from '@angular/core';

import { Space } from '../space';
import { User } from '@models/user';

@Injectable()
export class SpaceCreatorService {
  steps = ['入力', '確認', '完了'];
  previousStep: number;
  currentStep: number;
  space: Space;
  existedStudents = Array<User>();
  existedTeachers = Array<User>();

  constructor() {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.space = {
      id: null,
      name: '',
      users_count: 0,
      color_code: '',
      created_at: '',
      updated_at: '',
      school_year: null,
      create_user_id: null,
      update_user_id: '',
      teacher_can_pick_up: true,
      student_can_publish: true,
      student_can_comment_other: true,
      can_be_destroyed: false,
    };
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }
}
