import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { mergeMap, map, filter } from 'rxjs/operators';

import { User } from '@models/user';
import { AuthUserService } from '@services/auth-user.service';
import { LayoutService } from '@components/layout/layout.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-teacher-header',
  templateUrl: './teacher-header.component.html',
  styleUrls: ['./teacher-header.component.scss'],
})
export class TeacherHeaderComponent implements OnInit {
  currentUser: User;
  title: string;
  titleDefault: string;
  readonly classiTopUrl = environment.platformUrl;
  readonly helpPageUrl = environment.helpPageUrl;
  readonly profileUrl = `${environment.platformUrl}/profile`;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authUserService: AuthUserService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.titleDefault = 'ポートフォリオ';
    this.currentUser = this.authUserService.retrieve();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.title = event['title'] || this.titleDefault;
        this.titleService.setTitle(this.title);
      });
  }

  showGlobalMenu() {
    this.layoutService.showGlobalMenu();
  }
}
