import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { Document } from '@components/document/document';
import { Mark } from '@models/mark';

@Component({
  selector: 'app-marks-selector',
  templateUrl: './marks-selector.component.html',
  styleUrls: ['./marks-selector.component.scss'],
})
export class MarksSelectorComponent implements OnInit {
  @Input() centeredLabel = false;
  @Input() marks: Array<Mark>;
  @Input() formObject: Document | ActivityRecord;
  @Input() selectedIds = [];
  @Output() eventChangeMark = new EventEmitter();
  selectedMarks: Array<Mark>;

  ngOnInit() {
    this.selectedMarks = this.buildMarksSelect();
    if (this.selectedIds.length > 0) {
      this.selectedIds = this.selectedIds.map((id) => parseInt(id, 10));
      this.selectedMarks.filter((mark) => this.selectedIds.includes(mark.id)).forEach((mark) => (mark.checked = true));
    } else if (!!this.formObject) {
      this.updateMarksSelects(this.formObject);
    }
  }

  changeMark(selectedIds) {
    this.selectedMarks = this.buildMarksSelect();
    this.selectedIds = selectedIds;
    this.selectedMarks.filter((mark) => this.selectedIds.includes(mark.id)).forEach((mark) => (mark.checked = true));
  }

  updateMarksSelects(input: Document | ActivityRecord) {
    if (!input['activity_records_marks']) {
      return;
    }

    const existedMarkIds = input['activity_records_marks'].map((item) => item.mark_id);
    this.selectedMarks.filter((mark) => existedMarkIds.includes(mark.id)).forEach((mark) => (mark.checked = true));
  }

  checkBoxChange(mark: Mark) {
    mark.checked = !mark.checked;
    this.eventChangeMark.emit(true);
  }

  private buildMarksSelect() {
    return this.marks.map((mark) => Object.assign(new Mark(), mark, { checked: false }));
  }
}
