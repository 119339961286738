import { Component, Output, EventEmitter, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-word-cloud-wait-modal',
  templateUrl: './word-cloud-wait-modal.component.html',
  styleUrls: ['./word-cloud-wait-modal.component.scss'],
})
export class WordCloudWaitModalComponent implements OnInit, OnDestroy {
  @Output() closeFilter = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onClose() {
    this.closeFilter.emit();
  }
}
