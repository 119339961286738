import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraduationMessage } from '@components/graduation-message/graduation-message';

import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { SlideMenuService } from '@services/slide-menu.service';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-graduation-message-detail',
  templateUrl: './graduation-message-detail.component.html',
  styleUrls: ['./graduation-message-detail.component.scss'],
})
export class GraduationMessageDetailComponent implements OnInit, OnDestroy {
  graduationMessageDetail: GraduationMessage;
  graduationMessageDetailLoaded = false;

  routerSubscriber = null;

  constructor(
    private route: ActivatedRoute,
    private graduationMessageService: GraduationMessageService,
    private slideMenuService: SlideMenuService,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.routerSubscriber = this.route.params.subscribe(() => {
      const graduationMessageId = +this.route.snapshot.paramMap.get('id');
      this.loadGraduationMessageDetail(graduationMessageId);
    });
  }

  ngOnDestroy() {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }

  loadGraduationMessageDetail(graduationMessageId) {
    this.graduationMessageDetailLoaded = false;
    this.graduationMessageService.getStudentGraduationMessage(graduationMessageId).subscribe(
      (response) => {
        this.graduationMessageDetail = response;
        this.graduationMessageDetailLoaded = true;
      },
      (error) => {},
    );
  }

  slideMenu() {
    this.slideMenuService.slideIn();
  }
}
