<div class="tea-spaces-list-page">
  <div class="create-space">
    <a
      routerLink="/spaces/teacher/new"
      class="create-button"
      data-cy="transit-form"
      [ngClass]="[layoutService.isTabletDownView.value ? 'spen-util-link' : 'spen-mod-btn']"
      >スペース作成</a
    >
  </div>
  <div class="filter" *ngIf="formInited">
    <div *ngIf="layoutService.isTabletDownView.value">
      <div class="sort">
        <span class="order">
          <p>検索条件</p>
          <span data-cy="text-filter">{{ messageFilter }}</span>
        </span>
        <span class="order">
          <p>並び順</p>
          <span data-cy="text-sort-order">{{ messageSort }}</span>
        </span>
      </div>
      <span class="btn-show-detail" (click)="showMore()">
        <a class="spen-util-link">
          <span>変更</span>
          <i [ngClass]="[isShowMore ? 'fa fa-chevron-up' : 'fa fa-chevron-down']" data-cy="show-filter-status"></i>
        </a>
      </span>
    </div>
    <div [formGroup]="form" [ngClass]="toggleFilterOnMobileView()">
      <div class="align-center" [ngClass]="{ 'filter-content content-border': !layoutService.isTabletDownView.value }">
        <p>年度</p>
        <label class="spen-mod-select">
          <select class="select" formControlName="school_year" data-cy="school-year">
            <option data-cy="school-year-item" *ngFor="let year of schoolYears; let first = first" [value]="year">{{ year }}年度</option>
          </select>
          <i class="fa fa-caret-down"></i>
        </label>
      </div>
      <div class="align-center" [class.filter-content]="!layoutService.isTabletDownView.value">
        <p>権限</p>
        <label class="spen-mod-radio" data-cy="scope-radio">
          <input class="radio" data-cy="scope" type="radio" value="personal" formControlName="scope" />
          <i class="fa"></i>
          <span class="text">担当している</span>
        </label>
        <label class="spen-mod-radio" data-cy="scope-radio">
          <input class="radio" data-cy="scope" type="radio" value="school" formControlName="scope" />
          <i class="fa"></i>
          <span class="text">担当していない</span>
        </label>
        <p class="keyword">スペース名</p>
        <input
          class="spen-mod-input-text"
          data-cy="search-title"
          formControlName="keyword"
          maxlength="30"
          placeholder="スペース名を入力してください"
          type="text"
        />
      </div>
      <div class="align-center" *ngIf="layoutService.isTabletDownView.value">
        <p>並び順</p>
        <label class="spen-mod-select">
          <select class="select" formControlName="sort_value" data-cy="sort-order" (change)="onChangeSortValue($event.target.value)">
            <option
              data-cy="sort-order-item"
              class="select-option"
              *ngFor="let sortSelection of sortSelections"
              [value]="sortSelection.sort_value"
            >
              {{ sortSelection.text }}
            </option>
          </select>
          <i class="fa fa-caret-down"></i>
        </label>
      </div>
      <div class="btn-search">
        <button
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          type="button"
          (click)="resetForm()"
          [disabled]="isLoadingData || isDefaultForm"
        >
          選択解除
        </button>
        <button class="spen-mod-btn is-primary" data-cy="submit-filter" type="button" (click)="onSubmit()" [disabled]="isLoadingData">
          {{ layoutService.isTabletDownView.value ? '適用する' : '検索する' }}
        </button>
      </div>
    </div>
  </div>

  <div class="filter-info" *ngIf="dataLoaded">
    <p class="filter-result-info" data-cy="text-filter" *ngIf="!layoutService.isTabletDownView.value && !isEmptyList">
      検索条件：{{ messageFilter }}
    </p>
    <p class="page-info" data-cy="text-page-info" *ngIf="!inProgressSort && !isEmptySearchResults">{{ metaData | paginateText }}</p>
  </div>

  <div *ngIf="dataLoaded; else loadingIcon">
    <ng-container *ngIf="!layoutService.isTabletDownView.value; else spView">
      <div *ngIf="!isEmptySearchResults; else emptyList" class="spen-mod-flex-table table-list-full has-link">
        <div class="flex-table-head">
          <div class="flex-item col-size-6 is-first-col">
            <a class="spen-util-link" data-cy="col-space-name" (click)="changeBySortColumn('name')">
              スペース名
              <i [class]="sortStatus['name']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item col-size-4">作成者</div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-created-at" (click)="changeBySortColumn('created_at')">
              作成日時
              <i [class]="sortStatus['created_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-updated-at" (click)="changeBySortColumn('updated_at')">
              更新日時
              <i [class]="sortStatus['updated_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item redirect-cell"></div>
        </div>
        <a
          class="flex-table-row"
          data-cy="space-item"
          *ngFor="
            let space of spaces
              | paginate
                : {
                    itemsPerPage: this.metaData.per_page,
                    currentPage: this.metaData.current_page,
                    totalItems: this.metaData.total_count,
                  }
          "
          (click)="onClickSpace(space.id)"
        >
          <div class="flex-item col-size-6 is-first-col truncate-text">
            <div class="space-name">
              <p class="space-color" data-cy="space-color" [style.background-color]="space.color_code"></p>
              <p class="name" data-cy="space-name">{{ space.name }}</p>
            </div>
          </div>
          <div class="flex-item col-size-4 truncate-text" data-cy="create-user-name">{{ space.create_user_name }}</div>
          <div class="flex-item col-size-3" data-cy="created-at">{{ space.created_at }}</div>
          <div class="flex-item col-size-3" data-cy="updated-at">{{ space.updated_at }}</div>
          <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
        </a>
      </div>
    </ng-container>

    <ng-template #spView>
      <div class="spaces-list" *ngIf="!isEmptySearchResults; else emptyList">
        <div
          class="space"
          data-cy="space-item"
          *ngFor="
            let space of spaces
              | paginate
                : {
                    itemsPerPage: this.metaData.per_page,
                    currentPage: this.metaData.current_page,
                    totalItems: this.metaData.total_count,
                  }
          "
          (click)="onClickSpace(space.id)"
        >
          <div class="space-info">
            <div class="space-name">
              <p class="space-color" data-cy="space-color" [style.background-color]="space.color_code"></p>
              <p class="name" data-cy="space-name">{{ space.name }}</p>
            </div>
            <p class="creator truncate-text" data-cy="create-user-name">作成者：{{ space.create_user_name }}</p>
            <p class="small-text" data-cy="created-at">作成日時：{{ space.created_at }}</p>
            <p class="small-text" data-cy="updated-at">更新日時：{{ space.updated_at }}</p>
          </div>
          <div class="cell-inner"><i class="fa fa-chevron-right"></i></div>
        </div>
      </div>
    </ng-template>

    <div class="space-pagination">
      <div *ngIf="showPagination">
        <app-pagination-controls *ngIf="!layoutService.isTabletDownView.value" (pageChange)="pageChanged($event)"></app-pagination-controls>
        <app-pagination-controls
          *ngIf="layoutService.isTabletDownView.value"
          (pageChange)="pageChanged($event)"
          directionLinks="false"
          [stylePaginate]="'small'"
        ></app-pagination-controls>
      </div>
    </div>
  </div>

  <ng-template #loadingIcon>
    <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
  </ng-template>

  <ng-template #emptyList>
    <div class="null-content">
      <p class="text">{{ isSearching ? '検索結果が0件です。' : 'ここに校内のスペースが表示されます。' }}</p>
    </div>
  </ng-template>
</div>
