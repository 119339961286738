import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssessmentModule } from '../assessment/assessment.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedPipesModule } from '@pipes/shared-pipes.module';

import { TeacherActivityRecordsListComponent } from './activity-records-list/teacher/teacher-activity-records-list.component';
import { ActivityRecordDetailTeacherComponent } from './activity-record-detail/teacher/activity-record-detail-teacher.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ActivityRecordDetailTeacherComponent, TeacherActivityRecordsListComponent],
  exports: [ActivityRecordDetailTeacherComponent, TeacherActivityRecordsListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SharedDirectivesModule,
    AssessmentModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    SharedPipesModule,
  ],
})
export class ActivityRecordTeacherModule {}
