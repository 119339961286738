import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { GraduationMessage } from './graduation-message';
import { TeacherGraduationMessageResponse } from './teacher/graduation-message-list/teacher-graduation-message-response';
import { TeacherSelectableSpacesResponse } from '@components/teacher/space-picker/teacher-selectable-spaces-response';
import { StudentGraduationMessageResponse } from './student/portfolio-graduation/student-graduation-message-response';
import { StudentGraduationContentsResponse } from './student/portfolio-graduation/student-graduation-contents-response';
import { StudentSummary } from './student/portfolio-graduation/student-summary';
import { IsDeliveredResponse } from './student/is-delivered-response';
import { AttachmentStatusResponse } from './student/portfolio-graduation/attachment-status-response';

@Injectable()
export class GraduationMessageService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  createGraduationMessage(graduationMessage: GraduationMessage): Observable<GraduationMessage> {
    const url = `${this.apiEndpointV2}/teacher/graduation_messages`;
    return this.http.post<GraduationMessage>(url, { graduation_message: graduationMessage });
  }

  getTeacherGraduationMessages(params = {}): Observable<TeacherGraduationMessageResponse> {
    const url = `${this.apiEndpointV2}/teacher/graduation_messages/search`;
    return this.http.post<TeacherGraduationMessageResponse>(url, params);
  }

  getTeacherGraduationMessageDetail(id: number): Observable<GraduationMessage> {
    const url = `${this.apiEndpointV2}/teacher/graduation_messages/${id}`;
    return this.http.get<GraduationMessage>(url);
  }

  deleteGraduationMessage(id: number): Observable<any> {
    const url = `${this.apiEndpointV2}/teacher/graduation_messages/${id}`;
    return this.http.delete(url);
  }

  getTeacherSelectableSpaces(params: {}): Observable<TeacherSelectableSpacesResponse> {
    const url = `${this.apiEndpointV2}/teacher/graduation_messages/selectable_spaces`;
    return this.http.post<TeacherSelectableSpacesResponse>(url, params);
  }

  getStudentGraduationMessages(params = {}): Observable<StudentGraduationMessageResponse> {
    const url = `${this.apiEndpointV2}/student/graduation_messages`;
    return this.http.get<StudentGraduationMessageResponse>(url, { params: params });
  }

  getStudentSummaries(): Observable<Array<StudentSummary>> {
    const url = `${this.apiEndpointV2}/student/graduation_contents/summary`;
    return this.http.get<Array<StudentSummary>>(url);
  }

  getStudentGraduationContentsStatus(): Observable<AttachmentStatusResponse> {
    const url = `${this.apiEndpointV2}/student/graduation_contents/status`;
    return this.http.get<AttachmentStatusResponse>(url);
  }

  createStudentGraduationContents(): Observable<any> {
    const url = `${this.apiEndpointV2}/student/graduation_contents`;
    return this.http.post(url, null);
  }

  getStudentGraduationContents(): Observable<StudentGraduationContentsResponse> {
    const url = `${this.apiEndpointV2}/student/graduation_contents/attachment`;
    return this.http.get<StudentGraduationContentsResponse>(url);
  }

  getStudentIsDeliverdGraduationMessages(): Observable<IsDeliveredResponse> {
    const url = `${this.apiEndpointV2}/student/graduation_messages/delivered_status`;
    return this.http.get<IsDeliveredResponse>(url);
  }

  getStudentGraduationMessage(id: number): Observable<GraduationMessage> {
    const url = `${this.apiEndpointV2}/student/graduation_messages/${id}`;
    return this.http.get<GraduationMessage>(url);
  }
}
