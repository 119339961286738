import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/components/shared.module';
import { GraduationMessageTeacherModule } from './graduation-message-teacher.module';
import { GraduationMessageStudentModule } from './graduation-message-student.module';
import { GraduationMessageService } from './graduation-message.service';

@NgModule({
  imports: [CommonModule, SharedModule, GraduationMessageTeacherModule, GraduationMessageStudentModule],
  providers: [GraduationMessageService],
})
export class GraduationMessageModule {}
