<div class="stu-work-detail-issue" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <a (click)="onBack()"><i class="fa fa-chevron-left"></i></a>アンケート回答編集
    </h1>
  </div>

  <div class="template">
    <div class="descr-heading">アンケートの回答内容はアンケート機能から変更してください。</div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group albums">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">アルバム</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <ng-container *ngIf="activityRecord.albums_activity_records?.length > 0; else not_registered">
              <p class="album" *ngFor="let albums_activity_record of activityRecord.albums_activity_records">
                ・ <span class="album-name">{{ albums_activity_record.album_name }}</span>
                <a (click)="deleteActivityRecordFromAlbum(albums_activity_record)" *ngIf="albums_activity_record.can_delete">取り出す</a>
              </p>
            </ng-container>
            <ng-template #not_registered>未登録</ng-template>
          </div>
        </div>
      </div>

      <p class="classname" *ngIf="activityRecord.space_name">※{{ activityRecord.space_name }}に保存されます</p>
      <div class="block-last">
        <button type="submit" class="spen-mod-btn is-primary" [disabled]="isSubmitting">編集を完了する</button>
        <button type="button" class="spen-mod-btn" (click)="onBack()">戻る</button>
      </div>
    </form>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
