import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { WordCloudResponse } from './word-cloud-response';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable()
export class WordCloudService {
  apiEndpointV2 = environment.apiEndpointV2;
  wordCloudImageUrl = environment.wordCloudImageUrl;
  title: string;
  errorMessage: string;
  imageUrl: SafeUrl;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  getWordCloudDocumentId(params = {}): Observable<WordCloudResponse> {
    const url = `${this.apiEndpointV2}/teacher/word_clouds/fetch_word_cloud`;
    return this.http.get<WordCloudResponse>(url, { params: params });
  }

  makeTitleAboutActivityRecord(studentName: string) {
    const resizedStudentName = this.resizeName(studentName, 20);
    this.title = resizedStudentName + 'さんの活動記録の概況';
  }

  makeTitleAboutAlbum(studentName: string, albumTitle: string) {
    const resizedStudentName = this.resizeName(studentName, 8);
    const resizedAlbumTitle = this.resizeName(albumTitle, 15);
    this.title = resizedAlbumTitle + ' (' + resizedStudentName + ') の概況';
  }

  makeTitleAboutWork(workTitle: string) {
    const resizedWorkTitle = this.resizeName(workTitle, 22);
    this.title = resizedWorkTitle + 'の活動記録の概況';
  }

  get modalTitle() {
    return this.title;
  }

  get modalImageUrl() {
    return this.imageUrl;
  }

  get modalErrorMessage() {
    return this.errorMessage;
  }

  setFetchErrorMessage() {
    this.errorMessage = '概況を表示するには１件以上の活動記録が必要です。';
  }

  setServerErrorMessage() {
    this.errorMessage = 'サーバーとの通信時にエラーが発生しました。';
  }

  setOverCountErrorMessage() {
    this.errorMessage = '概況を表示可能な活動記録は５０００件までです。';
  }

  generateWordCloudUrl(document_id) {
    if (document_id) {
      return `${this.wordCloudImageUrl}?document_id1=${document_id}`;
    } else {
      return null;
    }
  }

  registerWordCloudUrl(document_id) {
    const url = this.generateWordCloudUrl(document_id);
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  resizeName(name: string, limit: number): string {
    if (name.length > limit) {
      name = name.substring(0, limit);
      name = name + '...';
    }
    return name;
  }
}
