<div class="stu-activity-records-sp" *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="filter-box" *ngIf="formInited">
    <div class="filter-text-info">
      <div class="text-row">
        <p class="label">検索条件</p>
        <p class="filter-result" data-cy="text-filter">
          {{ filterDataService.messageFilter }}
        </p>
      </div>
    </div>
    <a class="btn-show-detail spen-util-link" (click)="onChangeShowMoreFilter()">
      <span>変更</span>
      <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
    </a>
    <form [formGroup]="form" *ngIf="isShowMoreFilter">
      <div class="filter-detail">
        <div class="filter-group">
          <div class="label-text">カテゴリー</div>
          <div class="filter-inputs">
            <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
            </app-teacher-categories-selection>
          </div>
        </div>
        <div class="button-group">
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
            適用する
          </button>
          <button
            type="button"
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            (click)="onResetFilter()"
            [disabled]="isContentLoading || isDefaultForm"
          >
            選択解除
          </button>
        </div>
      </div>
    </form>
  </div>
  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="data-box" *ngIf="activityRecords.length > 0; else noActivityRecord">
      <p class="paginate-result" data-cy="text-page-info" *ngIf="contentLoaded">{{ pages | paginateText }}</p>
      <div class="list-activity-records">
        <div
          class="activity-record clickable-item"
          data-cy="activity-item"
          *ngFor="
            let record of activityRecords
              | paginate
                : {
                    itemsPerPage: this.pages.limit_value,
                    currentPage: this.pages.current_page,
                    totalItems: this.pages.total_count,
                  }
          "
          (click)="onClickActivityRecord(record)"
        >
          <div class="info">
            <p class="title label-text truncate-text" data-cy="activity-title">{{ record.title }}</p>
            <p class="create-time small-text" data-cy="activity-created-at">作成日時：{{ record.created_at }}</p>
            <p class="update-time small-text" data-cy="activity-updated-at">更新日時：{{ record.updated_at }}</p>
          </div>
          <i class="fa fa-chevron-right"></i>
        </div>
      </div>
      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #pcView>
  <div class="stu-activity-records">
    <form [formGroup]="form" *ngIf="formInited">
      <div class="filter-box">
        <div class="filter-detail">
          <div class="filter-group">
            <div class="filter-label">
              <b>カテゴリー</b>
            </div>
            <div class="filter-inputs">
              <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
              </app-teacher-categories-selection>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn right is-default"
            data-cy="remove-filter"
            (click)="onResetFilter()"
            [disabled]="isContentLoading || isDefaultForm"
          >
            選択解除
          </button>
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
            検索する
          </button>
        </div>
      </div>
    </form>
    <div class="filter-info" *ngIf="contentLoaded">
      <p class="filter-result-info" data-cy="text-filter" *ngIf="isSearching || activityRecords.length > 0">
        検索条件：{{ filterDataService.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
    </div>
    <ng-container *ngIf="contentLoaded; else loadingIcon">
      <div class="data-box" *ngIf="activityRecords.length > 0; else noActivityRecord">
        <div class="spen-mod-flex-table table-list-full has-link">
          <div class="flex-table-head">
            <div class="flex-item col-size-10 is-first-col">活動記録名</div>
            <div class="flex-item col-size-3">作成日時</div>
            <div class="flex-item col-size-3">更新日時</div>
            <div class="flex-item redirect-cell"></div>
          </div>
          <a
            class="flex-table-row"
            data-cy="activity-item"
            *ngFor="
              let record of activityRecords
                | paginate
                  : {
                      itemsPerPage: this.pages.limit_value,
                      currentPage: this.pages.current_page,
                      totalItems: this.pages.total_count,
                    }
            "
            (click)="onClickActivityRecord(record)"
          >
            <div class="flex-item col-size-10 is-first-col truncate-text" data-cy="activity-title">{{ record.title }}</div>
            <div class="flex-item col-size-3" data-cy="activity-created-at">{{ record.created_at }}</div>
            <div class="flex-item col-size-3" data-cy="activity-updated-at">{{ record.updated_at }}</div>
            <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
          </a>
        </div>

        <div class="spen-mod-paginate pagination" *ngIf="showPagination">
          <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
<ng-template #noActivityRecord>
  <div class="empty-message">{{ isSearching ? '検索結果が0件です。' : 'ここに生徒のマナビジョンの記録が表示されます。' }}</div>
</ng-template>
