<div class="assessment-item" data-cy="cross-assessment">
  <div class="user">
    <div class="user-comment">
      <img data-cy="cross-student-avatar" [src]="crossAssessment.create_user.avatar" alt="Avatar" />
      <div class="user-name">
        <p
          class="truncate-text"
          data-cy="cross-student-name"
          [class.spen-util-link]="crossAssessment.create_user.viewer_permission"
          [routerLink]="crossAssessment.create_user.viewer_permission ? ['/students/teacher', crossAssessment.create_user.id] : []"
        >
          {{ crossAssessment.create_user.name }}
        </p>
        <p class="student-class truncate-text" data-cy="cross-student-class">
          {{ crossAssessment.create_user.class_name }}{{ crossAssessment.create_user.attendance_number }}番
        </p>
        <p class="time-comment" data-cy="cross-created-at">{{ crossAssessment.created_at }}</p>
      </div>
    </div>

    <a class="request-review-link spen-util-link" data-cy="request-review" (click)="editReviewAssessment()" *ngIf="canRequestReview">
      {{ layoutService.isTabletDownView.value ? '見直し依頼' : '見直しを依頼する' }}
    </a>
  </div>

  <div class="content-comment">
    <app-read-more [content]="crossAssessment.content"></app-read-more>
    <!-- TODO: enable line below is up coming PRs -->
    <!-- <span class="updated-text" *ngIf="crossAssessment.is_updated">（変更済み）</span> -->
  </div>

  <div class="review" *ngIf="!!crossAssessment.is_reviewing">
    <img data-cy="review-teacher-avatar" [src]="crossAssessment.review_user.avatar" alt="Avatar" />
    <div [class.center-info]="!crossAssessment.review_reason">
      <p class="user-review" data-cy="review-updated-at">
        {{ crossAssessment.updated_at }} {{ crossAssessment.review_user.name }}先生が見直しを依頼しました。
      </p>
      <p class="preserve-line-break reason" data-cy="review-reason" *ngIf="crossAssessment.review_reason">
        {{ crossAssessment.review_reason }}
      </p>
    </div>
  </div>
</div>
