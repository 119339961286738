import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of as observableOf, Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class MarksService {
  apiEndpointV2 = environment.apiEndpointV2;
  marks: {};
  eventDone = new EventEmitter();
  errMsg: string;

  constructor(private http: HttpClient) {}

  getMarks(): Observable<any> {
    if (!this.marks) {
      const url = `${this.apiEndpointV2}/marks`;
      this.http.get(url).subscribe(
        (response) => {
          this.marks = response;
          this.eventDone.emit(this.marks);
        },
        (error) => {
          this.errMsg = <any>error;
        },
      );
      return this.eventDone;
    }
    return observableOf(this.marks);
  }
}
