import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Story } from './story';
import { StoriesExportResponse } from '@components/story/teacher/stories-list/stories-export-response';
import { StoriesListResponse } from '@components/story/teacher/stories-list/stories-list-response';

@Injectable()
export class StoryService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getTeacherStoriesList(params = {}): Observable<StoriesListResponse> {
    const url = `${this.apiEndpointV2}/teacher/stories/search`;
    return this.http.post<StoriesListResponse>(url, params);
  }

  createStory(story: Story) {
    return this.http.post<Story>(`${this.apiEndpointV2}/student/stories`, { story: story });
  }

  updateStory(story: Story) {
    return this.http.put<Story>(`${this.apiEndpointV2}/student/stories/${story.id}`, { story: story });
  }

  getStudentStories(params = {}): Observable<Story[]> {
    const url = `${this.apiEndpointV2}/student/stories`;
    return this.http.get<Story[]>(url, { params: params });
  }

  getTeacherStoryDetail(studentId: number, params = {}): Observable<Story[]> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/stories`;
    return this.http.get<Story[]>(url, { params: params });
  }

  getExportStories(params = {}): Observable<StoriesExportResponse> {
    const url = `${this.apiEndpointV2}/teacher/stories/export`;
    return this.http.post<StoriesExportResponse>(url, params);
  }
}
