<form [formGroup]="form" (ngSubmit)="onSubmit()" class="detail-form">
  <div class="form-horizontal">
    <div class="form-group">
      <div class="row">
        <div class="logo">
          <img [src]="currentUser.avatar" alt="Avatar" class="img-responsive" />
        </div>
        <div class="form-detail">
          <div class="user-info" *ngIf="isEditForm()">
            <div class="info">
              <p class="date">{{ assessment?.created_at }}</p>
              <p>{{ currentUser.name }}</p>
            </div>
          </div>
          <textarea #textArea [appPlaceholder]="'コメントを入力'" formControlName="content" appAutoExpand> </textarea>
          <div *ngIf="formErrors['content']" class="spen-mod-error-content">
            {{ formErrors['content'].join(' ') }}
          </div>
        </div>
        <div class="buttons">
          <button type="submit" class="spen-mod-btn is-primary" [disabled]="isSubmitting || isContentInvalid()">
            {{ isEditForm() ? '保存する' : 'コメントする' }}
          </button>
          <button type="button" class="spen-mod-btn" *ngIf="isEditForm()" (click)="cancelEdit()">キャンセル</button>
          <div class="delete-comment" *ngIf="isEditForm()">
            <span class="spen-util-link" (click)="deleteAssessment()"><i class="fa fa-trash-o"></i>削除する</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
