import { Component, OnInit, OnDestroy, AfterViewChecked, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { validateForm } from '@functions/validate-form';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

@Component({
  selector: 'app-filter-album-modal',
  templateUrl: './filter-album-modal.component.html',
  styleUrls: ['./filter-album-modal.component.scss'],
})
export class FilterAlbumModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() initSearchKeyword: string;
  @Output() submitFilter = new EventEmitter<any>();
  @Output() cancelFilter = new EventEmitter<any>();

  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    searchKeyword: {
      required: 'アルバム名を入力してください。',
    },
  };
  focusEvent = new EventEmitter<boolean>();
  isFocused = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private googleAnalytics4Service: GoogleAnalytics4Service,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.buildForm();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngAfterViewChecked() {
    if (!this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      searchKeyword: [this.initSearchKeyword || '', [Validators.required]],
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.googleAnalytics4Service.sendEvent('アルバム', '検索画面', '検索する');
      this.submitFilter.emit(this.form.value['searchKeyword']);
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  onCancel() {
    this.cancelFilter.emit();
  }
}
