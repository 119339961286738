import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoryCommentStudentService } from './story-comment-student.service';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { StoryCommentListStudentComponent } from './story-comment-list/story-comment-list-student.component';
import { StoryCommentDetailStudentComponent } from './story-comment-detail/story-comment-detail-student.component';

@NgModule({
  declarations: [StoryCommentListStudentComponent, StoryCommentDetailStudentComponent],
  exports: [StoryCommentListStudentComponent, StoryCommentDetailStudentComponent],
  imports: [CommonModule, RouterModule, SharedDirectivesModule, SharedModule],
  providers: [StoryCommentStudentService],
})
export class StoryCommentStudentModule {}
