import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface RouterData {
  title?: string;
  titleH1?: string;
  isShowLink?: boolean;
  isShowSidebar?: boolean;
}

@Injectable()
export class RouterDataService {
  defaultOptions = { isShowLink: false, isShowSidebar: true };

  routerData = new BehaviorSubject<RouterData>(this.defaultOptions);
  routerData$ = this.routerData.asObservable();

  setData(data) {
    this.routerData.next(Object.assign({}, this.defaultOptions, data));
  }
}
