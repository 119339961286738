<ng-container *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="sp-filter" *ngIf="formLoaded">
    <div class="filter-info">
      <div class="field">
        <div class="label-text">検索条件</div>
        <div class="text break-word" data-cy="text-filter" *ngIf="listLoaded">{{ messageFilter }}</div>
      </div>
      <div class="field">
        <div class="label-text">並び順</div>
        <div class="text" data-cy="text-sort-order" *ngIf="listLoaded">{{ messageSort }}</div>
      </div>
    </div>
    <div class="filter-panel">
      <div class="drawers">
        <a class="spen-util-link" (click)="toggleFilter()">
          <span class="">変更</span>
          <i [class]="isMobileFilterOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
        </a>
      </div>
      <form [class.display-none]="!isMobileFilterOpen" [formGroup]="filterForm">
        <div class="filter-inputs">
          <div class="input-group">
            <div class="label-text">校種学年組</div>
            <div class="select-group">
              <app-school-grade-class-select [parentForm]="filterForm"></app-school-grade-class-select>
            </div>
          </div>
          <div class="input-group">
            <div class="label-text">生徒氏名</div>
            <input
              class="spen-mod-input-text"
              data-cy="search-title"
              placeholder="生徒氏名を入力してください"
              type="text"
              formControlName="keyword"
              maxlength="100"
            />
          </div>
          <div class="input-group">
            <div class="label-text">並び順</div>
            <label class="spen-mod-select">
              <select class="select" data-cy="sort-order" formControlName="sort" (change)="onChangeSortValue($event.target.value)">
                <option *ngFor="let choice of sortSelections" [value]="choice.value">
                  {{ choice.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>
        <div class="filter-buttons">
          <button class="spen-mod-btn is-primary" type="button" data-cy="submit-filter" (click)="onFilter()" [disabled]="isLoadingData">
            適用する
          </button>
          <button
            class="spen-mod-btn is-default"
            type="button"
            data-cy="remove-filter"
            (click)="onResetFilter()"
            [disabled]="isLoadingData || isDefaultForm"
          >
            選択解除
          </button>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngIf="listLoaded; else loadingIcon">
    <ng-container *ngIf="!isEmptyList; else emptyList">
      <div class="paginate-result" data-cy="text-page-info">{{ metaData | paginateText }}</div>
      <div class="student-items-list">
        <div
          class="student-item {{ !!student.viewer_permission ? 'clickable-item' : 'disabled-item' }}"
          data-cy="student-item"
          *ngFor="
            let student of students
              | paginate
                : {
                    itemsPerPage: metaData.per_page,
                    currentPage: metaData.current_page,
                    totalItems: metaData.total_count,
                  }
          "
          (click)="onClickStudentItem(student, $event)"
        >
          <div class="student-info flex-center">
            <img [src]="student.avatar" class="student-image object-fit-cover" data-cy="student-avatar" />
            <div class="student-class-name">
              <p class="sp-student-name truncate-text" data-cy="student-name">{{ student.name }}</p>
              <p class="sp-class-name truncate-text" data-cy="student-class">{{ student.class_name }}{{ student.attendance_number }}番</p>
            </div>
          </div>
          <div class="small-text" data-cy="student-activity-count">活動記録数：{{ student.number_activities | number }}</div>
          <div class="muted-text">
            <div data-cy="student-activity-created-at">
              最新の活動記録作成日時：<span>{{ student.last_created_activity_time }}</span>
            </div>
          </div>
          <div class="muted-text">
            <div data-cy="student-activity-updated-at">
              最新の活動記録更新日時：<span>{{ student.last_updated_activity_time }}</span>
            </div>
          </div>
          <i *ngIf="student.viewer_permission" class="fa fa-chevron-right" data-cy="student-chevron"></i>
        </div>
      </div>

      <div class="spen-mod-paginate" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #pcView>
  <div class="pc-filter">
    <div class="filter-panel" *ngIf="formLoaded">
      <form [formGroup]="filterForm">
        <div class="filter-inputs">
          <div class="input-group flex-center">
            <div class="label-text">校種学年組</div>
            <app-school-grade-class-select [parentForm]="filterForm"></app-school-grade-class-select>
          </div>

          <div class="input-group">
            <span class="label-text">生徒氏名</span>
            <input
              class="spen-mod-input-text"
              data-cy="search-title"
              placeholder="生徒氏名を入力してください"
              type="text"
              formControlName="keyword"
              maxlength="100"
            />
          </div>
        </div>
        <div class="filter-buttons">
          <button
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            type="button"
            [disabled]="isLoadingData || isDefaultForm"
            (click)="onResetFilter()"
          >
            選択解除
          </button>
          <button class="spen-mod-btn is-primary" data-cy="submit-filter" type="button" [disabled]="isLoadingData" (click)="onFilter()">
            検索する
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="listLoaded; else loadingIcon">
    <div class="export-answer-csv" *ngIf="isClassOrKeywordSpecified">
      <div class="csv-button">
        <span [class.disabled-item]="isActivityRecordDownloading" class="spen-mod-btn" data-cy="export-csv" (click)="exportActivityRecord()"
          >CSV出力</span
        >
        <a #activityRecordsDownloadLink class="hidden-link"></a>
      </div>
    </div>
    <div class="table-info clearfix">
      <span class="filter-info" data-cy="text-filter" *ngIf="!isEmptyData">検索条件：{{ messageFilter }}</span>
      <span class="paginate-result" data-cy="text-page-info" *ngIf="!isSorting && !isEmptyList">{{ metaData | paginateText }}</span>
    </div>

    <div *ngIf="!isEmptyList; else emptyList" class="spen-mod-flex-table table-list-full has-link">
      <div class="flex-table-head">
        <div class="flex-item col-size-4 is-first-col">生徒氏名</div>
        <div class="flex-item col-size-4">
          <a class="spen-util-link" data-cy="col-sort" (click)="onChangeOrder('class_name')">
            <span>校種学年組番</span>
            <i [class]="sortStatus.class_name" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item col-size-2 pull-right">
          <a class="spen-util-link" data-cy="col-sort" (click)="onChangeOrder('number_activities')">
            <span>活動記録数</span>
            <i [class]="sortStatus.number_activities" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" data-cy="col-sort" (click)="onChangeOrder('last_created_activity_time')">
            <span>最新の活動記録作成日時</span>
            <i [class]="sortStatus.last_created_activity_time" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" data-cy="col-sort" (click)="onChangeOrder('last_updated_activity_time')">
            <span>最新の活動記録更新日時</span>
            <i [class]="sortStatus.last_updated_activity_time" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item redirect-cell"></div>
      </div>
      <a
        class="flex-table-row"
        data-cy="student-item"
        *ngFor="
          let student of students
            | paginate
              : {
                  itemsPerPage: metaData.per_page,
                  currentPage: metaData.current_page,
                  totalItems: metaData.total_count,
                }
        "
        [class.disabled-item]="!student.viewer_permission"
        (click)="onClickStudentItem(student, $event)"
      >
        <div class="flex-item col-size-4 is-first-col">
          <div class="flex-center">
            <img data-cy="student-avatar" [src]="student.avatar" class="student-image pull-left" />
            <span class="truncate-text" data-cy="student-name">{{ student.name }}</span>
          </div>
        </div>
        <div class="flex-item col-size-4 truncate-text" data-cy="student-class">
          {{ student.class_name }}{{ student.attendance_number }}番
        </div>
        <div class="flex-item col-size-2 pull-right" data-cy="student-activity-count">{{ student.number_activities | number }}</div>
        <div class="flex-item col-size-3" data-cy="student-activity-created-at">{{ student.last_created_activity_time }}</div>
        <div class="flex-item col-size-3" data-cy="student-activity-updated-at">{{ student.last_updated_activity_time }}</div>
        <div class="flex-item redirect-cell is-icon-item">
          <i *ngIf="student.viewer_permission" class="fa fa-chevron-right" data-cy="student-chevron"></i>
        </div>
      </a>
    </div>

    <div class="spen-mod-paginate" *ngIf="showPagination">
      <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
    </div>
  </div>
</ng-template>

<div *ngIf="!isLoadingData && !isEmptyList && !isEmptyData" class="guide">
  <div class="muted-text">※選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
  <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
  <div class="muted-text">※活動記録数、最新の活動記録作成日時、最新の活動記録更新日時にマナビジョンの活動記録は含まれません。</div>
</div>

<ng-template #emptyList>
  <div class="null-content">
    <ng-container *ngIf="isEmptyData; else emptySearch">
      <p class="text">ここに校内の生徒が表示されます。</p>
    </ng-container>
    <ng-template #emptySearch>
      <p class="text">検索結果が0件です。</p>
    </ng-template>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
