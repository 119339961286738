import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-files-progress',
  templateUrl: './upload-files-progress.component.html',
  styleUrls: ['./upload-files-progress.component.scss'],
})
export class UploadFilesProgressComponent {
  @Input() progress: number;

  @Output() cancelFilesEvent = new EventEmitter<boolean>();

  constructor() {}

  cancelUploadingFiles() {
    if (this.finishedUpload()) {
      return;
    }
    this.cancelFilesEvent.emit(true);
  }

  finishedUpload(): boolean {
    return this.progress === 100;
  }
}
