<div class="works-sp" *ngIf="layoutService.isTabletDownView.value; else workPc">
  <div class="create-work">
    <a class="spen-util-link" routerLink="/works/new" data-cy="transit-form">課題配信</a>
  </div>
  <div class="filter">
    <div class="box-info">
      <div class="row-info">
        <p class="label-input">検索条件</p>
        <p class="info-data" data-cy="text-filter">{{ filterService.messageFilter }}</p>
      </div>

      <div class="row-info">
        <p class="label-input">並び順</p>
        <p class="info-data" data-cy="text-sort-order">{{ messageSort }}</p>
      </div>
    </div>

    <div class="box-filter">
      <span class="btn-show-detail" (click)="changeShowFilterStatus()">
        <a class="spen-util-link">
          <span>変更</span>
          <i [ngClass]="isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter-status"></i>
        </a>
      </span>
      <form [formGroup]="form" *ngIf="isShowFilter">
        <div class="selector-group">
          <div class="form-group">
            <p class="label-input">権限</p>
            <label class="spen-mod-select">
              <select class="select" formControlName="distributor" data-cy="distributor">
                <option class="select-option" [ngValue]="distributor.formValue" *ngFor="let distributor of distributorOptions">
                  {{ distributor.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>

          <div class="form-group">
            <p class="label-input">配信課題名</p>
            <input
              class="spen-mod-input-text"
              data-cy="search-title"
              placeholder="配信課題名を入力してください"
              maxlength="50"
              formControlName="keyword"
            />
          </div>

          <div class="form-group">
            <p class="label-input">並び順</p>
            <label class="spen-mod-select">
              <select class="select" formControlName="sort_value" data-cy="sort-order" (change)="onChangeSortValue($event.target.value)">
                <option class="select-option" value="配信日時（降順）">配信日時（降順）</option>
                <option class="select-option" value="配信日時（昇順）">配信日時（昇順）</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn is-primary"
            data-cy="submit-filter"
            (click)="onSubmitFilter()"
            [disabled]="isLoadingData()"
          >
            適用する
          </button>
          <button
            type="button"
            class="cancel spen-mod-btn is-default"
            data-cy="remove-filter"
            [disabled]="isLoadingData() || isDefaultForm()"
            (click)="removeFilter()"
          >
            選択解除
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="list-submission" *ngIf="delivery_works.length > 0; else noWork">
      <p class="paginate-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
      <div
        class="list-item clickable-item"
        [class.last]="last"
        data-cy="delivered-work-item"
        *ngFor="
          let work of delivery_works
            | paginate
              : {
                  itemsPerPage: this.pages.per_page,
                  currentPage: this.pages.current_page,
                  totalItems: this.pages.total_count,
                };
          let last = last
        "
        (click)="onClickWork(work.id)"
      >
        <div class="info">
          <div class="work-name">
            <span *ngIf="isScheduled(work.delivered_at)" class="delivery-label" data-cy="is-scheduled">予約中</span>
            <span class="truncate-text"
              ><b data-cy="work-title">{{ work.title }}</b></span
            >
          </div>
          <p class="truncate-text creator-name" data-cy="creator-name">配信者：{{ work.create_user_name }}</p>
          <p class="text-smaller" data-cy="answer-count">提出数：{{ work.answers_count | number }}/{{ work.deliveries_count | number }}</p>
          <p class="text-smaller delivered-time" data-cy="delivered-at">配信日時：{{ work.delivered_at }}</p>
          <div class="text-smaller space-name">
            <span class="truncate-text" data-cy="space-name">配信先：{{ work.spaces[0].name }}</span>
            <span *ngIf="work.spaces.length > 1" class="more-space" data-cy="space-count">他{{ work.spaces.length - 1 | number }}件</span>
          </div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>

      <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #workPc>
  <div class="works-pc">
    <div class="create-work">
      <a routerLink="/works/new" class="spen-mod-btn create-button" data-cy="transit-form">課題配信</a>
    </div>

    <form [formGroup]="form" class="filter-form">
      <div class="form-contents">
        <div class="form-group">
          <span class="label">権限</span>
          <label class="spen-mod-radio creator-label-item" data-cy="distributor" *ngFor="let distributorOption of distributorOptions">
            <input
              class="radio"
              formControlName="distributor"
              data-cy="distributor-value"
              [value]="distributorOption.formValue"
              type="radio"
            />
            <i class="fa"></i><span class="text" data-cy="distributor-name">{{ distributorOption.text }}</span>
          </label>
        </div>
        <div class="form-group">
          <span class="label">配信課題名</span>
          <input
            type="text"
            class="spen-mod-input-text"
            placeholder="配信課題名を入力してください"
            maxlength="50"
            formControlName="keyword"
            data-cy="search-title"
          />
        </div>
      </div>
      <div class="button-group">
        <button
          type="button"
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          (click)="removeFilter()"
          [disabled]="isLoadingData() || isDefaultForm()"
        >
          選択解除
        </button>
        <button
          type="button"
          class="spen-mod-btn is-primary"
          data-cy="submit-filter"
          (click)="onSubmitFilter()"
          [disabled]="isLoadingData()"
        >
          検索する
        </button>
      </div>
    </form>

    <div class="data-info" *ngIf="contentLoaded">
      <p class="filter-info" data-cy="text-filter" *ngIf="isSearching || delivery_works.length > 0">
        検索条件：{{ filterService.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info" *ngIf="!inProgressSort && delivery_works.length > 0">{{ pages | paginateText }}</p>
    </div>

    <div class="content">
      <ng-container *ngIf="contentLoaded; else loadingIcon">
        <div class="list-submission" *ngIf="delivery_works.length > 0; else noWork">
          <div class="spen-mod-flex-table table-list-full has-link">
            <div class="flex-table-head">
              <div class="flex-item col-size-4 is-first-col">配信課題名</div>
              <div class="flex-item col-size-3">配信者</div>
              <div class="flex-item col-size-3">
                <a class="spen-util-link" (click)="changeBySortColumn('delivered_at')"
                  >配信日時
                  <i [class]="sortStatus['delivered_at']" data-cy="col-sort-icon"></i>
                </a>
              </div>
              <div class="flex-item col-size-4">配信先</div>
              <div class="flex-item col-size-2 pull-right">提出数</div>
              <div class="flex-item redirect-cell"></div>
            </div>
            <a
              class="flex-table-row clickable-item"
              data-cy="delivered-work-item"
              *ngFor="
                let work of delivery_works
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
              (click)="onClickWork(work.id)"
            >
              <div class="flex-item col-size-4 is-first-col truncate-text">
                <div class="work-title">
                  <span *ngIf="isScheduled(work.delivered_at)" class="delivery-label" data-cy="is-scheduled">予約中</span>
                  <span class="truncate-text" data-cy="work-title">{{ work.title }}</span>
                </div>
              </div>
              <div class="flex-item col-size-3 truncate-text" data-cy="creator-name">{{ work.create_user_name }}</div>
              <div class="flex-item col-size-3" data-cy="delivered-at">{{ work.delivered_at }}</div>
              <div class="flex-item col-size-4 space-name">
                <a class="spen-util-link truncate-text" data-cy="space-name" (click)="onClickSpaceName(work.spaces[0].id)">
                  {{ work.spaces[0].name }}
                </a>
                <span data-cy="space-count" *ngIf="work.spaces.length > 1">他{{ work.spaces.length - 1 | number }}件</span>
              </div>
              <div class="flex-item col-size-2 pull-right" data-cy="answer-count">
                {{ work.answers_count | number }}/{{ work.deliveries_count | number }}
              </div>
              <div class="flex-item redirect-cell is-icon-item">
                <i class="fa fa-chevron-right"></i>
              </div>
            </a>
          </div>

          <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
            <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noWork>
  <div class="empty-message">
    {{ isSearching ? '検索結果が0件です。' : 'ここに校内で配信された課題が表示されます。' }}
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>
