import { Component, Input } from '@angular/core';
import { Pickup } from '@models/pickup';
import { currentLocaleDate } from '@functions/date-formatter';

@Component({
  selector: 'app-pickup-item-toggle',
  templateUrl: './pickup-item-toggle.component.html',
  styleUrls: ['./pickup-item-toggle.component.scss'],
})
export class PickupItemToggleComponent {
  @Input() pickup: Pickup;
  @Input() pickupType = 'own_activity';
  display = false;

  constructor() {}

  getPickupTitle() {
    return `${this.pickup.creator.name}先生のピックアップ`;
  }

  getPickupReason() {
    return this.pickup.reason;
  }

  clickPickup() {
    if (this.pickupType === 'own_activity' || !this.isNoReason) {
      this.display = !this.display;
    }
  }

  getIconSrc() {
    return `assets/images/icons/pickup-${this.pickup.reason ? 'active' : 'not-active'}.svg`;
  }

  get isNoReason() {
    return !this.pickup.reason;
  }

  getClassIconArrow() {
    if (this.display) {
      return 'fa fa-chevron-up';
    }
    return 'fa fa-chevron-down';
  }

  pickupExpired() {
    const currentTime = currentLocaleDate();
    currentTime.setHours(0, 0, 0, 0);
    const pickupEndTime = new Date(this.pickup.end_time);

    return currentTime > pickupEndTime;
  }
}
