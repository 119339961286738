import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ActionsResponse } from '@components/timeline/actions-response';

@Injectable()
export class TimelineService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getActionInSchool(params: {}): Observable<ActionsResponse> {
    const url = `${this.apiEndpointV2}/teacher/actions`;
    return this.http.get<ActionsResponse>(url, { params: params });
  }
}
