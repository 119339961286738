<div class="stu-graduation-message-detail">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <button class="spen-mod-btn is-primary" (click)="slideMenu()"><i class="fa fa-reorder"></i></button>卒業メッセージ詳細
    </h1>
    <app-link-prev-next *ngIf="!layoutService.isTabletDownView.value"></app-link-prev-next>
  </div>
  <app-link-prev-next *ngIf="layoutService.isTabletDownView.value"></app-link-prev-next>

  <div class="graduation-message-content" *ngIf="graduationMessageDetailLoaded; else loadingIcon">
    <div class="graduation-message-detail">
      <p class="message-title">{{ graduationMessageDetail.title }}</p>

      <div class="user-info">
        <span>配信者：{{ graduationMessageDetail.create_user_name }}先生</span>
      </div>

      <div class="additional-info">
        <div class="time-detail">
          <span>配信日時：{{ graduationMessageDetail.created_at }}</span>
        </div>
        <div class="space-name">
          <span>配信先：{{ graduationMessageDetail.space_name }}</span>
        </div>
      </div>

      <div class="message-detail">
        <p class="content-title">本文</p>
        <div class="block-element">
          <p class="preserve-line-break" appConvertLink>{{ graduationMessageDetail.message }}</p>

          <div class="thumb">
            <app-files-view-student [files]="graduationMessageDetail?.upload_files"></app-files-view-student>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
