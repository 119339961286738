import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trimText' })
export class TrimTextPipe implements PipeTransform {
  transform(value: string, trimLength: number = 27): string {
    if (!value) {
      return '';
    }
    const trimText = value.trim();
    if (trimText.length <= trimLength) {
      return trimText;
    }
    return `${trimText.substring(0, trimLength - 1)}...`;
  }
}
