import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { Work } from '@components/space/space-detail/teacher-space-detail/works/work';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { omit, pick, isEqual, mapValues, merge, pickBy, isNil } from 'lodash';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { SpaceService } from '@components/space/space.service';
import { currentLocaleDate } from '@functions/date-formatter';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

const IconSort = {
  none: 'none',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-space-delivered-works',
  templateUrl: './space-delivered-works.component.html',
  styleUrls: ['./space-delivered-works.component.scss'],
})
export class SpaceDeliveredWorksComponent implements OnInit {
  works: Array<Work> = [];
  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };
  spaceId: number;
  isMobileFilterOpen = false;
  filterForm: UntypedFormGroup;
  errMsg: string;
  messageSort: string;
  sortKey = 'delivered_at';
  sortOrder = 'desc';
  formLoaded = false;
  contentLoaded = false;
  isSorting = false;
  queryParams = {};
  isSearching = false;

  sortStatus = {
    delivered_at: IconSort.down,
  };
  creatorSelections = [
    { value: 'all', text: 'すべて' },
    { value: 'myself', text: '自分' },
    { value: 'other_teachers', text: '自分以外の先生' },
  ];
  sortSelections = [
    { value: 'delivered_at desc', text: '配信日時 (降順)' },
    { value: 'delivered_at asc', text: '配信日時 (昇順)' },
  ];
  private readonly allowedParamKeys = ['creator', 'search_key', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['search_key'];
  readonly saveConditionKeys = ['creator', 'sort_key', 'sort_order'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutService,
    private spaceService: SpaceService,
    private formBuilder: UntypedFormBuilder,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit() {
    this.spaceId = +this.route.snapshot.paramMap.get('id');
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 1) {
      newParams = this.searchConditionSavingService.getSearchConditions(url, 'works');
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions, 'works');
    }

    const queryParams = pick(newParams, this.allowedParamKeys);
    this.queryParams = queryParams;
    queryParams['page'] = +params['page'] || 1;
    this.initForm();
    this.initSortIcon();
    this.loadSpaceWorks(queryParams);
    this.messageSort = this.sortText();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  inQueue(deliveredAt): boolean {
    const currentJapanTime = currentLocaleDate();
    const delivered_time = new Date(deliveredAt);
    return delivered_time > currentJapanTime;
  }

  toggleFilter() {
    this.isMobileFilterOpen = !this.isMobileFilterOpen;
  }

  get showPagination(): boolean {
    return this.metaData['total_pages'] > 1;
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  private initForm() {
    this.filterForm = this.formBuilder.group({
      creator: this.queryParams['creator'] || this.creatorSelections[0].value,
      search_key: this.queryParams['search_key'] || '',
      sort: this.queryParams['sort_key']
        ? `${this.queryParams['sort_key']} ${this.queryParams['sort_order']}`
        : this.sortSelections[0].value,
    });
    this.formLoaded = true;
    this.isSearching = !this.isDefaultForm;
  }

  initSortIcon() {
    this.sortKey = this.queryParams['sort_key'] || this.sortKey;
    this.sortOrder = this.queryParams['sort_order'] || this.sortOrder;
  }

  defaultFilterValue() {
    const filterValues = {
      creator: 'all',
      search_key: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort'] = this.sortSelections[0].value;
    }

    return filterValues;
  }

  onWorkClick(work: Work) {
    this.router.navigateByUrl(`works/teacher/delivery/${work.id}`);
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.trimTextInput();
    this.isMobileFilterOpen = false;
    const params = omit(this.filterForm.value, 'sort');

    this.buildOrderParams(params);
    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    params['request_timestamp'] = Date.now();
    this.navigate(params, 'merge');
  }

  private trimTextInput() {
    const search_key = this.filterForm.value.search_key;
    this.filterForm.get('search_key').setValue(search_key.trim());
  }

  resetForm() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[0].value.split(' ');
    }

    this.filterForm.patchValue(this.defaultFilterValue());
    this.onSubmit();
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  onChangeOrder(field) {
    if (this.isSorting) {
      return;
    }

    this.sortKey = field;
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    this.updateSortStatus();
    this.filterForm.get('sort').setValue(`${this.sortKey} ${this.sortOrder}`);
    const params = pick(this.queryParams, this.allowedParamKeys);
    this.buildOrderParams(params);
    if (+this.queryParams['page'] > 1) {
      params['page'] = null;
    }
    this.navigate(params, 'merge');
  }

  private updateSortStatus() {
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);

    if (!this.contentLoaded) {
      // when the list was just loaded
      this.contentLoaded = true;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else if (this.isSorting) {
      // when the list was just sorted
      this.isSorting = false;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else {
      // when the list is being sorted
      this.isSorting = true;
      this.sortStatus[this.sortKey] = IconSort.loader;
    }
  }

  private loadSpaceWorks(params = {}) {
    if (!this.isSorting) {
      this.contentLoaded = false;
    }

    this.spaceService.getTeacherWorksOfSpace(this.spaceId, params).subscribe(
      (response) => {
        this.works = response.works;
        Object.assign(this.metaData, response.meta);

        this.updateSortStatus();
      },
      (error) => {
        this.errMsg = <any>error;
        this.updateSortStatus();
      },
    );
  }

  private buildOrderParams(params) {
    params['sort_key'] = this.sortKey || 'delivered_at';
    params['sort_order'] = this.sortOrder || 'desc';
  }

  filterText() {
    const match = this.creatorSelections.filter((item) => {
      return item.value === this.queryParams['creator'];
    });
    const creator = (match[0] && match[0].text) || this.creatorSelections[0].text;
    const searchKey = this.queryParams['search_key'];
    return searchKey ? `${creator}/${searchKey}` : creator;
  }

  sortText() {
    const match = this.sortSelections.filter((item) => {
      return item.value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match[0] && match[0].text;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.filterForm.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  get isLoadingData(): boolean {
    return this.isSorting || !this.contentLoaded;
  }
}
