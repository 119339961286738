import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { TeacherPickupService } from './teacher-pickup.service';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { Pickup } from '@models/pickup';
import { ActivityPickupsMeta } from './activity-pickups.response';

@Component({
  selector: 'app-teacher-pickups-list',
  templateUrl: './teacher-pickups-list.component.html',
  styleUrls: ['./teacher-pickups-list.component.scss'],
  providers: [TeacherPickupService],
})
export class TeacherPickupsListComponent implements OnInit {
  @Input() activityRecord: ActivityRecord;

  pickups: Array<Pickup>;
  meta: ActivityPickupsMeta;

  pickupsLoaded = false;
  isLoadingMore = false;

  constructor(
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private teacherPickupService: TeacherPickupService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadPickups();
  }

  loadPickups() {
    this.teacherPickupService.loadPickups(this.activityRecord.id).subscribe(
      (response) => {
        this.pickups = response.activity_pickups;
        this.meta = response.meta;
        this.pickupsLoaded = true;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  loadMorePickups() {
    this.isLoadingMore = true;
    this.teacherPickupService.loadPickups(this.activityRecord.id, this.meta).subscribe(
      (response) => {
        this.pickups.push(...response.activity_pickups);
        this.meta = response.meta;
        this.isLoadingMore = false;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  addPickup() {
    this.googleAnalytics4Service.sendEvent('活動記録', '活動記録詳細画面', 'ピックアップを追加する');

    const queryParams = { activity_record_id: this.activityRecord.id };
    if (!!this.activityRecord.space_id) {
      queryParams['space_id'] = this.activityRecord.space_id;
    }
    this.router.navigate(['activity-pickups', 'teacher', 'new'], { queryParams: queryParams });
  }

  get canLoadMore(): boolean {
    return !!this.meta.next_id && !!this.meta.next_created_at;
  }
}
