import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateToNgbDate, ngbDateToString } from '@functions/date-formatter';
import { padNumber } from '@functions/number-formatter';
import { LayoutService } from '@components/layout/layout.service';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';

@Component({
  selector: 'app-datetime-selector',
  templateUrl: './datetime-selector.component.html',
  styleUrls: ['./datetime-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimeSelectorComponent), multi: true }],
})
export class DatetimeSelectorComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('datePicker', { static: true }) datepicker: DatepickerComponent;
  @Input() isDisabled = false;
  @Input() defaultValue = null;
  @Input() minuteSpan = 5;

  inputValue;
  dateSelect;
  hourSelect = 0;
  minuteSelect = 0;

  private propagateChange = (_: any) => {};

  constructor(public layoutService: LayoutService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentDisableStatus = changes['isDisabled'];
    if (!currentDisableStatus || currentDisableStatus.previousValue === undefined) {
      return;
    }
    if (currentDisableStatus.currentValue) {
      this.disableSelector();
    } else {
      this.initFormValue({ useDefaultValue: true, propagateChange: false });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any) {
    if (value) {
      this.inputValue = new Date(value);
      this.initFormValue({ useDefaultValue: false, propagateChange: true });
    } else {
      this.disableSelector();
    }
  }

  disableSelector() {
    this.datepicker.disablePicker('選択してください');
    this.hourSelect = 12;
    this.minuteSelect = 0;
  }

  initFormValue(options = { useDefaultValue: false, propagateChange: true }) {
    if (!this.inputValue && !this.defaultValue) {
      return;
    }
    if (!this.inputValue && options.useDefaultValue) {
      this.inputValue = this.defaultValue;
    }

    this.inputValue = this.roundUpMinutesInDate(this.inputValue);

    this.dateSelect = dateToNgbDate(this.inputValue);
    this.minuteSelect = this.inputValue.getMinutes();
    this.hourSelect = this.inputValue.getHours();
    if (options.propagateChange) {
      this.propagateChange(this.buildDateTime);
    }
  }

  changeSelectTime(typeChanged: string, value) {
    switch (typeChanged) {
      case 'date':
        this.dateSelect = value;
        break;
      case 'hour':
        this.hourSelect = value;
        break;
      case 'minute':
        this.minuteSelect = value;
        break;
    }

    this.inputValue = new Date(this.buildDateTime);
    this.propagateChange(this.buildDateTime);
  }

  get buildDateTime() {
    return `${ngbDateToString(this.dateSelect)} ${this.format2Digit(this.hourSelect)}:${this.format2Digit(this.minuteSelect)}`;
  }

  format2Digit(number: number) {
    return padNumber(number, 2);
  }

  onTimeFocus() {
    this.datepicker.closePicker();
  }

  private roundUpMinutesInDate(datetime: Date): Date {
    const MILISECONDS = this.minuteSpan * 60 * 1000;
    return new Date(Math.ceil(datetime.getTime() / MILISECONDS) * MILISECONDS);
  }
}
