<ng-container *ngIf="assessmentsLoaded; else loadingIcon">
  <div class="box-comment" *ngIf="activityRecord.can_comment || assessments.length > 0">
    <app-assessment-form-teacher
      *ngIf="canCreateAssessment()"
      [activityRecordId]="activityRecord.id"
      (saveNewAssessment)="onsaveNewAssessment($event)"
    >
    </app-assessment-form-teacher>

    <ng-container *ngFor="let assessment of assessments" [ngSwitch]="assessment !== edittingAssessment">
      <app-assessment-detail-teacher
        *ngSwitchCase="true"
        [assessment]="assessment"
        [canEdit]="canEditAssessment(assessment)"
        (editAssessment)="onEditAssessment(assessment)"
      >
      </app-assessment-detail-teacher>
      <app-assessment-form-teacher
        *ngSwitchCase="false"
        [activityRecordId]="activityRecord.id"
        [assessment]="assessment"
        (cancelEditAssessment)="onCancelEditAssessment()"
        (updateOldAssessment)="onUpdateOldAssessment($event, assessment)"
        (deleteOldAssessment)="onDeleteOldAssessment(assessment)"
      >
      </app-assessment-form-teacher>
    </ng-container>

    <div class="load-more" *ngIf="canLoadMore">
      <a class="spen-util-link" (click)="loadMoreAssessments()">
        <span>もっと見る</span>
        <i [class]="isLoadingMore ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
      </a>
    </div>
  </div>
  <div class="empty-message" *ngIf="assessments.length === 0">ここに先生のコメントが表示されます。</div>
</ng-container>

<p *ngIf="!activityRecord.can_comment && assessments?.length > 0" class="permission-message">
  ※課題配信先のスペースを担当していないためコメントできません。
</p>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
