import { Component, OnInit, EventEmitter, ViewChild, OnDestroy, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { Album } from '@models/album';
import { validateForm } from '@functions/validate-form';
import { checkPresentString } from '@functions/check-string';

import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';

import { AlbumService } from '../../album.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { RouterService } from '@services/router.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-form-album-student',
  templateUrl: './form-album-student.component.html',
  styleUrls: ['./form-album-student.component.scss'],
})
export class FormAlbumStudentComponent implements OnInit, AfterViewChecked, OnDestroy {
  album: Album = new Album();
  form: UntypedFormGroup;
  isSubmitting = false;

  errMsg: string;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    title: {
      required: 'タイトルを入力してください。',
      maxlength: 'タイトルは50文字以内で入力してください。',
    },
    goal: {
      maxlength: '目標は1000文字以内で入力してください。',
    },
    outline: {
      maxlength: '概要は1000文字以内で入力してください。',
    },
  };
  uploadFilesStatus: string;
  albumLoaded = false;
  isFocused = false;
  isSubmitted = false;
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };
  optionUploader = {
    singleSelector: true,
    onlyImage: true,
  };

  @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private albumService: AlbumService,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
    private routerService: RouterService,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private authUserService: AuthUserService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.prepareAlbumForm();
  }

  ngAfterViewChecked() {
    if (this.dataLoaded() && !this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty && !this.isSubmitted) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  async prepareAlbumForm() {
    const albumId = this.route.snapshot.params['id'];
    if (albumId) {
      this.albumService.getAlbum(albumId).subscribe(
        (album) => {
          Object.assign(this.album, album);
          this.albumLoaded = true;
          if (!album || this.authUserService.retrieve().id !== this.album.create_user_id) {
            return;
          }
          this.getFormAlbum(this.album);
        },
        (error) => {
          this.routerService.goBack();
          this.errMsg = <any>error;
        },
      );
    } else {
      this.albumLoaded = true;
      this.getFormAlbum(this.album);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.fileUploader.uploadAll();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  getFormAlbum(album: Album) {
    this.form = this.formBuilder.group({
      id: [album.id || ''],
      title: [album.title || '', [Validators.required, Validators.maxLength(50)]],
      goal: [album.goal || '', [Validators.maxLength(1000)]],
      outline: [album.outline || '', [Validators.maxLength(1000)]],
      image_attributes: [null],
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  createAlbum(data) {
    const isGoalSet = checkPresentString(data['goal']) ? '1' : '0';

    this.albumService.createStudentAlbum(data).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.isSubmitted = true;
          this.routerService.resetDataConfirm();
          this.router.navigateByUrl('/albums/student/' + response.id, { replaceUrl: true });
          this.toastService.showToast('作成しました');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  updateAlbum(data) {
    const isGoalSet = checkPresentString(data['goal']) ? '1' : '0';

    this.albumService.updateStudentAlbum(data).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.googleAnalytics4Service.sendEvent('アルバム', '自主作成アルバム編集画面', '編集を完了する');
          this.isSubmitted = true;
          this.routerService.resetDataConfirm();
          this.routerService.goBackSpecific(`albums/student/${this.album.id}`);
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  onDelete() {
    const deleteConfirm = {
      title: '削除確認',
      content: '一度削除したアルバムを復元することはできません。本当にアルバムを削除しますか？\nアルバム内の活動記録は削除されません。',
      acceptButton: '削除する',
      cancelButton: 'キャンセル',
      isDanger: true,
    };
    this.confirmDialogService.showConfirm(deleteConfirm).subscribe((obj) => {
      if (obj) {
        this.albumService.deleteStudentAlbum(this.album.id).subscribe(
          (response) => {
            this.googleAnalytics4Service.sendEvent('アルバム', '自主作成アルバム編集画面', '削除する');
            this.isSubmitted = true;
            history.go(-2);
          },
          (error) => {
            this.errMsg = <any>error;
          },
        );
      }
    });
  }

  onUploadFilesStatus(status) {
    this.uploadFilesStatus = status;
    if (status === 'completed') {
      const uploadedFiles = this.fileUploader.sendUploadedFiles();
      this.form.value.image_attributes = uploadedFiles[0];
      this.saveAlbum();
    }
  }

  saveAlbum() {
    this.isSubmitting = true;
    if (this.album.isExistedAlbum()) {
      this.updateAlbum(this.form.value);
    } else {
      this.createAlbum(this.form.value);
    }
  }

  dataLoaded(): boolean {
    return this.albumLoaded;
  }

  get pageTitle(): string {
    return this.album.isExistedAlbum() ? 'アルバム編集' : 'アルバム新規作成';
  }

  get submitButtonText(): string {
    return this.album.isExistedAlbum() ? '編集を完了する' : '作成を完了する';
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.dataConfirm);
    }
  }
}
