import { Component, OnInit, ViewChild } from '@angular/core';
import { RecordTemplateStep1Component } from '@components/activity-record-templates/teacher/form/step1/record-template-step1.component';
import { RecordTemplateStep2Component } from '@components/activity-record-templates/teacher/form/step2/record-template-step2.component';
import { ActivityRecordTemplateFormService } from '@components/activity-record-templates/teacher/form/activity-record-template-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '@services/router.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-activity-record-template-form',
  templateUrl: './activity-record-template-form.component.html',
  styleUrls: ['./activity-record-template-form.component.scss'],
  providers: [ActivityRecordTemplateFormService],
})
export class ActivityRecordTemplateFormComponent implements OnInit {
  @ViewChild(RecordTemplateStep1Component) step1Component: RecordTemplateStep1Component;
  @ViewChild(RecordTemplateStep2Component) step2Component: RecordTemplateStep2Component;

  constructor(
    private formService: ActivityRecordTemplateFormService,
    private router: Router,
    private route: ActivatedRoute,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], {
      queryParams: { step: 1 },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
    this.route.queryParams.subscribe((params) => {
      const step = +params['step'] || 1;
      if (!this.isStep1 && step === 1) {
        this.formService.setStep1State();
      }
    });
    window.scrollTo(0, 0);
  }

  get isStep1(): boolean {
    return this.formService.currentStep === 1;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.step1Component) {
      return this.step1Component.canDeactivate();
    } else {
      return this.step2Component.canDeactivate();
    }
  }
}
