<div (window:resize)="resize()" [formGroup]="parentForm" #upload_div>
  <div formArrayName="upload_files_attributes">
    <div *ngFor="let file of formUploadFiles; let i = index" [formGroupName]="i">
      <div class="attach" *ngIf="!file.controls._destroy.value">
        <a class="spen-util-link" (click)="deleteFileAttach(i)" *ngIf="canEdit">
          {{ layoutService.isTabletDownView.value ? '削除' : '削除する' }}</a
        >
        <div class="attach-image">
          <div *ngIf="file.controls.id.value; else showQueueFileIcon">
            <div *ngIf="!!file.controls.thumbnail.value; else showFileIcon" class="attach-file">
              <img [src]="file.controls.thumbnail.value" />
            </div>
          </div>
          <ng-template #showQueueFileIcon>
            <div *ngIf="isShowPreview(uploader.queue[i - filesAttachNumber]); else showFileIcon" class="attach-file">
              <img appImagePreview [image]="file.controls.thumbnail.value" />
            </div>
          </ng-template>
          <ng-template #showFileIcon>
            <i [ngClass]="file.controls.file_name.value | fileIconClass"></i>
          </ng-template>
        </div>
        <p class="file-name truncate-text">{{ file.controls.file_name.value }}</p>
        <textarea
          class="spen-mod-input-text"
          type="text"
          [appPlaceholder]="'このファイルは何ですか？'"
          formControlName="caption"
        ></textarea>
        <p class="text-num pull-right" *ngIf="!layoutService.isTabletDownView.value">
          文字数<span>{{ getLengthCaption(i) }}</span>
        </p>
        <div class="caption-error" *ngIf="getFormErrorsCaption(i)">
          <div class="spen-mod-error-content">
            {{ getFormErrorsCaption(i).join(' ') }}
          </div>
        </div>
        <div class="text-num" *ngIf="layoutService.isTabletDownView.value">
          <p>
            文字数<span>{{ getLengthCaption(i) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="create-new">
  <label class="spen-util-link" [ngClass]="{ 'disable-upload-btn': getAllFilesNumber >= MaxFilesCount }" for="file-upload">
    <i class="fa fa-plus-circle"></i>ファイルを添付する</label
  >
  <input
    id="file-upload"
    class="input-file"
    type="file"
    (change)="onSelectFile($event)"
    [accept]="acceptTypes"
    ng2FileSelect
    [uploader]="uploader"
    multiple
  />
</div>

<div *ngIf="attachFilesErrMsg" class="file-error">
  <div class="spen-mod-error-content">
    {{ attachFilesErrMsg }}
  </div>
</div>

<app-upload-files-progress *ngIf="showProgressbar" [progress]="uploader.progress" (cancelFilesEvent)="cancelUploadingFiles()">
</app-upload-files-progress>
