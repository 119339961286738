import { Component, Output, EventEmitter, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';

@Component({
  selector: 'app-word-cloud-error-modal',
  templateUrl: './word-cloud-error-modal.component.html',
  styleUrls: ['./word-cloud-error-modal.component.scss'],
})
export class WordCloudErrorModalComponent implements OnInit, OnDestroy {
  @Output() closeFilter = new EventEmitter<any>();
  title: String;
  errorMessage: String;

  constructor(
    private renderer: Renderer2,
    private wordCloudService: WordCloudService,
  ) {}

  ngOnInit() {
    this.getTitle();
    this.getErrorMessage();
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  getTitle(): void {
    this.title = this.wordCloudService.modalTitle;
  }

  getErrorMessage(): void {
    this.errorMessage = this.wordCloudService.modalErrorMessage;
  }

  onClose() {
    this.closeFilter.emit();
  }
}
