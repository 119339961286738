import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { AuthUserService } from '@services/auth-user.service';
import { isMobileApp } from '@functions/browser';
import { StudentNavigationService } from '@services/navigation/student-navigation.service';

@Component({
  selector: 'app-student-layout',
  templateUrl: './student-layout.component.html',
})
export class StudentLayoutComponent implements OnInit {
  isFetchedQuestionnaires = false;
  constructor(
    public authUserService: AuthUserService,
    private studentNavigationService: StudentNavigationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit() {
    const isAuthenticated = await this.authUserService.isAuthenticated();
    if (!this.isFetchedQuestionnaires && isAuthenticated && this.authUserService.isStudent()) {
      this.isFetchedQuestionnaires = true;
    }

    // 現在表示しているURLをnavigationServiceへ設定する
    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof NavigationEnd) {
            this.studentNavigationService.currentUrl = event.url;
            return true;
          }
        }),
        map(() => this.route.snapshot),
        map((route) => {
          const tempPathArray = [];
          while (route.firstChild) {
            route = route.firstChild;
            tempPathArray.push(route.routeConfig.path);
          }

          this.studentNavigationService.currentPathConfig = tempPathArray.join('/');
          return route;
        }),
      )
      .subscribe((activatedRoute: ActivatedRouteSnapshot) => {
        this.studentNavigationService.updateNavigator(activatedRoute);
      });
  }
  get isMobileApp() {
    return isMobileApp();
  }
}
