<div class="spen-ly-modal tea-work-template" @slideUpDown>
  <div class="modal-dialog">
    <div class="space-inner">
      <div class="modal-heading">
        <span class="top-btn-close" (click)="closeModal()">
          <a class="spen-util-link">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span *ngIf="!layoutService.isTabletDownView.value">閉じる</span>
          </a>
        </span>
        <h2 class="heading-text truncate-text">活動記録テンプレートの選択</h2>
      </div>

      <div class="modal-content" (scroll)="handleScroll($event)">
        <div *ngIf="!layoutService.isTabletDownView.value; else spView" class="content-modal-fix">
          <div class="filter-container">
            <div class="filter-detail-top">
              <form [formGroup]="form" class="full-width">
                <div class="text-detail-content filter-scope">
                  <span class="filter-detail-name radio-title">作成者</span>
                  <label class="spen-mod-radio margin-fix-label" *ngFor="let scope of createUserScope">
                    <input class="radio" formControlName="create_user_scope" value="{{ scope.value }}" type="radio" />
                    <i class="fa"></i>
                    <span class="text">{{ scope.name }}</span>
                  </label>
                  <span *ngIf="!layoutService.isMediumDesktopDownView.value">
                    <span class="filter-detail-name filter-search">活動記録テンプレート名</span>
                    <input
                      formControlName="title"
                      maxlength="50"
                      class="spen-mod-input-text search-input"
                      placeholder="キーワードを入力してください"
                      type="text"
                    />
                  </span>
                </div>
                <div class="text-detail-content filter-search" *ngIf="layoutService.isMediumDesktopDownView.value">
                  <span class="filter-detail-name">活動記録テンプレート名</span>
                  <input
                    formControlName="title"
                    maxlength="50"
                    class="spen-mod-input-text search-input"
                    placeholder="キーワードを入力してください"
                    type="text"
                  />
                </div>
              </form>
            </div>
            <div class="filter-detail-bottom text-center">
              <button class="spen-mod-btn is-default" (click)="onResetFilter()" [disabled]="isDefaultForm || workTemplateLoading">
                選択解除
              </button>
              <button class="spen-mod-btn is-primary" (click)="onSubmit()" [disabled]="workTemplateLoading">検索する</button>
            </div>
          </div>
          <div class="table-items-list">
            <div class="fix-filter-respon">
              <span class="pull-left">検索条件：{{ messageFilter }}</span>
              <span class="paginate-result pull-right" *ngIf="!workTemplateLoading && workTemplates.length > 0">
                {{ meta.total_count }}件
              </span>
            </div>
          </div>
          <div
            class="template-container"
            *ngIf="!workTemplateLoading && workTemplates?.length > 0; else loadingIcon"
            id="template-container"
          >
            <div
              class="content-section"
              id="template-list"
              infinite-scroll
              infiniteScrollDistance="1"
              infiniteScrollThrottle="100"
              [scrollWindow]="false"
              (scrolled)="getMoreWorkTemplate()"
            >
              <ul class="spen-mod-item-list">
                <li
                  class="list-item"
                  [class.is-first]="first"
                  [class.is-last]="last && hasScrollBar"
                  *ngFor="let item of workTemplates; let first = first; let last = last"
                  (click)="previewWorkTemplate(item); onMouseLeftClick()"
                >
                  <a class="list-item-inner truncate-text" [class.current]="item.id === currentWorkTemplate.id">
                    {{ item.title }}
                  </a>
                </li>
                <li *ngIf="moreWorkTemplateLoading">
                  <div class="spen-mod-flex-loading">
                    <div class="loading-icon"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="preview-work-template-section" id="template-preview">
              <div class="content" *ngIf="currentWorkTemplate">
                <div class="view-detail-border" *ngIf="!layoutService.isTabletDownView.value">
                  <div class="text-detail-content">
                    <p class="template-title">
                      テンプレート名：<span>{{ currentWorkTemplate.title }}</span>
                    </p>
                    <p>作成者：{{ currentWorkTemplate.create_user_name ? currentWorkTemplate.create_user_name + '先生' : 'Classi' }}</p>
                    <p>作成日時：{{ currentWorkTemplate.created_at }}</p>
                    <p *ngIf="currentWorkTemplate.updated_at !== currentWorkTemplate.created_at">
                      更新日時：{{ currentWorkTemplate.updated_at }}
                    </p>
                    <p>利用数：{{ currentWorkTemplate.used_count | number }}</p>
                  </div>
                </div>
                <div class="question" *ngFor="let question of currentWorkTemplate.work_template_questions; let i = index">
                  <div class="sub-title">
                    設問{{ i + 1 }}
                    <span class="spen-mod-badge is-red" *ngIf="currentWorkTemplate.is_compass_template">必須</span>
                  </div>
                  <ng-container *ngIf="!question.content.description; else singleChoiceQuestion">
                    <div class="padding-text preserve-line-break question-content">{{ question.content }}</div>
                    <div class="image" *ngIf="question?.image">
                      <img src="{{ question?.image }}" />
                    </div>
                    <textarea placeholder="※生徒自由記入" disabled></textarea>
                  </ng-container>
                  <ng-template #singleChoiceQuestion>
                    <div class="padding-text preserve-line-break question-content">
                      {{ question.content.description }}
                      <span class="question-content muted-text-color">(単一選択形式)</span>
                    </div>
                    <div class="question-options">
                      <ul>
                        <li *ngFor="let option of question.content.options">{{ option }}</li>
                      </ul>
                    </div>
                  </ng-template>
                </div>
                <div class="text-center approved-button">
                  <button class="spen-mod-btn is-primary" data-cy="submit-work-template" (click)="selectWorkTemplate(currentWorkTemplate)">
                    このテンプレートを使用する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spView>
  <div class="filter-container">
    <div class="filter-detail-top filter-border-b">
      <p>
        <span class="filter-detail-name">検索条件</span>
        <span class="text message-filter">{{ messageFilter }}</span>
      </p>
    </div>
    <div class="filter-detail-top remove-border-top">
      <div class="text-detail-content">
        <a class="spen-util-link detail-link-settings" (click)="onChangeShowMoreFilter()">
          <span>変更</span>
          <i [ngClass]="isShowFilterWorkTemplate ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
        </a>
      </div>
      <div class="text-detail-content filter-sp-show" *ngIf="isShowFilterWorkTemplate">
        <form [formGroup]="form" class="full-width">
          <p class="filter-detail-name">作成者</p>
          <ul>
            <li *ngFor="let scope of createUserScope">
              <label class="spen-mod-radio">
                <input class="radio" formControlName="create_user_scope" value="{{ scope.value }}" type="radio" />
                <i class="fa"></i>
                <span class="text">{{ scope.name }}</span>
              </label>
            </li>
          </ul>
          <p class="filter-detail-name input-title">活動記録テンプレート名</p>
          <input
            class="spen-mod-input-text"
            maxlength="50"
            formControlName="title"
            placeholder="キーワードを入力してください"
            type="text"
          />
        </form>
      </div>
      <div class="text-center" *ngIf="isShowFilterWorkTemplate">
        <button class="spen-mod-btn is-primary" (click)="onSubmit(); isShowFilterWorkTemplate = false" [disabled]="workTemplateLoading">
          適用する
        </button>
        <button class="spen-mod-btn is-default" (click)="onResetFilter()" [disabled]="isDefaultForm || workTemplateLoading">
          選択解除
        </button>
      </div>
    </div>
  </div>
  <div class="table-items-list" id="template-container" *ngIf="!workTemplateLoading && workTemplates?.length > 0; else loadingIcon">
    <div class="full-width" id="template-list">
      <div class="paginate-result item-padding">{{ meta.total_count }}件</div>
      <div
        class="li-item-border"
        [class.auto-height]="isShowWorkTemplateSP[i]"
        *ngFor="let item of workTemplates; let i = index"
        (click)="previewWorkTemplate(item, i)"
      >
        <div class="item-title" *ngIf="!isShowWorkTemplateSP[i]; else singleChoiceQuestion">
          <p class="filter-detail-name truncate-text" (click)="onChangeShowWorkTemplateSP(i)" *ngIf="!isShowWorkTemplateSP[i]">
            {{ item.title }}
          </p>
          <span class="arrow-items">
            <i [ngClass]="isShowWorkTemplateSP[i] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" (click)="onChangeShowWorkTemplateSP(i)"></i>
          </span>
        </div>
        <ng-template #singleChoiceQuestion>
          <div class="item-title-show">
            <p class="filter-detail-name truncate-text" (click)="onChangeShowWorkTemplateSP(i)">
              {{ item.title }}
            </p>
            <span class="arrow-items">
              <i
                [ngClass]="isShowWorkTemplateSP[i] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"
                (click)="onChangeShowWorkTemplateSP(i)"
              ></i>
            </span>
            <p class="template-name">テンプレート名：</p>
            <p class="template-title">{{ workTemplates[i]?.title }}</p>
            <p>作成者：{{ workTemplates[i]?.create_user_name ? workTemplates[i]?.create_user_name + '先生' : 'Classi' }}</p>
            <p>作成日時：{{ workTemplates[i]?.created_at }}</p>
            <p *ngIf="workTemplates[i]?.updated_at !== workTemplates[i]?.created_at">更新日時：{{ workTemplates[i]?.updated_at }}</p>
            <p>利用数：{{ workTemplates[i]?.used_count | number }}</p>
          </div>
          <div class="template-container">
            <div class="preview-work-template-section" id="template-preview">
              <div class="content" *ngIf="workTemplates[i]">
                <div
                  class="question"
                  [class.last-question]="last"
                  *ngFor="let question of workTemplates[i].work_template_questions; let i = index; let last = last"
                >
                  <div class="sub-title">
                    設問{{ i + 1 }}
                    <span class="spen-mod-badge is-red" *ngIf="item.is_compass_template">必須</span>
                  </div>
                  <ng-container *ngIf="!question.content.description; else singleChoiceQuestion">
                    <div class="padding-text preserve-line-break question-content">{{ question.content }}</div>
                    <div class="image" *ngIf="question?.image">
                      <img src="{{ question?.image }}" />
                    </div>
                    <textarea placeholder="※生徒自由記入" disabled></textarea>
                  </ng-container>
                  <ng-template #singleChoiceQuestion>
                    <div class="padding-text preserve-line-break question-content">
                      {{ question.content.description }}
                      <span class="question-content muted-text-color">(単一選択形式)</span>
                    </div>
                    <div class="question-options">
                      <ul>
                        <li *ngFor="let option of question.content.options">{{ option }}</li>
                      </ul>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center approved-button" *ngIf="isShowWorkTemplateSP[i]">
            <button class="spen-mod-btn is-primary" (click)="selectWorkTemplate(workTemplates[i])">このテンプレートを使用する</button>
          </div>
        </ng-template>
      </div>
      <li *ngIf="moreWorkTemplateLoading" class="item-loading">
        <div class="spen-mod-flex-loading">
          <div class="loading-icon"></div>
        </div>
      </li>
    </div>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="loading-item" *ngIf="!errorMsg && workTemplateLoading">
    <div class="spen-mod-flex-loading">
      <div class="loading-icon"></div>
    </div>
  </div>
  <div class="empty-message" *ngIf="workTemplates?.length === 0 && !workTemplateLoading">
    {{ errorMsg || '検索結果が0件です。' }}
  </div>
</ng-template>
