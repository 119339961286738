import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { ActivityRecord } from '@components/activity-record/activity-record';

import { RouterService } from '@services/router.service';
import { ActivityRecordService } from '../activity-record.service';
import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-form-edit-questionnaire',
  templateUrl: './form-edit-questionnaire.component.html',
  styleUrls: ['./form-edit-questionnaire.component.scss'],
})
export class FormEditQuestionnaireComponent implements OnInit, OnDestroy {
  activityRecord: ActivityRecord;
  isActivityRecordLoaded = false;
  isSubmitted = false;
  activityId = null;
  errMsg: string;

  form: UntypedFormGroup;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private activityRecordService: ActivityRecordService,
    private formBuilder: UntypedFormBuilder,
    private routerService: RouterService,
    private authUserService: AuthUserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.activityId = this.route.snapshot.params['id'];
    this.loadQuestionnaireDetail(this.activityId);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  loadQuestionnaireDetail(activityId: number) {
    this.activityRecordService.getEditActivityRecord(activityId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.isActivityRecordLoaded = true;
        if (this.authUserService.retrieve().id !== this.activityRecord.create_user_id) {
          this.routerService.goBack();
        }
        this.getFormQuestionnaire(this.activityRecord);
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  getFormQuestionnaire(activityRecord: ActivityRecord) {
    const formInit = {
      id: [activityRecord.id || ''],
      albums_activity_records_attributes: this.formBuilder.array([]),
    };
    this.form = this.formBuilder.group(formInit);
  }

  dataLoaded(): boolean {
    return this.isActivityRecordLoaded;
  }

  onBack() {
    this.routerService.goBack();
  }

  deleteActivityRecordFromAlbum(albums_activity_record) {
    const deletedAlbum = this.activityRecord['albums_activity_records'].find((item) => item.id === albums_activity_record.id);
    const index: number = this.activityRecord['albums_activity_records'].indexOf(albums_activity_record);

    this.form.value.albums_activity_records_attributes.push({ ...deletedAlbum, ...{ _destroy: true } });
    if (index !== -1) {
      this.activityRecord['albums_activity_records'].splice(index, 1);
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.activityRecordService.updateQuestionnaire(this.form.value).subscribe(
      (response) => {
        this.isSubmitted = true;
        this.routerService.resetDataConfirm();
        this.router.navigateByUrl('/my-space?tab=activities');
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }
}
