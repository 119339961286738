import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SpacesToPickupResponse } from '@components/activity-pickup/form-activity-pickup/search-space/spaces-to-pickup-response';
import { Pickup } from '@models/pickup';
import { Space } from '@components/space/space';
import { environment } from '@environments/environment';

@Injectable()
export class ActivityPickupService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getActivityPickupEdit(pickupId: number): Observable<Pickup> {
    const url = `${this.apiEndpointV2}/teacher/activity_pickups/${pickupId}/edit`;
    return this.http.get<Pickup>(url);
  }

  getSpaceDetail(spaceId: number, activityRecordId?: number): Observable<Space> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}`;
    const params = {};
    if (!!activityRecordId) {
      params['can_pickup_activity'] = activityRecordId;
    }
    return this.http.get<Space>(url, { params: params });
  }

  getListSpacesToPickup(activityRecordId: number, params = {}): Observable<SpacesToPickupResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityRecordId}/list_space_pickup`;
    return this.http.post<SpacesToPickupResponse>(url, params);
  }

  createPickup(activity_record_id: number, pickup: Pickup): Observable<Pickup> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activity_record_id}/activity_pickups`;
    return this.http.post<Pickup>(url, { activity_pickup: pickup });
  }

  updatePickup(pickupId: number, pickup: Pickup): Observable<Pickup> {
    const url = `${this.apiEndpointV2}/teacher/activity_pickups/${pickupId}`;
    return this.http.put<Pickup>(url, { activity_pickup: pickup });
  }

  deletePickup(pickupId: number): Observable<Pickup> {
    const url = `${this.apiEndpointV2}/teacher/activity_pickups/${pickupId}`;
    return this.http.delete<Pickup>(url);
  }
}
