import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { BadgeMySpaceResponse } from '@components/my-space/badge-my-space-response';
import { ActivityRecordsListResponse } from '@components/activity-record/activity-records-list-response';
import { AlbumsListResponse } from '@components/my-space/albums-list/albums-list-response';
import { WorkListResponse } from '@components/my-space/work-list-response';

@Injectable()
export class MySpaceService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getStudentActiviyRecordsList(params: {}): Observable<ActivityRecordsListResponse> {
    const url = `${this.apiEndpoint}/student/activity_records/myspace_search`;
    return this.http.post<ActivityRecordsListResponse>(url, params);
  }

  getStudentAlbumsList(params: {}): Observable<AlbumsListResponse> {
    const url = `${this.apiEndpoint}/student/albums/search`;
    return this.http.post<AlbumsListResponse>(url, params);
  }

  getStudentWorksList(queryParams: {}): Observable<WorkListResponse> {
    const url = `${this.apiEndpoint}/student/works/search`;
    return this.http.post<WorkListResponse>(url, queryParams);
  }

  getBadgeMySpaceStudent(): Observable<BadgeMySpaceResponse> {
    const url = `${this.apiEndpoint}/student/notifications/badge_my_space`;
    return this.http.get<BadgeMySpaceResponse>(url);
  }
}
