<div class="stu-public-activity-detail" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page">
    <h1 class="heading-text">
      <a (click)="onBack()"><i class="fa fa-chevron-left"></i></a>活動記録詳細
    </h1>
  </div>

  <div class="block-info">
    <div class="row">
      <div class="col-md-9 col-xs-9 block-time">
        <p>作成日時：{{ activityRecord?.created_at }}</p>
        <p *ngIf="!!activityRecord?.updated_at">最終更新：{{ activityRecord?.updated_at | date: 'yyyy/MM/dd HH:mm' }}</p>
        <p *ngIf="activityRecord?.is_answer">［課題］</p>
      </div>
    </div>
    <div>作成者： {{ activityRecord.user ? activityRecord.user.name : '匿名' }}</div>
    <div *ngFor="let pickup of activityRecord.pickups">
      <app-pickup-item-toggle [pickup]="pickup" [pickupType]="'public_activity'"></app-pickup-item-toggle>
    </div>
  </div>
  <app-document-detail-student
    [document]="activityRecord?.record"
    [categoryName]="activityRecord?.category_name"
    [workType]="activityRecord?.work_type"
    [marks]="activityRecord?.marks"
    [document_answers]="activityRecord.record.document_answers"
    [isCompassAnswer]="activityRecord?.is_compass_answer"
  >
  </app-document-detail-student>

  <div class="form-cross-assessment">
    <app-cross-assessment-form
      *ngIf="activityRecord.can_cross_review"
      [activityRecordId]="activityRecord?.id"
      [spaceId]="spaceId"
      (saveNewAssessment)="onSaveNewAssessment($event)"
    >
    </app-cross-assessment-form>
  </div>

  <ng-container *ngIf="activityRecord.cross_assessments.length > 0">
    <h2 class="assessments-header">みんなからのコメント</h2>
    <ng-container
      *ngFor="let assessment of activityRecord.cross_assessments; first as isFirst"
      [ngSwitch]="assessment !== edittingAssessment"
    >
      <div class="assessment-detail">
        <app-student-assessment-detail
          *ngSwitchCase="true"
          [assessment]="assessment"
          [canEdit]="canEditAssessment(assessment)"
          [isFirst]="isFirst"
          [onPublicActivity]="true"
          (editAssessment)="onEditAssessment(assessment)"
        >
        </app-student-assessment-detail>
      </div>
      <div *ngSwitchCase="false" class="form-edit-cross-assessment">
        <app-cross-assessment-form
          [activityRecordId]="activityRecord?.id"
          [spaceId]="spaceId"
          [assessment]="assessment"
          (cancelEditAssessment)="onCancelEditAssessment()"
          (updateOldAssessment)="onUpdateOldAssessment($event, assessment)"
          (deleteOldAssessment)="onDeleteOldAssessment(assessment)"
        >
        </app-cross-assessment-form>
      </div>
    </ng-container>
  </ng-container>

  <div class="block-last">
    <button class="spen-mod-btn" (click)="onBack()">戻る</button>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
