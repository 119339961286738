import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RouterService } from '@services/router.service';
import { isSafari } from '@functions/browser';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
// TODO: CanDeactivate は非推奨なので使わないようにする
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private routerService: RouterService) {}

  canDeactivate(component: CanComponentDeactivate) {
    // TODO: Safari of iPhone and iPad. In this function window.confirm is not working, so confirm dialog has been disabled before
    // We will try to correct it later
    // github ref: https://github.com/classi/classi_portfolio/pull/1980#issuecomment-514925701
    if (this.routerService.enableCanDeactive && !isSafari()) {
      if (this.routerService.isBacked && !this.routerService.manualBack) {
        // When press the back button of browser, the history will be pop already with or without window.confirm return true.
        // => Push it back to history's states so in case window.confirm return false => current page still in history states.
        history.pushState(null, null, window.location.href);
        const check = window.confirm('このまま戻ると、作成中の内容は破棄されます。破棄しますか？');
        if (check) {
          // if user use back button of browser and accept back confirm => set isBackBrowser = true
          // it used to delete tempt data in ContentSavingService
          this.routerService.removeConfirmUnload();
          this.routerService.excuseBack({ isBackBrowser: true });
        } else {
          this.routerService.isBacked = false;
          this.routerService.addConfirmUnload();
        }
        return check;
      }
      return component.canDeactivate ? component.canDeactivate() : true;
    }
    return true;
  }
}
