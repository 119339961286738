import { Component } from '@angular/core';

import { TeacherHeaderComponent } from '../../teacher-layout/teacher-header/teacher-header.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-student-header',
  templateUrl: './student-header.component.html',
  styleUrls: ['./student-header.component.scss'],
})
export class StudentHeaderComponent extends TeacherHeaderComponent {
  readonly classiTopUrl = environment.platformUrl;
  readonly helpPageUrl = environment.helpPageUrl;
}
