import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivityRecordTemplatesService } from './activity-record-templates.service';
import { ActivityRecordTemplatesComponent } from './teacher/activity-record-templates.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RecordTemplateStep1Component } from './teacher/form/step1/record-template-step1.component';
import { RecordTemplateStep2Component } from './teacher/form/step2/record-template-step2.component';
import { ImagesService } from '@services/images/images.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { S3CredentialService } from '@services/s3-credential.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { ActivityRecordTemplateFormComponent } from '@components/activity-record-templates/teacher/form/activity-record-template-form.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ActivityRecordTemplatesComponent,
    ActivityRecordTemplateFormComponent,
    RecordTemplateStep1Component,
    RecordTemplateStep2Component,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SharedModule, SharedDirectivesModule, InfiniteScrollModule],
  providers: [ActivityRecordTemplatesService, ImagesService, UploadFilesService, S3CredentialService, SearchConditionSavingService],
})
export class ActivityRecordTemplatesModule {}
