import { NgModule } from '@angular/core';

import { FocusDirective } from '../../shared/directives/focus.directive';
import { ImagePreviewDirective } from './image-preview.directive';
import { AutoExpandDirective } from '../../shared/directives/auto-expand.directive';
import { PlaceholderDirective } from './placeholder.directive';
import { ConvertLinkDirective } from './convert-link.directive';
import { ScrollToFirstInvalidDirective } from './scroll-to-first-invalid';

@NgModule({
  declarations: [
    FocusDirective,
    ImagePreviewDirective,
    AutoExpandDirective,
    PlaceholderDirective,
    ConvertLinkDirective,
    ScrollToFirstInvalidDirective,
  ],
  exports: [
    FocusDirective,
    ImagePreviewDirective,
    AutoExpandDirective,
    PlaceholderDirective,
    ConvertLinkDirective,
    ScrollToFirstInvalidDirective,
  ],
})
export class SharedDirectivesModule {}
