import { Image } from '@models/image';
import { User } from '@models/user';

export class Album {
  id: number;
  title: string;
  goal: string;
  outline: string;
  comment: string;
  image: Image;
  created_at: string;
  creator_type: string;
  neighbor_album_ids?: { next_album_id: number; prev_album_id: number };
  create_user_id: number;
  user?: User;
  space_id: number;
  can_add_work?: boolean;
  student_goal?: string;
  student_outline?: string;
  can_destroy?: boolean;
  can_edit?: boolean;

  isExistedAlbum(): boolean {
    return !!+this.id;
  }
}
