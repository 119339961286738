import { Directive, Input, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements OnInit, OnDestroy {
  @Input() appFocus: EventEmitter<boolean>;
  focusSubscription: Subscription;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if (this.appFocus) {
      this.focusSubscription = this.appFocus.subscribe((event) => {
        window.setTimeout(() => {
          this.element.nativeElement.focus();
        }, 10);
      });
    }
  }

  ngOnDestroy() {
    if (!!this.focusSubscription) {
      this.focusSubscription.unsubscribe();
    }
  }
}
