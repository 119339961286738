import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { StoryCommentTeacherModule } from '../story/teacher/story-comment/story-comment-teacher.module';

import { StoryService } from './story.service';
import { StoryCommentStudentModule } from '@components/story/student/story-comment/story-comment-student.module';
import { StoryTeacherModule } from './teacher/story-teacher.module';
import { StoryStudentModule } from './student/story-student.module';

@NgModule({
  imports: [
    CommonModule,
    SharedDirectivesModule,
    StoryCommentStudentModule,
    StoryCommentTeacherModule,
    StoryTeacherModule,
    StoryStudentModule,
  ],
  providers: [StoryService],
})
export class StoryModule {}
