import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@models/user';
import { SchoolStage } from '@models/school-stage';
import { TeacherRole } from '@models/teacher-role';

interface DataLayer {
  user_id: number;
  user_type_id: number;
  user_type_name: string;
  school_id: number;
  school_name: string;
  benesse_school_id: string;
  head_school_code: string;
  school_stage_id: string;
  school_stage_name: string;
  classi_type: number;
  teacher_role_id: string;
  teacher_role_name: string;
  usage_type1: number;
  usage_type2: number;
  usage_type3: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private containerId = environment.googleTagManagerId;

  constructor() {}

  initTagManager(user: User) {
    const dataLayer = this.buildDataLayer(user);
    this.setTagManager(dataLayer);
  }

  private setTagManager(dataLayer: DataLayer[]) {
    (window as any).dataLayer = dataLayer;
    /* eslint-disable */
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      (j as any).async = true;
      (j as any).src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', this.containerId);
  }

  private buildDataLayer(user: User): DataLayer[] {
    const [benesseSchoolId, schoolName] = user.ga.dimension2.split('_');
    const teacherRole = this.getTeacherRole(user.teacherRole);

    return [
      {
        user_id: +user.ga.dimension4,
        user_type_id: user.userTypeId,
        user_type_name: user.userTypeName,
        school_id: user.schoolId,
        school_name: schoolName,
        benesse_school_id: benesseSchoolId,
        head_school_code: user.ga.dimension2,
        school_stage_id: user.ga.dimension14,
        school_stage_name: this.getSchoolStageName(user.ga.dimension14),
        classi_type: +user.ga.dimension5,
        teacher_role_id: teacherRole.id,
        teacher_role_name: teacherRole.name,
        usage_type1: +user.ga.dimension7,
        usage_type2: +user.ga.dimension8,
        usage_type3: +user.ga.dimension9,
      },
    ];
  }

  private getSchoolStageName(schoolStageId: string): string {
    if (schoolStageId === '') {
      return '';
    }

    const schoolStage = new SchoolStage(+schoolStageId);
    return schoolStage.name;
  }

  private getTeacherRole(teacherRole: TeacherRole | null): { id: string; name: string } {
    if (!teacherRole) {
      return {
        id: '',
        name: '',
      };
    }

    return {
      id: teacherRole.id.toString(),
      name: teacherRole.name,
    };
  }
}
