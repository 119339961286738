import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { Album } from '@models/album';
import { Space } from '@components/space/space';

import { LayoutService } from '@components/layout/layout.service';

@Injectable()
export class AlbumCreatorService {
  previousStep: number;
  currentStep: number;
  space: Space;
  album: Album;
  albumId: number;
  fileUploader: FileUploader;
  attachedFiles = [];
  removedAttachFiles = [];
  isCopy = false;

  constructor(public layoutService: LayoutService) {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.space = {
      id: null,
      name: '',
      users_count: 0,
      color_code: '',
      created_at: '',
      updated_at: '',
      create_user_id: '',
      update_user_id: '',
      teacher_can_pick_up: true,
      student_can_publish: true,
      student_can_comment_other: true,
    };
    this.album = new Album();
    this.albumId = undefined;
    this.fileUploader = new FileUploader({ url: '' });
    this.attachedFiles = [];
    this.removedAttachFiles = [];
  }

  setStep3State() {
    this.currentStep = 3;
    this.previousStep = 2;
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }

  get steps(): Array<string> {
    return !!this.albumId ? ['入力', '確認', '完了'] : ['表紙作成', '配信設定', '確認', '完了'];
  }

  private isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }
}
