import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-home',
  template: '<div></div>',
})
export class HomeComponent implements OnInit {
  constructor(
    public authUserService: AuthUserService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.authUserService.isStudent()) {
      this.router.navigateByUrl('/my-space', { replaceUrl: true });
    } else {
      this.router.navigateByUrl('/timeline/teacher', { replaceUrl: true });
    }
  }
}
