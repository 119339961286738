import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { mapValues, pick, pickBy, omitBy, isNil, findIndex, isEqual, omit } from 'lodash';

import { CategoriesService } from '@services/categories/categories.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { TeacherMarksService } from '@services/marks/teacher-marks.service';
import { SpaceService } from '@components/space/space.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

import { checkBlankString } from '@functions/check-string';

import { Mark } from '@models/mark';
import { PublicActivityRecord } from './public-activity-record';

@Component({
  selector: 'app-space-public-activity-records',
  templateUrl: './space-public-activity-records.component.html',
  styleUrls: ['./space-public-activity-records.component.scss'],
})
export class SpacePublicActivityRecordsComponent implements OnInit {
  COMMENT_STATUSES = [null, 'has_comment', 'no_comment'];
  SUB_COMMENT_STATUSES = ['has_comment', 'cross', 'teacher'];
  isSearching = false;

  sortOptions = [
    { sort_value: 'created_at desc', text: '作成日時（降順）' },
    { sort_value: 'created_at asc', text: '作成日時（昇順）' },
    { sort_value: 'updated_at desc', text: '更新日時（降順）' },
    { sort_value: 'updated_at asc', text: '更新日時（昇順）' },
  ];
  iconSort = {
    none: 'fa',
    loader: 'fa fa-spinner fa-spin',
    up: 'fa fa-caret-up',
    down: 'fa fa-caret-down',
  };
  sortStatus = {
    created_at: this.iconSort.none,
    updated_at: this.iconSort.none,
  };

  spaceId: number;
  isShowMoreFilter = false;
  contentLoaded = false;
  filterData = {};
  publicActivityRecords: Array<PublicActivityRecord> = [];

  inProgressSort = false;
  sortKey = null;
  sortOrder = null;

  private readonly allowedParamKeys = [
    'keyword',
    'category_id',
    'activity_type',
    'public_status',
    'comment_status',
    'mark_ids',
    'sort_key',
    'sort_order',
    'page',
  ];
  private readonly disallowedUrlParamKeys = ['keyword'];
  private readonly saveConditionKeys = ['activity_type', 'sort_key', 'sort_order'];

  form: UntypedFormGroup;
  formInited = false;
  pages = {
    per_page: 0,
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
  };
  isShowDialogModal: boolean;
  messageSort: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spaceService: SpaceService,
    public layoutService: LayoutService,
    private marksService: TeacherMarksService,
    public categoriesService: CategoriesService,
    public filterDataService: TeacherFilterService,
    private formBuilder: UntypedFormBuilder,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    this.spaceId = this.route.snapshot.params['id'];

    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 1) {
      newParams = this.searchConditionSavingService.getSearchConditions(url, 'public_activity_record');
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions, 'public_activity_record');
    }

    this.sortKey = newParams['sort_key'] || 'created_at';
    this.sortOrder = newParams['sort_order'] || 'desc';
    this.filterData = pick(newParams, this.allowedParamKeys);
    if (!this.filterData['sort_key'] || !this.filterData['sort_order']) {
      Object.assign(this.filterData, { sort_key: this.sortKey, sort_order: this.sortOrder });
    }

    await this.categoriesService.loadCategories();
    await this.marksService.loadMarks();
    await this.filterDataService.setFilter(this.filterData);

    this.initForm();
    this.loadSpacePublicActivitiesRecords(this.filterData);
    this.messageSort = this.getMessageSort();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  loadSpacePublicActivitiesRecords(params) {
    if (!this.inProgressSort) {
      this.contentLoaded = false;
    }

    this.spaceService.getTeacherActivitiesOfSpace(this.spaceId, params).subscribe(
      (response) => {
        Object.assign(this.pages, response.meta);
        this.publicActivityRecords = response.public_activity_records;
        this.refreshIconSort();
        this.contentLoaded = true;
      },
      (error) => {
        // TODO: error handling.
      },
    );
  }

  refreshIconSort() {
    this.sortStatus = mapValues(this.sortStatus, () => this.iconSort.none);

    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = this.iconSort[icon];

    if (this.inProgressSort) {
      this.form.controls['sort_value'].setValue(this.getSortValue());
      this.inProgressSort = false;
    }
  }

  defaultFilterValue() {
    const filterValues = {
      mark_ids: this.marks.map((m) => false),
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = this.sortOptions[0].sort_value;
    }

    return filterValues;
  }

  initForm() {
    const commentStatus = this.filterData['comment_status'] || null;
    const selectedMarkIds: Array<Number> = this.filterData['mark_ids'] || [];

    this.form = this.formBuilder.group({
      keyword: [this.filterData['keyword'], Validators.maxLength(100)],
      category_id: this.filterData['category_id'],
      activity_type: this.filterData['activity_type'],
      public_status: this.filterData['public_status'],
      comment_status: this.COMMENT_STATUSES.includes(commentStatus) ? commentStatus : 'has_comment',
      sub_comment_status: [
        {
          value: this.SUB_COMMENT_STATUSES.includes(commentStatus) ? commentStatus : 'has_comment',
          disabled: !this.SUB_COMMENT_STATUSES.includes(commentStatus),
        },
      ],
      mark_ids: this.formBuilder.array(this.marks.map((mark) => selectedMarkIds.map(Number).includes(mark.id))),
      sort_value: this.getSortValue(),
    });

    this.formInited = true;
    this.isSearching = !this.isDefaultForm;
  }

  getSortValue() {
    return this.sortKey ? `${this.sortKey} ${this.sortOrder}` : this.sortOptions[0].sort_value;
  }

  onSubmit() {
    window.scrollTo(0, 0);
    if (this.layoutService.isTabletDownView.value) {
      this.isShowMoreFilter = false;
    }

    const params = omitBy(this.form.value, (value) => isNil(value));

    if (checkBlankString(params['keyword'])) {
      delete params['keyword'];
    }

    if (!!params['comment_status'] && !!params['sub_comment_status'] && params['sub_comment_status'] !== 'has_comment') {
      params['comment_status'] = params['sub_comment_status'];
    }
    delete params['sub_comment_status'];

    if (params['mark_ids']) {
      params['mark_ids'] = this.marks.map((mark) => mark.id).filter((id, index) => params['mark_ids'][index]);
    } else {
      delete params['mark_ids'];
    }

    this.buildOrderParams(params);
    delete params['sort_value'];

    params['request_timestamp'] = Date.now();

    this.navigate(this.buildQueryParams(params));
  }

  private buildOrderParams(params) {
    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
  }

  onResetFilter() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortOptions[0].sort_value.split(' ');
    }

    this.form.reset(this.defaultFilterValue());
    this.onSubmit();
    this.selectCommentStatus();
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  selectCommentStatus(value = null) {
    if (value !== 'has_comment') {
      this.form.get('sub_comment_status').disable();
    } else {
      this.form.get('sub_comment_status').enable();
    }
    this.form.get('sub_comment_status').setValue('has_comment');
  }

  changeBySortColumn(sortKey: string) {
    if (this.isContentLoading) {
      return false;
    }
    this.inProgressSort = true;

    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = this.iconSort.none;
      this.sortKey = sortKey;
      this.sortOrder = 'desc';
    }
    this.sortStatus[this.sortKey] = this.iconSort.loader;

    const params = { ...this.filterData, ...this.buildSortParams() };
    this.navigate(params, 'merge');
  }

  buildSortParams() {
    return { page: 1, sort_key: this.sortKey, sort_order: this.sortOrder };
  }

  buildQueryParams(params: {}) {
    return Object.assign(params, { tab: 'activity_records', page: 1 });
  }

  pageChanged(page: number) {
    const params = { ...this.filterData, page: page };
    this.navigate(params, 'merge');
  }

  onClickActivityRecord(activityRecord: PublicActivityRecord) {
    if (!!activityRecord.student.viewer_permission) {
      this.router.navigateByUrl(`/activity-records/teacher/${activityRecord.id}`, { state: { navigationFrom: '/spaces/teacher' } });
    }
  }

  onClickStudentDetail(event: Event, activityRecord: PublicActivityRecord) {
    if (!!activityRecord.student.viewer_permission) {
      this.router.navigateByUrl(`/students/teacher/${activityRecord.student.id}`);
    }
    event.stopPropagation();
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  get marks(): Array<Mark> {
    return this.marksService.marksSource.value;
  }

  getMessageSort() {
    const index = findIndex(this.sortOptions, ['sort_value', `${this.sortKey} ${this.sortOrder}`]);
    return index ? this.sortOptions[index].text : this.sortOptions[0].text;
  }

  get isContentLoading(): boolean {
    return !this.contentLoaded || this.inProgressSort;
  }

  get isDefaultForm(): boolean {
    const compareValues = pickBy(this.form.value);

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  onShowModalDialog() {
    this.isShowDialogModal = true;
  }

  onCancelModalDialog() {
    this.isShowDialogModal = false;
  }
}
