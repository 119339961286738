// TODO: Move this service to frontend/src/app/shared/services, providedIn: root

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUserService } from '@services/auth-user.service';
import { Grade } from '@components/school-grade-class-select/grade';
import { ClassesFilterService } from '@components/school-grade-class-select/classes-filter.service';
import { Class } from '@components/school-grade-class-select/class';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-school-grade-class-select',
  templateUrl: './school-grade-class-select.component.html',
  styleUrls: ['./school-grade-class-select.component.scss'],
})
export class SchoolGradeClassSelectComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  schoolYears: Array<string>;
  grades: Array<Grade>;
  classes: Array<Class>;
  currentYear: string;
  currentGradeId: number;
  currentClassId: number;
  errMsg: string;
  optionsLoaded = false;

  constructor(
    public layoutService: LayoutService,
    public classesFilterService: ClassesFilterService,
    private route: ActivatedRoute,
    private router: Router,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (this.classesFilterService.isReinitSelectData) {
        this.currentYear = this.parentForm.get('school_year').value;
        this.currentGradeId = +this.parentForm.get('grade_id').value;
        this.currentClassId = +this.parentForm.get('class_id').value || null;
      } else {
        this.currentYear = params['school_year'];
        this.currentGradeId = +params['grade_id'];
        this.currentClassId = +params['class_id'] || null;
      }

      if (!this.schoolYears) {
        await Promise.all([this.classesFilterService.getSchoolYears(), this.classesFilterService.getGrades()]);
        this.schoolYears = this.classesFilterService.schoolYears.value;
        this.grades = this.classesFilterService.grades.value;
        this.optionsLoaded = true;
      }

      if (!this.schoolYears.includes(this.currentYear)) {
        this.currentYear = this.getCurrentYear();
      }

      if (!this.grades.map((grade) => grade.id).includes(this.currentGradeId)) {
        this.currentGradeId = this.grades[0].id;
      }

      if (!this.classes) {
        this.getClassesList();
      }
    });
  }

  ngOnDestroy() {
    this.classesFilterService.isReinitSelectData = true;
  }

  onChangeSchoolOption(value) {
    this.currentYear = value;
    this.getClassesList();
  }

  onChangeGradeOption(value) {
    this.currentGradeId = +value;
    this.getClassesList();
  }

  onChangeClassIdOption(value) {
    this.currentClassId = +value || null;
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return this.schoolYears.includes(currentUser.schoolYear) ? currentUser.schoolYear : this.schoolYears[0];
  }

  onResetForm() {
    this.currentYear = this.getCurrentYear();
    this.currentGradeId = this.classesFilterService.grades.value[0].id;
    this.currentClassId = null;
    this.getClassesList();
  }

  private async getClassesList() {
    this.parentForm.get('school_year').disable();
    this.parentForm.get('grade_id').disable();
    this.parentForm.get('class_id').disable();
    this.classes = await this.classesFilterService.getClasses({ school_year: this.currentYear, grade_id: this.currentGradeId });
    if (this.currentClassId && !this.classes.map((_class) => _class.id).includes(this.currentClassId)) {
      this.currentClassId = null;
    }
    this.parentForm.get('school_year').enable();
    this.parentForm.get('grade_id').enable();
    this.parentForm.get('class_id').enable();
    this.fillValueForSelects();
  }

  private fillValueForSelects() {
    this.parentForm.get('school_year').setValue(this.currentYear);
    this.parentForm.get('grade_id').setValue(this.currentGradeId);
    this.parentForm.get('class_id').setValue(this.currentClassId);
  }
}
