import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Category } from '@models/category';
import { Mark } from '@models/mark';
import { TeacherActivityRecordItem } from '@components/activity-record/activity-records-list/teacher/teacher-activity-record-item';
import { ClassesFilterService } from '@components/school-grade-class-select/classes-filter.service';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';
import { ActivityRecordService } from '@components/activity-record/activity-record.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { CategoriesService } from '@services/categories/categories.service';
import { LayoutService } from '@components/layout/layout.service';
import { pick, pickBy, findIndex, mapValues, isEqual, omit, merge, isNil } from 'lodash';
import { AuthUserService } from '@services/auth-user.service';
import { TeacherMarksService } from '@services/marks/teacher-marks.service';
import { checkBlankString } from '@functions/check-string';
import { SchoolGradeClassSelectComponent } from '@components/school-grade-class-select/school-grade-class-select.component';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { ControlLinkService } from '@services/control-link/control-link.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

const IconSort = {
  none: 'fa',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-teacher-activity-records-list',
  templateUrl: './teacher-activity-records-list.component.html',
  styleUrls: ['./teacher-activity-records-list.component.scss'],
  providers: [
    TeacherFilterService,
    CategoriesService,
    ActivityRecordService,
    TeacherMarksService,
    ClassesFilterService,
    SearchConditionSavingService,
  ],
})
export class TeacherActivityRecordsListComponent implements OnInit {
  @ViewChild(SchoolGradeClassSelectComponent) formChild: SchoolGradeClassSelectComponent;

  commentStatuses = [
    { value: null, text: 'すべて' },
    { value: 'has_comment', text: 'コメントあり' },
    { value: 'no_comment', text: 'コメントなし' },
  ];

  subCommentStatuses = [
    { value: 'has_comment', text: 'すべて' },
    { value: 'cross', text: '生徒コメント' },
    { value: 'teacher', text: '先生コメント' },
  ];

  publicStatuses = [
    { value: null, text: 'すべて' },
    { value: 'shared', text: '公開' },
    { value: 'not_shared', text: '非公開' },
  ];

  subPublicStatuses = [
    { value: 'shared', text: 'すべて' },
    { value: 'self_publish', text: '自主公開' },
    { value: 'pickup', text: 'ピックアップ' },
  ];

  activityType = [
    { value: null, text: 'すべて' },
    { value: 'free', text: '自主作成' },
    { value: 'answer', text: '課題回答' },
    { value: 'questionnaire', text: 'アンケート回答' },
  ];

  formSubscriber = null;
  isShowFilter = false;
  metaData = {
    current_page: 0,
    next_page: null,
    per_page: 0,
    prev_page: null,
    total_pages: 0,
    total_count: 0,
    have_record_in_table: true,
  };
  filterData = {};
  queryParams = {};
  form: UntypedFormGroup;
  formLoaded = false;
  activityRecords: Array<TeacherActivityRecordItem> = [];
  dataLoaded = false;
  inProgressSort = false;
  isSearching = false;
  errMsg: string;
  sortKey = null;
  sortOrder = null;
  messageFilter: string;
  messageSort: string;

  sortStatus = {
    created_at: IconSort.none,
    updated_at: IconSort.none,
  };
  sortSelections = [
    { value: 'created_at desc', text: '作成日時（降順）' },
    { value: 'created_at asc', text: '作成日時（昇順）' },
    { value: 'updated_at desc', text: '更新日時（降順）' },
    { value: 'updated_at asc', text: '更新日時（昇順）' },
  ];

  private readonly allowedParamKeys = [
    'school_year',
    'grade_id',
    'class_id',
    'sort_key',
    'sort_order',
    'page',
    'activity_id',
    'keyword',
    'mark_ids',
    'category_id',
    'activity_type',
    'comment_status',
    'public_status',
  ];
  private readonly disallowedUrlParamKeys = ['keyword'];
  readonly saveConditionKeys = ['school_year', 'grade_id', 'class_id', 'sort_key', 'sort_order'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activityRecordService: ActivityRecordService,
    public classFilterService: ClassesFilterService,
    private marksService: TeacherMarksService,
    public categoriesService: CategoriesService,
    public layoutService: LayoutService,
    public filterDataService: TeacherFilterService,
    private authUserService: AuthUserService,
    private analytics4Service: GoogleAnalytics4Service,
    private formBuilder: UntypedFormBuilder,
    private navigationService: TeacherNavigationService,
    private controlLinkService: ControlLinkService,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    await this.categoriesService.loadCategories();
    await this.marksService.loadMarks();
    await this.classFilterService.getSchoolYears();
    await this.classFilterService.getGrades();

    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 0) {
      newParams = this.searchConditionSavingService.getSearchConditions(url);
      this.classFilterService.isReinitSelectData = true;
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
    }

    this.setQueryParamsFilter(newParams);
    await this.initForm();
    this.filterText(this.queryParams).then((message) => {
      this.messageFilter = message;
    });
    this.messageSort = this.sortText;
    this.queryParams = Object.assign(pickBy(this.queryParams), { filter_default: !this.isSearching });
    this.getTeacherActivityRecordsList(this.queryParams);
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  setQueryParamsFilter(params = {}) {
    this.queryParams = pick(params, this.allowedParamKeys);
    this.queryParams['page'] = +params['page'] || 1;
    this.queryParams['school_year'] = params['school_year'] || this.getCurrentYear();
    this.queryParams['grade_id'] = +params['grade_id'] || this.classFilterService.grades.value[0].id;
    this.queryParams['class_id'] = +params['class_id'] || null;
    this.queryParams['sort_key'] = params['sort_key'] || 'created_at';
    this.queryParams['sort_order'] = params['sort_order'] || 'desc';
    this.sortKey = this.queryParams['sort_key'];
    this.sortOrder = this.queryParams['sort_order'];
  }

  async initForm() {
    const comment_status = this.queryParams['comment_status'] || null;
    const public_status = this.queryParams['public_status'] || null;
    await this.classFilterService.getClasses(this.queryParams);

    if (this.queryParams['mark_ids'] && !(this.queryParams['mark_ids'] instanceof Array)) {
      this.queryParams['mark_ids'] = [this.queryParams['mark_ids']];
    }

    const selectedMarkIds: Array<Number> = this.queryParams['mark_ids'] || [];
    this.form = this.formBuilder.group({
      school_year: this.queryParams['school_year'],
      grade_id: this.queryParams['grade_id'],
      class_id: this.queryParams['class_id'],
      activity_type: this.queryParams['activity_type'],
      keyword: this.queryParams['keyword'],
      category_id: parseInt(this.queryParams['category_id'], 10),
      mark_ids: this.formBuilder.array(this.marks.map((mark) => selectedMarkIds.map(Number).includes(mark.id))),
      sort_value: this.getSortValue(),

      comment_status: this.commentStatuses.map((comment) => comment.value).includes(comment_status) ? comment_status : 'has_comment',
      sub_comment_status: [
        {
          value: this.subCommentStatuses.map((sub_comment) => sub_comment.value).includes(comment_status) ? comment_status : 'has_comment',
          disabled: !this.subCommentStatuses.map((sub_comment) => sub_comment.value).includes(comment_status),
        },
      ],
      public_status: this.publicStatuses.map((status) => status.value).includes(public_status) ? public_status : 'shared',
      sub_public_status: [
        {
          value: this.subPublicStatuses.map((sub_status) => sub_status.value).includes(public_status) ? public_status : 'shared',
          disabled: !this.subPublicStatuses.map((sub_status) => sub_status.value).includes(public_status),
        },
      ],
    });
    this.formLoaded = true;
    this.isSearching = !this.isDefaultForm;
  }

  defaultFilterValue() {
    const filterValues = {
      school_year: this.getCurrentYear(),
      grade_id: this.classFilterService.grades.value[0].id,
      class_id: null,
      keyword: null,
      activity_type: null,
      category_id: null,
      comment_status: null,
      public_status: null,
      mark_ids: this.marks.map((m) => false),
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = this.sortSelections[0].value;
    }

    return filterValues;
  }

  getSortValue() {
    return this.sortKey ? `${this.sortKey} ${this.sortOrder}` : this.sortSelections[0].value;
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return this.classFilterService.schoolYears.value.includes(currentUser.schoolYear)
      ? currentUser.schoolYear
      : this.classFilterService.schoolYears.value[0];
  }

  async filterText(params = {}) {
    let gradeName = null;
    const grade = this.classFilterService.grades.value.find((item) => {
      return item.id === +params['grade_id'];
    });

    if (grade) {
      gradeName = grade.school_stage_name + grade.name;
    } else {
      gradeName = this.classFilterService.grades[0].school_stage_name + this.classFilterService.grades[0].name;
    }

    if (!!this.classFilterService.classes.value && !this.dataLoaded) {
      await this.classFilterService.getClasses(params);
    }

    const _class = this.classFilterService.classes.value.find((item) => {
      return item.id === +params['class_id'];
    });

    const className = _class ? _class.classroom_name : 'すべての組';

    this.filterData = Object.assign({}, params, { grade: gradeName, class: className });
    await this.filterDataService.setFilter(this.filterData);

    return this.filterDataService.messageFilter;
  }

  getTeacherActivityRecordsList(params = {}) {
    if (!this.inProgressSort) {
      this.dataLoaded = false;
    }
    this.activityRecordService.getTeacherActivityRecordsList(params).subscribe(
      (response) => {
        this.activityRecords = response.activity_records;
        Object.assign(this.metaData, response.meta);
        this.refreshIconSort();
        this.dataLoaded = true;
      },
      (error) => {
        this.activityRecords = [];
        this.errMsg = <any>error;
      },
    );
  }

  onSubmit(sendGA: boolean = true) {
    window.scrollTo(0, 0);
    if (this.layoutService.isTabletDownView.value) {
      this.isShowFilter = false;
    }
    const params = pickBy(this.form.value);

    if (checkBlankString(params['keyword'])) {
      delete params['keyword'];
      this.form.get('keyword').setValue(null);
    } else {
      params['keyword'] = params['keyword'].trim();
      this.form.get('keyword').setValue(params['keyword']);
    }

    if (!!params['comment_status'] && !!params['sub_comment_status'] && params['sub_comment_status'] !== 'has_comment') {
      params['comment_status'] = params['sub_comment_status'];
    }
    delete params['sub_comment_status'];
    if (!!params['public_status'] && !!params['sub_public_status'] && params['sub_public_status'] !== 'shared') {
      params['public_status'] = params['sub_public_status'];
    }
    delete params['sub_public_status'];

    if (params['mark_ids'].every((mark) => mark === false)) {
      delete params['mark_ids'];
    } else {
      params['mark_ids'] = this.marks.map((mark) => mark.id).filter((id, index) => params['mark_ids'][index]);
    }

    this.buildOrderParams(params);
    delete params['sort_value'];

    params['request_timestamp'] = Date.now();

    this.filterText(this.queryParams).then((message) => {
      this.messageFilter = message;
    });

    this.navigate(params);
    if (sendGA) {
      this.sendEventGA4SearchActivity();
    }
  }

  private async sendEventGA4SearchActivity(): Promise<void> {
    let subCategory: Category;
    let category = await this.categoriesService.getCategory(this.form.value['category_id']);
    if (category.parent_id) {
      subCategory = category;
      category = await this.categoriesService.getCategory(category.parent_id);
    }

    this.analytics4Service.sendEvent('活動記録', '活動記録一覧画面', '検索する', {
      type_name: this.activityType.find((type) => type.value === this.form.value['activity_type']).text,
      category_name: category.name || 'すべて',
      sub_category_name: subCategory && subCategory.name,
      comment_status: this.commentStatuses.find((status) => status.value === this.form.value['comment_status']).text,
      sub_comment_status:
        this.form.value['sub_comment_status'] &&
        this.subCommentStatuses.find((status) => status.value === this.form.value['sub_comment_status']).text,
      publication_status: this.publicStatuses.find((status) => status.value === this.form.value['public_status']).text,
      sub_publication_status:
        this.form.value['sub_public_status'] &&
        this.subPublicStatuses.find((status) => status.value === this.form.value['sub_public_status']).text,
      mark_name: this.marks
        .filter((_, index) => this.form.value['mark_ids'][index])
        .map((mark) => mark.name)
        .join(','),
    });
  }

  private buildOrderParams(params) {
    [this.sortKey, this.sortOrder] = this.form.get('sort_value').value.split(' ');
    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
  }

  changeBySortColumn(sortKey: string) {
    if (this.inProgressSort) {
      return false;
    }
    this.inProgressSort = true;

    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = IconSort.none;
      this.sortKey = sortKey;
      this.sortOrder = 'desc';
    }
    this.sortStatus[this.sortKey] = IconSort.loader;

    const params = merge(this.queryParams, { page: 1, sort_key: this.sortKey, sort_order: this.sortOrder });
    this.navigate(params, 'merge');
  }

  refreshIconSort() {
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);

    if (!this.dataLoaded) {
      // when the list was just loaded
      this.dataLoaded = true;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else if (this.inProgressSort) {
      // when the list was just sorted
      this.inProgressSort = false;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = IconSort[icon];
    } else {
      // when the list is being sorted
      this.inProgressSort = true;
      this.sortStatus[this.sortKey] = IconSort.loader;
    }
    this.form.controls['sort_value'].setValue(this.getSortValue());
  }

  selectCommentStatus(value = null) {
    if (!value || !value.includes('has_comment')) {
      this.form.get('sub_comment_status').disable();
    } else {
      this.form.get('sub_comment_status').enable();
    }
    this.form.get('sub_comment_status').setValue('has_comment');
  }

  selectPublicStatus(value = null) {
    if (!value || value.includes('null') || value.includes('not_shared')) {
      this.form.get('sub_public_status').disable();
    } else {
      this.form.get('sub_public_status').enable();
    }
    this.form.get('sub_public_status').setValue('shared');
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  get marks(): Array<Mark> {
    return this.marksService.marksSource.value;
  }

  get sortText(): string {
    const index = findIndex(this.sortSelections, ['value', `${this.sortKey} ${this.sortOrder}`]);
    return index ? this.sortSelections[index].text : this.sortSelections[0].text;
  }

  get showPagination(): boolean {
    return this.metaData && this.metaData.total_pages > 1;
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  resetForm() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[0].value.split(' ');
    }

    this.form.patchValue(this.defaultFilterValue());
    this.selectCommentStatus();
    this.selectPublicStatus();
    this.onSubmit();
    this.formChild.onResetForm();
  }

  get isDefaultForm(): boolean {
    const compareValues = pickBy(this.form.value);

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, pickBy(this.defaultFilterValue()));
  }

  get isEmptySearchResults(): boolean {
    return this.metaData.total_count === 0 && this.dataLoaded;
  }

  get isLoadingData(): boolean {
    return this.classFilterService.isLoading || !this.dataLoaded || this.inProgressSort;
  }

  get haveActiveRecordInDB(): boolean {
    return this.metaData.have_record_in_table;
  }

  onClickActivityRecord(activityRecordId, event: KeyboardEvent) {
    this.navigationService.registerNavigator({ screenId: 2, filterData: omit(pickBy(this.queryParams), 'page') });
    this.controlLinkService.open(`/activity-records/teacher/${activityRecordId}`, event);
  }

  onClickStudent(studentId, event: KeyboardEvent) {
    event.stopPropagation();
    this.controlLinkService.open(`/students/teacher/${studentId}`, event);
  }

  showMore() {
    this.isShowFilter = !this.isShowFilter;
  }

  toggleFilterOnMobileView() {
    return this.layoutService.isTabletDownView.value ? (this.isShowFilter ? 'active' : 'inactive') : '';
  }
}
