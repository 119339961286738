import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-segment-view',
  templateUrl: './segment-view.component.html',
  styleUrls: ['./segment-view.component.scss'],
})
export class SegmentViewComponent implements OnInit {
  @Input() tabOptions: { [key: string]: string };
  @Input() tabBadge = {};
  @Input() disableChangeTab: boolean;
  @Output() switchTab = new EventEmitter<Object>();

  currentTab: { [key: string]: string };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initTabView();
  }

  initTabView() {
    const tabOptions = this.tabOptions || {};
    this.route.queryParams.subscribe((params) => {
      const tabValue = tabOptions[params['tab']];
      const defaultTabKey = !!tabValue ? params['tab'] : Object.keys(tabOptions)[0];
      if (!defaultTabKey) {
        return;
      }
      this.currentTab = this.createTabInfo(defaultTabKey);
    });
  }

  isActiveTab(tabKey: string) {
    return this.currentTab.key === tabKey;
  }

  onChangeTab(tabKey: string) {
    if (this.disableChangeTab) {
      return;
    }
    this.switchTab.emit(tabKey);
    this.currentTab = this.createTabInfo(tabKey);
  }

  createTabInfo(defaultTabKey: string) {
    return { key: defaultTabKey, value: this.tabOptions[defaultTabKey] };
  }

  get tabKeys() {
    return Object.keys(this.tabOptions);
  }

  tabValue(tabKey: string) {
    return this.tabOptions[tabKey];
  }
}
