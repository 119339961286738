<div class="gallery" *ngIf="isShow" (click)="onClose()" [class.loading]="isLoading" [class.is-show]="isShow">
  <div class="gallery-inner" #gallery_inner>
    <div class="gallery-content" #gallery_content>
      <img
        class="gallery-img"
        *ngIf="currentFileIsImage"
        (click)="preventCloseOnContent($event)"
        (load)="onContentLoad()"
        [src]="currentFilePath"
      />
      <video
        class="gallery-video"
        *ngIf="currentFileIsVideo"
        controls
        autoplay
        (click)="preventCloseOnContent($event)"
        (loadeddata)="onContentLoad()"
        [src]="currentFilePath"
      ></video>
      <audio
        class="gallery-audio"
        *ngIf="currentFileIsAudio"
        controls
        autoplay
        (click)="preventCloseOnContent($event)"
        (loadeddata)="onContentLoad()"
        [src]="currentFilePath"
      ></audio>
      <p class="gallery-caption" #gallery_caption>
        {{ currentFileName }}
      </p>
    </div>
    <div class="gallery-loader" *ngIf="isLoading"></div>
  </div>
</div>
