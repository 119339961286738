<ng-container *ngIf="formLoaded; else loadingIcon">
  <ng-container *ngIf="layoutService.isTabletDownView.value; else pcView">
    <div class="sp-filter">
      <div class="filter-info">
        <div class="field">
          <div class="label-text">検索条件</div>
          <div class="text break-word" data-cy="text-filter">{{ filterText() }}</div>
        </div>
        <div class="field">
          <div class="label-text">並び順</div>
          <div class="text" data-cy="text-sort-order">{{ messageSort }}</div>
        </div>
      </div>
      <div class="filter-panel">
        <div class="drawers">
          <a class="spen-util-link" (click)="toggleFilter()">
            <span class="">変更</span>
            <i [class]="isMobileFilterOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
          </a>
        </div>
        <form *ngIf="isMobileFilterOpen && formLoaded" [formGroup]="filterForm">
          <div class="filter-inputs">
            <div class="input-group">
              <div class="label-text">配信者</div>
              <label class="spen-mod-select">
                <select class="select" data-cy="distributor" formControlName="distributor" id="distributor-select">
                  <option *ngFor="let choice of distributorSelections" [ngValue]="choice.value">
                    {{ choice.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
            <div class="input-group">
              <div class="label-text">タイトル</div>
              <input
                class="spen-mod-input-text"
                data-cy="search-title"
                placeholder="タイトルを入力してください"
                type="text"
                maxlength="50"
                formControlName="keyword"
              />
            </div>
            <div class="input-group">
              <div class="label-text">並び順</div>
              <label class="spen-mod-select">
                <select
                  class="select"
                  data-cy="sort-order"
                  formControlName="sort"
                  id="sort-select"
                  (change)="onChangeSortValue($event.target.value)"
                >
                  <option *ngFor="let choice of sortSelections" [value]="choice.value">
                    {{ choice.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>
          <div class="filter-buttons">
            <button
              class="spen-mod-btn is-primary"
              data-cy="submit-filter"
              (click)="onSubmit()"
              [disabled]="isSorting || !contentLoaded"
              type="button"
            >
              適用する
            </button>
            <button
              class="spen-mod-btn is-default"
              data-cy="remove-filter"
              (click)="resetForm()"
              [disabled]="isLoadingData || isDefaultForm"
              type="button"
            >
              選択解除
            </button>
          </div>
        </form>
      </div>
    </div>
    <ng-container *ngIf="contentLoaded; else loadingIcon">
      <div class="paginate-result" data-cy="text-page-info">{{ metaData | paginateText }}</div>
      <div class="graduation-message-items" *ngIf="metaData.total_count !== 0; else emptyList">
        <div
          class="graduation-message-item clickable-item"
          data-cy="graduation-item"
          (click)="onGraduationMessageClick(graduationMessage)"
          *ngFor="
            let graduationMessage of graduationMessages
              | paginate
                : {
                    itemsPerPage: metaData.per_page,
                    currentPage: metaData.current_page,
                    totalItems: metaData.total_count,
                  }
          "
        >
          <div class="graduation-message-title">
            <span class="label-text truncate-text" data-cy="graduation-title">
              {{ graduationMessage.title }}
            </span>
          </div>
          <div class="text truncate-text distributor-name" data-cy="distributor-name">配信者：{{ graduationMessage.create_user_name }}</div>
          <div class="delivery-time small-text" data-cy="graduation-created-at">
            <div>
              配信日時：<span>{{ graduationMessage.created_at }}</span>
            </div>
          </div>
          <i class="fa fa-chevron-right"></i>
        </div>
      </div>

      <div class="spen-mod-paginate" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #pcView>
    <div class="pc-filter">
      <div class="filter-panel">
        <form [formGroup]="filterForm" *ngIf="formLoaded">
          <div class="filter-inputs">
            <div class="spen-mod-checkbox">
              <span class="label-text">配信者</span>
              <label class="spen-mod-radio" data-cy="distributor" *ngFor="let choice of distributorSelections">
                <input class="radio" data-cy="distributor-radio" formControlName="distributor" type="radio" [value]="choice.value" />
                <i class="fa"></i><span class="text">{{ choice.text }}</span>
              </label>

              <span class="label-text">タイトル</span>
              <input
                class="spen-mod-input-text"
                data-cy="search-title"
                placeholder="タイトルを入力してください"
                type="text"
                maxlength="50"
                formControlName="keyword"
              />
            </div>
          </div>
          <div class="filter-buttons">
            <button
              class="spen-mod-btn is-default"
              data-cy="remove-filter"
              (click)="resetForm()"
              [disabled]="isLoadingData || isDefaultForm"
              type="button"
            >
              選択解除
            </button>
            <button class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isLoadingData" type="button">
              検索する
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="result-info clearfix" *ngIf="contentLoaded">
      <span class="filter-info break-word" data-cy="text-filter" *ngIf="isSearching || graduationMessages.length > 0"
        >検索条件：{{ filterText() }}</span
      >
      <span class="paginate-result" data-cy="text-page-info">{{ metaData | paginateText }}</span>
    </div>
    <ng-container *ngIf="contentLoaded; else loadingIcon">
      <div class="section-contents">
        <div class="spen-mod-flex-table table-list-full has-link" *ngIf="metaData.total_count !== 0; else emptyList">
          <div class="flex-table-head">
            <div class="flex-item col-size-9 is-first-col">タイトル</div>
            <div class="flex-item col-size-4">配信者</div>
            <div class="flex-item col-size-3">
              <a class="spen-util-link" (click)="onChangeOrder('created_at')">
                配信日時
                <i [ngClass]="sortStatus['created_at']" data-cy="col-created-at"></i>
              </a>
            </div>
            <div class="flex-item redirect-cell"></div>
          </div>
          <a
            class="flex-table-row"
            data-cy="graduation-item"
            *ngFor="
              let graduationMessage of graduationMessages
                | paginate
                  : {
                      itemsPerPage: metaData.per_page,
                      currentPage: metaData.current_page,
                      totalItems: metaData.total_count,
                    }
            "
            (click)="onGraduationMessageClick(graduationMessage)"
          >
            <div class="flex-item col-size-9 is-first-col truncate-text graduation-message-title">
              <span class="truncate-text" data-cy="graduation-title">{{ graduationMessage.title }}</span>
            </div>
            <div class="flex-item col-size-4 truncate-text" data-cy="distributor-name">{{ graduationMessage.create_user_name }}</div>
            <div class="flex-item col-size-3" data-cy="graduation-created-at">{{ graduationMessage.created_at }}</div>
            <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
          </a>
        </div>

        <div class="spen-mod-paginate" *ngIf="showPagination">
          <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #emptyList>
  <div [class]="isSearching ? 'empty-search' : 'empty-list'">
    <p class="text">{{ isSearching ? '検索結果が0件です。' : '配信された卒業メッセージが表示されます。' }}</p>
  </div>
</ng-template>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
