<div class="portfolio-graduation">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <button class="spen-mod-btn is-primary" (click)="slideMenu()"><i class="fa fa-reorder"></i></button>ポートフォリオ卒業
    </h1>
  </div>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="portfolio-content">
      <p class="portfolio-title">ポートフォリオダウンロード</p>
      <p class="portfolio-note">
        卒業後にいかせそうな経験はありましたか？ぜひ、ダウンロードして、いつでも振り返りできるようにしてくださいね。
      </p>

      <div class="student-summaries" *ngIf="studentSummaries.length > 0">
        <p class="student-summaries-title">できるようになったこと</p>

        <div class="summary" *ngFor="let summary of studentSummaries" (click)="eventShowActivity(summary.activity_record_id)">
          <div class="published-date">
            {{ summary.created_at }}
            <img class="mark" *ngFor="let mark of summary.marks" src="{{ mark.icon }}" />
          </div>
          <div class="summary-area">
            <div class="text-block">
              <p class="summary-title">{{ summary.title }}</p>
              <p class="summary-content">{{ summary.content | trimText: 50 }}</p>
            </div>
            <div class="summary-thumb">
              <img *ngIf="summary.upload_picture?.thumbnail" src="{{ summary.upload_picture.thumbnail }}" />
            </div>
          </div>
        </div>
      </div>

      <div class="action">
        <ng-container *ngIf="layoutService.isTabletDownView.value; else actionPc">
          <!-- SP画面 -->
          <div class="action-sp">
            <p class="action-note">
              パソコンをお使いいただくことで、Classiポートフォリオの活動記録や添付ファイルなど、すべてのデータをダウンロードできます。
            </p>
          </div>
        </ng-container>
        <ng-template #actionPc>
          <!-- PC画面 -->
          <div class="action-pc">
            <button class="spen-mod-btn is-primary" [disabled]="progress !== null" (click)="onClickPortfolioDownload()">
              ポートフォリオをダウンロード
            </button>
            <a #portfolioDownloadLink class="hidden-link" download></a>
            <p class="action-note">※ご利用状況によりダウンロードには時間がかかることがあります。</p>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="graduation-content">
      <div class="graduation-heading">
        <p class="graduation-title">卒業メッセージ</p>
      </div>

      <ng-container *ngIf="listLoaded; else loadingIcon">
        <div class="data-info">
          <p class="page-info">{{ pages | paginateText }}</p>
        </div>

        <!-- SP画面 -->
        <div class="message-sp" *ngIf="layoutService.isTabletDownView.value; else graduationMessagePc">
          <div class="list-submission">
            <div
              class="list-item clickable-item"
              [class.last]="last"
              *ngFor="
                let graduation_message of graduationMessages
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      };
                let last = last
              "
              (click)="onClickGraduationMessage(graduation_message.id)"
            >
              <div class="info">
                <p class="truncate-text message-title">
                  <b>{{ graduation_message.title }}</b>
                </p>
                <p class="truncate-text creator-name">配信者：{{ graduation_message.create_user_name }}</p>
                <p class="text-smaller delivered-time">配信日時：{{ graduation_message.created_at }}</p>
                <p class="text-smaller truncate-text space-name">配信先：{{ graduation_message.space_name }}</p>
              </div>
              <i class="fa fa-chevron-right"></i>
            </div>

            <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
              <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
              </app-pagination-controls>
            </div>
          </div>
        </div>

        <!-- PC画面 -->
        <ng-template #graduationMessagePc>
          <div class="message-pc">
            <div class="list-submission">
              <div class="spen-mod-flex-table table-list-full has-link">
                <div class="flex-table-head">
                  <div class="flex-item col-size-4 is-first-col">タイトル</div>
                  <div class="flex-item col-size-3">配信者</div>
                  <div class="flex-item col-size-3">配信日時</div>
                  <div class="flex-item col-size-4">配信先</div>
                  <div class="flex-item redirect-cell"></div>
                </div>
                <a
                  class="flex-table-row clickable-item"
                  *ngFor="
                    let graduation_message of graduationMessages
                      | paginate
                        : {
                            itemsPerPage: this.pages.per_page,
                            currentPage: this.pages.current_page,
                            totalItems: this.pages.total_count,
                          }
                  "
                  (click)="onClickGraduationMessage(graduation_message.id)"
                >
                  <div class="flex-item col-size-4 is-first-col truncate-text">{{ graduation_message.title }}</div>
                  <div class="flex-item col-size-3 truncate-text">{{ graduation_message.create_user_name }}</div>
                  <div class="flex-item col-size-3">{{ graduation_message.created_at }}</div>
                  <div class="flex-item col-size-4 truncate-text">{{ graduation_message.space_name }}</div>
                  <div class="flex-item redirect-cell is-icon-item">
                    <i class="fa fa-chevron-right"></i>
                  </div>
                </a>
              </div>

              <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
                <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>

<app-activity-detail-modal [activityId]="activityId" (eventForBackAction)="eventForBackAction($event)" *ngIf="showActivityDetailModal">
</app-activity-detail-modal>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-progress-indicator
  *ngIf="progress !== null"
  [title]="'ダウンロードの準備中'"
  [status]="progressStatus"
  [progress]="progress"
  [canCancel]="progressCanCancel"
  (cancel)="onCancelPortfolioDownload()"
></app-progress-indicator>
