import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { SpaceService } from '../../space.service';
import { ClassService } from '@components/class/class.service';
import { AuthUserService } from '@services/auth-user.service';
import { LayoutService } from '@components/layout/layout.service';

import { Space } from '../../space';

import { pick, pickBy, isEqual, mapValues, omit, isNil } from 'lodash';
import { checkBlankString } from '@functions/check-string';

@Component({
  selector: 'app-spaces-list-teacher',
  templateUrl: './spaces-list-teacher.component.html',
  styleUrls: ['./spaces-list-teacher.component.scss'],
})
export class SpacesListTeacherComponent implements OnInit {
  spaces: Array<Space>;
  queryParams = {};
  form: UntypedFormGroup;
  formInited = false;
  schoolYears: Array<string>;

  dataLoaded = false;
  yearsLoaded = false;
  inProgressSort = false;
  isShowMore = false;
  isSearching = false;
  errorMsg: string;
  metaData = {
    current_page: 1,
    next_page: null,
    per_page: 1,
    prev_page: null,
    total_pages: 1,
    total_count: 1,
  };
  pageIndex = 1;
  private readonly allowedParamKeys = ['school_year', 'scope', 'keyword', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['keyword'];
  iconSort = {
    none: 'fa',
    loader: 'fa fa-spinner fa-spin',
    up: 'fa fa-caret-up',
    down: 'fa fa-caret-down',
  };
  sortStatus = {
    name: this.iconSort.none,
    created_at: this.iconSort.none,
    updated_at: this.iconSort.none,
  };
  sortKey = null;
  sortOrder = null;
  sortOrderData = ['asc', 'desc'];
  sortSelections = [
    { sort_value: 'name desc', text: 'スペース名（降順）' },
    { sort_value: 'name asc', text: 'スペース名（昇順）' },
    { sort_value: 'created_at desc', text: '作成日時（降順）' },
    { sort_value: 'created_at asc', text: '作成日時（昇順）' },
    { sort_value: 'updated_at desc', text: '更新日時（降順）' },
    { sort_value: 'updated_at asc', text: '更新日時（昇順）' },
  ];
  messageFilter = '';
  messageSort = '';

  constructor(
    private spaceService: SpaceService,
    private authUserService: AuthUserService,
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    public classService: ClassService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    await this.classService.getSchoolYears();
    this.schoolYears = this.classService.schoolYears.value;
    this.queryParams = pick(params, this.allowedParamKeys);
    this.queryParams['scope'] = params['scope'] || 'personal';
    this.queryParams['school_year'] = params['school_year'] || this.getCurrentYear();
    this.queryParams['page'] = +params['page'] || 1;
    this.sortKey = this.queryParams['sort_key'] || 'created_at';
    this.sortOrder = this.queryParams['sort_order'] || 'desc';
    this.messageSort = this.sortText();
    this.filterText(this.queryParams);
    this.initForm();
    this.getSpacesList(this.queryParams);
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  defaultFilterValue() {
    const filterValues = {
      school_year: this.getCurrentYear(),
      scope: 'personal',
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = this.sortSelections[2].sort_value;
    }

    return filterValues;
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return currentUser.schoolYear;
  }

  initForm() {
    this.form = this.formBuilder.group({
      school_year: this.queryParams['school_year'],
      scope: this.queryParams['scope'],
      keyword: this.queryParams['keyword'] || '',
      sort_value: this.getSortValue(),
    });
    this.formInited = true;
    this.isSearching = !this.isDefaultForm;
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.isShowMore = false;
    const params = pickBy(this.form.value);

    if (checkBlankString(params['keyword'])) {
      delete params['keyword'];
    } else {
      params['keyword'] = params['keyword'].trim();
      this.form.get('keyword').setValue(params['keyword']);
    }

    delete params['sort_value'];
    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
    params['request_timestamp'] = Date.now();
    this.filterText(params);
    this.navigate(params);
  }

  getSpacesList(params: Object) {
    if (!this.inProgressSort) {
      this.dataLoaded = false;
    }
    this.spaceService.getTeacherSpacesList(params).subscribe(
      (response) => {
        this.spaces = response.spaces;
        Object.assign(this.metaData, response.meta);
        this.refreshIconSort();
        this.dataLoaded = true;
      },
      (error) => {
        this.errorMsg = <any>error;
        this.spaces = [];
      },
    );
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  sortText() {
    const match = this.sortSelections.filter((item) => {
      return item.sort_value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match[0] && match[0].text;
  }

  changeBySortColumn(sortKey: string) {
    if (this.inProgressSort) {
      return false;
    }
    this.inProgressSort = true;
    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = this.iconSort.none;
      this.sortKey = sortKey;
      this.sortOrder = 'desc';
    }
    this.sortStatus[this.sortKey] = this.iconSort.loader;

    const params = { ...this.queryParams, page: 1, sort_key: this.sortKey, sort_order: this.sortOrder };
    this.navigate(params, 'merge');
  }

  refreshIconSort() {
    this.sortStatus = mapValues(this.sortStatus, () => this.iconSort.none);

    if (!this.dataLoaded) {
      // when the list was just loaded
      this.dataLoaded = true;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = this.iconSort[icon];
    } else if (this.inProgressSort) {
      // when the list was just sorted
      this.inProgressSort = false;
      const icon = this.sortOrder === 'asc' ? 'up' : 'down';
      this.sortStatus[this.sortKey] = this.iconSort[icon];
    } else {
      // when the list is being sorted
      this.inProgressSort = true;
      this.sortStatus[this.sortKey] = this.iconSort.loader;
    }
    this.form.controls['sort_value'].setValue(this.getSortValue());
  }

  getSortValue() {
    return this.sortKey ? `${this.sortKey} ${this.sortOrder}` : this.sortSelections[2].sort_value;
  }

  filterText(params: {}) {
    const filterText = [];

    params['school_year'] ? filterText.push(params['school_year'] + '年度') : filterText.push(this.getCurrentYear() + '年度');
    if (params['scope']) {
      switch (params['scope']) {
        case 'personal':
          filterText.push('担当している');
          break;
        case 'school':
          filterText.push('担当していない');
          break;
      }
    }
    if (params['keyword'] && params['keyword'].trim()) {
      filterText.push(params['keyword']);
    }

    this.messageFilter = filterText.join('/');
  }

  resetForm() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[2].sort_value.split(' ');
    }

    this.form.patchValue(this.defaultFilterValue());
    this.onSubmit();
  }

  get spacesCount() {
    return this.spaces.length;
  }

  get showPagination(): boolean {
    return this.metaData && this.metaData.total_pages > 1;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.form.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  get isEmptySearchResults(): boolean {
    return this.metaData.total_count === 0 && this.dataLoaded;
  }

  get isEmptyList(): boolean {
    return this.isEmptySearchResults && !this.isSearching;
  }

  get isLoadingData(): boolean {
    return !this.dataLoaded || this.inProgressSort;
  }

  onClickSpace(spaceId: number) {
    this.router.navigateByUrl(`spaces/teacher/${spaceId}`);
  }

  pageChanged(page: number) {
    const params = { ...this.queryParams, page: page };
    this.navigate(params, 'merge');
  }

  showMore() {
    this.isShowMore = !this.isShowMore;
  }

  toggleFilterOnMobileView() {
    return this.layoutService.isTabletDownView.value ? (this.isShowMore ? 'active' : 'inactive') : '';
  }
}
