import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '@services/router.service';
import { Observable } from 'rxjs';
import { GraduationMessageFormService } from './graduation-message-form.service';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';

@Component({
  selector: 'app-graduation-message-form',
  templateUrl: './graduation-message-form.component.html',
  styleUrls: ['./graduation-message-form.component.scss'],
  providers: [GraduationMessageFormService],
})
export class GraduationMessageFormComponent implements OnInit {
  @ViewChild(Step1Component) step1Component: Step1Component;
  @ViewChild(Step2Component) step2Component: Step2Component;
  @ViewChild(Step3Component) step3Component: Step3Component;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public creatorService: GraduationMessageFormService,
    private routerService: RouterService,
  ) {}

  ngOnInit(): void {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], {
      queryParams: { step: 1 },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
    this.route.queryParams.subscribe((params) => {
      window.scrollTo(0, 0);
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    switch (this.creatorService.currentStep) {
      case 1:
        return this.step1Component.canDeactivate();
      case 2:
        return this.step2Component.canDeactivate();
      case 3:
        return this.step3Component.canDeactivate();
    }
  }
}
