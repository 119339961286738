import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environments/environment';
import { padNumber } from './number-formatter';

export function ngbDateToString(date: NgbDateStruct): string {
  return `${date.year}/${padNumber(date.month, 2)}/${padNumber(date.day, 2)}`;
}

export function dateToNgbDate(date: Date): NgbDateStruct {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}

export function stringToNgbDate(dateString: string): NgbDateStruct {
  const date = new Date(dateString);
  return dateToNgbDate(date);
}

export function currentLocaleDate(): Date {
  const date = new Date();
  date.setTime(date.getTime() + (date.getTimezoneOffset() + environment.timeZone * 60) * 60000);
  return date;
}

// Input: 2019/06/24 12:00:00
// Output: 2019/06/24
export function timeStringToDateString(timeString: string): string {
  return timeString.match(/\d{4}\/\d{2}\/\d{2}/)[0];
}

export function insetDayOfWeekFor(datetimeString): string {
  const dayOfWeek = new Date(datetimeString).toLocaleString('ja-JP', { weekday: 'short' });
  return datetimeString.replace(' ', `（${dayOfWeek}）`);
}
