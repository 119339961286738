import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../../shared/components/shared.module';
import { PortfolioGraduationComponent } from './student/portfolio-graduation/portfolio-graduation.component';
import { GraduationMessageDetailComponent } from './student/graduation-message-detail/graduation-message-detail.component';
import { LinkPrevNextComponent } from './student/link-prev-next/link-prev-next.component';

@NgModule({
  declarations: [PortfolioGraduationComponent, GraduationMessageDetailComponent, LinkPrevNextComponent],
  imports: [CommonModule, SharedModule, SharedDirectivesModule, RouterModule, SharedPipesModule, NgxPaginationModule],
  providers: [],
})
export class GraduationMessageStudentModule {}
