import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-teacher-footer',
  templateUrl: './teacher-footer.component.html',
  styleUrls: ['./teacher-footer.component.scss'],
})
export class TeacherFooterComponent {
  readonly helpPageUrl = environment.helpPageUrl;
  readonly inquiryUrl = `${environment.platformUrl}/inquiry`;

  constructor() {}
}
