<div class="tea-graduation-message-form">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="2"></app-steps>
    </div>
  </div>

  <div class="form-horizontal">
    <div class="graduation-message-contents">
      <h2 class="group-label">配信する卒業メッセージ</h2>

      <div class="form-group result-group">
        <div class="row align-baseline">
          <div class="column-title">
            <label class="label">タイトル</label>
          </div>
          <div class="column-content">
            <p>{{ creatorService.graduationMessage.title }}</p>
          </div>
        </div>
      </div>

      <div class="form-group result-group">
        <div class="row">
          <div class="column-title">
            <label class="label">本文</label>
          </div>
          <div class="column-content">
            <p class="preserve-line-break" *ngIf="creatorService.graduationMessage.message">
              {{ creatorService.graduationMessage.message }}
            </p>
          </div>
        </div>
      </div>

      <div class="form-group result-group">
        <div class="row">
          <div class="column-title">
            <label class="label">添付ファイル</label>
          </div>
          <div class="column-content">
            <div class="file-preview" *ngIf="hasAttachedFiles">
              <app-file-uploader
                [uploader]="creatorService.fileUploader"
                [attachedFiles]="creatorService.attachedFiles"
                [removedAttachFiles]="creatorService.removedAttachFiles"
                [canEdit]="false"
              >
              </app-file-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="group-label">配信設定</h2>

    <div class="form-group">
      <div class="row">
        <div class="column-title space-title">
          <label class="label">配信先スペース</label>
        </div>
        <div class="column-content">
          <div class="space">
            <div
              class="block-color"
              [style.background-color]="creatorService.space.color_code"
              *ngIf="!layoutService.isTabletDownView.value"
            ></div>
            <p class="truncate-text">{{ creatorService.space.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="list-button-submit">
      <button type="button" (click)="goBack()" [disabled]="disableSubmitButton" class="spen-mod-btn">戻る</button>
      <button type="button" (click)="onSubmit()" [disabled]="disableSubmitButton" class="spen-mod-btn is-primary">配信完了</button>
    </div>
  </div>
</div>

<app-upload-files-progress
  *ngIf="creatorService.fileUploader.isUploading"
  [progress]="creatorService.fileUploader.progress"
  (cancelFilesEvent)="cancelUploadingFiles()"
>
</app-upload-files-progress>
