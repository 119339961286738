<div *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="spen-ly-modal" id="modal" data-cy="not-answer-student-modal">
    <div class="modal-dialog">
      <div class="modal-inner">
        <div class="modal-heading spen-modal-heading">
          <div class="close-modal">
            <a data-cy="close-modal" (click)="onCancel()"><i class="fa fa-times"></i></a>
          </div>
          <h2 class="heading-text">未提出者一覧</h2>
        </div>
        <div class="spen-modal-content">
          <div
            class="spen-detail-content"
            infinite-scroll
            infiniteScrollDistance="1"
            infiniteScrollThrottle="200"
            [scrollWindow]="false"
            [infiniteScrollDisabled]="!nextUrl"
            (scrolled)="getMoreStudents()"
          >
            <div *ngIf="firstStudentsLoaded; else loadingIcon">
              <p class="number-unsend" data-cy="student-count">未提出者：{{ students_count }}名</p>
              <div class="spen-mod-flex-table detail-modal-content">
                <div
                  data-cy="student-item"
                  *ngFor="let student of students"
                  [className]="'flex-table-row ' + getActivityRecordType(student)"
                >
                  <div class="flex-item detail-item">
                    <div class="info-modal">
                      <img data-cy="student-avator" src="{{ student.avatar }}" />
                      <div class="text-content">
                        <a class="spen-util-link truncate-text" data-cy="student-name" (click)="onCancel(student)">{{ student.name }}</a>
                        <p class="item-lv2" data-cy="student-class" *ngIf="student.class_name">
                          {{ student.class_name }}{{ student.attendance_number }}番
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="spen-mod-flex-loading" *ngIf="!moreStudentsLoaded">
                  <div class="loading-icon"></div>
                </div>
              </div>
              <div class="warning-modal-footer">
                <div class="muted-text">
                  選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。
                </div>
                <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pcView>
  <div class="spen-ly-modal" id="modal" data-cy="not-answer-student-modal">
    <div class="modal-dialog">
      <div class="modal-inner">
        <div class="modal-heading">
          <h2 class="heading-text">未提出者一覧</h2>
        </div>
        <div class="spen-modal-content">
          <div
            class="spen-detail-content"
            infinite-scroll
            infiniteScrollDistance="1"
            infiniteScrollThrottle="200"
            [scrollWindow]="false"
            [infiniteScrollDisabled]="!nextUrl"
            (scrolled)="getMoreStudents()"
          >
            <div *ngIf="firstStudentsLoaded; else loadingIcon">
              <p class="number-unsend" data-cy="student-count">未提出者：{{ students_count }}名</p>
              <div class="spen-mod-flex-table detail-modal-content">
                <div
                  *ngFor="let student of students"
                  [className]="'flex-table-row ' + getActivityRecordType(student)"
                  data-cy="student-item"
                >
                  <div class="flex-item detail-item">
                    <div class="info-modal">
                      <img data-cy="student-avator" src="{{ student.avatar }}" />
                      <div class="text text-content">
                        <a class="spen-util-link truncate-text" data-cy="student-name" (click)="onCancel(student)">{{ student.name }}</a>
                        <p class="item-lv2" data-cy="student-class" *ngIf="student.class_name">
                          {{ student.class_name }}{{ student.attendance_number }}番
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="spen-mod-flex-loading" *ngIf="!moreStudentsLoaded">
                  <div class="loading-icon"></div>
                </div>
              </div>
              <div class="warning-modal-footer">
                <div class="muted-text">
                  選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。
                </div>
                <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="spen-modal-footer">
          <a class="spen-mod-btn is-default js-close-modal-trigger" data-cy="close-modal" (click)="onCancel()">閉じる</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>
