<div class="row" (window:resize)="resize()" #upload_div>
  <div *ngFor="let file of attachedFiles" class="col-md-3 col-sm-4 col-xs-6">
    <div class="attach attach-image">
      <div *ngIf="!!file.thumbnail; else showFileIcon" class="attach-file">
        <img [src]="file.thumbnail" />
      </div>
      <ng-template #showFileIcon>
        <div class="file-format"><i [ngClass]="file.file_name | fileIconClass"></i></div>
      </ng-template>

      <a class="file-name">{{ file.file_name }}</a>
    </div>
  </div>

  <div *ngFor="let item of uploader.queue" class="col-md-3 col-sm-4 col-xs-6">
    <div class="attach attach-image">
      <div *ngIf="isShowPreview(item); else showFileIcon" class="attach-file">
        <img appImagePreview [image]="item?._file" />
      </div>
      <ng-template #showFileIcon>
        <div class="file-format"><i [ngClass]="item?.file?.name | fileIconClass"></i></div>
      </ng-template>

      <a class="file-name">
        {{ item?.file?.name }}
        <span *ngIf="item.isError">
          <i class="fa fa-exclamation-triangle" aria-hidden="true">&nbsp;Failed to upload</i>
        </span>
      </a>
    </div>
  </div>
</div>

<div *ngIf="canEdit">
  <div *ngIf="!isExistFileUploaded; else removeFile">
    <p class="empty-file">未選択</p>
    <label class="tea-add-file" for="file-upload">
      <span class="spen-util-link" [style.margin-bottom]="attachFilesErrMsg ? '0' : ''">
        {{ isExistFileUploaded ? '画像を変更する' : '画像を選択する' }}
      </span>
    </label>
    <input
      id="file-upload"
      class="input-file"
      type="file"
      (change)="onSelectFile($event)"
      [accept]="acceptTypes"
      ng2FileSelect
      [uploader]="uploader"
    />
  </div>
  <ng-template #removeFile>
    <label class="tea-add-file" for="file-upload">
      <span class="spen-util-link" data-cy="delete-cover-image" (click)="removeItems()">削除する</span>
    </label>
  </ng-template>
</div>
<div class="error-popup">
  <div *ngIf="attachFilesErrMsg" class="spen-mod-error-content">
    {{ attachFilesErrMsg }}
  </div>
</div>

<app-upload-files-progress *ngIf="showProgressbar" [progress]="uploader.progress" (cancelFilesEvent)="cancelUploadingFiles()">
</app-upload-files-progress>
