import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { environment } from '@environments/environment';
import { StoryCategory } from '@models/story-category';
import { map, catchError, finalize } from 'rxjs/operators';

interface StoryCategoryResponse {
  story_categories: Array<StoryCategory>;
}

@Injectable({
  providedIn: 'root',
})
export class StoryCategoriesService {
  categoriesSource = new BehaviorSubject<Array<StoryCategory>>([]);
  subCategories: Array<StoryCategory> = [];
  isRequested = false;

  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {
    if (this.categoriesSource.getValue().length === 0 && sessionStorage.getItem('story_categories')) {
      this.categoriesSource.next(JSON.parse(sessionStorage.getItem('story_categories')));
    }
  }

  getCategories(): Promise<Array<StoryCategory>> | Array<StoryCategory> {
    if (this.categoriesSource.value.length === 0 && !this.isRequested) {
      return this.doRequest().toPromise();
    }

    if (this.subCategories.length === 0) {
      this.categoriesSource.value.forEach((parent) => {
        this.subCategories = this.subCategories.concat(parent.sub_categories);
      });
    }

    return this.categoriesSource.getValue();
  }

  async getCategory(id: number): Promise<StoryCategory> {
    await this.getCategories();
    return this.findCategory(id, this.categoriesSource.value);
  }

  private findCategory(id: number, storyCategories: Array<StoryCategory>): StoryCategory {
    for (let i = 0; i < storyCategories.length; i++) {
      let category = storyCategories[i];

      if (category.id === id) {
        return category;
      }

      if (category.sub_categories) {
        category = category.sub_categories.find((subCategory) => subCategory.id === id);

        if (category) {
          return category;
        }
      }
    }
  }

  private doRequest(): Observable<Array<StoryCategory>> {
    const url = `${this.apiEndpointV2}/story_categories`;

    return this.http.get<StoryCategoryResponse>(url).pipe(
      map((response) => {
        sessionStorage.setItem('story_categories', JSON.stringify(response.story_categories));
        this.categoriesSource.next(response.story_categories);
        return response.story_categories;
      }),
      catchError((_error) => of(<StoryCategory[]>[])),
      finalize(() => (this.isRequested = true)),
    );
  }
}
