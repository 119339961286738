import { Component, OnDestroy, OnInit } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { TimelineService } from '@components/timeline/timeline.service';
import { ClassService } from '@components/class/class.service';
import { AuthUserService } from '@services/auth-user.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Action } from '@components/timeline/action';
import { Grade } from '@models/grade';
import { Class } from '@models/class';

import { timeStringToDateString } from '@functions/date-formatter';
import { pickBy, find, isEmpty, isEqual, omit, pick } from 'lodash';

@Component({
  selector: 'app-teacher-timeline',
  templateUrl: './teacher-timeline.component.html',
  styleUrls: ['./teacher-timeline.component.scss'],
})
export class TeacherTimelineComponent implements OnInit, OnDestroy {
  contentLoaded = false;
  isShowFilter = false;
  actionsLoading = false;
  isLoadingFilterData = false;
  currentPage = 1;

  timelineMap = {};
  form: UntypedFormGroup;
  meta = {};

  queryParams = {};
  schoolYears: Array<string>;
  grades: Array<Grade>;
  classes: Array<Class> = [];
  readonly saveConditionKeys = ['school_year', 'grade_id', 'class_id'];

  routerSubscriber = null;
  private filterText: string;

  constructor(
    public layoutService: LayoutService,
    private timelineService: TimelineService,
    private route: ActivatedRoute,
    private classService: ClassService,
    private authUserService: AuthUserService,
    private analytics4Service: GoogleAnalytics4Service,
    private searchConditionSavingService: SearchConditionSavingService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      school_year: null,
      grade_id: null,
      class_id: null,
    });

    this.routerSubscriber = this.route.queryParams.subscribe(async (params) => {
      await this.classService.getSchoolYears();
      await this.classService.getGrades();
      this.schoolYears = this.classService.schoolYears.value;
      this.grades = this.classService.grades.value;
      this.currentPage = 1;

      const url = this.router.url.split('?')[0];
      let newParams = params;

      if (Object.keys(params).length === 0) {
        newParams = this.searchConditionSavingService.getSearchConditions(url);
      } else {
        const saveConditions = pick(params, this.saveConditionKeys);
        this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
      }

      await this.initPageData(newParams);
    });
  }

  async initPageData(params) {
    this.filterText = '';
    this.timelineMap = {};
    this.queryParams = {};
    this.contentLoaded = false;
    this.isLoadingFilterData = true;
    this.form.disable();

    if (params['school_year'] && this.schoolYears.includes(params['school_year'])) {
      this.queryParams['school_year'] = params['school_year'];
    } else {
      this.queryParams['school_year'] = this.getCurrentYear();
    }
    this.filterText += `${this.queryParams['school_year']}年度/`;

    const currentGrade = find(this.grades, { id: +params['grade_id'] });
    if (currentGrade) {
      this.queryParams['grade_id'] = currentGrade['id'];
      this.filterText += `${currentGrade['school_stage_name'] + currentGrade['name']}/`;
    } else {
      this.queryParams['grade_id'] = null;
      this.filterText += `すべての学年/`;
    }

    if (isEmpty(this.classes)) {
      const response = await this.classService.getClassesListToFilter(this.queryParams).toPromise();
      this.classes = response['classes'] || [];
    }

    this.updateClassData(params);
    this.updateFormContent();
    this.getActions(Object.assign(pickBy(this.queryParams), { filter_default: this.isDefaulQueryParams }));
  }

  updateFormContent() {
    this.form = this.formBuilder.group({
      school_year: this.queryParams['school_year'],
      grade_id: this.queryParams['grade_id'],
      class_id: this.queryParams['class_id'],
    });
    this.isLoadingFilterData = false;
  }

  updateClassData(params) {
    const currentClass = find(this.classes, { id: +params['class_id'] });
    if (currentClass) {
      this.queryParams['class_id'] = currentClass['id'];
      this.filterText += currentClass['classroom_name'];
    } else {
      this.queryParams['class_id'] = null;
      this.filterText += 'すべての組';
    }
  }

  getActions(params = {}) {
    if (this.actionsLoading) {
      return;
    }
    this.actionsLoading = true;
    this.timelineService.getActionInSchool(params).subscribe(
      (response) => {
        this.updateTimelineMap(response.actions, this.timelineMap);
        this.meta = response.meta;
        this.contentLoaded = true;
        this.actionsLoading = false;
        this.form.enable();
      },
      (error) => {
        // TODO: add error handling.
      },
    );
  }

  updateTimelineMap(actions: Array<Action> = [], timelineMap = {}): void {
    actions.forEach((action) => {
      const createTime = timeStringToDateString(action.last_created_at);

      if (!timelineMap[createTime]) {
        timelineMap[createTime] = [];
      }

      timelineMap[createTime].push(action);
    });
  }

  changeShowFilterStatus() {
    this.isShowFilter = !this.isShowFilter;
  }

  onSelectChange($event) {
    this.form.controls['class_id'].setValue(null);
    this.form.disable();
    this.isLoadingFilterData = true;

    this.classService.getClassesListToFilter(this.form.value).subscribe(
      (response) => {
        this.form.enable();
        this.classes = response.classes || [];
        this.isLoadingFilterData = false;
      },
      (error) => {
        this.classes = [];
        this.isLoadingFilterData = false;
      },
    );
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return currentUser.schoolYear;
  }

  submitFilter() {
    window.scrollTo(0, 0);

    const params = pickBy(Object.assign({ request_timestamp: Date.now() }, this.form.value), (value) => +value);

    this.router.navigate(['.'], {
      queryParams: params,
      replaceUrl: true,
      relativeTo: this.route,
    });

    if (this.layoutService.isTabletDownView.value) {
      this.isShowFilter = false;
    }

    this.analytics4Service.sendEvent('', 'タイムライン', '検索する');
  }

  clearFilter() {
    window.scrollTo(0, 0);
    this.form.reset({ school_year: this.getCurrentYear() });
    if (this.layoutService.isTabletDownView.value) {
      this.isShowFilter = false;
    }
    this.router.navigate(['.'], {
      queryParams: { school_year: this.getCurrentYear(), request_timestamp: Date.now() },
      replaceUrl: true,
      relativeTo: this.route,
    });
  }

  loadMore() {
    if (this.actionsLoading) {
      return;
    }

    const queryParams = Object.assign(pickBy(this.queryParams), omit(this.meta, 'next_action_date'), { current_page: ++this.currentPage });
    this.getActions(queryParams);
  }

  get timelineDates(): string[] {
    return Object.keys(this.timelineMap);
  }

  get isLoadingData(): boolean {
    return this.actionsLoading || !this.contentLoaded || this.isLoadingFilterData;
  }

  get isDefaultForm(): boolean {
    return isEqual(pickBy(this.form.value), { school_year: this.getCurrentYear() });
  }

  get isDefaulQueryParams(): boolean {
    return isEqual(pickBy(this.queryParams), { school_year: this.getCurrentYear() });
  }

  get haveTimelineInDB(): boolean {
    return this.meta['have_record_in_table'] || !isEmpty(this.timelineMap);
  }

  ngOnDestroy(): void {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }
}
