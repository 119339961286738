<div class="tea-create-pickup" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="formActivityPickupService.steps" [currentStep]="1"></app-steps>
    </div>
  </div>

  <div class="form-group space-input step-2">
    <div class="form-filter">
      <div class="label-title step2-title">
        <label class="label">公開先スペース</label>
      </div>
      <ng-container *ngIf="formActivityPickupService.space; else multiSpace">
        <div class="space-info-confirm">
          <div class="block-color" [style.background-color]="formActivityPickupService.space.color_code"></div>
          <span class="truncate-text">{{ formActivityPickupService.space.name }}</span>
        </div>
      </ng-container>
      <ng-template #multiSpace>
        <div class="space-content">
          <div class="space" *ngFor="let space of formActivityPickupService.spaces">
            <div class="block-color" [style.background-color]="space.color_code" *ngIf="!layoutService.isTabletDownView.value"></div>
            <p class="truncate-text">{{ space.name }}</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="form-group form-input step-2">
    <div class="form-filter">
      <div class="label-title">
        <label class="label">ピックアップ表示期間</label>
      </div>
      <div class="form-filter">
        <p>{{ formatedDates() }}</p>
      </div>
    </div>
  </div>

  <div class="form-group form-input input-radio">
    <div class="form-filter">
      <div class="label-title">
        <label class="label">匿名設定</label>
      </div>
      <div class="form-filter">
        <p *ngIf="!formActivityPickupService.activityPickup.is_anonymous; else anonymous">匿名にしない</p>
        <ng-template #anonymous>
          <div class="form-filter">
            <p>匿名にする</p>
          </div>
          <div class="form-filter margin-note">
            <p>※生徒が自主公開している場合は匿名になりません</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="block-standard">
    <div class="form-filter">
      <div class="label-title">
        <label class="label">説明</label>
      </div>
      <div class="label-title step-2">
        <p class="preserve-line-break">{{ enteredReason ? formActivityPickupService.activityPickup.reason : '未入力' }}</p>
      </div>
    </div>
  </div>

  <div class="list-button-submit">
    <button type="button" class="spen-mod-btn" (click)="goBack()" [disabled]="isSavingPickup">戻る</button>
    <button type="button" class="spen-mod-btn is-primary" (click)="onSubmit()" [disabled]="isSavingPickup">
      {{ isEditPickup() ? '変更完了' : '設定完了' }}
    </button>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
