<div class="spen-util-inner page-header">
  <h1 class="page-title">{{ title || 'ポートフォリオ' }}</h1>
  <a class="header-content classi-logo" href="{{ classiTopUrl }}">
    <img src="https://cdn.classi.jp/img/common/logo-classi.png" />
  </a>
  <a class="header-content user-information">
    <img class="user-icon" [src]="currentUser.avatar" *ngIf="!!currentUser.avatar; else defaultImage" />
    <ng-template #defaultImage>
      <img class="user-icon" src="/assets/images/user-icon.png" />
    </ng-template>
    <span class="truncate-text">
      {{ currentUser.name }}
    </span>
  </a>
  <div class="header-content dummy-content"></div>
  <a class="header-content" href="{{ helpPageUrl }}" target="_blank">
    <i class="fa fa-question"></i>
    <span class="content-text">ヘルプ</span>
  </a>
  <div class="header-content" id="js-show-menu-list-trigger" (click)="showGlobalMenu()">
    <i class="fa fa-th menu-icon"></i>
    <span class="content-text">メニュー</span>
  </div>
</div>
