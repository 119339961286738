import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { StudentService } from '@components/student/student.service';
import { User } from '@models/user';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit, OnDestroy {
  readonly tabOptions = {
    grade_1: '1年',
    grade_2: '2年',
    grade_3: '3年',
    summary: 'まとめ',
  };
  currentTab = 'grade_1';
  student: User;
  dataLoaded: boolean;
  private routerSubscriber = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
  ) {}

  ngOnInit(): void {
    this.routerSubscriber.add(
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(this.tabOptions).includes(params['tab'])) {
          this.currentTab = params['tab'];
        } else {
          this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
        }
      }),
    );

    this.routerSubscriber.add(
      this.route.params.subscribe(() => {
        this.dataLoaded = false;
        this.studentService.getStudentInfo(this.route.snapshot.params['student_id']).subscribe((response) => {
          this.student = response;
          this.dataLoaded = true;
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.routerSubscriber.unsubscribe();
  }
}
