import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastService } from '@services/toast/toast.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { LayoutService } from '@components/layout/layout.service';
import { FormActivityPickupService } from '../form-activity-pickup.service';
import { ActivityPickupService } from '@components/activity-pickup/activity-pickup.service';
import { ErrorService } from '@services/error.service';

import { DatepickerParserFormatter } from '@components/datepicker/datepicker-parser-formatter';
import { checkPresentString } from '@functions/check-string';

@Component({
  selector: 'app-form-activity-pickup-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../form-activity-pickup.component.scss'],
  providers: [DatepickerParserFormatter],
})
export class Step2Component implements OnInit, OnDestroy {
  dataLoaded = false;
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };
  saveStatus = 'none';
  activity_record_id: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    private toastService: ToastService,
    private datePickerParser: DatepickerParserFormatter,
    private formActivityPickupService: FormActivityPickupService,
    private activityPickupService: ActivityPickupService,
    private errorService: ErrorService,
    public layoutService: LayoutService,
  ) {
    this.activity_record_id = this.route.snapshot.queryParams.activity_record_id;
  }

  ngOnInit() {
    const currentStep = this.formActivityPickupService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.dataLoaded = true;
    this.routerService.registerBackConfirm(this.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate() {
    if (!this.isCompleted) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  get isCompleted() {
    return this.saveStatus === 'completed';
  }

  get isSavingPickup() {
    return this.saveStatus !== 'none';
  }

  goBack() {
    this.formActivityPickupService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  get enteredReason(): boolean {
    return checkPresentString(this.formActivityPickupService.activityPickup.reason);
  }

  formatedDates(): string {
    const formatedEndDate = this.datePickerParser.format(this.formActivityPickupService.endTime);
    return `${formatedEndDate} まで`;
  }

  onSubmit() {
    if (!!this.formActivityPickupService.editActivityPickupId) {
      this.updatePickup();
    } else {
      this.createPickup();
    }
  }

  createPickup() {
    this.saveStatus = 'submitting';
    this.activityPickupService.createPickup(this.activity_record_id, this.formActivityPickupService.activityPickup).subscribe(
      (response) => {
        this.saveStatus = 'completed';
        this.routerService.resetDataConfirm();
        this.routerService.goBackSpecific('/activity-records/teacher/' + this.activity_record_id + '?tab=pickup');
        this.toastService.showToast('設定しました');
      },
      (error) => {
        this.errorService.addError(error);
        this.saveStatus = 'none';
      },
    );
  }

  isEditPickup(): boolean {
    return !!this.formActivityPickupService.editActivityPickupId;
  }

  updatePickup() {
    this.saveStatus = 'submitting';
    this.activityPickupService
      .updatePickup(this.formActivityPickupService.editActivityPickupId, this.formActivityPickupService.activityPickup)
      .subscribe(
        (response) => {
          this.saveStatus = 'completed';
          this.routerService.resetDataConfirm();
          this.routerService.goBackSpecific('/activity-records/teacher/' + this.formActivityPickupService.activityRecordId + '?tab=pickup');
          this.toastService.showToast('変更しました');
        },
        (error) => {
          this.errorService.addError(error);
          this.saveStatus = 'none';
        },
      );
  }
}
