<div class="spen-ly-modal tea-filter-space" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <h2 class="heading-text">{{ title }}</h2>
      </div>

      <div class="modal-content space-has-footer">
        <div class="bold-word-cloud-text-content">
          <div class="bold-word-cloud-text">
            <p>ワードクラウド</p>
          </div>
        </div>

        <div class="word-cloud-description-text-content">
          <div class="word-cloud-description-text">
            <p>文章中の特徴的な単語 (出現頻度も加味) を複数選び出し表示しています。特徴的な単語ほど大きく表示されます。</p>
          </div>
        </div>

        <div class="word-classification-description-content">
          <div class="blue-square"></div>
          <div class="classification-word"><p>名詞</p></div>
          <div class="red-square"></div>
          <div class="classification-word"><p>動詞</p></div>
          <div class="green-square"></div>
          <div class="classification-word"><p>形容詞</p></div>
          <div class="gray-square"></div>
          <div class="classification-word"><p>感動詞</p></div>
        </div>

        <div class="word-cloud-content">
          <iframe [src]="imageUrl" width="460," height="261"></iframe>
        </div>
      </div>

      <div class="modal-footer">
        <div class="modal-actions is-right-actions space-modal-action">
          <a class="spen-mod-btn is-default js-close-modal-trigger" (click)="onClose()">閉じる</a>
        </div>
      </div>
    </div>
  </div>
</div>
