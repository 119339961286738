import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { SpaceService } from './../../space.service';
import { Space } from './../../space';
import { Work } from '@components/work/work';
import { RouterService } from '@services/router.service';
import { MultipleFilterService } from '@services/filter/multiple-filter.service';
import { PublicActivityRecord } from '@components/activity-record/public-activity-record';
import { User } from '@models/user';
import { AuthUserService } from '@services/auth-user.service';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { isNil, omit, pickBy } from 'lodash';

@Component({
  selector: 'app-student-space-detail',
  templateUrl: './student-space-detail.component.html',
  styleUrls: ['./student-space-detail.component.scss'],
  providers: [MultipleFilterService],
})
export class StudentSpaceDetailComponent implements OnInit {
  spaceDetail: Space;
  works: Array<Work>;
  activityRecords: Array<PublicActivityRecord>;
  errMsg: string;
  spaceLoaded = false;
  currentUser: User;
  pages = {
    per_page: 1,
    current_page: 1,
    total_pages: 1,
    total_count: 0,
    start_item_index: 0,
    end_item_index: 0,
  };
  private readonly disallowedUrlParamKeys = ['keyword'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spaceService: SpaceService,
    private routerService: RouterService,
    public filterService: MultipleFilterService,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    const queryParams = Object.assign({}, params);
    queryParams['page'] = +params['page'] || 1;
    await this.filterService.setFilter(queryParams);
    const spaceId = +this.route.snapshot.paramMap.get('id');
    this.loadSpaceDetail(spaceId, queryParams);
    this.currentUser = this.authUserService.retrieve();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  loadSpaceDetail(spaceId, params = {}) {
    this.spaceLoaded = false;
    this.spaceService.getStudentSpace(spaceId, params).subscribe(
      (response) => {
        this.spaceDetail = response.space;
        this.works = response.works;
        this.activityRecords = response.activity_records;
        Object.assign(this.pages, response.pages);
        this.spaceLoaded = true;
      },
      (error) => (this.errMsg = <any>error),
    );
  }

  dataLoaded(): boolean {
    return this.spaceLoaded;
  }

  pageChanged(page: number) {
    const params = { ...this.filterService.getFilterData(), page: page };
    this.navigate(params, 'merge');
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  onBack() {
    this.routerService.goBack();
  }

  changeFilter(params: {}) {
    this.navigate(params);
  }

  removeFilter() {
    this.filterService.removeFilter();
    this.navigate({});
  }

  get activityRecordsCount() {
    return this.pages.total_count;
  }

  onClickActitityDetail(activityRecord: ActivityRecord) {
    const url = this.getActivityDetailLink(activityRecord);
    this.router.navigateByUrl(url);
  }

  getActivityDetailLink(activityRecord: ActivityRecord) {
    if (this.currentUser.id === activityRecord.create_user_id) {
      return `/activity-records/student/${activityRecord.id}`;
    }
    return `/spaces/student/${this.spaceDetail.id}/activity_records/${activityRecord.id}`;
  }
}
