import { Directive, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';

@Directive({
  selector: '[appScrollToFirstInvalid]',
})
export class ScrollToFirstInvalidDirective {
  headerOffsetHeight = this.layoutService.HEADER_OFFSET_HEIGHT;

  constructor(
    private element: ElementRef,
    public layoutService: LayoutService,
    private cdRef: ChangeDetectorRef,
  ) {}

  @HostListener('submit')
  onSubmit() {
    this.cdRef.detectChanges();

    const errorContent = this.element.nativeElement.querySelector('.spen-mod-error-content');

    if (errorContent) {
      const invalidElement = this.element.nativeElement.querySelector('.ng-invalid:not(div)');
      const errorContentOffsetTop = window.pageYOffset + errorContent.getBoundingClientRect().top;

      // scroll to center of page
      window.scrollTo(0, errorContentOffsetTop - (this.headerOffsetHeight + window.innerHeight) / 2);

      if (invalidElement) {
        invalidElement.focus();
      }
    }
  }
}
