<div class="teacher-story-detail" *ngIf="!!this.dataLoaded; else loadingIcon">
  <div class="stories-count">作成数：{{ numberOfStories }}/{{ MAX_NUMBER_STORIES }}</div>

  <div class="stories-list">
    <div class="category" *ngFor="let category of this.categoriesWithStory">
      <div class="category-heading">
        <div class="icon">
          <img src="{{ category.icon }}" alt="" />
        </div>
        <h3 class="category-name">
          {{ category.name }}
        </h3>
      </div>
      <div class="sub-categories">
        <div class="sub-category" *ngFor="let subCategory of category.sub_categories">
          <div class="sub-category-detail" [id]="subCategory.story?.id">
            <div class="sub-category-name" [class.no-content]="!subCategory.story">
              {{ subCategory.name }}
            </div>
            <div class="story-time">
              <p>作成日時： {{ !!subCategory.story ? subCategory.story.created_at : '-' }}</p>
              <p>更新日時： {{ !!subCategory.story ? this.getUpdatedAt(subCategory.story) : '-' }}</p>
            </div>
            <span class="story-icon" *ngIf="subCategory.story && layoutService.isTabletDownView.value">
              <i
                [ngClass]="isShowStory[subCategory.story.id] ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
                (click)="onChangeShowStory(subCategory.story.id)"
              ></i>
            </span>
          </div>

          <div
            class="story-detail"
            *ngIf="subCategory.story && (isShowStory[subCategory.story.id] || !layoutService.isTabletDownView.value)"
          >
            <div [class.story-vision]="subCategory.story.archive || subCategory.story.upload_files.length" *ngIf="subCategory.story.vision">
              <p class="story-title">目標</p>
              <div class="preserve-line-break break-word story-content">{{ subCategory.story.vision }}</div>
            </div>
            <ng-container *ngIf="subCategory.story.archive || subCategory.story.upload_files.length">
              <p class="story-title">やったこと・考えたこと</p>
              <div class="preserve-line-break break-word story-content" *ngIf="subCategory.story.archive">
                {{ subCategory.story.archive }}
              </div>
              <div class="story-file-views" *ngIf="subCategory.story.upload_files">
                <app-story-files-view [files]="subCategory.story.upload_files"></app-story-files-view>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <h2 class="teacher-comment" id="comments">先生からのひとこと</h2>
    <app-story-comment-list-teacher [storyUserId]="studentId" [grade]="grade"></app-story-comment-list-teacher>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
