<div class="spen-ly-modal filter-multiple" id="modal" data-cy="filter-by-multiple">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <span class="top-btn-close" (click)="closeFilter()" *ngIf="authUserService.isStudent()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <h2 class="heading-text">検索</h2>
      </div>

      <div class="modal-content">
        <div class="box-filter" *ngIf="dataLoaded(); else loadingIcon">
          <form [formGroup]="form">
            <div class="row" [class.no-border]="authUserService.isStudent()" *ngIf="showManaVisionOption">
              <div class="col-xs-12">
                <label class="spen-mod-checkbox">
                  <input class="checkbox" type="checkbox" formControlName="include_mana_vision" />
                  <i class="fa"></i>
                  <span class="text">マナビジョンを含める</span>
                </label>
              </div>
            </div>

            <div class="row" [class.no-border]="authUserService.isStudent()" *ngIf="!includingManaVisions">
              <div class="col-md-3 col-xs-12 align-center" *ngIf="authUserService.isTeacher()">
                <p class="label-input">キーワード</p>
              </div>
              <div class="col-md-9 col-xs-12" [class.col-md-offset-3]="authUserService.isStudent()">
                <input class="spen-mod-input-text" placeholder="キーワードを入力してください" formControlName="keyword" />
              </div>
            </div>

            <div class="row block-category" [class.no-border]="authUserService.isStudent()" [class.last-row]="includingManaVisions">
              <div class="col-md-3 col-xs-12 align-center">
                <p class="label-input">カテゴリー</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <app-categories-selection [categories]="categories" formControlName="category_id"> </app-categories-selection>
              </div>
            </div>

            <ng-container *ngIf="!includingManaVisions">
              <div class="row block-activity-type" [class.no-border]="authUserService.isStudent()">
                <div class="col-md-3 col-xs-12 align-center">
                  <p class="label-input">種別</p>
                </div>
                <div class="col-md-9 col-xs-12">
                  <label class="spen-mod-select">
                    <select class="select" formControlName="activity_type">
                      <option class="select-option" *ngFor="let activityType of ACTIVITY_TYPES" [ngValue]="activityType.value">
                        {{ activityType.text }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
              </div>

              <div class="block-marks" [class.no-border]="authUserService.isStudent()">
                <app-marks-selector [selectedIds]="selectedMarkIds" [centeredLabel]="true" [marks]="marks"></app-marks-selector>
              </div>

              <div class="row" [class.no-border]="authUserService.isStudent()">
                <div class="col-md-3 col-xs-12 align-center">
                  <p class="label-input">コメント状況</p>
                </div>
                <div class="col-md-9 col-xs-12">
                  <label class="spen-mod-select">
                    <select class="select" formControlName="comment_status" (change)="selectCommentStatus(form.value.comment_status)">
                      <option class="select-option" *ngFor="let commentStatus of COMMENT_STATUSES" [ngValue]="commentStatus.value">
                        {{ commentStatus.text }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                  <label class="spen-mod-select select-child">
                    <select class="select" formControlName="sub_comment_status">
                      <option
                        class="select-option"
                        *ngFor="let subCommentStatus of SUB_COMMENT_STATUSES"
                        [ngValue]="subCommentStatus.value"
                      >
                        {{ subCommentStatus.text }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
              </div>

              <div class="row last-row" [class.no-border]="authUserService.isStudent()">
                <div class="col-md-3 col-xs-12 align-center">
                  <p class="label-input">公開状況</p>
                </div>
                <div class="col-md-9 col-xs-12">
                  <label class="spen-mod-select">
                    <select class="select" formControlName="public_status" (change)="selectPublicStatus(form.value.public_status)">
                      <option class="select-option" *ngFor="let publicStatus of PUBLIC_STATUSES" [ngValue]="publicStatus.value">
                        {{ publicStatus.text }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                  <label class="spen-mod-select select-child">
                    <select class="select" formControlName="sub_public_status">
                      <option class="select-option" *ngFor="let subPublicStatus of SUB_PUBLIC_STATUSES" [ngValue]="subPublicStatus.value">
                        {{ subPublicStatus.text }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
        <ng-template #loadingIcon>
          <div class="modal-content">
            <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
          </div>
        </ng-template>
      </div>

      <div class="modal-footer">
        <div class="modal-actions is-right-actions">
          <a class="cancel spen-mod-btn is-default js-close-modal-trigger" data-cy="close-filter" (click)="closeFilter()">キャンセル</a>
          <a class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()">検索する</a>
        </div>
      </div>
    </div>
  </div>
</div>
