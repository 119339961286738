import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Space } from './space';
import { SpacesResponse } from './spaces-response';
import { StudentSpaceResponse } from './student-space-response';
import { TeacherSpaceResponse } from './teacher-space-response';
import { TeacherSpacePublicActivitiesResponse } from './space-detail/teacher-space-detail/activity-records/teacher-space-public-activities-response';
import { environment } from './../../../environments/environment';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { CrossAssessmentDialogResponse } from './space-detail/teacher-space-detail/cross-review-dialog/cross-assessment-dialog-response';
import { TeacherSpaceAlbumsResponse } from './space-detail/teacher-space-detail/albums/teacher-space-albums-response';
import { TeacherWorksResponse } from '@components/space/space-detail/teacher-space-detail/works/teacher-works-response';
import { ActionsResponse } from '@components/timeline/actions-response';
import { SpaceParticipantsResponse } from './space-participants-response';
import { TeacherGraduationMessageResponse } from '@components/graduation-message/teacher/graduation-message-list/teacher-graduation-message-response';

@Injectable()
export class SpaceService {
  apiEndpoint = environment.apiEndpoint;
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getTeacherSpacesList(params: {}): Observable<SpacesResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/search`;
    return this.http.post<SpacesResponse>(url, params);
  }

  getStudentSpacesList(params: {}): Observable<SpacesResponse> {
    const url = `${this.apiEndpoint}/student/spaces`;
    return this.http.get<SpacesResponse>(url, { params: params });
  }

  createSpace(space: Space): Observable<Space> {
    return this.http.post<Space>(`${this.apiEndpointV2}/teacher/spaces`, { space: space });
  }

  updateSpace(space: Space): Observable<Space> {
    return this.http.put<Space>(`${this.apiEndpointV2}/teacher/spaces/${space.id}`, { space: space });
  }

  getTeacherSpace(id: number): Observable<TeacherSpaceResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${id}`;
    return this.http.get<TeacherSpaceResponse>(url);
  }

  getTeacherActivitiesOfSpace(spaceId: number, params = {}): Observable<TeacherSpacePublicActivitiesResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/public_activity_records/search`;
    return this.http.post<TeacherSpacePublicActivitiesResponse>(url, params);
  }

  getTeacherWorksOfSpace(spaceId: number, params = {}): Observable<TeacherWorksResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/works/search`;
    return this.http.post<TeacherWorksResponse>(url, params);
  }

  getStudentSpace(id: number, params = {}): Observable<StudentSpaceResponse> {
    const url = `${this.apiEndpoint}/spaces/${id}/public_activity_records/search`;
    return this.http.post<StudentSpaceResponse>(url, params);
  }

  getTeacherSpaceEdit(id: number): Observable<TeacherSpaceResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${id}/edit`;
    return this.http.get<TeacherSpaceResponse>(url);
  }

  deleteSpace(id: number): Observable<TeacherSpaceResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${id}`;
    return this.http.delete<TeacherSpaceResponse>(url);
  }

  getPublicActivityRecord(spaceId: number, activityRecordId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/student/spaces/${spaceId}/activity_records/${activityRecordId}`;
    return this.http.get<ActivityRecord>(url);
  }

  getPublishDialog(spaceId: number, params = {}): Observable<CrossAssessmentDialogResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/public_activity_records/analytic_activity_public`;
    return this.http.get<CrossAssessmentDialogResponse>(url, { params: params });
  }

  getActivityViewDialog(spaceId: number, params = {}): Observable<CrossAssessmentDialogResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/public_activity_records/analytic_activity_view`;
    return this.http.get<CrossAssessmentDialogResponse>(url, { params: params });
  }

  getCrossAssessmentStatusDialog(spaceId: number, params = {}): Observable<CrossAssessmentDialogResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/public_activity_records/analytic_cross_assessment`;
    return this.http.get<CrossAssessmentDialogResponse>(url, { params: params });
  }

  getMoreDialog(nextUrl: string): Observable<CrossAssessmentDialogResponse> {
    return this.http.get<CrossAssessmentDialogResponse>(nextUrl);
  }

  getTeacherAlbumsOfSpace(spaceId: number, params = {}): Observable<TeacherSpaceAlbumsResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/albums/search`;
    return this.http.post<TeacherSpaceAlbumsResponse>(url, params);
  }

  getSpaceTimeline(spaceId: number, params = {}): Observable<ActionsResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/actions`;
    return this.http.get<ActionsResponse>(url, { params: params });
  }

  getSpaceParticipantsList(spaceId: number, params: {}): Observable<SpaceParticipantsResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/users`;
    return this.http.get<SpaceParticipantsResponse>(url, { params: params });
  }

  getTeacherGraduationMessagesOfSpace(spaceId: number, params = {}): Observable<TeacherGraduationMessageResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/graduation_messages/search`;
    return this.http.post<TeacherGraduationMessageResponse>(url, params);
  }
}
