import { AfterViewChecked, Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { Album } from '@models/album';

import { AlbumService } from '@components/album/album.service';
import { AlbumCreatorService } from '../album-creator.service';
import { AuthUserService } from '@services/auth-user.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { ToastService } from '@services/toast/toast.service';

import { CoverImageUploaderComponent } from '../cover-image-uploader/cover-image-uploader.component';
import { validateForm } from '@functions/validate-form';

@Component({
  selector: 'app-form-album-teacher-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['../form-album-teacher.component.scss'],
})
export class Step1Component implements OnInit, AfterViewChecked, OnDestroy {
  form: UntypedFormGroup;
  spaceLoaded = false;
  isFocused = false;
  isDeleting = false;
  errorMsg: string;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    title: {
      required: '配信アルバム名を入力してください。',
      maxlength: '配信アルバム名は50文字以内で入力してください。',
    },
    comment: {
      maxlength: '説明は500文字以内で入力してください。',
    },
  };
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };
  @ViewChild(CoverImageUploaderComponent) coverImageUploader: CoverImageUploaderComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private albumService: AlbumService,
    private authUserService: AuthUserService,
    private creatorService: AlbumCreatorService,
    private toastService: ToastService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const previousStep = this.creatorService.previousStep;
    if (previousStep === 2) {
      this.createAlbumForm(this.creatorService.album, true);
      this.spaceLoaded = true;
    } else {
      const albumId = this.route.snapshot.params.id;
      if (!!albumId) {
        this.creatorService.albumId = +albumId;
        this.loadAlbumDetailForEdit(+albumId);
      } else {
        const spaceId = this.route.snapshot.queryParams.space_id;
        const fromAlbumId = this.route.snapshot.queryParams.album_id;
        if (spaceId) {
          this.loadSpaceDetail(+spaceId);
        }
        this.createAlbumForm(new Album());
        if (fromAlbumId) {
          this.creatorService.isCopy = true;
          this.loadAlbumDetailForClone(+fromAlbumId);
        }
        this.spaceLoaded = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.spaceLoaded && !this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDeleting && this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  loadAlbumDetailForEdit(albumId: number) {
    this.albumService.getTeacherAlbumEdit(albumId).subscribe(
      (response) => {
        this.creatorService.album = response;
        if (this.creatorService.album.image) {
          this.creatorService.attachedFiles = [this.creatorService.album.image];
        }
        if (this.creatorService.album.can_edit) {
          this.loadSpaceDetail(this.creatorService.album.space_id);
        } else {
          this.router.navigateByUrl('/403');
        }
        this.createAlbumForm(this.creatorService.album);
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  loadAlbumDetailForClone(albumId: number) {
    this.albumService.getTeacherAlbum(albumId).subscribe(
      (response) => {
        this.creatorService.album = response;
        if (this.creatorService.album.image) {
          this.creatorService.attachedFiles = [this.creatorService.album.image];
        }
        this.createAlbumForm(this.creatorService.album);
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  loadSpaceDetail(spaceId: number) {
    this.albumService.getSpaceDetail(spaceId).subscribe(
      (response) => {
        this.creatorService.space = response;
        if (!this.creatorService.space.managed_by_user) {
          this.router.navigateByUrl('/403');
        }
        this.spaceLoaded = true;
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  createAlbumForm(album: Album, dirty = false) {
    this.form = this.formBuilder.group({
      title: [album.title || '', [Validators.required, Validators.maxLength(50)]],
      comment: [album.comment || '', [Validators.maxLength(500)]],
      cover_image: [!!this.creatorService.attachedFiles.length || !!this.creatorService.fileUploader.queue.length],
      image_attributes: [null],
      can_destroy: [album.can_destroy],
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe(() => {
      this.routerService.registerBackConfirm(this.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.creatorService.album = this.form.value;
      this.creatorService.attachedFiles = this.coverImageUploader.attachedFiles;
      this.creatorService.fileUploader = this.coverImageUploader.uploader;
      this.creatorService.removedAttachFiles = this.coverImageUploader.removedAttachFiles;
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  deleteAlbum() {
    this.isDeleting = true;
    this.confirmDialogService
      .showConfirm({
        title: '確認',
        content: '他のユーザの画面からも削除されます。一度削除したアルバムを復元することはできません。<br> 本当に削除しますか？',
        acceptButton: '削除する',
        cancelButton: 'キャンセル',
        isDanger: true,
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.albumService.deleteTeacherAlbum(this.creatorService.albumId).subscribe(
            () => {
              this.router.navigateByUrl('/albums/teacher/delivery');
              this.toastService.showToast('削除しました');
            },
            (error) => {
              this.isDeleting = false;
              this.errorMsg = <any>error;
            },
          );
        } else {
          this.isDeleting = false;
        }
      });
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    }
  }

  get isEditAlbum(): boolean {
    return !!this.creatorService.albumId;
  }

  goBack() {
    this.routerService.goBack(this.backConfirmMessages);
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep2State();
  }
}
