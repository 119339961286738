import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../../../environments/environment';
import { S3CredentialService } from '@services/s3-credential.service';
import { FileUploader, FileItem } from 'ng2-file-upload';

@Injectable()
export class UploadFilesService {
  apiEndpoint = environment.apiEndpoint;
  serverResponseErrMsg: string;
  eventDone: EventEmitter<boolean>;

  constructor(
    private http: HttpClient,
    private s3CredentialService: S3CredentialService,
  ) {}

  deleteCanceledFiles(fileNames: Array<string>): Observable<any> {
    const url = `${this.apiEndpoint}/upload_files/delete_canceled_files`;
    return this.http.delete(url, { params: { 'file_names[]': fileNames } });
  }

  cancelUploadedFiles(uploader: FileUploader) {
    this.eventDone.complete();
    uploader.cancelAll();
    const uploadedFileNames = uploader.queue
      .filter((fileItem) => fileItem.isUploaded && fileItem.isSuccess)
      .map((fileItem) => fileItem['uploadFileParams']['key']);
    this.resetStatusUploader(uploader);
    if (uploadedFileNames.length > 0) {
      this.deleteCanceledFiles(uploadedFileNames).subscribe(
        (response) => {},
        (error) => (this.serverResponseErrMsg = <any>error),
      );
    }
  }

  uploadAllFile(uploader: FileUploader): EventEmitter<boolean> | boolean {
    this.eventDone = new EventEmitter();
    const uploadFileItems = this.prepareFilesToUpload(uploader);
    if (uploadFileItems.length > 0) {
      this.initEventUploader(uploader);
      this.s3CredentialService.getPolicyParams(uploadFileItems).subscribe(
        (response) => {
          const uploadFilesParams = response.upload_links;
          uploadFileItems.forEach((fileItem, index) => {
            fileItem['uuid'] = uploadFilesParams[index].uuid;
            fileItem.url = uploadFilesParams[index].endpoint_url;
            fileItem['uploadFileParams'] = uploadFilesParams[index].url_properties;

            fileItem._file['fileExtension'] = this.getFileExtension(fileItem.file.name);
            fileItem._file['uuid'] = fileItem['uuid'];
            fileItem._file['fileName'] = fileItem.file.name;
          });
          uploader.uploadAll();
        },
        (error) => {
          this.serverResponseErrMsg = <any>error;
          this.eventDone.emit(false);
        },
      );
    } else {
      return true;
    }
    return this.eventDone;
  }

  sentUploadedFiles(uploader: FileUploader) {
    const uploadedFiles = uploader.queue
      .filter((fileItem) => fileItem._file['isSuccess'])
      .map((fileItem) => {
        const result = {
          uuid: fileItem._file['uuid'],
          file_name: fileItem._file['fileName'],
          file_extension: fileItem._file['fileExtension'],
        };
        if (fileItem._file['item_index'] !== undefined) {
          result['item_index'] = fileItem._file['item_index'];
        }
        return result;
      });
    return uploadedFiles;
  }

  getFileExtension(fileName) {
    return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
  }

  initEventUploader(uploader: FileUploader) {
    uploader.onBuildItemForm = (fileItem, form) => {
      const uploadFileParams = fileItem['uploadFileParams'] || {};
      for (const key of Object.keys(uploadFileParams)) {
        form.append(key, uploadFileParams[key]);
      }

      fileItem.formData = form;
    };

    uploader.onCompleteAll = () => {
      window.setTimeout(() => {
        if (uploader.queue.every((fileItem) => fileItem.isSuccess)) {
          this.eventDone.emit(true);
        } else {
          this.eventDone.emit(false);
        }
      }, 500);
    };

    uploader.onCompleteItem = (item) => {
      this.setStatusFileItemToFile(item);
    };
  }

  uploadFiles(fileItems) {
    fileItems.forEach((fileItem) => fileItem.upload());
  }

  setStatusFileItemToFile(item: FileItem) {
    item._file['isCancel'] = item.isCancel;
    item._file['isError'] = item.isError;
    item._file['isSuccess'] = item.isSuccess;
    item._file['isUploaded'] = item.isUploaded;
  }

  prepareFilesToUpload(uploader: FileUploader) {
    uploader.progress = 0;
    const uploadingFiles = uploader.queue.filter((fileItem) => !fileItem._file['isUploaded'] || fileItem._file['isError']);
    uploadingFiles.forEach((fileItem) => {
      fileItem.isCancel = false;
      fileItem.isError = false;
      fileItem.isSuccess = false;
      fileItem.isUploaded = false;
      this.setStatusFileItemToFile(fileItem);
    });
    return uploadingFiles;
  }

  resetStatusUploader(uploader: FileUploader) {
    uploader.queue.forEach((fileItem) => {
      fileItem.isCancel = false;
      fileItem.isError = false;
      fileItem.isSuccess = false;
      fileItem.isUploaded = false;
      this.setStatusFileItemToFile(fileItem);
    });
  }
}
