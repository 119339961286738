import { UploadFile } from '@models/upload-file';

export class Story {
  id: number;
  story_category_id: number;
  create_user_id: number;
  grade?: number;
  archive: string;
  vision: string;
  upload_files: Array<UploadFile>;
  created_at: string;
  updated_at: string;
}
