<div class="filter">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <span class="top-btn-close" (click)="onBack('close-modal')">
            <i class="fa fa-times"></i>
          </span>
          <h2 class="heading-text">活動記録参照</h2>
        </div>
        <div class="modal-content" *ngIf="dataLoaded(); else loadingIcon">
          <div class="segments" *ngIf="isDocumentRecord()">
            <app-segment-view [tabOptions]="tabOptions" (switchTab)="switchTab($event)"></app-segment-view>
          </div>

          <ng-container *ngIf="isQuestionnaireRecord()">
            <div class="text-info">
              <p>作成日時: {{ activityRecord.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
              <p>［アンケート］</p>
            </div>
            <app-questionnaire-detail-student [questionnaire]="activityRecord.record"></app-questionnaire-detail-student>
          </ng-container>

          <ng-container *ngIf="isDocumentRecord()">
            <ng-container [ngSwitch]="currentTab">
              <ng-container *ngSwitchCase="'self'">
                <div class="text-info">
                  <p>作成日時: {{ activityRecord.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
                  <p *ngIf="activityRecord.is_answer">［課題］</p>
                </div>
                <app-document-detail-student
                  [document]="activityRecord?.record"
                  [categoryName]="categoryName"
                  [marks]="activityRecord?.marks"
                  [document_answers]="activityRecord.record.document_answers"
                  [isCompassAnswer]="activityRecord?.is_compass_answer"
                >
                </app-document-detail-student>
                <div class="space-album-name" *ngIf="activityRecord.space_name">提出先：{{ activityRecord.space_name }}</div>
              </ng-container>
              <ng-container *ngSwitchCase="'cross_assessment'">
                <app-student-cross-assessments [crossAssessments]="activityRecord.cross_assessments"> </app-student-cross-assessments>
              </ng-container>
              <ng-container *ngSwitchCase="'teacher_assessment'">
                <h2 class="assessments-header">先生からのコメント</h2>
                <ng-container *ngFor="let assessment of activityRecord.assessments; first as isFirst">
                  <app-student-assessment-detail [assessment]="assessment" [canEdit]="false" [isFirst]="isFirst">
                  </app-student-assessment-detail>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <div class="block-last">
            <button class="spen-mod-btn" (click)="onBack('back')">戻る</button>
          </div>
        </div>
      </div>
      <ng-template #loadingIcon>
        <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
      </ng-template>
    </div>
  </div>
</div>
