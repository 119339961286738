<div class="comment" data-cy="commented-item">
  <div class="user">
    <div class="user-info">
      <img data-cy="commented-teacher-avatar" [src]="assessment.create_user.avatar" />
      <div class="user-name">
        <div class="teacher-name" *ngIf="isTeacher(); else studentName">
          <p class="truncate-text" data-cy="commented-teacher-name">{{ assessment.create_user.name }}</p>
          <p>先生</p>
        </div>
        <ng-template #studentName>
          <p
            class="student-name truncate-text"
            data-cy="commented-student-name"
            [class.spen-util-link]="assessment.create_user.viewer_permission"
            [routerLink]="assessment.create_user.viewer_permission ? ['/students/teacher', assessment.create_user.id] : []"
          >
            {{ assessment.create_user.name }}
          </p>
          <p class="student-class" data-cy="commented-student-class">
            {{ assessment.create_user.className }}{{ assessment.create_user.attendanceNumber }}番
          </p>
        </ng-template>
      </div>
    </div>
    <a class="delete-edit spen-util-link" data-cy="commented-edit-button" (click)="onEditAssessment()" *ngIf="canEdit">
      <i class="fa fa-pencil"></i><span>変更・削除</span>
    </a>
  </div>

  <div class="content-comment">
    <p class="time-comment" data-cy="commented-created-at">{{ assessment.created_at }}</p>
    <app-read-more [content]="assessment.content"></app-read-more>
    <span class="updated-text" data-cy="commented-is-updated" *ngIf="assessment.is_updated">（変更済み）</span>
  </div>
</div>
