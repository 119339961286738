<div class="row files-view" (window:resize)="resize()" #root_div>
  <div
    *ngFor="let file of imageFiles(); let file_index = index"
    class="col-md-4 col-sm-6 col-four-1440 col-xs-6"
    [class.attach-invisible]="file_index > 3"
  >
    <div class="attach attach-image" (click)="onFileClick(file)">
      <img src="{{ file.thumbnail }}" />
      <a class="action-file" *ngIf="!file.canPreview() && !file.isPdf()">
        <i class="fa fa-download"></i>
      </a>
      <ng-container *ngIf="showShadowBox(file_index)">
        <div class="overlay"></div>
        <p class="number-attach">+{{ imageFiles().length - 3 }}</p>
      </ng-container>
    </div>
    <a class="file-name" *ngIf="!showShadowBox(file_index)" (click)="onFileClick(file)">{{ file.file_name }}</a>
  </div>
  <div *ngFor="let file of notImageFiles()" class="col-md-4 col-sm-6 col-four-1440 col-xs-6">
    <div class="attach attach-image" (click)="onFileClick(file)">
      <div class="file-format"><i [ngClass]="file.file_name | fileIconClass"></i></div>
      <a class="action-file" *ngIf="!file.canPreview() && !file.isPdf()">
        <i class="fa fa-download"></i>
      </a>
    </div>
    <a class="file-name" (click)="onFileClick(file)">{{ file.file_name }}</a>
  </div>
  <app-gallery [items]="previewFiles()" #gallery></app-gallery>
</div>
