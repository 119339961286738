<div class="stu-create-album" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <a (click)="onBack()"> <i class="fa fa-chevron-left"></i> </a>{{ pageTitle }}
    </h1>
  </div>

  <form class="form" [formGroup]="form" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="line-separator"></div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-12">
            <p class="label-input">タイトル</p>
          </div>
          <div class="col-md-9 col-sm-9 col-xs-12">
            <input
              class="spen-mod-input-text"
              type="text"
              name="title"
              [appPlaceholder]="'タイトル'"
              formControlName="title"
              [appFocus]="focusEvent"
            />
            <div *ngIf="formErrors['title']" class="spen-mod-error-content">
              {{ formErrors['title'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">目標</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <textarea [appPlaceholder]="'目標'" formControlName="goal"></textarea>
            <div *ngIf="formErrors.goal" class="spen-mod-error-content">
              {{ formErrors['goal'].join(' ') }}
            </div>
            <p class="text-num pull-right">
              文字数 <span class="spen-ly-form-document is-num-character">{{ form.get('goal').value.length }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">概要</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <textarea [appPlaceholder]="'概要'" formControlName="outline"></textarea>
            <div *ngIf="formErrors.outline" class="spen-mod-error-content">
              {{ formErrors['outline'].join(' ') }}
            </div>
            <p class="text-num pull-right">
              文字数 <span class="spen-ly-form-document is-num-character">{{ form.get('outline').value.length }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <p class="label-input">表紙画像</p>
          </div>
          <app-file-uploader
            class="col-sm-12 col-xs-12"
            [attachedFiles]="album.image ? [album.image] : null"
            [option]="optionUploader"
            (eventSelectFile)="onSelectFile($event)"
            (uploadFilesStatus)="onUploadFilesStatus($event)"
          >
          </app-file-uploader>
        </div>
      </div>

      <div class="submit">
        <button
          type="submit"
          (click)="onSubmit()"
          class="spen-mod-btn is-primary"
          [disabled]="this.isSubmitting || uploadFilesStatus === 'uploading'"
        >
          {{ submitButtonText }}
        </button>
        <button type="button" (click)="onDelete()" class="spen-mod-btn is-default" *ngIf="album.isExistedAlbum()">削除する</button>
        <button type="button" (click)="onBack()" class="spen-mod-btn is-default">戻る</button>
      </div>
    </div>
  </form>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
