import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorService } from '@services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  hasError = false;
  defaultTitle = 'エラーが発生しました';
  defaultMessage = 'システムエラーが発生しました';
  title: string;
  message: string;
  subscription: Subscription;

  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.subscription = this.errorService.errorStream.subscribe((error) => {
      if (!error) {
        return;
      }

      this.title = error.title || this.defaultTitle;
      this.message = error.message || this.defaultMessage;
      this.hasError = true;
    });
  }

  close() {
    this.hasError = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
