import { Component } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { RouterDataService } from '@services/router-data.service';
import { StudentNavigationService } from '@services/navigation/student-navigation.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-link-prev-next',
  templateUrl: './link-prev-next.component.html',
  styleUrls: ['./link-prev-next.component.scss'],
})
export class LinkPrevNextComponent {
  constructor(
    public layoutService: LayoutService,
    public routerDataService: RouterDataService,
    public studentNavigationService: StudentNavigationService,
    private router: Router,
  ) {}

  onClickNavigate(position: 'list' | 'prev' | 'next') {
    if (this.studentNavigationService.isLoading) {
      return;
    }
    this.router.navigateByUrl(this.studentNavigationService[position].url, { replaceUrl: position !== 'list' });
  }
}
