<div class="view-detail-border" *ngIf="!layoutService.isTabletDownView.value; else tabletView">
  <div class="text-detail-content">
    <div class="pre-icons">
      <div class="pre-space-color" data-cy="space-color" [style.background-color]="spaceDetail?.color_code"></div>
      <span class="space-name" data-cy="space-name">{{ spaceDetail?.name }}</span>
    </div>
    <div class="class-detail">
      <p>
        <span data-cy="teacher-num">担当先生：{{ spaceDetail?.teachers_count | number }}名</span>
        <span data-cy="managed-user" *ngIf="spaceDetail?.managed_by_user">（担当中）</span>
        <span class="user-count" data-cy="student-num">参加生徒：{{ spaceDetail?.users_count | number }}名</span>
        <a class="spen-util-link" data-cy="participants-list" (click)="showParticipantsList()">一覧を見る</a>
      </p>
      <p data-cy="create-user">作成者：{{ spaceDetail?.create_user_name }}先生</p>
      <p>
        <span class="create-time" data-cy="created-at">作成日時：{{ spaceDetail?.created_at }}</span>
        <span data-cy="updated-at" *ngIf="spaceDetail?.updated_at !== '-'">更新日時：{{ spaceDetail?.updated_at }}</span>
      </p>
      <p *ngIf="spaceDetail?.school_year" data-cy="school-year">年度：{{ spaceDetail?.school_year }}年度</p>

      <span class="cross-setting-block">
        <ng-container *ngFor="let item of listCrossSettings">
          <label class="cross-setting-{{ item?.value ? '' : 'not-' }}allow" data-cy="cross-setting">
            <i class="fa fa-ban" data-cy="setting-disable" *ngIf="!item?.value"></i>{{ item?.name }}
          </label>
        </ng-container>
      </span>
    </div>
  </div>

  <div class="link-setting" *ngIf="isShowLinkSetting">
    <a
      *ngIf="spaceDetail?.can_be_edited"
      class="spen-util-link"
      data-cy="can-be-edited"
      routerLink="/spaces/teacher/{{ spaceDetail?.id }}/edit"
    >
      変更・削除
    </a>
    <br />
    <a
      *ngIf="spaceDetail?.managed_by_user"
      class="spen-util-link"
      data-cy="can-add-work"
      [routerLink]="['/works', 'new']"
      [state]="{ space: spaceDetail }"
    >
      新規課題配信
    </a>
    <br />
    <a
      *ngIf="spaceDetail?.managed_by_user"
      class="spen-util-link"
      data-cy="can-add-album"
      [routerLink]="['/albums', 'teacher', 'delivery', 'new']"
      [queryParams]="{ space_id: spaceDetail.id }"
    >
      新規アルバム配信
    </a>
    <br />
    <ng-container *ngIf="spaceDetail?.managed_by_user">
      <p *ngIf="spaceDetail?.is_delivered_graduation_message; else deliverGraduationMessage">卒業メッセージ配信済</p>
      <ng-template #deliverGraduationMessage>
        <a
          class="spen-util-link"
          data-cy="can-add-graduation-messages"
          [routerLink]="['/graduation-messages', 'teacher', 'new']"
          [state]="{ space: spaceDetail }"
        >
          卒業メッセージ配信
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #tabletView>
  <div class="view-detail-border">
    <span class="text-detail-content">
      <div class="pre-icons">
        <div class="pre-space-color" data-cy="space-color" [style.background-color]="spaceDetail?.color_code"></div>
        <span class="space-name" data-cy="space-name">{{ spaceDetail?.name }}</span>
      </div>
      <div class="class-detail">
        <div class="block-info pull-right" *ngIf="isShowLinkSetting">
          <label class="spen-mod-select" [class.is-selecting]="isShowDropdownActions">
            <button class="spen-mod-btn dropdown-toggle" data-cy="show-menu" (click)="isShowDropdownActions = !isShowDropdownActions">
              <img src="/assets/images/icon-dots.svg" alt="dot-icon" />
            </button>
            <div class="image-select-box">
              <ul class="option-list">
                <li *ngIf="spaceDetail?.can_be_edited">
                  <a routerLink="/spaces/teacher/{{ spaceDetail?.id }}/edit" class="dropdown-item" data-cy="can-be-edited"> 変更・削除 </a>
                </li>
                <li *ngIf="spaceDetail?.managed_by_user">
                  <a class="dropdown-item" data-cy="can-add-work" [routerLink]="['/works', 'new']" [state]="{ space: spaceDetail }">
                    新規課題配信
                  </a>
                </li>
                <li *ngIf="spaceDetail?.managed_by_user">
                  <a
                    class="dropdown-item"
                    data-cy="can-add-album"
                    [routerLink]="['/albums', 'teacher', 'delivery', 'new']"
                    [queryParams]="{ space_id: spaceDetail.id }"
                    >新規アルバム配信</a
                  >
                </li>
                <li *ngIf="spaceDetail?.managed_by_user">
                  <p *ngIf="spaceDetail?.is_delivered_graduation_message; else deliverGraduationMessage">卒業メッセージ配信済</p>
                  <ng-template #deliverGraduationMessage>
                    <a
                      class="dropdown-item"
                      data-cy="can-add-graduation-messages"
                      [routerLink]="['/graduation-messages', 'teacher', 'new']"
                      [state]="{ space: spaceDetail }"
                      >卒業メッセージ配信</a
                    >
                  </ng-template>
                </li>
              </ul>
            </div>
          </label>
        </div>

        <p>
          <span data-cy="teacher-num">担当先生：{{ spaceDetail?.teachers_count | number }}名</span>
          <span data-cy="managed-user" *ngIf="spaceDetail?.managed_by_user">（担当中）</span>
        </p>
        <p data-cy="student-num">生徒：{{ spaceDetail?.users_count | number }}名</p>

        <div class="show-more" *ngIf="!showMoreInMobile; else moreInfo">
          <a class="spen-util-link" data-cy="show-more" (click)="showMoreInMobile = true">
            <span>もっと見る</span>
            <i class="fa fa-chevron-down"></i>
          </a>
        </div>
        <ng-template #moreInfo>
          <div class="students-list">
            <a class="spen-util-link" data-cy="participants-list" (click)="showParticipantsList()">一覧を見る</a>
          </div>
          <p data-cy="create-user">作成者：{{ spaceDetail?.create_user_name }}先生</p>

          <p data-cy="created-at">作成日時：{{ spaceDetail?.created_at }}</p>
          <p data-cy="updated-at" *ngIf="spaceDetail?.updated_at !== '-'">更新日時：{{ spaceDetail?.updated_at }}</p>
          <p data-cy="school-year" *ngIf="spaceDetail?.school_year">年度：{{ spaceDetail?.school_year }}年度</p>

          <span class="cross-setting-block">
            <ng-container *ngFor="let item of listCrossSettings">
              <label class="cross-setting-{{ item?.value ? '' : 'not-' }}allow" data-cy="cross-setting">
                <i class="fa fa-ban" data-cy="setting-disable" *ngIf="!item?.value"></i>{{ item?.name }}
              </label>
            </ng-container>
          </span>
        </ng-template>
      </div>
    </span>
  </div>
</ng-template>

<app-modal-participants-list *ngIf="isShowParticipants" (eventCancel)="hideParticipantsList($event)" [spaceId]="spaceDetail.id">
</app-modal-participants-list>
