<div class="stu-work-detail" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <a (click)="onBack()"><i class="fa fa-chevron-left"></i></a>課題詳細
    </h1>
  </div>

  <app-work-info-student [work]="work"> </app-work-info-student>

  <ng-container *ngIf="work.activity_record?.published">
    <div class="submitted-file">
      <p class="submitted-heading">提出済ファイル</p>
    </div>
    <div class="list-file-submit">
      <ul class="detail-flex-list">
        <li class="list-item" routerLink="/activity-records/student/{{ work.activity_record.id }}">
          <a class="list-item-info">
            <i class="fa fa-file-text-o"></i>
            <div class="text-info">
              <p class="time-detail">{{ work.activity_record.created_at }}</p>
              <p class="name-file">{{ work.activity_record.title }}</p>
              <i class="fa fa-commenting-o"></i> {{ work.activity_record.assessments_count }}
              <span class="detail-category" *ngIf="!!work.activity_record.category_id">
                {{ work.activity_record.category_name }}
              </span>
            </div>
          </a>
          <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
        </li>
      </ul>
    </div>
  </ng-container>

  <div class="block-last">
    <button
      class="spen-mod-btn is-primary"
      *ngIf="showAnswerbutton()"
      (click)="sendEvent('回答する')"
      routerLink="/spaces/student/{{ spaceId }}/works/{{ work.id }}/answer"
    >
      回答する
    </button>
    <button
      class="spen-mod-btn is-primary"
      *ngIf="showUpdateDraftButton()"
      (click)="sendEvent('回答を再開する')"
      routerLink="/activity-records/student/{{ work.activity_record.id }}/editAnswer"
    >
      回答を再開する
    </button>
    <button class="spen-mod-btn" (click)="onBack()">戻る</button>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
