import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@models/user';
import * as _ from 'lodash';

@Injectable()
export class SpaceFilterService {
  readonly LIMIT = 20;

  apiEndpoint = environment.apiEndpoint;
  isTeacher: boolean;
  isCheckedAll = false;

  selectedUsers = new BehaviorSubject<Array<any>>([]);

  singleUserResults = new BehaviorSubject<Array<any>>([]);
  groupUserResults = new BehaviorSubject<Array<any>>([]);

  selectedUsers$ = this.selectedUsers.asObservable();
  singleUserResults$ = this.singleUserResults.asObservable();
  groupUserResultsS$ = this.groupUserResults.asObservable();

  constructor(protected http: HttpClient) {}

  /** Query functions */

  searchUsers(data: {}, nextPage = null): Observable<any> {
    const searchUserUrl = `${this.apiEndpoint}/users/search`;
    const params = {
      name: data['name'].trim(),
      user_type: this.isTeacher ? 'teacher' : 'student',
    };

    if (data['selected_all']) {
      params['selected_all'] = true;
    } else {
      params['limit'] = this.LIMIT;
      params['page'] = nextPage;
    }

    return this.http.post(searchUserUrl, params).pipe(
      map((response) => ({
        single: response['users'] || [],
        group: response['groups'] || [],
        nextPage: response['next_page'] || null,
      })),
    );
  }

  searchGroups(data: {}, nextPage = null): Observable<any> {
    const searchUserUrl = `${this.apiEndpoint}/groups/search`;
    const params = {
      name: data['name'].trim(),
      types: this.isTeacher ? 'teacher' : 'student',
    };

    if (data['selected_all']) {
      params['selected_all'] = true;
    } else {
      params['limit'] = this.LIMIT;
      params['page'] = nextPage;
    }
    return this.http.post(searchUserUrl, params).pipe(
      map((response) => ({
        single: response['users'] || [],
        group: response['groups'] || [],
        nextPage: response['next_page'] || null,
      })),
    );
  }
  /** Filter logic functions */

  onSearch() {
    this.singleUserResults.next([]);
    this.groupUserResults.next([]);
  }

  removeSelectedUser(user: User) {
    this.selectedUsers.value.splice(this.selectedUsers.value.indexOf(user), 1);
  }

  get selectedUsersCount() {
    return this.selectedUsers.value.length;
  }

  onSearchDone(params: { single: Array<any>; group: Array<any>; nextUrl?: string }) {
    params = this.changeDataSearch(params);

    this.singleUserResults.value.push(...params.single);
    this.groupUserResults.value.push(...params.group);
    this.isCheckedAll =
      this.singleUserResultsFilter({ disabled: false }).length === 0 && this.groupUserResultsFilter({ disabled: false }).length === 0;
  }

  removeOldItems(isLoadedAllGroups) {
    if (!isLoadedAllGroups && !this.isTeacher) {
      this.groupUserResults.value.splice(0, this.groupUserResults.value.length);
    }
    this.singleUserResults.value.splice(0, this.singleUserResults.value.length);
  }

  onChangeUser(user: User) {
    user['checked'] = !user['checked'];
    user['checked'] ? this.checkedUser(user) : this.uncheckedUser(user);
  }

  onChangeGroup(group) {
    group['checked'] = !group['checked'];
    group['checked'] ? this.checkedGroup(group) : this.uncheckedGroup(group);
  }

  onSelectAll(checked) {
    const userIds = this.selectedUsers.value.map((user) => user['id']);
    for (const object of this.singleUserResults.value) {
      if (userIds.indexOf(object.id) === -1) {
        object['checked'] = checked;
        object['disabled'] = false;
      }
    }
    this.changeByIdsForGroupUserResults(userIds, this.groupUserResults.value, true, { selected: true });
    for (const object of this.groupUserResults.value) {
      if (!object['selected']) {
        object['checked'] = checked;
        object['disabled'] = false;
      }
    }
  }

  onSubmit(): boolean {
    let checkedUsers = this.singleUserResultsFilter({ checked: true, disabled: false, selected: false });
    for (const group of this.groupUserResultsFilter({ checked: true, disabled: false, selected: false })) {
      group['members'].forEach((member) => checkedUsers.push(member));
    }
    checkedUsers = _.uniq(checkedUsers);
    if (checkedUsers.length === 0) {
      return false;
    }

    for (const user of checkedUsers) {
      if (this.selectedUsers.value.findIndex((item) => item.id === user.id) === -1) {
        this.selectedUsers.value.push(user);
      }
    }
    this.onClose();
    return true;
  }

  onClose() {
    this.singleUserResults.next([]);
    this.groupUserResults.next([]);
  }

  /** Append conditions (checked, disabled, selected) to each item in response */
  /** Then disable all selected items */
  changeDataSearch(params) {
    params['single'].map((single) => Object.assign(single, { checked: false, disabled: false, selected: false }));

    const filterRoleType = this.isTeacher ? 1 : 2;
    params['group'].map((group) => {
      group['members'] = group['members'].filter((member) => member['user_type_id'] === filterRoleType);
      return Object.assign(group, { checked: false, disabled: false, selected: false });
    });

    const idsOfSelectedUsers = this.getAllIdByListObject([
      this.selectedUsers.value,
      this.groupUserResultsFilter({ checked: true, disabled: false }),
    ]);

    params['single'] = params['single'].map((user) => {
      if (idsOfSelectedUsers.includes(user.id)) {
        user['disabled'] = true;
      }
      return user;
    });

    params['group'] = params['group'].map((group) => {
      const idsOfUsersInGroup = this.idsOfUsersInGroup(group);

      if (this.arrayHasContainArray(idsOfSelectedUsers, idsOfUsersInGroup)) {
        group['disabled'] = true;
      }
      return group;
    });
    return params;
  }

  /** update after checked user */
  checkedUser(user: User) {
    const allIdsChecked = this.getAllIdsChecked();

    const groups = this.groupUserResultsFilter({ checked: false });
    this.changeByIdsForGroupUserResults(allIdsChecked, groups, true, { disabled: true });
  }

  /** update after unchecked user */
  uncheckedUser(user: User) {
    const allIdsChecked = this.getAllIdsChecked();

    const groups = this.groupUserResultsFilter({ disabled: true });
    this.changeByIdsForGroupUserResults(allIdsChecked, groups, false, { disabled: false });
  }

  /** update after checked group */
  checkedGroup(group) {
    const allIdsChecked = this.getAllIdsChecked();

    this.changeByGroupToSingle(group, this.singleUserResultsFilter({ disabled: false }), { disabled: true });

    const uncheckedGroups = this.groupUserResultsFilter({ checked: false });
    this.changeByIdsForGroupUserResults(allIdsChecked, uncheckedGroups, true, { disabled: true });
  }

  /** update after unchecked group */
  uncheckedGroup(group) {
    const allIdsChecked = this.getAllIdsChecked();

    const singleUserExceptSelectedUser = this.getSingleUserExceptSelectedUser(this.singleUserResultsFilter({ disabled: true }));
    this.changeByGroupToSingle(group, singleUserExceptSelectedUser, { disabled: false });
    const idsInSelectedGroups = this.getAllIdByListObject([this.groupUserResultsFilter({ checked: true, disabled: false })]);
    for (const single of this.singleUserResultsFilter({ disabled: false })) {
      if (idsInSelectedGroups.includes(single.id)) {
        Object.assign(single, { disabled: true });
      }
    }

    const uncheckedGroups = this.groupUserResultsFilter({ checked: false });
    this.changeByIdsForGroupUserResults(allIdsChecked, uncheckedGroups, true, { disabled: true });

    const groups = this.groupUserResultsFilter({ disabled: true });
    this.changeByIdsForGroupUserResults(allIdsChecked, groups, false, { disabled: false });
  }

  /** Return new array users not include user was selected before open modal **/
  getSingleUserExceptSelectedUser(users = []) {
    const result = [];
    for (const user of users) {
      const target = this.selectedUsers.value.find((item) => item.id === user.id);
      if (!target) {
        result.push(user);
      }
    }
    return result;
  }

  /** Find single item in singles array, if it's contained in group, then assign condition to that item */
  changeByGroupToSingle(group: {}, singles: Array<User>, conditions: {}) {
    const ids = this.idsOfUsersInGroup(group) || [];
    for (const id of ids) {
      const target = singles.find((item) => item.id === id);
      if (target) {
        Object.assign(target, conditions);
      }
    }
  }

  /**
    - if value == true: filter all group that contained in ids
    - if value == false: filter all group that not contained in ids
    then append conditions to that filtered groups
  */
  changeByIdsForGroupUserResults(ids: Array<number>, groups: Array<any>, value: boolean, conditions: {}) {
    for (const group of groups) {
      const groupMembersIds = this.idsOfUsersInGroup(group);
      const contain = this.arrayHasContainArray(ids, groupMembersIds);
      if ((value && contain) || (!value && !contain)) {
        Object.assign(group, conditions);
      }
    }
  }

  /** Get ids of checked users (source in selectedUsers + singleUserResults + groupUserResults) */
  getAllIdsChecked(): Array<number> {
    const result = this.getAllIdByListObject([
      this.selectedUsers.value,
      this.singleUserResultsFilter({ checked: true, disabled: false }),
      this.groupUserResultsFilter({ checked: true, disabled: false }),
    ]);
    return result;
  }

  /** Get ids of checked and unselected users (source in singleUserResults + groupUserResults) */
  /** Used to binding in view */
  get allIdsCheckedAndUnselected(): Array<number> {
    const result = this.getAllIdByListObject([
      this.singleUserResultsFilter({ checked: true, disabled: false }),
      this.groupUserResultsFilter({ checked: true, disabled: false }),
    ]);
    return result;
  }

  /** Get all user's id of arrays (array may of type users or groups) */
  getAllIdByListObject(params: Array<any>): Array<number> {
    const ids = [];
    for (const arr of params) {
      arr.forEach((object) => {
        if (object['members']) {
          object['members'].forEach((member) => ids.push(member['id']));
        } else {
          ids.push(object['id']);
        }
      });
    }
    return _.uniq(ids);
  }

  /** get filtered singleUserResults by conditions: selected, disabled or checked */
  singleUserResultsFilter(conditions: { [key: string]: boolean }): Array<any> {
    return this.getObjectsByConditions(this.singleUserResults.value, conditions);
  }

  /** get filtered groupUserResults by conditions: selected, disabled or checked */
  groupUserResultsFilter(conditions: { [key: string]: boolean }): Array<any> {
    return this.getObjectsByConditions(this.groupUserResults.value, conditions);
  }

  /** Filter a list of objects by conditions: selected, disabled or checked */
  private getObjectsByConditions(objects: Array<User | any>, conditions: { [key: string]: boolean }): Array<any> {
    return objects.filter((object) => {
      for (const condition of Object.keys(conditions)) {
        if (object[condition] !== conditions[condition]) {
          return false;
        }
      }
      return true;
    });
  }

  /** Check if first array contains all element of second array */
  private arrayHasContainArray(arr1: Array<number>, arr2: Array<number>) {
    return _.difference(arr2, arr1).length === 0;
  }

  /** return ids of all users in 1 group */
  private idsOfUsersInGroup(group: {}): Array<number> {
    return group['members'].map((user) => user['id']);
  }
}

@Injectable()
export class TeacherFilterService extends SpaceFilterService {
  isTeacher = true;

  constructor(protected http: HttpClient) {
    super(http);
  }
}

@Injectable()
export class StudentFilterService extends SpaceFilterService {
  isTeacher = false;

  constructor(protected http: HttpClient) {
    super(http);
  }
}
