import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LayoutService } from '@components/layout/layout.service';
import { StudentService } from '@components/student/student.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { UnsubmittedWork } from '@components/student/student-detail/unsubmitted-works/student.unsubmit-works-response';
import { isEqual, pick, pickBy, find, omit, isNil } from 'lodash';

@Component({
  selector: 'app-student-unsubmitted-works',
  templateUrl: 'student-unsubmitted-works.component.html',
  styleUrls: ['./student-unsubmitted-works.component.scss'],
})
export class StudentUnsubmittedWorksComponent implements OnInit {
  @Input() studentId: number;
  form: UntypedFormGroup;
  data: any;
  isShowFilterDetail = false;
  isLoadingWorkData = true;
  dataFilterOptions = {};
  pages: any = {};
  private readonly allowedParamKeys = ['page', 'submission_status', 'keyword', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['sort_key', 'keyword'];
  studentUnsubmitWorks: Array<UnsubmittedWork>;
  deliveryDateSortIcon: string;
  hasFilterFormSubmittedYet = false;
  emptyMessage = '';
  messageSort: string;
  private _deliveryColumSortOrder: string;

  sortOptions = [
    { sort_value: 'desc', text: '配信日時（降順）' },
    { sort_value: 'asc', text: '配信日時（昇順）' },
  ];

  submitionStatus = [
    { value: 'all', name: 'すべて' },
    { value: 'unanswered', name: '未回答' },
    { value: 'draft', name: '回答中' },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    private studentService: StudentService,
    private filterSerice: TeacherFilterService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    this.buildQueryParams(params);
    this.initForm();
    this._deliveryColumSortOrder = this.dataFilterOptions['sort_order'];
    this.messageSort = this.getDeliverFilterOrderMessage();
    await this.filterSerice.setFilter(this.dataFilterOptions);
    this.loadUnsubmitedWorks();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  buildQueryParams(params = {}) {
    this.dataFilterOptions = pick(params, this.allowedParamKeys);
    this.dataFilterOptions['submission_status'] = params['submission_status'] || 'all';
    this.dataFilterOptions['keyword'] = params['keyword'] || '';
    this.dataFilterOptions['sort_key'] = params['sort_key'] || 'delivered_at';
    this.dataFilterOptions['sort_order'] = params['sort_order'] || 'desc';
  }

  initForm() {
    this.form = this.formBuilder.group({
      submission_status: this.dataFilterOptions['submission_status'],
      keyword: this.dataFilterOptions['keyword'],
      sort_order: this.dataFilterOptions['sort_order'],
    });
    this.hasFilterFormSubmittedYet = !this.isDefaultFormValue;
  }

  onChangeShowFilter() {
    this.isShowFilterDetail = !this.isShowFilterDetail;
  }

  onChangeSortValue(value: string) {
    this._deliveryColumSortOrder = value;
  }

  loadUnsubmitedWorks() {
    if (!this.isLoadingSortDeliveryColumn()) {
      this.isLoadingWorkData = true;
    }

    this.studentService.getUnsubmitWorks(this.studentId, this.dataFilterOptions).subscribe((res) => {
      this.studentUnsubmitWorks = res.works;
      Object.assign(this.pages, res.meta);
      this.getEmptyMessage();
      this.isLoadingWorkData = false;
      this.setDeliverySortStatus();
    });
  }

  getEmptyMessage() {
    if (this.studentUnsubmitWorks.length === 0) {
      if (this.isDefaultFormValue) {
        this.emptyMessage = 'ここに生徒の未提出課題が表示されます。';
      } else {
        this.emptyMessage = '検索結果が0件です。';
      }
    }
  }

  onSubmit() {
    window.scrollTo(0, 0);
    this.isShowFilterDetail = false;
    this.hasFilterFormSubmittedYet = true;
    this.trimKeyWord();
    const params = pickBy(this.form.value);
    params['request_timestamp'] = Date.now();

    this.navigate(this.makeQueryParams(params));
  }

  get isDefaultFormValue(): boolean {
    const compareValues = this.form.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_order'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  defaultFilterValue() {
    const fliterValues = {
      submission_status: 'all',
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      fliterValues['sort_order'] = 'desc';
    }

    return fliterValues;
  }

  resetFormValue() {
    window.scrollTo(0, 0);
    this.form.patchValue(this.defaultFilterValue());
    this.onSubmit();
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  onChangeDeliveryDateOrder(): void {
    if (!this.isLoadingSortDeliveryColumn()) {
      this.deliveryDateSortIcon = 'fa fa-spinner fa-spin';
      this.hasFilterFormSubmittedYet = true;
      const params = { ...this.dataFilterOptions, sort_order: this.toggleSortOrder(), sort_key: 'delivered_at', page: 1 };
      this.navigate(params, 'merge');
    }
  }

  makeQueryParams(params) {
    return Object.assign(params, { tab: 'unsubmitted_works' });
  }

  setDeliverySortStatus(): void {
    this.deliveryDateSortIcon = this._deliveryColumSortOrder === 'desc' ? 'fa fa-caret-down' : 'fa fa-caret-up';
  }

  pageChanged(page: number) {
    const params = { ...this.dataFilterOptions, page: page };
    this.navigate(params, 'merge');
  }

  getDeliverFilterOrderMessage() {
    const sort = find(this.sortOptions, { sort_value: this._deliveryColumSortOrder });
    return !!sort ? sort.text : '';
  }

  isLoadingSortDeliveryColumn(): boolean {
    return this.deliveryDateSortIcon === 'fa fa-spinner fa-spin';
  }

  onOpenSpaceDetail(id) {
    this.router.navigateByUrl(`/spaces/teacher/${id}`);
  }

  onOpenWorkDetail(workId) {
    this.router.navigateByUrl(`/works/teacher/delivery/${workId}`);
  }

  trimKeyWord() {
    const keyword = this.form.value['keyword'].trim();
    this.form.controls['keyword'].setValue(keyword);
  }

  private toggleSortOrder() {
    return this._deliveryColumSortOrder === 'desc' ? 'asc' : 'desc';
  }
}
