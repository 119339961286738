import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CrossAssessmentsResponse } from './cross-assessments.response';

@Injectable()
export class TeacherCrossAssessmentService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  loadCrossAssessments(activityId: number, params = {}): Observable<CrossAssessmentsResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityId}/cross_assessments`;
    return this.http.get<CrossAssessmentsResponse>(url, { params: params });
  }
}
