import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManavisionDetailTeacherComponent } from './detail/teacher/manavision-detail-teacher.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ManavisionDetailTeacherComponent],
  exports: [ManavisionDetailTeacherComponent],
  imports: [CommonModule, RouterModule],
  providers: [],
})
export class ManavisionModule {}
