import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TemplatesService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getTemplates(): Observable<any> {
    const url = `${this.apiEndpoint}/templates`;
    return this.http.get(url);
  }

  getTemplate(template_id: number): Observable<any> {
    const url = `${this.apiEndpoint}/templates/${template_id}`;
    return this.http.get(url);
  }
}
