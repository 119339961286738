import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkEditorService } from '@components/work/edit-work/work-editor.service';
import { EditWorkStep1Component } from '@components/work/edit-work/step1/edit-work-step1.component';
import { EditWorkStep2Component } from '@components/work/edit-work/step2/edit-work-step2.component';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-edit-work',
  templateUrl: './edit-work.component.html',
  styleUrls: ['./edit-work.component.scss'],
  providers: [WorkEditorService],
})
export class EditWorkComponent implements OnInit {
  @ViewChild(EditWorkStep1Component) step1Component: EditWorkStep1Component;
  @ViewChild(EditWorkStep2Component) step2Component: EditWorkStep2Component;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private editorService: WorkEditorService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.route.queryParams.subscribe((params) => {
      const step = +params['step'] || 1;
      if (this.editorService.currentStep === 2 && step === 1) {
        this.editorService.setStep1State();
      }
    });
  }

  get isStep2(): boolean {
    return this.editorService.currentStep === 2;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.step1Component) {
      return this.step1Component.canDeactivate();
    } else {
      return this.step2Component.canDeactivate();
    }
  }
}
