<div class="spen-ly-modal" id="modal" data-cy="modal-participants-list">
  <div class="modal-dialog">
    <div class="participants-inner">
      <div class="modal-heading">
        <a *ngIf="layoutService.isTabletDownView.value" (click)="onClose()"><i class="fa fa-times"></i></a>
        <h2 class="heading-text">スペースの生徒・先生一覧</h2>
      </div>

      <div class="modal-content">
        <ng-container *ngIf="dataLoaded; else loadingIcon">
          <div class="content-inner participants-modal-content">
            <span class="students-count">参加生徒：{{ studentMetaData.students_count | number }}名</span>
            <span>担当先生：{{ studentMetaData.teachers_count | number }}名</span>
            <div class="spen-mod-segmentation">
              <a class="segment-column" [ngClass]="!teacherTab ? 'is-primary' : 'is-default'" (click)="changeTab('student')"> 参加生徒 </a>
              <a class="is-default segment-column" [ngClass]="teacherTab ? 'is-primary' : 'is-default'" (click)="changeTab('teacher')">
                担当先生
              </a>
            </div>
            <div class="users-list">
              <ul *ngIf="!teacherTab">
                <li
                  [ngClass]="student.viewer_permission ? 'clickable-item' : 'disabled-item'"
                  *ngFor="
                    let student of students
                      | paginate
                        : {
                            itemsPerPage: studentMetaData.per_page,
                            currentPage: studentMetaData.current_page,
                            totalItems: studentMetaData.total_count,
                          }
                  "
                >
                  <div class="user">
                    <div>
                      <img [src]="student.avatar" />
                    </div>
                    <div class="user-info truncate-text">
                      <a class="spen-util-link" [routerLink]="['/students/teacher/', student.id]">{{ student.name }}</a>
                      <p class="truncate-text" *ngIf="student.class_name">{{ student.class_name }}{{ student.attendance_number }}番</p>
                    </div>
                  </div>
                </li>
              </ul>
              <ul *ngIf="teacherTab">
                <li
                  *ngFor="
                    let teacher of teachers
                      | paginate
                        : {
                            itemsPerPage: teacherMetaData.per_page,
                            currentPage: teacherMetaData.current_page,
                            totalItems: teacherMetaData.total_count,
                          }
                  "
                >
                  <div class="user">
                    <img [src]="teacher.avatar" />
                    <span class="truncate-text">{{ teacher.name }}</span>
                    <span class="self" *ngIf="teacher.id === teacherMetaData.create_user_id">（作成者）</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="pagination" *ngIf="showStudentPagination || showTeacherPagination">
              <app-pagination-controls
                [directionLinks]="!layoutService.isTabletDownView.value"
                [stylePaginate]="layoutService.isTabletDownView.value ? 'small' : 'normal'"
                (pageChange)="pageChanged($event)"
              >
              </app-pagination-controls>
            </div>
            <div class="permission-message" *ngIf="!teacherTab">
              <div class="muted-text">
                選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。
              </div>
              <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご相談ください。</div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-footer participants-modal-footer" *ngIf="!layoutService.isTabletDownView.value">
        <div class="modal-actions is-right-actions">
          <a class="spen-mod-btn is-default js-close-modal-trigger" (click)="onClose()">閉じる</a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
