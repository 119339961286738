import { Component } from '@angular/core';

import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(public authUserService: AuthUserService) {}
}
