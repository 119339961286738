<section class="spen-ly-global-menu-list header-menu-list" *ngIf="isOpenMenu">
  <div class="menu-list-background" (click)="toggleMenu()"></div>
  <div class="menu-list-wrapper">
    <div class="spen-util-inner">
      <div class="menu-item" *ngFor="let menu of globalMenuData" (click)="toggleMenu()">
        <a class="menu-link" [href]="menu.link_url" [target]="menu.target_blank ? '_blank' : '_self'">
          <img class="menu-icon" [src]="menu.icon_url" /><br />
          <div class="spen-mod-circle-badge is-old-red" *ngIf="menu.batch">
            <i class="fa fa-exclamation"></i>
          </div>
          <div class="text" [innerHTML]="menu.title"></div>
        </a>
      </div>
    </div>
  </div>
</section>
