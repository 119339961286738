import { Component, Input, OnInit } from '@angular/core';

import { StoryCommentStudentService } from '@components/story/student/story-comment/story-comment-student.service';

import { StoryComment } from '@models/story-comment';
import { StudentStoryCommentsMeta } from '@components/story/student/story-comment/story-comment-list/student-story-comments.response';
import { pickBy } from 'lodash';

@Component({
  selector: 'app-story-comment-list-student',
  templateUrl: './story-comment-list-student.component.html',
  styleUrls: ['./story-comment-list-student.component.scss'],
})
export class StoryCommentListStudentComponent implements OnInit {
  storyComments: Array<StoryComment>;
  meta: StudentStoryCommentsMeta;

  storyCommentsLoaded = false;
  isLoadingMore = false;
  canLoadMore = false;
  @Input() grade: string;

  constructor(private storyCommentService: StoryCommentStudentService) {}

  ngOnInit() {
    this.loadStoryComments();
  }

  loadStoryComments() {
    const params = pickBy({ grade: this.grade });
    this.storyCommentService.loadStudentStoryComments(params).subscribe(
      (response) => {
        this.storyComments = response.story_comments;
        this.meta = response.meta;
        this.storyCommentsLoaded = true;
        this.canLoadMore = !!this.meta.next_id;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  loadMoreStoryComments() {
    this.isLoadingMore = true;
    const params = { ...this.meta, ...pickBy({ grade: this.grade }) };
    this.storyCommentService.loadStudentStoryComments(params).subscribe(
      (response) => {
        this.storyComments.push(...response.story_comments);
        this.meta = response.meta;
        this.isLoadingMore = false;
        this.canLoadMore = !!this.meta.next_id;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }
}
