import { isOldAndroid, isMobileApp } from '@functions/browser';
import { environment } from '../../../environments/environment';

export class UploadFile {
  readonly IMAGE_TYPES = ['jpg', 'jpeg', 'gif', 'png'];

  readonly AUDIO_TYPES = [
    'mp3',
    'm4a', // 'wma' type can not played by html5 audio tag.
  ];

  readonly VIDEO_TYPES = [
    'mp4', // '3gp' and 'avi' types can not played by html5 video tag;
    // NOTE: mp4 + mpeg4 files with MPEG-4 codec can not played :-s
    // remove 'mov' out of preview types because it could have HEVC codec.
  ];

  readonly PREVIEW_TYPES = [...this.IMAGE_TYPES, ...this.AUDIO_TYPES, ...this.VIDEO_TYPES];

  id: number;
  uuid: string;
  create_user_id: number;
  file_name: string;
  file_extension: string;
  caption?: string;
  thumbnail: string;
  created_at: string;
  updated_at: string;

  isImage(): boolean {
    return this.IMAGE_TYPES.includes(this.file_extension);
  }

  isAudio(): boolean {
    return this.AUDIO_TYPES.includes(this.file_extension);
  }

  isVideo(): boolean {
    return this.VIDEO_TYPES.includes(this.file_extension);
  }

  isPdf(): boolean {
    return this.file_extension === 'pdf';
  }

  canPreview(): boolean {
    return this.PREVIEW_TYPES.includes(this.file_extension) && !(isOldAndroid() && isMobileApp());
  }

  downloadLink(): string {
    return `${environment.apiEndpoint}/upload_files/attachment?uuid=${this.uuid}`;
  }

  previewLink(): string {
    return `${environment.apiEndpoint}/upload_files/attachment?uuid=${this.uuid}&preview=${this.file_extension}`;
  }
}
