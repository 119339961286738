import { Injectable } from '@angular/core';
import { studentNavigationConfigs } from '@services/navigation/student-navigation-configs';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NavigationService } from './navigation.service';

@Injectable()
export class StudentNavigationService extends NavigationService {
  navigationConfigs = studentNavigationConfigs;

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }
}
