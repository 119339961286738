<div class="upload-files-progress">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <h2 class="heading-text">アップロード中</h2>
        </div>

        <div class="modal-content has-footer">
          <div class="content-inner">
            <div class="progress-bar">
              <app-progress-bar [progress]="progress"></app-progress-bar>
              <span>アップロードしています...{{ progress }}%</span>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="modal-actions is-right-actions">
            <button
              class="spen-mod-btn is-default js-close-modal-trigger"
              (click)="cancelUploadingFiles()"
              [disabled]="this.finishedUpload()"
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
