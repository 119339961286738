import { environment } from '@environments/environment';

const apiEndpointV2 = environment.apiEndpointV2;

export const studentNavigationConfigs = {
  // H-18: graduation message list => H-19: graduation message detail
  1: {
    path: '/graduation-messages/student/:id',
    aliasId: 'id',
    listText: '卒業メッセージ',
    urlAPI: ({}) => `${apiEndpointV2}/student/graduation_messages/navigate`,
  },
  // H-6: graduation message delivery notification list => H-19: graduation message detail
  2: {
    path: '/graduation-messages/student/:id',
    aliasId: 'id',
    listText: '卒業メッセージ',
    urlAPI: ({}) => `${apiEndpointV2}/student/graduation_messages/navigate`,
  },
};
