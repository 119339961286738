import { WorkTemplateQuestion } from './work-template-question';

export class WorkTemplate {
  id: number;
  title: string;
  create_user_id?: number;
  create_user_name?: string;
  used_count?: number;
  master_id?: number;
  work_template_questions_attributes?: Array<WorkTemplateQuestion>;
  work_template_questions: Array<WorkTemplateQuestion>;
  questions?: Array<WorkTemplateQuestion>; // v2
  created_at: string;
  updated_at: string;
  isEdit?: boolean;
  isRecycle?: boolean;
  is_compass_template: boolean;
}
