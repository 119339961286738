import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkService } from '../../work.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { RouterService } from '@services/router.service';
import { LayoutService } from '@components/layout/layout.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { environment } from '@environments/environment';
import { TeacherDeliveryWorkDetailResponse } from './teacher-delivery-work-detail-response';
import { TeacherDeliveryActivityRecords } from './teacher-delivery-activity-records-response';
import { currentLocaleDate } from '@functions/date-formatter';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { pick, isEqual, find, omit } from 'lodash';
import { TeacherDeliveryWorkSpaceStudent } from './teacher-delivery-work-space-student';
import { User } from '@models/user';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';
import { AuthUserService } from '@services/auth-user.service';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';
import { ControlLinkService } from '@services/control-link/control-link.service';

@Component({
  selector: 'app-teacher-work-detail',
  templateUrl: './teacher-work-detail.component.html',
  styleUrls: ['./teacher-work-detail.component.scss'],
})
export class TeacherWorkDetailComponent implements OnInit {
  private apiEndPointV2 = environment.apiEndpointV2;
  currentUser: User;
  workId: number;
  errMsg: string;
  isShowNotAnsweredStudent = false;
  form: UntypedFormGroup;
  workDetail: TeacherDeliveryWorkDetailResponse;
  activityRecords: TeacherDeliveryActivityRecords;
  currentSpace = null;
  currentSortParam = null;
  workLoaded = false;
  isShowMoreWorkDetailInfo = false;
  isShowSortPanelActions = false;
  isShowDropdownActions = false;
  cancelSearchDisabled = false;
  isDeletingWorkDetail = false;
  isShowAnswerPreviewModal = false;
  spaces = {
    single_length: 1,
    limit_length: 2,
  };
  spaceType: string;
  studentLoadType = 'init';
  private iconSort = {
    none: 'sort-column none',
    loader: 'sort-loader fa fa-spinner fa-spin',
    up: 'sort-column fa fa-caret-up',
    down: 'sort-column fa fa-caret-down',
  };
  private readonly allowedParamKeys = ['page', 'sort_key', 'sort_order', 'space_id'];
  sortParams = [
    { sort_key: 'class_name', sort_text: '校種学年組番（降順）', sort_order: 'desc' },
    { sort_key: 'class_name', sort_text: '校種学年組番（昇順）', sort_order: 'asc' },
    { sort_key: 'answer_date', sort_text: '作成日時（降順）', sort_order: 'desc' },
    { sort_key: 'answer_date', sort_text: '作成日時（昇順）', sort_order: 'asc' },
    { sort_key: 'update_answer_date', sort_text: '更新日時（降順）', sort_order: 'desc' },
    { sort_key: 'update_answer_date', sort_text: '更新日時（昇順）', sort_order: 'asc' },
  ];
  private queryParams = {};
  pages = {
    current_page: 1,
    next_page: null,
    per_page: 1,
    prev_page: null,
    total_count: 1,
    total_pages: 1,
  };

  isShowingModal = false;
  modalSize = 600;
  wordCloudServerError: 'happening' | 'not happening';
  gotWordCloudResponse: 'not yet' | 'have gotten' = 'not yet';
  gotWordCloudDocumentId: 'success' | 'failed';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private workService: WorkService,
    public layoutService: LayoutService,
    private router: Router,
    private analytic4Service: GoogleAnalytics4Service,
    private routerService: RouterService,
    private confirmDialogService: ConfirmDialogService,
    private toastService: ToastService,
    private navigationService: TeacherNavigationService,
    private authUserService: AuthUserService,
    private wordCloudService: WordCloudService,
    private controlLinkService: ControlLinkService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.workId = +this.route.snapshot.paramMap.get('id');
      this.queryParams = pick(params, this.allowedParamKeys);
      if (!this.workLoaded) {
        this.loadWorkDetail(this.workId);
      } else {
        this.loadActivityRecords(this.currentSpace.id, this.workId, this.queryParams);
      }
    });
    this.currentUser = this.authUserService.retrieve();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      space: [this.currentSpace],
      sort_param: [this.currentSortParam],
    });
  }

  get displaySpaces() {
    switch (this.spaceType) {
      case 'single':
      case 'all':
        return this.workDetail.spaces;
      case 'limit':
        return this.workDetail.spaces.slice(0, this.spaces.limit_length);
    }
  }

  private setSpaceType() {
    const length = this.workDetail.spaces.length;
    if (length <= this.spaces.single_length) {
      this.spaceType = 'single';
    } else if (length > this.spaces.limit_length) {
      this.spaceType = 'limit';
    } else {
      this.spaceType = 'all';
    }
  }

  private loadWorkDetail(workId) {
    this.workService.getTeacherDeliveryWorkDetails(workId).subscribe(
      (response) => {
        this.workDetail = response;
        this.workLoaded = true;
        this.currentSpace = this.workDetail.spaces.find((space) => space.id === +this.queryParams['space_id']) || this.workDetail.spaces[0];
        this.currentSortParam =
          find(this.sortParams, {
            sort_key: this.queryParams['sort_key'],
            sort_order: this.queryParams['sort_order'],
          }) || this.sortParams[0];
        this.buildForm();
        this.setSpaceType();
        this.checkDisableCancelSearchButton();
        this.loadActivityRecords(this.currentSpace.id, this.workId, this.buildSortParams(this.queryParams['page']));
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  private loadActivityRecords(spaceId, workId, params = {}) {
    this.workService.getTeacherDeliveryActivityRecords(spaceId, workId, params).subscribe(
      (response) => {
        this.activityRecords = response.activity_records;
        Object.assign(this.pages, response.meta);
        this.studentLoadType = 'loaded';
      },
      (error) => {
        this.studentLoadType = 'loaded';
        this.errMsg = <any>error;
      },
    );
  }

  isScheduled(delivered_at) {
    const currentTime = currentLocaleDate();
    const deliveredTime = new Date(delivered_at);
    return currentTime < deliveredTime;
  }

  getActivityRecordType(student) {
    if (!student.viewer_permission) {
      return 'record-permission';
    } else if (!student.answer_id) {
      return 'record-not-answer-yet';
    } else {
      return 'record-answered';
    }
  }

  private buildSortParams(currentPage: number = 1) {
    return {
      page: currentPage,
      sort_key: this.currentSortParam.sort_key,
      sort_order: this.currentSortParam.sort_order,
      space_id: this.currentSpace.id,
    };
  }

  checkDisableCancelSearchButton() {
    this.cancelSearchDisabled = this.form.value.sort_param === this.sortParams[0] && this.form.value.space === this.workDetail.spaces[0];
  }

  getSortStatus(sortKey: string) {
    if (sortKey !== this.currentSortParam.sort_key) {
      return this.iconSort.none;
    }
    switch (this.studentLoadType) {
      case 'init':
        return this.iconSort.down;
      case 'sorting':
        return this.iconSort.loader;
      case 'loaded':
        return this.currentSortParam.sort_order === 'asc' ? this.iconSort.up : this.iconSort.down;
    }
  }

  private setCurrentStateFromFormValue() {
    this.currentSortParam = this.form.value.sort_param;
    this.currentSpace = this.form.value.space;
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  get studentLoaded() {
    return this.studentLoadType === 'loaded' || this.studentLoadType === 'sorting';
  }

  get canEditOrDeleteWorkDetail() {
    return this.workDetail.can_be_edited || this.workDetail.can_be_destroyed;
  }

  getClassName(student: TeacherDeliveryWorkSpaceStudent) {
    return student.className ? `${student.className}${student.attendanceNumber}番` : '-';
  }

  onClickSortColumn(sortKey: string) {
    if (this.studentLoadType === 'sorting') {
      return;
    }
    this.studentLoadType = 'sorting';
    const isSameSortKey = sortKey === this.currentSortParam.sort_key;
    const order = isSameSortKey ? (this.currentSortParam.sort_order === 'asc' ? 'desc' : 'asc') : 'desc';
    this.currentSortParam = find(this.sortParams, { sort_key: sortKey, sort_order: order });
    this.router.navigate(['.'], {
      queryParams: this.buildSortParams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  onChangeSpace(isTabletDownView: boolean) {
    if (isTabletDownView) {
      this.checkDisableCancelSearchButton();
      return;
    }
    this.gotWordCloudResponse = 'not yet';
    this.setCurrentStateFromFormValue();
    this.studentLoadType = 'changed';
    this.router.navigate(['.'], {
      queryParams: this.buildSortParams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  onChangeParam() {
    this.checkDisableCancelSearchButton();
  }

  onSubmitFilter() {
    const currentState = { space: this.currentSpace, sort_param: this.currentSortParam };
    this.isShowSortPanelActions = false;
    this.checkDisableCancelSearchButton();
    if (isEqual(currentState, this.form.value)) {
      return;
    }
    window.scrollTo(0, 0);
    this.setCurrentStateFromFormValue();
    this.studentLoadType = 'sorting';
    this.router.navigate(['.'], {
      queryParams: this.buildSortParams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  removeFilter() {
    window.scrollTo(0, 0);
    this.form.patchValue({ space: this.workDetail.spaces[0], sort_param: this.sortParams[0] });
    this.onSubmitFilter();
  }

  pageChanged(page: number) {
    this.studentLoadType = 'changed';
    this.router.navigate(['.'], { queryParams: { page: page }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  exportAnswers() {
    this.analytic4Service.sendEvent('課題', '詳細画面', 'CSV出力');
    const url = `${this.apiEndPointV2}/teacher/delivered_works/${this.workId}/spaces/${this.currentSpace.id}/activity_records/export`;
    window.open(url, '_blank');
  }

  onClickSpaceName(spaceId: number) {
    this.router.navigateByUrl(`spaces/teacher/${spaceId}?tab=activities`);
  }

  onClickStudent(student: TeacherDeliveryWorkSpaceStudent, event: KeyboardEvent) {
    if (!student.viewer_permission) {
      return;
    }
    event.stopPropagation();
    this.controlLinkService.open(`/students/teacher/${student.id}?tab=actions`, event);
  }

  onClickStudentFromModal(student: TeacherDeliveryWorkSpaceStudent) {
    if (!student.viewer_permission) {
      return;
    }
    event.stopPropagation();
    this.router.navigateByUrl(`/students/teacher/${student.id}?tab=actions`);
  }

  onClickActivityRecord(student: TeacherDeliveryWorkSpaceStudent, event: KeyboardEvent) {
    if (this.getActivityRecordType(student) === 'record-answered') {
      const navigatorData = Object.assign(this.queryParams, { space_id: this.currentSpace.id }, { work_id: this.workId });

      this.navigationService.registerNavigator({ screenId: 1, filterData: omit(navigatorData, 'page') });
      this.controlLinkService.open(`/activity-records/teacher/${student.answer_id}`, event, {
        state: { navigationFrom: '/works/teacher/delivery' },
      });
    }
  }

  onClickAlbum() {
    this.router.navigateByUrl(`/albums/teacher/delivery/${this.workDetail.album_id}`);
  }

  showNotAnswerStudent() {
    this.isShowNotAnsweredStudent = true;
  }

  eventCancelModal(student) {
    this.isShowNotAnsweredStudent = false;
    if (student) {
      this.onClickStudentFromModal(student);
    }
  }

  toggleAnswerPreviewModal(isOpen: boolean) {
    this.isShowAnswerPreviewModal = isOpen;
  }

  onClickCopyAndDistribute() {
    this.router.navigateByUrl(`/works/new?clone_work_id=${this.workId}`);
  }

  onConfirmEditAndDelete(): void {
    if (this.workDetail.can_be_edited) {
      this.router.navigateByUrl(`/works/${this.workId}/edit`);
    } else {
      const confirmOptions = {
        title: '確認',
        content: '一度削除した課題は復元することができません。本当に課題を削除しますか？<br>提出済みの回答は生徒のマイスペースに残ります。',
        acceptButton: '削除する',
        cancelButton: 'キャンセル',
      };
      this.confirmDialogService.showConfirm(confirmOptions).subscribe((accept) => {
        if (accept) {
          this.isDeletingWorkDetail = true;
          this.onDelete();
        }
      });
    }
  }

  onDelete(): void {
    this.workService.deleteTeacherWork(this.workId).subscribe(
      () => this.deletionSucceeded(),
      (error) => {
        this.errMsg = <any>error;
        this.isDeletingWorkDetail = false;
      },
    );
  }

  onBack() {
    this.router.navigateByUrl(`works/teacher/delivery`, { replaceUrl: true });
  }

  get possibleShowModal(): boolean {
    return window.innerWidth >= this.modalSize && window.innerHeight >= this.modalSize;
  }

  switchToShowWordCloud() {
    this.isShowingModal = true;
    this.analytic4Service.sendEvent('課題', '詳細画面', '概況を見る');
    if (this.gotWordCloudResponse === 'not yet') {
      this.wordCloudService.makeTitleAboutWork(this.workDetail.title);
      this.sendWorkParamsToWordCloud();
    }
  }

  sendWorkParamsToWordCloud() {
    this.wordCloudService.getWordCloudDocumentId(this.buildWordCloudParams()).subscribe(
      (response) => {
        const document_id = response['document_id'] as string;
        switch (document_id) {
          case null:
            this.wordCloudService.setFetchErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          case 'over_count':
            this.wordCloudService.setOverCountErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          default:
            this.wordCloudService.registerWordCloudUrl(document_id);
            this.gotWordCloudDocumentId = 'success';
        }
        this.gotWordCloudResponse = 'have gotten';
      },
      (error) => {
        this.wordCloudService.setServerErrorMessage();
        this.wordCloudServerError = 'happening';
        this.gotWordCloudResponse = 'have gotten';
        this.gotWordCloudDocumentId = 'failed';
      },
    );
  }

  onCloseWordCloud() {
    this.isShowingModal = false;
    if (this.wordCloudServerError === 'happening') {
      this.gotWordCloudResponse = 'not yet';
      this.wordCloudServerError = 'not happening';
    }
  }

  private buildWordCloudParams() {
    return {
      type: 'work',
      workId: this.workId,
      spaceId: this.currentSpace.id,
      title: this.workDetail.title,
    };
  }

  private deletionSucceeded(): void {
    this.onBack();
    this.toastService.showToast('削除しました');
    this.isDeletingWorkDetail = false;
  }
}
