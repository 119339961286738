<div class="stu-create-document" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <a (click)="onBack()"> <i class="fa fa-chevron-left"></i> </a>{{ pageTitle }}
    </h1>
  </div>

  <div class="btn-show-history">
    <a class="spen-util-link" (click)="showModalActivities()">
      <span>過去の活動記録を見る</span>
    </a>
  </div>

  <form class="form" [formGroup]="form" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="line-separator"></div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-12">
            <p class="label-input">タイトル</p>
          </div>
          <div class="col-md-9 col-sm-9 col-xs-12">
            <input
              class="spen-mod-input-text"
              type="text"
              name="title"
              [appPlaceholder]="'入力してください'"
              formControlName="title"
              [appFocus]="focusEvent"
            />
            <div *ngIf="formErrors['title']" class="spen-mod-error-content">
              {{ formErrors['title'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">本文</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <label class="spen-mod-select">
              <select
                class="select templateBox"
                name="template_id"
                [class.unselect-option]="isUnselectTemplate"
                formControlName="template_id"
                (change)="onChangeTemplate()"
              >
                <option class="is-all-point select-option" [ngValue]="null">テンプレートを選択</option>
                <option class="is-part-point select-option" *ngFor="let template of templates" [ngValue]="template.id">
                  {{ template.title }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
          <div class="col-md-3 col-xs-12"></div>
          <div class="col-md-9 col-xs-12">
            <textarea name="content" [appPlaceholder]="'入力してください'" formControlName="content"> </textarea>
            <div *ngIf="formErrors.content" class="spen-mod-error-content">
              {{ formErrors['content'].join(' ') }}
            </div>
            <p class="text-num pull-right">
              文字数 <span class="spen-ly-form-document is-num-character">{{ lengthOfContent }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <p class="label-input">添付ファイル</p>
          </div>
          <app-file-uploader
            class="col-sm-12 col-xs-12"
            [attachedFiles]="document.upload_files"
            (eventSelectFile)="onSelectFile($event)"
            (uploadFilesStatus)="onUploadFilesStatus($event)"
          >
          </app-file-uploader>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">カテゴリー</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <app-categories-selection formControlName="category_id" [categories]="categories"> </app-categories-selection>
          </div>
        </div>
      </div>

      <div class="block-marks">
        <app-marks-selector [formObject]="document" [marks]="marks" (eventChangeMark)="onChangeMark()"></app-marks-selector>
      </div>

      <div class="form-group albums" *ngIf="document.albums_activity_records?.length > 0">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">アルバム</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <p class="album" *ngFor="let albums_activity_record of document.albums_activity_records">
              ・ {{ albums_activity_record.album_name }} <a (click)="deleteDocumentFromAlbum(albums_activity_record)">取り出す</a>
            </p>
          </div>
        </div>
      </div>
      <div class="submit">
        <button
          type="submit"
          (click)="onSubmit()"
          class="spen-mod-btn is-primary"
          [disabled]="this.isSubmitting || uploadFilesStatus === 'uploading'"
        >
          {{ submitButtonText }}
        </button>
        <button type="button" (click)="onDeleteDocument()" class="spen-mod-btn is-default" *ngIf="document.isExistedDocument()">
          活動記録を削除する
        </button>
        <button type="button" (click)="onBack()" class="spen-mod-btn is-default">戻る</button>
      </div>
    </div>
  </form>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-list-activity-modal
  (eventShowActivity)="eventShowActivity($event)"
  (eventSelectActivityModal)="eventSelectActivityModal($event)"
  *ngIf="showListActivityModal"
>
</app-list-activity-modal>

<app-activity-detail-modal [activityId]="activityId" (eventForBackAction)="eventForBackAction($event)" *ngIf="showActivityDetailModal">
</app-activity-detail-modal>

<app-student-mana-vision-detail *ngIf="!!currentManaVision" [manaVision]="currentManaVision" (closeEvent)="hideManaVisionModal()">
</app-student-mana-vision-detail>
