<form [formGroup]="form" (ngSubmit)="onSubmit()" class="detail-form">
  <div class="form-horizontal">
    <div class="form-group">
      <div class="row">
        <div class="logo" *ngIf="isEditForm()">
          <img [src]="currentUser.avatar" alt="Avatar" class="img-responsive" />
        </div>
        <div class="form-detail">
          <div class="user-info" *ngIf="isEditForm()">
            <div class="info">
              <p class="date">{{ assessment?.created_at }}</p>
              <p>{{ currentUser.name }}さん</p>
            </div>
          </div>
          <textarea
            #textArea
            [appPlaceholder]="'活動記録を読んで、どのようなことを思いましたか？考えましたか？'"
            formControlName="content"
            appAutoExpand
          >
          </textarea>
          <div *ngIf="formErrors['content']" class="spen-mod-error-content">
            {{ formErrors['content'].join(' ') }}
          </div>
          <ng-container *ngIf="assessment?.is_reviewing && !!assessment?.review_reason">
            <p class="review-title">見直し理由</p>
            <div class="review-reason preserve-line-break">{{ assessment.review_reason }}</div>
          </ng-container>
        </div>
        <div class="buttons">
          <button type="submit" class="spen-mod-btn is-primary" [disabled]="isSubmitting || isContentInvalid()">
            {{ isEditForm() ? '編集を完了する' : 'コメントする' }}
          </button>
          <button type="button" class="spen-mod-btn" *ngIf="isEditForm()" (click)="cancelEdit()">キャンセル</button>
          <div class="delete-comment" *ngIf="isEditForm()">
            <span class="spen-util-link" (click)="deleteAssessment()"><i class="fa fa-trash-o"></i>削除する</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
