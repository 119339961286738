import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';

import { StoryFileUploaderComponent } from './story-file-uploader.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { SharedModule } from '@components/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [StoryFileUploaderComponent],
  exports: [StoryFileUploaderComponent],
  imports: [CommonModule, FileUploadModule, SharedDirectivesModule, SharedPipesModule, SharedModule, FormsModule, ReactiveFormsModule],
})
export class StoryFileUploaderModule {}
