<div class="spen-ly-modal tea-filter-album" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <h2 class="heading-text">検索</h2>
      </div>

      <div class="modal-content has-footer">
        <div class="content-inner">
          <div class="filter-block">
            <div class="filter-inner">
              <form [formGroup]="form">
                <input
                  type="text"
                  class="spen-mod-input-text"
                  placeholder="キーワードを入力してください"
                  [appFocus]="focusEvent"
                  formControlName="searchKeyword"
                  (keydown.enter)="onSubmit()"
                />
                <div *ngIf="formErrors['searchKeyword']" class="spen-mod-error-content">
                  {{ formErrors['searchKeyword'].join(' ') }}
                </div>
              </form>
            </div>
            <p class="filter-description">アルバム名が検索対象です。</p>
          </div>
        </div>
      </div>

      <div class="modal-footer album-modal-footer">
        <div class="modal-actions is-right-actions album-modal-action">
          <a class="spen-mod-btn is-default js-close-modal-trigger" (click)="onCancel()">キャンセル</a>
          <a class="spen-mod-btn is-primary" (click)="onSubmit()">検索する</a>
        </div>
      </div>
    </div>
  </div>
</div>
