import { Component, OnInit, Output, EventEmitter, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { MultipleFilterService } from '@services/filter/multiple-filter.service';
import { Category } from '@models/category';
import { Mark } from '@models/mark';
import { CategoriesService } from '@services/categories/categories.service';
import { MarksService } from '@services/marks/marks.service';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthUserService } from '@services/auth-user.service';
import { checkPresentString } from '@functions/check-string';
import { pickBy } from 'lodash';

@Component({
  selector: 'app-student-filter-by-cross-review',
  templateUrl: './student-filter-by-cross-review.component.html',
  styleUrls: ['./student-filter-by-cross-review.component.scss'],
})
export class StudentFilterByCrossReviewComponent implements OnInit, OnDestroy {
  @Output() submitFilter = new EventEmitter<{}>();

  categories: Array<Category> = [];
  marks: Array<Mark> = [];
  form: UntypedFormGroup;

  errMsg: string;
  filterData = {};
  selectedMarkIds = [];

  categoriesLoaded = false;
  marksLoaded = false;

  @ViewChild(MarksSelectorComponent) marksSelector: MarksSelectorComponent;

  constructor(
    private renderer: Renderer2,
    private categoryService: CategoriesService,
    private marksService: MarksService,
    private filterService: MultipleFilterService,
    private formBuilder: UntypedFormBuilder,
    public authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.filterData = this.filterService.getFilterData() || {};
    this.initForm();
    this.loadCategories();
    this.loadMarks();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        if (this.filterData['mark_ids']) {
          if (!(this.filterData['mark_ids'] instanceof Array)) {
            this.filterData['mark_ids'] = [this.filterData['mark_ids']];
          }
          this.selectedMarkIds = this.filterData['mark_ids'];
        }
        this.marksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  dataLoaded() {
    return this.categoriesLoaded && this.marksLoaded;
  }

  closeFilter() {
    this.filterService.isShowingFilter = false;
  }

  onSubmit() {
    const params = pickBy(Object.assign({}, this.form.value));

    params['mark_ids'] = this.marksSelector.selectedMarks.filter((mark) => !!mark.checked).map((mark) => mark.id);

    this.filterService.setFilter(params);
    this.submitFilter.emit(params);
    this.filterService.isShowingFilter = false;
  }

  async loadCategories() {
    const categories = await this.categoryService.loadCategories();
    if (!!categories) {
      this.categories = categories;
      this.categoriesLoaded = true;
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      keyword: this.filterData['keyword'],
      category_id: this.filterData['category_id'],
      activity_type: this.filterData['activity_type'],
      public_status: this.filterData['public_status'],
      comment_status: this.filterData['comment_status'],
    });
  }
}
