import { Component, Input } from '@angular/core';
import { Assessment } from '@components/assessment/assessment';

@Component({
  selector: 'app-student-cross-assessments',
  templateUrl: './student-cross-assessments.component.html',
  styleUrls: ['./student-cross-assessments.component.scss'],
})
export class StudentCrossAssessmentsComponent {
  @Input() crossAssessments: Array<Assessment>;

  constructor() {}
}
