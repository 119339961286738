import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Space } from '@components/space/space';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherSpacePickerService } from './teacher-space-picker.service';
import { NextPageParams } from './teacher-selectable-spaces-response';

@Component({
  selector: 'app-teacher-space-picker',
  templateUrl: './teacher-space-picker.component.html',
  styleUrls: ['./teacher-space-picker.component.scss'],
})
export class TeacherSpacePickerComponent implements OnInit, OnDestroy {
  @Output() submitEvent: EventEmitter<Space> = new EventEmitter<Space>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedSpace: Space;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  searchedResults: Array<Space> = new Array<Space>();
  newSelectSpace: Space;
  nextPageParams: NextPageParams;
  isShowError = false;
  isLoading = false;
  errMsg: string;

  constructor(
    private renderer: Renderer2,
    public layoutService: LayoutService,
    private spacePickerService: TeacherSpacePickerService,
  ) {}

  ngOnInit() {
    this.newSelectSpace = this.selectedSpace;
    this.onSearch();
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.nextPageParams = null;
    this.searchedResults = [];
    if (this.isShowError) {
      this.isShowError = false;
    }

    const keyword = !!this.searchInput ? this.searchInput.nativeElement.value.trim() : '';

    this.spacePickerService.getTeacherSelectableSpaces({ keyword: keyword }).subscribe(
      (response) => {
        this.isLoading = false;
        this.searchedResults = response.spaces;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;

    // リクエスト用のキー名に変更する
    const params = {
      ...this.nextPageParams,
      page: this.nextPageParams.next_page,
    };
    delete params.next_page;

    this.spacePickerService.getTeacherSelectableSpaces(params).subscribe(
      (response) => {
        this.searchedResults.push(...response.spaces);
        this.isLoading = false;
        this.nextPageParams = null;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onClose() {
    this.closeEvent.emit();
  }

  onSubmit() {
    if (!this.newSelectSpace.id) {
      this.isShowError = true;
      return;
    }
    this.submitEvent.emit(this.newSelectSpace);
  }

  selectSpace(space: Space) {
    if (this.isShowError) {
      this.isShowError = false;
    }
    this.newSelectSpace = space;
  }
}
