<form class="comment" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-content">
    <img [src]="currentUser.avatar" alt="Avatar" />
    <div class="time-comment">
      <div *ngIf="isShowCommentInfo" class="teacher-name">
        <p class="truncate-text">{{ currentUser.name }}</p>
        <p>先生</p>
      </div>
      <p *ngIf="isShowCommentInfo" class="story-comment-time">{{ storyComment.created_at }}</p>
      <div class="text-comment">
        <textarea
          class="spen-mod-input-text"
          #textArea
          [appPlaceholder]="'先生からのひとことを入力'"
          formControlName="content"
          appAutoExpand
        ></textarea>
        <div *ngIf="formErrors['content']" class="spen-mod-error-content">
          {{ formErrors['content'].join(' ') }}
        </div>
      </div>
    </div>
  </div>

  <div class="list-button">
    <button type="submit" class="spen-mod-btn is-primary" [disabled]="isSubmitting || isContentInvalid()">
      {{ isEditForm() ? '保存する' : '投稿する' }}
    </button>
    <button type="button" class="spen-mod-btn" [disabled]="isSubmitting" *ngIf="isEditForm()" (click)="cancelEdit()">キャンセル</button>
    <span>
      <a class="delete-comment spen-util-link" *ngIf="isEditForm()" (click)="deleteStoryComment()">
        <i class="fa fa-trash-o"></i>削除する
      </a>
    </span>
  </div>
</form>
