<div class="tea-create-album" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps" [class.list-steps-edit]="creatorService.albumId">
      <app-steps [steps]="creatorService.steps" [currentStep]="1"></app-steps>
    </div>
  </div>

  <form (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="form-group form-group-step2">
        <div class="row">
          <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
            <label class="label">配信先スペース</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <div class="col-md-9 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
            <div class="space-choosen">
              <div class="space-info" *ngIf="creatorService.space.id; else emptySpace">
                <span class="space-color" [style.background-color]="creatorService.space.color_code"></span>
                <span class="truncate-text">{{ creatorService.space.name }}</span>
              </div>
              <span class="spen-util-link" (click)="chooseSpace()" data-cy="select-space-link">スペースを選択する</span>
            </div>
            <ng-template #emptySpace>
              <span class="empty-space">未選択</span>
            </ng-template>
          </div>
          <div
            class="col-md-9 col-md-offset-3 col-xs-12"
            *ngIf="formErrors['space_id']"
            [ngClass]="{ 'col-sm-9 col-sm-offset-3': !creatorService.isTabletAndDown() }"
          >
            <div class="spen-mod-error-content error-popup">
              {{ formErrors['space_id'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="step2-button-submit">
        <button type="button" class="spen-mod-btn" (click)="goBack()">戻る</button>
        <button type="submit" class="spen-mod-btn is-primary" data-cy="submit-button">確認へ</button>
      </div>
    </div>
  </form>
</div>
<app-teacher-space-picker
  *ngIf="isShowSearchModal"
  (closeEvent)="closeSearch()"
  (submitEvent)="onSelectSpace($event)"
  [selectedSpace]="creatorService.space"
>
</app-teacher-space-picker>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
