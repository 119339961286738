<div class="stu-album-detail" *ngIf="dataLoaded(); else loadingIcon" [hidden]="showActivityAlbumModal || showSelectActivityModal">
  <div class="pre-page">
    <h1 class="heading-text">
      <a (click)="onBack()"> <i class="fa fa-chevron-left"></i> </a>アルバム詳細
    </h1>
  </div>
  <div class="block-info" *ngIf="isAlbumCreatedByStudent">
    <div class="row">
      <div class="col-md-9 col-xs-9 block-time">
        <p>作成日時：{{ album.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
        <p *ngIf="!!album.updated_at">最終更新：{{ album.updated_at | date: 'yyyy/MM/dd HH:mm' }}</p>
      </div>
      <div class="col-md-3 col-xs-3">
        <div class="block-edit pull-right" [routerLink]="['/albums/student', album.id, 'edit']" *ngIf="canEditAlbum">
          <i class="fa fa-pencil"></i>
          <a class="edit">編集</a>
        </div>
      </div>
    </div>
  </div>

  <div class="block-standard">
    <div class="header-title">
      <img class="icon" [class.object-fit-cover]="this.album.image" src="{{ iconAlbumUrl }}" />
      <div class="title">{{ album.title }}</div>
    </div>

    <div class="block-user" *ngIf="isAlbumCreatedByTeacher">
      <div class="user-info">
        <img alt="avatar" src="{{ album.user?.avatar }}" />
        <div class="text-info">
          <p>{{ album.created_at }} 配信</p>
          <p>{{ album.user?.name }} 先生</p>
        </div>
      </div>
      <div class="box-comment" *ngIf="album.comment">
        <span class="tip-up"></span>
        <span class="tip-up-large"></span>
        <p appConvertLink>{{ album.comment }}</p>
      </div>
    </div>

    <div class="block-content" *ngIf="isShowAlbumGoal">
      <div class="label-input">目標</div>
      <p appConvertLink>{{ albumGoalOutline['goal'] }}</p>
      <div class="label-input second">概要</div>
      <p appConvertLink>{{ albumGoalOutline['outline'] }}</p>
    </div>

    <div class="add-info-album" *ngIf="isAlbumCreatedByTeacher">
      <button class="spen-mod-btn btn-add-info" *ngIf="!isShowAlbumGoal; else editInfo" (click)="goToAddGoalAlbumPage()">
        目標・概要を入力する
      </button>
      <ng-template #editInfo>
        <div class="edit-info">
          <span (click)="goToAddGoalAlbumPage()">
            <i class="fa fa-pencil"></i>
            <a>編集</a>
          </span>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="btn-actions-redirect">
    <div class="btn-create-activity">
      <button class="spen-mod-btn prev-button" (click)="createNewActivity()">活動記録の新規作成</button>
    </div>
    <div class="btn-show-work" *ngIf="isShowButtonRedirectWorkTab()">
      <button class="spen-mod-btn prev-button" (click)="showWorksAlbum()">未回答の課題に取り組む</button>
    </div>
  </div>
  <div class="activities">
    <div class="list-amount">{{ pages.total_count }}件</div>
    <ul class="space-flex-list">
      <li
        class="list-item"
        [routerLink]="['/activity-records/student/', activityRecord.id]"
        *ngFor="
          let activityRecord of activities
            | paginate
              : {
                  itemsPerPage: this.pages.per_page,
                  currentPage: this.pages.current_page,
                  totalItems: this.pages.total_count,
                };
          first as isFirst
        "
        [class.is-first]="isFirst"
      >
        <app-student-activity-item [activityRecord]="activityRecord"></app-student-activity-item>
      </li>
      <li class="list-item">
        <div class="add-new" (click)="addActivityToAlbum()">
          <i class="fa fa-plus-circle"></i><a class="spen-util-link">既存の活動記録を追加</a>
        </div>
      </li>
    </ul>
    <div class="block-footer">
      <div class="pagination" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-album-select-activity-modal
  [albumId]="album.id"
  (eventShowActivity)="eventShowActivity($event)"
  (eventSelectActivityModal)="eventSelectActivityModal($event)"
  *ngIf="showSelectActivityModal"
>
</app-album-select-activity-modal>

<app-activity-album-modal
  [albumId]="album.id"
  [activityId]="activityId"
  (eventActivityAlbumModal)="eventActivityAlbumModal($event)"
  *ngIf="showActivityAlbumModal"
>
</app-activity-album-modal>
