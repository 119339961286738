import { Component, OnInit, Input } from '@angular/core';

import { TeacherCrossAssessmentService } from './teacher-cross-assessment.service';

import { Assessment } from '@components/assessment/assessment';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { CrossAssessmentsMeta } from './cross-assessments.response';

@Component({
  selector: 'app-cross-assessment-list',
  templateUrl: './cross-assessment-list.component.html',
  styleUrls: ['./cross-assessment-list.component.scss'],
  providers: [TeacherCrossAssessmentService],
})
export class CrossAssessmentListComponent implements OnInit {
  @Input() activityRecord: ActivityRecord;

  crossAssessments: Array<Assessment>;
  meta: CrossAssessmentsMeta;
  edittingReviewAssessment: Assessment;

  crossAssessmentsLoaded = false;
  isLoadingMore = false;

  constructor(private teacherCrossAssessmentService: TeacherCrossAssessmentService) {}

  ngOnInit() {
    this.loadCrossAssessments();
  }

  loadCrossAssessments() {
    this.teacherCrossAssessmentService.loadCrossAssessments(this.activityRecord.id).subscribe(
      (response) => {
        this.crossAssessments = response.cross_assessments;
        this.meta = response.meta;
        this.crossAssessmentsLoaded = true;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  loadMoreCrossAssessments() {
    this.isLoadingMore = true;
    this.teacherCrossAssessmentService.loadCrossAssessments(this.activityRecord.id, this.meta).subscribe(
      (response) => {
        this.crossAssessments.push(...response.cross_assessments);
        this.meta = response.meta;
        this.isLoadingMore = false;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  canRequestReview(assessment: Assessment) {
    return !assessment.is_reviewing && !this.edittingReviewAssessment;
  }

  onRequestReviewAssessment(assessment) {
    this.edittingReviewAssessment = assessment;
  }

  cancelEditAssessment() {
    this.edittingReviewAssessment = null;
  }

  onUpdateOldAssessment(newAssessment, oldAssessment) {
    this.edittingReviewAssessment = null;
    Object.assign(oldAssessment, newAssessment);
  }

  get canLoadMore(): boolean {
    return !!this.meta.next_id;
  }
}
