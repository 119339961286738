import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ActivityRecord } from './activity-record';
import { SpacesPublishResponse } from './activity-record-detail/student/publish-activity-modal/spaces-publish-response';
import { ActivityRecordsItemResponse } from './activity-records-list/teacher/teacher-activity-records-item-response';
import { Document } from '@components/document/document';

@Injectable()
export class ActivityRecordService {
  apiEndpoint = environment.apiEndpoint;
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getActivityRecord(activityRecordId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}`;
    return this.http.get<ActivityRecord>(url);
  }

  getEditActivityRecord(activityRecordId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}/edit`;
    return this.http.get<ActivityRecord>(url);
  }

  getTeacherActivityDetail(activityId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityId}`;
    return this.http.get<ActivityRecord>(url);
  }

  updateReadAssessments(activityRecordId: number, assessmentType: string): Observable<boolean> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}/read_assessments`;
    return this.http.put<boolean>(url, { type: assessmentType });
  }

  updateAnswer(document: Document): Observable<Document> {
    return this.http.put<Document>(`${this.apiEndpoint}/documents/${document.id}`, { document: document });
  }

  updateQuestionnaire(activityRecord: ActivityRecord): Observable<ActivityRecord> {
    return this.http.put<ActivityRecord>(`${this.apiEndpoint}/activity_records/${activityRecord.id}`, { activity_record: activityRecord });
  }

  getListSpacesPublish(activityRecordId: number, params = {}): Observable<SpacesPublishResponse> {
    const url = `${this.apiEndpoint}/student/activity_records/${activityRecordId}/list_spaces_publish`;
    return this.http.post<SpacesPublishResponse>(url, params);
  }

  studentPublishToSpaces(activityRecordId: number, buildParams: Object): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/student/activity_records/${activityRecordId}/publish_to_spaces`;
    return this.http.post<ActivityRecord>(url, { activity_record: { public_activity_records_spaces_attributes: buildParams } });
  }

  getTeacherActivityRecordsList(params: {}): Observable<ActivityRecordsItemResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/search`;
    return this.http.post<ActivityRecordsItemResponse>(url, params);
  }
}
