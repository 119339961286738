import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { Album } from '@models/album';

import { ToastService } from '@services/toast/toast.service';
import { AlbumService } from '@components/album/album.service';
import { AlbumCreatorService } from '../album-creator.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';

import { CoverImageUploaderComponent } from '../cover-image-uploader/cover-image-uploader.component';

@Component({
  selector: 'app-form-album-teacher-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['../form-album-teacher.component.scss'],
})
export class Step3Component implements OnInit, OnDestroy {
  dataLoaded = false;
  saveStatus = 'none';
  errorMsg: string;
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  @ViewChild(CoverImageUploaderComponent) coverImageUploaderComponent: CoverImageUploaderComponent;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private confirmDialogService: ConfirmDialogService,
    private albumService: AlbumService,
    private creatorService: AlbumCreatorService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (this.isEditAlbum && currentStep !== 2) {
      this.goToStep1();
      return;
    }
    if (!this.isEditAlbum && currentStep !== 3) {
      this.goToStep2();
      return;
    }

    this.dataLoaded = true;
    this.routerService.registerBackConfirm(this.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isCompleted) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  onSubmit() {
    this.saveStatus = 'init';
    if (this.creatorService.fileUploader.queue.length > 0) {
      this.coverImageUploaderComponent.uploadAll();
    } else {
      this.saveAlbum();
    }
  }

  onUploadFilesStatus(status) {
    if (status === 'completed') {
      this.saveAlbum();
    } else {
      this.saveStatus = 'none';
    }
  }

  saveAlbum() {
    if (this.coverImageUploaderComponent) {
      const uploadFiles = this.coverImageUploaderComponent.sendUploadedFiles();
      this.creatorService.album['image_attributes'] = uploadFiles[0];
    } else if (this.creatorService.removedAttachFiles.length > 0) {
      this.creatorService.album['image_attributes'] = this.creatorService.removedAttachFiles[0];
    }

    if (this.creatorService.isCopy) {
      this.creatorService.album['image_attributes'] = this.creatorService.attachedFiles[0];
    }

    const trimmedComment = this.creatorService.album.comment.trim();
    if (!trimmedComment) {
      this.creatorService.album.comment = trimmedComment;
    }

    if (!!this.creatorService.albumId) {
      this.updateAlbum(this.creatorService.albumId, this.creatorService.album);
    } else {
      this.createAlbum(this.creatorService.space.id, this.creatorService.album);
    }
  }

  createAlbum(spaceId: number, album: Album) {
    this.saveStatus = 'submitting';
    this.albumService.createTeacherAlbum(spaceId, album).subscribe(
      (response) => {
        this.saveStatus = 'completed';
        this.routerService.resetDataConfirm();
        this.router.navigateByUrl('/albums/teacher/delivery', { replaceUrl: true });
        this.toastService.showToast('配信しました');
      },
      (error) => {
        this.errorMsg = <any>error;
        this.saveStatus = 'none';
      },
    );
  }

  updateAlbum(albumId: number, album: Album) {
    this.saveStatus = 'submitting';
    this.albumService.updateTeacherAlbum(albumId, album).subscribe(
      (response) => {
        this.saveStatus = 'completed';
        this.routerService.resetDataConfirm();
        this.routerService.goBackSpecific(`/albums/teacher/delivery/${this.creatorService.albumId}`);
        this.toastService.showToast('変更しました');
      },
      (error) => {
        this.errorMsg = <any>error;
        this.saveStatus = 'none';
      },
    );
  }

  goBack() {
    if (this.isEditAlbum) {
      this.creatorService.setStep1State();
      this.goToStep1();
      return;
    }
    this.creatorService.setStep2State();
    this.goToStep2();
  }

  goToStep2() {
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  get isCompleted() {
    return this.saveStatus === 'completed';
  }

  get isSavingAlbum() {
    return this.saveStatus !== 'none';
  }

  get isEditAlbum(): boolean {
    return !!this.creatorService.albumId;
  }

  get hasAttachedFiles(): boolean {
    return this.creatorService.attachedFiles.length > 0 || this.creatorService.fileUploader.queue.length > 0;
  }

  get enteredComment(): boolean {
    return !!this.creatorService.album.comment.trim();
  }
}
