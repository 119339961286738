import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MySpaceComponent } from './my-space.component';
import { SharedModule } from '../../shared/components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { ActivityRecordsListComponent } from './activity-records-list/activity-records-list.component';
import { AlbumsListComponent } from './albums-list/albums-list.component';
import { FilterAlbumModalComponent } from './albums-list/filter-album-modal/filter-album-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MySpaceService } from './my-space.service';
import { WorkListComponent } from './work-list/work-list.component';
import { WorkFilterModalComponent } from './work-filter-modal/work-filter-modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MySpaceComponent,
    ActivityRecordsListComponent,
    AlbumsListComponent,
    FilterAlbumModalComponent,
    WorkListComponent,
    WorkFilterModalComponent,
  ],
  exports: [MySpaceComponent, ActivityRecordsListComponent, AlbumsListComponent, WorkListComponent, WorkFilterModalComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SharedModule, SharedDirectivesModule, NgxPaginationModule],
  providers: [MySpaceService],
})
export class MySpaceModule {}
