import { Component } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { RouterDataService } from '@services/router-data.service';
import { SlideMenuService } from '@services/slide-menu.service';

@Component({
  selector: 'app-h1-heading-title',
  templateUrl: './h1-heading-title.component.html',
  styleUrls: ['./h1-heading-title.component.scss'],
})
export class H1HeadingTitleComponent {
  constructor(
    private slideMenuService: SlideMenuService,
    public layoutService: LayoutService,
    public routerDataService: RouterDataService,
  ) {}

  slideMenu() {
    this.slideMenuService.slideIn();
  }
}
