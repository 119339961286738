<div class="tea-edit-work">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="1"></app-steps>
    </div>
  </div>

  <div class="block-standard">
    <div class="form-horizontal">
      <div class="form-group">
        <div class="row work-align-center">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>配信日時</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <p>{{ deliveryText }}</p>
          </div>
        </div>
      </div>

      <div class="step2-button-submit">
        <button type="button" (click)="onBack()" [disabled]="disableButtons" class="spen-mod-btn">戻る</button>
        <button type="button" (click)="onSubmit()" [disabled]="disableButtons" class="spen-mod-btn is-primary" data-cy="submit-button">
          変更完了
        </button>
      </div>
    </div>
  </div>
</div>
