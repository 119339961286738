import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';

import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { StoryFilesViewModule } from '@components/story/story-files-view/story-files-view.module';
import { StoriesListComponent } from '@components/story/teacher/stories-list/stories-list.component';
import { StoriesComponent } from './stories/stories.component';
import { StoryCommentTeacherModule } from '@components/story/teacher/story-comment/story-comment-teacher.module';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { RouterModule } from '@angular/router';
import { StoryDetailComponent } from './story-detail/story-detail.component';

@NgModule({
  declarations: [StoriesListComponent, StoriesComponent, StoryDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    SharedModule,
    SharedDirectivesModule,
    SharedPipesModule,
    StoryFilesViewModule,
    StoryCommentTeacherModule,
  ],
  providers: [SearchConditionSavingService],
})
export class StoryTeacherModule {}
