import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {
  @Input() title: string;
  @Input() status: string;
  @Input() progress: number;
  @Input() canCancel: boolean;

  @Output() cancel = new EventEmitter();

  onCancel(): void {
    if (!this.isFinished) {
      this.cancel.emit();
    }
  }

  get isFinished(): boolean {
    return this.progress === 100 && !this.canCancel;
  }
}
