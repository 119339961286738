<div
  *ngIf="!isPopup"
  class="spen-datepicker"
  [class.non-next-month]="!canGoToNextMonth()"
  [class.non-prev-month]="!canGoToPrevMonth()"
  [class.is-weekly]="isWeekly"
>
  <ngb-datepicker
    (navigate)="onMonthChange($event)"
    [(ngModel)]="model"
    [navigation]="'arrows'"
    [outsideDays]="'visible'"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [startDate]="startDate"
    [dayTemplate]="customDay"
    (ngModelChange)="onNgModelChange($event)"
    #dp
  ></ngb-datepicker>
</div>

<div
  *ngIf="isPopup"
  [class.is-weekly]="isWeekly"
  class="spen-datepicker"
  [class.is-mobile-right-position]="isRightAlignOnMobile"
  [class.fixed-dropdown]="isFixedDropdown"
>
  <div
    class="spen-mod-input-date"
    [class.include-responsive]="includeResponsive"
    [class.non-next-month]="!canGoToNextMonth()"
    [class.non-prev-month]="!canGoToPrevMonth()"
  >
    <input
      class="spen-mod-input-text"
      [class.disabled]="disabled"
      ngbDatepicker
      #d="ngbDatepicker"
      [disabled]="disabled"
      [(ngModel)]="model"
      [navigation]="'arrows'"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [startDate]="startDate"
      [dayTemplate]="customDay"
      (navigate)="onMonthChange($event)"
      (click)="d.toggle()"
      (ngModelChange)="onNgModelChange($event)"
      placeholder="{{ placehoderText }}"
      readonly
    />
  </div>
</div>

<ng-template #customDay let-date="date" let-selected="selected">
  <span
    class="datepicker-custom-day"
    [class.is-selected]="selected"
    [class.is-today]="isToday(date)"
    [class.is-range]="isSelectedWeek(date)"
    [class.is-other-month-day]="isOtherMonthDay(date)"
    >{{ date.day }}</span
  >
</ng-template>
