import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthUserService } from '@services/auth-user.service';
import { LayoutService } from '@components/layout/layout.service';

import { Pickup } from '@models/pickup';
import { currentLocaleDate } from '@functions/date-formatter';

@Component({
  selector: 'app-teacher-pickup-item',
  templateUrl: './teacher-pickup-item.component.html',
  styleUrls: ['./teacher-pickup-item.component.scss'],
})
export class TeacherPickupItemComponent {
  @Input() pickup: Pickup;

  constructor(
    private authUserService: AuthUserService,
    public layoutService: LayoutService,
    private router: Router,
  ) {}

  editPickup() {
    this.router.navigate(['activity-pickups', 'teacher', this.pickup.id, 'edit']);
  }

  canEditPickup() {
    return this.authUserService.retrieve().id === this.pickup.create_user_id;
  }

  pickupExpired() {
    const currentTime = currentLocaleDate();
    currentTime.setHours(0, 0, 0, 0);
    const pickupEndTime = new Date(this.pickup.end_time);

    return currentTime > pickupEndTime;
  }
}
