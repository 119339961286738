import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { Space } from '@components/space/space';
import { validateForm } from '@functions/validate-form';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { Observable } from 'rxjs';
import { GraduationMessageFormService } from '../graduation-message-form.service';

@Component({
  selector: 'app-graduation-message-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['../graduation-message-form.component.scss', './step1.component.scss'],
})
export class Step1Component implements OnInit, OnDestroy {
  @ViewChild(FileUploaderComponent) fileUploaderComponent: FileUploaderComponent;

  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  formInited = false;

  focusEvent = new EventEmitter<boolean>();

  validationMessages = {
    title: {
      required: 'タイトルを入力してください。',
      maxlength: 'タイトルは50文字以内で入力してください。',
    },
    message: {
      required: '本文を入力してください。',
      maxlength: '本文は5000文字以内で入力してください。',
    },
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public creatorService: GraduationMessageFormService,
    private routerService: RouterService,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const previousStep = this.creatorService.previousStep;
    if (previousStep === 2) {
      this.createGraduationMessageForm(this.creatorService.graduationMessage, true);
    } else {
      const graduationMessage = new GraduationMessage();
      this.loadSpaceFromHistory();
      this.createGraduationMessageForm(graduationMessage);
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  loadSpaceFromHistory() {
    if (!history.state) {
      return;
    }
    const space = <Space>history.state.space;
    if (!!space) {
      this.creatorService.space = space;
    }
  }

  createGraduationMessageForm(graduationMessage: GraduationMessage, dirty = false) {
    this.form = this.formBuilder.group({
      title: [graduationMessage.title || '', [Validators.required, Validators.maxLength(50)]],
      message: [graduationMessage.message || '', [Validators.required, Validators.maxLength(5000)]],
      upload_files_attributes: [[]],
    });

    this.formErrors = {};
    this.formInited = true;
    this.form.valueChanges.subscribe(() => {
      this.formErrors = validateForm(this.form, true, this.validationMessages);
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.creatorService.graduationMessage = Object.assign(this.creatorService.graduationMessage, this.form.value);
      this.creatorService.attachedFiles = this.fileUploaderComponent.attachedFiles;
      this.creatorService.fileUploader = this.fileUploaderComponent.uploader;
      this.creatorService.removedAttachFiles = this.fileUploaderComponent.removedAttachFiles;
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  goBack() {
    this.routerService.goBack(this.creatorService.backConfirmMessages);
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep2State();
  }
}
