import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { Grade } from '@models/grade';
import { ClassesListResponse } from './classes-list/classes-list-response';

@Injectable({ providedIn: 'root' })
export class ClassService {
  schoolYears = new BehaviorSubject<Array<string>>([]);
  grades = new BehaviorSubject<Array<Grade>>([]);
  isRequestedSchoolYears = false;
  isRequestedGrades = false;

  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {
    if (this.schoolYears.getValue().length === 0 && sessionStorage.getItem('schoolYears')) {
      this.schoolYears.next(JSON.parse(sessionStorage.getItem('schoolYears')));
    }
    if (this.grades.getValue().length === 0 && sessionStorage.getItem('grades')) {
      this.grades.next(JSON.parse(sessionStorage.getItem('grades')));
    }
  }

  getClassesListToFilter(params = {}): Observable<ClassesListResponse> {
    let url = `${this.apiEndpointV2}/classes?school_year=${params['school_year']}`;
    if (params['grade_id']) {
      url += `&grade_id=${params['grade_id']}`;
    }
    return this.http.get<ClassesListResponse>(url);
  }

  getSchoolYears(): Promise<Array<string>> | Array<string> {
    if (this.schoolYears.value.length === 0 && !this.isRequestedSchoolYears) {
      return this.doRequestSchoolYears().toPromise();
    } else {
      return this.schoolYears.getValue();
    }
  }

  getGrades(): Promise<Array<Grade>> | Array<Grade> {
    if (this.grades.value.length === 0 && !this.isRequestedGrades) {
      return this.doRequestGrades().toPromise();
    } else {
      return this.grades.getValue();
    }
  }

  private doRequestSchoolYears(): Observable<Array<string>> {
    const url = `${this.apiEndpointV2}/school_years`;
    return this.http.get<Array<string>>(url).pipe(
      map((response) => {
        sessionStorage.setItem('schoolYears', JSON.stringify(response));
        this.schoolYears.next(response);
        return response;
      }),
      catchError((_error) => of(<Array<string>>[])),
      finalize(() => (this.isRequestedSchoolYears = true)),
    );
  }

  private doRequestGrades(): Observable<Array<Grade>> {
    const url = `${this.apiEndpointV2}/grades`;
    return this.http.get<Array<Grade>>(url).pipe(
      map((response) => {
        sessionStorage.setItem('grades', JSON.stringify(response['grades']));
        this.grades.next(response['grades']);
        return response['grades'];
      }),
      catchError((_error) => of(<Array<Grade>>[])),
      finalize(() => (this.isRequestedGrades = true)),
    );
  }
}
