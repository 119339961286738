<div class="tea-create-space" *ngIf="spaceLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form class="form" [formGroup]="form" [class.disable-clicked]="isDeleting" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペース名</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <input
              class="spen-mod-input-text"
              data-cy="space-name"
              type="text"
              [appPlaceholder]="'例) １年A組HR'"
              name="name"
              formControlName="name"
              [appFocus]="focusEvent"
            />
          </div>
          <div class="col-md-2" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }"></div>
          <div class="col-md-5 col-xs-12" [ngClass]="{ 'col-sm-5': !isTabletAndDown() }">
            <div class="spen-mod-error-content error-popup" data-cy="error-message" *ngIf="formErrors['name']">
              {{ formErrors['name'].join(' ') }}
            </div>
          </div>
        </div>
      </div>
      <div class="space-color form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペースのカラー</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <label class="spen-mod-select select-color" (click)="showColorDropdown = !showColorDropdown" tabindex="1">
              <div class="image-select-label">
                <div class="color-picker" data-cy="space-color" [style.background-color]="form.get('color_code').value"></div>
              </div>
              <i class="fa fa-caret-down"></i>
            </label>
            <div class="list-color" *ngIf="showColorDropdown" tabindex="-1" (focusout)="onOutFocus($event.relatedTarget)" #listColor>
              <ul>
                <li data-cy="color-list" *ngFor="let color of COLORS" (click)="chooseColor(color)" [style.background-color]="color"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペースの年度</label>
          </div>
          <div class="col-md-10 col-xs-12 school-year-select" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <label class="spen-mod-select">
              <select class="select" data-cy="space-year" formControlName="school_year">
                <option [ngValue]="null">指定しない</option>
                <option *ngFor="let year of classService.schoolYears.getValue()" [value]="year" [selected]="year === space?.school_year">
                  {{ year }}年度
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>担当先生</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <p class="num-teacher" data-cy="teacher-num">{{ teacherFilterService.selectedUsersCount }}名</p>
            <div class="list-teacher">
              <ul [ngClass]="{ 'no-border': teacherFilterService.selectedUsersCount === 0 }">
                <li class="list-item" *ngFor="let teacher of selectedTeachersSub | async">
                  <div class="teacher-info" data-cy="teacher-info">
                    <img data-cy="teacher-avatar" src="{{ teacher.avatar }}" alt="" />
                    <span class="truncate-text">{{ teacher.name }}</span>
                    <span class="self" *ngIf="(space.create_user_id || currentUser.id) === teacher.id">（作成者）</span>
                  </div>
                  <a (click)="removeSelectedTeacher(teacher)" class="spen-util-link" data-cy="remove-teacher">削除する</a>
                </li>
              </ul>
              <div class="add-btn" [ngClass]="{ last: teacherFilterService.selectedUsersCount === 0 }">
                <div class="create-new" (click)="chooseTeacher()">
                  <a class="spen-util-link" data-cy="add-teacher"><i class="fa fa-plus-circle"></i>追加する</a>
                </div>
              </div>
            </div>
            <div class="spen-mod-error-content error-popup" data-cy="error-message" *ngIf="formErrors['charges_attributes']">
              {{ formErrors['charges_attributes'].join(' ') }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>参加生徒</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <p class="num-teacher" data-cy="student-num">{{ studentFilterService.selectedUsersCount | number }}名</p>
            <div class="list-student">
              <ul [ngClass]="{ 'no-border': studentFilterService.selectedUsersCount === 0 }">
                <li class="list-item" *ngFor="let student of selectedStudentsSub | async">
                  <div class="student-info" data-cy="student-info">
                    <img data-cy="student-avatar" src="{{ student.avatar }}" alt="" />
                    <span class="stu-group-info truncate-text">
                      <span class="truncate-text">{{ student.name }}</span>
                      <ng-container *ngIf="student.className">
                        <span class="class-info">{{ student.className }}{{ student.attendanceNumber }}番</span>
                      </ng-container>
                    </span>
                  </div>
                  <a (click)="removeSelectedStudent(student)" class="spen-util-link" data-cy="remove-student">削除する</a>
                </li>
              </ul>
              <div class="add-btn" [ngClass]="{ last: studentFilterService.selectedUsersCount === 0 }">
                <div class="create-new" (click)="chooseStudent()">
                  <a class="spen-util-link" data-cy="add-student"><i class="fa fa-plus-circle"></i>追加する</a>
                </div>
              </div>
            </div>
            <div class="spen-mod-error-content error-popup" data-cy="error-message" *ngIf="formErrors['space_members_attributes']">
              {{ formErrors['space_members_attributes'].join(' ') }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>利用権限</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <div class="row align-center permission-item" *ngFor="let permission of CROSS_REVIEW_PERMISSIONS">
              <div class="col-md-3 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
                <label>{{ permission.title }}</label>
              </div>
              <div class="col-md-9 col-xs-12 group-radio" [ngClass]="{ 'col-sm-8': !isTabletAndDown() }">
                <label class="spen-mod-radio" data-cy="permission-item-use">
                  <input class="radio" [checked]="!!space[permission.key]" type="radio" [value]="true" [formControlName]="permission.key" />
                  <i class="fa"></i>
                  <span class="text">利用する</span>
                </label>
                <label class="spen-mod-radio" data-cy="permission-item-unuse">
                  <input class="radio" [checked]="!space[permission.key]" type="radio" [value]="false" [formControlName]="permission.key" />
                  <i class="fa"></i>
                  <span class="text">利用しない</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-space-filter
        *ngIf="isShowingStudentFilter"
        [isTeacher]="false"
        (closeEvent)="closeStudentForm()"
        (submitEvent)="onStudentFilterSubmit()"
      ></app-space-filter>
      <app-space-filter
        *ngIf="isShowingTeacherFilter"
        [isTeacher]="true"
        [creatorId]="space.create_user_id || currentUser.id"
        (closeEvent)="closeTeacherForm()"
        (submitEvent)="onTeacherFilterSubmit()"
      ></app-space-filter>
      <div class="actions">
        <div class="delete-space-description" *ngIf="currentSpaceId">
          ※配信課題や配信アルバム、公開中の活動記録がないスペースのみ削除できます。
        </div>
        <div *ngIf="space.can_be_destroyed" (click)="deleteSpace()" class="spen-util-link delete-space" data-cy="remove-space">
          <i class="fa fa-trash-o action-icon"></i>このスペースを削除する
        </div>
        <div class="list-button-submit" [style.padding-bottom]="currentSpaceId ? '54px' : ''">
          <button type="button" (click)="onBack()" class="spen-mod-btn" data-cy="back-button">戻る</button>
          <button type="submit" class="spen-mod-btn is-primary" data-cy="submit-button">確認へ</button>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
