import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { FileUploaderModule } from '@components/file-uploader/file-uploader.module';

import { WorkService } from './work.service';
import { ImagesService } from '@services/images/images.service';
import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { LayoutService } from '@components/layout/layout.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

import { CreateWorkComponent } from './create-work/create-work.component';
import { Step1Component } from './create-work/step1/step1.component';
import { Step2Component } from './create-work/step2/step2.component';
import { Step3Component } from './create-work/step3/step3.component';
import { SpacePickerComponent } from './create-work/space-picker/space-picker.component';
import { AlbumPickerComponent } from './create-work/album-picker/album-picker.component';

import { EditWorkComponent } from '@components/work/edit-work/edit-work.component';
import { EditWorkStep1Component } from '@components/work/edit-work/step1/edit-work-step1.component';
import { EditWorkStep2Component } from '@components/work/edit-work/step2/edit-work-step2.component';

import { TeacherWorkDetailComponent } from './work-detail/teacher-work-detail/teacher-work-detail.component';
import { ModalListStudentComponent } from './work-detail/modal-list-student/modal-list-student.component';
import { WorkTemplateModalComponent } from './work-template-modal/work-template-modal.component';
import { FormWorkTemplateModalComponent } from './form-work-template-modal/form-work-template-modal.component';
import { FilterWorkTemplateModalComponent } from './filter-work-template-modal/filter-work-template-modal.component';
import { TeacherDeliveryWorksListComponent } from './teacher/delivery-works-list/delivery-works-list.component';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { AnswerPreviewModalComponent } from './answer-preview-modal/answer-preview-modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedDirectivesModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    FileUploaderModule,
    SharedPipesModule,
    ClickOutsideModule,
    RouterModule,
  ],
  declarations: [
    CreateWorkComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    SpacePickerComponent,
    AlbumPickerComponent,
    TeacherWorkDetailComponent,
    ModalListStudentComponent,
    WorkTemplateModalComponent,
    FormWorkTemplateModalComponent,
    FilterWorkTemplateModalComponent,
    TeacherDeliveryWorksListComponent,
    EditWorkComponent,
    EditWorkStep1Component,
    EditWorkStep2Component,
    AnswerPreviewModalComponent,
  ],
  exports: [TeacherWorkDetailComponent, WorkTemplateModalComponent, FormWorkTemplateModalComponent, FilterWorkTemplateModalComponent],
  providers: [
    WorkService,
    ImagesService,
    S3CredentialService,
    UploadFilesService,
    LayoutService,
    TeacherFilterService,
    SearchConditionSavingService,
  ],
})
export class WorkTeacherModule {}
