import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { cloneDeep, omit, pickBy } from 'lodash';

import { LayoutService } from '@components/layout/layout.service';
import { StoryCategoriesService } from '@services/story-categories/story-categories.service';
import { StoryService } from '../../story.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

import { Story } from '@components/story/story';

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.scss'],
})
export class StoryDetailComponent implements OnInit {
  categoriesWithStory = [];
  isShowStory = [];
  dataLoaded = false;
  @Input() grade: string;

  constructor(
    public layoutService: LayoutService,
    private storyCategoriesService: StoryCategoriesService,
    private storyService: StoryService,
    private analytics4Service: GoogleAnalytics4Service,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getStories();
  }

  onChangeShowStory(storyId: number): void {
    this.isShowStory[storyId] = !this.isShowStory[storyId];
  }

  getUpdatedAt(story: Story): string {
    return story.created_at === story.updated_at ? '-' : story.updated_at;
  }

  sendEvent(clickParts: string, storyCategoryName: string): void {
    const params = {
      grade: this.grade,
      story_categories_name: storyCategoryName,
    };
    this.analytics4Service.sendEvent('ストーリー', 'ストーリー画面', clickParts, params);
  }

  private async getStories(): Promise<void> {
    await this.storyCategoriesService.getCategories();

    let params = omit(this.route.snapshot.queryParams, 'tab');
    params = Object.assign(params, pickBy({ grade: this.grade }));
    this.storyService.getStudentStories(params).subscribe((response) => {
      this.mapCategoryWithStory(response);
      this.dataLoaded = true;

      this.scrollToStoryComment();
    });
  }

  private mapCategoryWithStory(stories: Array<Story>): void {
    this.categoriesWithStory = cloneDeep(this.storyCategoriesService.categoriesSource.value);
    this.categoriesWithStory.forEach((category) => {
      category.sub_categories.forEach((subCategory) => {
        const story = stories.find((s) => s.story_category_id === subCategory.id);
        if (story) {
          subCategory['story'] = story;
          this.isShowStory[story.id] = true;
        }
      });
    });
  }

  private scrollToStoryComment(): void {
    const scrollStoryComment = this.route.snapshot.fragment;

    if (!!scrollStoryComment) {
      this.cdRef.detectChanges();

      const storyCommentElement = document.getElementById('story-comment');
      if (storyCommentElement) {
        window.scrollTo(0, storyCommentElement.offsetTop);
      }
    }
  }
}
