<div class="progress-indicator">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <h2 class="heading-text">{{ title }}</h2>
        </div>

        <div class="modal-content has-footer">
          <div class="content-inner">
            <div class="progress-bar">
              <app-progress-bar [progress]="progress"></app-progress-bar>
              <div class="status-container">
                <span class="status-text" [innerHTML]="status"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="modal-actions is-right-actions">
            <button class="spen-mod-btn is-default js-close-modal-trigger" (click)="onCancel()" [disabled]="this.isFinished">
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
