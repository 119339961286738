import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

import { environment } from '@environments/environment';
import { GlobalMenu } from '@models/global-menu';
import { Breakpoints } from '@functions/breakpoints';

@Injectable()
export class LayoutService {
  readonly HEADER_OFFSET_HEIGHT = 55;
  apiEndpoint = environment.apiEndpoint;

  private isOpenGlobalMenuSource = new Subject<boolean>();
  isOpenGlobalMenu$ = this.isOpenGlobalMenuSource.asObservable();

  isMobileView = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(Breakpoints.Mobile));
  isPhoneDownView = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(Breakpoints.PhoneDown));
  isTabletDownView = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(Breakpoints.TabletDown));
  isMediumDesktopDownView = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(Breakpoints.MediumDesktopDown));

  isMediumDesktopDownView$ = this.isMediumDesktopDownView.asObservable();

  constructor(
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe(Breakpoints.Mobile).subscribe((result) => this.isMobileView.next(result.matches));
    this.breakpointObserver.observe(Breakpoints.PhoneDown).subscribe((result) => this.isPhoneDownView.next(result.matches));
    this.breakpointObserver.observe(Breakpoints.TabletDown).subscribe((result) => this.isTabletDownView.next(result.matches));
    this.breakpointObserver.observe(Breakpoints.MediumDesktopDown).subscribe((result) => this.isMediumDesktopDownView.next(result.matches));
  }

  getGlobalMenu(): Observable<Array<GlobalMenu>> {
    const url = `${this.apiEndpoint}/menu`;
    return this.http.get<Array<GlobalMenu>>(url);
  }

  showGlobalMenu() {
    this.isOpenGlobalMenuSource.next(true);
  }
}
