<div class="tea-activity-detail" *ngIf="dataLoaded; else loadingIcon">
  <p class="activity-title">{{ record.title }}</p>

  <div class="user-info">
    <img [src]="student.avatar" alt="avatar" />
    <div class="student-name">
      <a [routerLink]="['/students/teacher', student.id]" class="spen-util-link">
        {{ student.name }}
      </a>
      <p>{{ student.class_name }}{{ student.attendance_number }}番</p>
    </div>
  </div>

  <div class="additional-info">
    <div class="time-detail">
      <span>作成日時：{{ record.created_at }}</span>
      <span *ngIf="isUpdated">更新日時：{{ record.updated_at }}</span>
    </div>
    <p class="activity-type">種別：自主作成</p>
  </div>

  <h2>本文</h2>
  <p>{{ record.content }}</p>
  <div class="category-text">
    <p *ngFor="let name of categoryNames">{{ name }}</p>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
