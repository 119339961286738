import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlbumService } from '@components/album/album.service';
import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { AlbumTeacherModule } from './teacher/album-teacher.module';
import { AlbumStudentModule } from './student/album-student.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, AlbumTeacherModule, AlbumStudentModule],
  providers: [AlbumService, UploadFilesService, SearchConditionSavingService, S3CredentialService],
})
export class AlbumModule {}
