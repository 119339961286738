import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoryService } from '@components/story/story.service';
import { LayoutService } from '@components/layout/layout.service';
import { StoryCategoriesService } from '@services/story-categories/story-categories.service';
import { Story } from '@components/story/story';
import { environment } from '@environments/environment';
import { cloneDeep, pickBy } from 'lodash';

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.scss'],
})
export class StoryDetailComponent implements OnInit, OnDestroy {
  readonly MAX_NUMBER_STORIES = environment.maxNumberStories;

  private studentId: number;
  @Input() grade: string;
  categoriesWithStory = [];
  numberOfStories: number;
  isShowStory = [];
  dataLoaded = false;
  errMsg: string;
  routerSubscriber = null;

  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private storyService: StoryService,
    private storyCategoriesService: StoryCategoriesService,
    public layoutService: LayoutService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.studentId = +this.activatedRoute.snapshot.params['student_id'];
    this.routerSubscriber = this.route.params.subscribe(async () => {
      await this.storyCategoriesService.getCategories();
      this.getStories();
    });
  }

  getStories() {
    this.dataLoaded = false;
    const params = pickBy({ grade: this.grade });
    this.storyService.getTeacherStoryDetail(this.studentId, params).subscribe(
      (response) => {
        this.numberOfStories = response.length;
        this.mapStoriesWithCategory(response);
        this.dataLoaded = true;

        this.scrollStoryToTop();
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  mapStoriesWithCategory(stories: Array<Story>) {
    this.categoriesWithStory = cloneDeep(this.storyCategoriesService.categoriesSource.value);

    this.categoriesWithStory.forEach((category) => {
      category.sub_categories.forEach((subCategory) => {
        const story = stories.find((s) => s.story_category_id === subCategory.id);
        if (story) {
          subCategory['story'] = story;
          this.isShowStory[story.id] = true;
        }
      });
    });
  }

  onChangeShowStory(story_id: number) {
    this.isShowStory[story_id] = !this.isShowStory[story_id];
  }

  getUpdatedAt(story: Story) {
    return story.created_at === story.updated_at ? '-' : story.updated_at;
  }

  ngOnDestroy() {
    if (this.routerSubscriber) {
      this.routerSubscriber.unsubscribe();
    }
  }

  private scrollStoryToTop() {
    const MARGIN_HEIGHT_OF_STORY = 16;
    const scrollStoryId = this.route.snapshot.fragment;

    if (!!scrollStoryId) {
      this.cdRef.detectChanges();

      const storyElement = document.getElementById(scrollStoryId);
      if (storyElement) {
        window.scrollTo(0, storyElement.offsetTop - MARGIN_HEIGHT_OF_STORY);
      }
    }
  }
}
