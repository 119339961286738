<div class="works">
  <div class="filter-button">
    <a class="spen-util-link" (click)="doFilterWork()"
      ><i class="fa fa-search"></i>{{ !!queryParams['keyword'] ? '検索条件を変更' : '検索' }}</a
    >
  </div>
  <div class="filtered-work" *ngIf="queryParams['keyword']">
    <span class="name">検索条件：{{ queryParams['keyword'] }}</span>
    <a class="spen-util-link remove-filter" (click)="removeWorkFilter()">解除</a>
  </div>

  <div class="work-list">
    <ng-container *ngIf="dataLoaded; else loadingIcon">
      <ng-container *ngIf="works.length > 0; else emptyList">
        <div class="list-amount">
          <span>{{ worksCount }}件</span>
        </div>
        <div class="content">
          <ul class="detail-flex-list">
            <li
              class="list-item"
              (click)="onClickWork(work)"
              *ngFor="
                let work of works
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
            >
              <a class="list-item-info">
                <div class="icon">
                  <i class="fa fa-file-text-o"></i>
                </div>
                <div class="text-info">
                  <p class="text-badge">
                    <span class="spen-mod-badge is-red spen-util-link" *ngIf="!work.is_read">NEW</span>
                    <span class="spen-mod-badge default-detail-badge">{{ answerState(work) }}</span>
                    <span class="time-detail">{{ work.created_at | date: 'yyyy/MM/dd' }}</span>
                  </p>
                  <p class="title">{{ work.title }}</p>
                  <p class="author">
                    {{ work.create_user.name }}先生 <span class="note-album">配信先: {{ work.album_title || work.space.name }}</span>
                  </p>
                </div>
              </a>
              <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
            </li>
          </ul>
          <div class="block-footer">
            <div class="pagination" *ngIf="showPagination">
              <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
              </app-pagination-controls>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #emptyList>
        <div class="null-content">
          <p class="text">課題はありません。</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingIcon>
      <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
    </ng-template>
  </div>
  <a href="{{ classiUrl }}" class="spen-mod-btn prev-button" *ngIf="!isMobileApp">ホームへ戻る</a>
</div>

<app-work-filter-modal
  *ngIf="showFilterModal"
  (eventModalFilter)="eventModalFilter($event)"
  [keyword]="queryParams['keyword']"
></app-work-filter-modal>
