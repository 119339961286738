import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable()
export class NotificationService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getNotifications(): Observable<any> {
    const url = `${this.apiEndpoint}/notifications`;
    return this.http.get(url);
  }

  getMoreNotifications(url: string): Observable<any> {
    return this.http.get(url);
  }
}
