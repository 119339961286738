import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { StoryCommentTeacherService } from '../story-comment-teacher.service';
import { AuthUserService } from '@services/auth-user.service';

import { StoryComment } from '@models/story-comment';
import { User } from '@models/user';
import { pickBy } from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-story-comment-list-teacher',
  templateUrl: './story-comment-list-teacher.component.html',
  styleUrls: ['./story-comment-list-teacher.component.scss'],
})
export class StoryCommentListTeacherComponent implements OnInit {
  @Input() storyUserId: number;
  @Input() grade: string;

  storyComments: Array<StoryComment>;
  edittingStoryComment: StoryComment;

  currentUser: User;
  storyCommentsLoaded = false;
  isLoadingMore = false;
  nextId = null;

  constructor(
    private storyCommentTeacherService: StoryCommentTeacherService,
    private authUserService: AuthUserService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.currentUser = this.authUserService.retrieve();
    this.loadStoryComments();
  }

  loadStoryComments() {
    this.storyCommentTeacherService.loadTeacherStoryComments(pickBy(this.defaultParams())).subscribe(
      (response) => {
        this.storyComments = response.story_comments;
        this.nextId = response.meta.next_id;
        this.storyCommentsLoaded = true;
        this.scrollToComments();
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  loadMoreStoryComments() {
    this.isLoadingMore = true;
    this.storyCommentTeacherService.loadTeacherStoryComments(this.defaultParams()).subscribe(
      (response) => {
        this.storyComments.push(...response.story_comments);
        this.nextId = response.meta.next_id;
        this.isLoadingMore = false;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  onsaveNewStoryComment(storyComment) {
    this.storyComments.unshift(storyComment);
  }

  onEditStoryComment(storyComment) {
    this.edittingStoryComment = storyComment;
  }

  onCancelEditStoryComment() {
    this.edittingStoryComment = null;
  }

  onUpdateOldStoryComment(newStoryComment, oldStoryComment) {
    this.edittingStoryComment = null;
    Object.assign(oldStoryComment, newStoryComment);
  }

  onDeleteOldStoryComment(storyComment) {
    this.edittingStoryComment = null;
    const removeIndex = this.storyComments.findIndex((item) => item === storyComment);
    if (removeIndex === -1) {
      return;
    }
    this.storyComments.splice(removeIndex, 1);
  }

  canCreateStoryComment() {
    return !this.edittingStoryComment;
  }

  canEditStoryComment(storyComment: StoryComment) {
    return !this.edittingStoryComment && storyComment.create_user.id === this.currentUser.id;
  }

  get canLoadMore(): boolean {
    return !!this.nextId;
  }

  private defaultParams() {
    const params = { story_user_id: this.storyUserId, next_id: this.nextId };
    if (this.grade) {
      params['grade'] = this.grade;
    }

    return params;
  }

  private scrollToComments() {
    const fragment = this.route.snapshot.fragment;

    if (!!fragment && fragment === 'comments') {
      this.cdRef.detectChanges();

      const storyElement = document.getElementById('comments');
      if (storyElement) {
        window.scrollTo(0, storyElement.offsetTop);
      }
    }
  }
}
