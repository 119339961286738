<div class="tea-create-space" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="1"></app-steps>
    </div>
  </div>

  <div class="block-standard">
    <div class="form-horizontal">
      <div class="form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペース名</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }" data-cy="space-name">
            <p>{{ creatorService.space?.name }}</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペースのカラー</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <div class="color-choosen" data-cy="space-color" [style.background-color]="creatorService.space?.color_code"></div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row space-align-center">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>スペースの年度</label>
          </div>
          <div class="col-md-10 col-xs-12 school-year-select" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }" data-cy="space-year">
            <label>
              {{ creatorService.space.school_year ? creatorService.space.school_year + '年度' : '指定しない' }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>担当先生</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <p class="num-teacher">{{ teacherFilterService.selectedUsersCount }}名</p>
            <ul class="list-teacher scroll-list">
              <li class="list-item" *ngFor="let teacher of teacherFilterService.selectedUsers | async">
                <div class="teacher-info" data-cy="teacher-info">
                  <img data-cy="teacher-avatar" src="{{ teacher.avatar }}" alt="" />
                  <span class="truncate-text">{{ teacher.name }}</span>
                  <span class="self" *ngIf="creatorService.space.create_user_id === teacher.id">（作成者）</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>参加生徒</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <p class="num-teacher">{{ studentFilterService.selectedUsersCount | number }}名</p>
            <ul class="list-student scroll-list">
              <li class="list-item" *ngFor="let student of studentFilterService.selectedUsers$ | async">
                <div class="student-info" data-cy="student-info">
                  <img data-cy="student-avatar" src="{{ student.avatar }}" alt="" />
                  <span class="stu-group-info">
                    <span class="truncate-text">{{ student.name }}</span>
                    <ng-container *ngIf="student.className">
                      <span class="class-info">{{ student.className }}{{ student.attendanceNumber }}番</span>
                    </ng-container>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-12" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>利用権限</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <div class="row align-center permission-item" *ngFor="let permission of CROSS_REVIEW_PERMISSIONS">
              <div class="col-md-3 col-xs-6" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
                <label>{{ permission.title }}</label>
              </div>
              <div class="col-md-9 col-xs-6" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
                <span class="text" data-cy="permission-item-status">{{
                  creatorService.space[permission.key] ? '利用する' : '利用しない'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step2-button-submit">
        <button type="button" (click)="onBack()" class="spen-mod-btn" [disabled]="isDisabled">戻る</button>
        <button type="button" (click)="onSubmit()" class="spen-mod-btn is-primary" data-cy="submit-button" [disabled]="isDisabled">
          {{ !!creatorService.space.id ? '変更完了' : '作成完了' }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
