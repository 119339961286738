<div class="notifications">
  <h1 class="heading-title heading-bold">通知・履歴</h1>
  <div
    class="listing-noti"
    infinite-scroll
    [infiniteScrollDistance]="0"
    [infiniteScrollThrottle]="500"
    [infiniteScrollDisabled]="!nextUrl"
    (scrolled)="getMoreNotifications()"
  >
    <div
      class="item-noti"
      *ngFor="let notification of notifications"
      (click)="readNotification(notification)"
      [class.item-blue]="!notification['is_read']"
    >
      <div class="header-noti clearfix">
        <div class="small-avatar">
          <a href="#">
            <img [src]="notification.create_user['avatar']" alt="Avatar" />
          </a>
        </div>
        <div class="info-noti">
          <p>
            {{ notification.message }}
          </p>
          <p class="noti-date">
            {{ notification.created_at }}
          </p>
        </div>
      </div>
      <div class="noti-input" *ngIf="notification.isWorkDelivery">
        <label class="spen-mod-input">
          <input class="spen-mod-input-text" [readonly]="true" value="回答期限：{{ notification.expiredTime }}" type="text" />
          <p class="check-reply {{ notification.work['status'] }}">
            {{ notification.statusText }}
          </p>
        </label>
      </div>
    </div>
  </div>
</div>
