<div class="tea-create-work" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="list-form">
        <div class="form-group">
          <div class="row align-center">
            <div class="column-title"><label class="label">配信課題名</label><span class="spen-mod-badge is-red">必須</span></div>
            <div class="column-content">
              <input
                class="spen-mod-input-text"
                data-cy="work-title"
                type="text"
                [appPlaceholder]="'例）進路講演会の感想'"
                formControlName="title"
                [appFocus]="focusEvent"
              />
              <div class="error-popup" *ngIf="formErrors['title']">
                <div class="spen-mod-error-content">
                  {{ formErrors['title'].join(' ') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group comment-files-group">
          <div class="row">
            <div class="column-title">
              <label class="label">説明</label>
            </div>
            <div class="column-content">
              <textarea
                class="spen-mod-input-text"
                data-cy="work-comment"
                formControlName="comment"
                [appPlaceholder]="'例）もっと知りたいこと、今後役立ちそうなことは何ですか？それはなぜですか？'"
              >
              </textarea>
              <div class="error-popup" *ngIf="formErrors['comment']">
                <div class="spen-mod-error-content">
                  {{ formErrors['comment'].join(' ') }}
                </div>
              </div>

              <div class="file-uploader">
                <app-file-uploader
                  [uploader]="creatorService.fileUploader"
                  [attachedFiles]="creatorService.attachedFiles"
                  [removedAttachFiles]="creatorService.removedAttachFiles"
                  (eventSelectFile)="onSelectFile($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="column-title">
              <label class="label">活動記録名</label>
            </div>
            <div class="column-content group-radio">
              <label class="spen-mod-radio" data-cy="select-answer-title">
                <input
                  class="radio"
                  name="answer_title"
                  [checked]="!creatorService.reservedAnswerTitle"
                  type="radio"
                  (change)="changeReservedAnswerTitle(false)"
                />
                <i class="fa"></i><span class="text">生徒が指定する</span>
              </label>
              <label class="spen-mod-radio" data-cy="select-answer-title">
                <input
                  class="radio"
                  name="answer_title"
                  [checked]="creatorService.reservedAnswerTitle"
                  type="radio"
                  (change)="changeReservedAnswerTitle(true)"
                />
                <i class="fa"></i><span class="text">先生が指定する</span>
              </label>

              <ng-container *ngIf="creatorService.reservedAnswerTitle">
                <input
                  class="spen-mod-input-text work-answer-title-input"
                  data-cy="work-answer-title"
                  type="text"
                  [appPlaceholder]="'例）２年１学期進路講演会'"
                  formControlName="answer_title"
                />
                <div class="error-popup" *ngIf="formErrors['answer_title']">
                  <div class="spen-mod-error-content">
                    {{ formErrors['answer_title'].join(' ') }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="column-title">
              <label class="label">活動記録テンプレート</label>
            </div>
            <div class="column-content group-radio">
              <label class="spen-mod-radio" data-cy="select-work-type">
                <input
                  class="radio"
                  name="work_type"
                  formControlName="work_type"
                  [value]="1"
                  type="radio"
                  (change)="changeReservedWorkTemplate(false)"
                />
                <i class="fa"></i><span class="text">生徒が指定する</span>
              </label>
              <label class="spen-mod-radio" data-cy="select-work-type">
                <input
                  class="radio"
                  name="work_type"
                  formControlName="work_type"
                  [value]="2"
                  type="radio"
                  (change)="changeReservedWorkTemplate(true)"
                />
                <i class="fa"></i><span class="text">先生が指定する</span>
              </label>

              <div class="select-template" *ngIf="creatorService.reservedWorkTemplate">
                <input formControlName="work_template_id" type="hidden" />
                <p class="template-selected-name" data-cy="work-template-title">{{ form.get('group_question_title').value || '未選択' }}</p>
                <a class="spen-util-link" data-cy="show-work-template" (click)="toggleWorkTemplateModal(true)">変更する</a>

                <div class="error-popup" *ngIf="formErrors['work_template_id']">
                  <div class="spen-mod-error-content">
                    {{ formErrors['work_template_id'].join(' ') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="column-title">
              <label class="label">活動記録カテゴリー</label>
            </div>
            <div class="column-content group-radio">
              <label class="spen-mod-radio" data-cy="select-category">
                <input
                  class="radio"
                  name="category"
                  [checked]="!creatorService.reservedCategory"
                  type="radio"
                  (change)="changeReservedCategory(false)"
                />
                <i class="fa"></i><span class="text">生徒が指定する</span>
              </label>
              <label class="spen-mod-radio" data-cy="select-category">
                <input
                  class="radio"
                  name="category"
                  [checked]="creatorService.reservedCategory"
                  type="radio"
                  (change)="changeReservedCategory(true)"
                />
                <i class="fa"></i><span class="text">先生が指定する</span>
              </label>

              <div class="category-selection" *ngIf="creatorService.reservedCategory">
                <div class="select-box">
                  <app-categories-selection
                    formControlName="category_id"
                    [categories]="creatorService.categories"
                    [emptyLabel]="'選択してください'"
                  >
                  </app-categories-selection>
                </div>
                <div class="error-popup" *ngIf="formErrors['category_id']">
                  <div class="spen-mod-error-content">
                    {{ formErrors['category_id'].join(' ') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="preview-work-link" *ngIf="!!form.get('title').value">
        <a class="spen-util-link" data-cy="show-preview-work" [class.is-disabled]="!form.valid" (click)="toggleAnswerPreviewModal(true)">
          課題回答画面のプレビューを見る
        </a>
      </div>

      <div class="list-button-submit">
        <button type="button" (click)="goBack()" class="spen-mod-btn">戻る</button>
        <button type="submit" class="spen-mod-btn is-primary" data-cy="submit-button">配信設定へ</button>
      </div>
    </div>
  </form>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-work-template-modal
  *ngIf="isShowWorkTemplateModal"
  @ngIfAnimation
  (eventSelectWorkTemplate)="eventSelectWorkTemplate($event)"
  (eventCloseModal)="toggleWorkTemplateModal(false)"
>
</app-work-template-modal>

<app-answer-preview-modal
  *ngIf="isShowAnswerPreviewModal"
  (eventCloseModal)="toggleAnswerPreviewModal(false)"
  [work]="creatorService.work"
  [workTemplate]="creatorService.workTemplate"
  [currentFiles]="currentFiles"
>
</app-answer-preview-modal>
