import { Injectable } from '@angular/core';
import { WorkTemplate } from '@models/work-template';

export enum Action {
  New = 1,
  Edit = 2,
  Clone = 3,
}

@Injectable()
export class ActivityRecordTemplateFormService {
  steps = ['入力', '確認', '完了'];
  removedWorkTemplates = [];
  previousStep: number;
  currentStep: number;
  workTemplate: WorkTemplate;
  editTemplateId: number;
  action: Action;

  constructor() {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.workTemplate = new WorkTemplate();
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }

  get isEdit() {
    return this.action === Action.Edit;
  }
}
