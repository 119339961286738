import { TeacherRole } from './teacher-role';
import { Ga } from './ga';
import { Ga4 } from './ga4';

const userType = {
  1: '先生',
  2: '生徒',
  3: '保護者',
};

export class User {
  id: number;
  name: string;
  userTypeId: number;
  avatar: string;
  benesseId?: string;
  schoolId: number;
  schoolYear?: string;
  className?: string;
  class_name?: string;
  attendanceNumber?: number;
  attendance_number?: number;
  member_user_id?: number;
  charge_id?: number;
  viewer_permission?: boolean;
  ga: Ga = {} as Ga;
  ga4: Ga4 = {} as Ga4;
  contentEncodingKey?: string;
  number_stories?: number;
  spaces?: Array<Object>;
  teacherRole?: TeacherRole | null;

  isTeacher(): boolean {
    return this.userTypeId === 1;
  }

  isStudent(): boolean {
    return this.userTypeId === 2;
  }

  get userTypeName(): string {
    return userType[this.userTypeId];
  }
}
