import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploaderModule } from '@components/file-uploader/file-uploader.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../../shared/components/shared.module';
import { GraduationMessageDetailComponent } from './teacher/graduation-message-detail/graduation-message-detail.component';
import { GraduationMessageFormComponent } from './teacher/graduation-message-form/graduation-message-form.component';
import { Step1Component } from './teacher/graduation-message-form/step1/step1.component';
import { Step2Component } from './teacher/graduation-message-form/step2/step2.component';
import { Step3Component } from './teacher/graduation-message-form/step3/step3.component';
import { GraduationMessageListComponent } from './teacher/graduation-message-list/graduation-message-list.component';
@NgModule({
  declarations: [
    GraduationMessageFormComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    GraduationMessageListComponent,
    GraduationMessageDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectivesModule,
    RouterModule,
    FileUploaderModule,
    SharedPipesModule,
    ClickOutsideModule,
    InfiniteScrollModule,
    NgxPaginationModule,
  ],
  providers: [UploadFilesService, S3CredentialService],
})
export class GraduationMessageTeacherModule {}
