import { Work } from '../work/work';
import { User } from '@models/user';
import { MessageLists } from './messages-list';

export class Notification {
  id: string;
  title: string;
  action: string;
  create_user: User;
  is_read: boolean;
  notifiable_type: string;
  created_at: string;
  work: Work;

  get isWorkDelivery() {
    return this.notifiable_type === 'WorkDelivery';
  }

  get userName() {
    if (this.create_user.userTypeId === 1) {
      return `${this.create_user['name']}先生`;
    }
    return this.create_user['name'];
  }

  get isActionNewWork() {
    return this.action === 'create_work';
  }

  get isActionRemindWork() {
    return this.action === 'remind_work';
  }

  get message() {
    return MessageLists[this.action] ? MessageLists[this.action](this) : '';
  }
}
