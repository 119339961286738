<div class="block-standard" [class.is-first]="isFirst" [class.on-public-page]="onPublicActivity">
  <div class="row">
    <div class="logo">
      <img [src]="assessment?.user?.avatar" alt="Avatar" class="img-responsive" />
    </div>
    <div class="assessment-content">
      <div class="user-info">
        <div class="info">
          <div class="reviewing-tag" *ngIf="assessment.is_reviewing">見直し依頼あり</div>
          <p class="date">{{ assessment.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
          <p class="name">{{ assessment?.user?.name }}{{ tailUserName }}</p>
        </div>
        <a *ngIf="canEdit" class="spen-util-link" (click)="onEditAssessment()">編集・削除</a>
      </div>
      <div class="text-content">
        <app-read-more [content]="assessment.content"></app-read-more>
      </div>
      <ng-container *ngIf="assessment?.is_reviewing && !!assessment?.review_reason">
        <p class="review-title">見直し理由</p>
        <div class="review-reason preserve-line-break" appConvertLink>{{ assessment.review_reason }}</div>
      </ng-container>
    </div>
  </div>
</div>
