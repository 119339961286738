import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';

import { StudentService } from './student.service';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { AssessmentModule } from '../assessment/assessment.module';
import { ActivityRecordService } from '@components/activity-record/activity-record.service';
import { StudentActivityRecordsComponent } from './student-detail/activity-records/student-activity-records.component';
import { StudentAlbumsComponent } from './student-detail/albums/albums.component';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { StudentManavisionRecordsComponent } from './student-detail/manavision/student-manavision-records.component';
import { StudentUnsubmittedWorksComponent } from './student-detail/unsubmitted-works/student-unsubmitted-works.component';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { StudentsListComponent } from '@components/student/students-list/students-list.component';
import { StudentTimelineComponent } from './student-detail/student-timeline/student-timeline.component';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    StudentsListComponent,
    StudentDetailComponent,
    StudentActivityRecordsComponent,
    StudentAlbumsComponent,
    StudentManavisionRecordsComponent,
    StudentUnsubmittedWorksComponent,
    StudentTimelineComponent,
  ],
  exports: [StudentDetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    SharedModule,
    SharedDirectivesModule,
    AssessmentModule,
    SharedPipesModule,
  ],
  providers: [StudentService, ActivityRecordService, TeacherFilterService, SearchConditionSavingService],
})
export class StudentModule {}
