import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Album } from '@models/album';
import { WorkService } from '@components/work/work.service';
import { LayoutService } from '@components/layout/layout.service';
import { NextPageParams } from './album-picker-response';

@Component({
  selector: 'app-album-picker',
  templateUrl: './album-picker.component.html',
  styleUrls: ['./album-picker.component.scss'],
})
export class AlbumPickerComponent implements OnInit, OnDestroy {
  @Output() submitEvent: EventEmitter<Album> = new EventEmitter<Album>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedAlbum: Album;
  @Input() spaceId: number;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  queryParams = {};
  searchedResults: Array<Album> = new Array<Album>();
  newSelectAlbum: Album;
  nextPageParams: NextPageParams;
  isShowError = false;
  isLoading = false;
  errMsg: string;

  constructor(
    private renderer: Renderer2,
    public layoutService: LayoutService,
    public workService: WorkService,
  ) {}

  ngOnInit() {
    this.newSelectAlbum = this.selectedAlbum;
    this.onSearch();
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.nextPageParams = null;
    this.searchedResults = [];
    if (this.isShowError) {
      this.isShowError = false;
    }

    this.queryParams['search_key'] = !!this.searchInput ? this.searchInput.nativeElement.value.trim() : '';

    this.workService.getAlbumsPickerList(this.spaceId, this.queryParams).subscribe(
      (response) => {
        this.isLoading = false;
        this.searchedResults = response.albums;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;

    // リクエスト用のキー名に変更する
    const params = {
      ...this.nextPageParams,
      page: this.nextPageParams.next_page,
    };
    delete params.next_page;

    this.workService.getAlbumsPickerList(this.spaceId, params).subscribe(
      (response) => {
        this.isLoading = false;
        this.searchedResults.push(...response.albums);
        this.nextPageParams = null;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onClose() {
    this.closeEvent.emit();
  }

  onSubmit() {
    if (!this.newSelectAlbum) {
      this.isShowError = true;
    } else {
      this.submitEvent.emit(this.newSelectAlbum);
    }
  }

  selectAlbum(album: Album) {
    if (this.isShowError) {
      this.isShowError = false;
    }
    this.newSelectAlbum = album;
  }
}
