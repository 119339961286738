import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app.routing';
import { LayoutModule } from '@components/layout/layout.module';
import { AppGuardModule } from '@guards/app.guard.module';
import { SpaceModule } from '@components/space/space.module';
import { ConfirmDialogModule } from '@components/confirm-dialog/confirm-dialog.module';

import { AppComponent } from './app.component';
import { ToastComponent } from '@components/toast/toast.component';

import { ToastService } from '@services/toast/toast.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { CategoriesService } from '@services/categories/categories.service';
import { MarksService } from '@services/marks/marks.service';
import { TemplatesService } from '@services/templates/templates.service';
import { HttpsInterceptor } from '@services/http.interceptor';
import { AuthUserService } from '@services/auth-user.service';
import { SlideMenuService } from '@services/slide-menu.service';
import { RouterDataService } from '@services/router-data.service';
import { ErrorComponent } from '@components/error/error.component';
import { ErrorHandlerFactory } from '@components/error/error-handler.factory';
import { ErrorService } from '@services/error.service';
import { RouterService } from '@services/router.service';
import { NativeInterfaceService } from '@services/native-interface.service';
import { LayoutService } from '@components/layout/layout.service';
import { LayoutModule as CdkLayoutModule } from '@angular/cdk/layout';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';
import { StudentNavigationService } from '@services/navigation/student-navigation.service';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';
import { DocumentModule } from '@components/document/document.module';
import { ActivityRecordModule } from '@components/activity-record/activity-record.module';
import { MySpaceModule } from '@components/my-space/my-space.module';
import { AlbumModule } from '@components/album/album.module';
import { WorkTeacherModule } from '@components/work/work-teacher.module';
import { WorkStudentModule } from '@components/work/work-student.module';
import { ActivityRecordTemplatesModule } from '@components/activity-record-templates/activity-record-templates.module';
import { NotificationModule } from '@components/notification/notification.module';
import { ActivityPickupModule } from '@components/activity-pickup/activity-pickup.module';
import { StudentModule } from '@components/student/student.module';
import { ManavisionModule } from '@components/manavision/manavision.module';
import { TimelineModule } from '@components/timeline/timeline.module';
import { StoryModule } from '@components/story/story.module';
import { GraduationMessageModule } from '@components/graduation-message/graduation-message.module';

@NgModule({
  declarations: [AppComponent, ToastComponent, ErrorComponent],
  imports: [
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppGuardModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    SpaceModule,
    CdkLayoutModule,
    DocumentModule,
    ActivityRecordModule,
    MySpaceModule,
    AlbumModule,
    WorkTeacherModule,
    WorkStudentModule,
    ActivityRecordTemplatesModule,
    NotificationModule,
    ActivityPickupModule,
    StudentModule,
    ManavisionModule,
    TimelineModule,
    StoryModule,
    GraduationMessageModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: ErrorHandlerFactory.create, deps: [ErrorService] },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    ToastService,
    ConfirmDialogService,
    CategoriesService,
    AuthUserService,
    MarksService,
    TemplatesService,
    SlideMenuService,
    RouterDataService,
    ErrorService,
    RouterService,
    NativeInterfaceService,
    LayoutService,
    TeacherNavigationService,
    StudentNavigationService,
    WordCloudService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
