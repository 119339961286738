import { Component, OnInit, Input, Output, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
import { RouterService } from '@services/router.service';
import { AlbumService } from '@components/album/album.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityRecord } from '@components/activity-record/activity-record';

@Component({
  selector: 'app-activity-album-modal',
  templateUrl: './activity-album-modal.component.html',
  styleUrls: ['./activity-album-modal.component.scss'],
})
export class ActivityAlbumModalComponent implements OnInit, OnDestroy {
  tabOptions = { self: '自分', cross_assessment: 'みんな', teacher_assessment: '先生' };
  currentTab = 'self';
  activityLoaded = false;
  activityRecord: ActivityRecord;
  errMsg: string;
  @Input() activityId: number;
  @Input() albumId: number;
  @Output() eventActivityAlbumModal = new EventEmitter<{}>();
  submitting = false;

  constructor(
    private routerService: RouterService,
    private albumService: AlbumService,
    private route: ActivatedRoute,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.albumService.getActivityRecord(this.activityId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.activityLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isQuestionnaireRecord() {
    return this.activityRecord.record_type === 'Questionnaire';
  }

  isDocumentRecord() {
    return this.activityRecord.record_type === 'Document';
  }

  onBack() {
    this.eventActivityAlbumModal.emit(false);
  }

  dataLoaded() {
    return this.activityLoaded;
  }

  switchTab(tabKey: string) {
    this.currentTab = tabKey;
  }

  addActivityToAlbum() {
    this.submitting = true;
    this.albumService.addActivityRecord(this.albumId, this.activityId).subscribe(
      (response) => {
        this.googleAnalytics4Service.sendEvent('アルバム', '既存の活動記録モーダル', 'アルバムに追加する');
        this.eventActivityAlbumModal.emit({ type: 'submit' });
      },
      (error) => {
        this.submitting = false;
        this.errMsg = <any>error;
      },
    );
  }
}
