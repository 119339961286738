import { UploadFile } from '@models/upload-file';

export class GraduationMessage {
  title: string;
  message: string;
  space_id: number;
  upload_files_attributes: Array<Object>;
  create_user_name: string;
  created_at: string;
  id: number;
  space_name: string;
  upload_files: Array<UploadFile>;
  can_destroy: boolean;
}
