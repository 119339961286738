import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoryFilesViewComponent } from './story-files-view.component';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { SharedModule } from '@components/shared.module';
import { StoryGalleryModule } from '@components/story/story-gallery/story-gallery.module';

@NgModule({
  declarations: [StoryFilesViewComponent],
  exports: [StoryFilesViewComponent],
  imports: [CommonModule, SharedPipesModule, SharedModule, StoryGalleryModule],
})
export class StoryFilesViewModule {}
