import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayJapanesesText' })
export class DayJapanesesTextPipe implements PipeTransform {
  transform(time: string): string {
    const date = new Date(time);
    const today = new Date();

    const MILISECONDS_PER_DAY = 86400000; // 1000 * 60 * 60 * 24
    const differenceDay = (today.setHours(12) - date.setHours(12)) / MILISECONDS_PER_DAY;

    switch (Math.round(differenceDay)) {
      case 0:
        return '今日';
      case 1:
        return '昨日';
      case 2:
        return '一昨日';
      default:
        const dayOfWeek = date.toLocaleString('ja-JP', { weekday: 'short' });
        return `${time}（${dayOfWeek}）`;
    }
  }
}
