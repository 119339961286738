import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { mapValues, pick, pickBy, findIndex, isEqual, omit, isNil } from 'lodash';

import { CategoriesService } from '@services/categories/categories.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { TeacherMarksService } from '@services/marks/teacher-marks.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

import { checkBlankString } from '@functions/check-string';

import { Mark } from '@models/mark';
import { StudentService } from '@components/student/student.service';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';
import { ControlLinkService } from '@services/control-link/control-link.service';

@Component({
  selector: 'app-student-activity-records',
  templateUrl: './student-activity-records.component.html',
  styleUrls: ['./student-activity-records.component.scss'],
})
export class StudentActivityRecordsComponent implements OnInit {
  @Input() studentId: number;

  isShowMoreFilter = false;
  formInited = false;
  isSearching = false;

  COMMENT_STATUSES = [null, 'has_comment', 'no_comment'];
  SUB_COMMENT_STATUSES = ['has_comment', 'cross', 'teacher'];
  PUBLIC_STATUSES = [null, 'shared', 'not_shared'];
  SUB_PUBLIC_STATUSES = ['shared', 'self_publish', 'pickup'];

  filterData = {};

  iconSort = {
    none: 'fa',
    loader: 'fa fa-spinner fa-spin',
    up: 'fa fa-caret-up',
    down: 'fa fa-caret-down',
  };

  sortStatus = {
    created_at: this.iconSort.none,
    updated_at: this.iconSort.none,
  };

  sortOptions = [
    { sort_value: 'created_at desc', text: '作成日時（降順）' },
    { sort_value: 'created_at asc', text: '作成日時（昇順）' },
    { sort_value: 'updated_at desc', text: '更新日時（降順）' },
    { sort_value: 'updated_at asc', text: '更新日時（昇順）' },
  ];

  inProgressSort = false;
  sortKey = null;
  sortOrder = null;

  private readonly allowedParamKeys = [
    'sort_key',
    'sort_order',
    'page',
    'keyword',
    'mark_ids',
    'category_id',
    'activity_type',
    'comment_status',
    'public_status',
  ];
  private readonly disallowedUrlParamKeys = ['keyword'];

  form: UntypedFormGroup;
  activityRecords = [];
  pages = {
    per_page: 1,
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  };

  contentLoaded = false;
  messageSort: string;

  isShowingModal = false;
  studentName: string;
  modalSize = 600;
  wordCloudServerError: 'happening' | 'not happening';
  gotWordCloudResponse: 'not yet' | 'have gotten' = 'not yet';
  gotWordCloudDocumentId: 'success' | 'failed';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    private categoriesService: CategoriesService,
    private marksService: TeacherMarksService,
    private filterDataService: TeacherFilterService,
    private studentService: StudentService,
    private wordCloudService: WordCloudService,
    private analytics4Service: GoogleAnalytics4Service,
    private controlLinkService: ControlLinkService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    this.sortKey = params['sort_key'] || 'created_at';
    this.sortOrder = params['sort_order'] || 'desc';
    this.filterData = pick(params, this.allowedParamKeys);
    if (!this.filterData['sort_key'] || !this.filterData['sort_order']) {
      Object.assign(this.filterData, { sort_key: this.sortKey, sort_order: this.sortOrder });
    }

    await this.categoriesService.loadCategories();
    await this.marksService.loadMarks();
    await this.filterDataService.setFilter(this.filterData);

    this.loadStudentActivityRecords(this.studentId, this.filterData);
    this.initForm();
    this.messageSort = this.getMessageSort();
    this.isSearching = !this.isDefaultForm;
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  defaultFilterValue() {
    const filterValues = {
      mark_ids: this.marks.map((m) => false),
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = this.sortOptions[0].sort_value;
    }

    return filterValues;
  }

  initForm() {
    const comment_status = this.filterData['comment_status'] || null;
    const public_status = this.filterData['public_status'] || null;
    const selectedMarkIds: Array<Number> = this.filterData['mark_ids'] || [];

    this.form = this.formBuilder.group({
      keyword: [this.filterData['keyword'], Validators.maxLength(100)],
      category_id: this.filterData['category_id'],
      activity_type: this.filterData['activity_type'],
      comment_status: this.COMMENT_STATUSES.includes(comment_status) ? comment_status : 'has_comment',
      sub_comment_status: [
        {
          value: this.SUB_COMMENT_STATUSES.includes(comment_status) ? comment_status : 'has_comment',
          disabled: !this.SUB_COMMENT_STATUSES.includes(comment_status),
        },
      ],
      public_status: this.PUBLIC_STATUSES.includes(public_status) ? public_status : 'shared',
      sub_public_status: [
        {
          value: this.SUB_PUBLIC_STATUSES.includes(public_status) ? public_status : 'shared',
          disabled: !this.SUB_PUBLIC_STATUSES.includes(public_status),
        },
      ],
      mark_ids: this.formBuilder.array(this.marks.map((mark) => selectedMarkIds.map(Number).includes(mark.id))),
      sort_value: this.getSortValue(),
    });
    this.formInited = true;
  }

  loadStudentActivityRecords(studentId: number, params: {}) {
    if (!this.inProgressSort) {
      this.contentLoaded = false;
    }

    this.studentService.getStudentActivityRecords(studentId, params).subscribe(
      (response) => {
        Object.assign(this.pages, response.meta);
        this.refreshIconSort();
        this.activityRecords = response.activity_records;
        this.contentLoaded = true;
      },
      (error) => {
        // TODO: error handling.
      },
    );
  }

  onSubmit() {
    window.scrollTo(0, 0);
    if (this.layoutService.isTabletDownView.value) {
      this.isShowMoreFilter = false;
    }

    this.gotWordCloudResponse = 'not yet';
    this.onChangeFilterParams();
  }

  onResetFilter() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortOptions[0].sort_value.split(' ');
    }

    this.form.reset(this.defaultFilterValue());

    this.selectCommentStatus();
    this.selectPublicStatus();

    this.onSubmit();
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  makeQueryParams(params: {}) {
    return Object.assign(params, { tab: 'activity_records', page: 1 });
  }

  pageChanged(page: number) {
    const params = { ...this.filterData, page: page };
    this.navigate(params, 'merge');
  }

  onChangeShowMoreFilter() {
    this.isShowMoreFilter = !this.isShowMoreFilter;
  }

  selectCommentStatus(value = '') {
    if (value !== 'has_comment') {
      this.form.get('sub_comment_status').disable();
    } else {
      this.form.get('sub_comment_status').enable();
    }
    this.form.get('sub_comment_status').patchValue('has_comment');
  }

  selectPublicStatus(value = '') {
    if (value !== 'shared') {
      this.form.get('sub_public_status').disable();
    } else {
      this.form.get('sub_public_status').enable();
    }
    this.form.get('sub_public_status').patchValue('shared');
  }

  changeBySortColumn(sortKey: string) {
    if (this.inProgressSort) {
      return false;
    }

    this.inProgressSort = true;
    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = this.iconSort.none;
      this.sortOrder = 'desc';
    }
    this.sortKey = sortKey;
    this.sortStatus[this.sortKey] = this.iconSort.loader;
    const params = { ...this.filterData, ...this.makeSortParams() };
    this.navigate(params, 'merge');
  }

  getSortValue() {
    return this.sortKey ? `${this.sortKey} ${this.sortOrder}` : this.sortOptions[0].sort_value;
  }

  refreshIconSort() {
    this.sortStatus = mapValues(this.sortStatus, () => this.iconSort.none);

    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = this.iconSort[icon];
    if (this.inProgressSort) {
      this.form.controls['sort_value'].setValue(this.getSortValue());
    }
    this.inProgressSort = false;
  }

  onClickActivityRecord(activityRecord, event: KeyboardEvent) {
    this.controlLinkService.open(`/activity-records/teacher/${activityRecord.id}`, event, {
      state: { navigationFrom: '/students/teacher' },
    });
  }

  private onChangeFilterParams() {
    const params = pickBy(this.form.value);

    if (checkBlankString(params['keyword'])) {
      delete params['keyword'];
    }

    if (!!params['comment_status'] && !!params['sub_comment_status'] && params['sub_comment_status'] !== 'has_comment') {
      params['comment_status'] = params['sub_comment_status'];
    }
    delete params['sub_comment_status'];

    if (params['mark_ids']) {
      params['mark_ids'] = this.marks.map((mark) => mark.id).filter((id, index) => params['mark_ids'][index]);
    } else {
      delete params['mark_ids'];
    }

    if (!!params['public_status'] && !!params['sub_public_status'] && params['sub_public_status'] !== 'shared') {
      params['public_status'] = params['sub_public_status'];
    }
    delete params['sub_public_status'];

    this.buildOrderParams(params);
    delete params['sort_value'];

    params['request_timestamp'] = Date.now();

    this.navigate(this.makeQueryParams(params));
  }

  makeSortParams() {
    return {
      page: 1,
      sort_key: this.sortKey,
      sort_order: this.sortOrder,
    };
  }

  private buildOrderParams(params) {
    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
  }

  get showPagination(): boolean {
    return this.pages && this.pages.total_pages > 1;
  }

  get marks(): Array<Mark> {
    return this.marksService.marksSource.value;
  }

  getMessageSort() {
    const index = findIndex(this.sortOptions, ['sort_value', `${this.sortKey} ${this.sortOrder}`]);
    return index ? this.sortOptions[index].text : this.sortOptions[0].text;
  }

  get isContentLoading(): boolean {
    return !this.contentLoaded || this.inProgressSort;
  }

  get isDefaultForm(): boolean {
    const compareValues = pickBy(this.form.value);

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }

  get possibleShowModal(): boolean {
    return window.innerWidth >= this.modalSize && window.innerHeight >= this.modalSize;
  }

  switchToShowWordCloud() {
    this.isShowingModal = true;
    this.analytics4Service.sendEvent('活動記録', '生徒詳細画面_活動記録タブ', '概況を見る');
    if (this.gotWordCloudResponse === 'not yet') {
      this.sendStudentNameToWordCloud();
      this.sendRecordParamsToWordCloud();
    }
  }

  sendRecordParamsToWordCloud() {
    this.wordCloudService.getWordCloudDocumentId(this.buildActivityRecordParams()).subscribe(
      (response) => {
        const document_id = response['document_id'] as string;
        switch (document_id) {
          case null:
            this.wordCloudService.setFetchErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          case 'over_count':
            this.wordCloudService.setOverCountErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          default:
            this.wordCloudService.registerWordCloudUrl(document_id);
            this.gotWordCloudDocumentId = 'success';
        }
        this.gotWordCloudResponse = 'have gotten';
      },
      (error) => {
        this.wordCloudService.setServerErrorMessage();
        this.wordCloudServerError = 'happening';
        this.gotWordCloudResponse = 'have gotten';
        this.gotWordCloudDocumentId = 'failed';
      },
    );
  }

  private buildActivityRecordParams() {
    const wordCloudStatus = { type: 'activity-record', student_id: this.studentId, title: '' };
    const wordCloudParams = Object.assign(wordCloudStatus, this.filterData);
    return wordCloudParams;
  }

  onCloseWordCloud() {
    this.isShowingModal = false;
    if (this.wordCloudServerError === 'happening') {
      this.gotWordCloudResponse = 'not yet';
      this.wordCloudServerError = 'not happening';
    }
  }

  sendStudentNameToWordCloud() {
    this.studentService.getStudentInfo(this.studentId).subscribe((student) => {
      this.wordCloudService.makeTitleAboutActivityRecord(student.name);
    });
  }
}
