export class SchoolStage {
  private _name: string;
  private stageNameMap = {
    1: '中学校',
    2: '高等学校',
    3: '小学校',
    4: '専門学校',
  };

  constructor(private id: number) {
    this._name = this.stageNameMap[id];
  }

  get name(): string {
    return this._name;
  }
}
