import { Component, Input, Renderer2, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { AuthUserService } from '@services/auth-user.service';
import { UntypedFormGroup, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { FileItem } from 'ng2-file-upload';

@Component({
  selector: 'app-story-file-uploader',
  templateUrl: './story-file-uploader.component.html',
  styleUrls: ['./story-file-uploader.component.scss'],
  providers: [S3CredentialService, UploadFilesService],
})
export class StoryFileUploaderComponent extends FileUploaderComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() formErrors: { [key: string]: Array<string> };
  @Output() anyFileExists: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly MaxFilesCount = 50;
  filesRemoveNumber = 0;

  constructor(
    protected s3CredentialService: S3CredentialService,
    protected uploadFilesService: UploadFilesService,
    protected renderer: Renderer2,
    public authUserService: AuthUserService,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(s3CredentialService, uploadFilesService, authUserService, renderer, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    const formUploadFiles = <UntypedFormArray>this.parentForm.controls.upload_files_attributes;

    if (this.attachedFiles && this.attachedFiles.length !== 0) {
      for (let i = 0; i < this.attachedFiles.length; i++) {
        formUploadFiles.push(this.initCaptionForm(this.attachedFiles[i]));
      }
      this.anyFileExists.emit(true);
    }

    this.uploader.onAfterAddingAll = (fileItems) => {
      if (this.isNotAllowedFileExtensions(fileItems) || this.isNotAllowedFileSizes(fileItems) || this.isNotAllowedFileCountExceeded()) {
        this.removeItemsFromQueue(fileItems);
      } else {
        for (let i = 0; i < fileItems.length; i++) {
          formUploadFiles.push(this.initCaptionForm(fileItems[i]));
        }
        this.anyFileExists.emit(true);
        this.attachFilesErrMsg = null;
      }
    };
  }

  initCaptionForm(file) {
    return this.formBuilder.group({
      id: [file.id || null],
      uuid: [file.uuid || null],
      file_name: [file.file_name || file.file.name],
      file_extension: [file.file_extension || file['fileExtension']],
      thumbnail: [file.thumbnail || file._file],
      caption: [file.caption || '', [Validators.required, Validators.maxLength(50)]],
      _destroy: false,
    });
  }

  isNotAllowedFileCountExceeded() {
    if (this.getAllFilesNumber > this.MaxFilesCount) {
      this.attachFilesErrMsg = `アップロード出来るファイルの上限は50個までです。`;
      return true;
    }
    return false;
  }

  deleteFileAttach(index: number) {
    if (this.attachedFiles && this.attachedFiles[index]) {
      this.parentForm.markAsDirty();
      this.attachedFiles[index]._destroy = true;
      this.formUploadFiles[index].controls._destroy.setValue(true);
      this.filesRemoveNumber = this.attachedFiles.filter((file) => file._destroy).length;
    } else {
      this.uploader.queue[index - this.filesAttachNumber].remove();
      (<UntypedFormArray>this.parentForm.controls.upload_files_attributes).removeAt(index);
    }
    this.anyFileExists.emit(this.getAllFilesNumber !== 0);
    this.attachFilesErrMsg = null;
  }

  sendUploadedFiles() {
    const uploadedFiles = super.sendUploadedFiles();

    if (this.attachedFiles) {
      return this.attachedFiles.concat(uploadedFiles);
    }

    return uploadedFiles;
  }

  getFormErrorsCaption(index: number) {
    const captionError = this.formErrors['upload_files_attributes'];
    return captionError ? captionError[index]['caption'] : null;
  }

  getLengthCaption(index: number) {
    return this.formUploadFiles[index].controls.caption.value.length;
  }

  get formUploadFiles(): Array<UntypedFormGroup> {
    return this.parentForm.controls.upload_files_attributes['controls'];
  }

  get filesAttachNumber(): number {
    return this.attachedFiles ? this.attachedFiles.length : 0;
  }

  get getAllFilesNumber(): number {
    return this.uploader.queue.length + this.filesAttachNumber - this.filesRemoveNumber;
  }

  protected updateFileToBlob(blobItem: FileItem): void {
    const queueIndex = this.uploader.queue.findIndex((fileItem) => fileItem === blobItem);
    if (queueIndex > -1) {
      const fileIndex = this.filesAttachNumber + queueIndex;
      const attachForm = this.formUploadFiles[fileIndex];
      attachForm.controls.thumbnail.setValue(blobItem._file);
    }
  }
}
