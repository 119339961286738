<div class="category-selector">
  <label class="spen-mod-select">
    <select class="select" data-cy="activity-category" [class.unselect-option]="isUnselectCategory" (change)="onChangeCategory($event)">
      <option class="is-all-point select-option" [value]="null" [selected]="isSelectedCategory(null)">
        {{ emptyLabel }}
      </option>
      <option
        class="is-part-point select-option"
        *ngFor="let category of categories"
        [value]="category.id"
        [selected]="isSelectedCategory(category)"
      >
        {{ category.name }}
      </option>
    </select>
    <i class="fa fa-caret-down"></i>
  </label>
  <label class="spen-mod-select">
    <select
      class="select"
      data-cy="sub-activity-category"
      [disabled]="isNoSubCategories()"
      [class.unselect-option]="isUnselectSubCategory"
      (change)="onChangeSubCategory($event)"
    >
      <option class="is-all-point select-option" [value]="null" [selected]="isSelectedSubCategory(null)">
        {{ emptyLabel }}
      </option>
      <option
        class="is-part-point select-option"
        *ngFor="let subCategory of subCategories"
        [value]="subCategory.id"
        [selected]="isSelectedSubCategory(subCategory)"
      >
        {{ subCategory.name }}
      </option></select
    ><i class="fa fa-caret-down"></i>
  </label>
</div>
