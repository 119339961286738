import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements OnInit, AfterViewInit {
  @Input() tabOptions: { [key: string]: string };
  @Input() tabBadge = {};
  @Input() disableChangeTab: boolean;
  @Input() disableReloadWhenChangeTab: boolean;
  @Output() switchTab = new EventEmitter<Object>();

  currentTab: { [key: string]: string };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.initTabView();
  }

  ngAfterViewInit() {
    this.scrollIntoTab(this.currentTab.key);
  }

  initTabView() {
    const tabOptions = this.tabOptions || {};
    this.route.queryParams.subscribe((params) => {
      const defaultTabKey = !!tabOptions[params['tab']] ? params['tab'] : Object.keys(tabOptions)[0];
      if (!defaultTabKey) {
        return;
      }

      this.currentTab = this.createTabInfo(defaultTabKey);
      if (!this.disableReloadWhenChangeTab) {
        this.switchTab.emit(this.currentTab);
      }
    });
  }

  isActiveTab(tabKey: string) {
    return this.currentTab.key === tabKey;
  }

  onChangeTab(tabKey: string) {
    if (this.disableChangeTab) {
      return;
    }

    if (this.disableReloadWhenChangeTab) {
      this.currentTab = this.createTabInfo(tabKey);
      this.switchTab.emit(this.currentTab);
    } else {
      this.router.navigate(['.'], { queryParams: { tab: tabKey }, replaceUrl: true, relativeTo: this.route });
    }
    this.scrollIntoTab(tabKey);
  }

  createTabInfo(defaultTabKey: string) {
    return { key: defaultTabKey, value: this.tabOptions[defaultTabKey] };
  }

  scrollIntoTab(tabKey: string) {
    const scrollList = document.getElementsByClassName('scroll-menu')[0];
    const element = document.getElementById(this.getTabHtmlId(tabKey));

    if (!!scrollList && !!element) {
      scrollList.scrollLeft = element.offsetLeft - (window.innerWidth - element.clientWidth) / 2;
    }
  }

  get tabKeys() {
    return Object.keys(this.tabOptions);
  }

  tabValue(tabKey: string) {
    return this.tabOptions[tabKey];
  }

  getTabHtmlId(tabKey: string) {
    return 'tab-' + this.tabValue(tabKey);
  }
}
