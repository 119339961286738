import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';

import { LayoutService } from '@components/layout/layout.service';
import { StoryService } from '@components/story/story.service';
import { ClassesFilterService } from '@components/school-grade-class-select/classes-filter.service';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';

import { Student } from '@models/student';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { pickBy, omit, mapValues, merge, isEqual, pick, isNil } from 'lodash';
import { AuthUserService } from '@services/auth-user.service';
import { checkPresentString } from '@functions/check-string';
import { SchoolGradeClassSelectComponent } from '@components/school-grade-class-select/school-grade-class-select.component';
import { environment } from '@environments/environment';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

const IconSort = {
  none: 'fa',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-stories-list',
  templateUrl: './stories-list.component.html',
  styleUrls: ['./stories-list.component.scss'],
  providers: [ClassesFilterService],
})
export class StoriesListComponent implements OnInit {
  @ViewChild(SchoolGradeClassSelectComponent) formChild: SchoolGradeClassSelectComponent;

  readonly MAX_NUMBER_STORIES = environment.maxNumberStories;

  private apiEndPointV2 = environment.apiEndpointV2;
  filterForm: UntypedFormGroup;
  isMobileFilterOpen = false;
  queryParams = {};
  students: Array<Student>;
  errMsg: string;
  sortKey = 'class_name';
  sortOrder = 'asc';
  messageFilter: string;
  messageSort: string;
  isStoryDownloading = false;

  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
    is_there_any_student: true,
  };

  sortSelections = [
    { value: 'class_name asc', text: '校種学年組番（昇順）' },
    { value: 'class_name desc', text: '校種学年組番（降順）' },
    { value: 'last_updated_story_time asc', text: '更新日時（昇順）' },
    { value: 'last_updated_story_time desc', text: '更新日時（降順）' },
    { value: 'number_stories asc', text: '作成数（昇順）' },
    { value: 'number_stories desc', text: '作成数（降順）' },
  ];

  sortStatus = {
    class_name: IconSort.none,
    number_stories: IconSort.none,
    last_updated_story_time: IconSort.none,
  };

  formLoaded = false;
  listLoaded = false;
  isSorting = false;

  private readonly allowedParamKeys = [
    'school_year',
    'grade_id',
    'class_id',
    'sort_key',
    'sort_order',
    'page',
    'keyword',
    'class_name',
    'number_stories',
    'last_updated_story_time',
  ];
  private readonly disallowedUrlParamKeys = ['keyword'];
  readonly saveConditionKeys = ['school_year', 'grade_id', 'class_id', 'sort_key', 'sort_order'];

  @ViewChild('storiesDownloadLink') storiesDownloadLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private storyService: StoryService,
    private classFilterService: ClassesFilterService,
    private authUserService: AuthUserService,
    private navigationService: TeacherNavigationService,
    private analytics4Service: GoogleAnalytics4Service,
    public layoutService: LayoutService,
    private searchConditionSavingService: SearchConditionSavingService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    await this.classFilterService.getSchoolYears();
    await this.classFilterService.getGrades();

    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 0) {
      newParams = this.searchConditionSavingService.getSearchConditions(url);
      this.classFilterService.isReinitSelectData = true;
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
    }

    this.setQueryParamsFilter(newParams);
    await this.initForm();
    this.initSortIcon();
    this.loadStudentsList(pickBy(this.queryParams));
    this.buildMessageFilter(this.queryParams).then((message) => {
      this.messageFilter = message;
    });
    this.messageSort = this.buildMessageSort();
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  setQueryParamsFilter(params = {}) {
    this.queryParams = pick(params, this.allowedParamKeys);
    this.queryParams['school_year'] = params['school_year'] || this.getCurrentYear();
    this.queryParams['grade_id'] = +params['grade_id'] || this.classFilterService.grades.value[0].id;
    this.queryParams['class_id'] = +params['class_id'] || null;
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return this.classFilterService.schoolYears.value.includes(currentUser.schoolYear)
      ? currentUser.schoolYear
      : this.classFilterService.schoolYears.value[0];
  }

  async initForm() {
    const params = this.queryParams;
    await this.classFilterService.getClasses(params);

    this.filterForm = this.formBuilder.group({
      school_year: params['school_year'],
      grade_id: params['grade_id'],
      class_id: params['class_id'],
      keyword: params['keyword'] || '',
      sort: params['sort_key'] ? `${params['sort_key']} ${params['sort_order']}` : this.sortSelections[0].value,
    });
    this.formLoaded = true;
  }

  loadStudentsList(params = {}) {
    if (params['class_id'] === 'null') {
      params = omit(params, 'class_id');
    }
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);
    if (this.isSorting) {
      this.sortStatus[this.sortKey] = IconSort.loader;
    } else {
      this.listLoaded = false;
    }

    this.storyService.getTeacherStoriesList(merge(params, { default_filter: this.isDefaultForm })).subscribe(
      (response) => {
        this.students = response.users;
        Object.assign(this.metaData, response.pages);

        if (this.listLoaded) {
          this.isSorting = false;
        } else {
          this.listLoaded = true;
        }

        const icon = this.sortOrder === 'asc' ? 'up' : 'down';
        this.sortStatus[this.sortKey] = IconSort[icon];
      },
      (error) => {
        this.students = [];
        this.metaData.total_pages = 1;
        this.metaData.total_count = 0;
        this.listLoaded = true;
        this.errMsg = <any>error;
      },
    );
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  toggleFilter() {
    this.isMobileFilterOpen = !this.isMobileFilterOpen;
  }

  onFilter() {
    if (checkPresentString(this.filterForm.value.keyword)) {
      this.trimTextInput();
    } else {
      this.filterForm.get('keyword').setValue('');
    }
    window.scrollTo(0, 0);
    this.isMobileFilterOpen = false;
    this.buildMessageFilter(this.queryParams).then((message) => {
      this.messageFilter = message;
    });
    const params = omit(this.filterForm.value, 'sort');
    params['request_timestamp'] = Date.now();
    this.navigate(merge(params, this.buildSortParams()), 'merge');
  }

  onChangeOrder(field) {
    if (this.isSorting) {
      return;
    }

    if (this.sortKey === field) {
      this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortKey = field;
      this.sortOrder = 'desc';
    }

    this.filterForm.get('sort').setValue(`${this.sortKey} ${this.sortOrder}`);
    this.isSorting = true;

    this.navigate(merge(this.queryParams, this.buildSortParams()), 'merge');
  }

  onResetFilter() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[0].value.split(' ');
    }

    this.filterForm.patchValue(this.defaultFilterValues());
    this.onFilter();
    this.formChild.onResetForm();
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  onClickStoryItem(student) {
    if (student.viewer_permission) {
      this.navigationService.registerNavigator({ screenId: 4, filterData: omit(pickBy(this.queryParams), 'page') });
      this.router.navigateByUrl(`/stories/teacher/students/${student.id}`);
    }
  }

  exportStory() {
    if (this.isEmptyList) {
      return;
    }
    this.isStoryDownloading = true;
    this.buildMessageFilter(this.queryParams).then((returnValue) => {
      let params = pickBy(this.queryParams);
      if (params['class_id'] === 'null') {
        params = omit(params, 'class_id');
      }
      params['filter_text'] = returnValue;
      this.storyService.getExportStories(params).subscribe(
        (response) => {
          const anchor = this.storiesDownloadLink.nativeElement;
          anchor.setAttribute('href', response.url);
          anchor.click();
          anchor.removeAttribute('href');

          this.analytics4Service.sendEvent('ストーリー', 'ストーリー一覧画面', 'CSV出力');

          this.isStoryDownloading = false;
        },
        (error) => {
          this.isStoryDownloading = false;
        },
      );
    });
  }

  private trimTextInput() {
    const keyword = this.filterForm.value.keyword;
    this.filterForm.get('keyword').setValue(keyword.replace(/\s/g, ''));
  }

  buildSortParams() {
    const sortParams = { sort_key: this.sortKey, sort_order: this.sortOrder };
    if (this.queryParams['page']) {
      sortParams['page'] = null;
    }
    return sortParams;
  }

  private initSortIcon() {
    this.sortKey = this.queryParams['sort_key'] || this.sortKey;
    this.sortOrder = this.queryParams['sort_order'] || this.sortOrder;
    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = IconSort[icon];
  }

  defaultFilterValues() {
    const filterValues = {
      school_year: this.getCurrentYear(),
      grade_id: this.classFilterService.grades.value[0].id,
      class_id: null,
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort'] = this.sortSelections[0].value;
    }

    return filterValues;
  }

  async buildMessageFilter(params = {}) {
    let gradeName = null;
    const textsArray = [];

    const schoolYear = this.filterForm.get('school_year').value + '年度';
    const grade = this.classFilterService.grades.value.find((item) => {
      return item.id === +params['grade_id'];
    });

    if (grade) {
      gradeName = grade.school_stage_name + grade.name;
    } else {
      gradeName = this.classFilterService.grades[0].school_stage_name + this.classFilterService.grades[0].name;
    }
    if (!!this.classFilterService.classes.value && !this.listLoaded) {
      await this.classFilterService.getClasses(params);
    }

    const _class = this.classFilterService.classes.value.find((item) => {
      return item.id === +params['class_id'];
    });
    const className = _class ? _class.classroom_name : 'すべての組';
    textsArray.push(schoolYear, gradeName, className);

    const keyword = this.filterForm.get('keyword').value;
    if (keyword && keyword.length > 0) {
      textsArray.push(keyword);
    }
    return textsArray.join('/');
  }

  buildMessageSort() {
    const match = this.sortSelections.find((item) => {
      return item.value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match && match.text;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.filterForm.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort'];
    }

    return isEqual(compareValues, this.defaultFilterValues());
  }

  get showPagination(): boolean {
    return this.metaData.total_pages > 1;
  }

  get isEmptyList(): boolean {
    return this.metaData.total_count === 0;
  }

  get isEmptyData(): boolean {
    return !this.metaData['is_there_any_student'];
  }

  get isLoadingData(): boolean {
    return this.classFilterService.isLoading || !this.listLoaded || this.isSorting;
  }

  get isSortingByNumberStories(): boolean {
    return this.sortKey === 'number_stories';
  }
}
