import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appPlaceholder]',
})
export class PlaceholderDirective implements OnInit {
  @Input() appPlaceholder: string;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.element.nativeElement.setAttribute('placeholder', this.appPlaceholder);
  }
}
