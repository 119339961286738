import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/components/shared.module';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { SpaceService } from './space.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { SpaceTeacherModule } from './space-teacher.module';
import { SpaceStudentModule } from './space-student.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    SpaceStudentModule,
    SpaceTeacherModule,
  ],
  providers: [SpaceService, SearchConditionSavingService],
})
export class SpaceModule {}
