<div class="spen-ly-main">
  <div class="spen-mod-heading lv-1">お探しのページは見つかりませんでした</div>
  <div class="main-content">
    <p class="error-message">以下の状況が考えられます。</p>
    <ul class="error-message-list">
      <li>
        ご利用中のサービス以外のページへアクセスされている<br />
        利用サービス設定を見直してから、再度アクセスしてください。
      </li>
      <li>
        一時的にアクセスできない状況になっている<br />
        時間をおいて再度アクセスしてください。
      </li>
    </ul>
  </div>
</div>
