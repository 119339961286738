import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ControlLinkService {
  constructor(private router: Router) {}

  open(url: string, event: KeyboardEvent, options = {}) {
    if (event.ctrlKey || event.metaKey) {
      window.open(url);
    } else {
      if ('state' in options) {
        this.router.navigateByUrl(url, options);
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }
}
