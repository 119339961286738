import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { StoryCommentListTeacherComponent } from './story-comment-list/story-comment-list-teacher.component';
import { StoryCommentDetailTeacherComponent } from './story-comment-detail/story-comment-detail-teacher.component';
import { StoryCommentFormTeacherComponent } from './story-comment-form/story-comment-form-teacher.component';
import { StoryCommentTeacherService } from './story-comment-teacher.service';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';

@NgModule({
  declarations: [StoryCommentListTeacherComponent, StoryCommentDetailTeacherComponent, StoryCommentFormTeacherComponent],
  exports: [StoryCommentListTeacherComponent, StoryCommentDetailTeacherComponent, StoryCommentFormTeacherComponent],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, SharedDirectivesModule, SharedModule],
  providers: [StoryCommentTeacherService],
})
export class StoryCommentTeacherModule {}
