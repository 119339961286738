<div class="tea-create-pickup" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="formActivityPickupService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="form-horizontal">
      <div
        class="form-group space-input"
        [class.space-input-edit]="
          (isEditPickup && !formActivityPickupService.space) || form.controls['space_id'].hasError('equalForbidValue')
        "
      >
        <div class="form-filter">
          <div class="label-title">
            <label class="label" id="pickupInvalid">公開先スペース</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <ng-container *ngIf="formActivityPickupService.space; else multiSpace">
            <div class="space-info">
              <div class="space-info-edit">
                <div class="block-color" [style.background-color]="formActivityPickupService.space.color_code"></div>
                <span class="truncate-text">{{ formActivityPickupService.space.name }}</span>
                <a class="spen-util-link" (click)="findSpace()">変更する</a>
              </div>

              <div
                class="input-space-offset"
                [class.edit-id-invalid]="form.controls['space_id'].hasError('equalForbidValue')"
                *ngIf="formErrors['space_id']"
              >
                <div class="spen-mod-error-content error-popup">
                  {{ formErrors['space_id'].join(' ') }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #multiSpace>
            <div class="space-content">
              <div class="list-space" *ngIf="formActivityPickupService.spaces.length > 0">
                <div class="selecting-space" *ngFor="let space of formActivityPickupService.spaces">
                  <div class="space-color" [style.background-color]="space.color_code"></div>
                  <p class="space-name truncate-text">{{ space.name }}</p>
                  <a class="spen-util-link" (click)="removeSpace(space)">削除する</a>
                </div>
              </div>
              <label class="space-select-box" [class.remove-border-top]="formActivityPickupService.spaces.length > 0" (click)="findSpace()">
                <i class="fa fa-plus-circle"></i>
                <span class="spen-util-link">{{ formActivityPickupService.spaces.length === 0 ? '選択する' : '追加する' }}</span>
              </label>

              <input type="hidden" formControlName="space_ids" />
              <div class="error-popup" *ngIf="formErrors['space_ids']">
                <div class="spen-mod-error-content">
                  {{ formErrors['space_ids'].join(' ') }}
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="form-group form-input step-1">
        <div class="form-filter align-center">
          <div class="label-title">
            <label class="label">ピックアップ表示期間</label>
          </div>
          <div class="flex-container">
            <div class="input-date">
              <app-datepicker
                [isPopup]="true"
                [isFixedDropdown]="true"
                (modelChange)="updateEndTime($event)"
                [selectedDate]="formActivityPickupService.endTime"
                [includeResponsive]="true"
              ></app-datepicker>
            </div>
            <div class="label-date">
              <span class="until-label">まで</span>
            </div>
          </div>
        </div>
        <div class="input-space-offset" *ngIf="formErrors['end_time']">
          <div class="spen-mod-error-content error-popup end-time-error">
            {{ formErrors['end_time'].join(' ') }}
          </div>
        </div>
      </div>

      <div class="form-group form-input">
        <div class="form-filter">
          <div class="label-title title-self-center">
            <label class="label">匿名設定</label>
          </div>
          <div class="input-space group-radio">
            <label class="spen-mod-radio">
              <input
                class="radio"
                [checked]="!form.get('is_anonymous').value"
                type="radio"
                [value]="false"
                formControlName="is_anonymous"
              />
              <i class="fa"></i>
              <span class="text">匿名にしない</span>
            </label>
            <div class="clear-fix" *ngIf="layoutService.isTabletDownView.value"></div>
            <label class="spen-mod-radio">
              <input
                class="radio"
                [checked]="!!form.get('is_anonymous').value"
                type="radio"
                [value]="true"
                formControlName="is_anonymous"
              />
              <i class="fa"></i>
              <span class="text">匿名にする</span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group form-input textarea-input">
        <div class="form-filter">
          <div class="label-title">
            <label class="label">説明</label>
          </div>
          <div class="input-space input-textarea">
            <textarea
              class="spen-mod-input-text"
              [appPlaceholder]="'例）できるようになったことや成長したことを踏まえて、次の学びにつながるように書かれていて素晴らしいです。'"
              formControlName="reason"
              maxlength="1000"
            >
            </textarea>
            <div class="spen-mod-error-content error-popup" *ngIf="formErrors['reason']">
              {{ formErrors['reason'].join(' ') }}
            </div>
          </div>
        </div>
      </div>
      <div class="list-button-submit" *ngIf="!layoutService.isTabletDownView.value; else buttonSubmitSP">
        <button type="button" class="spen-mod-btn" (click)="goBack()" [disabled]="disabledSubmit">戻る</button>
        <div class="delete-pickup">
          <div class="delete-button" (click)="deletePickup()" *ngIf="isEditPickup()">
            <i class="fa fa-trash-o"></i>
            <span class="spen-util-link">ピックアップを取り消す</span>
          </div>
          <button type="button" (click)="onSubmit()" [disabled]="disabledSubmit" class="spen-mod-btn is-primary">確認へ</button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #buttonSubmitSP>
  <div class="list-button-submit">
    <div class="delete-pickup" *ngIf="isEditPickup()">
      <div class="delete-button" (click)="deletePickup()">
        <i class="fa fa-trash-o"></i>
        <span class="spen-util-link">ピックアップを取り消す</span>
      </div>
    </div>
    <button type="button" class="spen-mod-btn" (click)="goBack()">戻る</button>
    <button type="button" (click)="onSubmit()" class="spen-mod-btn is-primary">確認へ</button>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>

<app-search-multi-space
  *ngIf="isShowSearchModalMulti"
  (closeEvent)="closeSearch()"
  (submitEvent)="onSelectMultiSpace($event)"
  [selectedSpaces]="formActivityPickupService.spaces"
  [activityRecordId]="formActivityPickupService.activityRecordId"
>
</app-search-multi-space>

<app-search-single-space
  *ngIf="isShowSearchModalSingle"
  (closeEvent)="closeSearch()"
  (submitEvent)="onSelectSpace($event)"
  [selectedSpace]="formActivityPickupService.space"
  [activityRecordId]="formActivityPickupService.activityRecordId"
  [spaceEditId]="formActivityPickupService.originalSpaceEditId"
>
</app-search-single-space>
