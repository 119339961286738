export function parseJsonItem<T>(outputClass: new () => T, inputItem: Object): T {
  if (inputItem) {
    return Object.assign(new outputClass(), inputItem);
  }
}

export function parseJsonList<T>(outputClass: new () => T, inputList: Array<Object>): Array<T> {
  if (!!inputList) {
    return inputList.map((item) => parseJsonItem<T>(outputClass, item));
  } else {
    return [];
  }
}
