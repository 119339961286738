import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-student-footer',
  templateUrl: './student-footer.component.html',
  styleUrls: ['./student-footer.component.scss'],
})
export class StudentFooterComponent {
  readonly helpPageUrl = environment.helpPageUrl;
  readonly noticeUrl = `${environment.authUrl}/students/notice`;

  constructor() {}
}
