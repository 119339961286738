import { merge } from 'lodash';
import { Component, OnInit, Output, EventEmitter, Renderer2, OnDestroy, Input } from '@angular/core';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { StudentService } from '../student.service';

@Component({
  selector: 'app-list-activity-modal',
  templateUrl: './list-activity-modal.component.html',
  styleUrls: ['./list-activity-modal.component.scss'],
})
export class ListActivityModalComponent implements OnInit, OnDestroy {
  @Input() showManaVisionOption = true;
  @Output() eventSelectActivityModal = new EventEmitter<boolean>();
  @Output() eventShowActivity = new EventEmitter<ActivityRecord>();
  activitiesLoaded = false;
  isLoading = false;
  activities: Array<ActivityRecord>;
  errMsg: string;
  isShowFilter = false;
  filterStatus = 'none';
  nextPage = null;
  total_records = 0;
  searchParams = {};

  constructor(
    private renderer: Renderer2,
    private studentService: StudentService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.getActivities();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPage) {
      return;
    }
    this.isLoading = true;
    this.studentService.getHistoryActivityRecord(merge(this.searchParams, { page: this.nextPage })).subscribe(
      (response) => {
        this.activities.push(...response['activity_records']);
        this.nextPage = response['next_page'];
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  async doFilter(params = {}) {
    if (!this.isShowFilter) {
      return false;
    }
    if (this.filterStatus === 'processing' || this.filterStatus === 'wait') {
      this.filterStatus = 'wait';
      return true;
    }
    this.filterStatus = 'processing';
    this.searchParams = params;
    this.studentService.getHistoryActivityRecord(params).subscribe(
      (response) => {
        this.activities = response['activity_records'];
        this.nextPage = response['next_page'];
        this.total_records = response['total_records'];
        if (this.filterStatus === 'wait') {
          this.filterStatus = 'none';
          this.doFilter();
        } else {
          this.filterStatus = 'none';
        }
      },
      (error) => {
        this.filterStatus = 'none';
      },
    );
  }

  closeFilter() {
    this.isShowFilter = false;
    this.getActivities(``);
  }

  getActivities(params = {}) {
    this.studentService.getHistoryActivityRecord(params).subscribe(
      (response) => {
        this.activities = response['activity_records'];
        this.nextPage = response['next_page'];
        this.activitiesLoaded = true;
        this.total_records = response['total_records'];
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  showFilter() {
    this.isShowFilter = true;
  }

  closeSelectActivityModal() {
    this.eventSelectActivityModal.emit(false);
  }

  dataLoaded() {
    return this.activitiesLoaded;
  }

  handleClickActivity(activityRecord: ActivityRecord) {
    this.eventShowActivity.emit(activityRecord);
  }
}
