<ng-container [ngSwitch]="pickupType">
  <ng-container *ngSwitchCase="'own_activity'">
    <div class="pickup-for-own">
      <div class="pickup" (click)="clickPickup()">
        <img class="pickup-icon" src="assets/images/icons/pickup-active.svg" />
        <span class="spen-util-link">{{ this.getPickupTitle() }}</span>
        <i class="{{ getClassIconArrow() }}"></i>
      </div>
      <div class="info" *ngIf="display">
        <div class="reason preserve-line-break" appConvertLink>{{ this.getPickupReason() }}</div>
        <ng-container *ngIf="pickupExpired(); else spaceLink">
          <span class="space-name">{{ pickup.space.name }}</span>
        </ng-container>
        <ng-template #spaceLink>
          <span routerLink="/spaces/student/{{ pickup?.space.id }}" class="space-name spen-util-link">{{ pickup.space.name }}</span>
        </ng-template>
        <p *ngIf="pickup.is_anonymous">匿名</p>
        <div class="pickup-time">ピックアップ期間：{{ pickup.end_time | date: 'yyyy/MM/dd' }} まで</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'public_activity'">
    <div class="pickup-for-public">
      <div class="pickup" [class.no-reason]="isNoReason" (click)="clickPickup()">
        <img class="pickup-icon" src="{{ this.getIconSrc() }}" />
        <span [class.spen-util-link]="!isNoReason">{{ this.getPickupTitle() }}</span>
        <i *ngIf="!isNoReason" class="{{ getClassIconArrow() }}"></i>
      </div>
      <div class="reason preserve-line-break" *ngIf="display" appConvertLink>{{ this.getPickupReason() }}</div>
    </div>
  </ng-container>
</ng-container>
