import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { LayoutComponent } from './layout.component';
import { TeacherLayoutComponent } from './teacher-layout/teacher-layout.component';
import { StudentLayoutComponent } from './student-layout/student-layout.component';
import { ContentComponent } from './content/content.component';
import { TeacherHeaderComponent } from './teacher-layout/teacher-header/teacher-header.component';
import { StudentHeaderComponent } from './student-layout/student-header/student-header.component';
import { StudentFooterComponent } from './student-layout/student-footer/student-footer.component';
import { TeacherFooterComponent } from './teacher-layout/teacher-footer/teacher-footer.component';
import { TeacherSidebarComponent } from './sidebar/teacher-sidebar/teacher-sidebar.component';
import { StudentSidebarComponent } from './sidebar/student-sidebar/student-sidebar.component';
import { GlobalMenuComponent } from './global-menu/global-menu.component';
import { HomeComponent } from './home/home.component';
import { H1HeadingTitleComponent } from './h1-heading-title/h1-heading-title.component';
import { SharedModule } from '@components/shared.module';

@NgModule({
  declarations: [
    LayoutComponent,
    TeacherLayoutComponent,
    H1HeadingTitleComponent,
    StudentLayoutComponent,
    ContentComponent,
    TeacherHeaderComponent,
    StudentHeaderComponent,
    StudentFooterComponent,
    TeacherFooterComponent,
    TeacherSidebarComponent,
    StudentSidebarComponent,
    HomeComponent,
    GlobalMenuComponent,
  ],
  exports: [LayoutComponent],
  imports: [CommonModule, RouterModule, SharedModule],
})
export class LayoutModule {}
