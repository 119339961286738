import { UploadFile } from '@models/upload-file';
import { User } from '@models/user';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { Space } from '@components/space/space';
import { WorkQuestion } from '@components/work/work-question';

export class Work {
  id: number;
  title: string;
  comment: string;
  template_title: string;
  template_content: string;
  category_id: number;
  category_name: string;
  created_at: string;
  updated_at: string;
  delivered_at?: string;
  upload_files?: Array<UploadFile>;
  upload_files_attributes?: Array<Object>;
  create_user?: User;
  answers_count?: number;
  deliveries_count?: number;
  activity_record?: ActivityRecord;
  answer_state?: string;
  space?: Space;
  can_destroy: boolean;
  work_questions?: Array<WorkQuestion>;
  work_type: string | number;
  work_template_id?: number;
  master_id?: number;
  group_question_title: string;
  album_title?: string;
  album_id?: number;
  space_name?: string;
  is_compass_template?: boolean;
  answer_title?: string;
  work_questions_attributes?: Array<WorkQuestion>;
  is_compass_work?: boolean;
}

export const WorkType = { single: 'single', multiple: 'multiple' };
