import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ActivityPickupService } from './activity-pickup.service';

import { FormActivityPickupComponent } from './form-activity-pickup/form-activity-pickup.component';
import { Step1Component } from './form-activity-pickup/step1/step1.component';
import { Step2Component } from './form-activity-pickup/step2/step2.component';
import { SearchMultiSpaceComponent } from './form-activity-pickup/search-space/search-multi-space/search-multi-space.component';
import { SearchSingleSpaceComponent } from './form-activity-pickup/search-space/search-single-space/search-single-space.component';

@NgModule({
  declarations: [FormActivityPickupComponent, Step1Component, Step2Component, SearchSingleSpaceComponent, SearchMultiSpaceComponent],
  exports: [FormActivityPickupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, SharedDirectivesModule, InfiniteScrollModule],
  providers: [ActivityPickupService],
})
export class ActivityPickupModule {}
