<div class="gallery" *ngIf="isShow" (click)="onClose()" [class.loading]="isLoading" [class.is-show]="isShow">
  <div class="gallery-inner" #gallery_inner>
    <div class="gallery-content" #gallery_content>
      <img
        class="gallery-img"
        data-cy="gallery-image"
        *ngIf="currentItem.isImage()"
        (click)="preventCloseOnContent($event)"
        (load)="onContentLoad()"
        [src]="currentItem.previewLink()"
      />
      <video
        class="gallery-video"
        data-cy="gallery-video"
        *ngIf="currentItem.isVideo()"
        controls
        autoplay
        (click)="preventCloseOnContent($event)"
        (loadeddata)="onContentLoad()"
        [src]="currentItem.previewLink()"
      ></video>
      <audio
        class="gallery-audio"
        data-cy="gallery-audio"
        *ngIf="currentItem.isAudio()"
        controls
        autoplay
        (click)="preventCloseOnContent($event)"
        (loadeddata)="onContentLoad()"
        [src]="currentItem.previewLink()"
      ></audio>
      <p class="gallery-caption" data-cy="gallery-caption" #gallery_caption>
        {{ currentItem.file_name }}
      </p>
    </div>
    <div class="gallery-loader" *ngIf="isLoading"></div>
    <button class="previous-button gallery-button" *ngIf="canNavigate()" (click)="onPrevious($event)"></button>
    <button class="next-button gallery-button" *ngIf="canNavigate()" (click)="onNext($event)"></button>
  </div>
</div>
