import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ActivitiesResponse } from './activities-response';
import { AlbumsResponse } from './student-detail/albums/albums-response';
import { User } from '@models/user';
import { ActivityRecordsExportResponse } from '@components/student/activity-records-export-response';
import { StudentActivitiesResponse } from '@components/student/student-detail/activity-records/student-activities-response';
import { StudentManavisionActivitiesResponse } from '@components/student/student-detail/manavision/student-manavision-activities-response';
import { StudentUnsubmittedWorksResponse } from '@components/student/student-detail/unsubmitted-works/student.unsubmit-works-response';
import { ActionsResponse } from '@components/timeline/actions-response';
import { StudentsListResponse } from '@components/student/students-list/students-list-response';

@Injectable()
export class StudentService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getStudentActivities(studentId: number, queryParams: {}): Observable<ActivitiesResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/activity_records`;
    return this.http.get<ActivitiesResponse>(url, { params: queryParams });
  }

  getStudentInfo(studentId): Observable<User> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}`;
    return this.http.get<User>(url);
  }

  getStudentActivityRecords(studentId: number, params = {}): Observable<StudentActivitiesResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/activity_records/search`;
    return this.http.post<StudentActivitiesResponse>(url, params);
  }

  getAlbums(studentId: number, params = {}): Observable<AlbumsResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/albums/search`;
    return this.http.post<AlbumsResponse>(url, params);
  }

  getManavisionRecords(studentId: number, params = {}): Observable<StudentManavisionActivitiesResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/activity_records/search`;
    params = Object.assign(params, { service: 'manavision' });
    return this.http.post<StudentManavisionActivitiesResponse>(url, params);
  }

  getStudentDetailActions(studentId: number, params = {}): Observable<ActionsResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/actions`;
    return this.http.get<ActionsResponse>(url, { params: params });
  }

  getTeacherStudentsList(params = {}): Observable<StudentsListResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/search`;
    return this.http.post<StudentsListResponse>(url, params);
  }

  getUnsubmitWorks(studentId: number, params = {}): Observable<StudentUnsubmittedWorksResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/unsubmitted_works/search`;
    return this.http.post<StudentUnsubmittedWorksResponse>(url, params);
  }

  getExportActivityRecords(params = {}): Observable<ActivityRecordsExportResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/export`;
    return this.http.post<ActivityRecordsExportResponse>(url, params);
  }
}
