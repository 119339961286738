import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';

import { CoverImageUploaderComponent } from './cover-image-uploader.component';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { SharedModule } from '@components/shared.module';

@NgModule({
  declarations: [CoverImageUploaderComponent],
  exports: [CoverImageUploaderComponent, FileUploadModule],
  imports: [CommonModule, FileUploadModule, SharedDirectivesModule, SharedPipesModule, SharedModule],
})
export class CoverImageUploaderModule {}
