import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthUserService } from '@services/auth-user.service';
import { isMobileApp } from '@functions/browser';
import { SlideMenuService } from '@services/slide-menu.service';
import { LayoutService } from '@components/layout/layout.service';
import { RouterDataService } from '@services/router-data.service';

import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-teacher-layout',
  templateUrl: './teacher-layout.component.html',
  styleUrls: ['./teacher-layout.component.scss'],
})
export class TeacherLayoutComponent implements OnInit, OnDestroy {
  isShowSidebar = true;
  sub: Subscription;

  constructor(
    public authUserService: AuthUserService,
    public layoutService: LayoutService,
    private slideMenuService: SlideMenuService,
    public routerDataService: RouterDataService,
  ) {}

  ngOnInit() {
    this.sub = combineLatest([
      this.layoutService.isMediumDesktopDownView$,
      this.slideMenuService.slideStatus$,
      this.routerDataService.routerData$,
    ]).subscribe(([isMediumDesktopDownView, slideStatus, routerData]) => {
      if (!this.authUserService.isLoggedIn) {
        return;
      }

      if (isMediumDesktopDownView) {
        this.isShowSidebar = slideStatus === 'in';
      } else {
        this.isShowSidebar = routerData.isShowSidebar;
      }
    });
  }

  ngOnDestroy() {
    if (!!this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }

  get isMobileApp() {
    return isMobileApp();
  }
}
