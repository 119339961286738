import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalMenu } from '@models/global-menu';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-global-menu',
  templateUrl: './global-menu.component.html',
  styleUrls: ['./global-menu.component.scss'],
})
export class GlobalMenuComponent implements OnInit, OnDestroy {
  globalMenuData: Array<GlobalMenu>;
  errorMsg: string;
  isOpenMenu = false;
  isLoadingMenu = false;
  subscription: Subscription;

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.subscription = this.layoutService.isOpenGlobalMenu$.subscribe((isOpen) => {
      if (isOpen && !this.isLoadingMenu) {
        this.fetchGlobalMenu();
      }
    });
  }

  fetchGlobalMenu() {
    this.isLoadingMenu = true;
    this.layoutService.getGlobalMenu().subscribe(
      (response) => {
        this.globalMenuData = response;
        this.isOpenMenu = true;
        this.isLoadingMenu = false;
      },
      (error) => {
        this.errorMsg = <any>error;
        this.isLoadingMenu = false;
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleMenu() {
    this.isOpenMenu = !this.isOpenMenu;
  }
}
