import { Component, OnInit, Input, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { UploadFile } from '@models/upload-file';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { parseJsonList } from '@functions/parse-json-list';
import { StoryGalleryComponent } from '@components/story/story-gallery/story-gallery.component';

@Component({
  selector: 'app-story-files-view',
  templateUrl: './story-files-view.component.html',
  styleUrls: ['./story-files-view.component.scss'],
})
export class StoryFilesViewComponent implements OnInit, AfterViewChecked {
  apiEndPoint = environment.apiEndpoint;
  errorMsg: string;

  @ViewChild('root_div', { static: true }) root_div;
  @ViewChild('gallery', { static: true }) gallery: StoryGalleryComponent;

  @Input() files: Array<UploadFile>;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.files = parseJsonList(UploadFile, this.files);
  }

  ngAfterViewChecked() {
    this.resize();
  }

  public previewFiles(): Array<UploadFile> {
    if (!!this.files) {
      return this.files.filter((file) => file.canPreview());
    } else {
      return [];
    }
  }

  onFileClick(file: UploadFile) {
    switch (true) {
      case file.isPdf():
        window.open(file.previewLink(), '_blank');
        break;
      case file.canPreview():
        this.gallery.onPreview(file);
        break;
      default:
        window.location.href = file.downloadLink();
        break;
    }
  }

  resize() {
    if (!this.root_div) {
      return;
    }
    const imageDiv = this.root_div.nativeElement.querySelectorAll('.attach-image');
    for (const div of imageDiv) {
      this.renderer.setStyle(div, 'height', getComputedStyle(div).width);
    }
  }
}
