<div class="stu-activity-records-sp" *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="filter-box" *ngIf="formInited">
    <div class="filter-text-info">
      <div class="text-row">
        <p class="label">検索条件</p>
        <p class="filter-result" data-cy="text-filter">
          {{ filterDataService.messageFilter }}
        </p>
      </div>
      <div class="text-row">
        <p class="label">並び順</p>
        <p class="filter-result" data-cy="text-sort-order">
          {{ messageSort }}
        </p>
      </div>
    </div>
    <a class="btn-show-detail spen-util-link" (click)="onChangeShowMoreFilter()">
      <span>変更</span>
      <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
    </a>
    <form [formGroup]="form" *ngIf="isShowMoreFilter">
      <div class="filter-detail">
        <div class="selector-group">
          <div class="filter-group">
            <div class="label-text">種別</div>
            <div class="filter-inputs">
              <label class="spen-mod-select">
                <select class="select" data-cy="activity-type" formControlName="activity_type">
                  <option class="select-option" [value]="null">すべて</option>
                  <option class="select-option" value="free">自主作成</option>
                  <option class="select-option" value="answer">課題回答</option>
                  <option class="select-option" value="questionnaire">アンケート回答</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>

          <div class="filter-group">
            <div class="filter-label">キーワード</div>
            <div class="filter-inputs">
              <input
                class="spen-mod-input-text"
                data-cy="search-title"
                formControlName="keyword"
                placeholder="キーワードを入力してください"
                maxlength="100"
                type="text"
              />
            </div>
          </div>
          <div class="filter-group">
            <div class="filter-label">カテゴリー</div>
            <div class="category-filter">
              <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
              </app-teacher-categories-selection>
            </div>
          </div>
          <div class="filter-group">
            <div class="filter-label">コメント状況</div>
            <div class="filter-inputs">
              <label class="spen-mod-select">
                <select
                  class="select"
                  data-cy="comment-status"
                  formControlName="comment_status"
                  (change)="selectCommentStatus($event.target.value)"
                >
                  <option class="select-option" [ngValue]="null">すべて</option>
                  <option class="select-option" value="has_comment">コメントあり</option>
                  <option class="select-option" value="no_comment">コメントなし</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
              <label class="spen-mod-select">
                <select class="select" data-cy="sub-comment-status" formControlName="sub_comment_status">
                  <option class="select-option" value="has_comment">すべて</option>
                  <option class="select-option" value="cross">生徒コメント</option>
                  <option class="select-option" value="teacher">先生コメント</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div class="filter-label">公開状況</div>
            <div class="filter-inputs">
              <label class="spen-mod-select">
                <select
                  class="select"
                  data-cy="public-status"
                  formControlName="public_status"
                  (change)="selectPublicStatus($event.target.value)"
                >
                  <option class="select-option" [ngValue]="null">すべて</option>
                  <option class="select-option" value="shared">公開</option>
                  <option class="select-option" value="not_shared">非公開</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
              <label class="spen-mod-select">
                <select class="select" data-cy="sub-public-status" formControlName="sub_public_status">
                  <option class="select-option" value="shared">すべて</option>
                  <option class="select-option" value="self_publish">自主公開</option>
                  <option class="select-option" value="pickup">ピックアップ</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>
          <div class="filter-group">
            <div class="filter-label">マーク</div>
            <div class="filter-inputs mark">
              <label
                class="spen-mod-checkbox check-box-inline"
                data-cy="mark-ids"
                formArrayName="mark_ids"
                *ngFor="let mark of marks; let i = index"
              >
                <input class="checkbox" data-cy="mark-ids-checkbox" [formControlName]="i" type="checkbox" />
                <i class="fa"></i>
                <img class="mark-icon-fix-width" data-cy="mark-ids-icon" [src]="mark.icon" />
                <span>{{ mark.name }}</span>
              </label>
            </div>
          </div>

          <div class="filter-group">
            <div class="filter-label sort-lable">並び順</div>
            <div class="filter-inputs">
              <label class="spen-mod-select">
                <select class="select" data-cy="sort-order" formControlName="sort_value" (change)="onChangeSortValue($event.target.value)">
                  <option class="select-option" [value]="sortOption.sort_value" *ngFor="let sortOption of sortOptions">
                    {{ sortOption.text }}
                  </option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
            適用する
          </button>
          <button
            type="button"
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            (click)="onResetFilter()"
            [disabled]="isContentLoading || isDefaultForm"
          >
            選択解除
          </button>
        </div>
      </div>
    </form>
  </div>
  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="data-box">
      <div class="list-activity-records" *ngIf="activityRecords.length > 0; else noActivityRecord">
        <div class="align-contents">
          <p class="paginate-result" data-cy="text-page-info">{{ pages | paginateText }}</p>
          <div class="word-cloud-link" data-cy="show-word-cloud" *ngIf="possibleShowModal" (click)="switchToShowWordCloud()">
            <a class="spen-util-link">概況を見る</a>
          </div>
        </div>

        <div
          class="activity-record clickable-item"
          data-cy="activity-item"
          *ngFor="
            let record of activityRecords
              | paginate
                : {
                    itemsPerPage: this.pages.per_page,
                    currentPage: this.pages.current_page,
                    totalItems: this.pages.total_count,
                  }
          "
          (click)="onClickActivityRecord(record, $event)"
        >
          <div class="info">
            <p class="title" data-cy="activity-title">{{ record.title }}</p>
            <p class="create-time small-text" data-cy="activity-created-at">作成日時：{{ record.created_at }}</p>
            <p class="update-time small-text" data-cy="activity-updated-at">更新日時：{{ record.updated_at }}</p>
          </div>
          <i class="fa fa-chevron-right"></i>
        </div>
      </div>
      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #pcView>
  <div class="stu-activity-records">
    <form [formGroup]="form" *ngIf="formInited">
      <div class="filter-box">
        <div class="filter-detail">
          <div class="filter-row">
            <div class="filter-group filter-record-type">
              <div class="filter-label">種別</div>
              <div class="filter-inputs">
                <label class="spen-mod-radio" data-cy="activity-type">
                  <input class="radio" data-cy="activity-type-radio" formControlName="activity_type" type="radio" [value]="null" />
                  <i class="fa"></i>
                  <span class="text">すべて</span>
                </label>
                <label class="spen-mod-radio" data-cy="activity-type">
                  <input class="radio" data-cy="activity-type-radio" value="free" formControlName="activity_type" type="radio" />
                  <i class="fa"></i>
                  <span class="text">自主作成</span>
                </label>
                <label class="spen-mod-radio" data-cy="activity-type">
                  <input class="radio" data-cy="activity-type-radio" value="answer" formControlName="activity_type" type="radio" />
                  <i class="fa"></i>
                  <span class="text">課題回答</span>
                </label>
                <label class="spen-mod-radio" data-cy="activity-type">
                  <input class="radio" data-cy="activity-type-radio" value="questionnaire" formControlName="activity_type" type="radio" />
                  <i class="fa"></i>
                  <span class="text">アンケート回答</span>
                </label>
              </div>
            </div>

            <div class="btn-show-detail" (click)="onChangeShowMoreFilter()">
              <a class="spen-util-link">
                <span>詳細検索</span>
                <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
              </a>
            </div>
          </div>
          <ng-container *ngIf="isShowMoreFilter">
            <div class="filter-row">
              <div class="filter-group">
                <div class="filter-label">キーワード</div>
                <div class="filter-inputs">
                  <input
                    class="spen-mod-input-text"
                    data-cy="search-title"
                    placeholder="キーワードを入力してください"
                    maxlength="100"
                    formControlName="keyword"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="filter-row">
              <div class="filter-group">
                <div class="filter-label">カテゴリー</div>
                <div class="filter-inputs">
                  <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
                  </app-teacher-categories-selection>
                </div>
              </div>
            </div>
            <div class="filter-row">
              <div class="filter-group">
                <div class="filter-label">コメント状況</div>
                <div class="filter-inputs">
                  <label class="spen-mod-select">
                    <select
                      class="select"
                      data-cy="comment-status"
                      formControlName="comment_status"
                      (change)="selectCommentStatus($event.target.value)"
                    >
                      <option class="select-option" class="is-placeholder" [ngValue]="null">すべて</option>
                      <option class="select-option" value="has_comment">コメントあり</option>
                      <option class="select-option" value="no_comment">コメントなし</option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                  <label class="spen-mod-select">
                    <select class="select" data-cy="sub-comment-status" formControlName="sub_comment_status">
                      <option class="select-option" value="has_comment">すべて</option>
                      <option class="select-option" value="cross">生徒コメント</option>
                      <option class="select-option" value="teacher">先生コメント</option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
              </div>
            </div>
            <div class="filter-row">
              <div class="filter-group">
                <div class="filter-label">公開状況</div>
                <div class="filter-inputs">
                  <label class="spen-mod-select">
                    <select
                      class="select"
                      data-cy="public-status"
                      formControlName="public_status"
                      (change)="selectPublicStatus($event.target.value)"
                    >
                      <option class="select-option" [ngValue]="null">すべて</option>
                      <option class="select-option" value="shared">公開</option>
                      <option class="select-option" value="not_shared">非公開</option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                  <label class="spen-mod-select">
                    <select class="select" data-cy="sub-public-status" formControlName="sub_public_status">
                      <option class="select-option" value="shared">すべて</option>
                      <option class="select-option" value="self_publish">自主公開</option>
                      <option class="select-option" value="pickup">ピックアップ</option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
              </div>
            </div>
            <div class="filter-row">
              <div class="filter-group">
                <div class="filter-label">マーク</div>
                <div class="filter-inputs mark">
                  <label
                    class="spen-mod-checkbox check-box-inline"
                    data-cy="mark-ids"
                    formArrayName="mark_ids"
                    *ngFor="let mark of marks; let i = index"
                  >
                    <input class="checkbox" data-cy="mark-ids-checkbox" [formControlName]="i" type="checkbox" />
                    <i class="fa"></i>
                    <img class="mark-icon-fix-width" data-cy="mark-ids-icon" [src]="mark.icon" />
                    <span>{{ mark.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn right is-default"
            data-cy="remove-filter"
            (click)="onResetFilter()"
            [disabled]="isContentLoading || isDefaultForm"
          >
            選択解除
          </button>
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
            検索する
          </button>
        </div>
      </div>
    </form>

    <div class="word-cloud-link" data-cy="show-word-cloud" *ngIf="possibleShowModal" (click)="switchToShowWordCloud()">
      <a class="spen-util-link">概況を見る</a>
    </div>

    <div class="filter-info" *ngIf="contentLoaded">
      <p class="filter-result-info" data-cy="text-filter" *ngIf="isSearching || activityRecords.length > 0">
        検索条件：{{ filterDataService.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
    </div>

    <ng-container *ngIf="contentLoaded; else loadingIcon">
      <div class="data-box" *ngIf="activityRecords.length > 0; else noActivityRecord">
        <div class="spen-mod-flex-table table-list-full has-link">
          <div class="flex-table-head">
            <div class="flex-item col-size-10 is-first-col">活動記録名</div>
            <div class="flex-item col-size-3">
              <a class="spen-util-link" data-cy="col-created-at" (click)="changeBySortColumn('created_at')">
                作成日時
                <i [class]="sortStatus['created_at']" data-cy="col-sort-icon"></i>
              </a>
            </div>
            <div class="flex-item col-size-3">
              <a class="spen-util-link" data-cy="col-updated-at" (click)="changeBySortColumn('updated_at')">
                更新日時
                <i [class]="sortStatus['updated_at']" data-cy="col-sort-icon"></i>
              </a>
            </div>
            <div class="flex-item redirect-cell"></div>
          </div>
          <a
            class="flex-table-row"
            data-cy="activity-item"
            *ngFor="
              let record of activityRecords
                | paginate
                  : {
                      itemsPerPage: this.pages.per_page,
                      currentPage: this.pages.current_page,
                      totalItems: this.pages.total_count,
                    }
            "
            (click)="onClickActivityRecord(record, $event)"
          >
            <div class="flex-item col-size-10 is-first-col truncate-text" data-cy="activity-title">
              {{ record.title }}
            </div>
            <div class="flex-item col-size-3" data-cy="activity-created-at">{{ record.created_at }}</div>
            <div class="flex-item col-size-3" data-cy="activity-updated-at">{{ record.updated_at }}</div>
            <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
          </a>
        </div>
        <div class="spen-mod-paginate pagination" *ngIf="showPagination">
          <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
<ng-template #noActivityRecord>
  <div class="empty-message">{{ isSearching ? '検索結果が0件です。' : 'ここに生徒の活動記録が表示されます。' }}</div>
</ng-template>

<div *ngIf="isShowingModal">
  <app-word-cloud-wait-modal *ngIf="gotWordCloudResponse === 'not yet'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-wait-modal>
  <div *ngIf="gotWordCloudResponse === 'have gotten'">
    <app-word-cloud-modal *ngIf="gotWordCloudDocumentId === 'success'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-modal>
    <app-word-cloud-error-modal *ngIf="gotWordCloudDocumentId === 'failed'" (closeFilter)="onCloseWordCloud()">
    </app-word-cloud-error-modal>
  </div>
</div>
