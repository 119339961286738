import { Directive, ElementRef, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appConvertLink]',
})
export class ConvertLinkDirective implements AfterViewInit, OnChanges {
  regex = /(https?:\/\/[-A-Z0-9+&@#\/%=~_|()?!:,.;]*[-A-Z0-9+&@#\/%=~_|()])/gim;

  @Input() inputText: String;

  constructor(public element: ElementRef) {}

  ngAfterViewInit() {
    if (!!this.inputText) {
      return;
    }

    this.sanitizerText(this.element.nativeElement.textContent);
  }

  ngOnChanges(change: SimpleChanges) {
    this.sanitizerText(change.inputText.currentValue);
  }

  sanitizerText(input) {
    const tempElement = document.createElement('div');
    tempElement.textContent = input;

    const convertedText = this.convert(tempElement.innerHTML);
    this.element.nativeElement.innerHTML = convertedText;
  }

  convert(input: String): String {
    return input.replace(this.regex, `<a href="$1" class="spen-util-link" target="_blank">$1</a>`);
  }
}
