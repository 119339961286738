import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { NavigationResponse } from '@services/navigation/navigation-response';
import { NavigationItem } from '@services/navigation/navigation-item';
import { NavigatorParam } from '@services/navigation/navigator-param';

@Injectable()
export class NavigationService {
  navigationConfigs: {};

  currentSreenId: number;
  currentPathConfig: string;

  filterData: {};

  prev: NavigationItem | null;
  next: NavigationItem | null;
  list: NavigationItem | null;

  prevUrl = '';
  currentUrl = '';

  isLoading = true;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  // register screenId in navigation-configs.ts file
  registerNavigator(param: NavigatorParam) {
    this.currentSreenId = param.screenId;
    this.filterData = param.filterData;
    this.list = {
      url: param.listUrl || this.router.url,
      label: this.currentScreen.listText,
    };
    this.prev = this.next = null;
  }

  get canDisplayNavigator() {
    return !!this.currentSreenId && this.currentPathConfig && this.currentPathConfig === this.currentScreen.path.split('?')[0];
  }

  updateNavigator(route: ActivatedRouteSnapshot) {
    if (this.canDisplayNavigator) {
      if (!this.isOnlyQueryParamsChange) {
        Object.assign(this.filterData, { current_id: route.params[this.currentScreen.aliasId] });
        this.loadNavigatorData();
      }
    }

    this.prevUrl = this.currentUrl;
  }

  loadNavigatorData() {
    this.isLoading = true;

    this.getNavigateAPI().subscribe(
      (response) => {
        this.prev = this.updateNavigatorData('prev', response);
        this.next = this.updateNavigatorData('next', response);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      },
    );
  }

  updateNavigatorData(navigateType: string, response: NavigationResponse): NavigationItem {
    if (!response[navigateType]) {
      return null;
    }

    return {
      label: response[navigateType]['label'],
      url: this.setNavigatorLink(response[navigateType]['record_id']),
    };
  }

  setNavigatorLink(id): string {
    return this.currentScreen.path.replace(`:${this.currentScreen.aliasId}`, id);
  }

  get currentScreen() {
    return this.navigationConfigs[this.currentSreenId];
  }

  get isOnlyQueryParamsChange() {
    return this.prevUrl.split('?')[0] === this.currentUrl.split('?')[0];
  }

  getNavigateAPI(): Observable<NavigationResponse> {
    return this.http.post<NavigationResponse>(this.currentScreen.urlAPI(this.filterData), this.filterData);
  }
}
