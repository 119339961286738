import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Album } from '@models/album';
import { Space } from '@components/space/space';
import { environment } from '@environments/environment';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { ActivityRecordsListResponse } from '@components/activity-record/activity-records-list-response';
import { TeacherDeliveredAlbumDetail } from './teacher/teacher-delivered-album-detail/models/teacher-delivered-album-detail';
import { TeacherAlbumsStudentsResponse } from './teacher/teacher-delivered-album-detail/models/teacher-albums-students-response';
import { TeacherDeliveredAlbumsResponse } from '@components/album/teacher/delivered-albums-list/teacher-delivered-albums-response';
import { TeacherAlbumsResponse } from '@components/album/teacher/teacher-albums-list/teacher-albums-response';
import { AlbumDetailTeacher } from './teacher/album-detail-teacher/album-detail-teacher';
import { ActivityRecordsListAlbumResponse } from './teacher/album-detail-teacher/activity-records-list-album-response';

@Injectable()
export class AlbumService {
  apiEndpoint = environment.apiEndpoint;
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  createStudentAlbum(album: Album): Observable<Album> {
    return this.http.post<Album>(`${this.apiEndpoint}/student/albums`, { album: album });
  }

  updateStudentAlbum(album: Album): Observable<Album> {
    return this.http.put<Album>(`${this.apiEndpoint}/student/albums/${album.id}`, { album: album });
  }

  deleteStudentAlbum(albumId: number): Observable<Album> {
    return this.http.delete<Album>(`${this.apiEndpoint}/student/albums/${albumId}`);
  }

  createTeacherAlbum(spaceId: number, album: Album): Observable<Album> {
    return this.http.post<Album>(`${this.apiEndpointV2}/teacher/spaces/${spaceId}/albums`, { album: album });
  }

  updateTeacherAlbum(albumId: number, album: Album): Observable<Album> {
    return this.http.put<Album>(`${this.apiEndpointV2}/teacher/albums/${albumId}`, { album: album });
  }

  deleteTeacherAlbum(albumId: number): Observable<Album> {
    return this.http.delete<Album>(`${this.apiEndpointV2}/teacher/albums/${albumId}`);
  }

  getAlbum(id: number): Observable<Album> {
    const url = `${this.apiEndpoint}/albums/${id}`;
    return this.http.get<Album>(url);
  }

  getTeacherAlbum(id: number): Observable<Album> {
    const url = `${this.apiEndpointV2}/teacher/albums/${id}`;
    return this.http.get<Album>(url);
  }

  getTeacherAlbumEdit(id: number): Observable<Album> {
    const url = `${this.apiEndpointV2}/teacher/albums/${id}/edit`;
    return this.http.get<Album>(url);
  }

  getAlbumActivities(params: {}): Observable<ActivityRecordsListResponse> {
    const url = `${this.apiEndpoint}/student/activity_records/search`;
    return this.http.post<ActivityRecordsListResponse>(url, params);
  }

  getActivitiesInAlbum(albumId: number, params: {}) {
    const url = `${this.apiEndpoint}/student/albums/${albumId}/activity_records`;
    return this.http.get<ActivityRecordsListResponse>(url, { params: params });
  }

  getActivityRecord(activityRecordId: number): Observable<ActivityRecord> {
    const url = `${this.apiEndpoint}/activity_records/${activityRecordId}`;
    return this.http.get<ActivityRecord>(url);
  }

  addActivityRecord(albumId: number, activityId: number) {
    const url = `${this.apiEndpoint}/student/albums/${albumId}/add_activity_record`;
    return this.http.post<ActivityRecord>(url, { activity_record: { activity_record_id: activityId } });
  }

  getSpaceDetail(spaceId: number): Observable<Space> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}`;
    return this.http.get<Space>(url);
  }

  studentSetAlbumGoal(albumId: number, params: {}): Observable<Album> {
    const url = `${this.apiEndpoint}/student/albums_students/${albumId}`;
    return this.http.put<Album>(url, { albums_student: params });
  }

  getTeacherDeliveredAlbumDetail(albumId: number): Observable<TeacherDeliveredAlbumDetail> {
    const url = `${this.apiEndpointV2}/teacher/delivered_albums/${albumId}`;
    return this.http.get<TeacherDeliveredAlbumDetail>(url);
  }

  getTeacherDeliveredAlbumsStudents(albumId: number, params: {}): Observable<TeacherAlbumsStudentsResponse> {
    const url = `${this.apiEndpointV2}/teacher/delivered_albums/${albumId}/students`;
    return this.http.get<TeacherAlbumsStudentsResponse>(url, { params: params });
  }

  getTeacherDeliveredAlbums(params = {}): Observable<TeacherDeliveredAlbumsResponse> {
    const url = `${this.apiEndpointV2}/teacher/delivered_albums/search`;
    return this.http.post<TeacherDeliveredAlbumsResponse>(url, params);
  }

  getTeacherAlbums(params = {}): Observable<TeacherAlbumsResponse> {
    const url = `${this.apiEndpointV2}/teacher/albums/search`;
    return this.http.post<TeacherAlbumsResponse>(url, params);
  }

  getAlbumDetailTeacher(studentId: number, albumId: number): Observable<AlbumDetailTeacher> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/albums/${albumId}`;
    return this.http.get<AlbumDetailTeacher>(url);
  }

  getTeacherAlbumActivityRecords(studentId: number, albumId: number, params = {}): Observable<ActivityRecordsListAlbumResponse> {
    const url = `${this.apiEndpointV2}/teacher/students/${studentId}/albums/${albumId}/activity_records`;
    return this.http.get<ActivityRecordsListAlbumResponse>(url, { params: params });
  }
}
