import { Component, OnInit, EventEmitter, ViewChildren, ViewChild, AfterViewChecked, OnDestroy, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { parseJsonList } from '@functions/parse-json-list';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { Document } from '@components/document/document';
import { Mark } from '@models/mark';
import { Space } from '@components/space/space';
import { Work, WorkType } from '@components/work/work';
import { Template } from '@models/template';
import { Category } from '@models/category';

import { CategoriesService } from '@services/categories/categories.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { MarksService } from '@services/marks/marks.service';
import { WorkService } from '../work.service';
import { ToastService } from '@services/toast/toast.service';
import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';
import { TemplatesService } from '@services/templates/templates.service';

import { checkBlankString } from '@functions/check-string';
import { validateForm } from '@functions/validate-form';
import { RouterService } from '@services/router.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { FileUploader } from 'ng2-file-upload';
import { ContentSavingService } from '@services/content-saving.service';

@Component({
  selector: 'app-form-answer-work',
  templateUrl: './form-answer-work.component.html',
  styleUrls: ['./form-answer-work.component.scss'],
})
export class FormAnswerWorkComponent implements OnInit, AfterViewChecked, OnDestroy {
  spaceId: number;
  workId: number;
  work: Work;
  isWorkLoaded = false;
  isCategoryLoaded = false;
  isMarksLoaded = false;
  documentLoaded = false;
  errMsg: string;
  space: Space;
  isSubmitted = false;

  showListActivityModal = false;
  showActivityDetailModal = false;
  activityId = null;
  currentManaVision: ActivityRecord;

  document: Document = new Document();
  form: UntypedFormGroup;
  isSubmitting = false;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    title: {
      required: 'タイトルを入力してください。',
      maxlength: 'タイトルは50文字以内で入力してください。',
    },
    content: {
      required: '本文を入力してください。',
      maxlength: '本文は5000文字以内で入力してください。',
    },
    document_answers_attributes: {
      text_content: {
        required: '本文を入力してください。',
        maxlength: '本文は5000文字以内で入力してください。',
      },
      single_choice_content: {
        required: '項目を選択してください。',
        maxlength: '本文は5000文字以内で入力してください。',
      },
    },
  };
  uploadFilesStatus: string;
  lengthOfContent = 0;
  categoryName = null;
  marks: Array<Mark> = [];
  isFocused = false;
  dataConfirm = {
    title: '確認',
    content: '入力内容を下書き保存し、画面を移動します。',
    acceptButton: '移動する',
    cancelButton: 'キャンセル',
  };
  dataConfirmForCannotUseLocalStorage = {
    title: '確認',
    content: '推奨環境でないため下書き保存はできませんが、画面を移動しますか？',
    acceptButton: '移動する',
    cancelButton: 'キャンセル',
  };
  indexUploads = {};
  uploaderAll: FileUploader = new FileUploader({ url: '' });
  categories: Array<Category> = [];
  templates: Array<Template> = [];
  templatesLoaded = false;

  @ViewChildren(FileUploaderComponent) fileUploaders: QueryList<FileUploaderComponent>;
  @ViewChild(MarksSelectorComponent) marksSelector: MarksSelectorComponent;

  constructor(
    private categoryService: CategoriesService,
    private route: ActivatedRoute,
    private workService: WorkService,
    private location: Location,
    private marksService: MarksService,
    private router: Router,
    private toastService: ToastService,
    private formBuilder: UntypedFormBuilder,
    private analytic4Service: GoogleAnalytics4Service,
    private uploadFilesService: UploadFilesService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    private contentSavingService: ContentSavingService,
    private templatesService: TemplatesService,
  ) {}

  ngOnInit() {
    this.spaceId = this.route.snapshot.params['spaceId'];
    this.workId = this.route.snapshot.params['workId'];
    this.loadWorkDetail(this.workId, this.spaceId, false);
  }

  ngAfterViewChecked() {
    if (this.dataLoaded() && !this.isFocused) {
      this.contentSavingService.startService(this.router.url, this.form, this.marksSelector, '課題回答');
      if (!this.contentSavingService.haveTemporaryData()) {
        this.focusEvent.emit(true);
        this.isFocused = true;
      } else {
        this.contentSavingService.confirmed$.subscribe(() => {
          this.focusEvent.emit(true);
          this.isFocused = true;
        });
      }
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
    this.contentSavingService.stopService();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty && !this.isSubmitted) {
      if (localStorage) {
        return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
      } else {
        return this.confirmDialogService.showConfirmDeactive(this.dataConfirmForCannotUseLocalStorage);
      }
    }
    return true;
  }

  async getCategoryName() {
    this.categoryService.getCategoryName(this.work.category_id).then((categoryName) => {
      this.categoryName = categoryName;
      this.isCategoryLoaded = true;
    });
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        this.isMarksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadWorkDetail(workId: number, spaceId: number, isShowAnswer: boolean) {
    this.workService.getStudentWorkDetail(workId, spaceId, isShowAnswer).subscribe(
      (response) => {
        this.work = response;
        this.isWorkLoaded = true;
        this.loadMarks();
        if (this.work.category_id) {
          this.getCategoryName();
        } else {
          this.loadCategories();
        }
        if (this.work.work_type === 'single') {
          this.loadTemplates();
        }
        this.getFormDocument();
        this.lengthOfContent = this.form.get('content') ? this.form.get('content').value.length : 0;
        this.form.valueChanges.subscribe((data) => {
          this.routerService.registerBackConfirm(this.dataConfirm);
          this.lengthOfContent = this.form.get('content') ? this.form.get('content').value.length : 0;
          this.formErrors = validateForm(this.form, true, this.validationMessages);
        });
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  onBack() {
    if (localStorage) {
      this.routerService.goBack(this.dataConfirm, true);
    } else {
      this.routerService.goBack(this.dataConfirmForCannotUseLocalStorage, true);
    }
  }

  getFormErrorsAnswerIndex(index: number) {
    const answersErrors = this.formErrors['document_answers_attributes'];
    if (answersErrors) {
      return answersErrors[index] ? answersErrors[index] : {};
    } else {
      return {};
    }
  }

  getDataConfirmSubmit() {
    const isPublished = this.form.controls['published'].value;

    if (!this.isSingleWork && isPublished) {
      const answers = this.form.controls['document_answers_attributes'].value;
      if (
        answers
          .map((answer) => {
            return answer.text_content || answer.single_choice_content || '';
          })
          .some(checkBlankString)
      ) {
        return {
          title: '確認',
          content: '未回答の項目がありますが、提出しますか。',
          acceptButton: '提出する',
          cancelButton: 'キャンセル',
        };
      }
    }

    return {
      title: '確認',
      content: isPublished ? '課題を提出しますか？' : '一時保存しますか？',
      acceptButton: isPublished ? '提出する' : '一時保存する',
      cancelButton: 'キャンセル',
    };
  }

  onSubmit(isPublished: boolean) {
    this.setPublishedParam(isPublished);

    if (this.form.valid) {
      this.confirmDialogService.showConfirm(this.getDataConfirmSubmit()).subscribe((obj) => {
        if (obj) {
          this.isSubmitting = true;
          if (this.isSingleWork) {
            this.fileUploaders.toArray()[0].uploadAll();
          } else {
            this.uploadFilesForMultipleWork();
          }
        }
      });
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  uploadFilesForMultipleWork() {
    const listFiles = [];
    let indexFile = 0;
    this.fileUploaders.toArray().forEach((item, index) => {
      this.indexUploads[index] = item.uploader.queue.map((val) => {
        listFiles.push(val._file);
        return indexFile++;
      });
    });
    this.uploaderAll.clearQueue();
    this.uploaderAll.addToQueue(listFiles);
    const result = this.uploadFilesService.uploadAllFile(this.uploaderAll);
    if (result === true) {
      this.changeFormAfterUploadFile();
    } else if (result instanceof EventEmitter) {
      result.subscribe((value) => {
        if (value) {
          this.changeFormAfterUploadFile();
        } else {
          this.isSubmitting = false;
        }
      });
    }
  }

  changeFormAfterUploadFile() {
    const uploadedFiles = this.uploadFilesService.sentUploadedFiles(this.uploaderAll);
    this.formDocumentAnswers.forEach((item, index) => {
      const files = this.indexUploads[index].map((i) => uploadedFiles[i]);
      item.get('upload_files_attributes').setValue(files);
    });
    this.createDocument(this.form.value);
  }

  get isSingleWork() {
    return this.work.work_type === WorkType.single;
  }

  dataLoaded(): boolean {
    return this.isWorkLoaded && this.isCategoryLoaded && this.documentLoaded && this.isMarksLoaded;
  }

  getFormDocument() {
    const formInit = {
      title: [this.work.answer_title || '', [Validators.required, Validators.maxLength(50)]],
      published: true,
      activity_records_marks_attributes: this.formBuilder.array([]),
      category_id: this.work.category_id || '',
    };
    if (this.isSingleWork) {
      formInit['content'] = [this.work.template_content || '', [Validators.required, Validators.maxLength(5000)]];
      formInit['upload_files_attributes'] = this.formBuilder.array([]);
    } else {
      formInit['document_answers_attributes'] = this.formBuilder.array(
        this.work.work_questions.map((question) => {
          const contentValidations = [Validators.maxLength(5000)];
          if (this.work.is_compass_template) {
            contentValidations.push(Validators.required);
          } else {
            delete this.validationMessages.document_answers_attributes.text_content.required;
            delete this.validationMessages.document_answers_attributes.single_choice_content.required;
          }
          if (question.is_single_choice) {
            return this.formBuilder.group({
              work_question_id: [question.id, Validators.required],
              single_choice_content: ['', contentValidations],
              upload_files_attributes: [this.formBuilder.array([])],
            });
          } else {
            return this.formBuilder.group({
              work_question_id: [question.id, Validators.required],
              text_content: ['', contentValidations],
              upload_files_attributes: [this.formBuilder.array([])],
            });
          }
        }),
      );
    }
    this.form = this.formBuilder.group(formInit);
    this.formErrors = {};
    this.documentLoaded = true;
  }

  get formDocumentAnswers(): Array<UntypedFormGroup> {
    return this.form.get('document_answers_attributes')['controls'];
  }

  setPublishedParam(isPublished: boolean) {
    this.form.controls['published'].setValue(isPublished);
    if (!isPublished && this.form.get('document_answers_attributes')) {
      this.form.get('document_answers_attributes')['controls'].map((formGroup) => {
        const content = formGroup.controls.text_content ? 'text_content' : 'single_choice_content';
        const content_control = formGroup.controls[content];
        if (content_control.errors && content_control.errors.required) {
          delete content_control.errors.required;
          if (Object.keys(content_control.errors).length === 0) {
            content_control.setErrors(null);
          }
        }
      });
    }
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.dataConfirm);
    }
  }

  onUploadFilesStatus(status) {
    this.uploadFilesStatus = status;
    if (status === 'completed') {
      const uploadedFiles = this.fileUploaders.toArray()[0].sendUploadedFiles();
      for (const file of uploadedFiles) {
        this.form.value.upload_files_attributes.push(file);
      }
      this.createDocument(this.form.value);
    } else if (status === 'failed') {
      this.isSubmitting = false;
    }
  }

  onChangeMark() {
    this.routerService.registerBackConfirm(this.dataConfirm);
  }

  createDocument(data) {
    this.buildMarkParams();

    const params = { ...data };
    if (data.document_answers_attributes) {
      params.document_answers_attributes = data.document_answers_attributes.map((answer) => {
        const attr = { ...answer };

        attr.content = attr.text_content || attr.single_choice_content || '';
        delete attr.text_content;
        delete attr.single_choice_content;

        return attr;
      });
    }

    this.workService.createAnswer(this.spaceId, this.workId, params).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.isSubmitted = true;
          this.routerService.resetDataConfirm();
          const isPublished = this.form.controls['published'].value;
          if (isPublished) {
            this.router.navigateByUrl(`activity-records/student/${response['activity_record_id']}`, { replaceUrl: true });
            this.sendAnswerWorkGaEvent();
          } else {
            this.analytic4Service.sendEvent('課題', '回答画面', '一時保存する');
            this.routerService.goBack();
          }
          this.contentSavingService.removeTemporaryData();
          this.toastService.showToast(isPublished ? '提出しました' : '一時保存しました');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  buildMarkParams() {
    this.form.value.activity_records_marks_attributes = [];

    this.marksSelector.selectedMarks
      .filter((mark) => !!mark.checked)
      .map((mark) => mark.id)
      .forEach((newId) => this.form.value.activity_records_marks_attributes.push({ mark_id: newId }));
  }

  cancelUploadingFiles() {
    this.isSubmitting = false;
    this.uploadFilesService.cancelUploadedFiles(this.uploaderAll);
  }

  showModalActivities() {
    this.showListActivityModal = true;
    this.analytic4Service.sendEvent('課題', '回答画面', '過去の活動記録を見る');
  }

  eventSelectActivityModal(value: boolean) {
    this.showListActivityModal = value;
  }

  eventForBackAction(action: string) {
    if (action === 'close-modal') {
      this.showListActivityModal = false;
    }
    this.showActivityDetailModal = false;
  }

  eventShowActivity(activityRecord: ActivityRecord) {
    if (activityRecord.record_type !== 'ManaVision') {
      this.activityId = activityRecord.id;
      this.showActivityDetailModal = true;
    } else {
      this.currentManaVision = activityRecord;
    }
  }

  hideManaVisionModal() {
    this.currentManaVision = null;
  }

  loadTemplates() {
    this.templatesService.getTemplates().subscribe(
      (response) => {
        this.templates = parseJsonList(Template, response['templates']);
        this.templatesLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  onChangeTemplate(selectedItem) {
    const newTemplateId = selectedItem.target.value;
    if (newTemplateId === 'null') {
      this.form.controls['content'].setValue('');
    } else {
      const template = this.templates.find((_template) => _template.id === +newTemplateId);
      if (!!template) {
        this.form.controls['content'].setValue(template.content);
      }
    }
  }

  loadCategories() {
    this.categoryService.loadCategories().then((categories) => {
      if (!!categories) {
        this.categories = categories;
        this.isCategoryLoaded = true;
      }
    });
  }

  private sendAnswerWorkGaEvent() {
    this.analytic4Service.sendEvent('課題', '回答画面', '回答を完了する');
  }
}
