<div class="tea-album-detail" *ngIf="albumLoaded; else loadingIcon">
  <div class="album-info">
    <div class="album-title align-center">
      <img [src]="albumIconUrl" />
      <p>{{ album.title }}</p>
    </div>
    <div class="album-creator align-top">
      <img src="{{ album.student.avatar }}" />
      <div class="user-info">
        <a class="spen-util-link" [routerLink]="['/students/teacher/', album.student.id]">{{ album.student.name }}</a>
        <p class="class-name">{{ album.student.class_name }}{{ album.student.attendance_number }}番</p>
      </div>
    </div>
    <span>作成日時：{{ album.created_at }}</span>
    <span *ngIf="album.updated_at !== '-'">更新日時：{{ album.updated_at }}</span>
    <p>種別：{{ teacherCreator ? '先生配信' : '自主作成' }}</p>
    <p *ngIf="teacherCreator">
      配信アルバム名：<a class="spen-util-link" [routerLink]="['/albums/teacher/delivery/', album.id]">{{ album.title }}</a>
    </p>
    <p *ngIf="layoutService.isTabletDownView.value">活動記録数：{{ album.activity_records_count | number }}</p>
  </div>

  <div class="word-cloud-link" *ngIf="possibleShowModal" (click)="switchToShowWordCloud()">
    <a class="spen-util-link">概況を見る</a>
  </div>

  <ng-container *ngIf="firstActivityRecordsLoaded; else loadingIcon">
    <ng-container *ngIf="!checkEmptyAlbum; else emptyActivityRecord">
      <div
        class="content"
        [ngClass]="{ 'content-bottom-loading': moreActivityRecordsLoading }"
        infinite-scroll
        infiniteScrollDistance="1"
        infiniteScrollThrottle="300"
        [scrollWindow]="true"
        [infiniteScrollDisabled]="!metaData.next_id"
        (scrolled)="getAlbumActivityRecords(metaData)"
      >
        <div
          class="act-thumbnail"
          #actThumbnail
          *ngIf="!layoutService.isTabletDownView.value"
          [ngClass]="{ 'sticky-top': stickyTop, 'sticky-bottom': stickyBottom }"
          infinite-scroll
          infiniteScrollDistance="1"
          infiniteScrollThrottle="300"
          [scrollWindow]="false"
          [infiniteScrollDisabled]="!metaData.next_id"
          (scrolled)="getAlbumActivityRecords(metaData)"
        >
          <div class="latest-act-item-thumbnail" *ngIf="lastestActivityRecord">
            <p class="title">最新の活動記録</p>
            <div class="thumb current-activity-record" data-id="item-1" (click)="goToDetail($event)">
              <p class="truncate-text">{{ lastestActivityRecord.title }}</p>
              <div class="act-content-thumb">
                <div
                  class="truncate-paragraph"
                  [ngClass]="[getThumbnailAnswerImage(lastestActivityRecord) ? 'line-clamp-3' : 'line-clamp-9']"
                >
                  {{ getThumbnailText(lastestActivityRecord) }}
                </div>
                <div class="img-container" *ngIf="srcThumbnailImage">
                  <img src="{{ srcThumbnailImage }}" />
                </div>
              </div>
              <p class="page">{{ album.activity_records_count | number }}</p>
            </div>
          </div>

          <div class="goal-outline-thumbnail" *ngIf="album.goal || album.outline">
            <p class="title" [class.goal-position]="!lastestActivityRecord">目標・概要</p>
            <div class="thumb" data-id="item-2" (click)="goToDetail($event)" [class.current-activity-record]="!lastestActivityRecord">
              <div class="act-content-thumb">
                <div *ngIf="album.goal">
                  <p>目標：</p>
                  <p class="goal truncate-paragraph" [ngClass]="[album.outline ? 'line-clamp-3' : 'line-clamp-8']">{{ album.goal }}</p>
                </div>
                <div *ngIf="album.outline">
                  <p>概要：</p>
                  <p class="goal truncate-paragraph" [ngClass]="[album.goal ? 'line-clamp-3' : 'line-clamp-8']">{{ album.outline }}</p>
                </div>
              </div>
              <p class="page">0</p>
            </div>
          </div>

          <div *ngFor="let activityRecord of activityRecords; let i = index">
            <p class="title" *ngIf="setCassetteTitle(activityRecords[i - 1], activityRecords[i])">{{ cassetteTitle }}</p>
            <div class="thumb" [attr.data-id]="['item-' + (i + 3)]" (click)="goToDetail($event)">
              <p class="truncate-text">{{ activityRecord.title }}</p>
              <div class="act-content-thumb">
                <div class="truncate-paragraph" [ngClass]="[getThumbnailAnswerImage(activityRecord) ? 'line-clamp-3' : 'line-clamp-9']">
                  {{ getThumbnailText(activityRecord) }}
                </div>
                <div class="img-container" *ngIf="srcThumbnailImage">
                  <img src="{{ srcThumbnailImage }}" />
                </div>
              </div>
              <p class="page">{{ i + 1 }}</p>
            </div>
          </div>

          <div class="spen-mod-flex-loading" *ngIf="moreActivityRecordsLoading"><div class="loading-icon"></div></div>
        </div>

        <div class="activity-records" #actDetail [class.activity-detail-position]="onScroll && !layoutService.isTabletDownView.value">
          <div class="cassette" *ngIf="lastestActivityRecord">
            <p class="cassette-title">最新の活動記録</p>
            <div class="act-detail" id="item-1">
              <app-activity-view-detail
                [activityRecord]="album.latest_activity_record"
                [page]="album.activity_records_count"
                [totalPage]="album.activity_records_count"
              >
              </app-activity-view-detail>
            </div>
          </div>

          <div class="cassette" *ngIf="album.goal || album.outline">
            <p class="cassette-title">目標・概要</p>
            <p class="serial" *ngIf="!layoutService.isTabletDownView.value">[ 0/{{ album.activity_records_count | number }} ]</p>
            <div class="act-detail" id="item-2">
              <div class="goal-outline-detail" *ngIf="album.goal">
                <p>目標：</p>
                <div class="act-content">
                  <app-read-more [content]="album.goal" [collapsedLength]="499"></app-read-more>
                </div>
              </div>
              <div class="goal-outline-detail" *ngIf="album.outline">
                <p>概要：</p>
                <div class="act-content">
                  <app-read-more [content]="album.outline" [collapsedLength]="499"></app-read-more>
                </div>
              </div>
              <div class="page">- 0 -</div>
            </div>
          </div>

          <div class="cassette" *ngFor="let activityRecord of activityRecords; let i = index">
            <p class="cassette-title" *ngIf="setCassetteTitle(activityRecords[i - 1], activityRecords[i])">{{ cassetteTitle }}</p>
            <div class="act-detail" [attr.id]="['item-' + (i + 3)]">
              <app-activity-view-detail [activityRecord]="activityRecord" [page]="i + 1" [totalPage]="album.activity_records_count">
              </app-activity-view-detail>
            </div>
          </div>

          <div class="spen-mod-flex-loading" *ngIf="moreActivityRecordsLoading"><div class="loading-icon"></div></div>
        </div>
      </div>

      <p class="empty-activity-record" *ngIf="!lastestActivityRecord">ここに追加された活動記録が表⽰されます。</p>
    </ng-container>
  </ng-container>
</div>

<ng-template #emptyActivityRecord>
  <p class="empty-activity-record">ここに追加された活動記録が表⽰されます。</p>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<div *ngIf="isShowingModal">
  <app-word-cloud-wait-modal *ngIf="gotWordCloudResponse === 'not yet'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-wait-modal>
  <div *ngIf="gotWordCloudResponse === 'have gotten'">
    <app-word-cloud-modal *ngIf="gotWordCloudDocumentId === 'success'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-modal>
    <app-word-cloud-error-modal *ngIf="gotWordCloudDocumentId === 'failed'" (closeFilter)="onCloseWordCloud()">
    </app-word-cloud-error-modal>
  </div>
</div>
