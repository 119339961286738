import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Work } from '../../work';
import { CategoriesService } from '@services/categories/categories.service';
import { WorkService } from '../../work.service';
import { AuthUserService } from '@services/auth-user.service';
import { RouterService } from '@services/router.service';
import { Location } from '@angular/common';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

@Component({
  selector: 'app-work-detail-student',
  templateUrl: './work-detail-student.component.html',
  styleUrls: ['./work-detail-student.component.scss'],
})
export class WorkDetailStudentComponent implements OnInit {
  spaceId: number;
  work: Work;
  isShowAnswer = true;
  isWorkLoaded = false;
  errMsg: string;

  constructor(
    private categoryService: CategoriesService,
    private route: ActivatedRoute,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private workService: WorkService,
    private router: Router,
    private authUserService: AuthUserService,
    private routerService: RouterService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.spaceId = +this.route.snapshot.params['spaceId'];
    const workId = +this.route.snapshot.params['id'];
    if (this.route.snapshot.queryParams['isShowAnswer'] === 'false') {
      this.isShowAnswer = false;
    }
    this.loadWorkDetail(workId, this.spaceId, this.isShowAnswer);
  }

  loadWorkDetail(workId: number, spaceId: number, isShowAnswer: boolean) {
    this.workService.getStudentWorkDetail(workId, spaceId, isShowAnswer).subscribe(
      (response) => {
        this.work = response;
        this.isWorkLoaded = true;
        if (this.isShowAnswer && this.work.activity_record) {
          this.categoryService
            .getCategoryName(this.work.activity_record.category_id)
            .then((categoryName) => (this.work.activity_record.category_name = categoryName));
        }
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  onBack() {
    this.routerService.goBack();
  }

  sendEvent(clickParts: string): void {
    this.googleAnalytics4Service.sendEvent('課題', '詳細画面', clickParts);
  }

  dataLoaded() {
    return this.isWorkLoaded;
  }

  // when isShowAnswer == false; server doesn't return work's answer, whether user answered or not.
  showAnswerbutton() {
    return this.dataLoaded() && this.isShowAnswer && this.work.activity_record === null;
  }

  showUpdateDraftButton() {
    return this.dataLoaded() && this.isShowAnswer && !!this.work.activity_record && !this.work.activity_record.published;
  }
}
