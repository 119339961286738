import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { MySpaceService } from '../my-space.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { RouterService } from '@services/router.service';
import { isMobileApp } from '@functions/browser';
import { MultipleFilterService } from '@services/filter/multiple-filter.service';

import { environment } from '@environments/environment';
import { isNil, omit, pickBy } from 'lodash';

@Component({
  selector: 'app-activity-records-list',
  templateUrl: './activity-records-list.component.html',
  styleUrls: ['./activity-records-list.component.scss'],
  providers: [MultipleFilterService],
})
export class ActivityRecordsListComponent implements OnInit {
  @Input() unread_assessments_count;
  activityRecordLoaded = false;
  activityRecords: ActivityRecord[] = [];
  currentManaVision: ActivityRecord;
  errMsg: string;
  readonly classiUrl = environment.platformUrl;
  private readonly disallowedUrlParamKeys = ['keyword'];
  pages = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mySpaceService: MySpaceService,
    private analytics4Service: GoogleAnalytics4Service,
    private routerService: RouterService,
    public filterService: MultipleFilterService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
    this.filterService.prepareData();
  }

  private async loadList(params: Params): Promise<void> {
    const queryParams = Object.assign({}, params);
    queryParams['page'] = +params['page'] || 1;
    await this.filterService.setFilter(queryParams);
    this.getActivityRecordsList(queryParams);
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  async getActivityRecordsList(params: Object): Promise<void> {
    this.activityRecordLoaded = false;
    const response = await this.mySpaceService.getStudentActiviyRecordsList(params).toPromise();
    this.activityRecords = response.activity_records;
    Object.assign(this.pages, response.pages);
    this.activityRecordLoaded = true;
    window.scrollBy(0, 0);
  }

  getUnreadAssessments() {
    const params = Object.assign(this.filterService.getFilterData(), { has_unread_assessment: true, include_mana_vision: false });
    this.changeFilter(params);
  }

  get dataLoaded(): boolean {
    return this.activityRecordLoaded && this.filterService.categoriesLoaded;
  }

  get activityRecordsCount() {
    return this.pages.total_count;
  }

  get showPagination(): boolean {
    return this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    const params = { ...this.filterService.getFilterData(), page: page };
    this.navigate(params, 'merge');
  }

  clickItem(activityRecord: ActivityRecord) {
    if (activityRecord.record_type !== 'ManaVision') {
      this.router.navigate(['/activity-records/student/', activityRecord.id]);
    } else {
      this.currentManaVision = activityRecord;
    }
  }

  sendNewDocumentGAEvent() {
    this.analytics4Service.sendEvent('活動記録', '一覧画面', '活動記録の新規作成');
  }

  hideManaVisionModal() {
    this.currentManaVision = null;
  }

  onBack() {
    this.routerService.goBack();
  }

  get isMobileApp() {
    return isMobileApp();
  }

  changeFilter(params: {}) {
    this.navigate(this.makeQueryParams(params));
  }

  removeCategoryFilter() {
    this.filterService.removeFilter();
    this.navigate(this.makeQueryParams({}));
  }

  makeQueryParams(params: {}) {
    return Object.assign(params, { tab: 'activities' });
  }
}
