<div class="spen-ly-modal tea-filter-space" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-content space-has-footer">
        <div class="spen-mod-flex-loading">
          <div class="loading-icon"></div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="modal-actions is-right-actions space-modal-action">
          <a class="spen-mod-btn is-default js-close-modal-trigger" (click)="onClose()">閉じる</a>
        </div>
      </div>
    </div>
  </div>
</div>
