import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-space-detail-info',
  templateUrl: './space-info.component.html',
  styleUrls: ['./space-info.component.scss'],
})
export class SpaceDetailInfoComponent {
  @Input() spaceDetail;
  isShowDropdownActions = false;
  showMoreInMobile = false;
  isShowParticipants = false;

  constructor(
    private router: Router,
    public layoutService: LayoutService,
  ) {}

  showParticipantsList() {
    this.isShowParticipants = true;
  }

  hideParticipantsList() {
    this.isShowParticipants = false;
  }

  get listCrossSettings() {
    if (!this.spaceDetail) {
      return [];
    }
    return [
      { name: '先生ピックアップ', value: this.spaceDetail.teacher_can_pick_up },
      { name: '生徒自主公開', value: this.spaceDetail.student_can_publish },
      { name: '相互評価コメント', value: this.spaceDetail.student_can_comment_other },
    ];
  }

  get isShowLinkSetting(): boolean {
    return this.spaceDetail.managed_by_user || this.spaceDetail.can_be_edited;
  }
}
