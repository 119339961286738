import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import { Error, ErrorService } from '@services/error.service';

// see https://gist.github.com/impressiver/5092952
const sentryOptions: Sentry.BrowserOptions = {
  dsn: environment.sentry?.dsn,
  environment: environment.sentry?.environment,
  release: environment.sentry?.release,
  attachStacktrace: true,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ['localhost', environment.apiEndpoint, environment.apiEndpointV2],
  tracesSampleRate: 0.01,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    /\bonSvFinishLoading is not defined\b/,
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};

Sentry.init(sentryOptions);

export class SentryHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) {}

  handleError(err: any): void {
    if (!err) return;
    const unauthorizedMessage = /認証されていません/;
    const unauthenticatedMessage = /ポートフォリオを使うことができません/;
    if (unauthorizedMessage.test(err) || unauthenticatedMessage.test(err)) return;

    const chunkLoadErrorMessage = /ChunkLoadError/;
    if (chunkLoadErrorMessage.test(err.message)) {
      const error: Error = {
        title: '読み込みエラーが発生しました',
        message: '読み込み時にエラーが発生しました。ページの再読み込みを行なうか時間を置いて再度アクセスしてください。',
        error_code: 404,
        internalMessage: err.message,
      };
      this.errorService.addError(error);
      return;
    }

    Sentry.captureException(err);
  }
}
