<div class="graduation-message-sp" *ngIf="layoutService.isTabletDownView.value; else graduationMessagePc">
  <div class="create-graduation-message">
    <a class="spen-util-link" routerLink="/graduation-messages/teacher/new" data-cy="transit-form">卒業メッセージ配信</a>
  </div>
  <div class="filter">
    <div class="box-info">
      <div class="row-info">
        <p class="label-input">検索条件</p>
        <p class="info-data" data-cy="text-filter">{{ filterService.messageFilter }}</p>
      </div>

      <div class="row-info">
        <p class="label-input">並び順</p>
        <p class="info-data" data-cy="text-sort-order">{{ messageSort }}</p>
      </div>
    </div>

    <div class="box-filter">
      <span class="btn-show-detail" (click)="changeShowFilterStatus()">
        <a class="spen-util-link">
          <span>変更</span>
          <i [ngClass]="isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter-status"></i>
        </a>
      </span>
      <form [formGroup]="form" *ngIf="isShowFilter">
        <div class="selector-group">
          <div class="form-group">
            <p class="label-input">配信者</p>
            <label class="spen-mod-select">
              <select class="select" formControlName="distributor" data-cy="distributor">
                <option
                  class="select-option"
                  data-cy="distributor-name"
                  [ngValue]="distributor.formValue"
                  *ngFor="let distributor of distributorOptions"
                >
                  {{ distributor.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>

          <div class="form-group">
            <p class="label-input">タイトル</p>
            <input
              class="spen-mod-input-text"
              placeholder="タイトルを入力してください"
              maxlength="50"
              formControlName="keyword"
              data-cy="search-title"
            />
          </div>

          <div class="form-group">
            <p class="label-input">並び順</p>
            <label class="spen-mod-select">
              <select class="select" formControlName="sort_value" data-cy="sort-order" (change)="onChangeSortValue($event.target.value)">
                <option class="select-option" data-cy="sort-order-name" value="配信日時（降順）">配信日時（降順）</option>
                <option class="select-option" data-cy="sort-order-name" value="配信日時（昇順）">配信日時（昇順）</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn is-primary"
            data-cy="submit-filter"
            (click)="onSubmitFilter()"
            [disabled]="isLoadingData()"
          >
            適用する
          </button>
          <button
            type="button"
            class="cancel spen-mod-btn is-default"
            data-cy="remove-filter"
            [disabled]="isLoadingData() || isDefaultForm()"
            (click)="removeFilter()"
          >
            選択解除
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="list-submission" *ngIf="graduation_messages.length > 0; else noGraduationMessage">
      <p class="paginate-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
      <div
        class="list-item clickable-item"
        [class.last]="last"
        data-cy="graduation-message-item"
        *ngFor="
          let graduation_message of graduation_messages
            | paginate
              : {
                  itemsPerPage: this.pages.per_page,
                  currentPage: this.pages.current_page,
                  totalItems: this.pages.total_count,
                };
          let last = last
        "
        (click)="onClickGraduationMessage(graduation_message.id)"
      >
        <div class="info">
          <div class="graduation-message-name">
            <span class="truncate-text"
              ><b data-cy="message-title">{{ graduation_message.title }}</b></span
            >
          </div>
          <p class="truncate-text creator-name" data-cy="create-user-name">配信者：{{ graduation_message.create_user_name }}</p>
          <p class="text-smaller delivered-time" data-cy="created-at">配信日時：{{ graduation_message.created_at }}</p>
          <div class="text-smaller space-name">
            <span class="truncate-text" data-cy="space-name">配信先：{{ graduation_message.space_name }}</span>
          </div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>

      <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #graduationMessagePc>
  <div class="graduation-message-pc">
    <div class="create-graduation-message">
      <a routerLink="/graduation-messages/teacher/new" class="spen-mod-btn create-button" data-cy="transit-form">卒業メッセージ配信</a>
    </div>

    <form [formGroup]="form" class="filter-form">
      <div class="form-contents">
        <div class="form-group">
          <span class="label">配信者</span>
          <label class="spen-mod-radio creator-label-item" data-cy="distributor" *ngFor="let distributorOption of distributorOptions">
            <input
              class="radio"
              formControlName="distributor"
              data-cy="distributor-value"
              [value]="distributorOption.formValue"
              type="radio"
            />
            <i class="fa"></i><span class="text" data-cy="distributor-name">{{ distributorOption.text }}</span>
          </label>
        </div>
        <div class="form-group">
          <span class="label">タイトル</span>
          <input
            type="text"
            class="spen-mod-input-text"
            placeholder="タイトルを入力してください"
            maxlength="50"
            formControlName="keyword"
            data-cy="search-title"
          />
        </div>
      </div>
      <div class="button-group">
        <button
          type="button"
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          (click)="removeFilter()"
          [disabled]="isLoadingData() || isDefaultForm()"
        >
          選択解除
        </button>
        <button
          type="button"
          class="spen-mod-btn is-primary"
          data-cy="submit-filter"
          (click)="onSubmitFilter()"
          [disabled]="isLoadingData()"
        >
          検索する
        </button>
      </div>
    </form>

    <div class="data-info" *ngIf="contentLoaded">
      <p class="filter-info" data-cy="text-filter" *ngIf="isSearching || graduation_messages.length > 0">
        検索条件：{{ filterService.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info" *ngIf="!inProgressSort && graduation_messages.length > 0">{{ pages | paginateText }}</p>
    </div>

    <div class="content">
      <ng-container *ngIf="contentLoaded; else loadingIcon">
        <div class="list-submission" *ngIf="graduation_messages.length > 0; else noGraduationMessage">
          <div class="spen-mod-flex-table table-list-full has-link">
            <div class="flex-table-head">
              <div class="flex-item col-size-4 is-first-col">タイトル</div>
              <div class="flex-item col-size-3">配信者</div>
              <div class="flex-item col-size-3">
                <a class="spen-util-link" (click)="changeBySortColumn('created_at')"
                  >配信日時
                  <i [class]="sortStatus['created_at']" data-cy="deliverd-at"></i>
                </a>
              </div>
              <div class="flex-item col-size-4">配信先</div>
              <div class="flex-item redirect-cell"></div>
            </div>
            <a
              class="flex-table-row clickable-item"
              data-cy="graduation-message-item"
              *ngFor="
                let graduation_message of graduation_messages
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
              (click)="onClickGraduationMessage(graduation_message.id)"
            >
              <div class="flex-item col-size-4 is-first-col truncate-text">
                <div class="graduation-message-title">
                  <span class="truncate-text" data-cy="message-title">{{ graduation_message.title }}</span>
                </div>
              </div>
              <div class="flex-item col-size-3 truncate-text" data-cy="create-user-name">{{ graduation_message.create_user_name }}</div>
              <div class="flex-item col-size-3" data-cy="created-at">{{ graduation_message.created_at }}</div>
              <div class="flex-item col-size-4 space-name">
                <a class="spen-util-link truncate-text" data-cy="space-name" (click)="onClickSpaceName(graduation_message.space_id)">
                  {{ graduation_message.space_name }}
                </a>
              </div>
              <div class="flex-item redirect-cell is-icon-item">
                <i class="fa fa-chevron-right"></i>
              </div>
            </a>
          </div>

          <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
            <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noGraduationMessage>
  <div class="empty-message">
    {{ isSearching ? '検索結果が0件です。' : '配信された卒業メッセージが表示されます。' }}
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>
