import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Album } from '@models/album';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { AlbumService } from '@components/album/album.service';
import { RouterService } from '@services/router.service';
import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-album-detail-student',
  templateUrl: './album-detail-student.component.html',
  styleUrls: ['./album-detail-student.component.scss'],
})
export class AlbumDetailStudentComponent implements OnInit {
  albumLoaded = false;
  activitiesLoaded = false;
  album: Album;
  activities: Array<ActivityRecord>;
  errMsg: string;
  showSelectActivityModal = false;
  showActivityAlbumModal = false;
  albumId: number;
  activityId: number;
  pages = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };

  constructor(
    private albumService: AlbumService,
    private route: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.albumId = this.route.snapshot.params['id'];
    this.getAlbumInfo(this.albumId);
    this.route.queryParams.subscribe((params) => {
      const pageIndex = +params['page'] || 1;
      this.activitiesLoaded = false;
      this.getActivities(this.albumId, pageIndex);
    });
  }

  isShowButtonRedirectWorkTab() {
    return this.isAlbumCreatedByTeacher && this.album['have_work_not_answer'];
  }

  get isAlbumCreatedByTeacher() {
    return this.album.creator_type === 'teacher';
  }

  get isAlbumCreatedByStudent() {
    return this.album.creator_type === 'student';
  }

  get isShowAlbumGoal() {
    return this.album.goal || this.album.outline || this.album.student_goal || this.album.student_outline;
  }

  get albumGoalOutline() {
    if (this.isAlbumCreatedByStudent) {
      return { goal: this.album.goal, outline: this.album.outline };
    } else {
      return { goal: this.album.student_goal, outline: this.album.student_outline };
    }
  }

  goToAddGoalAlbumPage() {
    this.router.navigateByUrl(`albums/student/${this.albumId}/add-goal-outline`);
  }

  getAlbumInfo(albumId) {
    this.albumService.getAlbum(albumId).subscribe(
      (response) => {
        this.album = response;
        this.albumLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  getActivities(albumId: number, pageIndex: number) {
    this.albumService.getActivitiesInAlbum(albumId, { page: pageIndex }).subscribe(
      (response) => {
        this.activities = response['activity_records'];
        Object.assign(this.pages, response['pages']);
        this.activitiesLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  get iconAlbumUrl() {
    return this.album.image ? this.album.image.thumbnail : 'assets/images/icons/album.svg';
  }

  get canEditAlbum() {
    return this.authUserService.retrieve().id === this.album.create_user_id;
  }

  get showPagination(): boolean {
    return this.pages.total_pages > 1;
  }

  addActivityToAlbum() {
    this.showSelectActivityModal = true;
  }

  createNewActivity() {
    this.router.navigateByUrl(`/documents/new?album_id=${this.album.id}`);
  }

  showWorksAlbum() {
    this.router.navigate(['/my-space'], { queryParams: { tab: 'work', album_id: this.albumId } });
  }

  pageChanged(page: number) {
    this.router.navigate(['.'], { queryParams: { page: page }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  dataLoaded() {
    return this.albumLoaded && this.activitiesLoaded;
  }

  onBack() {
    this.routerService.goBack();
  }

  eventSelectActivityModal(value: boolean) {
    this.showSelectActivityModal = value;
  }

  eventActivityAlbumModal(data: { [type: string]: string }) {
    this.showActivityAlbumModal = false;
    if (data['type'] === 'submit') {
      this.showSelectActivityModal = false;
      this.getActivities(this.albumId, 1);
    } else {
      this.showSelectActivityModal = true;
    }
  }

  eventShowActivity(activityId: number) {
    this.activityId = activityId;
    this.showSelectActivityModal = false;
    this.showActivityAlbumModal = true;
  }
}
