import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { TeacherSelectableSpacesResponse } from './teacher-selectable-spaces-response';

@Injectable()
export class TeacherSpacePickerService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  getTeacherSelectableSpaces(params: {}): Observable<TeacherSelectableSpacesResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/selectable_spaces`;
    return this.http.post<TeacherSelectableSpacesResponse>(url, params);
  }
}
