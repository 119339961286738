import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { SpaceCreatorService } from './space-creator.service';
import { RouterService } from '@services/router.service';
import { SpaceService } from '@components/space/space.service';
import { TeacherFilterService, StudentFilterService } from './space-filter/space-filter.service';

@Component({
  selector: 'app-form-space',
  templateUrl: './form-space.component.html',
  styleUrls: ['./form-space.component.scss'],
  providers: [SpaceService, SpaceCreatorService, TeacherFilterService, StudentFilterService],
})
export class FormSpaceComponent implements OnInit {
  @ViewChild(Step1Component) step1Component: Step1Component;
  @ViewChild(Step2Component) step2Component: Step2Component;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private creatorService: SpaceCreatorService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.route.queryParams.subscribe((params) => {
      const step = +params['step'] || 1;
      if (this.creatorService.currentStep === 2 && step === 1) {
        this.creatorService.setStep1State();
      }
      window.scrollTo(0, 0);
    });
  }

  get isStep2(): boolean {
    return this.creatorService.currentStep === 2;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.step1Component) {
      return this.step1Component.canDeactivate();
    } else {
      return this.step2Component.canDeactivate();
    }
  }
}
