import { NgModule } from '@angular/core';

import { FileIconClassPipe } from './file-icon-class.pipe';
import { TrimTextPipe } from './trim-text.pipe';
import { PaginateResultPipe } from '@pipes/paginate-result.pipe';
import { DayJapanesesTextPipe } from '@pipes/day-japaneses-text.pipe';

@NgModule({
  declarations: [FileIconClassPipe, TrimTextPipe, PaginateResultPipe, DayJapanesesTextPipe],
  exports: [FileIconClassPipe, TrimTextPipe, PaginateResultPipe, DayJapanesesTextPipe],
})
export class SharedPipesModule {}
