<ng-container *ngIf="activityRecord.can_add_pickup; else cantPickup">
  <div class="add-pickup">
    <a data-cy="add-pickup" (click)="addPickup()">
      <img src="assets/images/icons/pickup-active.svg" />
      <span class="spen-util-link">ピックアップを追加する</span>
    </a>
  </div>
</ng-container>
<ng-template #cantPickup>
  <p *ngIf="pickupsLoaded && pickups.length > 0" class="permission-message">
    ※課題配信先のスペースを担当していないためピックアップできません。
  </p>
</ng-template>

<ng-container *ngIf="pickupsLoaded; else loadingIcon">
  <div class="pickups-list" *ngIf="pickups.length > 0; else emptyList">
    <app-teacher-pickup-item *ngFor="let pickup of pickups" [pickup]="pickup"></app-teacher-pickup-item>
  </div>

  <div class="load-more" *ngIf="canLoadMore">
    <a class="spen-util-link" (click)="loadMorePickups()">
      <span>もっと見る</span>
      <i [class]="isLoadingMore ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
    </a>
  </div>
</ng-container>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<ng-template #emptyList>
  <div class="empty-message">ここにピックアップが表示されます。</div>
</ng-template>
