import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Document } from './document';
import { environment } from './../../../environments/environment';

@Injectable()
export class DocumentService {
  apiEndpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  getDocument(id: number): Observable<Document> {
    const url = `${this.apiEndpoint}/documents/${id}`;
    return this.http.get<Document>(url);
  }

  createDocument(document: Document): Observable<Document> {
    return this.http.post<Document>(`${this.apiEndpoint}/documents`, { document: document });
  }

  updateDocument(document: Document): Observable<Document> {
    return this.http.put<Document>(`${this.apiEndpoint}/documents/${document.id}`, { document: document });
  }

  deleteDocument(documentId: number): Observable<Document> {
    return this.http.delete<Document>(`${this.apiEndpoint}/documents/${documentId}`);
  }
}
