import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ChangeDetectorRef } from '@angular/core';
import { SpaceService } from '@components/space/space.service';
import { ActivatedRoute } from '@angular/router';
import { currentLocaleDate, dateToNgbDate, ngbDateToString } from '@functions/date-formatter';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cross-review-dialog',
  templateUrl: './cross-review-dialog.component.html',
  styleUrls: ['./cross-review-dialog.component.scss'],
})
export class CrossReviewDialogComponent implements OnInit, OnDestroy {
  @Output() cancelModal = new EventEmitter<any>();
  students = [];
  tabOptions = { publish: '公開状況', view: '閲覧状況', comment: 'コメント状況' };
  currentTab = 'publish';
  listTableInfo = {
    publish: [
      { text: '生徒名', name: 'student' },
      { text: '学年組番', name: 'class_name' },
      { text: '自主公開', name: 'activity_public_count' },
      { text: 'ピックアップ', name: 'activity_pickup_count' },
    ],
    view: [
      { text: '生徒名', name: 'student' },
      { text: '学年組番', name: 'class_name' },
      { text: '閲覧回数', name: 'own_view_count' },
      { text: '被閲覧回数', name: 'other_view_count' },
    ],
    comment: [
      { text: '生徒名', name: 'student' },
      { text: '学年組番', name: 'class_name' },
      { text: 'コメント数', name: 'owner_comments' },
      { text: '被コメント数', name: 'another_comments' },
    ],
  };

  currentTable = this.listTableInfo[this.currentTab];
  spaceId: number;
  errMsg: string;
  iconSort = {
    none: 'sort-column none',
    loader: 'sort-loader fa fa-spinner fa-spin',
    up: 'sort-column fa fa-caret-up',
    down: 'sort-column fa fa-caret-down',
  };
  sortStatus = {};
  isLoading = false;
  inProgressSort = false;
  nextUrl = null;
  sortType = null;
  sortValue = null;
  startTime: NgbDateStruct;
  endTime: NgbDateStruct;
  queryParams = {};
  contentLoaded: boolean;
  currentDate = dateToNgbDate(currentLocaleDate());

  private _isSetDefaultSortIcon = false;

  constructor(
    private spaceService: SpaceService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.spaceId = +this.route.snapshot.paramMap.get('id');
    this._isSetDefaultSortIcon = true;
    this.setDefaultParams();
    this.setDefaultTimeFilter();
    this.loadDialog(this.currentTab);
  }

  onChangeTabDialog(tabKey: Object) {
    this.sortStatus = {};
    this._isSetDefaultSortIcon = true;
    this.currentTab = tabKey['key'];
    this.currentTable = this.listTableInfo[this.currentTab];
    this.setDefaultParams();
    this.loadDialog(tabKey['key']);
  }

  loadDialog(type: string) {
    this.contentLoaded = false;
    switch (type) {
      case 'publish':
        this.loadPublishDialog(this.spaceId);
        break;
      case 'view':
        this.loadActivityViewDialog(this.spaceId);
        break;
      case 'comment':
        this.loadCrossAssessmentStatusResponse(this.spaceId);
        break;
      default:
    }
  }

  loadActivityViewDialog(spaceId) {
    this.spaceService.getActivityViewDialog(spaceId, this.makeParams()).subscribe(
      (response) => {
        this.students = response['students'];
        this.nextUrl = response['meta']['next_url'];
        this.refreshModalStatus();
      },
      (error) => {
        this.errMsg = <any>error;
        this.contentLoaded = true;
      },
    );
  }

  loadPublishDialog(spaceId) {
    this.spaceService.getPublishDialog(spaceId, this.makeParams()).subscribe(
      (response) => {
        this.students = response['students'];
        this.nextUrl = response['meta']['next_url'];
        this.refreshModalStatus();
      },
      (error) => {
        this.errMsg = <any>error;
        this.contentLoaded = true;
      },
    );
  }

  loadCrossAssessmentStatusResponse(spaceId) {
    this.spaceService.getCrossAssessmentStatusDialog(spaceId, this.makeParams()).subscribe(
      (response) => {
        this.students = response['students'];
        this.nextUrl = response['meta']['next_url'];
        this.refreshModalStatus();
      },
      (error) => {
        this.errMsg = <any>error;
        this.contentLoaded = true;
      },
    );
  }

  loadMoreDialog() {
    this.isLoading = true;
    this.spaceService.getMoreDialog(this.nextUrl).subscribe(
      (response) => {
        this.students.push(...response['students']);
        this.nextUrl = response['meta']['next_url'];
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  onCancel() {
    this.cancelModal.emit();
  }

  changeBySortColumn(sortType: string) {
    if (this.inProgressSort) {
      return false;
    }
    this.inProgressSort = true;
    if (sortType === this.sortType) {
      this.sortValue = this.sortValue === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortType] = this.iconSort['none'];
      this.sortValue = 'asc';
    }
    this.sortType = sortType;
    this.sortStatus[this.sortType] = this.iconSort['loader'];
    this.loadDialog(this.currentTab);
  }

  refreshIconSort() {
    const icon = this.sortValue === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortType] = this.iconSort[icon];
    this.inProgressSort = false;
    this._isSetDefaultSortIcon = false;
  }

  refreshModalStatus() {
    this.contentLoaded = true;
    this.sortStatus = {};
    if (this.inProgressSort || this._isSetDefaultSortIcon) {
      this.refreshIconSort();
    }
  }

  makeParams() {
    return {
      sort_key: this.sortType,
      sort_order: this.sortValue,
      start_time: ngbDateToString(this.startTime),
      end_time: ngbDateToString(this.endTime),
    };
  }

  updateStartTime(start_time: NgbDateStruct) {
    this.startTime = start_time;
    this._isSetDefaultSortIcon = true;
    this.loadDialog(this.currentTab);
  }

  updateEndTime(end_time: NgbDateStruct) {
    this.endTime = end_time;
    this._isSetDefaultSortIcon = true;
    this.loadDialog(this.currentTab);
  }

  setDefaultParams() {
    this.sortType = 'class_name';
    this.sortValue = 'asc';
  }

  setDefaultTimeFilter() {
    const date = currentLocaleDate();
    this.endTime = dateToNgbDate(date);
    this.startTime = dateToNgbDate(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()));
  }

  onLoadMore() {
    if (!this.contentLoaded || !this.nextUrl || this.isLoading) {
      return;
    }
    this.loadMoreDialog();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
