import { Component, OnInit, Output, EventEmitter, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { WorkTemplate } from '@models/work-template';
import { LayoutService } from '@components/layout/layout.service';
import { WorkService } from '../work.service';
import { pick, pickBy, isEqual } from 'lodash';
import { NextPageParams } from '@components/work/work-templates-response';

const DEFAULT_FORM_OPTIONS = {
  create_user_scope: '',
  title: '',
};
const marginFooterModal = 64;

@Component({
  selector: 'app-work-template-modal',
  templateUrl: './work-template-modal.component.html',
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [style({ transform: 'translateY(100%)' }), animate('200ms', style({ transform: 'translateY(0%)' }))]),
      transition(':leave', [animate('200ms', style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
  styleUrls: ['./work-template-modal.component.scss'],
})
export class WorkTemplateModalComponent implements OnInit, OnDestroy {
  @Output() eventCloseModal = new EventEmitter();
  @Output() eventSelectWorkTemplate = new EventEmitter();
  @Output() eventRecycleWorkTemplate = new EventEmitter();
  private readonly allowedParamKeys = ['title', 'create_user_scope'];
  createUserScope = [
    { value: '', name: 'すべて' },
    { value: 'myself', name: '自分' },
    { value: 'other_teachers', name: '自分以外の先生' },
    { value: 'system', name: 'Classi' },
  ];
  meta: NextPageParams = {
    total_count: 0,
    create_user_scope: null,
    next_page: null,
    title: null,
  };
  workTemplates: Array<WorkTemplate>;
  currentWorkTemplate: WorkTemplate;
  isShowFilterWorkTemplate = false;
  newViewTemplate = false;
  routerSubscriber = null;
  form: UntypedFormGroup;
  errorMsg: string;
  workTemplateLoading = false;
  moreWorkTemplateLoading = false;
  filterData = {};
  isShowWorkTemplateSP = [];
  messageFilter: string;
  params = {};
  submited = false;
  currentFormOption: any;
  hasScrollBar = false;

  constructor(
    private renderer: Renderer2,
    private workService: WorkService,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.filterData = pick(this.params, this.allowedParamKeys);
    this.setFilter(this.filterData);
    this.getWorkTemplates(this.filterData);
    this.initForm();
  }

  getWorkTemplates(param = {}) {
    this.workTemplateLoading = true;
    this.hasScrollBar = false;
    this.workService.getWorkTemplates(param).subscribe(
      (response) => {
        this.workTemplates = response.work_templates || [];
        this.currentWorkTemplate = this.workTemplates[0];
        this.updateMetaData(response.meta);
        this.workTemplates.forEach((template) => {
          this.isShowWorkTemplateSP.push(false);
        });
        this.currentFormOption = Object.assign({}, param);
        this.workTemplateLoading = false;
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  initForm() {
    this.form = this.formBuilder.group({
      title: this.filterData['title'] || '',
      create_user_scope: this.filterData['create_user_scope'] || '',
    });
  }

  onSubmit() {
    this.form.controls['title'].setValue(this.form.controls['title'].value.trim());
    const params = pickBy(Object.assign({}, this.form.value));
    this.filterData = Object.assign({}, params);
    if (!this.isSameCurrentParams) {
      this.submited = !this.isDefaultForm;
      this.messageFilter = '';
      this.setFilter(this.filterData);
      this.isShowFilterWorkTemplate = false;
      this.workTemplates = [];
      this.isShowWorkTemplateSP = [];
      this.getWorkTemplates(params);
    }
  }

  get isDefaultForm(): boolean {
    return isEqual(this.form.value, DEFAULT_FORM_OPTIONS);
  }

  get isSameCurrentParams(): boolean {
    return isEqual(this.form.value, this.currentFormOption);
  }

  updateMetaData(meta: Object) {
    Object.assign(this.meta, meta);
    if (!meta.hasOwnProperty('next_page')) {
      this.meta['next_page'] = null;
    }
    if (!meta.hasOwnProperty('title')) {
      this.meta['title'] = null;
    }
  }

  onResetFilter() {
    this.form.reset(DEFAULT_FORM_OPTIONS);
    if (this.submited) {
      this.onSubmit();
    }
  }

  onChangeShowMoreFilter() {
    this.isShowFilterWorkTemplate = !this.isShowFilterWorkTemplate;
  }

  onChangeShowWorkTemplateSP(i) {
    this.isShowWorkTemplateSP[i] = !this.isShowWorkTemplateSP[i];
  }

  closeModal() {
    this.eventCloseModal.emit(false);
  }

  previewWorkTemplate(workTemplate: WorkTemplate) {
    this.currentWorkTemplate = workTemplate;
    this.newViewTemplate = true;
  }

  selectWorkTemplate(workTemplate: WorkTemplate) {
    workTemplate.work_template_questions.forEach((question) => {
      delete question.id;
      delete question.image;
    });
    workTemplate.work_template_questions_attributes = this.currentWorkTemplate.work_template_questions;
    delete workTemplate.work_template_questions;
    this.eventSelectWorkTemplate.emit(workTemplate);
  }

  recycleWorkTemplate(workTemplate: WorkTemplate) {
    if (!workTemplate) {
      return false;
    }
    workTemplate.work_template_questions.forEach((question) => {
      question['image_attributes'] = question['image'];
      delete question.id;
      delete question.image;
    });
    workTemplate.work_template_questions_attributes = workTemplate.work_template_questions;
    delete workTemplate.work_template_questions;
    workTemplate['isEdit'] = true;
    this.eventRecycleWorkTemplate.emit(workTemplate);
  }

  getMoreWorkTemplate() {
    this.hasScrollBar = true;
    if (!this.meta.next_page || this.moreWorkTemplateLoading) {
      return;
    }
    this.moreWorkTemplateLoading = true;

    const params = {
      create_user_scope: this.meta.create_user_scope,
      page: this.meta.next_page,
      title: this.meta.title,
    };
    this.workService.getWorkTemplates(params).subscribe(
      (response) => {
        this.workTemplates.push(...response.work_templates);
        this.updateMetaData(response.meta);
        this.moreWorkTemplateLoading = false;
        response.work_templates.forEach((template) => {
          this.isShowWorkTemplateSP.push(false);
        });
      },
      (error) => (this.errorMsg = <any>error),
    );
  }

  private haveFilterData(params) {
    return this.hasValueAtKeys(params, this.allowedParamKeys);
  }

  private hasValueAtKeys(object, keys: Array<string>): boolean {
    for (const key of keys) {
      if (!!object[key] && object[key] !== 'null') {
        return true;
      }
    }
    return false;
  }

  setFilter(params = {}) {
    if (!this.haveFilterData(params)) {
      this.messageFilter = 'すべて';
      return;
    }
    const pairMessage = [];

    if (params['create_user_scope']) {
      switch (params['create_user_scope']) {
        case '':
          pairMessage.push('すべて');
          break;
        case 'myself':
          pairMessage.push('自分');
          break;
        case 'other_teachers':
          pairMessage.push('自分以外の先生');
          break;
        case 'system':
          pairMessage.push('Classi');
          break;
      }
    } else {
      pairMessage.push('すべて');
    }

    if (params['title'] && params['title'].trim()) {
      pairMessage.push(params['title'].trim());
    } else {
      delete params['title'];
    }

    this.messageFilter = pairMessage.join('/');
  }

  @HostListener('click', ['$event'])
  onMouseLeftClick() {
    const templateContainerElement = document.getElementById('template-container');
    const modalContent = document.getElementsByClassName('modal-content')[0] as HTMLElement;

    if (this.newViewTemplate && !this.layoutService.isTabletDownView.value && templateContainerElement) {
      const templateContainerMargin = templateContainerElement.offsetTop;
      if (modalContent.scrollTop > templateContainerMargin) {
        modalContent.scrollTo({
          top: templateContainerMargin,
          behavior: 'smooth',
        });
      }
      setTimeout(() => {
        this.newViewTemplate = false;
      }, 200);
    }
  }

  @HostListener('scroll', ['$event'])
  handleScroll(event) {
    const templateContainerElement = document.getElementById('template-container');
    const templateListElement = document.getElementById('template-list');
    const templateSection = document.getElementById('template-preview');
    const headerOffsetHeight = (document.getElementsByClassName('modal-heading')[0] as HTMLElement).offsetHeight;
    const modalContent = document.getElementsByClassName('modal-content')[0] as HTMLElement;
    const ulItemlist = document.getElementsByClassName('spen-mod-item-list')[0] as HTMLElement;
    // reference margin bottom of class: "content-modal-fix"
    const footerMagin = 64;

    if (templateContainerElement) {
      const templateContainerMargin = templateContainerElement.offsetTop;
      if (this.layoutService.isTabletDownView.value) {
        // 0.97 and 0.55 is the percentage of the element's height, this percentage varies according to screen size ratio
        if (
          event.target.scrollTop >
          document.getElementById('template-container').scrollHeight * 0.97 -
            headerOffsetHeight -
            marginFooterModal -
            templateContainerMargin -
            window.innerHeight * 0.55
        ) {
          this.getMoreWorkTemplate();
        }
      } else {
        if (event.target.scrollTop > templateContainerMargin) {
          if (modalContent.offsetHeight < event.target.scrollHeight - event.target.scrollTop - marginFooterModal) {
            templateSection.classList.add('div-offset');
            templateListElement.classList.add('list-fixed-top');
            templateListElement.classList.remove('list-fixed-bottom');
          } else {
            if (ulItemlist.offsetHeight >= window.innerHeight - headerOffsetHeight - footerMagin) {
              templateSection.classList.add('div-offset');
              templateListElement.classList.add('list-fixed-bottom');
              templateListElement.classList.remove('list-fixed-top');
            }
          }
        } else {
          templateListElement.classList.remove('list-fixed-top', 'list-fixed-bottom');
          templateSection.classList.remove('div-offset');
        }
      }
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
