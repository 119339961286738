<ng-container *ngIf="albumLoaded; else loadingIcon1">
  <div class="sp-panel" *ngIf="layoutService.isTabletDownView.value; else pcPanel">
    <div class="album-header">
      <img
        class="album-thumbnail object-fit-cover"
        data-cy="album-image"
        [src]="albumDetail.thumbnail"
        alt="albumDetail.title"
        *ngIf="albumDetail.thumbnail; else defaultImage"
      />

      <div class="album-title label-text" data-cy="album-title">
        {{ albumDetail.title }}
      </div>
    </div>
    <div class="field-with-margin-r">
      <button type="button" class="commands" data-cy="show-menu" (click)="toggleCommandsBox()">
        <img src="/assets/images/icon-dots.svg" alt="dot-icon" />
      </button>
      <div class="commands-box" *ngIf="showCommandsBox">
        <ul class="option-list">
          <li
            class="clickable-item"
            data-cy="can-be-edited"
            *ngIf="albumDetail.can_be_edited"
            routerLink="/albums/teacher/delivery/{{ albumId }}/edit"
          >
            変更・取り消し
          </li>
          <li
            class="clickable-item"
            data-cy="copy-delivered-album"
            [routerLink]="['/albums', 'teacher', 'delivery', 'new']"
            [queryParams]="{ album_id: albumId }"
          >
            コピーして配信
          </li>
          <li class="clickable-item" data-cy="can-add-work" *ngIf="albumDetail.can_add_work" (click)="createWork()">課題配信</li>
        </ul>
      </div>
      <div class="field">
        配信者：<span data-cy="creator-name">{{ albumDetail.create_user_name }}先生</span>
      </div>
      <div class="field">
        配信日時：<span data-cy="created-at">{{ albumDetail.created_at }}</span>
      </div>
      <div class="field" *ngIf="albumDetail.updated_at">
        更新日時：<span data-cy="updated-at">{{ albumDetail.updated_at }}</span>
      </div>
    </div>

    <div class="field link-field">
      配信先スペース：<span
        class="spen-util-link"
        data-cy="space-name"
        [routerLink]="['/spaces/teacher', albumDetail.space.id]"
        [queryParams]="{ tab: 'albums' }"
        >{{ albumDetail.space.name }}</span
      >
    </div>

    <div class="field description-field" *ngIf="albumDetail.comment">
      説明：<app-read-more [content]="albumDetail.comment" [collapsedLength]="100"></app-read-more>
    </div>
  </div>

  <ng-template #pcPanel>
    <div class="pc-panel">
      <div class="album-detail">
        <div class="album-header">
          <img
            class="album-thumbnail object-fit-cover"
            [src]="albumDetail.thumbnail"
            alt="albumDetail.title"
            data-cy="album-image"
            *ngIf="albumDetail.thumbnail; else defaultImage"
          />

          <div class="album-title label-text" data-cy="album-title">
            {{ albumDetail.title }}
          </div>
        </div>
        <div class="field">
          <span class="field-label">配信者：</span><span data-cy="creator-name">{{ albumDetail.create_user_name }}先生</span>
        </div>
        <div class="field timestamps">
          <span data-cy="created-at">配信日時：{{ albumDetail.created_at }}</span>
          <span *ngIf="albumDetail.updated_at" data-cy="updated-at">更新日時：{{ albumDetail.updated_at }}</span>
        </div>
        <div class="field link-field">
          <span class="field-label">配信先スペース：</span>
          <span
            class="spen-util-link"
            data-cy="space-name"
            [routerLink]="['/spaces/teacher', albumDetail.space.id]"
            [queryParams]="{ tab: 'albums' }"
            >{{ albumDetail.space.name }}</span
          >
        </div>
        <div class="field description-field" *ngIf="albumDetail.comment">
          <span class="field-label">説明：</span><span class="preserve-line-break" data-cy="album-comment">{{ albumDetail.comment }}</span>
        </div>
      </div>
      <div class="commands">
        <div
          class="spen-util-link"
          data-cy="can-be-edited"
          *ngIf="albumDetail.can_be_edited"
          routerLink="/albums/teacher/delivery/{{ albumId }}/edit"
        >
          変更・取り消し
        </div>
        <div
          class="spen-util-link"
          data-cy="copy-delivered-album"
          [routerLink]="['/albums', 'teacher', 'delivery', 'new']"
          [queryParams]="{ album_id: albumId }"
        >
          コピーして配信
        </div>
        <div class="spen-util-link" data-cy="can-add-work" *ngIf="albumDetail.can_add_work" (click)="createWork()">課題配信</div>
      </div>
    </div>
  </ng-template>

  <div class="albums-students-list-info">
    <div class="label-text">アルバム一覧</div>
    <div class="clearfix">
      <div class="used-count pull-left" data-cy="user-number">
        利用開始数：{{ albumDetail.students_used_count | number }}/{{ albumDetail.students_count | number }}
      </div>
      <div
        class="paginate-result pull-right"
        data-cy="text-page-info"
        *ngIf="studentsListLoaded && !isSorting && !layoutService.isTabletDownView.value"
      >
        {{ metaData | paginateText }}
      </div>
    </div>
  </div>
</ng-container>

<div class="sp-filter" *ngIf="layoutService.isTabletDownView.value && formLoaded">
  <div class="filter-info">
    <div class="field">
      <div class="label-text">並び順</div>
      <div class="text" data-cy="text-sort-oder">{{ sortText() }}</div>
    </div>
  </div>
  <div class="filter-panel">
    <div class="drawers">
      <a class="spen-util-link" (click)="toggleFilter()">
        <span class="">変更</span>
        <i [class]="isMobileFilterOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter-status"></i>
      </a>
    </div>
    <form *ngIf="isMobileFilterOpen" [formGroup]="filterForm">
      <div class="filter-inputs">
        <div class="input-group">
          <div class="label-text">並び順</div>
          <label class="spen-mod-select">
            <select class="select" formControlName="sort" data-cy="sort-order">
              <option data-cy="sort-order-name" *ngFor="let choice of sortSelections" [value]="choice.value">
                {{ choice.text }}
              </option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
        </div>
      </div>
      <div class="filter-buttons">
        <button
          class="spen-mod-btn is-primary"
          data-cy="submit-filter"
          (click)="onSubmit()"
          [disabled]="isSorting || !studentsListLoaded"
          type="button"
        >
          適用する
        </button>
        <button class="spen-mod-btn is-default" data-cy="remove-filter" (click)="resetForm()" [disabled]="isDefaultForm()" type="button">
          選択解除
        </button>
      </div>
    </form>
  </div>
</div>

<ng-container *ngIf="studentsListLoaded; else loadingIcon2">
  <div class="sp-album-students-list" *ngIf="layoutService.isTabletDownView.value; else pcList">
    <div class="paginate-result" data-cy="text-page-info">{{ metaData | paginateText }}</div>
    <ng-container *ngIf="metaData.total_count > 0; else emptyList">
      <div
        class="album-item {{ !!record.student.viewer_permission ? 'clickable-item' : 'disabled-row' }}"
        data-cy="album-item"
        *ngFor="
          let record of albumsStudents
            | paginate
              : {
                  itemsPerPage: metaData.per_page,
                  currentPage: metaData.current_page,
                  totalItems: metaData.total_count,
                }
        "
        (click)="onClickStudent(albumId, record.student.id)"
      >
        <div class="user-info">
          <img [src]="record.student.avatar" class="circle-img pull-left" data-cy="avator" />
          <div class="student-info">
            <div class="truncate-text" data-cy="student-name">
              {{ record.student.name }}
            </div>
            <div class="small-text truncate-text" data-cy="class-name">
              {{ record.student.class_name }}{{ record.student.attendance_number }}<span *ngIf="record.student.class_name">番</span>
            </div>
          </div>
        </div>
        <div class="record-number small-text" data-cy="activity-records-count">
          活動記録数：{{ record.activity_records_count | number }}
        </div>
        <div class="update-time muted-text small-text">
          <div>
            更新日時：<span data-cy="last-activity-at">{{ record.last_activity_at }}</span>
          </div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>
    </ng-container>
  </div>

  <ng-template #pcList>
    <div *ngIf="metaData.total_count > 0; else emptyList" class="spen-mod-flex-table table-list-full has-link">
      <div class="flex-table-head">
        <div class="flex-item col-size-7 is-first-col">利用者</div>
        <div class="flex-item col-size-4">
          <a class="spen-util-link" data-cy="col-class-name" (click)="onChangeOrder('class_name')">
            <span>校種学年組番</span>
            <i [class]="sortStatus.class_name" data-cy="icon-class-name"></i>
          </a>
        </div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" data-cy="col-last-activity-at" (click)="onChangeOrder('last_activity_at')">
            <span>更新日時</span>
            <i [class]="sortStatus.last_activity_at" data-cy="icon-last-activity-at"></i>
          </a>
        </div>
        <div class="flex-item col-size-2 pull-right">
          <a class="spen-util-link" data-cy="col-activity-records-count" (click)="onChangeOrder('activity_records_count')">
            <span>活動記録数</span>
            <i [class]="sortStatus.activity_records_count" data-cy="icon-activity-records-count"></i>
          </a>
        </div>
        <div class="flex-item redirect-cell"></div>
      </div>
      <a
        class="flex-table-row"
        data-cy="album-item"
        *ngFor="
          let record of albumsStudents
            | paginate
              : {
                  itemsPerPage: metaData.per_page,
                  currentPage: metaData.current_page,
                  totalItems: metaData.total_count,
                }
        "
        [class.disabled-item]="!record.student.viewer_permission"
        (click)="onClickStudent(albumId, record.student.id)"
      >
        <div class="flex-item col-size-7 is-first-col user-info truncate-text">
          <img [src]="record.student.avatar" alt="" class="circle-img" data-cy="avator" />
          <span
            class="truncate-text"
            data-cy="student-name"
            [class.spen-util-link]="!!record.student.viewer_permission"
            routerLink="/students/teacher/{{ record.student.id }}"
            (click)="stopOuterLinkEvent($event)"
            >{{ record.student.name }}</span
          >
        </div>
        <div class="flex-item col-size-4 truncate-text" data-cy="class-name">
          {{ record.student.class_name }}{{ record.student.attendance_number }}{{ record.student.class_name ? '番' : '' }}
        </div>
        <div class="flex-item col-size-3" data-cy="last-activity-at">{{ record.last_activity_at }}</div>
        <div class="flex-item col-size-2 pull-right" data-cy="activity-records-count">{{ record.activity_records_count | number }}</div>
        <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
      </a>
    </div>
  </ng-template>

  <div class="spen-mod-paginate" *ngIf="showPagination">
    <app-pagination-controls
      (pageChange)="pageChanged($event)"
      *ngIf="layoutService.isTabletDownView.value"
      directionLinks="false"
      [stylePaginate]="'small'"
    >
    </app-pagination-controls>
    <app-pagination-controls (pageChange)="pageChanged($event)" *ngIf="!layoutService.isTabletDownView.value"> </app-pagination-controls>
  </div>
</ng-container>

<div class="guide">
  <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
  <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
</div>

<ng-template #loadingIcon1>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
<ng-template #loadingIcon2>
  <ng-container *ngIf="albumLoaded">
    <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
  </ng-container>
</ng-template>
<ng-template #emptyList>
  <div class="null-content">
    <p class="text">XXが表示されます。</p>
  </div>
</ng-template>
<ng-template #defaultImage>
  <img class="default-image album-thumbnail" src="/assets/images/album.svg" />
</ng-template>
