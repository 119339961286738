import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { ActivityRecordTemplateFormService } from '@components/activity-record-templates/teacher/form/activity-record-template-form.service';
import { WorkTemplate } from '@models/work-template';
import { RouterService } from '@services/router.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { ToastService } from '@services/toast/toast.service';
import { LayoutService } from '@components/layout/layout.service';
import { ActivityRecordTemplatesService } from '@components/activity-record-templates/activity-record-templates.service';
import { WorkTemplateQuestion } from '@models/work-template-question';

@Component({
  selector: 'app-record-template-step2',
  templateUrl: './record-template-step2.component.html',
  styleUrls: ['../activity-record-template-form.component.scss'],
})
export class RecordTemplateStep2Component implements OnInit, OnDestroy {
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };
  errorMsg: string;
  workTemplate: WorkTemplate;
  uploader: FileUploader = new FileUploader({ url: '' });
  saveStatus = 'none';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    public formService: ActivityRecordTemplateFormService,
    private routerService: RouterService,
    private uploadFilesService: UploadFilesService,
    private templateService: ActivityRecordTemplatesService,
    private toastService: ToastService,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    const currentStep = this.formService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.workTemplate = this.formService.workTemplate;
    this.routerService.registerBackConfirm(this.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  onSubmit() {
    this.saveStatus = 'init';
    this.prepareAllAttachFile();
    this.uploadAttachFileAndSubmit();
  }

  goBack() {
    this.formService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], {
      queryParams: { step: 1 },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isCompleted) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  get isCompleted() {
    return this.saveStatus === 'completed';
  }

  get isSubmitting() {
    return this.saveStatus !== 'none';
  }

  get remainingQuestions() {
    return this.workTemplate.work_template_questions_attributes.filter((question) => !question['_destroy']);
  }

  prepareAllAttachFile() {
    const images = [];
    this.workTemplate.work_template_questions_attributes.forEach((question, index) => {
      question['order'] = index;
      if (question['image_local'] && !question['_destroy']) {
        question['image_local']['item_index'] = index;
        images.push(question['image_local']);
      }
    });
    this.uploader.addToQueue(images);
  }

  uploadAttachFileAndSubmit() {
    const result = this.uploadFilesService.uploadAllFile(this.uploader);
    if (result === true) {
      this.submitTemplate();
    } else if (result instanceof EventEmitter) {
      result.subscribe((value) => {
        if (value) {
          this.submitTemplate();
        }
        result.unsubscribe();
      });
    }
  }

  submitTemplate() {
    const uploadedFiles = this.uploadFilesService.sentUploadedFiles(this.uploader);
    uploadedFiles.forEach((file) => {
      const questionIndex = file['item_index'];
      if (questionIndex !== undefined) {
        const questionParams = this.workTemplate.work_template_questions_attributes;
        questionParams[questionIndex]['image_attributes'] = file;
        questionParams[questionIndex]['image_local'] = null;
      }
    });

    this.formService.isEdit ? this.updateTemplate() : this.createTemplate();
  }

  createTemplate() {
    this.saveStatus = 'submitting';
    this.templateService.createWorkTemplate(this.workTemplate).subscribe(
      () => {
        this.saveStatus = 'completed';
        this.navigateAfterSaveTemplate();
      },
      (error) => {
        this.errorMsg = <any>error;
        this.saveStatus = 'none';
      },
    );
  }

  updateTemplate() {
    this.saveStatus = 'submitting';
    this.workTemplate.work_template_questions_attributes.push(...this.formService.removedWorkTemplates);
    this.templateService.updateWorkTemplate(this.formService.editTemplateId, this.workTemplate).subscribe(
      () => {
        this.saveStatus = 'completed';
        this.navigateAfterSaveTemplate();
      },
      (error) => {
        this.errorMsg = <any>error;
        this.saveStatus = 'none';
      },
    );
  }

  private navigateAfterSaveTemplate() {
    this.router.navigateByUrl(`/activity-record-templates/teacher`, { replaceUrl: true });
    const toastMessage = this.formService.isEdit ? '変更しました' : '作成しました';
    this.toastService.showToast(toastMessage);
  }

  cancelUploadingFiles() {
    this.uploadFilesService.cancelUploadedFiles(this.uploader);
    this.saveStatus = 'none';
  }

  hasImageToBeUpdated(question: WorkTemplateQuestion) {
    return question.image_attributes && !question.image_attributes['_destroy'];
  }
}
