<div class="evaluation" *ngIf="!layoutService.isTabletDownView.value">
  <a class="spen-util-link" (click)="onShowModalDialog()">相互評価の取り組み状況を見る</a>
</div>

<div class="activity-records-sp" *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="filter-box" *ngIf="formInited">
    <div class="filter-text-info">
      <div class="text-row">
        <p class="label">検索条件</p>
        <p class="filter-result" data-cy="text-filter">
          {{ filterDataService.messageFilter }}
        </p>
      </div>
      <div class="text-row">
        <p class="label">並び順</p>
        <p class="filter-result" data-cy="text-sort-order">
          {{ messageSort }}
        </p>
      </div>
    </div>

    <a class="btn-show-detail spen-util-link" (click)="isShowMoreFilter = !isShowMoreFilter">
      <span>変更</span>
      <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
    </a>
    <form class="filter-detail" [formGroup]="form" *ngIf="isShowMoreFilter">
      <div class="selector-group">
        <div class="filter-group">
          <div class="filter-label">
            <b>種別</b>
          </div>
          <div class="filter-inputs">
            <label class="spen-mod-select">
              <select class="select" data-cy="activity-type" formControlName="activity_type">
                <option class="select-option" [ngValue]="null">すべて</option>
                <option class="select-option" value="free">自主作成</option>
                <option class="select-option" value="answer">課題回答</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>キーワード</b>
          </div>
          <div class="filter-inputs">
            <input
              type="text"
              class="spen-mod-input-text"
              data-cy="search-title"
              maxlength="100"
              formControlName="keyword"
              placeholder="キーワードを入力してください"
            />
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>カテゴリー</b>
          </div>
          <div class="filter-inputs">
            <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
            </app-teacher-categories-selection>
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>コメント状況</b>
          </div>
          <div class="filter-inputs">
            <label class="spen-mod-select">
              <select
                class="select"
                data-cy="comment-status"
                formControlName="comment_status"
                (change)="selectCommentStatus($event.target.value)"
              >
                <option class="select-option" [ngValue]="null">すべて</option>
                <option class="select-option" value="has_comment">コメントあり</option>
                <option class="select-option" value="no_comment">コメントなし</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
            <label class="spen-mod-select">
              <select class="select" data-cy="sub-comment-status" formControlName="sub_comment_status">
                <option class="select-option" value="has_comment">すべて</option>
                <option class="select-option" value="cross">生徒コメント</option>
                <option class="select-option" value="teacher">先生コメント</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>公開方法</b>
          </div>
          <div class="filter-inputs">
            <label class="spen-mod-select">
              <select class="select" data-cy="public-status" formControlName="public_status">
                <option class="select-option" [ngValue]="null">すべて</option>
                <option class="select-option" value="self_publish">自主公開</option>
                <option class="select-option" value="pickup">ピックアップ</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>マーク</b>
          </div>
          <div class="filter-inputs mark">
            <label
              class="spen-mod-checkbox check-box-inline"
              data-cy="mark-ids"
              formArrayName="mark_ids"
              *ngFor="let mark of marks; let i = index"
            >
              <input class="checkbox" data-cy="mark-ids-checkbox" [formControlName]="i" type="checkbox" />
              <i class="fa"></i>
              <img class="mark-icon-fix-width" data-cy="mark-ids-icon" [src]="mark.icon" />
              <span>{{ mark.name }}</span>
            </label>
          </div>
        </div>

        <div class="filter-group">
          <div class="filter-label">
            <b>並び順</b>
          </div>
          <div class="filter-inputs">
            <label class="spen-mod-select">
              <select class="select" data-cy="sort-order" formControlName="sort_value" (change)="onChangeSortValue($event.target.value)">
                <option class="select-option" [value]="sortOption.sort_value" *ngFor="let sortOption of sortOptions">
                  {{ sortOption.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
          適用する
        </button>
        <button
          type="button"
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          (click)="onResetFilter()"
          [disabled]="isContentLoading || isDefaultForm"
        >
          選択解除
        </button>
      </div>
    </form>
  </div>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <div class="data-box">
      <p class="paginate-result" data-cy="text-page-info">{{ pages | paginateText }}</p>
      <div class="list-activity-records" *ngIf="publicActivityRecords.length > 0; else emptyList">
        <div
          class="activity-record"
          [ngClass]="record.student.viewer_permission ? 'clickable-item' : 'disabled-item'"
          data-cy="activity-item"
          (click)="onClickActivityRecord(record)"
          *ngFor="
            let record of publicActivityRecords
              | paginate
                : {
                    itemsPerPage: this.pages.per_page,
                    currentPage: this.pages.current_page,
                    totalItems: this.pages.total_count,
                  }
          "
        >
          <div class="info">
            <p class="title" data-cy="activity-title">{{ record.title }}</p>
            <div class="user-info">
              <img data-cy="acivity-creator-avator" [src]="record.student.avatar" />
              <div>
                <p class="truncate-text" data-cy="activity-creator">{{ record.student.name }}</p>
                <p class="truncate-text class-name" data-cy="activity-creator-class">
                  {{ record.student.class_name }}{{ record.student.attendance_number }}番
                </p>
              </div>
            </div>
            <p class="create-time small-text" data-cy="activity-created-at">作成日時：{{ record.created_at }}</p>
            <p class="update-time small-text" data-cy="activity-updated-at">更新日時：{{ record.updated_at }}</p>
          </div>
          <i class="fa fa-chevron-right"></i>
        </div>
      </div>
      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls
          [directionLinks]="!layoutService.isMobileView.value"
          (pageChange)="pageChanged($event)"
        ></app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #pcView>
  <div class="activity-records">
    <form class="filter-box" [formGroup]="form" *ngIf="formInited">
      <div class="filter-detail">
        <div class="filter-row">
          <div class="filter-group">
            <div class="filter-label">
              <b>種別</b>
            </div>
            <div class="filter-inputs">
              <label class="spen-mod-radio" data-cy="activity-type">
                <input class="radio" data-cy="activity-type-radio" formControlName="activity_type" type="radio" [value]="null" />
                <i class="fa"></i>
                <span class="text">すべて</span>
              </label>
              <label class="spen-mod-radio" data-cy="activity-type">
                <input class="radio" data-cy="activity-type-radio" value="free" formControlName="activity_type" type="radio" />
                <i class="fa"></i>
                <span class="text">自主作成</span>
              </label>
              <label class="spen-mod-radio" data-cy="activity-type">
                <input class="radio" data-cy="activity-type-radio" value="answer" formControlName="activity_type" type="radio" />
                <i class="fa"></i>
                <span class="text">課題回答</span>
              </label>
            </div>
          </div>

          <div class="filter-group">
            <div class="filter-label second-label">
              <b>キーワード</b>
            </div>
            <div class="filter-inputs">
              <input
                class="spen-mod-input-text input-search-key"
                data-cy="search-title"
                placeholder="キーワードを入力してください"
                formControlName="keyword"
                type="text"
                maxlength="100"
              />
            </div>
          </div>

          <a class="btn-show-detail spen-util-link" (click)="isShowMoreFilter = !isShowMoreFilter">
            <span>詳細検索</span>
            <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
          </a>
        </div>

        <ng-container *ngIf="isShowMoreFilter">
          <div class="filter-row">
            <div class="filter-group">
              <div class="filter-label">
                <b>カテゴリー</b>
              </div>
              <div class="filter-inputs">
                <app-teacher-categories-selection formControlName="category_id" [categories]="categoriesService.categories">
                </app-teacher-categories-selection>
              </div>
            </div>
          </div>

          <div class="filter-row">
            <div class="filter-group">
              <div class="filter-label">
                <b>コメント状況</b>
              </div>
              <div class="filter-inputs">
                <label class="spen-mod-select">
                  <select
                    class="select"
                    data-cy="comment-status"
                    formControlName="comment_status"
                    (change)="selectCommentStatus($event.target.value)"
                  >
                    <option class="select-option" class="is-placeholder" [ngValue]="null">すべて</option>
                    <option class="select-option" value="has_comment">コメントあり</option>
                    <option class="select-option" value="no_comment">コメントなし</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
                <label class="spen-mod-select">
                  <select class="select" data-cy="sub-comment-status" formControlName="sub_comment_status">
                    <option class="select-option" value="has_comment">すべて</option>
                    <option class="select-option" value="cross">生徒コメント</option>
                    <option class="select-option" value="teacher">先生コメント</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>

            <div class="filter-group">
              <div class="filter-label second-label">
                <b>公開方法</b>
              </div>
              <div class="filter-inputs">
                <label class="spen-mod-select">
                  <select class="select" data-cy="public-status" formControlName="public_status">
                    <option class="select-option" [ngValue]="null">すべて</option>
                    <option class="select-option" value="self_publish">自主公開</option>
                    <option class="select-option" value="pickup">ピックアップ</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>
          </div>

          <div class="filter-row">
            <div class="filter-group">
              <div class="filter-label">
                <b>マーク</b>
              </div>
              <div class="filter-inputs mark">
                <label
                  class="spen-mod-checkbox check-box-inline"
                  data-cy="mark-ids"
                  formArrayName="mark_ids"
                  *ngFor="let mark of marks; let i = index"
                >
                  <input class="checkbox" data-cy="mark-ids-checkbox" [formControlName]="i" type="checkbox" />
                  <i class="fa"></i>
                  <img class="mark-icon-fix-width" data-cy="mark-ids-icon" [src]="mark.icon" />
                  <span>{{ mark.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="button-group">
        <button
          type="button"
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          (click)="onResetFilter()"
          [disabled]="isContentLoading || isDefaultForm"
        >
          選択解除
        </button>
        <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isContentLoading">
          検索する
        </button>
      </div>
    </form>

    <div class="filter-info" *ngIf="contentLoaded">
      <p class="filter-result-info" data-cy="text-filter" *ngIf="isSearching || publicActivityRecords.length > 0">
        検索条件：{{ filterDataService.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
    </div>

    <div class="data-box" *ngIf="contentLoaded; else loadingIcon">
      <div class="spen-mod-flex-table table-list-full has-link" *ngIf="publicActivityRecords.length > 0; else emptyList">
        <div class="flex-table-head">
          <div class="flex-item col-size-6 is-first-col">活動記録名</div>
          <div class="flex-item col-size-4">作成者</div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-created-at" (click)="changeBySortColumn('created_at')">
              作成日時
              <i [class]="sortStatus['created_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link" data-cy="col-updated-at" (click)="changeBySortColumn('updated_at')">
              更新日時
              <i [class]="sortStatus['updated_at']" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item redirect-cell"></div>
        </div>
        <a
          class="flex-table-row"
          data-cy="activity-item"
          [class.disabled-item]="!record.student.viewer_permission"
          *ngFor="
            let record of publicActivityRecords
              | paginate
                : {
                    itemsPerPage: this.pages.per_page,
                    currentPage: this.pages.current_page,
                    totalItems: this.pages.total_count,
                  }
          "
          (click)="onClickActivityRecord(record)"
        >
          <div class="flex-item col-size-6 is-first-col truncate-text" data-cy="activity-title">{{ record.title }}</div>
          <div class="flex-item col-size-4 student-info">
            <img [src]="record.student.avatar" data-cy="acivity-creator-avator" />
            <div>
              <a
                class="truncate-text"
                data-cy="activity-creator"
                [ngClass]="record.student.viewer_permission ? 'spen-util-link' : 'font-black'"
                (click)="onClickStudentDetail($event, record)"
              >
                {{ record.student.name }}
              </a>
              <p class="truncate-text class-name" data-cy="activity-creator-class">
                {{ record.student.class_name }}{{ record.student.attendance_number }}番
              </p>
            </div>
          </div>
          <div class="flex-item col-size-3" data-cy="activity-created-at">{{ record.created_at }}</div>
          <div class="flex-item col-size-3" data-cy="activity-updated-at">{{ record.updated_at }}</div>
          <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
        </a>
      </div>

      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
      </div>
    </div>
  </div>
</ng-template>

<div class="permission" *ngIf="pages.total_count > 0 && contentLoaded">
  <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
  <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<ng-template #emptyList>
  <div class="null-content">
    <p>{{ isSearching ? '検索結果が0件です。' : 'ここにスペースに公開中の活動記録が表示されます。' }}</p>
  </div>
</ng-template>

<app-cross-review-dialog *ngIf="isShowDialogModal" (cancelModal)="onCancelModalDialog()"></app-cross-review-dialog>
