import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StudentService } from '../student.service';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { CategoriesService } from '@services/categories/categories.service';

@Component({
  selector: 'app-activity-detail-modal',
  templateUrl: './activity-detail-modal.component.html',
  styleUrls: ['./activity-detail-modal.component.scss'],
})
export class ActivityDetailModalComponent implements OnInit {
  tabOptions = { self: '自分', cross_assessment: 'みんな', teacher_assessment: '先生' };
  currentTab = 'self';
  activityLoaded = false;
  activityRecord: ActivityRecord;
  errMsg: string;
  @Input() activityId: number;
  @Output() eventForBackAction = new EventEmitter<string>();

  categoryName = '';

  constructor(
    private studentService: StudentService,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit() {
    this.studentService.getActivityRecord(this.activityId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.activityLoaded = true;
        this.getCategoryName();
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  switchTab(tabKey: string) {
    this.currentTab = tabKey;
  }

  isDocumentRecord() {
    return this.activityRecord.record_type === 'Document';
  }

  isQuestionnaireRecord() {
    return this.activityRecord.record_type === 'Questionnaire';
  }

  onBack(action: string) {
    this.eventForBackAction.emit(action);
  }

  dataLoaded() {
    return this.activityLoaded;
  }

  getCategoryName() {
    this.categoriesService.getCategoryName(this.activityRecord.category_id).then((name) => (this.categoryName = name));
  }
}
