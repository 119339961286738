import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SlideMenuService {
  private renderer: Renderer2;
  private slideSource = new BehaviorSubject<string>('out');
  slideStatus$ = this.slideSource.asObservable();

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  slideIn() {
    this.slideSource.next('in');
    this.renderer.addClass(document.body, 'modal-open');
  }

  slideOut() {
    this.slideSource.next('out');
    this.renderer.removeClass(document.body, 'modal-open');
  }

  get isShowSidebar(): boolean {
    return this.slideSource.value === 'in';
  }
}
