import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { User } from '@models/user';
import { Observable } from 'rxjs';
import { TeacherFilterService, StudentFilterService } from './space-filter.service';
import { isTouchDevice } from '@functions/browser';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-space-filter',
  templateUrl: './space-filter.component.html',
  styleUrls: ['./space-filter.component.scss'],
})
export class SpaceFilterComponent implements OnInit, OnDestroy {
  readonly PER_PAGE = 20;

  @Input() creatorId: number;
  @Input() isTeacher: boolean;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  spaceFilterService: TeacherFilterService | StudentFilterService;

  singleUserResultsSub: Observable<Array<any>>;
  groupUserResultsSub: Observable<Array<any>>;

  query = '';
  nextPage: number;
  allItemResultCount: number;
  selectAll: UntypedFormControl = new UntypedFormControl(false);
  isShowError = false;
  isLoading = false;
  isLoadedAllGroups = false;
  isLoadedAllDatas = false;
  isEmptySearchResult = true;
  isLoadingSelectAll = false;

  labelConstants = { title: '', placeHolderSP: '', placeHolder: '', tableTitle: '', role: '' };
  errMsg: string;

  constructor(
    private injector: Injector,
    private renderer: Renderer2,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.spaceFilterService = this.isTeacher ? this.injector.get(TeacherFilterService) : this.injector.get(StudentFilterService);

    this.singleUserResultsSub = this.spaceFilterService.singleUserResults$;
    this.groupUserResultsSub = this.spaceFilterService.groupUserResultsS$;
    this.labelConstants = {
      title: this.isTeacher ? '先生を追加する' : '生徒を追加する',
      placeHolderSP: this.isTeacher ? '先生名を入力' : '生徒情報・グループを入力',
      placeHolder: this.isTeacher ? '先生名を入力' : '生徒情報・グループ名を入力',
      tableTitle: this.isTeacher ? '先生リスト' : 'グループ・生徒リスト',
      role: this.isTeacher ? '先生' : '生徒',
    };

    this.initSearch();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onClose() {
    this.spaceFilterService.onClose();
    this.closeEvent.emit();
  }

  initSearch() {
    this.isLoading = true;
    this.spaceFilterService.onSearch();
    if (this.isTeacher) {
      this.spaceFilterService.searchUsers({ name: '' }).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    } else {
      this.spaceFilterService.searchGroups({ name: '' }).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    }
  }

  onSearch($event) {
    if ($event.preventDefault) {
      $event.preventDefault();
    }
    if (this.isLoading || this.query.trim().length < 1) {
      return;
    }

    this.isLoading = true;
    this.isLoadedAllGroups = this.isLoadedAllDatas = false;
    this.spaceFilterService.onSearch();
    if (this.selectAll.value) {
      this.selectAll.setValue(false);
    }
    if (this.isTeacher) {
      this.spaceFilterService.searchUsers({ name: this.query }).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    } else {
      this.spaceFilterService.searchGroups({ name: this.query }).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    }
  }

  onLoadMore() {
    if (this.isLoading || this.isLoadedAllDatas) {
      return;
    }
    this.isTeacher ? this.onLoadMoreTeacher() : this.onLoadMoreStudent();
  }

  private onLoadMoreTeacher() {
    if (this.nextPage) {
      this.isLoading = true;
      this.spaceFilterService.searchUsers({ name: this.query }, this.nextPage).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    }
  }

  private onLoadMoreStudent() {
    if (this.nextPage) {
      this.isLoading = true;
      if (this.isLoadedAllGroups) {
        this.spaceFilterService.searchUsers({ name: this.query }, this.nextPage).subscribe(
          (res) => this.onSearchDone(res),
          (err) => this.onSearchFailed(err),
        );
      } else {
        this.spaceFilterService.searchGroups({ name: this.query }, this.nextPage).subscribe(
          (res) => this.onSearchDone(res),
          (err) => this.onSearchFailed(err),
        );
      }
    } else if (this.isLoadedAllGroups) {
      this.isLoading = true;
      this.spaceFilterService.searchUsers({ name: this.query }).subscribe(
        (res) => this.onSearchDone(res),
        (err) => this.onSearchFailed(err),
      );
    }
  }

  onSearchDone(params: { single: Array<any>; group: Array<any>; nextPage?: number }) {
    this.isLoading = false;
    this.isEmptySearchResult = false;
    this.nextPage = params.nextPage;
    this.spaceFilterService.onSearchDone(params);

    const allIdsResultArr = [this.spaceFilterService.groupUserResults.value, this.spaceFilterService.singleUserResults.value];
    this.allItemResultCount = this.spaceFilterService.getAllIdByListObject(allIdsResultArr).length;

    if (!this.allItemResultCount) {
      this.isEmptySearchResult = true;
    }
    if (this.nextPage) {
      return;
    }

    if (this.isTeacher) {
      this.isLoadedAllDatas = true;
    } else {
      if (this.isLoadedAllGroups) {
        this.isLoadedAllDatas = true;
        return;
      }
      this.isLoadedAllGroups = true;
      if (this.spaceFilterService.groupUserResults.getValue.length < this.PER_PAGE) {
        this.onLoadMoreStudent();
      }
    }
  }

  onSearchFailed(err) {
    this.isLoading = false;
    this.isLoadingSelectAll = false;
    this.selectAll.setValue(false);
    this.errMsg = <any>err;
  }

  onChangeUser(user: User) {
    if (user['disabled']) {
      return;
    }
    if (this.isShowError) {
      this.isShowError = false;
    }
    this.selectAll.setValue(false);
    this.spaceFilterService.onChangeUser(user);
    if (this.allItemResultCount === this.selectedItemsCount) {
      this.selectAll.setValue(true);
    }
  }

  onChangeGroup(group) {
    if (group['disabled']) {
      return;
    }
    if (this.isShowError) {
      this.isShowError = false;
    }
    this.selectAll.setValue(false);
    this.spaceFilterService.onChangeGroup(group);

    if (this.allItemResultCount === this.selectedItemsCount) {
      this.selectAll.setValue(true);
    }
  }

  onSelectAll($event) {
    const checked = $event.target.checked;
    if (checked && this.isShowError) {
      this.isShowError = false;
    }
    if (checked && !this.isLoadedAllDatas) {
      this.isLoadingSelectAll = true;
      if (!this.isTeacher && !this.isLoadedAllGroups) {
        this.spaceFilterService.searchGroups({ name: this.query, selected_all: true }).subscribe(
          (res) => {
            this.onSearchAllDone(res);
            this.onSearchUser({ name: this.query, selected_all: true });
          },
          (err) => this.onSearchFailed(err),
        );
      } else {
        this.onSearchUser({ name: this.query, selected_all: true });
      }
    } else {
      this.spaceFilterService.onSelectAll($event.target.checked);
    }
  }

  onSearchUser(data: {}, nextPage = null) {
    this.spaceFilterService.searchUsers(data, nextPage).subscribe(
      (res) => this.onSearchAllDone(res),
      (err) => this.onSearchFailed(err),
    );
  }

  onSearchAllDone(params: { single: Array<any>; group: Array<any> }) {
    this.spaceFilterService.removeOldItems(this.isLoadedAllGroups);
    this.spaceFilterService.onSearchDone(params);
    if (!this.isTeacher && !this.isLoadedAllGroups) {
      this.isLoadedAllGroups = true;
    } else {
      this.isLoadedAllDatas = true;
      this.isLoadingSelectAll = false;
      this.spaceFilterService.onSelectAll(true);
    }
    this.allItemResultCount = this.selectedItemsCount;
  }

  onSubmit() {
    const submitSuccess = this.spaceFilterService.onSubmit();
    if (submitSuccess) {
      this.onClose();
      this.submitEvent.emit();
    } else {
      this.isShowError = true;
    }
  }

  get isTouchDevice() {
    return isTouchDevice();
  }

  get selectedItemsCount() {
    return this.spaceFilterService.allIdsCheckedAndUnselected.length;
  }

  countSelectedLabel() {
    if (this.isTeacher) {
      return `${this.spaceFilterService.allIdsCheckedAndUnselected.length}名を選択中`;
    } else {
      return `${this.spaceFilterService.groupUserResultsFilter({ checked: true, selected: false, disabled: false }).length}
        グループ ${this.spaceFilterService.allIdsCheckedAndUnselected.length}名を選択中`;
    }
  }
}
