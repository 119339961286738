<div class="tea-create-album" *ngIf="spaceLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps" [class.list-steps-edit]="creatorService.albumId">
      <app-steps [steps]="creatorService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form [formGroup]="form" [class.disable-clicked]="isDeleting" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="form-group">
        <div class="row align-center">
          <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
            <label>配信アルバム名</label>
            <span class="spen-mod-badge is-red">必須</span>
          </div>
          <div class="col-md-9 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
            <input
              class="spen-mod-input-text"
              type="text"
              [appPlaceholder]="'例）高2進路'"
              formControlName="title"
              [appFocus]="focusEvent"
              data-cy="title"
            />
          </div>
          <div class="col-md-3" [class.col-sm-3]="!creatorService.isTabletAndDown()"></div>
          <div class="col-md-5 col-xs-12" [class.col-sm-5]="!creatorService.isTabletAndDown()">
            <div class="spen-mod-error-content error-popup" *ngIf="formErrors['title']">
              {{ formErrors['title'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
            <label>説明</label>
          </div>
          <div class="col-md-7 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
            <textarea
              class="spen-mod-input-text"
              [appPlaceholder]="'例）進路について考えたことや気づいたことを蓄積しましょう。'"
              formControlName="comment"
              data-cy="comment"
            >
            </textarea>
            <div class="spen-mod-error-content error-popup" *ngIf="formErrors['comment']">
              {{ formErrors['comment'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group cover-image">
        <div class="row row-uploader" [style.margin-bottom]="isSelectCoverImage ? 0 : ''">
          <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
            <label>表紙画像</label>
          </div>
          <div class="col-md-7 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
            <app-cover-image-uploader
              [attachedFiles]="creatorService.attachedFiles"
              [uploader]="creatorService.fileUploader"
              [removedAttachFiles]="creatorService.removedAttachFiles"
              (eventSelectFile)="onSelectFile($event)"
            >
            </app-cover-image-uploader>
          </div>
        </div>
      </div>

      <div class="actions">
        <div class="delete-album-description" *ngIf="isEditAlbum">※生徒の利用がないアルバムのみ削除できます。</div>
        <div
          class="spen-util-link delete-button"
          data-cy="delete-delivered-album"
          [class.muted-text]="isDeleting"
          *ngIf="creatorService.album.can_destroy"
          (click)="deleteAlbum()"
        >
          <i class="fa fa-trash-o"></i>アルバム配信を取り消す
        </div>
        <div class="list-button-submit" [style.padding-bottom]="isEditAlbum ? '54px' : ''">
          <button type="button" class="spen-mod-btn" (click)="goBack()">戻る</button>
          <button type="submit" class="spen-mod-btn is-primary" data-cy="submit-button">
            {{ isEditAlbum ? '確認へ' : '配信設定へ' }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
