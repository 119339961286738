<div
  class="heading-title"
  *ngIf="routerDataService.routerData.value"
  [class.no-toggle-menu]="!routerDataService.routerData.value.isShowSidebar"
>
  <button
    class="spen-mod-btn is-primary toggle-menu"
    (click)="slideMenu()"
    *ngIf="layoutService.isMediumDesktopDownView.value && routerDataService.routerData.value.isShowSidebar"
  >
    <i class="fa fa-reorder"></i>
  </button>
  <h1 class="heading-text">{{ routerDataService.routerData.value.titleH1 }}</h1>
</div>
