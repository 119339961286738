import { User } from '@models/user';
import { AuthUserService } from '@services/auth-user.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { Assessment } from '@components/assessment/assessment';
import { AssessmentService } from '@components/assessment/assessment.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { validateForm } from '@functions/validate-form';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-assessment-form-student',
  templateUrl: './assessment-form-student.component.html',
  styleUrls: ['./assessment-form-student.component.scss'],
})
export class AssessmentFormStudentComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  errMsg: string;
  formErrors = {};
  validationMessages = {
    content: {
      maxlength: 'コメントは1000文字以内で入力してください。',
    },
  };
  currentUser: User;
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  @Input() activityRecordId: number;
  @Input() assessment: Assessment;
  @Output() saveNewAssessment = new EventEmitter<Assessment>();
  @Output() cancelEditAssessment = new EventEmitter<any>();
  @Output() updateOldAssessment = new EventEmitter<Assessment>();
  @Output() deleteOldAssessment = new EventEmitter<Assessment>();

  @ViewChild('textArea', { static: true }) textArea: ElementRef;

  isSubmitting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assessmentService: AssessmentService,
    private confirmDialogService: ConfirmDialogService,
    private location: Location,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private authUserService: AuthUserService,
    private router: Router,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.getCreateForm(this.assessment);
    this.currentUser = this.authUserService.retrieve();
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  getForm(assessment: Assessment) {
    this.form = this.formBuilder.group({
      content: [assessment.content || '', [Validators.maxLength(1000)]],
    });
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  getCreateForm(assessment: Assessment = null) {
    if (this.isEditForm()) {
      this.getForm(assessment);
      this.textArea.nativeElement.focus();
    } else {
      const emptyAssessment = {
        id: null,
        content: '',
        is_read: false,
        created_at: '',
        updated_at: '',
        user: new User(),
        type: '',
        create_user_id: null,
      };
      this.getForm(emptyAssessment);
    }
  }

  onSubmit() {
    if (this.form.invalid || this.isContentInvalid()) {
      return;
    }
    this.isSubmitting = true;
    this.form.controls.content.disable({ onlySelf: true });
    if (this.isEditForm()) {
      this.updateAssessment();
    } else {
      this.createAssessment();
    }
  }

  createAssessment() {
    this.assessmentService.createSelfAssessment(this.activityRecordId, this.form.value).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        if (!response.error_code) {
          this.saveNewAssessment.emit(response);
          this.googleAnalytics4Service.sendEvent('活動記録', '詳細画面', 'コメントする', { activity_record_id: this.activityRecordId });
          this.getCreateForm();
          this.routerService.resetDataConfirm();
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        this.errMsg = <any>error;
      },
    );
  }

  updateAssessment() {
    this.assessmentService.updateSelfAssessment(this.assessment.id, this.form.value).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        if (!response.error_code) {
          this.updateOldAssessment.emit(response);
          this.routerService.resetDataConfirm();
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.form.controls.content.enable();
        this.errMsg = <any>error;
      },
    );
  }

  deleteAssessment() {
    this.confirmDialogService
      .showConfirm({
        title: '削除確認',
        content: 'この内容は削除され、見ることができなくなります。本当に削除しますか？',
        acceptButton: '削除する',
        cancelButton: 'キャンセル',
      })
      .subscribe((accept) => {
        if (!accept) {
          return;
        }
        this.isSubmitting = true;
        this.assessmentService.deleteSelfAssessment(this.assessment.id).subscribe(
          (response) => {
            this.isSubmitting = false;
            this.deleteOldAssessment.emit(response);
          },
          (error) => {
            this.isSubmitting = false;
            this.errMsg = <any>error;
          },
        );
      });
  }

  cancelEdit() {
    this.cancelEditAssessment.emit();
  }

  isContentInvalid() {
    const newContent = this.form.value.content.trim();
    return newContent === '' || (!!this.assessment && newContent === this.assessment.content);
  }

  isEditForm() {
    return !!this.assessment;
  }
}
