<div class="stu-spaces-list-page">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <button class="spen-mod-btn is-primary" (click)="slideMenu()"><i class="fa fa-reorder"></i></button>スペース
    </h1>
  </div>
  <div class="block-content">
    <a class="spen-mod-btn is-primary" (click)="sendNewDocumentGAEvent()" routerLink="/documents/new">活動記録作成</a>
    <div class="spaces-list">
      <ng-container *ngIf="dataLoaded; else loadingIcon">
        <ng-container *ngIf="spacesCount > 0; else emptyList">
          <div class="spaces">
            <a
              class="space-element"
              [routerLink]="['/spaces/student/', space.id]"
              *ngFor="
                let space of spaces
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
            >
              <div class="space" [style.background-color]="space.color_code">
                <div class="list-icons">
                  <img src="/assets/images/icon_space.svg" />
                </div>
                <a class="space-name">{{ space.name | trimText: 27 }}</a>
              </div>
            </a>
          </div>
          <div class="pagination" *ngIf="showPagination">
            <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
            </app-pagination-controls>
          </div>
        </ng-container>

        <ng-template #emptyList>
          <div class="empty-content">
            <p class="text">参加しているスペースが表示されます。</p>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #loadingIcon>
        <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
      </ng-template>
    </div>
  </div>
  <div class="block-footer">
    <a href="{{ classiUrl }}" class="spen-mod-btn is-default" *ngIf="!isMobileApp">ホームへ戻る</a>
  </div>
</div>
