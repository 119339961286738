import { Component, Input, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { UploadFile } from '@models/upload-file';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { parseJsonList } from '@functions/parse-json-list';
import { GalleryComponent } from '../../gallery/gallery.component';

@Component({
  selector: 'app-files-view-student',
  templateUrl: './files-view-student.component.html',
  styleUrls: ['./files-view-student.component.scss'],
})
export class FilesViewStudentComponent implements AfterViewChecked {
  apiEndPoint = environment.apiEndpoint;
  errorMsg: string;

  @ViewChild('root_div', { static: true }) root_div;
  @ViewChild('gallery', { static: true }) gallery: GalleryComponent;

  private _files: Array<UploadFile>;

  readonly FILES_PER_LINE = 4;

  @Input()
  set files(files: Array<UploadFile>) {
    this._files = parseJsonList(UploadFile, files);
  }

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
  ) {}

  ngAfterViewChecked() {
    this.resize();
  }

  public imageFiles(): Array<UploadFile> {
    if (!!this._files) {
      return this._files.filter((file) => file.isImage());
    } else {
      return [];
    }
  }

  public notImageFiles(): Array<UploadFile> {
    if (!!this._files) {
      return this._files.filter((file) => !file.isImage());
    } else {
      return [];
    }
  }

  public previewFiles(): Array<UploadFile> {
    if (!!this._files) {
      return this._files.filter((file) => file.canPreview());
    } else {
      return [];
    }
  }

  onFileClick(file: UploadFile) {
    switch (true) {
      case file.isPdf():
        window.open(file.previewLink(), '_blank');
        break;
      case file.canPreview():
        this.gallery.onPreview(file);
        break;
      default:
        window.open(file.downloadLink(), '_blank');
        break;
    }
  }

  showShadowBox(index: number): boolean {
    return this.imageFiles().length > this.FILES_PER_LINE && index === this.FILES_PER_LINE - 1;
  }

  resize() {
    if (!this.root_div) {
      return;
    }
    const imageDiv = this.root_div.nativeElement.querySelectorAll('.attach-image');
    for (const div of imageDiv) {
      this.renderer.setStyle(div, 'height', getComputedStyle(div).width);
    }
  }
}
