import { NgbDateStruct, NgbDatepickerI18n, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { Injectable } from '@angular/core';

// Note: Custom implement from here:
// https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/datepicker/ngb-date-parser-formatter.ts
@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {
  constructor(
    private i18n: NgbDatepickerI18n,
    private calendar: NgbCalendar,
  ) {
    super();
  }

  format(date: NgbDateStruct): string {
    if (date) {
      const convertedModel = NgbDate.from(date);
      const weekDay = this.calendar.getWeekday(convertedModel);
      const weekDayShortName = this.i18n.getWeekdayLabel(weekDay);

      return `${convertedModel.year}/${convertedModel.month}/${convertedModel.day}（${weekDayShortName}）`;
    } else {
      return '';
    }
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { year: this.toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return { year: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), day: null };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return { year: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), day: this.toInteger(dateParts[2]) };
      }
    }
    return null;
  }

  private toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  private isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }
}
