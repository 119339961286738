import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'img[appImagePreview], [appQuestionImagePreview]',
})
export class ImagePreviewDirective implements OnChanges, OnDestroy {
  @Input() image: any;
  url: string | null = null;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const el = this.el;

    if (this.image) {
      this.url = URL.createObjectURL(this.image);
      el.nativeElement.src = this.url;
    }
  }

  ngOnDestroy(): void {
    if (this.url) URL.revokeObjectURL(this.url);
  }
}
