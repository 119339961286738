import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Work } from './work';
import { environment } from '@environments/environment';
import { WorkTemplatesResponse } from './work-templates-response';
import { WorkTemplate } from '@models/work-template';
import { TeacherDeliveryWorksResponse } from './teacher/delivery-works-list/teacher-delivery-works-response';
import { TeacherDeliveryWorkDetailResponse } from './work-detail/teacher-work-detail/teacher-delivery-work-detail-response';
import { TeacherDeliveryActivityRecordsResponse } from './work-detail/teacher-work-detail/teacher-delivery-activity-records-response';
import { TeacherNotAnsweredStudentsResponse } from './work-detail/modal-list-student/teacher-not-answered-students-response';
import { SpacePickerResponse } from './create-work/space-picker/space-picker-response';
import { AlbumPickerResponse } from './create-work/album-picker/album-picker-response';

@Injectable()
export class WorkService {
  apiEndpoint = environment.apiEndpoint;
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  createWork(work: Work): Observable<Work> {
    const url = `${this.apiEndpointV2}/teacher/works`;
    return this.http.post<Work>(url, { work: work });
  }

  getStudentWorkDetail(workId: number, spaceId: number, isShowAnswer: boolean): Observable<Work> {
    const url = `${this.apiEndpoint}/student/spaces/${spaceId}/works/${workId}?is_show_answer=${isShowAnswer}`;
    return this.http.get<Work>(url);
  }

  createAnswer(space_id: number, work_id: number, document: Document): Observable<Document> {
    return this.http.post<Document>(`${this.apiEndpoint}/student/spaces/${space_id}/works/${work_id}/documents`, { document: document });
  }

  getWorkTemplates(params: {}): Observable<WorkTemplatesResponse> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/search`;
    return this.http.post<WorkTemplatesResponse>(url, params);
  }

  getTeacherDeliveryWorks(params = {}): Observable<TeacherDeliveryWorksResponse> {
    const url = `${this.apiEndpointV2}/teacher/works/search`;
    return this.http.post<TeacherDeliveryWorksResponse>(url, params);
  }

  updateWork(work: Work): Observable<Work> {
    const url = `${this.apiEndpointV2}/teacher/works/${work.id}`;
    return this.http.put<Work>(url, { work: work });
  }

  getEditWork(workId: number): Observable<Work> {
    const url = `${this.apiEndpointV2}/teacher/works/${workId}/edit`;
    return this.http.get<Work>(url);
  }

  loadWorkClone(workId: number): Observable<Work> {
    const url = `${this.apiEndpointV2}/teacher/works/${workId}/clone_info`;
    return this.http.get<Work>(url);
  }

  loadWorkTemplateStructure(workTemplateId: number): Observable<WorkTemplate> {
    const url = `${this.apiEndpointV2}/teacher/work_templates/${workTemplateId}/delivery_structure`;
    return this.http.get<WorkTemplate>(url);
  }

  getSpacesPickerList(params = {}): Observable<SpacePickerResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/selectable_spaces`;
    return this.http.post<SpacePickerResponse>(url, params);
  }

  getAlbumsPickerList(spaceId: number, params = {}): Observable<AlbumPickerResponse> {
    const url = `${this.apiEndpointV2}/teacher/spaces/${spaceId}/albums/selectable_albums`;
    return this.http.post<AlbumPickerResponse>(url, params);
  }

  getTeacherDeliveryWorkDetails(workId: number): Observable<TeacherDeliveryWorkDetailResponse> {
    const url = `${this.apiEndpointV2}/teacher/delivered_works/${workId}`;
    return this.http.get<TeacherDeliveryWorkDetailResponse>(url);
  }

  getTeacherDeliveryActivityRecords(spaceId: number, workId: number, params = {}): Observable<TeacherDeliveryActivityRecordsResponse> {
    const url = `${this.apiEndpointV2}/teacher/delivered_works/${workId}/spaces/${spaceId}/activity_records`;
    return this.http.get<TeacherDeliveryActivityRecordsResponse>(url, { params: params });
  }

  getNotAnswerStudents(spaceId, workId): Observable<TeacherNotAnsweredStudentsResponse> {
    const url = `${this.apiEndpointV2}/teacher/delivered_works/${workId}/spaces/${spaceId}/not_answered_students`;
    return this.http.get<TeacherNotAnsweredStudentsResponse>(url);
  }

  getMoreNotAnswerStudents(nextUrl: string): Observable<TeacherNotAnsweredStudentsResponse> {
    return this.http.get<TeacherNotAnsweredStudentsResponse>(nextUrl);
  }

  deleteTeacherWork(workId: number): any {
    const url = `${this.apiEndpointV2}/teacher/works/${workId}`;
    return this.http.delete<TeacherDeliveryWorkDetailResponse>(url);
  }

  getAnswerPreview(work_id: number): Observable<Work> {
    const url = `${this.apiEndpointV2}/teacher/works/${work_id}/answer_preview`;
    return this.http.get<Work>(url);
  }
}
