import { Component, Input } from '@angular/core';
import { ActivityRecordAlbum } from '../activity-record-album';
import { LayoutService } from '@components/layout/layout.service';

@Component({
  selector: 'app-activity-view-detail',
  templateUrl: './activity-view-detail.component.html',
  styleUrls: ['../album-detail-teacher.component.scss'],
})
export class ActivityViewDetailComponent {
  @Input() activityRecord: ActivityRecordAlbum;
  @Input() page: number;
  @Input() totalPage: number;

  constructor(public layoutService: LayoutService) {}

  isQuestionnaireRecord() {
    return this.activityRecord.record_type === 'Questionnaire';
  }

  isDocumentRecord() {
    return this.activityRecord.record_type === 'Document';
  }
}
