<div class="modal" (window:resize)="resizeModalContent()">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <span class="top-btn-close" (click)="closeModal()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
          <h2 class="heading-text">{{ isConfirming ? '公開設定確認' : '公開設定' }}</h2>
        </div>

        <div class="modal-content" #modalContent>
          <div class="spaces-input" *ngIf="!isConfirming">
            <input
              class="spen-mod-input-text"
              type="text"
              placeholder="キーワードを入力してください"
              #searchInput
              (keydown.enter)="onSearch()"
            />
            <p class="spaces-count">{{ totalCount }}件</p>
          </div>

          <div class="list-header">
            <h3>{{ isConfirming ? '公開先のスペース' : 'スペース一覧' }}</h3>
          </div>

          <div
            class="spaces-list"
            *ngIf="!isConfirming; else confirmContent"
            infinite-scroll
            infiniteScrollDistance="1"
            infiniteScrollThrottle="200"
            [infiniteScrollDisabled]="!nextPageParams"
            [scrollWindow]="false"
            (scrolled)="onLoadMore()"
            (touchmove)="onTouchmove($event)"
          >
            <label class="spen-mod-checkbox" [class.is-disabled]="!space.student_can_publish" *ngFor="let space of searchedResults">
              <input class="checkbox" type="checkbox" [checked]="calculateCheckState(space)" (change)="onCheck(space, $event)" />
              <i class="fa"></i>
              <div class="space-icon" [style.background-color]="space.color_code"></div>
              <p>
                {{ space.name }}
                <span *ngIf="!space.student_can_publish">（自主公開制限中）</span>
              </p>
            </label>
            <div class="spen-mod-flex-loading" *ngIf="isLoading">
              <div class="loading-icon"></div>
            </div>
          </div>

          <ng-template #confirmContent>
            <div class="spaces-list" *ngIf="selectingSpaces.length > 0; else emptyMessage">
              <div class="spen-mod-checkbox" *ngFor="let space of selectingSpaces">
                <div class="space-icon" [style.background-color]="space.color_code"></div>
                <p>{{ space.name }}</p>
              </div>
            </div>
            <ng-template #emptyMessage>
              <div class="empty-message">公開先なし</div>
            </ng-template>
          </ng-template>
        </div>

        <div class="modal-footer">
          <button class="spen-mod-btn is-primary" [disabled]="isSubmitting" (click)="onSubmit()">
            {{ isConfirming ? '設定を完了する' : '確認する' }}
          </button>
          <button class="spen-mod-btn" (click)="onClose()">{{ isConfirming ? '戻る' : 'キャンセル' }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
