<ng-container *ngIf="crossAssessmentsLoaded; else loadingIcon">
  <div class="box-comment" *ngIf="crossAssessments.length > 0; else emptyList">
    <ng-container *ngFor="let assessment of crossAssessments" [ngSwitch]="assessment !== edittingReviewAssessment">
      <app-cross-assessment-item
        *ngSwitchCase="true"
        [crossAssessment]="assessment"
        [canRequestReview]="canRequestReview(assessment)"
        (requestReviewAssessment)="onRequestReviewAssessment(assessment)"
      >
      </app-cross-assessment-item>
      <app-request-review-assessment
        *ngSwitchCase="false"
        [crossAssessment]="assessment"
        (cancelEditAssessment)="cancelEditAssessment()"
        (updateOldAssessment)="onUpdateOldAssessment($event, assessment)"
      >
      </app-request-review-assessment>
    </ng-container>
  </div>

  <div class="load-more" *ngIf="canLoadMore">
    <a class="spen-util-link" (click)="loadMoreCrossAssessments()">
      <span>もっと見る</span>
      <i [class]="isLoadingMore ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
    </a>
  </div>
</ng-container>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<ng-template #emptyList>
  <div class="empty-content">
    <p>ここに相互評価のコメントが表示されます。</p>
  </div>
</ng-template>
