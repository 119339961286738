<ng-container *ngIf="!!this.albumId; else createAlbum">
  <app-form-album-teacher-step3 *ngIf="isStep2; else step1"> </app-form-album-teacher-step3>
  <ng-template #step1>
    <app-form-album-teacher-step1></app-form-album-teacher-step1>
  </ng-template>
</ng-container>
<ng-template #createAlbum>
  <ng-container [ngSwitch]="creatorService.currentStep">
    <app-form-album-teacher-step2 *ngSwitchCase="2"> </app-form-album-teacher-step2>
    <app-form-album-teacher-step3 *ngSwitchCase="3"> </app-form-album-teacher-step3>
    <app-form-album-teacher-step1 *ngSwitchDefault> </app-form-album-teacher-step1>
  </ng-container>
</ng-template>
