<header class="spen-ly-global-header fixed-header" id="global-header" *ngIf="!isMobileApp">
  <app-student-header *ngIf="authUserService.isLoggedIn"></app-student-header>
  <app-global-menu></app-global-menu>
</header>

<main class="spen-main-layout" [class.has-header]="!isMobileApp">
  <app-student-sidebar *ngIf="authUserService.isLoggedIn"></app-student-sidebar>
  <div class="prod-ly-content">
    <app-content></app-content>
  </div>
</main>

<footer *ngIf="authUserService.isLoggedIn && !isMobileApp">
  <app-student-footer></app-student-footer>
</footer>
