import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { parseJsonItem } from '../functions/parse-json-list';

import { User } from '../models/user';
import { Observable } from 'rxjs';
import { GoogleTagManagerService } from '../../shared/services/google-tag-manager.service';
import { GoogleAnalytics4Service } from './google-analytics-4.service';

@Injectable()
export class AuthUserService {
  apiEndpoint = environment.apiEndpoint;
  private _current_user: User;
  isSystemAuthenticated = false;
  sessionKey: string;

  constructor(
    private http: HttpClient,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    private googleTagManagerService: GoogleTagManagerService,
  ) {}

  get isLoggedIn() {
    return !!this._current_user;
  }

  async isAuthenticated(): Promise<boolean> {
    if (this.isLoggedIn) {
      return true;
    }
    const userInfo = await this.getUserInfo();
    this.store(userInfo);
    return this.isLoggedIn;
  }

  async getUserInfo(): Promise<User | null> {
    const getUserInfoUrl = `${this.apiEndpoint}/user_info`;

    try {
      const response = await this.http.get(getUserInfoUrl).toPromise();
      return this.convertUserInfoResponseToUser(response);
    } catch (err) {
      return null;
    }
  }

  authenticateToSystem(): Observable<any> {
    const url = `${environment.apiEndpoint}/users/authenticate_by_result_system`;
    return this.http.get(url);
  }

  store(user: User | null) {
    if (user) {
      this._current_user = user;
      this.setupSentry(this._current_user);
      this.googleAnalytics4Service.initGA4(this._current_user);
      this.googleTagManagerService.initTagManager(this._current_user);
    }
  }

  retrieve(): User {
    return this._current_user;
  }

  isTeacher() {
    return this._current_user ? this._current_user.isTeacher() : false;
  }

  isStudent() {
    return this._current_user ? this._current_user.isStudent() : false;
  }

  changeToCamelCase(obj) {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const camelCase = key.replace(/_./g, function (g) {
        return g[1].toUpperCase();
      });
      result[camelCase] = obj[key];
    });
    return result;
  }

  private setupSentry(user: User) {
    if (Sentry) {
      const userTypeName = user.isTeacher() ? '先生' : user.isStudent() ? '生徒' : '保護者';

      Sentry.setUser({
        id: user.id + '',
      });
      Sentry.setTags({
        school_id: user.schoolId + '',
        user_type_id: user.userTypeId + '',
        user_type_name: userTypeName,
      });
    }
  }

  private convertUserInfoResponseToUser(response: Object): User {
    const userInfo = response['user_info'];
    userInfo['ga'] = Object.entries(response['ga']).reduce((acc, [key, value]) => {
      if (typeof value === 'number') {
        return { ...acc, [key]: value.toString() };
      }

      return { ...acc, [key]: value };
    }, {});
    userInfo['ga4'] = Object.entries(response['ga4']).reduce((acc, [key, value]) => {
      if (typeof value === 'number') {
        return { ...acc, [key]: value.toString() };
      }

      return { ...acc, [key]: value };
    }, {});
    const user = this.changeToCamelCase(userInfo);
    return parseJsonItem(User, user);
  }
}
