import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthUserService } from '@services/auth-user.service';
import { CategoriesService } from '@services/categories/categories.service';
import { RouterService } from '@services/router.service';
import { SpaceService } from '@components/space/space.service';
import { CrossAssessmentFormComponent } from '@components/assessment/assessment-form/cross-assessment/cross-assessment-form.component';

import { ActivityRecord } from '@components/activity-record/activity-record';
import { User } from '@models/user';
import { Assessment } from '@components/assessment/assessment';

@Component({
  selector: 'app-student-activity-detail',
  templateUrl: './student-activity-detail.component.html',
  styleUrls: ['./student-activity-detail.component.scss'],
})
export class StudentActivityDetailComponent implements OnInit {
  activityRecordLoaded = false;
  currentUser: User;
  activityRecord: ActivityRecord;
  errMsg: string;
  edittingAssessment: Assessment;
  spaceId: number;
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、コメント中の内容は破棄されます。破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  @ViewChild(CrossAssessmentFormComponent) assessmentForm: CrossAssessmentFormComponent;

  constructor(
    private categoryService: CategoriesService,
    private route: ActivatedRoute,
    private spaceService: SpaceService,
    private authUserService: AuthUserService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    this.spaceId = +this.route.snapshot.paramMap.get('spaceId');
    const activityRecordId = +this.route.snapshot.paramMap.get('activityId');
    this.currentUser = this.authUserService.retrieve();
    this.loadActivityRecordDetail(this.spaceId, activityRecordId);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.assessmentForm ? this.assessmentForm.canDeactivate() : true;
  }

  loadActivityRecordDetail(spaceId, activityRecordId) {
    this.spaceService.getPublicActivityRecord(spaceId, activityRecordId).subscribe(
      (response) => {
        this.activityRecord = response;
        this.activityRecordLoaded = true;
        this.loadCategoryName();
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadCategoryName() {
    this.categoryService
      .getCategoryName(this.activityRecord.category_id)
      .then((categoryName) => (this.activityRecord.category_name = categoryName));
  }

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  dataLoaded(): boolean {
    return this.activityRecordLoaded;
  }

  canEditAssessment(assessment: Assessment) {
    return !this.edittingAssessment && assessment.create_user_id === this.currentUser.id;
  }

  onEditAssessment(assessment) {
    this.edittingAssessment = assessment;
  }

  onSaveNewAssessment(assessment) {
    this.activityRecord.cross_assessments.unshift(assessment);
  }

  onCancelEditAssessment() {
    this.edittingAssessment = null;
  }

  onUpdateOldAssessment(newAssessment, oldAssessment) {
    this.edittingAssessment = null;
    Object.assign(oldAssessment, newAssessment);
  }

  onDeleteOldAssessment(assessment) {
    this.edittingAssessment = null;
    const removeIndex = this.activityRecord.cross_assessments.findIndex((item) => item === assessment);
    if (removeIndex === -1) {
      return;
    }
    this.activityRecord.cross_assessments.splice(removeIndex, 1);
  }
}
