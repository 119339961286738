import { ErrorHandler } from '@angular/core';
import { SentryHandler } from './sentry.handler';
import { environment } from '@environments/environment';
import { ErrorService } from '@services/error.service';

export class ErrorHandlerFactory {
  static create(errorService: ErrorService): ErrorHandler {
    if (environment.sentry) {
      return new SentryHandler(errorService);
    }
    return new ErrorHandler();
  }
}
