<ng-container *ngIf="formInitialized; else loadingIcon">
  <div class="step1">
    <div class="list-steps">
      <app-steps [steps]="formService.steps" [currentStep]="0"></app-steps>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
      <div class="template-name">
        <div class="label">
          <div class="label-text">活動記録テンプレート名</div>
          <div class="spen-mod-badge is-red">必須</div>
        </div>
        <div>
          <input type="text" class="spen-mod-input-text" [appPlaceholder]="'例）学期末の振り返り'" formControlName="title" />
          <div>
            <div *ngIf="formErrors['title']" class="spen-mod-error-content">{{ formErrors['title'].join(' ') }}</div>
          </div>
        </div>
      </div>

      <div formArrayName="work_template_questions_attributes">
        <ng-container *ngFor="let question of formQuestions; let i = index">
          <div class="question-template">
            <div class="question-header">
              <div class="question-title label-text">設問{{ i + 1 }}</div>
              <div class="question-buttons">
                <i class="fa fa-trash-o" [class.deactive]="formQuestions.length === 1" (click)="removeQuestion(i)"></i>
                <div class="pipe"></div>
                <i class="fa fa-caret-down" [class.deactive]="isDeactiveDownCaret(i)" (click)="downPositionQuestion(i)"></i>
                <div class="pipe"></div>
                <i class="fa fa-caret-up" [class.deactive]="isDeactiveUpCaret(i)" (click)="upPositionQuestion(i)"></i>
              </div>
            </div>
            <div class="question-content">
              <label class="label-text">設問文</label>
              <div class="question-input" [formGroupName]="i">
                <textarea
                  name="text"
                  class="spen-mod-input-text"
                  formControlName="content"
                  [appPlaceholder]="'例）学習面で頑張ったこと、成長したと思うことは何ですか？'"
                ></textarea>
                <div>
                  <div *ngIf="getFormErrorsQuestionIndex(i)['content']" class="spen-mod-error-content">
                    {{ getFormErrorsQuestionIndex(i)['content'].join(' ') }}
                  </div>
                </div>
                <label class="btn-select-image" for="image-upload-{{ i }}" *ngIf="!hasImageToBeSaved(question.controls)">
                  <span class="spen-util-link">画像を選択する</span>
                </label>
                <input
                  id="image-upload-{{ i }}"
                  class="input-file"
                  type="file"
                  (change)="onSelectFile($event, question)"
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                />
                <div class="image" *ngIf="question.controls.image_local?.value; else image_s3">
                  <img appQuestionImagePreview [image]="question.controls.image_local.value" />
                  <a class="action-file" (click)="removeImage(question)">
                    <i class="fa fa-trash-o"></i>
                  </a>
                </div>
                <ng-template #image_s3>
                  <div class="image" *ngIf="hasImageToBeUpdated(question.controls)">
                    <div>
                      <img src="{{ question.controls.image_attributes.value['thumbnail'] }}" />
                    </div>
                    <div>
                      <a class="action-file" (click)="removeImage(question)">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </div>
                  </div>
                </ng-template>

                <div>
                  <div *ngIf="question['image_error']" class="spen-mod-error-content image-error">{{ question['image_error'] }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="add-question-node">
        <span class="create-new" (click)="addQuestionNode()">
          <i class="fa fa-plus-circle"></i><span class="spen-util-link">設問を追加する</span>
        </span>
      </div>

      <div class="buttons-group" *ngIf="layoutService.isTabletDownView.value; else pcButtonsGroup">
        <button type="submit" class="spen-mod-btn is-primary">確認へ</button>
        <button type="button" class="spen-mod-btn is-default" (click)="goBack()">戻る</button>
        <div class="delete-template" *ngIf="formService.isEdit" (click)="deleteTemplate()">
          <i class="fa fa-trash-o"></i><span class="spen-util-link">この活動記録テンプレートを削除する</span>
        </div>
      </div>
      <ng-template #pcButtonsGroup>
        <div class="buttons-group" [class.disable-mouse]="isDeleting">
          <button type="button" class="spen-mod-btn is-default pull-left" (click)="goBack()">戻る</button>
          <div class="pull-right flex-center">
            <div class="delete-template" *ngIf="formService.isEdit" (click)="deleteTemplate()">
              <i class="fa fa-trash-o"></i><span class="spen-util-link">この活動記録テンプレートを削除する</span>
            </div>
            <button type="submit" class="spen-mod-btn is-primary">確認へ</button>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
</ng-container>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
