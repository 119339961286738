import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoryGalleryComponent } from './story-gallery.component';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { SharedModule } from '@components/shared.module';

@NgModule({
  declarations: [StoryGalleryComponent],
  exports: [StoryGalleryComponent],
  imports: [CommonModule, SharedPipesModule, SharedModule],
})
export class StoryGalleryModule {}
