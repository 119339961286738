import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

@Component({
  selector: 'app-work-filter-modal',
  templateUrl: './work-filter-modal.component.html',
  styleUrls: ['./work-filter-modal.component.scss'],
})
export class WorkFilterModalComponent {
  @Input() keyword: string;
  @Output() eventModalFilter = new EventEmitter<{}>();

  constructor(private googleAnalytics4Service: GoogleAnalytics4Service) {}

  submitFilter() {
    this.googleAnalytics4Service.sendEvent('課題', '検索画面', '検索する');
    this.eventModalFilter.emit({ type: 'submit', keyword: this.keyword });
  }

  cancelFitler() {
    this.eventModalFilter.emit({ type: 'cancel' });
  }
}
