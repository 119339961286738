<div class="tea-create-work">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="2"></app-steps>
    </div>
  </div>

  <div class="form-horizontal">
    <h2 class="group-label">配信する課題</h2>

    <div class="form-group result-group">
      <div class="row align-center">
        <div class="column-title">
          <label class="label">配信課題名</label>
        </div>
        <div class="column-content">
          <p data-cy="work-title">{{ creatorService.work.title }}</p>
        </div>
      </div>
    </div>

    <div class="form-group result-group">
      <div class="row">
        <div class="column-title">
          <label class="label">説明</label>
        </div>
        <div class="column-content">
          <ng-container *ngIf="hasCommentOrFile; else emptyCommentAndFile">
            <p class="preserve-line-break" data-cy="work-comment" *ngIf="creatorService.work.comment">
              {{ creatorService.work.comment }}
            </p>
            <div class="file-preview" *ngIf="hasAttachedFiles">
              <app-file-uploader
                [uploader]="creatorService.fileUploader"
                [attachedFiles]="creatorService.attachedFiles"
                [removedAttachFiles]="creatorService.removedAttachFiles"
                [canEdit]="false"
              >
              </app-file-uploader>
            </div>
          </ng-container>
          <ng-template #emptyCommentAndFile>
            <p>未入力</p>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="form-group result-group">
      <div class="row">
        <div class="column-title">
          <label class="label">活動記録名</label>
        </div>
        <div class="column-content">
          <p data-cy="work-answer-title">{{ creatorService.work.answer_title || '生徒が指定する' }}</p>
        </div>
      </div>
    </div>

    <div class="form-group result-group">
      <div class="row">
        <div class="column-title">
          <label class="label">活動記録テンプレート</label>
        </div>
        <div class="column-content">
          <p data-cy="work-template-title">{{ creatorService.work.group_question_title || '生徒が指定する' }}</p>
        </div>
      </div>
    </div>

    <div class="form-group result-group">
      <div class="row">
        <div class="column-title">
          <label class="label">活動記録カテゴリー</label>
        </div>
        <div class="column-content">
          <p data-cy="work-category">{{ categoryName || '生徒が指定する' }}</p>
        </div>
      </div>
    </div>

    <div class="preview-link">
      <a class="spen-util-link" data-cy="show-preview-work" (click)="toggleAnswerPreviewModal(true)">課題回答画面のプレビューを見る</a>
    </div>

    <h2 class="group-label">配信設定</h2>

    <div class="form-group">
      <div class="row">
        <div class="column-title space-title">
          <label class="label">配信先スペース</label>
        </div>
        <div class="column-content">
          <div class="space" *ngFor="let space of creatorService.spaces">
            <div
              class="block-color"
              data-cy="work-space-color"
              [style.background-color]="space.color_code"
              *ngIf="!layoutService.isTabletDownView.value"
            ></div>
            <p class="truncate-text" data-cy="work-space-name">{{ space.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row align-center">
        <div class="column-title">
          <label class="label">追加先アルバム</label>
        </div>
        <div class="column-content album">
          <ng-container *ngIf="!!creatorService.album; else noAlbum">
            <img
              class="album-image"
              [src]="creatorService.album.image?.thumbnail || '/assets/images/album.svg'"
              *ngIf="!layoutService.isTabletDownView.value"
            />
            <p class="truncate-text" data-cy="work-album-title">{{ creatorService.album.title }}</p>
          </ng-container>
          <ng-template #noAlbum>
            <p>なし</p>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="form-group result-group">
      <div class="row">
        <div class="column-title">
          <label class="label">配信日時</label>
        </div>
        <div class="column-content">
          <p data-cy="work-delivered-at">{{ deliveryText }}</p>
        </div>
      </div>
    </div>

    <div class="list-button-submit">
      <button type="button" (click)="goBack()" [disabled]="disableSubmitButton" class="spen-mod-btn">戻る</button>
      <button type="button" (click)="onSubmit()" [disabled]="disableSubmitButton" class="spen-mod-btn is-primary" data-cy="submit-button">
        配信完了
      </button>
    </div>
  </div>
</div>

<app-upload-files-progress
  *ngIf="creatorService.fileUploader.isUploading"
  [progress]="creatorService.fileUploader.progress"
  (cancelFilesEvent)="cancelUploadingFiles()"
>
</app-upload-files-progress>

<app-answer-preview-modal
  *ngIf="isShowAnswerPreviewModal"
  (eventCloseModal)="toggleAnswerPreviewModal(false)"
  [work]="creatorService.work"
  [workTemplate]="creatorService.workTemplate"
  [currentFiles]="currentFiles"
>
</app-answer-preview-modal>
