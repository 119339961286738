import { Component, OnInit, Input } from '@angular/core';

import { AssessmentService } from '../../assessment.service';
import { AuthUserService } from '@services/auth-user.service';

import { Assessment } from '@components/assessment/assessment';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { TeacherAssessmentsMeta } from './teacher-assessments.response';
import { User } from '@models/user';

@Component({
  selector: 'app-assessment-list-teacher',
  templateUrl: './assessment-list-teacher.component.html',
  styleUrls: ['./assessment-list-teacher.component.scss'],
})
export class AssessmentListTeacherComponent implements OnInit {
  @Input() activityRecord: ActivityRecord;

  assessments: Array<Assessment>;
  meta: TeacherAssessmentsMeta;
  edittingAssessment: Assessment;

  currentUser: User;
  assessmentsLoaded = false;
  isLoadingMore = false;

  constructor(
    private assessmentService: AssessmentService,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.currentUser = this.authUserService.retrieve();
    this.loadAssessments();
  }

  loadAssessments() {
    this.assessmentService.loadTeacherAssessments(this.activityRecord.id).subscribe(
      (response) => {
        this.assessments = response.teacher_assessments;
        this.meta = response.meta;
        this.assessmentsLoaded = true;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  loadMoreAssessments() {
    this.isLoadingMore = true;
    this.assessmentService.loadTeacherAssessments(this.activityRecord.id, this.meta).subscribe(
      (response) => {
        this.assessments.push(...response.teacher_assessments);
        this.meta = response.meta;
        this.isLoadingMore = false;
      },
      (error) => {
        // TODO: implement catching error
      },
    );
  }

  onsaveNewAssessment(assessment) {
    this.assessments.unshift(assessment);
  }

  onEditAssessment(assessment) {
    this.edittingAssessment = assessment;
  }

  onCancelEditAssessment() {
    this.edittingAssessment = null;
  }

  onUpdateOldAssessment(newAssessment, oldAssessment) {
    this.edittingAssessment = null;
    Object.assign(oldAssessment, newAssessment);
  }

  onDeleteOldAssessment(assessment) {
    this.edittingAssessment = null;
    const removeIndex = this.assessments.findIndex((item) => item === assessment);
    if (removeIndex === -1) {
      return;
    }
    this.assessments.splice(removeIndex, 1);
  }

  canCreateAssessment() {
    return !this.edittingAssessment && this.activityRecord.can_comment;
  }

  canEditAssessment(assessment: Assessment) {
    return !this.edittingAssessment && assessment.can_be_edited;
  }

  get canLoadMore(): boolean {
    return !!this.meta.next_id && !!this.meta.next_created_at;
  }
}
