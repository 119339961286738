import { Injectable } from '@angular/core';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { LayoutService } from '@components/layout/layout.service';
import { Space } from '@components/space/space';
import { FileUploader } from 'ng2-file-upload';

@Injectable()
export class GraduationMessageFormService {
  steps = ['卒業メッセージ作成', '配信設定', '確認', '完了'];
  previousStep: number;
  currentStep: number;

  graduationMessage: GraduationMessage;

  space: Space;

  fileUploader: FileUploader;
  attachedFiles = [];
  removedAttachFiles = [];

  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  constructor(public layoutService: LayoutService) {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.graduationMessage = new GraduationMessage();
    this.space = {
      id: null,
      name: '',
      users_count: 0,
      color_code: '',
      created_at: '',
      updated_at: '',
      create_user_id: '',
      update_user_id: '',
      teacher_can_pick_up: true,
      student_can_publish: true,
      student_can_comment_other: true,
    };
    this.fileUploader = new FileUploader({ url: '' });
    this.attachedFiles = [];
    this.removedAttachFiles = [];
  }

  isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }

  setStep3State() {
    this.currentStep = 3;
    this.previousStep = 2;
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }
}
