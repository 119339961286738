import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';

import { AuthUserService } from '@services/auth-user.service';
import { MySpaceService } from '../my-space.service';
import { isMobileApp } from '@functions/browser';

import { environment } from '@environments/environment';

import { Album } from '@models/album';
import { isNil, omit, pickBy } from 'lodash';

@Component({
  selector: 'app-albums-list',
  templateUrl: './albums-list.component.html',
  styleUrls: ['./albums-list.component.scss'],
})
export class AlbumsListComponent implements OnInit {
  albumsLoaded = false;
  albums: Album[];
  isShowingFilterModal = false;
  searchKeyword = '';
  private readonly disallowedUrlParamKeys = ['search_key'];
  readonly classiUrl = environment.platformUrl;

  errMsg: string;
  pages = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mySpaceService: MySpaceService,
    private authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    const pageIndex = +params['page'] || 1;
    this.searchKeyword = params['search_key'] || '';
    this.getAlbumsList({ page: pageIndex });
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  getAlbumsList(params: Object) {
    this.albumsLoaded = false;
    const queryParams = Object.assign({}, params, { student_id: this.authUserService.retrieve().id });
    if (this.searchKeyword !== '') {
      Object.assign(queryParams, { search_key: this.searchKeyword });
    }

    this.mySpaceService.getStudentAlbumsList(queryParams).subscribe(
      (response) => {
        this.albums = response.albums;
        Object.assign(this.pages, response.pages);
        this.albumsLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
        this.albums = [];
      },
    );
  }

  get dataLoaded(): boolean {
    return this.albumsLoaded;
  }

  get albumsCount() {
    return this.pages.total_count;
  }

  get showPagination(): boolean {
    return this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    this.navigate({ search_key: this.searchKeyword, page: page }, 'merge');
  }

  get isMobileApp() {
    return isMobileApp();
  }

  showFilterModal() {
    this.isShowingFilterModal = true;
  }

  onCancelFilter() {
    this.isShowingFilterModal = false;
  }

  removeAlbumFilter() {
    this.navigate({ tab: 'albums' });
  }

  onSubmitFilter(searchKeyword: string) {
    this.isShowingFilterModal = false;
    this.navigate({ tab: 'albums', search_key: searchKeyword });
  }
}
