import { Component, Input, Output, EventEmitter } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';

import { Assessment } from '@components/assessment/assessment';

@Component({
  selector: 'app-cross-assessment-item',
  templateUrl: '../cross-assessment-item/cross-assessment-item.component.html',
  styleUrls: ['../cross-assessment-item/cross-assessment-item.component.scss'],
})
export class CrossAssessmentItemComponent {
  @Input() crossAssessment: Assessment;
  @Input() canRequestReview: boolean;
  @Output() requestReviewAssessment = new EventEmitter<Assessment>();

  constructor(public layoutService: LayoutService) {}

  editReviewAssessment() {
    this.requestReviewAssessment.emit();
  }
}
