import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { parseJsonList } from '../../functions/parse-json-list';
import { Category } from '../../models/category';

@Injectable()
export class CategoriesService {
  readonly apiEndpoint = environment.apiEndpointV2;
  readonly categoriesSource = new Subject<Array<Category>>();
  readonly eventCategories: Observable<Array<Category>> = this.categoriesSource.asObservable();
  isRequesting = false;
  categories: Array<Category> = [];

  constructor(private http: HttpClient) {
    const categories = sessionStorage.getItem('categories');
    if (!this.categories.length && categories) {
      this.categories = JSON.parse(categories);
    }
  }

  async getCategories(): Promise<Array<Category>> {
    if (this.categories.length) {
      return this.categories;
    }
    if (this.isRequesting) {
      return firstValueFrom(this.eventCategories);
    }
    return this.doRequest();
  }

  async getCategory(id: number): Promise<Category> {
    const categories = await this.getCategories();
    return this.findCategory(id, categories);
  }

  async getCategoryNames(ids: Array<number>): Promise<Array<string>> {
    const categories = await this.getCategories();
    return categories.filter((c) => ids.indexOf(c.id) > -1).map((c) => c.name);
  }

  async getCategoryName(categoryId: number, type = 'default'): Promise<string> {
    try {
      const category = await this.getCategory(categoryId);
      if (type === 'filter') {
        return category.name;
      }
      if (!category.parent_id) {
        return category.name;
      }
      const parentCategory = await this.getCategory(category.parent_id);
      return type === 'googleAnalyticLabel' ? `${parentCategory.name}&${category.name}` : `${parentCategory.name} / ${category.name}`;
    } catch (err) {
      return '';
    }
  }

  async loadCategories() {
    try {
      const categories = await this.getCategories();
      return parseJsonList(Category, categories);
    } catch (error) {
      return null;
    }
  }

  private findCategory(id: number, categories: Array<Category>): Category {
    let result = new Category();
    if (!categories) {
      return result;
    }
    categories.forEach((category) => {
      if (category.id === id) {
        result = category;
        return;
      } else if (category.sub_categories) {
        category = category.sub_categories.find((subCategory) => subCategory.id === id);
        if (category) {
          result = category;
          return;
        }
      }
    });
    return result;
  }

  private async doRequest(): Promise<Array<Category>> {
    const url = `${this.apiEndpoint}/categories`;
    this.isRequesting = true;

    try {
      const categories = await lastValueFrom(this.http.get<{ categories: Category[] }>(url).pipe(map((data) => data.categories)));
      sessionStorage.setItem('categories', JSON.stringify(categories));
      this.categories = categories;
      this.categoriesSource.next(categories);
      return categories;
    } catch (err) {
      return [];
    } finally {
      this.isRequesting = false;
    }
  }
}
