<div class="row files-view" (window:resize)="resize()" #root_div>
  <div class="file-view" *ngFor="let file of this.files">
    <div class="attach" (click)="onFileClick(file)">
      <img *ngIf="file.isImage(); else notImageFile" src="{{ file.thumbnail }}" />
      <ng-template #notImageFile>
        <div class="file-format"><i [ngClass]="file.file_name | fileIconClass"></i></div>
        <a class="action-file" *ngIf="!file.canPreview() && !file.isPdf()">
          <i class="fa fa-download"></i>
        </a>
      </ng-template>
    </div>
    <p class="file-name" (click)="onFileClick(file)">{{ file.file_name }}</p>
    <p class="caption">{{ file.caption }}</p>
  </div>
  <app-story-gallery [items]="previewFiles()" #gallery></app-story-gallery>
</div>
