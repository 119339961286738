import { Component, Input, ViewChild, ViewChildren, Renderer2, OnInit, AfterViewChecked } from '@angular/core';
import { QuestionnaireFilePreviewComponent } from '../questionnaire-file-preview/questionnaire-file-preview.component';

import { Questionnaire } from '@models/questionnaire';
import { QuestionnaireAnswer } from './questionnaire-answer';
import { AuthUserService } from '../../../services/auth-user.service';

import { canPreview, isPdf } from '@functions/file-types';
import { getFileExtension } from '@functions/check-string';

@Component({
  selector: 'app-questionnaire-detail-teacher',
  templateUrl: './questionnaire-detail-teacher.component.html',
  styleUrls: ['./questionnaire-detail-teacher.component.scss'],
})
export class QuestionnaireDetailTeacherComponent implements OnInit, AfterViewChecked {
  @Input() questionnaire: Questionnaire;
  @Input() categoryName: string;
  @Input() isReadMore: boolean;

  @ViewChildren('upload_div') upload_divs;
  @ViewChild(QuestionnaireFilePreviewComponent, { static: true }) filePreview: QuestionnaireFilePreviewComponent;

  listAnswers: Array<Array<QuestionnaireAnswer>>;
  answerOption = [];

  constructor(
    public authUserService: AuthUserService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.listAnswers = this.questionnaire['answers'].map((answer) => this.getListAnswer(answer));
    this.listAnswers.forEach((answers) => {
      answers.forEach((answer) => {
        answer.answer_options = this.getListAnswerOption(answer);
      });
    });
  }

  ngAfterViewChecked() {
    this.resize();
  }

  getListAnswer(answer: any) {
    if (!answer) {
      return [];
    }
    return Array.isArray(answer) ? answer : [answer];
  }

  getListAnswerOption(answer: any) {
    if (answer.answer_options) {
      return answer.answer_options || [];
    } else if (answer.text || answer.src) {
      return [{ text: answer.text, src: answer.src }];
    }
    return [];
  }

  isShowThumbnail(file: string) {
    const PreviewType = ['jpg', 'jpeg', 'gif', 'png'];
    const fileExtension = getFileExtension(file);
    return PreviewType.includes(fileExtension);
  }

  resize() {
    if (!this.upload_divs) {
      return;
    }
    this.upload_divs.forEach((upload_div) => {
      const imageDiv = upload_div.nativeElement.querySelectorAll('.attach-file');
      for (const div of imageDiv) {
        this.renderer.setStyle(div, 'height', getComputedStyle(div).width);
      }
    });
  }

  onFileClick(answer) {
    if (canPreview(getFileExtension(answer.attached_file_name))) {
      this.filePreview.onPreview(answer.attached, answer.attached_file_name);
    } else {
      window.open(answer.attached + '/download');
    }
  }

  showDownloadIcon(answer) {
    const fileExtension = getFileExtension(answer.attached_file_name);
    return !canPreview(fileExtension) && !isPdf(fileExtension);
  }

  isTeacher() {
    return !!this.questionnaire.commented_user && this.questionnaire.commented_user.userTypeId === 1;
  }
}
