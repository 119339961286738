<div class="filter" [ngClass]="isPageLayout ? 'page-layout' : 'modal-layout'">
  <div class="spen-ly-modal">
    <div class="modal-dialog">
      <div class="inner">
        <ng-container [ngSwitch]="isPageLayout">
          <div class="modal-heading" *ngSwitchCase="false">
            <span class="top-btn-close" (click)="onClose()">
              <i class="fa fa-times"></i>
            </span>
            <h2 class="heading-text">活動記録参照</h2>
          </div>
          <div class="page-heading" *ngSwitchCase="true">
            <h1 class="heading-text">
              <a (click)="onClose()"><i class="fa fa-chevron-left"></i></a>活動記録詳細
            </h1>
          </div>
        </ng-container>
        <div class="modal-content">
          <div class="info">
            <p>作成日時: {{ manaVision.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
            <p *ngIf="manaVision.updated_at">最終更新: {{ manaVision.updated_at | date: 'yyyy/MM/dd HH:mm' }}</p>
            <p>［マナビジョン］</p>
          </div>
          <div class="block-standard">
            <h2 class="title">{{ manaVision.title }}</h2>
            <p class="preserve-line-break">{{ manaVision.content }}</p>
            <div class="row category-detail" *ngIf="categoryNames.length > 0">
              <div class="col-md-4 col-sm-4 col-xs-6">
                <div class="category-text" *ngFor="let category_name of categoryNames">
                  {{ category_name }}
                </div>
              </div>
            </div>
          </div>
          <div class="block-last">
            <button class="spen-mod-btn" (click)="onClose()">戻る</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
