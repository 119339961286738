<div class="tea-activity-detail" *ngIf="activityRecordLoaded; else loadingIcon">
  <div class="activity-detail">
    <p class="activity-title" data-cy="activity-title">{{ activityRecord.record.title }}</p>

    <div class="user-info">
      <img data-cy="student-avatar" [src]="activityRecord.student.avatar" alt="avatar" />
      <div class="student-name">
        <a [routerLink]="['/students/teacher', activityRecord.student.id]" class="spen-util-link" data-cy="student-name">
          {{ activityRecord.student.name }}
        </a>
        <p data-cy="student-class">{{ activityRecord.student.class_name }}{{ activityRecord.student.attendance_number }}番</p>
      </div>
    </div>

    <div class="additional-info">
      <div class="time-detail">
        <span data-cy="activity-created-at">作成日時：{{ activityRecord.created_at }}</span>
        <span data-cy="activity-updated-at" *ngIf="isUpdated">更新日時：{{ activityRecord.updated_at }}</span>
      </div>
      <p class="activity-type" data-cy="activity-type">種別：{{ activityType }}</p>
      <div class="work-name" *ngIf="activityRecord.is_answer">
        <span>配信課題名：</span>
        <a
          data-cy="work-title"
          *ngIf="workPath; else deletedWork"
          [routerLink]="workPath"
          [queryParams]="{ space_id: activityRecord.space_id }"
          class="spen-util-link"
          >{{ activityRecord.work.title }}</a
        >
        <ng-template #deletedWork>
          <span class="work_deleted">※課題は削除されました</span>
        </ng-template>
      </div>
    </div>

    <div class="record-detail">
      <app-document-detail-teacher
        *ngIf="isDocumentRecord()"
        [document]="activityRecord.record"
        [categoryName]="activityRecord.category_name"
        [marks]="activityRecord.marks"
        [isCompassAnswer]="activityRecord.is_compass_answer"
      >
      </app-document-detail-teacher>
      <app-questionnaire-detail-teacher
        *ngIf="isQuestionnaireRecord()"
        [categoryName]="activityRecord.category_name"
        [questionnaire]="activityRecord.record"
      >
      </app-questionnaire-detail-teacher>
    </div>
  </div>

  <div>
    <div class="block-info" *ngIf="displayedAlbums.length > 0">
      <p class="title">追加先アルバム：</p>
      <div class="links">
        <a
          *ngFor="let album of displayedAlbums"
          class="spen-util-link"
          data-cy="displayed-albums"
          [routerLink]="['/albums/teacher', album.id, 'students', activityRecord.student.id]"
        >
          {{ album.title }}
        </a>
        <a *ngIf="canShowMoreAlbums" class="spen-util-link" data-cy="more-albums" (click)="showMoreAlbums()">
          他{{ activityRecord.albums.length - displayedAlbums.length }}件<i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>

    <div class="block-info" *ngIf="displayedSpaces.length > 0">
      <p class="title">公開先スペース：</p>
      <div class="links">
        <a
          *ngFor="let space of displayedSpaces"
          class="spen-util-link"
          data-cy="displayed-spaces"
          [routerLink]="['/spaces/teacher', space.id]"
          >{{ space.name }}</a
        >
        <a *ngIf="canShowMoreSpaces" class="spen-util-link" data-cy="more-spaces" (click)="showMoreSpaces()">
          他{{ activityRecord.public_spaces.length - displayedSpaces.length }}件<i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isDocumentRecord()">
    <app-tab-view [tabOptions]="tabOptions"></app-tab-view>

    <ng-container [ngSwitch]="currentTab">
      <ng-container *ngSwitchCase="'cross_assessment'">
        <app-cross-assessment-list [activityRecord]="activityRecord"></app-cross-assessment-list>
      </ng-container>
      <ng-container *ngSwitchCase="'pickup'">
        <app-teacher-pickups-list [activityRecord]="activityRecord"></app-teacher-pickups-list>
      </ng-container>
      <ng-container *ngSwitchCase="'teacher_assessment'">
        <app-assessment-list-teacher [activityRecord]="activityRecord"></app-assessment-list-teacher>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="questionnaire-comment" *ngIf="activityRecord.record.commented_user">
    <h2>コメント</h2>
    <div class="comment">
      <div class="user-info">
        <img [src]="activityRecord.record.commented_user.avatar" />
        <div class="user-name">
          <p class="truncate-text">{{ activityRecord.record.commented_user.name }}</p>
          <p>先生</p>
        </div>
      </div>
      <div class="content-comment">
        <p class="time-comment">{{ activityRecord.record.commented_at }}</p>
        <app-read-more [content]="activityRecord.record.commented_text"></app-read-more>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
