<pagination-template
  #p="paginationApi"
  [id]="id"
  [maxSize]="maxSize"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageChange.emit($event)"
>
  <div class="section-contents" *ngIf="!(autoHide && p.pages.length <= 1)">
    <div class="spen-mod-paginate {{ stylePaginate }}">
      <span [class.is-disabled]="p.isFirstPage()" *ngIf="directionLinks">
        <a *ngIf="1 < p.getCurrent()" (click)="onPageClick('previous')">
          <i class="fa fa-chevron-left"></i>
        </a>
        <i *ngIf="p.isFirstPage()" class="fa fa-chevron-left"></i>
      </span>
      <span [class.current]="p.getCurrent() === page.value" *ngFor="let page of pages" [class.gap]="isGap(page)">
        {{ isGap(page) ? '···' : null }}
        <a *ngIf="p.getCurrent() !== page.value && !isGap(page)" [class.is-touch-device]="isTouchDevice" (click)="onPageClick(page.value)">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </span>
      <span [class.is-disabled]="p.isLastPage()" *ngIf="directionLinks" class="last">
        <a *ngIf="!p.isLastPage()" (click)="onPageClick('next')">
          <i class="fa fa-chevron-right"></i>
        </a>
        <i *ngIf="p.isLastPage()" class="fa fa-chevron-right"></i>
      </span>
    </div>
  </div>
</pagination-template>
