import { Component, OnInit } from '@angular/core';
import { Work } from '@components/work/work';
import { RouterService } from '@services/router.service';
import { MySpaceService } from '../my-space.service';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { isMobileApp } from '@functions/browser';
import { environment } from '@environments/environment';
import { isNil, omit, pickBy } from 'lodash';

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.scss'],
})
export class WorkListComponent implements OnInit {
  workLoaded = false;
  works: Work[];
  errMsg: string;
  pages = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
  };
  queryParams = {};
  showFilterModal = false;
  readonly classiUrl = environment.platformUrl;
  private readonly disallowedUrlParamKeys = ['keyword'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    private mySpaceService: MySpaceService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    this.initQueryParams(params);
    this.getWorksList(this.queryParams);
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  initQueryParams(params: Params) {
    this.queryParams = {};
    this.queryParams['page'] = +params['page'] || 1;
    if (params['keyword']) {
      this.queryParams['keyword'] = params['keyword'];
    }
    if (params['album_id']) {
      this.queryParams['album_id'] = params['album_id'];
    }
  }

  get isMobileApp() {
    return isMobileApp();
  }

  removeWorkFilter() {
    this.navigate({ page: 1 });
  }

  doFilterWork() {
    this.showFilterModal = true;
  }

  eventModalFilter(data: { [type: string]: string }) {
    this.showFilterModal = false;
    if (data['type'] === 'submit') {
      const params = { ...this.queryParams, page: 1, keyword: data['keyword'] };
      this.navigate(params, 'merge');
    }
  }

  answerState(work: Work): string {
    switch (work.answer_state) {
      case 'answered':
        return '回答済';
      case 'drafted':
        return '回答中';
      case 'not_answered':
        return '未回答';
    }
  }

  onClickWork(work: Work) {
    this.router.navigateByUrl(`spaces/student/${work.space.id}/works/${work.id}`);
  }

  getWorksList(params: {}) {
    this.mySpaceService.getStudentWorksList(params).subscribe(
      (response) => {
        this.works = response.works;
        Object.assign(this.pages, response.pages);
        this.workLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
        this.works = [];
      },
    );
  }

  get dataLoaded(): boolean {
    return this.workLoaded;
  }

  get worksCount() {
    return this.pages.total_count;
  }

  get showPagination(): boolean {
    return this.pages.total_pages > 1;
  }

  pageChanged(page: number) {
    const params = { ...this.queryParams, page: page };
    this.navigate(params, 'merge');
  }

  onBack() {
    this.routerService.goBack();
  }
}
