<div class="tea-edit-work" *ngIf="workLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="workEditorService.steps" [currentStep]="0"></app-steps>
    </div>
  </div>

  <form class="form" [formGroup]="form" [class.disable-clicked]="isDeleting">
    <div class="form-horizontal">
      <div class="form-group">
        <div class="row work-align-center">
          <div class="col-md-2 col-xs-12 form-element" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }">
            <label>配信日時</label>
          </div>
          <div class="col-md-10 col-xs-12" [ngClass]="{ 'col-sm-9': !isTabletAndDown() }">
            <div class="group-radio">
              <label class="spen-mod-radio" data-cy="select-delivered-time">
                <input
                  class="radio"
                  [checked]="!form.controls['delivered_at'].value"
                  type="radio"
                  name="radio1"
                  (change)="changeDeliveryType('immediate')"
                />
                <i class="fa"></i>
                <span class="text">すぐに配信する</span>
              </label>

              <label class="spen-mod-radio" data-cy="select-delivered-time">
                <input
                  class="radio"
                  [checked]="!!form.controls['delivered_at'].value"
                  type="radio"
                  name="radio1"
                  (change)="changeDeliveryType('specify_time')"
                />
                <i class="fa"></i>
                <span class="text">予約配信する</span>
              </label>
            </div>

            <app-datetime-selector
              formControlName="delivered_at"
              [isDisabled]="disabledDatetimePicker"
              [defaultValue]="defaultDeliveredValue()"
            >
            </app-datetime-selector>
          </div>
          <div class="col-md-2" [ngClass]="{ 'col-sm-3': !isTabletAndDown() }"></div>
          <div class="col-md-5 col-xs-12" [ngClass]="{ 'col-sm-5': !isTabletAndDown() }" *ngIf="formErrors['delivered_at']">
            <div class="spen-mod-error-content error-popup">
              {{ formErrors['delivered_at'].join(' ') }}
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <div (click)="deleteWork()" class="spen-util-link delete-work" data-cy="delete-delivered-work">
          <i class="fa fa-trash-o action-icon"></i>課題配信を取り消す
        </div>
        <div class="list-button-submit">
          <button type="button" (click)="onBack()" class="spen-mod-btn">戻る</button>
          <button type="button" (click)="onSubmit()" class="spen-mod-btn is-primary" data-cy="submit-button">確認へ</button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
