<div class="spen-ly-modal" id="modal">
  <div class="modal-dialog">
    <div class="space-inner">
      <div class="modal-heading">
        <a *ngIf="layoutService.isTabletDownView.value" (click)="onClose()"><i class="fa fa-times"></i></a>
        <h2 class="heading-text">配信先の選択</h2>
      </div>

      <div class="scrollable-modal-content">
        <div
          class="modal-content"
          infinite-scroll
          infiniteScrollDistance="1"
          infiniteScrollThrottle="200"
          [infiniteScrollDisabled]="!nextPageParams"
          [scrollWindow]="false"
          (scrolled)="onLoadMore()"
        >
          <div class="spaces-input">
            <input class="spen-mod-input-text" type="text" placeholder="キーワードを入力してください" #searchInput />
            <button class="spen-mod-btn" (click)="onSearch()">
              {{ layoutService.isTabletDownView.value ? '検索' : '検索する' }}
            </button>
          </div>
          <div class="error-popup">
            <div class="spen-mod-error-content" *ngIf="isShowError">配信先スペースを選択してください。</div>
          </div>

          <div class="list-header">
            <h3>スペースリスト</h3>
          </div>

          <div class="spaces-list" *ngIf="isLoading || searchedResults.length > 0">
            <div
              class="space-item"
              *ngFor="let space of searchedResults"
              (change)="selectSpace(space)"
              [class.has-hover-effect]="!layoutService.isTabletDownView.value"
              data-cy="space-item"
            >
              <label class="spen-mod-radio">
                <input class="radio" type="radio" [checked]="newSelectSpace?.id === space.id" />
                <i class="fa"></i>
                <div class="block-color" [style.background-color]="space.color_code"></div>
                <p class="truncate-text">{{ space.name }}</p>
              </label>
            </div>

            <div class="spen-mod-flex-loading" *ngIf="isLoading">
              <div class="loading-icon"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="modal-actions is-right-actions">
            <button class="spen-mod-btn" (click)="onClose()">キャンセル</button>
            <button class="spen-mod-btn is-primary" (click)="onSubmit()" data-cy="select-button">選択する</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
