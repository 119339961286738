<div class="student-detail" *ngIf="studentInfoLoaded; else loadingIcon">
  <div class="info">
    <div class="student-info">
      <img class="avatar-image" data-cy="student-avator" [src]="student.avatar" />
      <div class="text-info">
        <p class="name" data-cy="student-name">{{ student.name }}</p>
        <p class="class-name" data-cy="student-class">{{ student.class_name }}{{ student.attendance_number }}番</p>
      </div>
    </div>
    <span class="number-stories">
      作成したストーリー：<a class="spen-util-link" data-cy="created-stories" routerLink="/stories/teacher/students/{{ student.id }}"
        >{{ student.number_stories }}/{{ MAX_NUMBER_STORIES }}</a
      >
    </span>
    <div [class]="layoutService.isTabletDownView.value ? 'student-spaces-sp' : 'student-spaces'">
      <p *ngIf="displayedSpaces.length > 0">参加しているスペース：</p>
      <div class="spaces">
        <p *ngFor="let space of displayedSpaces">
          <a class="spen-util-link" data-cy="join-space" (click)="onClickSpace(space)">{{ space.name }}</a>
        </p>

        <p class="show-more-spaces" *ngIf="spaceNumberUndisplay > 0 && !isShowMoreSpaces">
          <a (click)="showMoreSpaces()" class="spen-util-link" data-cy="join-space-remain">
            他{{ spaceNumberUndisplay | number }}件<i class="fa fa-chevron-down"></i>
          </a>
        </p>
      </div>
    </div>
  </div>
  <app-tab-view [tabOptions]="tabOptions"></app-tab-view>
  <div class="list-box" *ngIf="studentInfoLoaded; else loadingIcon">
    <ng-container [ngSwitch]="currentTab">
      <app-student-timeline *ngSwitchCase="'timeline'" [studentId]="studentId"></app-student-timeline>
      <app-student-activity-records *ngSwitchCase="'activity_records'" [studentId]="studentId"></app-student-activity-records>
      <app-student-albums *ngSwitchCase="'albums'" [studentId]="studentId"></app-student-albums>
      <app-student-manavision-records *ngSwitchCase="'manavision'" [student]="student"></app-student-manavision-records>
      <app-student-unsubmitted-works *ngSwitchCase="'unsubmitted_works'" [studentId]="studentId"></app-student-unsubmitted-works>
    </ng-container>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
