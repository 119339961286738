import { User } from '@models/user';
import { Space } from '@components/space/space';

export class Pickup {
  id: number;
  activity_record_id: number;
  create_user_id: number;
  creator?: User;
  reason: string;
  is_anonymous: boolean;
  start_time: string;
  end_time: string;
  space_id: number;
  space?: Space;
  teacher_avatar?: string;
  teacher_name?: string;

  constructor(params?: Object) {
    if (params) {
      for (const attr in params) {
        if (params.hasOwnProperty(attr)) {
          this[attr] = params[attr];
        }
      }
    }
  }
}
