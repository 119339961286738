import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '@components/timeline/action';

@Component({
  selector: 'app-timeline-date-box',
  templateUrl: './timeline-date-box.component.html',
  styleUrls: ['./timeline-date-box.component.scss'],
})
export class TimelineDateBoxComponent {
  @Input() time: string;
  @Input() actions: Action[];
  @Input() nextActionDate: string;
  @Input() actionsLoading: boolean;

  @Output() loadMore = new EventEmitter<{}>();

  get isLoadMoreInnerBox(): boolean {
    return this.time === this.nextActionDate;
  }

  onLoadMore() {
    this.loadMore.emit();
  }
}
