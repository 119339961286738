<div class="comment">
  <div class="user-info">
    <img [src]="storyComment.create_user.avatar" />
    <div class="user-name">
      <p class="truncate-text">{{ storyComment.create_user.name }}</p>
      <p>先生</p>
    </div>
  </div>
  <div class="content-comment">
    <p class="time-comment">{{ storyComment.created_at }}</p>
    <app-read-more [content]="storyComment.content"></app-read-more>
    <span class="updated-text" *ngIf="storyComment.is_updated">（変更済み）</span>
  </div>
</div>
