import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlbumService } from '@components/album/album.service';
import { LayoutService } from '@components/layout/layout.service';
import { AlbumDetailTeacher } from './album-detail-teacher';
import { ActivityRecordAlbum } from './activity-record-album';
import { getFileExtension } from '@functions/check-string';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';

const ImageExtensions = ['jpg', 'jpeg', 'gif', 'png'];

@Component({
  selector: 'app-album-detail-teacher',
  templateUrl: './album-detail-teacher.component.html',
  styleUrls: ['./album-detail-teacher.component.scss'],
})
export class AlbumDetailTeacherComponent implements OnInit {
  @ViewChild('actThumbnail') actThumbnail: ElementRef;
  @ViewChild('actDetail') actDetail: ElementRef;

  stickyTop = false;
  stickyBottom = false;
  onScroll = false;
  album: AlbumDetailTeacher;
  lastestActivityRecord: ActivityRecordAlbum;
  activityRecords: Array<ActivityRecordAlbum> = [];
  metaData = {
    next_id: null,
    next_created_at: null,
  };
  albumId: number;
  studentId: number;
  cassetteTitle: string;
  srcThumbnailImage: string;
  messageError: string;
  albumLoaded = false;
  firstActivityRecordsLoaded = false;
  moreActivityRecordsLoading = false;
  formInit = false;
  headerOffsetHeight = this.layoutService.HEADER_OFFSET_HEIGHT;
  detailCurrentPage: string;
  isShowingModal = false;
  modalSize = 600;
  wordCloudServerError: 'happening' | 'not happening';
  gotWordCloudResponse: 'not yet' | 'have gotten' = 'not yet';
  gotWordCloudDocumentId: 'success' | 'failed';

  constructor(
    private activatedRoute: ActivatedRoute,
    private albumService: AlbumService,
    public layoutService: LayoutService,
    private cdRef: ChangeDetectorRef,
    private wordCloudService: WordCloudService,
    private analytic4Service: GoogleAnalytics4Service,
  ) {}

  ngOnInit() {
    this.albumId = +this.activatedRoute.snapshot.params['id'];
    this.studentId = +this.activatedRoute.snapshot.params['student_id'];
    this.getAlbumInfo();
    this.albumService.getTeacherAlbumActivityRecords(this.studentId, this.albumId).subscribe(
      (response) => {
        this.activityRecords = response.activity_records;
        Object.assign(this.metaData, response.meta);
        this.firstActivityRecordsLoaded = true;
      },
      (error) => {
        this.messageError = <any>error;
      },
    );
    this.formInit = true;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    if (this.layoutService.isTabletDownView.value || !this.formInit || !this.detailCurrentPage || !this.lastestActivityRecord) {
      return;
    }

    const actDetailOffsetTop = this.actDetail.nativeElement.offsetTop;
    const actDetailOffsetHeight = this.actDetail.nativeElement.offsetHeight;
    const actDetails = document.getElementsByClassName('act-detail') as HTMLCollectionOf<HTMLElement>;
    let currentPage;

    for (let i = 0; i < actDetails.length; i++) {
      const actDetail = actDetails[i];

      if (window.pageYOffset + window.innerHeight / 2 <= actDetail.offsetTop + this.headerOffsetHeight / 2) {
        break;
      }
      currentPage = actDetail.getAttribute('id');
    }

    if (currentPage && currentPage !== this.detailCurrentPage) {
      document.querySelector(`[data-id='${this.detailCurrentPage}']`).classList.remove('current-activity-record');
      this.detailCurrentPage = currentPage;
      const thumbnail = document.querySelector(`[data-id='${this.detailCurrentPage}']`);
      thumbnail.classList.add('current-activity-record');
      if (this.stickyTop) {
        thumbnail.scrollIntoView({ block: 'nearest' });
      }
    }

    this.onScroll = true;
    if (window.pageYOffset >= actDetailOffsetTop) {
      if (window.pageYOffset + window.innerHeight - this.headerOffsetHeight >= actDetailOffsetHeight + actDetailOffsetTop) {
        this.stickyTop = false;
        this.stickyBottom = true;
      } else {
        this.stickyTop = true;
        this.stickyBottom = false;
      }
    } else {
      this.stickyTop = false;
      this.stickyBottom = false;
      this.onScroll = false;
    }
  }

  goToDetail(e) {
    const activityRecord = document.getElementById(e.currentTarget.dataset.id);
    // If height of activity record less more than height of screen display (without header)
    // Then, top of activity record will scroll to center of screen
    // Opposite, top of activity record will scroll to top of screen

    if (activityRecord.offsetHeight <= window.innerHeight / 2 - this.headerOffsetHeight) {
      // Scroll to center of screen
      window.scrollTo(0, activityRecord.offsetTop - this.headerOffsetHeight / 2 - window.innerHeight / 4);
    } else {
      // Scroll to top of screen
      window.scrollTo(0, activityRecord.offsetTop - this.headerOffsetHeight / 2);
    }
  }

  getThumbnailAnswerImage(activityRecordAlbum: ActivityRecordAlbum) {
    this.srcThumbnailImage = null;
    const activity = activityRecordAlbum.record;

    if (activityRecordAlbum.record_type === 'Document') {
      if (activity.upload_files && activity.upload_files.length) {
        const activityFileUpload = activity.upload_files.find((file) => ImageExtensions.includes(file.file_extension));

        if (activityFileUpload) {
          this.srcThumbnailImage = activityFileUpload.thumbnail;
        }
      } else if (activity.document_answers && activity.document_answers.length) {
        const documentFileUpload = activity.document_answers[0].upload_files.find((file) =>
          ImageExtensions.includes(file['file_extension']),
        );

        if (documentFileUpload) {
          this.srcThumbnailImage = documentFileUpload['thumbnail'];
        }
      }
    } else {
      const activityAnswer = activity.answers[0];

      if (activityAnswer.attached_file_name) {
        const fileExtension = getFileExtension(activityAnswer.attached_file_name);

        if (ImageExtensions.includes(fileExtension)) {
          this.srcThumbnailImage = activityAnswer.attached;
        }
      } else {
        this.srcThumbnailImage = activityAnswer.answer_options?.length ? activityAnswer.answer_options[0].src : activityAnswer.src;
      }
    }
    return this.srcThumbnailImage;
  }

  getThumbnailText(activityRecordAlbum: ActivityRecordAlbum) {
    const activity = activityRecordAlbum.record;
    let thumbnailContent = '';

    if (activityRecordAlbum.record_type === 'Document') {
      if (!activity.content) {
        activity.document_answers.forEach((answer, index) => {
          thumbnailContent += `設問${index + 1} ${answer.work_question.content}\n${answer.content}\n`;
        });
      } else {
        thumbnailContent = activity.content;
      }
    } else {
      activity.questions.forEach((question, index) => {
        const answer = activity.answers[index];

        if (answer.text || answer.text === '') {
          thumbnailContent += `設問${index + 1} ${question.text}\n${answer.text}\n`;
        } else if (answer.answer_options === undefined) {
          thumbnailContent += `設問${index + 1} ${question.text}\n\n`;
        } else {
          let answersOption = '';

          answer.answer_options.forEach((option) => {
            answersOption += `${option.text}\n`;
          });
          thumbnailContent += `設問${index + 1} ${question.text}\n${answersOption}\n`;
        }
      });
    }
    return thumbnailContent;
  }

  setCassetteTitle(previousActivity: ActivityRecordAlbum, currentActivity: ActivityRecordAlbum) {
    if (previousActivity) {
      if (previousActivity.created_at.slice(0, 7) !== currentActivity.created_at.slice(0, 7)) {
        return (this.cassetteTitle = this.getTimestamp(currentActivity.created_at));
      }
    } else {
      return (this.cassetteTitle = this.getTimestamp(currentActivity.created_at));
    }
  }

  getTimestamp(time: string) {
    const createdAt = new Date(time);
    return createdAt.getFullYear() + '年' + (createdAt.getMonth() + 1) + '月';
  }

  getAlbumInfo() {
    this.albumService.getAlbumDetailTeacher(this.studentId, this.albumId).subscribe(
      (response) => {
        this.album = response;
        this.albumLoaded = true;
        this.lastestActivityRecord = this.album.latest_activity_record;
        if (this.lastestActivityRecord) {
          this.detailCurrentPage = 'item-1';
        } else if (this.album.goal || this.album.outline) {
          this.detailCurrentPage = 'item-2';
        }
      },
      (error) => {
        this.messageError = <any>error;
      },
    );
  }

  getAlbumActivityRecords(params = {}) {
    if (!this.metaData.next_id || this.moreActivityRecordsLoading) {
      return;
    }
    this.moreActivityRecordsLoading = true;

    this.albumService.getTeacherAlbumActivityRecords(this.studentId, this.albumId, params).subscribe(
      (response) => {
        this.activityRecords = this.activityRecords.concat(response.activity_records);
        Object.assign(this.metaData, response.meta);
        this.moreActivityRecordsLoading = false;

        // Detect view changed and set css styles because more items are pushed to list.
        this.cdRef.detectChanges();
        const actDetailOffsetTop = this.actDetail.nativeElement.offsetTop;
        const actDetailOffsetHeight = this.actDetail.nativeElement.offsetHeight;
        if (window.pageYOffset + window.innerHeight - this.headerOffsetHeight < actDetailOffsetHeight + actDetailOffsetTop) {
          this.stickyTop = true;
          this.stickyBottom = false;
        }
      },
      (error) => {
        this.messageError = <any>error;
      },
    );
  }

  get teacherCreator(): boolean {
    return this.album.creator_type === 'teacher';
  }

  get albumIconUrl() {
    return this.album.thumbnail || 'assets/images/icons/album.svg';
  }

  get checkEmptyAlbum(): boolean {
    return !this.album.goal && !this.album.outline && !this.lastestActivityRecord;
  }

  get possibleShowModal(): boolean {
    return window.innerWidth >= this.modalSize && window.innerHeight >= this.modalSize;
  }

  switchToShowWordCloud() {
    this.isShowingModal = true;
    this.analytic4Service.sendEvent('アルバム', 'アルバム詳細画面', '概況を見る');
    if (this.gotWordCloudResponse === 'not yet') {
      this.wordCloudService.makeTitleAboutAlbum(this.album.student.name, this.album.title);
      this.sendAlbumParamsToWordCloud();
    }
  }

  onCloseWordCloud() {
    this.isShowingModal = false;
    if (this.wordCloudServerError === 'happening') {
      this.gotWordCloudResponse = 'not yet';
      this.wordCloudServerError = 'not happening';
    }
  }

  sendAlbumParamsToWordCloud() {
    this.wordCloudService.getWordCloudDocumentId(this.buildWordCloudParams()).subscribe(
      (response) => {
        const document_id = response['document_id'] as string;
        switch (document_id) {
          case null:
            this.wordCloudService.setFetchErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          case 'over_count':
            this.wordCloudService.setOverCountErrorMessage();
            this.gotWordCloudDocumentId = 'failed';
            break;
          default:
            this.wordCloudService.registerWordCloudUrl(document_id);
            this.gotWordCloudDocumentId = 'success';
        }
        this.gotWordCloudResponse = 'have gotten';
      },
      (error) => {
        this.wordCloudService.setServerErrorMessage();
        this.wordCloudServerError = 'happening';
        this.gotWordCloudResponse = 'have gotten';
        this.gotWordCloudDocumentId = 'failed';
      },
    );
  }

  private buildWordCloudParams() {
    return {
      type: 'album',
      studentId: this.studentId,
      albumId: this.albumId,
      title: this.album.title,
    };
  }
}
