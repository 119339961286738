import { Component, Input } from '@angular/core';
import { RouterDataService } from '@services/router-data.service';
import { RouterService } from '@services/router.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-student-heading-bar',
  templateUrl: './student-heading-bar.component.html',
  styleUrls: ['./student-heading-bar.component.scss'],
})
export class StudentHeadingBarComponent {
  @Input() dataConfirm: Subject<string>;

  constructor(
    public routerDataService: RouterDataService,
    private routerService: RouterService,
  ) {}

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }
}
