<div class="space-timeline" *ngIf="contentLoaded; else loadingIcon">
  <ng-container *ngIf="activitiesDates.length > 0; else emptyMessage">
    <app-timeline-date-box
      *ngFor="let date of activitiesDates; let last = last"
      [time]="date"
      [actions]="activitiesMap[date]"
      [nextActionDate]="last ? meta.next_action_date : null"
      [actionsLoading]="actionsLoading"
      (loadMore)="loadMore()"
    >
    </app-timeline-date-box>
  </ng-container>

  <ng-template #emptyMessage>
    <p class="empty-message muted-text">ここに生徒のアクティビティが表示されます。</p>
  </ng-template>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>
