<div [formGroup]="parentForm">
  <label class="spen-mod-select">
    <select
      class="select"
      data-cy="school-year"
      id="school-year-select"
      formControlName="school_year"
      (change)="onChangeSchoolOption($event.target.value)"
    >
      <ng-container *ngIf="optionsLoaded">
        <option *ngFor="let year of schoolYears" [value]="year">{{ year }}年度</option>
      </ng-container>
    </select>
    <i class="fa fa-caret-down"></i>
  </label>
  <label class="spen-mod-select">
    <select
      class="select"
      data-cy="grade-id"
      id="grade-select"
      formControlName="grade_id"
      (change)="onChangeGradeOption($event.target.value)"
    >
      <ng-container *ngIf="optionsLoaded">
        <option *ngFor="let grade of grades" [value]="grade.id">{{ grade.school_stage_name }}{{ grade.name }}</option>
      </ng-container>
    </select>
    <i class="fa fa-caret-down"></i>
  </label>
  <label class="spen-mod-select">
    <select
      class="select"
      data-cy="class-id"
      id="class-select"
      formControlName="class_id"
      (change)="onChangeClassIdOption($event.target.value)"
    >
      <ng-container *ngIf="!classesFilterService.isLoading">
        <option [ngValue]="null">すべての組</option>
        <option *ngFor="let class of classes" [value]="class.id">
          {{ class.classroom_name }}
        </option>
      </ng-container>
    </select>
    <i class="fa fa-caret-down"></i>
  </label>
</div>
