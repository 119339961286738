<form class="comment" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-content">
    <img data-cy="comment-teacher-avatar" [src]="currentUser.avatar" alt="Avatar" />
    <div class="time-comment">
      <div *ngIf="isShowCommentInfo" class="teacher-name">
        <p class="truncate-text" data-cy="comment-teacher-name">{{ currentUser.name }}</p>
        <p>先生</p>
      </div>
      <p *ngIf="isShowCommentInfo" class="assessment-time" data-cy="comment-created-at">{{ assessment.created_at }}</p>
      <div class="text-comment">
        <textarea
          class="spen-mod-input-text"
          data-cy="comment-content"
          #textArea
          [appPlaceholder]="'コメントを入力'"
          formControlName="content"
          appAutoExpand
        ></textarea>
        <div *ngIf="formErrors['content']" class="spen-mod-error-content">
          {{ formErrors['content'].join(' ') }}
        </div>
      </div>
    </div>
  </div>

  <div class="list-button">
    <button type="submit" class="spen-mod-btn is-primary" data-cy="comment-submit-button" [disabled]="isSubmitting || isContentInvalid()">
      {{ isEditForm() ? '保存する' : 'コメントする' }}
    </button>
    <button
      type="button"
      class="spen-mod-btn"
      data-cy="comment-cancel-button"
      [disabled]="isSubmitting"
      *ngIf="isEditForm()"
      (click)="cancelEdit()"
    >
      キャンセル
    </button>
    <span>
      <a class="delete-comment spen-util-link" data-cy="comment-delete-button" *ngIf="isEditForm()" (click)="deleteAssessment()">
        <i class="fa fa-trash-o"></i>削除する
      </a>
    </span>
  </div>
</form>
