import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { Album } from '@models/album';
import { Category } from '@models/category';
import { Space } from '@components/space/space';
import { Work } from '../work';
import { WorkTemplate } from '@models/work-template';

@Injectable()
export class WorkCreatorService {
  steps = ['課題作成', '配信設定', '確認', '完了'];
  previousStep: number;
  currentStep: number;

  work: Work;

  album: Album;
  spaces: Array<Space>;
  workTemplate: WorkTemplate;
  categories: Array<Category>;

  fileUploader: FileUploader;
  attachedFiles = [];
  removedAttachFiles = [];

  isCloneWork = false;
  reservedAnswerTitle = false;
  reservedWorkTemplate = false;
  reservedCategory = false;
  reservedDeliveryTime = false;

  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  constructor() {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
    this.album = null;
    this.spaces = [];
    this.categories = [];
    this.work = new Work();
    this.fileUploader = new FileUploader({ url: '' });
    this.attachedFiles = [];
    this.removedAttachFiles = [];
  }

  setupFromWorkClone(cloneWork: Work) {
    this.work = cloneWork;
    this.isCloneWork = true;
    this.reservedAnswerTitle = !!cloneWork.answer_title;
    this.reservedWorkTemplate = !!cloneWork.group_question_title;
    this.reservedCategory = !!cloneWork.category_id;
    this.attachedFiles = cloneWork['upload_files_attributes'];
  }

  setupFromWorkTemplate(workTemplate: WorkTemplate) {
    this.workTemplate = workTemplate;
    this.reservedWorkTemplate = true;
  }

  setStep3State() {
    this.currentStep = 3;
    this.previousStep = 2;
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }
}
