import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paginateText', pure: false })
export class PaginateResultPipe implements PipeTransform {
  transform(value: Object): string {
    const currentPage = value['current_page'];
    const nextPage = value['next_page'];
    const perPage = value['per_page'] || value['limit_value'];
    const totalCount = value['total_count'];
    if (totalCount === 0) {
      return '';
    }

    if (totalCount === 1) {
      return `全1件中1件を表示`;
    }

    const startIndex = (currentPage - 1) * perPage + 1;
    const endIndex = nextPage ? currentPage * perPage : totalCount;
    return `全${totalCount.toLocaleString()}件中${startIndex}〜${endIndex}件を表示`;
  }
}
