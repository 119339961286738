import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@models/user';
import { StudentService } from '../student.service';
import { LayoutService } from '@components/layout/layout.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss'],
})
export class StudentDetailComponent implements OnInit {
  readonly MAX_NUMBER_STORIES = environment.maxNumberStories;

  tabOptions = {
    timeline: 'アクティビティ',
    activity_records: '活動記録',
    albums: 'アルバム',
    unsubmitted_works: '未提出課題',
    manavision: 'マナビジョン',
  };
  currentTab = 'timeline';
  studentInfoLoaded = false;

  studentId: number;
  student: User;
  displayedSpaces: Array<object>;

  isShowMoreSpaces = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((obj) => {
      this.studentId = +this.route.snapshot.params.id;
      this.getStudentInfo();
    });

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (tab && Object.keys(this.tabOptions).includes(tab)) {
        this.currentTab = tab;
        this.sendChangeTabGA();
      } else {
        this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
      }
    });
  }

  sendChangeTabGA() {
    const actions = {
      timeline: '生徒詳細アクティビティ確認',
      activity_records: '生徒詳細活動記録確認',
      albums: '生徒詳細アルバム確認',
      unsubmitted_works: '生徒詳細未提出課題確認',
      manavision: '生徒詳細マナビジョンの記録確認',
    };
  }

  getStudentInfo() {
    this.studentInfoLoaded = false;
    this.studentService.getStudentInfo(this.studentId).subscribe((student) => {
      this.student = student;
      this.displayedSpaces = student.spaces.slice(0, 2);
      this.studentInfoLoaded = true;
      this.isShowMoreSpaces = false;
    });
  }

  get spaceNumberUndisplay() {
    return this.student.spaces.length - 2;
  }

  showMoreSpaces() {
    this.displayedSpaces = this.student.spaces;
    this.isShowMoreSpaces = true;
  }

  onClickSpace(space) {
    this.router.navigateByUrl(`/spaces/teacher/${space.id}`);
  }
}
