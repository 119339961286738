<div class="spen-ly-modal confirm-dialog" tabindex="-1" role="dialog" *ngIf="isShow">
  <div class="modal-dialog">
    <div class="detail-inner">
      <div class="modal-heading">
        <p class="heading-text">{{ data['title'] }}</p>
      </div>
      <div class="modal-content">
        <div class="content-inner space-modal-content">
          <p [innerHTML]="data['content']"></p>
        </div>
      </div>
      <div class="modal-footer detail-modal-footer">
        <div class="modal-actions is-right-actions">
          <a class="spen-mod-btn is-default" (click)="submitModal(false)">
            {{ data['cancelButton'] }}
          </a>
          <a class="spen-mod-btn {{ acceptButtonClass }}" (click)="submitModal(true)" data-dismiss="spen-ly-modal">
            {{ data['acceptButton'] }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
