<div class="tea-create-work">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="1"></app-steps>
    </div>
  </div>

  <form class="form" [formGroup]="form" *ngIf="formInited; else loadingIcon" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-horizontal">
      <div class="form-group space-group">
        <div class="row">
          <div class="column-title"><label class="label">配信先スペース</label><span class="spen-mod-badge is-red">必須</span></div>
          <div class="column-content">
            <div class="list-space" *ngIf="creatorService.spaces.length > 0">
              <div class="selecting-space" *ngFor="let space of creatorService.spaces">
                <div class="space-color" data-cy="work-space-color" [style.background-color]="space.color_code"></div>
                <p class="space-name" data-cy="work-space-name">{{ space.name }}</p>
                <a class="spen-util-link" (click)="removeSpace(space)">削除する</a>
              </div>
            </div>
            <label
              class="space-select-box"
              [class.remove-border-top]="creatorService.spaces.length > 0"
              data-cy="show-work-space"
              (click)="toggleSpacesModal(true)"
            >
              <i class="fa fa-plus-circle"></i>
              <span class="spen-util-link">{{ creatorService.spaces.length === 0 ? '選択する' : '追加する' }}</span>
            </label>

            <input type="hidden" formControlName="works_spaces_attributes" />
            <div class="error-popup" *ngIf="formErrors['works_spaces_attributes']">
              <div class="spen-mod-error-content">
                {{ formErrors['works_spaces_attributes'].join(' ') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group album-group" [class.empty-album-group]="cantSelectAlbum">
        <div class="row">
          <div class="column-title">
            <label class="label">追加先アルバム</label>
          </div>
          <div class="column-content">
            <p class="album-block-message" *ngIf="cantSelectAlbum; else albumDetail">
              {{
                creatorService.spaces.length !== 1
                  ? '配信先スペースが1つの場合選択できます。'
                  : '選択したスペースに配信されたアルバムがありません。'
              }}
            </p>
            <ng-template #albumDetail>
              <ng-container *ngIf="!creatorService.album; else selectedAlbum">
                <p class="album-unselected-label">未選択</p>
                <a class="spen-util-link" data-cy="show-album-list" (click)="toggleAlbumsModal(true)">アルバムを選択する</a>
              </ng-container>
              <ng-template #selectedAlbum>
                <div class="album-detail">
                  <img [src]="creatorService.album.image?.thumbnail || '/assets/images/album.svg'" />
                  <p data-cy="work-album-title">{{ creatorService.album.title }}</p>
                </div>
                <a class="spen-util-link" (click)="removeAlbum()">削除する</a>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="form-group delivered-at-group">
        <div class="row">
          <div class="column-title">
            <label class="label">配信日時</label>
          </div>
          <div class="column-content group-radio">
            <label class="spen-mod-radio">
              <input
                class="radio"
                [checked]="!creatorService.reservedDeliveryTime"
                type="radio"
                (change)="changeReservedDeliveryTime(false)"
              />
              <i class="fa"></i><span class="text">すぐに配信する</span>
            </label>
            <label class="spen-mod-radio">
              <input
                class="radio"
                [checked]="creatorService.reservedDeliveryTime"
                type="radio"
                (change)="changeReservedDeliveryTime(true)"
              />
              <i class="fa"></i><span class="text">予約配信する</span>
            </label>

            <div class="reserved-time">
              <app-datetime-selector
                formControlName="delivered_at"
                [isDisabled]="!creatorService.reservedDeliveryTime"
                [defaultValue]="defaultDeliveredValue()"
              >
              </app-datetime-selector>
            </div>
            <div class="error-popup" *ngIf="formErrors['delivered_at']">
              <div class="spen-mod-error-content">
                {{ formErrors['delivered_at'].join(' ') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-button-submit">
        <button type="button" (click)="goBack()" class="spen-mod-btn">戻る</button>
        <button type="submit" class="spen-mod-btn is-primary" data-cy="submit-button">確認へ</button>
      </div>
    </div>
  </form>
</div>

<app-space-picker
  *ngIf="isShowSpacePickerModal"
  (closeEvent)="toggleSpacesModal(false)"
  (submitEvent)="onSelectSpace($event)"
  [selectedSpaces]="creatorService.spaces"
>
</app-space-picker>

<app-album-picker
  *ngIf="isShowAlbumPickerModal"
  (closeEvent)="toggleAlbumsModal(false)"
  (submitEvent)="onSelectAlbum($event)"
  [selectedAlbum]="creatorService.album"
  [spaceId]="creatorService.spaces[0]?.id"
>
</app-album-picker>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
