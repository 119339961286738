<ng-container *ngIf="storyCommentsLoaded; else loadingIcon">
  <div class="box-comment" *ngIf="storyComments.length > 0">
    <app-story-comment-detail-student *ngFor="let storyComment of storyComments" [storyComment]="storyComment">
    </app-story-comment-detail-student>

    <div class="load-more" *ngIf="canLoadMore">
      <a class="spen-util-link" (click)="loadMoreStoryComments()">
        <span>もっと見る</span>
        <i [class]="isLoadingMore ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
      </a>
    </div>
  </div>
  <div class="empty-message" *ngIf="storyComments.length === 0">ここに先生からのひとことが表示されます。</div>
</ng-container>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
