import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { UploadFile } from '@models/upload-file';
import { S3CredentialService } from '@services/s3-credential.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { AuthUserService } from '@services/auth-user.service';

import * as loadImage from 'blueimp-load-image';

const NeedProcessingTypes = ['jpg', 'jpeg', 'png'];
const AllowedImageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
const MaxFileSizeInMB = 300;
const MaxNeedProcessingTypesSizeInMB = 50;
const MaxFileSizeInByte = MaxFileSizeInMB * 1024 * 1024;
const MaxNeedProcessingTypesSizeInByte = MaxNeedProcessingTypesSizeInMB * 1024 * 1024;
const MaxPreviewImageSize = 30 * 1024 * 1024;

@Component({
  selector: 'app-cover-image-uploader',
  templateUrl: './cover-image-uploader.component.html',
  styleUrls: ['./cover-image-uploader.component.scss'],
  providers: [S3CredentialService, UploadFilesService],
})
export class CoverImageUploaderComponent implements OnInit, AfterViewChecked {
  @Input() uploader: FileUploader = new FileUploader({ url: '' });
  @Input() canEdit = true;
  @Input() attachedFiles: Array<UploadFile> = [];
  @Input() removedAttachFiles = [];
  @Output() uploadFilesStatus: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventSelectFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  attachFilesErrMsg: string;
  serverResponseErrMsg: string;
  showProgressbar = false;
  canceledUploadFiles: boolean;

  @ViewChild('upload_div', { static: true }) upload_div;

  constructor(
    private s3CredentialService: S3CredentialService,
    private uploadFilesService: UploadFilesService,
    public authUserService: AuthUserService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.uploader.onAfterAddingFile = (fileItem) => {
      const isImageTypeNeedsProcessing = NeedProcessingTypes.includes(this.getFileExtension(fileItem.file.name));
      if (!isImageTypeNeedsProcessing || (isImageTypeNeedsProcessing && fileItem.file.size > MaxNeedProcessingTypesSizeInByte)) {
        return;
      }

      loadImage.parseMetaData(fileItem._file, (data) => {
        if (!data.exif || !data.exif.get('Orientation')) {
          return;
        }
        const orientation = data.exif.get('Orientation');

        loadImage(
          fileItem._file,
          (canvas) => {
            canvas.toBlob((blob) => {
              blob.name = fileItem._file.name;
              fileItem._file = blob;
              this.changeDetectorRef.detectChanges();
            }, fileItem._file.type);
          },
          { canvas: true, orientation: orientation },
        );
      });
    };

    this.uploader.onAfterAddingAll = (fileItems) => {
      if (this.attachedFiles) {
        this.attachedFiles.forEach((file) => this.removeAttachedFile(file));
        this.attachedFiles = [];
      }
      this.uploader.queue = [fileItems[0]];
      if (this.isNotAllowedFileExtensions(fileItems[0])) {
        this.attachFilesErrMsg = 'ファイル拡張子が未対応です。';
        this.removeItemFromQueue(fileItems[0]);
      } else if (this.isNotAllowedFileSizes(fileItems[0])) {
        this.attachFilesErrMsg = `画像の大きさは${
          NeedProcessingTypes.includes(this.getFileExtension(fileItems[0].file.name)) ? MaxNeedProcessingTypesSizeInMB : MaxFileSizeInMB
        }MB以下にしてください。`;
        this.removeItemFromQueue(fileItems[0]);
      } else {
        this.attachFilesErrMsg = null;
      }
    };

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const uploadFileParams = fileItem['uploadFileParams'] || {};
      for (const key of Object.keys(uploadFileParams)) {
        form.append(key, uploadFileParams[key]);
      }
    };

    this.uploader.onCompleteItem = (fileItem) => {
      window.setTimeout(() => {
        this.showProgressbar = false;
        if (fileItem.isSuccess) {
          this.uploadFilesStatus.emit('completed');
        } else {
          this.uploadFilesStatus.emit('failed');
        }
      }, 500);
    };
  }

  ngAfterViewChecked() {
    this.resize();
  }

  isNotAllowedFileExtensions(fileItem) {
    const fileExtension = this.getFileExtension(fileItem.file.name);

    fileItem.fileExtension = fileExtension;
    return !AllowedImageExtensions.includes(fileExtension);
  }

  isNotAllowedFileSizes(fileItem) {
    return NeedProcessingTypes.includes(this.getFileExtension(fileItem.file.name))
      ? fileItem.file.size > MaxNeedProcessingTypesSizeInByte
      : fileItem.file.size > MaxFileSizeInByte;
  }

  isShowPreview(fileItem) {
    const fileExtension = this.getFileExtension(fileItem.file.name);
    return !this.isNotAllowedFileExtensions(fileItem) && fileItem.file.size < MaxPreviewImageSize;
  }

  sendUploadedFiles() {
    const uploadedFiles = this.uploader.queue
      .filter((fileItem) => fileItem.isSuccess)
      .map((fileItem) => {
        return {
          uuid: fileItem['uuid'],
          file_name: fileItem.file.name,
          file_extension: fileItem['fileExtension'],
        };
      });
    return uploadedFiles.concat(this.removedAttachFiles);
  }

  uploadAll() {
    if (this.uploader.queue.length === 0 || this.uploader.queue[0].isSuccess) {
      this.showProgressbar = false;
      this.uploadFilesStatus.emit('completed');
      return;
    }

    const uploadFileItems = this.prepareFilesToUpload();
    if (uploadFileItems.length > 0) {
      this.uploadFilesStatus.emit('uploading');
      this.s3CredentialService.getPolicyParams(uploadFileItems).subscribe(
        (response) => {
          const uploadFilesParams = response.upload_links;
          uploadFileItems.forEach((fileItem, index) => {
            fileItem.url = uploadFilesParams[index].endpoint_url;
            fileItem['uuid'] = uploadFilesParams[index].uuid;
            fileItem['uploadFileParams'] = uploadFilesParams[index].url_properties;
          });
          if (!this.canceledUploadFiles) {
            this.uploadFiles(uploadFileItems);
          }
        },
        (error) => {
          this.serverResponseErrMsg = <any>error;
          if (!this.canceledUploadFiles) {
            this.uploadFiles(uploadFileItems);
          }
        },
      );
    }
  }

  prepareFilesToUpload() {
    this.showProgressbar = true;
    this.uploader.progress = 0;
    let uploadingFiles;
    if (this.canceledUploadFiles) {
      this.canceledUploadFiles = false;
      uploadingFiles = this.uploader.queue;
    } else {
      uploadingFiles = this.uploader.queue.filter((fileItem) => !fileItem.isUploaded || fileItem.isError);
    }
    uploadingFiles.forEach((fileItem) => {
      fileItem.isCancel = false;
      fileItem.isError = false;
      fileItem.isSuccess = false;
      fileItem.isUploaded = false;
    });
    return uploadingFiles;
  }

  uploadFiles(fileItems) {
    fileItems.forEach((fileItem) => fileItem.upload());
  }

  getFileExtension(fileName) {
    return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
  }

  removeAttachedFile(file) {
    if (!!this.attachedFiles) {
      this.attachedFiles = this.attachedFiles.filter((fileItem) => fileItem.id !== file.id);
      this.removedAttachFiles.push({
        id: file.id,
        _destroy: true,
      });
    }
  }

  removeItemFromQueue(fileItem) {
    this.uploader.removeFromQueue(fileItem);
  }

  cancelUploadingFiles() {
    this.showProgressbar = false;
    this.canceledUploadFiles = true;
    this.uploader.cancelAll();
    this.uploadFilesStatus.emit('pending');
    const uploadedFileNames = this.uploader.queue
      .filter((fileItem) => fileItem.isUploaded && fileItem.isSuccess)
      .map((fileItem) => fileItem['uploadFileParams']['key']);

    if (uploadedFileNames.length > 0) {
      this.deleteCanceledFiles(uploadedFileNames);
    }
  }

  deleteCanceledFiles(fileNames: Array<string>) {
    this.uploadFilesService.deleteCanceledFiles(fileNames).subscribe(
      (response) => {},
      (error) => (this.serverResponseErrMsg = <any>error),
    );
  }

  resize() {
    if (!this.upload_div) {
      return;
    }
    const imageDiv = this.upload_div.nativeElement.querySelectorAll('.attach-file');
    for (const div of imageDiv) {
      this.renderer.setStyle(div, 'height', getComputedStyle(div).width);
    }
  }

  onSelectFile($event) {
    this.eventSelectFile.emit(true);
  }

  get acceptTypes(): string {
    return 'image/png,image/gif,image/jpeg';
  }

  get isExistFileUploaded() {
    return !!this.uploader.queue.length || !!this.attachedFiles.length;
  }

  private removeItems() {
    if (this.attachedFiles.length) {
      this.removeAttachedFile(this.attachedFiles[0]);
      this.attachedFiles = [];
    }

    if (this.uploader.queue.length) {
      this.uploader.queue = [];
    }
  }
}
