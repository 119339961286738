<div class="spen-ly-modal" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <h2 class="heading-text">相互評価取り組み状況一覧</h2>
      </div>
      <div class="modal-content">
        <div class="filter">
          <div class="section-contents">
            <app-datepicker
              [isPopup]="true"
              [isFixedDropdown]="true"
              (modelChange)="updateStartTime($event)"
              [selectedDate]="startTime"
              [maxDate]="endTime"
            >
            </app-datepicker>
            <p>から</p>
            <app-datepicker
              [isPopup]="true"
              [isFixedDropdown]="true"
              (modelChange)="updateEndTime($event)"
              [selectedDate]="endTime"
              [minDate]="startTime"
              [maxDate]="currentDate"
            >
            </app-datepicker>
            <p>まで</p>
          </div>
          <div class="tab-view">
            <app-tab-view
              [tabOptions]="tabOptions"
              [disableReloadWhenChangeTab]="true"
              (switchTab)="onChangeTabDialog($event)"
            ></app-tab-view>
          </div>
        </div>
        <ng-container *ngIf="contentLoaded || inProgressSort; else loadingIcon">
          <div class="spen-mod-table">
            <div class="cell-rate-30 table-header header-name">
              <p>{{ currentTable[0].text }}</p>
            </div>
            <div class="cell-rate-30 class-name table-header">
              <div>
                <a class="spen-util-link pointer" (click)="changeBySortColumn(currentTable[1].name)">
                  {{ currentTable[1].text }}
                  <i [class]="sortStatus[currentTable[1].name]"></i>
                </a>
              </div>
            </div>
            <div class="cell-rate-20 right-alignment padding-left-60 table-header">
              <div>
                <a class="spen-util-link pointer" (click)="changeBySortColumn(currentTable[2].name)">
                  {{ currentTable[2].text }}
                  <i [class]="sortStatus[currentTable[2].name]"></i>
                </a>
              </div>
            </div>
            <div class="cell-rate-20 right-alignment table-header header-last">
              <div>
                <a class="spen-util-link pointer" (click)="changeBySortColumn(currentTable[3].name)">
                  {{ currentTable[3].text }}
                  <i [class]="sortStatus[currentTable[3].name]"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            class="spen-mod-flex-table"
            infinite-scroll
            infiniteScrollDistance="1"
            infiniteScrollThrottle="200"
            [scrollWindow]="false"
            [infiniteScrollDisabled]="!nextUrl"
            (scrolled)="onLoadMore()"
          >
            <div class="flex-table-row" *ngFor="let student of students" [ngClass]="{ 'row-disable': !student.viewer_permission }">
              <div class="cell-rate-30 row-item">
                <div class="user-info">
                  <img src="{{ student.avatar }}" />
                  <a [routerLink]="['/students/teacher', student.id]" class="spen-util-link">
                    <div class="text-info truncate-text">
                      <span>{{ student.name }}</span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="cell-rate-30 row-item">
                <p class="text-info truncate-text">
                  {{ !!student.class_name ? student.class_name + student.attendance_number + '番' : '-' }}
                </p>
              </div>
              <div class="cell-rate-20 row-item" align="right">
                <p class="title-submit">{{ student[currentTable[2].name] }}</p>
              </div>
              <div class="cell-rate-20 row-item" align="right">
                <p class="title-submit">{{ student[currentTable[3].name] }}</p>
              </div>
            </div>
            <div class="spen-mod-flex-loading table-loading-icon" *ngIf="isLoading">
              <div class="loading-icon"></div>
            </div>
          </div>
          <div class="box-text-permision">
            <p>選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</p>
            <p>※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</p>
          </div>
        </ng-container>
        <ng-template #loadingIcon>
          <div class="table-loading">
            <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
          </div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <div class="modal-actions is-right-actions detail-modal-footer">
          <a class="spen-mod-btn is-default js-close-modal-trigger" (click)="onCancel()">閉じる</a>
        </div>
      </div>
    </div>
  </div>
</div>
