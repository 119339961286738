import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Work } from '@components/work/work';

import { CategoriesService } from '@services/categories/categories.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { LayoutService } from '@components/layout/layout.service';
import { ToastService } from '@services/toast/toast.service';
import { WorkCreatorService } from '../work-creator.service';
import { WorkService } from '@components/work/work.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { AuthUserService } from '@services/auth-user.service';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-work-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['../create-work.component.scss', './step3.component.scss'],
})
export class Step3Component implements OnInit, OnDestroy {
  workCreateStatus: 'none' | 'init' | 'submitting' | 'completed' = 'none';
  categoryName: string;
  private ga4Params: { [key: string]: string };
  errorMsg: string;
  currentFiles = [];
  isShowAnswerPreviewModal = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public creatorService: WorkCreatorService,
    private categoriesService: CategoriesService,
    private analytics4Service: GoogleAnalytics4Service,
    private toastService: ToastService,
    private workService: WorkService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    public layoutService: LayoutService,
    private authUserService: AuthUserService,
    private uploadFilesService: UploadFilesService,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 3) {
      this.goToStep1();
      return;
    }
    this.prepareData();
    this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.iscompletedAll) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  prepareData() {
    this.categoriesService.getCategoryName(this.creatorService.work.category_id).then((categoryName) => {
      this.categoryName = categoryName;
    });

    let subCategoryName: string;
    this.categoriesService
      .getCategory(this.creatorService.work.category_id)
      .then((subCategory) => {
        if (subCategory.parent_id) {
          subCategoryName = subCategory.name;
          return this.categoriesService.getCategory(subCategory.parent_id);
        }

        return subCategory;
      })
      .then((category) => {
        this.ga4Params = {
          activity_record_name_flag: this.creatorService.work.answer_title ? '先生が指定する' : '生徒が指定する',
          activity_record_template_flag: this.creatorService.work.work_template_id ? '先生が指定する' : '生徒が指定する',
          activity_record_category_flag: this.creatorService.work.category_id ? '先生が指定する' : '生徒が指定する',
          activity_record_category_name: category.name,
          sub_activity_record_category_name: subCategoryName,
        };
      });
  }

  goBack() {
    this.creatorService.setStep2State();
    this.goToStep2();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  goToStep2() {
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onSubmit() {
    this.workCreateStatus = 'init';
    if (this.creatorService.fileUploader.queue.length > 0) {
      this.uploadAttachFileThenSubmit();
    } else {
      this.prepareForSubmitWork();
    }
  }

  uploadAttachFileThenSubmit() {
    const result = this.uploadFilesService.uploadAllFile(this.creatorService.fileUploader);
    if (result === true) {
      this.prepareForSubmitWork();
    } else if (result instanceof EventEmitter) {
      result.subscribe((value) => {
        if (value) {
          this.prepareForSubmitWork();
        } else {
          this.workCreateStatus = 'none';
        }
        result.unsubscribe();
      });
    }
  }

  cancelUploadingFiles() {
    this.workCreateStatus = 'none';
    this.uploadFilesService.cancelUploadedFiles(this.creatorService.fileUploader);
  }

  prepareForSubmitWork() {
    // Add new files uploaded.
    const newUploadedFiles = this.uploadFilesService.sentUploadedFiles(this.creatorService.fileUploader);
    for (const file of newUploadedFiles) {
      if (file['item_index'] === undefined) {
        this.creatorService.work.upload_files_attributes.push(file);
      }
    }
    // Add existed files from cloned work.
    this.creatorService.work.upload_files_attributes.push(...this.creatorService.attachedFiles);

    const trimmedComment = this.creatorService.work.comment.trim();
    if (!trimmedComment) {
      this.creatorService.work.comment = trimmedComment;
    }

    if (!!this.creatorService.workTemplate) {
      this.copyWorkTemplateStructure();
      this.formatQuestionContentForCompassWork();
    } else if (this.creatorService.isCloneWork) {
      if (this.creatorService.reservedWorkTemplate) {
        this.formatQuestionContentForCompassWork();
      } else {
        delete this.creatorService.work['work_questions_attributes'];
      }
    }

    this.createWork(this.creatorService.work);
  }

  private copyWorkTemplateStructure() {
    this.creatorService.work['work_questions_attributes'] = cloneDeep(this.creatorService.workTemplate.work_template_questions_attributes);
  }

  // In case of Compass questions: it could has JSON-like structure at the last question.
  private formatQuestionContentForCompassWork() {
    this.creatorService.work['work_questions_attributes'].forEach((question) => {
      if (typeof question['content'] === 'object') {
        question['content'] = JSON.stringify(question['content']);
      }
    });
  }

  createWork(work: Work) {
    this.workCreateStatus = 'submitting';

    const { create_user, ...params } = work;
    this.workService.createWork(params).subscribe(
      (response) => {
        this.workCreateStatus = 'completed';
        this.routerService.resetDataConfirm();
        this.router.navigateByUrl(`/works/teacher/delivery`, { replaceUrl: true });
        this.toastService.showToast('配信しました');
        this.sendCreateWorkGA(work);
        this.analytics4Service.sendEvent('課題', '配信画面', '配信完了', this.ga4Params);
      },
      (error) => {
        this.errorMsg = <any>error;
        this.workCreateStatus = 'none';
      },
    );
  }

  sendCreateWorkGA(work: Work) {
    const action = work.delivered_at ? '課題作成完了（予約配信）' : '課題作成完了';
    let label = null;

    if (work.work_template_id) {
      if (this.creatorService.workTemplate) {
        label = this.creatorService.workTemplate.master_id;
      } else {
        if (this.creatorService.isCloneWork) {
          label = this.creatorService.work.master_id;
        }
      }
    }
  }

  toggleAnswerPreviewModal(isOpen: boolean) {
    this.isShowAnswerPreviewModal = isOpen;
    if (isOpen) {
      this.creatorService.work.create_user = this.authUserService.retrieve();
      this.currentFiles = [...this.creatorService.attachedFiles, ...this.creatorService.fileUploader.queue];
    }
  }

  get hasAttachedFiles(): boolean {
    return this.creatorService.attachedFiles.length > 0 || this.creatorService.fileUploader.queue.length > 0;
  }

  get deliveryText() {
    const delivered_at = this.creatorService.work.delivered_at;
    if (!delivered_at) {
      return 'すぐに配信する';
    }
    const dayOfWeek = new Date(delivered_at).toLocaleString('ja-JP', { weekday: 'short' });
    return delivered_at.replace(' ', `（${dayOfWeek}）`);
  }

  get disableSubmitButton() {
    return this.workCreateStatus !== 'none';
  }

  get iscompletedAll() {
    return this.workCreateStatus === 'completed';
  }

  get hasCommentOrFile(): boolean {
    return !!this.creatorService.work.comment || this.hasAttachedFiles;
  }
}
