<div *ngIf="routerDataService.routerData.value.isShowLink && navigationService.canDisplayNavigator" class="row">
  <div class="col-12">
    <span class="navigator" [class.disable-link]="navigationService.isLoading">
      <a (click)="onClickNavigate('prev')" *ngIf="navigationService.prev" class="navigator-link" data-cy="navigate-prev">
        <i class="fa fa-chevron-left"></i>
        <span class="spen-util-link truncate-text">
          {{ layoutService.isTabletDownView.value ? '前へ' : navigationService.prev.label }}
        </span>
      </a>
    </span>

    <span class="text-center" [class.link-margin-pc]="!layoutService.isTabletDownView.value">
      <a (click)="onClickNavigate('list')" class="spen-util-link" data-cy="navigate-list">{{ navigationService.list.label }}</a>
    </span>

    <span class="navigator" [class.disable-link]="navigationService.isLoading">
      <a (click)="onClickNavigate('next')" class="navigator-link" *ngIf="navigationService.next">
        <span class="spen-util-link truncate-text" data-cy="navigate-next">
          {{ layoutService.isTabletDownView.value ? '次へ' : navigationService.next.label }}
        </span>
        <i class="fa fa-chevron-right"></i>
      </a>
    </span>
  </div>
</div>
