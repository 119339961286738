import { Component, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { WorkService } from '@components/work/work.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { LayoutService } from '@components/layout/layout.service';
import { ToastService } from '@services/toast/toast.service';
import { RouterService } from '@services/router.service';
import { WorkEditorService } from '@components/work/edit-work/work-editor.service';

import { CustomValidators, validateForm } from '@functions/validate-form';
import { Observable } from 'rxjs';
import { currentLocaleDate } from '@functions/date-formatter';

import { Work } from '@components/work/work';

@Component({
  selector: 'app-edit-work-step1',
  templateUrl: './edit-work-step1.component.html',
  styleUrls: ['../edit-work.component.scss'],
})
export class EditWorkStep1Component implements OnInit {
  isDeleting = false;
  workLoaded = false;
  disabledDatetimePicker = false;
  defaultDeliveredValue = currentLocaleDate;

  work: Work;

  errMsg: string;
  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    delivered_at: {
      minDateTime: '配信日時に過去日時を指定できません。',
    },
  };

  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  dataConfirmDelete = {
    title: '確認',
    content: '一度削除した課題は復元することができません。本当に課題を削除しますか？<br>提出済みの回答は生徒のマイスペースに残ります。',
    acceptButton: '取り下げる',
    cancelButton: 'キャンセル',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workService: WorkService,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private workEditorService: WorkEditorService,
    private toastService: ToastService,
    private routerService: RouterService,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    const previousStep = this.workEditorService.previousStep;
    if (previousStep === 2) {
      this.work = this.workEditorService.work;
      this.initForm();
    } else {
      const workId = this.route.snapshot.params.id;
      this.loadWorkEdit(workId);
    }
  }

  private loadWorkEdit(workId) {
    this.workService.getEditWork(workId).subscribe(
      (work) => {
        this.work = work;
        this.initForm();
      },
      (error) => {
        // Auto redirect to 403, 404 if have error
      },
    );
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: this.work.id,
      delivered_at: [this.work.delivered_at, CustomValidators.minimumDateTimeValid(this.defaultDeliveredValue)],
    });

    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
    this.disabledDatetimePicker = !this.work.delivered_at;
    this.workLoaded = true;
  }

  private onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  onSubmit() {
    this.form.get('delivered_at').updateValueAndValidity();

    this.formErrors = validateForm(this.form, false, this.validationMessages);

    if (this.form.valid) {
      this.workEditorService.work = this.form.value;
      this.nextStep();
    }
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.workEditorService.setStep2State();
  }

  deleteWork() {
    this.isDeleting = true;
    this.confirmDialogService.showConfirm(this.dataConfirmDelete).subscribe((value) => {
      if (value) {
        this.workService.deleteTeacherWork(this.work['id']).subscribe(
          (res) => {
            this.router.navigateByUrl('/works/teacher/delivery');
            this.toastService.showToast('削除しました');
          },
          (error) => {
            this.errMsg = <any>error;
            this.isDeleting = false;
          },
        );
      } else {
        this.isDeleting = false;
      }
    });
  }

  changeDeliveryType(type) {
    this.disabledDatetimePicker = type === 'immediate';
    if (this.disabledDatetimePicker) {
      this.form.controls['delivered_at'].setValue(null);
    } else {
      this.form.controls['delivered_at'].setValue(this.defaultDeliveredValue());
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDeleting && this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  private isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }
}
