<div class="timeline">
  <div class="filter-box" [formGroup]="form">
    <div class="filter-element-sp" *ngIf="layoutService.isTabletDownView.value; else filterPC">
      <div class="filter-text">
        <p class="label-text">検索条件</p>
        <p class="filter-result" data-cy="text-filter">{{ filterText }}</p>
      </div>
      <div class="filter-select">
        <div class="drawers">
          <a class="spen-util-link show-more-filter" (click)="changeShowFilterStatus()">
            変更<i [class]="isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter-status"></i>
          </a>
        </div>
        <ng-container *ngIf="isShowFilter">
          <p class="label-text">校種学年組</p>
          <label class="spen-mod-select">
            <select class="select" formControlName="school_year" data-cy="school-year" (change)="onSelectChange($event)">
              <option *ngFor="let year of schoolYears" [value]="year">{{ year }}年度</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
          <label class="spen-mod-select">
            <select class="select" formControlName="grade_id" data-cy="grade-id" (change)="onSelectChange($event)">
              <option class="is-placeholder" [ngValue]="null">すべての学年</option>
              <option *ngFor="let grade of grades" [value]="grade.id">{{ grade.school_stage_name }}{{ grade.name }}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
          <label class="spen-mod-select">
            <select class="select" formControlName="class_id" data-cy="class-id">
              <option class="is-placeholder" [ngValue]="null">すべての組</option>
              <option *ngFor="let class of classes" [value]="class.id">{{ class.classroom_name }}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>

          <div class="button-group">
            <button
              type="button"
              class="spen-mod-btn is-primary"
              data-cy="submit-filter"
              (click)="submitFilter()"
              [disabled]="isLoadingData"
            >
              適用する
            </button>
            <button
              type="button"
              class="spen-mod-btn is-default"
              data-cy="remove-filter"
              (click)="clearFilter()"
              [disabled]="isLoadingData || isDefaultForm"
            >
              選択解除
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-template #filterPC>
      <div class="filter-element">
        <div class="block-filter">
          <p class="label-text">校種学年組</p>
          <label class="spen-mod-select">
            <select class="select" formControlName="school_year" data-cy="school-year" (change)="onSelectChange($event)">
              <option *ngFor="let year of schoolYears" [value]="year">{{ year }}年度</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
          <label class="spen-mod-select">
            <select class="select" formControlName="grade_id" data-cy="grade-id" (change)="onSelectChange($event)">
              <option class="is-placeholder" [ngValue]="null">すべての学年</option>
              <option *ngFor="let grade of grades" [value]="grade.id">{{ grade.school_stage_name }}{{ grade.name }}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
          <label class="spen-mod-select">
            <select class="select" formControlName="class_id" data-cy="class-id">
              <option class="is-placeholder" [ngValue]="null">すべての組</option>
              <option *ngFor="let class of classes" [value]="class.id">{{ class.classroom_name }}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </label>
        </div>
        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            (click)="clearFilter()"
            [disabled]="isLoadingData || isDefaultForm"
          >
            選択解除
          </button>
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="submitFilter()" [disabled]="isLoadingData">
            検索する
          </button>
        </div>
      </div>
    </ng-template>
  </div>

  <p
    class="filter-text"
    data-cy="text-filter"
    *ngIf="contentLoaded && !layoutService.isTabletDownView.value && (!isDefaulQueryParams || haveTimelineInDB)"
  >
    検索条件：{{ filterText }}
  </p>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <ng-container *ngIf="timelineDates.length > 0; else emptyMessage">
      <app-timeline-date-box
        *ngFor="let date of timelineDates; let last = last"
        [time]="date"
        [actions]="timelineMap[date]"
        [nextActionDate]="last ? meta.next_action_date : null"
        [actionsLoading]="actionsLoading"
        (loadMore)="loadMore()"
      >
      </app-timeline-date-box>
    </ng-container>

    <ng-template #emptyMessage>
      <div class="empty-block">
        <p class="empty-filter-result muted-text" *ngIf="haveTimelineInDB || !this.isDefaulQueryParams; else notRecordInDB">
          検索結果が0件です。
        </p>
        <ng-template #notRecordInDB>
          <p class="empty-record muted-text">ここに校内のアクティビティが表示されます。</p>
          <img src="assets/images/timeline-empty.svg" alt="" class="empty-image" />
        </ng-template>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingIcon>
    <div class="spen-mod-flex-loading">
      <div class="loading-icon"></div>
    </div>
  </ng-template>
</div>
