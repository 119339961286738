import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AssessmentListTeacherComponent } from './assessment-list/teacher/assessment-list-teacher.component';
import { AssessmentDetailTeacherComponent } from './assessment-detail/teacher/assessment-detail-teacher.component';
import { AssessmentFormStudentComponent } from './assessment-form/student/assessment-form-student.component';
import { AssessmentFormTeacherComponent } from './assessment-form/teacher/assessment-form-teacher.component';
import { CrossAssessmentFormComponent } from './assessment-form/cross-assessment/cross-assessment-form.component';
import { AssessmentService } from './assessment.service';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';

@NgModule({
  declarations: [
    AssessmentListTeacherComponent,
    AssessmentDetailTeacherComponent,
    AssessmentFormStudentComponent,
    AssessmentFormTeacherComponent,
    CrossAssessmentFormComponent,
  ],
  exports: [
    AssessmentListTeacherComponent,
    AssessmentDetailTeacherComponent,
    AssessmentFormStudentComponent,
    AssessmentFormTeacherComponent,
    CrossAssessmentFormComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, SharedDirectivesModule, SharedModule],
  providers: [AssessmentService],
})
export class AssessmentModule {}
