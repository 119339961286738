import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CategoriesService } from '@services/categories/categories.service';

import { Manavision } from '@models/manavision';
import { User } from '@models/user';

@Component({
  selector: 'app-manavision-detail-teacher',
  templateUrl: './manavision-detail-teacher.component.html',
  styleUrls: ['./manavision-detail-teacher.component.scss'],
})
export class ManavisionDetailTeacherComponent implements OnInit {
  record: Manavision;
  student: User;
  isUpdated: boolean;
  categoryNames = [];
  dataLoaded = false;

  constructor(
    private router: Router,
    private location: Location,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit() {
    if (!history.state || !history.state.activityRecord || !history.state.student) {
      this.location.back();
      return;
    }
    this.record = history.state.activityRecord;
    this.student = history.state.student;
    this.isUpdated = this.record.updated_at === '-';
    this.getCategoryNames();
  }

  getCategoryNames() {
    if (!this.record.category_ids) {
      this.dataLoaded = true;
    }

    this.categoriesService.getCategoryNames(this.record.category_ids).then((names) => {
      this.categoryNames = names;
      this.dataLoaded = true;
    });
  }
}
