import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { NotificationService } from './notification.service';

@NgModule({
  declarations: [ShowNotificationComponent],
  exports: [ShowNotificationComponent],
  imports: [CommonModule, InfiniteScrollModule],
  providers: [NotificationService],
})
export class NotificationModule {}
