<div class="prod-ly-teacher-sidebar" [ngClass]="{ 'is-show': isShowSidebar }">
  <ul class="left-sidebar">
    <div *ngIf="layoutService.isMediumDesktopDownView.value">
      <li class="close" (click)="hideSidebar()"><i class="fa fa-times"></i></li>
    </div>
    <li
      *ngFor="let tab of appTabs; let last = last"
      [class.active]="menuTabStatus[tab.path]"
      [class.last]="last"
      [class.disable-mouse]="isCurrentRoute(tab.path)"
    >
      <a
        [routerLink]="'/' + tab.path"
        [ngClass]="[tab.path === '' ? 'title' : 'item']"
        (click)="hideSidebar()"
        *ngIf="!tab.newTab; else newTabLink"
        >{{ tab.name }}</a
      >
      <ng-template #newTabLink>
        <a href="{{ tab.path }}" (click)="hideSidebar(); sendEvent(tab.ga4ClickParts)" target="_blank">
          <i class="fa fa-external-link item"></i>{{ tab.name }}</a
        >
      </ng-template>
    </li>
  </ul>
</div>
<div
  [ngClass]="{ 'shadow-screen': layoutService.isMediumDesktopDownView.value }"
  (click)="hideSidebar()"
  *ngIf="slideMenuService.isShowSidebar"
></div>
