import { Component, OnInit } from '@angular/core';
import { SlideMenuService } from '@services/slide-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MySpaceService } from '@components/my-space/my-space.service';
import { AuthUserService } from '@services/auth-user.service';
import { StudentNavigationService } from '@services/navigation/student-navigation.service';
import { User } from '@models/user';
import { BadgeMySpaceResponse } from './badge-my-space-response';

@Component({
  selector: 'app-my-space',
  templateUrl: './my-space.component.html',
  styleUrls: ['./my-space.component.scss'],
})
export class MySpaceComponent implements OnInit {
  tabOptions = { activities: '活動記録', albums: 'アルバム', work: '課題' };
  notificationBadge: BadgeMySpaceResponse = {
    activities: false,
    albums: false,
    work: false,
    unread_assessments_count: 0,
    request_reviews: [],
    story_comments: [],
    delivered_graduation_messages: [],
  };
  currentTab = 'activities';
  currentUser: User;

  notificationBadgeLoaded = false;
  errMsg: string;
  isNotification = false;

  constructor(
    private slideMenuService: SlideMenuService,
    private mySpaceService: MySpaceService,
    private route: ActivatedRoute,
    private router: Router,
    public authUserService: AuthUserService,
    private navigationService: StudentNavigationService,
  ) {}

  ngOnInit() {
    this.currentUser = this.authUserService.retrieve();
    this.route.queryParams.subscribe((params) => {
      if (params['tab'] && Object.keys(this.tabOptions).includes(params['tab'])) {
        this.currentTab = params['tab'];
        if (!this.notificationBadgeLoaded) {
          this.getBadge();
        }
      } else {
        this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
      }
    });
  }

  async getBadge(): Promise<void> {
    const response = await this.mySpaceService.getBadgeMySpaceStudent().toPromise();
    this.notificationBadge = response;
    this.notificationBadgeLoaded = true;
    this.isNotification = this.hasNotification();
  }

  slideMenu() {
    if (this.currentUser.benesseId) {
      this.authUserService.authenticateToSystem().subscribe(
        (response) => {
          this.authUserService.isSystemAuthenticated = !response['result'];
          this.authUserService.sessionKey = response['sk'];
        },
        (error) => {
          this.authUserService.isSystemAuthenticated = false;
          this.errMsg = <any>error;
        },
      );
    }
    this.slideMenuService.slideIn();
  }

  private hasNotification(): boolean {
    const notifications = [
      this.notificationBadge.request_reviews,
      this.notificationBadge.story_comments,
      this.notificationBadge.delivered_graduation_messages,
    ];

    return notifications.some((value) => value?.length > 0);
  }

  onClickDeliveredGraduationMessageLink(): void {
    this.navigationService.registerNavigator({ screenId: 2, filterData: {}, listUrl: '/graduation-messages/student' });
  }
}
