import { Component, Input } from '@angular/core';
import { ActivityRecord } from '@components/activity-record/activity-record';

@Component({
  selector: 'app-student-activity-item',
  templateUrl: './student-activity-item.component.html',
  styleUrls: ['./student-activity-item.component.scss'],
})
export class StudentActivityItemComponent {
  @Input() activityRecord: ActivityRecord;

  constructor() {}
}
