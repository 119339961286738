export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isEdge() {
  return /Edge/i.test(navigator.userAgent);
}

export function isMobileApp() {
  return /Every|PocketBook|Flip|Portal|filter/i.test(navigator.userAgent);
}

export function isOldAndroid() {
  return /Android 4/i.test(navigator.userAgent);
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export function isMobile() {
  return /Mobile/i.test(navigator.userAgent);
}
