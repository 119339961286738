<div class="spen-ly-modal filter-multiple" id="modal">
  <div class="modal-dialog">
    <div class="inner">
      <div class="modal-heading">
        <span class="top-btn-close" (click)="closeFilter()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <h2 class="heading-text">検索</h2>
      </div>

      <div class="modal-content has-footer">
        <div class="box-filter" *ngIf="dataLoaded(); else loadingIcon">
          <form [formGroup]="form">
            <div class="row line-keyword" [class.no-border]="authUserService.isStudent()">
              <div class="col-md-3 col-xs-12">
                <p *ngIf="authUserService.isTeacher()" class="title-1 label-input">キーワード</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <input class="spen-mod-input-text" placeholder="キーワードを入力してください" formControlName="keyword" />
              </div>
            </div>

            <div class="row block-category" [class.no-border]="authUserService.isStudent()">
              <div class="col-md-3 col-xs-12 align-base-line">
                <p class="title-1 label-input">カテゴリー</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <app-categories-selection [categories]="categories" formControlName="category_id"> </app-categories-selection>
              </div>
            </div>

            <div class="row block-select">
              <div class="col-md-3 col-xs-12 align-base-line">
                <p class="title-1 label-input">種別</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <label class="spen-mod-select">
                  <select class="select" formControlName="activity_type">
                    <option class="select-option" [ngValue]="null">すべて</option>
                    <option class="select-option" value="free">自主作成</option>
                    <option class="select-option" value="answer">課題回答</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>

            <app-marks-selector [selectedIds]="selectedMarkIds" [marks]="marks"></app-marks-selector>

            <div class="row block-select">
              <div class="col-md-3 col-xs-12 align-base-line">
                <p class="title-1 label-input">コメント状況</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <label class="spen-mod-select">
                  <select class="select" formControlName="comment_status">
                    <option class="select-option" [ngValue]="null">すべて</option>
                    <option class="select-option" value="include_current_student">コメントした</option>
                    <option class="select-option" value="exclude_current_student">コメントしていない</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>

            <div class="row block-select">
              <div class="col-md-3 col-xs-12 align-base-line">
                <p class="title-1 label-input">公開状況</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <label class="spen-mod-select">
                  <select class="select" formControlName="public_status">
                    <option class="select-option" [ngValue]="null">すべて</option>
                    <option class="select-option" value="self_publish">自主公開</option>
                    <option class="select-option" value="pickup">ピックアップ</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </div>
          </form>
        </div>
        <ng-template #loadingIcon>
          <div class="modal-content">
            <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
          </div>
        </ng-template>
      </div>

      <div class="modal-footer">
        <div class="modal-actions is-right-actions">
          <a class="cancel spen-mod-btn is-default js-close-modal-trigger" (click)="closeFilter()">キャンセル</a>
          <a class="spen-mod-btn is-primary btn-submit" (click)="onSubmit()">検索する</a>
        </div>
      </div>
    </div>
  </div>
</div>
