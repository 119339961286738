import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthUserService } from '../services/auth-user.service';

// https://app.asana.com/0/1152390259906737/1157371729905753
// This guard only used for backward-compatibility purpose.
// It will redirect 'spaces' to 'spaces/teacher' or 'spaces/student' based on role of user.

@Injectable()
// TODO: CanActivate は非推奨なので使わないようにする
export class RedirectSpacesRoutesGuard implements CanActivate {
  constructor(
    private authUserService: AuthUserService,
    protected router: Router,
  ) {}

  async canActivate(): Promise<boolean> {
    const isAuthenticated = await this.authUserService.isAuthenticated();

    if (!isAuthenticated) {
      return false;
    }

    if (this.authUserService.isStudent()) {
      this.router.navigateByUrl('/spaces/student');
    } else {
      this.router.navigateByUrl('/spaces/teacher');
    }
  }
}
