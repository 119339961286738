<div *ngIf="!layoutService.isTabletDownView.value; else spView">
  <div class="filter-detail-border-top">
    <form [formGroup]="form" class="full-width">
      <div class="filter-box">
        <div class="filter-condition">
          <span class="label-text">ステータス</span>
          <label *ngFor="let status of submitionStatus" class="spen-mod-radio" data-cy="submition-status">
            <input
              type="radio"
              value="{{ status.value }}"
              formControlName="submission_status"
              class="radio"
              data-cy="submition-status-radio"
            />
            <i class="fa"></i>
            <span data-cy="submition-status-name">{{ status.name }}</span>
          </label>
          <span class="label-text">配信課題名</span>
          <input
            class="spen-mod-input-text input-search"
            data-cy="search-title"
            formControlName="keyword"
            placeholder="配信課題名を入力してください"
            type="text"
            maxlength="50"
          />
        </div>
        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn is-default"
            data-cy="remove-filter"
            [disabled]="isLoadingWorkData || isDefaultFormValue"
            (click)="resetFormValue()"
          >
            選択解除
          </button>
          <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" [disabled]="isLoadingWorkData" (click)="onSubmit()">
            検索する
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="results" *ngIf="!isLoadingWorkData; else loadingIcon">
    <div class="result-info">
      <p class="filter-result-info" data-cy="text-filter" *ngIf="studentUnsubmitWorks.length > 0 || hasFilterFormSubmittedYet">
        検索条件：{{ filterSerice.messageFilter }}
      </p>
      <p class="page-info" data-cy="text-page-info" *ngIf="studentUnsubmitWorks?.length > 0">{{ pages | paginateText }}</p>
    </div>

    <div *ngIf="studentUnsubmitWorks?.length > 0; else noWork" class="data-box">
      <div class="spen-mod-flex-table table-list-full has-link">
        <div class="flex-table-head">
          <div class="flex-item col-size-5 is-first-col">配信課題名</div>
          <div class="flex-item col-size-4">配信者</div>
          <div class="flex-item col-size-3">
            <a class="spen-util-link pointer" (click)="onChangeDeliveryDateOrder()">
              配信日時
              <i [ngClass]="deliveryDateSortIcon" data-cy="col-sort-icon"></i>
            </a>
          </div>
          <div class="flex-item col-size-4">配信先</div>
          <div class="flex-item redirect-cell"></div>
        </div>
        <a
          class="flex-table-row"
          data-cy="submitted-item"
          *ngFor="
            let work of studentUnsubmitWorks
              | paginate
                : {
                    itemsPerPage: pages.per_page,
                    currentPage: pages.current_page,
                    totalItems: pages.total_count,
                  }
          "
          (click)="onOpenWorkDetail(work.id)"
        >
          <div class="flex-item col-size-5 is-first-col truncate-text" data-cy="submitted-title">
            {{ work.title }}
          </div>
          <div class="flex-item col-size-4 truncate-text" data-cy="submitted-creator-name">{{ work.create_user_name }}</div>
          <div class="flex-item col-size-3" data-cy="submitted-delivered-at">{{ work.delivered_at }}</div>
          <div class="flex-item col-size-4 space-name truncate-text">
            <a class="spen-util-link" data-cy="submitted-delivered-space" (click)="onOpenSpaceDetail(work.spaces[0].id)">
              <span class="truncate-text">{{ work.spaces[0].name }}</span>
            </a>
            <span data-cy="submitted-delivered-space-more" *ngIf="work.spaces.length > 1">他{{ work.spaces.length - 1 }}件</span>
          </div>
          <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
        </a>
      </div>

      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls class="paginate-custom" (pageChange)="pageChanged($event)"> </app-pagination-controls>
      </div>
    </div>
  </div>
</div>

<ng-template #spView>
  <div class="sp-view">
    <div class="filter-box">
      <div class="filter-text-info">
        <div class="text-row">
          <p class="label">検索条件</p>
          <p class="filter-result" data-cy="text-filter">{{ filterSerice.messageFilter }}</p>
        </div>
        <div class="text-row">
          <p class="label">並び順</p>
          <p class="filter-result" data-cy="text-sort-order">{{ messageSort }}</p>
        </div>
      </div>
      <div class="filter-detail">
        <div>
          <a (click)="onChangeShowFilter()" class="spen-util-link detail-link-settings">
            <span>変更</span>
            <i [ngClass]="isShowFilterDetail ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
          </a>
        </div>
        <div *ngIf="isShowFilterDetail">
          <div class="text-detail-content">
            <form [formGroup]="form" class="full-width">
              <div>
                <p class="filter-detail-name">ステータス</p>
                <label class="spen-mod-select">
                  <select class="select" data-cy="submition-status" formControlName="submission_status">
                    <option value="{{ status.value }}" *ngFor="let status of submitionStatus">{{ status.name }}</option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
                <p class="filter-detail-name">配信課題名</p>
                <input
                  class="spen-mod-input-text"
                  data-cy="search-title"
                  formControlName="keyword"
                  maxlength="50"
                  placeholder="配信課題名を入力してください"
                  type="text"
                  maxlength="50"
                />
                <p class="filter-detail-name">並び順</p>
                <label class="spen-mod-select">
                  <select
                    class="select"
                    data-cy="sort-order"
                    formControlName="sort_order"
                    (change)="onChangeSortValue($event.target.value)"
                  >
                    <option *ngFor="let sortOption of sortOptions" [value]="sortOption.sort_value" class="select-option">
                      {{ sortOption.text }}
                    </option>
                  </select>
                  <i class="fa fa-caret-down"></i>
                </label>
              </div>
            </form>
            <div class="button-group">
              <button
                type="button"
                class="spen-mod-btn is-primary"
                data-cy="submit-filter"
                [disabled]="isLoadingWorkData"
                (click)="onSubmit()"
              >
                適用する
              </button>
              <button
                type="button"
                class="spen-mod-btn is-default"
                data-cy="remove-filter"
                [disabled]="isLoadingWorkData || isDefaultFormValue"
                (click)="resetFormValue()"
              >
                選択解除
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="data-box" *ngIf="!isLoadingWorkData; else loadingIcon">
        <div class="list-work" *ngIf="studentUnsubmitWorks?.length > 0; else noWork">
          <p class="paginate-result" data-cy="text-page-info">{{ pages | paginateText }}</p>
          <div
            class="work"
            data-cy="submitted-item"
            *ngFor="
              let work of studentUnsubmitWorks
                | paginate: { itemsPerPage: pages.per_page, currentPage: pages.current_page, totalItems: pages.total_count }
            "
            (click)="onOpenWorkDetail(work.id)"
          >
            <div class="info">
              <p class="title truncate-text" data-cy="submitted-title">{{ work.title }}</p>
              <p class="creator truncate-text" data-cy="submitted-creator-name">配信者：{{ work.create_user_name }}</p>
              <p class="delivery-time small-text" data-cy="submitted-delivered-at">配信日時：{{ work.delivered_at }}</p>
              <p class="delivery-space small-text">
                <span *ngIf="work.spaces.length > 1" class="more-space" data-cy="submitted-delivered-space-more"
                  >他{{ work.spaces.length - 1 }}件</span
                >
                <span class="truncate-text small-text" data-cy="submitted-delivered-space">配信先：{{ work.spaces[0].name }}</span>
              </p>
            </div>
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
        <div class="spen-mod-paginate" *ngIf="showPagination">
          <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
          </app-pagination-controls>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<ng-template #noWork>
  <div class="empty-message">{{ emptyMessage }}</div>
</ng-template>
