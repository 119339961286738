import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GoogleAnalytics4Service } from './google-analytics-4.service';

@Injectable()
export class RouterService {
  previousHref: string;
  locationSupcription = null;
  enableCanDeactive = true;
  isConfirmBack = false;
  manualBack = false;
  isBacked = false;
  isSetPreviousHref = true;
  isAccessRootApp = false;
  private backBrowserListener = new Subject<void>();

  backBrowser$ = this.backBrowserListener.asObservable();

  constructor(
    private location: Location,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private googleAnalytics4Service: GoogleAnalytics4Service,
  ) {}

  setPreviousHref(href: string) {
    if (this.isSetPreviousHref) {
      this.previousHref = href;
    } else {
      this.isSetPreviousHref = true;
    }
  }

  goBackSpecific(url: string) {
    if (this.canBackDefault()) {
      this.disableSetPreviousHref();
      this.location.back();
    } else {
      this.disableSetPreviousHref();
      this.router.navigateByUrl(url, { replaceUrl: true });
    }
  }

  excuseBack(options = {}) {
    if (options['isBackBrowser']) {
      this.backBrowserListener.next();
    }

    this.disableSetPreviousHref();
    if (this.canBackDefault()) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  canBackDefault() {
    const url = this.router.url;
    return this.isAccessRootApp || (this.previousHref && url !== this.previousHref);
  }

  disableSetPreviousHref() {
    this.isSetPreviousHref = false;
  }

  goBack(params?: {}, sendGa4?: boolean) {
    if (this.isConfirmBack) {
      this.confirmDialogService.showConfirm(params).subscribe((value) => {
        if (value) {
          this.manualBack = true;
          this.enableCanDeactive = false;
          this.removeConfirmUnload();
          this.excuseBack();
          if (sendGa4) {
            this.googleAnalytics4Service.sendEvent('活動記録', '下書き確認モーダル', '移動する');
          }
        } else {
          this.enableCanDeactive = true;
        }
      });
    } else {
      this.removeConfirmUnload();
      this.excuseBack();
    }
  }

  addConfirmUnload() {
    window['confirm_unload'] = () => {
      return window.confirm('このまま戻ると、作成中の内容は破棄されます。破棄しますか？');
    };
  }

  removeConfirmUnload() {
    window['confirm_unload'] = () => {};
  }

  registerBackConfirm(params: {}) {
    this.isConfirmBack = true;
    if (this.locationSupcription === null) {
      this.locationSupcription = this.location.subscribe((obj) => {
        this.isBacked = true;
        if (!this.manualBack) {
          this.enableCanDeactive = true;
        }
      });
    }
    this.addConfirmUnload();
  }

  resetDataConfirm() {
    this.removeConfirmUnload();
    this.confirmDialogService.isShow = false;
    this.manualBack = false;
    this.isBacked = false;
    this.isConfirmBack = false;
    this.enableCanDeactive = true;
    if (this.locationSupcription) {
      this.locationSupcription.unsubscribe();
      this.locationSupcription = null;
    }
  }
}
