<div class="questionnaire-detail">
  <div class="content">
    <h2 class="heading-title">
      {{ questionnaire?.title }}
    </h2>
    <div class="question-answer" *ngFor="let question of questionnaire.questions; let i = index">
      <div class="label-title position-question">項目{{ i + 1 }}</div>
      <div class="label-title question-info">設問文</div>
      <div class="block-element">
        <p class="question-content" appConvertLink>{{ question.text }}</p>
        <img class="question-image" src="{{ question.src }}" *ngIf="question.src" />
        <p class="label-title answer-info">回答</p>
        <ng-container *ngFor="let answer of getListAnswer(questionnaire.answers[i])">
          <ng-container *ngFor="let answerOption of getListAnswerOption(answer)">
            <p class="answer-content preserve-line-break" *ngIf="answerOption.text" appConvertLink>{{ answerOption.text }}</p>
            <img class="answer-image" src="{{ answerOption.src }}" *ngIf="answerOption.src" />
          </ng-container>

          <div class="col-sm-12 col-xs-12 block-attached">
            <div class="row">
              <div
                class="image-attached col-md-3 col-sm-4 col-four-1440 col-xs-6"
                *ngIf="answer.attached"
                (window:resize)="resize()"
                #upload_div
              >
                <div class="attach attach-image" (click)="onFileClick(answer)">
                  <div class="attach-file" *ngIf="isShowThumbnail(answer.attached_file_name); else showFileIcon">
                    <img [src]="answer.attached" />
                  </div>
                  <ng-template #showFileIcon>
                    <div class="file-format"><i [ngClass]="answer.attached_file_name | fileIconClass"></i></div>
                  </ng-template>
                  <a class="action-file" *ngIf="showDownloadIcon(answer)">
                    <i class="fa fa-download"></i>
                  </a>
                </div>
                <a class="file-name" (click)="onFileClick(answer)">{{ answer.attached_file_name }}</a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="category-text" *ngIf="!!questionnaire.category_name">
      {{ questionnaire.category_name }}
    </div>
  </div>
  <div class="assessment" *ngIf="questionnaire.commented_user">
    <div class="row">
      <div class="col-xs-2">
        <div class="logo">
          <img [src]="questionnaire.commented_user?.avatar" alt="Avatar" class="img-responsive" />
        </div>
      </div>
      <div class="col-xs-10">
        <div class="user-info">
          <div class="info">
            <p class="date">{{ questionnaire.created_at | date: 'yyyy/MM/dd HH:mm' }}</p>
            <p class="name">{{ questionnaire.commented_user.name }}{{ isTeacher() ? '先生' : '' }}</p>
          </div>
        </div>
        <div class="text-content">
          <app-read-more [content]="questionnaire.commented_text"></app-read-more>
        </div>
      </div>
    </div>
  </div>

  <app-questionnaire-file-preview></app-questionnaire-file-preview>
</div>
