import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { LayoutService } from '@components/layout/layout.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StudentService } from '@components/student/student.service';
import { TeacherFilterService } from '@services/filter/teacher-filter.service';
import { pick, pickBy, mapValues, findIndex, isEqual, omit, isNil } from 'lodash';
import { checkPresentString } from '@functions/check-string';
import { ControlLinkService } from '@services/control-link/control-link.service';

const ICON_SORT = {
  none: 'fa',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-student-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss'],
})
export class StudentAlbumsComponent implements OnInit {
  @Input() studentId: number;
  private readonly allowedParamKeys = ['sort_key', 'sort_order', 'page', 'title', 'album_type'];
  private readonly disallowedUrlParamKeys = ['title'];
  albumType = [
    { value: 'all', name: 'すべて' },
    { value: 'self_create', name: '自主作成' },
    { value: 'teacher_create', name: '先生配信' },
  ];
  inProgressSort = false;
  sortKey = null;
  sortOrder = null;
  isLoading = true;
  isShowMoreFilter = false;
  form: UntypedFormGroup;
  albums = [];
  errorMsg: string;
  messageSort: string;
  isSearching = false;
  filterData = {};

  sortStatus = {
    created_at: ICON_SORT.none,
    updated_at: ICON_SORT.none,
    activity_records_count: ICON_SORT.none,
  };

  sortOptions = [
    { sort_value: 'created_at desc', text: '作成日時（降順）' },
    { sort_value: 'created_at asc', text: '作成日時（昇順）' },
    { sort_value: 'activity_records_count desc', text: '活動記録数（降順）' },
    { sort_value: 'activity_records_count asc', text: '活動記録数（昇順）' },
    { sort_value: 'updated_at desc', text: '更新日時（降順）' },
    { sort_value: 'updated_at asc', text: '更新日時（昇順）' },
  ];

  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_pages: 1,
    total_count: 0,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private formBuilder: UntypedFormBuilder,
    private filterService: TeacherFilterService,
    private studentService: StudentService,
    private controlLinkService: ControlLinkService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private loadList(params: Params): void {
    if (this.inProgressSort === false) {
      this.albums = [];
    }
    this.isLoading = true;
    this.filterData = pick(params, this.allowedParamKeys);
    this.filterService.setFilter(this.filterData);
    this.sortOrder = params['sort_order'];
    this.sortKey = params['sort_key'];
    this.getAlbums(this.studentId, this.filterData);
    this.initForm();
    this.messageSort = this.sortText;
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  initForm() {
    this.form = this.formBuilder.group({
      title: this.filterData['title'] || '',
      album_type: this.filterData['album_type'] || 'all',
      sort_value: this.getSortValue(),
    });
    this.inProgressSort = false;
    this.isSearching = !this.isDefaultForm;
  }

  getAlbums(studentId: number, params: {}) {
    this.isShowMoreFilter = false;
    this.studentService.getAlbums(studentId, params).subscribe(
      (response) => {
        Object.assign(this.metaData, response.meta);
        this.refreshIconSort();
        this.albums = response.albums;
        this.isLoading = false;
      },
      (error) => {
        this.albums = [];
        this.isLoading = false;
        this.errorMsg = <any>error;
        this.inProgressSort = false;
      },
    );
  }

  onSubmit() {
    window.scrollTo(0, 0);
    if (this.form.value.title) {
      this.form.controls['title'].setValue(this.form.value.title.trim());
    }
    const params = pickBy(Object.assign({ page: 1 }, this.form.value));

    params['sort_key'] = this.sortKey;
    params['sort_order'] = this.sortOrder;
    delete params['sort_value'];
    params['request_timestamp'] = Date.now();

    this.navigate(this.makeQueryParams(params));
  }

  onResetFilter() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortOptions[0].sort_value.split(' ');
    }

    this.form.reset(this.defaultFilterValue());
    this.onSubmit();
  }

  defaultFilterValue() {
    const filterValues = {
      album_type: 'all',
      title: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort_value'] = this.sortOptions[0].sort_value;
    }

    return filterValues;
  }

  makeQueryParams(params: {}) {
    return Object.assign(params, { tab: 'albums' });
  }

  pageChanged(page: number) {
    if (this.inProgressSort || this.isLoading) {
      return false;
    }

    const params = { ...this.filterData, page: page };
    this.navigate(params, 'merge');
  }

  onChangeShowMoreFilter() {
    this.isShowMoreFilter = !this.isShowMoreFilter;
  }

  changeBySortColumn(sortKey: string) {
    if (this.inProgressSort || this.isLoading) {
      return false;
    }

    this.inProgressSort = true;
    if (sortKey === this.sortKey) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortStatus[this.sortKey] = ICON_SORT.none;
      this.sortOrder = 'desc';
    }
    this.sortKey = sortKey;
    this.sortStatus[this.sortKey] = ICON_SORT.loader;
    const params = { ...this.filterData, ...this.makeParams() };
    this.navigate(params, 'merge');
  }

  getSortValue() {
    return this.sortKey ? `${this.sortKey} ${this.sortOrder}` : this.sortOptions[0].sort_value;
  }

  refreshIconSort() {
    if (!this.sortKey) {
      this.sortKey = 'created_at';
    }

    this.isLoading = true;
    this.sortStatus = mapValues(this.sortStatus, () => ICON_SORT.none);
    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = ICON_SORT[icon];
    if (this.inProgressSort) {
      this.form.controls['sort_value'].setValue(this.getSortValue());
    }
    this.inProgressSort = false;
  }

  get sortText() {
    const index = findIndex(this.sortOptions, ['sort_value', `${this.sortKey} ${this.sortOrder}`]);
    return index >= 0 ? this.sortOptions[index].text : this.sortOptions[0].text;
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  onClickAlbumsRecord(record, event: KeyboardEvent) {
    this.controlLinkService.open(`/albums/teacher/${record.id}/students/${this.studentId}`, event);
  }

  makeParams() {
    return {
      page: 1,
      sort_key: this.sortKey,
      sort_order: this.sortOrder,
      title: checkPresentString(this.filterData['title']) ? this.filterData['title'].trim() : '',
      album_type: this.filterData['album_type'],
    };
  }

  get showPagination(): boolean {
    return this.metaData && this.metaData.total_pages > 1;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.form.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort_value'];
    }

    return isEqual(compareValues, this.defaultFilterValue());
  }
}
