<div class="spen-ly-main">
  <div class="spen-mod-heading lv-1">指定されたページへのアクセスは制限されています。</div>
  <div class="main-content">
    <p class="error-message">以下の状況が考えられます。</p>
    <ul class="error-message-list">
      <li>
        IPアドレス制限されている<br />
        許可されたネットワークから再度アクセスしてください。
      </li>
      <li>
        閲覧権限が不足している<br />
        [設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。<br />
        ※閲覧権限の変更は、校内のClassi管理責任者/管理者の先生にご相談ください。
      </li>
      <li>
        ご利用中のサービス以外のページへアクセスされている<br />
        利用サービス設定を見直してから、再度アクセスしてください。
      </li>
      <li>
        一時的にアクセスできない状況になっている<br />
        時間をおいて再度アクセスしてください。
      </li>
    </ul>
  </div>
</div>
