<div class="teacher-work-detail-sp" *ngIf="layoutService.isTabletDownView.value; else pcView">
  <ng-container [class.disable-clicked]="isDeletingWorkDetail" *ngIf="workLoaded; else loadingIcon">
    <div class="work-detail-info">
      <p class="spen-mod-badge" data-cy="work-scheduled" *ngIf="isScheduled(workDetail.delivered_at)">予約中</p>
      <p class="work-title" data-cy="work-title">{{ workDetail.title }}</p>
      <div class="work-info">
        <div>
          <p data-cy="work-creator-name">配信者：{{ workDetail.create_user_name }}先生</p>
          <p data-cy="work-delivered-at">配信日時：{{ workDetail.delivered_at }}</p>
        </div>
        <div class="block-info pull-right" (clickOutside)="isShowDropdownActions = false">
          <label class="spen-mod-select" [class.is-selecting]="isShowDropdownActions">
            <button class="spen-mod-btn dropdown-toggle" data-cy="show-menu" (click)="isShowDropdownActions = !isShowDropdownActions">
              <img src="/assets/images/icon-dots.svg" alt="dot-icon" />
            </button>
            <div class="image-select-box" *ngIf="isShowDropdownActions">
              <ul class="option-list">
                <li data-cy="can-be-edited" *ngIf="canEditOrDeleteWorkDetail" (click)="onConfirmEditAndDelete()">
                  {{ workDetail.can_be_edited ? '編集・削除' : '削除' }}
                </li>
                <li data-cy="work-clone" (click)="onClickCopyAndDistribute()">コピーして配信</li>
              </ul>
            </div>
          </label>
        </div>
      </div>
      <div class="show-more-btn" *ngIf="!isShowMoreWorkDetailInfo">
        <a class="spen-util-link" (click)="isShowMoreWorkDetailInfo = !isShowMoreWorkDetailInfo">
          <span>もっと見る</span>
          <i class="fa fa-chevron-down" data-cy="work-detail-chevron"></i>
        </a>
      </div>
      <div *ngIf="isShowMoreWorkDetailInfo">
        <p>配信先スペース：</p>
        <p *ngFor="let space of displaySpaces">
          <a class="spen-util-link" data-cy="work-delivered-space" (click)="onClickSpaceName(space.id)">{{ space.name }}</a>
        </p>
        <a class="spen-util-link" (click)="spaceType = 'all'" *ngIf="spaceType === 'limit'">
          <span data-cy="work-delivered-space-remain">他{{ workDetail.spaces.length - spaces.limit_length }}件</span>
          <i class="fa fa-chevron-down"></i>
        </a>
        <div *ngIf="workDetail.album_name">
          <p>追加先アルバム：</p>
          <p>
            <a class="spen-util-link" data-cy="work-delivered-album" (click)="onClickAlbum()">{{ workDetail.album_name }}</a>
          </p>
        </div>
        <p data-cy="work-answer-title">活動記録名：{{ workDetail.answer_title || '指定しない' }}</p>
        <p data-cy="work-template-title">活動記録テンプレート：{{ workDetail.group_question_title || '指定しない' }}</p>
        <div class="title-link" *ngIf="workDetail.category_name; else notSpecified">
          <span class="flex-fixed">活動記録カテゴリー：</span>
          <div class="nav-link">
            <span class="category spen-break-word" data-cy="work-category-name">{{ workDetail.category_name }}</span>
          </div>
        </div>
        <div class="work-comment" *ngIf="workDetail.comment">
          <p>説明：</p>
          <p data-cy="work-comment">{{ workDetail.comment }}</p>
        </div>
        <div class="upload-files">
          <app-files-view-teacher [files]="workDetail.upload_files"> </app-files-view-teacher>
        </div>
      </div>
    </div>
    <div class="preview-answer-screen">
      <a class="spen-util-link" data-cy="show-answer-preview" (click)="toggleAnswerPreviewModal(true)">課題回答画面のプレビューを見る</a>
    </div>
    <p class="activity-record-title">活動記録一覧</p>
    <div class="activity-record-list" *ngIf="studentLoadType === 'loaded'; else loadingIcon">
      <div class="search-panel">
        <p data-cy="work-answers-count">提出数：{{ activityRecords.answers_count | number }}/{{ activityRecords.users_count | number }}</p>
        <div class="align-contents">
          <a class="spen-util-link" data-cy="show-not-answer-student" (click)="showNotAnswerStudent()">未提出者の一覧を見る</a>
          <div class="right-side-content" *ngIf="!isScheduled(workDetail.delivered_at)">
            <div *ngIf="possibleShowModal" data-cy="show-word-cloud" (click)="switchToShowWordCloud()">
              <a class="spen-util-link">概況を見る</a>
            </div>
          </div>
        </div>
        <div [formGroup]="form" class="sp-content">
          <div class="search-criteria-order">
            <div class="criteria-order-title" *ngIf="this.spaceType !== 'single'">
              <span class="left-title space-name">検索条件</span>
              <span data-cy="text-filter">{{ currentSpace.name }}</span>
            </div>
            <div class="criteria-order-title">
              <span class="left-title">並び順</span>
              <span data-cy="text-sort-order">{{ currentSortParam.sort_text }}</span>
            </div>
          </div>
          <div class="sp-change">
            <a class="spen-util-link" (click)="isShowSortPanelActions = !isShowSortPanelActions">
              <span>変更</span>
              <i class="fa" [ngClass]="isShowSortPanelActions ? 'fa-chevron-up' : 'fa-chevron-down'" data-cy="show-filter-status"></i>
            </a>
          </div>
          <div class="sp-select-options" *ngIf="isShowSortPanelActions">
            <div *ngIf="this.spaceType !== 'single'">
              <p>配信先スペース</p>
              <label class="spen-mod-select bottom-margin">
                <select class="select" data-cy="delivered-space" (change)="onChangeSpace(true)" formControlName="space">
                  <option *ngFor="let space of workDetail.spaces" [ngValue]="space">{{ space.name }}</option>
                </select>
                <i class="fa fa-caret-down"></i>
              </label>
            </div>
            <p>並び順</p>
            <label class="spen-mod-select">
              <select class="select" data-cy="sort-order" (change)="onChangeParam()" formControlName="sort_param">
                <option *ngFor="let param of sortParams" [ngValue]="param">{{ param.sort_text }}</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
            <div class="button-group">
              <button
                class="spen-mod-btn is-primary bottom-margin"
                data-cy="submit-filter"
                (click)="onSubmitFilter()"
                [disabled]="this.studentLoadType === 'sorting'"
              >
                適用する
              </button>
              <button class="spen-mod-btn is-second" data-cy="remove-filter" (click)="removeFilter()" [disabled]="cancelSearchDisabled">
                選択解除
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="students-table">
        <p class="display-number" data-cy="text-page-info">{{ pages | paginateText }}</p>
        <div class="spen-mod-table">
          <table class="table has-link">
            <tbody>
              <tr
                *ngFor="
                  let student of activityRecords.delivered_students
                    | paginate
                      : {
                          itemsPerPage: this.pages.per_page,
                          currentPage: this.pages.current_page,
                          totalItems: this.pages.total_count,
                        }
                "
                [className]="getActivityRecordType(student)"
                data-cy="work-student-item"
                (click)="onClickActivityRecord(student, $event)"
              >
                <td class="cell-inner">
                  <p class="label-text truncate-text" data-cy="work-student-answer-title" [class.normal-text]="!student.answer_title">
                    {{ student.answer_title || '(未提出)' }}
                  </p>
                  <div class="student-info">
                    <img data-cy="work-student-avator" src="{{ student.avatar }}" />
                    <div class="truncate-text">
                      <p class="student-name truncate-text" data-cy="work-student-name">{{ student.name }}</p>
                      <p class="small-text truncate-text" data-cy="work-student-class">{{ getClassName(student) }}</p>
                    </div>
                  </div>
                  <p class="date-content answer-date" data-cy="work-student-answer-date">作成日時：{{ student.answer_date || '-' }}</p>
                  <p class="date-content" data-cy="work-student-answer-update-date">更新日時：{{ student.update_answer_date || '-' }}</p>
                </td>
                <td class="is-chevron-right">
                  <i
                    class="fa fa-chevron-right"
                    data-cy="work-student-chevron"
                    *ngIf="getActivityRecordType(student) === 'record-answered'"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
          <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
          </app-pagination-controls>
        </div>
      </div>
      <div class="warning-message">
        <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
        <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #pcView>
  <div class="teacher-work-detail" [class.disable-clicked]="isDeletingWorkDetail" *ngIf="workLoaded; else loadingIcon">
    <div class="work-detail-info">
      <div class="detail-info-content">
        <div class="work-title">
          <div class="delivered-tag flex-fixed" *ngIf="isScheduled(workDetail.delivered_at)">
            <span class="spen-mod-badge" data-cy="work-scheduled">予約中</span>
          </div>
          <div class="title">
            <span class="spen-break-word" data-cy="work-title">{{ workDetail.title }}</span>
          </div>
        </div>
        <div class="work-info">
          <div class="bottom-padding">
            <span data-cy="work-creator-name">配信者：{{ workDetail.create_user_name }}先生</span>
          </div>
          <div class="bottom-padding">
            <span data-cy="work-delivered-at">配信日時：{{ workDetail.delivered_at }}</span>
          </div>
          <div class="title-link bottom-padding">
            <span class="flex-fixed">配信先スペース：</span>
            <div class="nav-link">
              <span *ngFor="let space of displaySpaces">
                <a class="spen-util-link spen-break-word" data-cy="work-delivered-space" (click)="onClickSpaceName(space.id)">{{
                  space.name
                }}</a>
              </span>
              <a class="spen-util-link" data-cy="work-delivered-space-remain" (click)="spaceType = 'all'" *ngIf="spaceType === 'limit'">
                他{{ workDetail.spaces.length - spaces.limit_length }}件
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>
          </div>
          <div class="title-link bottom-padding" *ngIf="workDetail.album_name">
            <span class="flex-fixed">追加先アルバム：</span>
            <div class="nav-link">
              <a class="spen-util-link spen-break-word" data-cy="work-delivered-album" (click)="onClickAlbum()">{{
                workDetail.album_name
              }}</a>
            </div>
          </div>
          <div class="bottom-padding">
            <span class="spen-break-word" data-cy="work-answer-title">活動記録名：{{ workDetail.answer_title || '指定しない' }}</span>
          </div>
          <div class="title-link bottom-padding" *ngIf="workDetail.template_title">
            <span class="flex-fixed">課題テンプレート名：</span>
            <div class="nav-link">
              <a class="spen-util-link spen-break-word">{{ workDetail.template_title }}</a>
            </div>
          </div>
          <div class="bottom-padding">
            <span class="spen-break-word" data-cy="work-template-title"
              >活動記録テンプレート：{{ workDetail.group_question_title || '指定しない' }}</span
            >
          </div>
          <div class="title-link" *ngIf="workDetail.category_name; else notSpecified">
            <span class="flex-fixed">活動記録カテゴリー：</span>
            <div class="nav-link">
              <span class="category spen-break-word" data-cy="work-category-name">{{ workDetail.category_name }}</span>
            </div>
          </div>
        </div>
        <div class="work-comment" *ngIf="workDetail.comment">
          <span class="flex-fixed">説明：</span>
          <div class="detail-comment">
            <span class="spen-break-word" data-cy="work-comment">{{ workDetail.comment }}</span>
          </div>
        </div>
        <div>
          <app-files-view-teacher [files]="workDetail.upload_files"> </app-files-view-teacher>
        </div>
      </div>
      <div class="flex-fixed link-setting">
        <p *ngIf="canEditOrDeleteWorkDetail">
          <a class="spen-util-link" data-cy="can-be-edited" (click)="onConfirmEditAndDelete()">{{
            workDetail.can_be_edited ? '編集・削除' : '削除'
          }}</a>
        </p>
        <p><a class="spen-util-link" data-cy="work-clone" (click)="onClickCopyAndDistribute()">コピーして配信</a></p>
      </div>
    </div>
    <div class="preview-answer-screen">
      <a class="spen-util-link" data-cy="show-answer-preview" (click)="toggleAnswerPreviewModal(true)">課題回答画面のプレビューを見る</a>
    </div>
    <div class="activity-record-list">
      <p class="title">活動記録一覧</p>
      <ng-container *ngIf="studentLoaded; else loadingIcon">
        <div class="export-answer-csv">
          <div [formGroup]="form" class="delivery-space" *ngIf="spaceType !== 'single'">
            <p>配信先スペース：</p>
            <label class="spen-mod-select">
              <select class="select" data-cy="delivered-space" (change)="onChangeSpace(false)" formControlName="space">
                <option class="select-option" *ngFor="let space of workDetail.spaces" [ngValue]="space">
                  {{ space.name }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
          <div class="right-side-content" *ngIf="!isScheduled(workDetail.delivered_at)">
            <div *ngIf="possibleShowModal" data-cy="show-word-cloud" (click)="switchToShowWordCloud()">
              <a class="spen-util-link">概況を見る</a>
            </div>
          </div>
          <div class="button-in-right-side">
            <div [class.csv-button]="spaceType === 'single'" data-cy="export-csv" *ngIf="!isScheduled(workDetail.delivered_at)">
              <span class="spen-mod-btn" (click)="exportAnswers()">CSV出力</span>
            </div>
          </div>
        </div>
        <div class="students-table">
          <div class="number">
            <span data-cy="work-answers-count"
              >提出数：{{ activityRecords.answers_count | number }}/{{ activityRecords.users_count | number }}
              <a class="spen-util-link not-answered" data-cy="show-not-answer-student" (click)="showNotAnswerStudent()"
                >未提出者の一覧を見る</a
              >
            </span>
            <span data-cy="text-page-info">{{ pages | paginateText }}</span>
          </div>

          <div class="spen-mod-flex-table table-list-full has-link">
            <div class="flex-table-head">
              <div class="flex-item col-size-4 is-first-col">活動記録名</div>
              <div class="flex-item col-size-3">作成者</div>
              <div class="flex-item col-size-3">
                <a (click)="onClickSortColumn('class_name')" class="spen-util-link" data-cy="col-class-name"
                  >校種学年組番
                  <i [class]="getSortStatus('class_name')" data-cy="col-sort-icon"></i>
                </a>
              </div>
              <div class="flex-item col-size-3">
                <a (click)="onClickSortColumn('answer_date')" class="spen-util-link" data-cy="col-created-at"
                  >作成日時
                  <i [class]="getSortStatus('answer_date')" data-cy="col-sort-icon"></i>
                </a>
              </div>
              <div class="flex-item col-size-3">
                <a (click)="onClickSortColumn('update_answer_date')" class="spen-util-link" data-cy="col-updated-at"
                  >更新日時
                  <i [class]="getSortStatus('update_answer_date')" data-cy="col-sort-icon"></i>
                </a>
              </div>
              <div class="flex-item redirect-cell"></div>
            </div>
            <a
              class="flex-table-row {{ getActivityRecordType(student) }}"
              data-cy="work-student-item"
              *ngFor="
                let student of activityRecords.delivered_students
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
              [class.disabled-item]="!student.viewer_permission"
              (click)="onClickActivityRecord(student, $event)"
            >
              <div class="flex-item col-size-4 is-first-col truncate-text" data-cy="work-student-answer-title">
                {{ student.answer_title || '-' }}
              </div>
              <div class="flex-item col-size-3 student-info">
                <img data-cy="work-student-avator" src="{{ student.avatar }}" />
                <a
                  class="truncate-text"
                  data-cy="work-student-name"
                  [class.spen-util-link]="!!student.viewer_permission"
                  (click)="onClickStudent(student, $event)"
                  >{{ student.name }}</a
                >
              </div>
              <div class="flex-item col-size-3 truncate-text" data-cy="work-student-class">{{ getClassName(student) }}</div>
              <div class="flex-item col-size-3" data-cy="work-student-answer-date">{{ student.answer_date || '-' }}</div>
              <div class="flex-item col-size-3" data-cy="work-student-answer-update-date">{{ student.update_answer_date || '-' }}</div>
              <div class="flex-item redirect-cell is-icon-item">
                <i
                  class="fa fa-chevron-right"
                  data-cy="work-student-chevron"
                  *ngIf="getActivityRecordType(student) === 'record-answered'"
                ></i>
              </div>
            </a>
          </div>

          <div class="spen-mod-paginate pagination-custom" *ngIf="showPagination">
            <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
          </div>
        </div>
        <div class="warning-message">
          <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
          <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #notSpecified>
  <div class="title-link">
    <span class="flex-fixed">活動記録カテゴリー：指定しない</span>
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>

<app-modal-list-student
  *ngIf="isShowNotAnsweredStudent"
  [spaceId]="this.currentSpace.id"
  [workId]="this.workId"
  (eventCancel)="eventCancelModal($event)"
>
</app-modal-list-student>

<app-answer-preview-modal *ngIf="isShowAnswerPreviewModal" (eventCloseModal)="toggleAnswerPreviewModal(false)" [workId]="workId">
</app-answer-preview-modal>

<div *ngIf="isShowingModal">
  <app-word-cloud-wait-modal *ngIf="gotWordCloudResponse === 'not yet'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-wait-modal>
  <div *ngIf="gotWordCloudResponse === 'have gotten'">
    <app-word-cloud-modal *ngIf="gotWordCloudDocumentId === 'success'" (closeFilter)="onCloseWordCloud()"> </app-word-cloud-modal>
    <app-word-cloud-error-modal *ngIf="gotWordCloudDocumentId === 'failed'" (closeFilter)="onCloseWordCloud()">
    </app-word-cloud-error-modal>
  </div>
</div>
