<app-student-heading-bar [dataConfirm]="dataConfirm"></app-student-heading-bar>
<div class="stu-create-story" *ngIf="dataLoaded; else loadingIcon">
  <div class="btn-show-history">
    <a class="spen-util-link" (click)="showModalActivities()">
      <span>過去の活動記録を見る</span>
    </a>
  </div>
  <div class="category-heading">
    <div class="icon">
      <img src="{{ storyParentCategory.icon }}" alt="" />
    </div>
    <h3 class="category-name">
      {{ storyParentCategory.name }}
    </h3>
  </div>
  <p class="sub-category">{{ storyCategory.name }}</p>
  <form class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)" appScrollToFirstInvalid>
    <div class="form-group">
      <div class="row">
        <div class="column-title">
          <label class="label">目標</label>
        </div>
        <div class="column-content">
          <textarea class="spen-mod-input-text" formControlName="vision" [appPlaceholder]="'入力してください'" [appFocus]="focusEvent">
          </textarea>
          <p class="text-num pull-right" *ngIf="!layoutService.isTabletDownView.value">
            文字数<span>{{ form.get('vision').value.length }}</span>
          </p>
          <div>
            <div class="spen-mod-error-content" *ngIf="formErrors['vision']">
              {{ formErrors['vision'].join(' ') }}
            </div>
          </div>
          <p class="text-num pull-right" *ngIf="layoutService.isTabletDownView.value">
            文字数<span>{{ form.get('vision').value.length }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="column-title">
          <label class="label">やったこと・考えたこと</label>
        </div>
        <div class="column-content">
          <textarea class="spen-mod-input-text" formControlName="archive" [appPlaceholder]="'入力してください'"> </textarea>
          <p class="text-num pull-right archive-words-number" *ngIf="!layoutService.isTabletDownView.value">
            文字数<span>{{ form.get('archive').value.length }}</span>
          </p>
          <div>
            <div class="spen-mod-error-content" *ngIf="formErrors['archive']">
              {{ formErrors['archive'].join(' ') }}
            </div>
          </div>
          <p class="text-num pull-right archive-words-number" *ngIf="layoutService.isTabletDownView.value">
            文字数<span>{{ form.get('archive').value.length }}</span>
          </p>
          <div class="file-uploader">
            <app-story-file-uploader
              [parentForm]="form"
              [formErrors]="formErrors"
              [attachedFiles]="editStory?.upload_files"
              (eventSelectFile)="onSelectFile($event)"
              (uploadFilesStatus)="onUploadFilesStatus($event)"
              (anyFileExists)="isAttachFile($event)"
            >
            </app-story-file-uploader>
          </div>
        </div>
      </div>
    </div>
    <div class="list-button-submit">
      <button type="button" class="spen-mod-btn" (click)="onBack()">戻る</button>
      <button type="submit" class="spen-mod-btn is-primary" [disabled]="checkBlankData || isSubmitting">保存</button>
    </div>
  </form>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-list-activity-modal
  [showManaVisionOption]="false"
  (eventShowActivity)="eventShowActivity($event)"
  (eventSelectActivityModal)="eventSelectActivityModal($event)"
  *ngIf="showListActivityModal"
>
</app-list-activity-modal>

<app-activity-detail-modal [activityId]="activityId" (eventForBackAction)="eventForBackAction($event)" *ngIf="showActivityDetailModal">
</app-activity-detail-modal>
