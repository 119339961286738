import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { MarksService } from '@services/marks/marks.service';
import { CategoriesService } from '@services/categories/categories.service';
import { WorkService } from '../work.service';

import { Work } from '@components/work/work';
import { Mark } from '@models/mark';
import { WorkTemplate } from '@models/work-template';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const AllowedImageExtensions = ['jpg', 'jpeg', 'gif', 'png'];

@Component({
  selector: 'app-answer-preview-modal',
  templateUrl: './answer-preview-modal.component.html',
  styleUrls: ['./answer-preview-modal.component.scss'],
})
export class AnswerPreviewModalComponent implements OnInit, OnDestroy {
  @Output() eventCloseModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() work: Work;
  @Input() workId: number;
  @Input() workTemplate: WorkTemplate;
  @Input() currentFiles = [];

  @ViewChild('scrollable', { static: true }) scrollable: ElementRef;

  marks: Array<Mark> = [];
  imageFiles = [];
  notImageFiles = [];
  categoryName = '';
  workQuestions = [];
  isCompassWork = false;

  isMarksLoaded = false;
  errMsg: string;
  readonly FILES_PER_LINE = 4;

  constructor(
    private marksService: MarksService,
    private workService: WorkService,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit() {
    disableBodyScroll(this.scrollable.nativeElement);
    this.getData();
  }

  async getData() {
    if (this.workId) {
      this.work = await this.workService.getAnswerPreview(this.workId).toPromise();
    }
    if (this.convertWorkType(this.work.work_type) === 'multiple') {
      this.getWorkQuestions();
    }
    this.getCategoryName();
    if (this.currentFiles.length > 0 || this.work.upload_files) {
      const files = this.currentFiles.length > 0 ? this.currentFiles : this.work.upload_files;
      this.imageFiles = this.getImageFiles(files);
      this.notImageFiles = this.getNotImageFiles(files);
    }
    this.loadMarks();
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        this.isMarksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  convertWorkType(work_type) {
    switch (work_type) {
      case 1:
        return 'single';
      case 2:
        return 'multiple';
      default:
        return work_type;
    }
  }

  getCategoryName() {
    if (this.work.category_id) {
      this.categoriesService.getCategoryName(this.work.category_id).then((categoryName) => (this.work.category_name = categoryName));
    } else {
      this.work.category_name = '';
    }
  }

  getImageFiles(files) {
    return files.filter((file) => this.isImage(file));
  }

  getNotImageFiles(files) {
    return files.filter((file) => !this.isImage(file));
  }

  isImage(fileItem) {
    const fileExtension = !!fileItem.file ? this.getFileExtension(fileItem.file.name) : fileItem.file_extension;
    return AllowedImageExtensions.includes(fileExtension);
  }

  getFileExtension(fileName: string) {
    return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
  }

  showShadowBox(index: number): boolean {
    return this.imageFiles.length > this.FILES_PER_LINE && index === this.FILES_PER_LINE - 1;
  }

  getFileName(item) {
    return item.file ? item.file.name : item.file_name;
  }

  getWorkQuestions() {
    if (this.workTemplate) {
      this.workQuestions = this.workTemplate.work_template_questions_attributes;
      this.isCompassWork = this.workTemplate.is_compass_template;
    } else {
      if (this.workId) {
        this.workQuestions = this.work.work_questions;
      } else {
        this.workQuestions = this.work.work_questions_attributes;
      }
      this.isCompassWork = this.work.is_compass_work;
    }
  }

  dataLoading() {
    return !this.isMarksLoaded;
  }

  closeModal() {
    this.eventCloseModal.emit(false);
  }

  ngOnDestroy() {
    clearAllBodyScrollLocks();
  }
}
