<ng-container *ngIf="layoutService.isTabletDownView.value; else pcView">
  <div class="sp-filter" *ngIf="formLoaded">
    <div class="filter-info">
      <div class="field">
        <div class="label-text">検索条件</div>
        <div class="text break-word" *ngIf="listLoaded">{{ messageFilter }}</div>
      </div>
      <div class="field">
        <div class="label-text">並び順</div>
        <div class="text" *ngIf="listLoaded">{{ messageSort }}</div>
      </div>
    </div>
    <div class="filter-panel">
      <div class="drawers">
        <a class="spen-util-link" (click)="toggleFilter()">
          <span class="">変更</span>
          <i [class]="isMobileFilterOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
        </a>
      </div>
      <form [class.display-none]="!isMobileFilterOpen" [formGroup]="filterForm">
        <div class="filter-inputs">
          <div class="input-group">
            <div class="label-text">校種学年組</div>
            <div class="select-group">
              <app-school-grade-class-select [parentForm]="filterForm"></app-school-grade-class-select>
            </div>
          </div>
          <div class="input-group">
            <div class="label-text">種別</div>
            <label class="spen-mod-select">
              <select class="select" formControlName="album_type" id="album-type-select">
                <option *ngFor="let choice of typeSelections" [ngValue]="choice.value">
                  {{ choice.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
          <div class="input-group">
            <div class="label-text">アルバム名</div>
            <input
              class="spen-mod-input-text"
              placeholder="アルバム名を入力してください"
              type="text"
              formControlName="keyword"
              maxlength="50"
            />
          </div>
          <div class="input-group">
            <div class="label-text">並び順</div>
            <label class="spen-mod-select">
              <select class="select" formControlName="sort" (change)="onChangeSortValue($event.target.value)">
                <option *ngFor="let choice of sortSelections" [value]="choice.value">
                  {{ choice.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>
        <div class="filter-buttons">
          <button class="spen-mod-btn is-primary" type="button" (click)="onSubmit()" [disabled]="isLoadingData">適用する</button>
          <button class="spen-mod-btn is-default" type="button" (click)="onResetFilter()" [disabled]="isLoadingData || isDefaultForm">
            選択解除
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-container *ngIf="listLoaded; else loadingIcon">
    <ng-container *ngIf="!isEmptyList; else emptyList">
      <div class="paginate-result">{{ metaData | paginateText }}</div>
      <div class="album-items-list">
        <div
          class="album-item {{ !!album.album_user.viewer_permission ? 'clickable-item' : 'disabled-item' }}"
          *ngFor="
            let album of albums
              | paginate
                : {
                    itemsPerPage: metaData.per_page,
                    currentPage: metaData.current_page,
                    totalItems: metaData.total_count,
                  }
          "
          (click)="onClickAlbumItem(album, $event)"
        >
          <div class="album-info flex-center">
            <img [src]="album.image" [alt]="album.title" class="album-image object-fit-cover" *ngIf="!!album.image; else defaultImage" />
            <ng-template #defaultImage>
              <img class="album-image default-image" src="/assets/images/album.svg" />
            </ng-template>
            <div class="label-text truncate-text album-title">
              <span>{{ album.title }}</span>
            </div>
          </div>
          <div class="user-info">
            <div class="student-name truncate-text">利用者：{{ album.album_user.name }}</div>
          </div>
          <div class="small-text">活動記録数：{{ album.activity_records_count | number }}</div>
          <div class="muted-text">
            <div>
              作成日時：<span>{{ album.created_at }}</span>
            </div>
          </div>
          <div class="muted-text">
            <div>
              更新日時：<span>{{ album.updated_at }}</span>
            </div>
          </div>
          <i class="fa fa-chevron-right" *ngIf="!!album.album_user.viewer_permission"></i>
        </div>
      </div>

      <div class="spen-mod-paginate" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #pcView>
  <form class="pc-filter filter-panel" [formGroup]="filterForm" *ngIf="formLoaded">
    <div class="filter-inputs">
      <div class="input-group flex-center">
        <div class="label-text">校種学年組</div>
        <app-school-grade-class-select [parentForm]="filterForm"></app-school-grade-class-select>
      </div>
      <div class="input-group">
        <div class="spen-mod-checkbox">
          <span class="label-text">種別</span>
          <label class="spen-mod-radio" *ngFor="let choice of typeSelections">
            <input class="radio" type="radio" [value]="choice.value" formControlName="album_type" />
            <i class="fa"></i><span class="text">{{ choice.text }}</span>
          </label>
          <span class="label-text">アルバム名</span>
          <input
            class="spen-mod-input-text"
            placeholder="アルバム名を入力してください"
            type="text"
            formControlName="keyword"
            maxlength="50"
          />
        </div>
      </div>
    </div>

    <div class="filter-buttons">
      <button class="spen-mod-btn is-default" type="button" [disabled]="isLoadingData || isDefaultForm" (click)="onResetFilter()">
        選択解除
      </button>
      <button class="spen-mod-btn is-primary" type="button" [disabled]="isLoadingData" (click)="onSubmit()">検索する</button>
    </div>
  </form>

  <div *ngIf="listLoaded; else loadingIcon">
    <div class="table-info clearfix">
      <span class="filter-info break-word" *ngIf="haveAlbumsInDB || isSearching">検索条件：{{ messageFilter }}</span>
      <span class="paginate-result" *ngIf="!isEmptyList && !isSorting">{{ metaData | paginateText }}</span>
    </div>

    <div *ngIf="!isEmptyList; else emptyList" class="spen-mod-flex-table table-list-full has-link">
      <div class="flex-table-head">
        <div class="flex-item col-size-4 is-first-col">アルバム名</div>
        <div class="flex-item col-size-3">利用者</div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" (click)="onChangeOrder('created_at')">
            <span>作成日時</span>
            <i [class]="sortStatus.created_at"></i>
          </a>
        </div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" (click)="onChangeOrder('updated_at')">
            <span>更新日時</span>
            <i [class]="sortStatus.updated_at"></i>
          </a>
        </div>
        <div class="flex-item col-size-3 pull-right">
          <a class="spen-util-link" (click)="onChangeOrder('activity_records_count')">
            <span>活動記録数</span>
            <i [class]="sortStatus.activity_records_count"></i>
          </a>
        </div>
        <div class="flex-item redirect-cell"></div>
      </div>
      <a
        class="flex-table-row"
        *ngFor="
          let album of albums
            | paginate
              : {
                  itemsPerPage: metaData.per_page,
                  currentPage: metaData.current_page,
                  totalItems: metaData.total_count,
                }
        "
        [class.disabled-item]="!album.album_user.viewer_permission"
        (click)="onClickAlbumItem(album, $event)"
      >
        <div class="flex-item col-size-4 is-first-col truncate-text">
          <div class="flex-center">
            <img [src]="album.image" [alt]="album.title" class="album-image object-fit-cover" *ngIf="!!album.image; else defaultImage" />
            <ng-template #defaultImage>
              <img class="album-image default-image" src="/assets/images/album.svg" />
            </ng-template>
            <span class="album-title truncate-text">{{ album.title }}</span>
          </div>
        </div>
        <div class="flex-item col-size-3 flex-center">
          <img [src]="album.album_user.avatar" class="circle-img pull-left" />
          <div class="truncate-text">
            <a
              class="student-name"
              [class.spen-util-link]="!!album.album_user.viewer_permission"
              (click)="onClickStudentName(album, $event)"
            >
              {{ album.album_user.name }}
            </a>
            <p class="small-text truncate-text">{{ album.album_user.class_name }}{{ album.album_user.attendance_number }}番</p>
          </div>
        </div>
        <div class="flex-item col-size-3">{{ album.created_at }}</div>
        <div class="flex-item col-size-3">{{ album.updated_at }}</div>
        <div class="flex-item col-size-3 pull-right">{{ album.activity_records_count | number }}</div>
        <div class="flex-item redirect-cell is-icon-item">
          <i *ngIf="!!album.album_user.viewer_permission" class="fa fa-chevron-right"></i>
        </div>
      </a>
    </div>
  </div>

  <div class="spen-mod-paginate" *ngIf="showPagination">
    <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
  </div>
</ng-template>

<div class="guide" *ngIf="listLoaded && !isEmptyList">
  <div class="muted-text">選択できない項目は、[設定・登録]の[閲覧権限]ならびに[先生情報]の設定により、閲覧が制限されています。</div>
  <div class="muted-text">※閲覧権限の変更は、校内のClassi管理責任者 / 管理者の先生にご確認ください。</div>
</div>

<ng-template #emptyList>
  <div class="null-content">
    {{ haveAlbumsInDB || isSearching ? '検索結果が0件です。' : 'ここに校内のアルバムが表示されます。' }}
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
