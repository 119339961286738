import { Component } from '@angular/core';
import { AuthUserService } from '@services/auth-user.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  constructor(public authUserService: AuthUserService) {}
}
