import { Component, Output, EventEmitter, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { WordCloudService } from '@services/word-cloud/word-cloud.service';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-word-cloud-modal',
  templateUrl: './word-cloud-modal.component.html',
  styleUrls: ['./word-cloud-modal.component.scss'],
})
export class WordCloudModalComponent implements OnInit, OnDestroy {
  @Output() closeFilter = new EventEmitter<any>();
  title: String;
  imageUrl: SafeUrl;

  constructor(
    private renderer: Renderer2,
    private wordCloudService: WordCloudService,
  ) {}

  ngOnInit() {
    this.getTitle();
    this.getImageUrl();
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  getTitle(): void {
    this.title = this.wordCloudService.modalTitle;
  }

  getImageUrl(): void {
    this.imageUrl = this.wordCloudService.modalImageUrl;
  }

  onClose() {
    this.closeFilter.emit();
  }
}
