<div class="questionnaire-detail">
  <div class="question-answer" data-cy="questionnaire-item" *ngFor="let question of questionnaire.questions; let i = index">
    <div class="label-title position-question" data-cy="questionnaire-num">設問{{ i + 1 }}</div>
    <div class="block-element">
      <p class="question-content" data-cy="questionnaire-content" *ngIf="!isReadMore; else readMoreTextQuestion">{{ question.text }}</p>

      <ng-template #readMoreTextQuestion>
        <app-read-more [content]="question.text" [collapsedLength]="149"></app-read-more>
      </ng-template>

      <img class="question-image" data-cy="questionnaire-image" src="{{ question.src }}" *ngIf="question.src" />
      <p class="label-title answer-info">回答</p>

      <ng-container *ngFor="let answer of listAnswers[i]">
        <ng-container *ngFor="let answerOption of answer['answer_options']">
          <div *ngIf="answerOption.text">
            <p class="preserve-line-break" data-cy="questionnaire-answer" *ngIf="!isReadMore; else readMoreTextAnswer">
              {{ answerOption.text }}
            </p>

            <ng-template #readMoreTextAnswer>
              <app-read-more [content]="answerOption.text" [collapsedLength]="149"></app-read-more>
            </ng-template>
          </div>
          <img class="answer-image" data-cy="questionnaire-answer-image" src="{{ answerOption.src }}" *ngIf="answerOption.src" />
        </ng-container>

        <div class="block-attached">
          <div class="row">
            <div class="image-attached" *ngIf="answer.attached" (window:resize)="resize()" #upload_div>
              <div class="attach" (click)="onFileClick(answer)">
                <div class="attach-file" *ngIf="isShowThumbnail(answer.attached_file_name); else showFileIcon">
                  <img [src]="answer.attached" />
                </div>

                <ng-template #showFileIcon>
                  <div class="file-format"><i [ngClass]="answer.attached_file_name | fileIconClass"></i></div>
                </ng-template>

                <a class="action-file" *ngIf="showDownloadIcon(answer)">
                  <i class="fa fa-download"></i>
                </a>
              </div>
              <p class="file-name" (click)="onFileClick(answer)">{{ answer.attached_file_name }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="category-text" *ngIf="!!categoryName">
    {{ categoryName }}
  </div>

  <app-questionnaire-file-preview></app-questionnaire-file-preview>
</div>
