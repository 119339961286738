<a class="list-item-info">
  <i class="fa fa-file-text-o"></i>
  <div class="text-info">
    <p class="pick-up" *ngIf="activityRecord.has_active_pickup">
      <img src="assets/images/icons/pickup.svg" />
      <label>先生のピックアップ</label>
    </p>
    <p class="student-name{{ activityRecord.has_active_pickup ? '-pickup' : '' }}">
      <span>{{ userNameDisplay }}</span>
    </p>
    <p class="activity-name">{{ activityRecord.title }}</p>
    <span *ngIf="!!activityRecord.category_name" class="document-category">{{ activityRecord.category_name }}</span>
  </div>
</a>
<a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
