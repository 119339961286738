import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormActivityPickupService } from '../form-activity-pickup.service';
import { AuthUserService } from '@services/auth-user.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { ActivityPickupService } from '@components/activity-pickup/activity-pickup.service';
import { LayoutService } from '@components/layout/layout.service';
import { ToastService } from '@services/toast/toast.service';
import { ErrorService } from '@services/error.service';

import { Space } from '@components/space/space';
import { Pickup } from '@models/pickup';

import { Observable } from 'rxjs';
import { validateForm } from '@functions/validate-form';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '@functions/validate-form';
import { currentLocaleDate, ngbDateToString, stringToNgbDate } from '@functions/date-formatter';

@Component({
  selector: 'app-form-activity-pickup-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['../form-activity-pickup.component.scss'],
})
export class Step1Component implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  spaceLoaded = false;
  formErrors: { [key: string]: Array<string> } = {};
  isShowSearchModalSingle = false;
  isShowSearchModalMulti = false;
  validationMessages = {
    space_id: {
      equalForbidValue: 'ピックアップ制限中のスペースのため設定を変更できません。',
      isNumberRequired: '公開先スペースを選択してください。',
    },
    end_time: {
      dateMinimum: '表示終了日に過去日を指定できません。',
    },
    reason: {
      maxlength: '説明は500文字以内で入力してください。',
    },
    space_ids: {
      required: '配信先スペースを選択してください。',
    },
  };
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };
  disabledSubmit = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private authUserService: AuthUserService,
    private formActivityPickupService: FormActivityPickupService,
    private activityPickupService: ActivityPickupService,
    public layoutService: LayoutService,
    private toastService: ToastService,
    private errorService: ErrorService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const previousStep = this.formActivityPickupService.previousStep;
    if (previousStep === 2) {
      this.createPickupForm(this.formActivityPickupService.activityPickup, true);
      this.spaceLoaded = true;
    } else {
      const pickupId = this.route.snapshot.params.id;
      if (!!pickupId) {
        this.formActivityPickupService.editActivityPickupId = +pickupId;
        this.loadPickupDetail(this.formActivityPickupService.editActivityPickupId);
      } else {
        this.initNewPickup();
      }
    }
  }

  initNewPickup() {
    const activityRecordId = +this.route.snapshot.queryParams.activity_record_id;
    if (!activityRecordId) {
      this.router.navigateByUrl('/404');
      return;
    }

    this.createPickupForm(
      new Pickup({
        is_anonymous: false,
        start_time: ngbDateToString(this.formActivityPickupService.startTime),
        end_time: ngbDateToString(this.formActivityPickupService.endTime),
      }),
    );

    this.formActivityPickupService.activityRecordId = activityRecordId;
    this.spaceLoaded = true;
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  loadPickupDetail(pickupId: number) {
    this.activityPickupService.getActivityPickupEdit(pickupId).subscribe(
      (response) => {
        this.formActivityPickupService.activityPickup = response;

        if (this.authUserService.retrieve().id !== this.formActivityPickupService.activityPickup.create_user_id) {
          this.router.navigateByUrl('/403');
        }

        this.formActivityPickupService.startTime = stringToNgbDate(response.start_time);
        this.formActivityPickupService.endTime = stringToNgbDate(response.end_time);
        this.formActivityPickupService.activityRecordId = response.activity_record_id;
        this.formActivityPickupService.originalSpaceEditId = this.formActivityPickupService.activityPickup.space_id;
        this.loadSpaceDetail(this.formActivityPickupService.activityPickup.space_id);
        this.createPickupForm(this.formActivityPickupService.activityPickup);
      },
      (error) => {
        this.errorService.addError(error);
      },
    );
  }

  loadSpaceDetail(spaceId: number, activityRecordId?: number) {
    this.activityPickupService.getSpaceDetail(spaceId, activityRecordId).subscribe(
      (response) => {
        if (response.allow_pickup !== false) {
          this.formActivityPickupService.space = response;
          if (!response.teacher_can_pick_up) {
            this.form.get('space_id').setValidators([CustomValidators.mustNotEqual(response.id), CustomValidators.requiredNumber()]);
            this.form.get('space_id').updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.form.get('space_id').markAsDirty();
            this.formErrors = validateForm(this.form, false, this.validationMessages);
            this.scrollToError();
          }
        }
        this.spaceLoaded = true;
      },
      (error) => {
        this.errorService.addError(error);
      },
    );
  }

  createPickupForm(pickup: Pickup, dirty = false) {
    const today = currentLocaleDate();
    today.setHours(0, 0, 0, 0);

    if (this.isEditPickup()) {
      this.form = this.formBuilder.group({
        space_id: [pickup.space_id, CustomValidators.requiredNumber()],
        end_time: [pickup.end_time, CustomValidators.minimumDate(today)],
        is_anonymous: [pickup.is_anonymous],
        reason: [pickup.reason, Validators.maxLength(500)],
      });
    } else {
      const spaceIds = this.formActivityPickupService.spaces.map((space) => space.id);
      this.form = this.formBuilder.group({
        space_ids: [spaceIds, Validators.required],
        end_time: [pickup.end_time, CustomValidators.minimumDate(today)],
        is_anonymous: [pickup.is_anonymous],
        reason: [pickup.reason, Validators.maxLength(500)],
      });
    }

    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.formActivityPickupService.activityPickup = this.form.value;
      this.routerService.disableSetPreviousHref();
      this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
      this.formActivityPickupService.setStep2State();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
      this.scrollToError();
    }
  }

  deletePickup() {
    this.disabledSubmit = true;
    this.confirmDialogService
      .showConfirm({
        title: '取り下げ確認',
        content: 'ピックアップを取り下げると元に戻すことはできません。<br> 本当に取り下げますか。',
        acceptButton: '取り下げる',
        cancelButton: 'キャンセル',
        isDanger: true,
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.activityPickupService.deletePickup(this.formActivityPickupService.editActivityPickupId).subscribe(
            (response) => {
              this.toastService.showToast('削除しました');
              this.routerService.excuseBack();
            },
            (error) => {
              this.errorService.addError(error);
              this.disabledSubmit = false;
            },
          );
        } else {
          this.disabledSubmit = false;
        }
      });
  }

  get dataLoaded(): boolean {
    return this.spaceLoaded;
  }

  isEditPickup(): boolean {
    return !!this.formActivityPickupService.editActivityPickupId;
  }

  goBack() {
    this.routerService.goBack(this.backConfirmMessages);
  }

  findSpace() {
    if (this.isEditPickup()) {
      this.isShowSearchModalSingle = true;
    } else {
      this.isShowSearchModalMulti = true;
    }
  }

  closeSearch() {
    this.isShowSearchModalSingle = false;
    this.isShowSearchModalMulti = false;
  }

  onSelectSpace(space: Space) {
    this.isShowSearchModalSingle = false;
    this.formActivityPickupService.space = space;
    this.form.get('space_id').markAsDirty();
    this.form.get('space_id').clearValidators();
    this.form.get('space_id').setValue(space.id);
  }

  onSelectMultiSpace(spaces) {
    this.isShowSearchModalMulti = false;
    this.formActivityPickupService.spaces = spaces;
    this.setSpacesAttributes();
  }

  removeSpace(space: Space) {
    const removeIndex = this.formActivityPickupService.spaces.findIndex((item) => item.id === space.id);
    this.formActivityPickupService.spaces.splice(removeIndex, 1);

    this.setSpacesAttributes();
  }

  private setSpacesAttributes() {
    this.form.controls['space_ids'].setValue(
      this.formActivityPickupService.spaces.map((s) => {
        return s.id;
      }),
    );
    this.form.controls['space_ids'].markAsDirty();
  }

  updateEndTime(selectedTime: NgbDateStruct) {
    this.formActivityPickupService.endTime = selectedTime;
    this.form.get('end_time').markAsDirty();
    this.form.get('end_time').setValue(ngbDateToString(selectedTime));
  }

  scrollToError() {
    const error = document.getElementById('pickupInvalid');
    if (error) {
      error.scrollIntoView();
    }
  }
}
