<div class="box-filter">
  <form [formGroup]="form" *ngIf="dataLoaded; else loadingIcon">
    <label class="spen-mod-checkbox mana-vision-checkbox" *ngIf="showManaVisionOption">
      <input class="checkbox" type="checkbox" formControlName="include_mana_vision" />
      <i class="fa"></i>
      <span class="text">マナビジョンを含める</span>
    </label>

    <input
      class="spen-mod-input-text"
      [appPlaceholder]="'キーワードを入力してください'"
      formControlName="keyword"
      *ngIf="!includingManaVisions"
    />

    <p class="label-input">カテゴリー</p>
    <app-categories-selection [categories]="categories" formControlName="category_id"> </app-categories-selection>

    <ng-container *ngIf="!includingManaVisions">
      <p class="title-1 label-input">種別</p>
      <label class="spen-mod-select">
        <select class="select" formControlName="activity_type">
          <option class="select-option" [ngValue]="null">すべて</option>
          <option class="select-option" value="free">自主作成</option>
          <option class="select-option" value="answer">課題回答</option>
          <option class="select-option" value="questionnaire">アンケート回答</option>
        </select>
        <i class="fa fa-caret-down"></i>
      </label>

      <app-marks-selector (eventChangeMark)="eventChangeMark($event)" [marks]="marks"></app-marks-selector>

      <p class="label-input">コメント状況</p>
      <div class="drop-downs">
        <label class="spen-mod-select">
          <select class="select" formControlName="comment_status">
            <option class="select-option" [ngValue]="null">すべて</option>
            <option class="select-option" value="has_comment">コメントあり</option>
            <option class="select-option" value="no_comment">コメントなし</option>
          </select>
          <i class="fa fa-caret-down"></i>
        </label>
        <label class="spen-mod-select select-child-comment-status">
          <select class="select" formControlName="sub_comment_status">
            <option class="select-option" value="has_comment">すべて</option>
            <option class="select-option" value="cross">生徒コメント</option>
            <option class="select-option" value="teacher">先生コメント</option>
          </select>
          <i class="fa fa-caret-down"></i>
        </label>
      </div>
    </ng-container>
  </form>
  <div class="action-filter">
    <span class="spen-util-link" (click)="onCloseFilter()">検索を閉じる</span>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
