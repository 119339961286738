import { Component, OnInit, Input } from '@angular/core';
import { Action } from '@components/timeline/action';
import { timeStringToDateString } from '@functions/date-formatter';
import { StudentService } from '@components/student/student.service';

import { omit } from 'lodash';

@Component({
  selector: 'app-student-timeline',
  templateUrl: './student-timeline.component.html',
  styleUrls: ['./student-timeline.component.scss'],
})
export class StudentTimelineComponent implements OnInit {
  @Input() studentId: number;

  contentLoaded = false;
  activitiesMap = {};
  actionsLoading = false;
  meta = {};
  current_page = 1;

  constructor(private studentService: StudentService) {}

  ngOnInit() {
    this.getActions();
  }

  getActions(params = {}) {
    if (this.actionsLoading) {
      return;
    }
    this.actionsLoading = true;
    this.studentService.getStudentDetailActions(this.studentId, params).subscribe(
      (response) => {
        this.updateActivitiesMap(response.actions, this.activitiesMap);
        this.meta = response.meta;
        this.contentLoaded = true;
        this.actionsLoading = false;
      },
      (error) => {
        // TODO: error handling.
      },
    );
  }

  get activitiesDates(): string[] {
    return Object.keys(this.activitiesMap);
  }

  updateActivitiesMap(actions: Array<Action> = [], activitiesMap = {}): void {
    actions.forEach((action) => {
      const createTime = timeStringToDateString(action.last_created_at);

      if (!activitiesMap[createTime]) {
        activitiesMap[createTime] = [];
      }

      activitiesMap[createTime].push(action);
    });
  }

  loadMore() {
    if (this.actionsLoading) {
      return;
    }

    this.getActions(Object.assign(omit(this.meta, 'next_action_date'), { current_page: ++this.current_page }));
  }
}
