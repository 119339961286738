<div class="block-standard" data-cy="pickup-item">
  <div class="block-header">
    <div class="info">
      <div class="teacher-info">
        <img class="avatar" data-cy="pickup-avatar" [src]="pickup.teacher_avatar" />
        <p class="truncate-text">{{ pickup.teacher_name }}</p>
        <p>先生</p>
      </div>

      <div class="next-icons" *ngIf="!layoutService.isTabletDownView.value">
        <i class="fa fa-angle-right"></i><i class="fa fa-angle-right"></i><i class="fa fa-angle-right"></i>
      </div>

      <p class="space-name" data-cy="pickup-space-name" *ngIf="pickupExpired(); else spaceLink">
        <img class="pickup-icon" src="assets/images/icons/pickup.svg" />
        <span class="truncate-text">{{ pickup.space_name }}</span>
      </p>
    </div>

    <a class="spen-util-link edit-button" data-cy="pickup-edit" (click)="editPickup()" *ngIf="canEditPickup()">
      <i class="fa fa-pencil"></i>変更・削除
    </a>
  </div>

  <div class="time">
    <span data-cy="pickup-period">期間：{{ pickup.start_time | date: 'yyyy/MM/dd' }}〜{{ pickup.end_time | date: 'yyyy/MM/dd' }}</span>
    <span class="anonymous" data-cy="pickup-is-anonymous">{{ pickup.is_anonymous ? '匿名にする' : '匿名にしない' }}</span>
  </div>
  <div class="reason preserve-line-break" *ngIf="!!this.pickup.reason" appConvertLink>{{ this.pickup.reason }}</div>
</div>

<ng-template #spaceLink>
  <a class="space-name spen-util-link" data-cy="pickup-space-name" [routerLink]="['/spaces/teacher', pickup.space_id]">
    <img class="pickup-icon" src="assets/images/icons/pickup-active.svg" />
    <span class="truncate-text">{{ pickup.space_name }}</span>
  </a>
</ng-template>
