<div class="filter">
  <div class="spen-ly-modal spen-ly-static" id="modal">
    <div class="modal-dialog">
      <div class="inner">
        <div class="modal-heading">
          <span class="top-btn-close" (click)="closeSelectActivityModal()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
          <h2 class="heading-text">既存の活動記録</h2>
        </div>

        <div
          class="modal-content"
          infinite-scroll
          infiniteScrollDistance="1"
          infiniteScrollThrottle="200"
          [infiniteScrollDisabled]="!nextPage"
          [scrollWindow]="false"
          (scrolled)="onLoadMore()"
          *ngIf="dataLoaded(); else loadingIcon"
        >
          <div class="spen-util-link block-search" *ngIf="!isShowFilter; else boxFilter" (click)="showFilter()">
            <i class="fa fa-search"></i>検索
          </div>
          <ng-template #boxFilter>
            <app-dropdown-filter-activities (closeFilter)="closeFilter()" (submitFilter)="doFilter($event)">
            </app-dropdown-filter-activities>
          </ng-template>

          <div class="activities">
            <div class="info-count">{{ total_records }}件</div>
            <div class="space-flex-list">
              <ul>
                <li
                  class="list-item"
                  (click)="handleClickActivity(activityRecord)"
                  *ngFor="let activityRecord of activities; first as isFirst"
                  [class.is-first]="isFirst"
                >
                  <app-student-activity-item [activityRecord]="activityRecord"></app-student-activity-item>
                </li>
              </ul>
            </div>
          </div>
          <div class="spen-mod-flex-loading" *ngIf="isLoading">
            <div class="loading-icon"></div>
          </div>
        </div>
        <ng-template #loadingIcon>
          <div class="modal-content">
            <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
