import { Component, HostListener, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { LayoutService } from '@components/layout/layout.service';

import { Router } from '@angular/router';
import { Action } from '@components/timeline/action';
import { ActionUser } from '@components/timeline/action-user';
import { ControlLinkService } from '@services/control-link/control-link.service';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss'],
})
export class ActionItemComponent implements OnInit, OnDestroy {
  @Input() action: Action;
  @Input() isLast: boolean;
  actionTextInfo: string;
  actionSubjectName: string;
  layoutServiceSubscription: Subscription;

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    public sanitized: DomSanitizer,
    private controlLinkService: ControlLinkService,
  ) {}

  ngOnInit() {
    this.layoutServiceSubscription = this.layoutService.isTabletDownView.subscribe((isTabletView) => {
      this.actionTextInfo =
        this.buildUserLink(this.action.last_create_user, { disableLink: isTabletView }) + this.buildActionMessage(isTabletView);
    });

    this.actionSubjectName = this.buildSubjectName();
  }

  onSubjectClick(event: KeyboardEvent) {
    if (!this.canViewSubject) {
      return;
    }

    let url = '';

    switch (this.action.action_type) {
      case 'create_activity_record':
      case 'link_questionnaire':
      case 'public_activity':
      case 'comment_activity_record':
      case 'pickup':
        url = `/activity-records/teacher/${this.action.subject_id}`;
        break;
      case 'delivery_work':
      case 'answer_work':
        const spaceId = this.action.spaces[0]['id'];
        const activityRecordId = this.action.activity_record_id;
        url = activityRecordId
          ? `/activity-records/teacher/${activityRecordId}`
          : `/works/teacher/delivery/${this.action.subject_id}?space_id=${spaceId}`;
        break;
      case 'create_album':
        if (this.isCreateByTeacher) {
          url = `/albums/teacher/delivery/${this.action.subject_id}`;
        } else {
          url = `/albums/teacher/${this.action.subject_id}/students/${this.action.last_create_user.id}`;
        }
        break;
      case 'used_album':
        url = `/albums/teacher/${this.action.subject_id}/students/${this.action.last_create_user.id}`;
        break;
      case 'create_story':
      case 'update_story':
        url = `/stories/teacher/students/${this.action.last_create_user.id}?tab=${this.storyGradeValue}#${this.action.subject_id}`;
        break;
      case 'comment_story':
        url = `/stories/teacher/students/${this.action.subject_user.id}?tab=${this.storyGradeValue}#comments`;
        break;
      case 'create_graduation_message':
        url = `/graduation-messages/teacher/${this.action.subject_id}`;
        break;
    }

    this.controlLinkService.open(url, event);
  }

  get actionIcon(): string {
    const source = 'assets/images/icons/timeline/';
    switch (this.action.action_type) {
      case 'create_activity_record':
      case 'link_questionnaire':
      case 'public_activity':
      case 'comment_activity_record':
      case 'pickup':
        return source + 'activity_record.svg';
      case 'delivery_work':
      case 'answer_work':
        return source + 'delivery_work.svg';
      case 'create_album':
        return source + (this.isCreateByTeacher ? 'delivery_album.svg' : 'album.svg');
      case 'used_album':
        return source + 'delivery_album.svg';
      case 'create_story':
      case 'update_story':
      case 'comment_story':
        return source + 'story.svg';
      case 'create_graduation_message':
        return source + 'graduation_message.svg';
    }
  }

  buildActionMessage(disableLink = true): string {
    let userName = '';

    switch (this.action.action_type) {
      case 'create_activity_record':
        return 'が活動記録を作成しました。';
      case 'link_questionnaire':
        return 'が振り返りアンケートに回答しました。';
      case 'public_activity':
        return 'が活動記録を公開しました。';
      case 'comment_activity_record':
        userName = this.buildUserLink(this.action.subject_user, { disableLink: disableLink });
        return `${this.implementerNumberText}が${userName}の活動記録にコメントしました。`;
      case 'pickup':
        userName = this.buildUserLink(this.action.subject_user, { disableLink: disableLink });
        return `が${userName}の活動記録をピックアップしました。`;
      case 'delivery_work':
        return 'が課題を配信しました。';
      case 'answer_work':
        return `${this.implementerNumberText}が課題に回答しました。`;
      case 'create_album':
        return this.isCreateByTeacher ? 'がアルバムを配信しました。' : 'がアルバムを作成しました。';
      case 'used_album':
        return `${this.implementerNumberText}がアルバムを利用開始しました。`;
      case 'create_story':
        return 'がストーリーを作成しました。';
      case 'update_story':
        return 'がストーリーを更新しました。';
      case 'comment_story':
        userName = this.buildUserLink(this.action.subject_user, { disableLink: disableLink });
        return `${this.implementerNumberText}が${userName}のストーリーに先生からのひとことを投稿しました。`;
      case 'create_graduation_message':
        return 'が卒業メッセージを配信しました。';
    }
  }

  // options: disableLink: boolean, className: string
  buildUserLink(user: ActionUser, options = { disableLink: false }) {
    const userName = this.sanitized.sanitize(SecurityContext.HTML, user.name);
    return `<a data-cy="subject-user" redirect_user_id="${user.viewer_permission ? user.id : null}"
      class="${user.viewer_permission && !options['disableLink'] ? 'spen-util-link' : 'disable-link'}"
      >${userName}</a>${user.user_type_id === 1 ? '先生' : '（' + user.class_info + '）さん'}`;
  }

  get isCreateByTeacher(): boolean {
    return this.action.last_create_user.user_type_id === 1;
  }

  get canViewSubject() {
    switch (this.action.action_type) {
      case 'create_activity_record':
      case 'link_questionnaire':
      case 'public_activity':
      case 'create_story':
      case 'update_story':
        return this.action.last_create_user.viewer_permission;
      case 'comment_activity_record':
      case 'pickup':
      case 'comment_story':
        return this.action.subject_user.viewer_permission;
      case 'create_album':
        return this.isCreateByTeacher || this.action.last_create_user.viewer_permission;
      default: // delivery_work, answer_work, used_album, create_graduation_message
        return true;
    }
  }

  get implementerNumberText(): string {
    return this.action.create_user_count > 1 ? `他${this.action.create_user_count - 1}名` : '';
  }

  onSpaceClick(spaceId) {
    const targetUrl = `spaces/teacher/${spaceId}`;
    if (this.router.url.includes(targetUrl)) {
      return;
    }

    this.router.navigateByUrl(targetUrl);
  }

  ngOnDestroy() {
    if (this.layoutServiceSubscription) {
      this.layoutServiceSubscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event.target', '$event'])
  onStudentClick(target, event: KeyboardEvent) {
    if (this.layoutService.isTabletDownView.value) {
      return;
    }

    const studentId = target.getAttribute('redirect_user_id');
    if (!+studentId) {
      return;
    }

    const targetUrl = `students/teacher/${studentId}`;
    if (this.router.url.includes(targetUrl)) {
      return;
    }

    this.controlLinkService.open(targetUrl, event);
  }

  private get storyGradeValue(): string {
    return this.action.story_grade == null ? 'summary' : `grade_${this.action.story_grade}`;
  }

  private get storyGradeName(): string {
    return this.action.story_grade == null ? 'まとめ' : `${this.action.story_grade}年`;
  }

  private buildSubjectName(): string {
    switch (this.action.action_type) {
      case 'create_story':
      case 'update_story':
        return `${this.storyGradeName}/${this.action.subject_name}`;
      case 'comment_story':
        return `ストーリー/${this.storyGradeName}（${this.action.subject_user.name}）`;
      default:
        return this.action.subject_name;
    }
  }
}
