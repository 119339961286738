import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserService } from '../services/auth-user.service';

export const canLoad = async (): Promise<boolean> => {
  const authUserService = inject(AuthUserService);
  const router = inject(Router);

  const isAuthenticated = await authUserService.isAuthenticated();
  if (!isAuthenticated) return false;

  if (authUserService.isStudent()) {
    return true;
  } else {
    router.navigateByUrl('/timeline/teacher');
    return false;
  }
};

export const canActivate = async (): Promise<boolean> => {
  const authUserService = inject(AuthUserService);
  const router = inject(Router);

  if (authUserService.isStudent()) {
    return true;
  } else {
    await router.navigate(['/']);
    return false;
  }
};

export const canActivateChild = async (): Promise<boolean> => {
  const authUserService = inject(AuthUserService);
  const router = inject(Router);

  if (authUserService.isStudent()) {
    return true;
  } else {
    await router.navigate(['/']);
    return false;
  }
};
