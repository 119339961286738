import { Directive, ElementRef, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appAutoExpand]',
})
export class AutoExpandDirective implements AfterContentChecked {
  constructor(public element: ElementRef) {}

  ngAfterContentChecked(): void {
    this.adjust();
  }

  adjust(): void {
    this.element.nativeElement.style.overflow = 'hidden';
    this.element.nativeElement.style.height = null;
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
  }
}
