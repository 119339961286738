<div class="tea-space-detail" *ngIf="spaceLoaded; else loadingIcon">
  <app-space-detail-info [spaceDetail]="spaceDetail"></app-space-detail-info>
  <div class="tab-view">
    <app-tab-view [tabOptions]="tabOptions"></app-tab-view>
  </div>
  <div class="detail-content">
    <ng-container [ngSwitch]="currentTab">
      <ng-container *ngSwitchCase="'works'">
        <app-space-delivered-works></app-space-delivered-works>
      </ng-container>
      <ng-container *ngSwitchCase="'albums'">
        <app-space-albums [spaceMemberNumber]="spaceDetail.users_count"></app-space-albums>
      </ng-container>
      <ng-container *ngSwitchCase="'timeline'">
        <app-space-timeline></app-space-timeline>
      </ng-container>
      <ng-container *ngSwitchCase="'activity_records'">
        <app-space-public-activity-records></app-space-public-activity-records>
      </ng-container>
      <ng-container *ngSwitchCase="'graduation_message'">
        <app-space-graduation-message></app-space-graduation-message>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
