<div class="spen-ly-modal" id="modal">
  <div class="modal-dialog">
    <div class="space-inner">
      <div class="modal-heading">
        <a *ngIf="layoutService.isTabletDownView.value" (click)="onClose()"><i class="fa fa-times"></i></a>
        <h2 class="heading-text">{{ labelConstants.title }}</h2>
      </div>

      <div class="scrollable-modal-content">
        <div class="modal-content space-has-footer">
          <div
            class="content-inner space-modal-content"
            infinite-scroll
            infiniteScrollDistance="2"
            infiniteScrollThrottle="200"
            [infiniteScrollDisabled]="isLoadedAllDatas"
            [scrollWindow]="false"
            (scrolled)="onLoadMore()"
          >
            <div class="modal-search">
              <label class="space-search-box">
                <input
                  class="spen-mod-input-text"
                  data-cy="search-text"
                  type="text"
                  [placeholder]="layoutService.isTabletDownView.value ? labelConstants.placeHolderSP : labelConstants.placeHolder"
                  [(ngModel)]="query"
                  (keydown.enter)="onSearch($event)"
                />
              </label>
              <button class="spen-mod-btn btn-search" data-cy="submit-filter" (click)="onSearch($event)">
                {{ layoutService.isTabletDownView.value ? '検索' : '検索する' }}
              </button>
            </div>
            <div class="spen-mod-error-content error-popup" data-cy="error-message" *ngIf="isShowError">メンバーを選択してください。</div>
            <div class="spen-mod-flex-table flex-table">
              <div class="flex-table-head" [style.border-bottom]="isEmptySearchResult && !isLoading ? 'none' : ''">
                <div class="flex-item">
                  <label class="spen-mod-checkbox space-checkbox" data-cy="check-user-all">
                    <input
                      class="checkbox"
                      name="radio1"
                      type="checkbox"
                      [checked]="false"
                      [formControl]="selectAll"
                      (change)="onSelectAll($event)"
                      [attr.disabled]="isEmptySearchResult || spaceFilterService.isCheckedAll ? 'disabled' : null"
                    />
                    <i class="fa" [class.disabled-checkbox]="isEmptySearchResult || spaceFilterService.isCheckedAll"></i>
                    <span>{{ labelConstants.tableTitle }}</span>
                    <span></span>
                  </label>
                </div>
              </div>
              <div
                class="flex-table-row"
                *ngFor="let object of groupUserResultsSub | async"
                [class.has-hover-effect]="!layoutService.isTabletDownView.value && !object['disabled']"
              >
                <div class="flex-item">
                  <label
                    class="spen-mod-checkbox"
                    data-cy="check-group"
                    [class.is-disabled]="object['disabled']"
                    (click)="onChangeGroup(object)"
                  >
                    <input class="checkbox" name="radio1" type="checkbox" disabled [checked]="object['checked'] && !object['disabled']" />
                    <i class="fa"></i>
                    <div class="info-modal">
                      <i class="fa fa-users"></i>
                      <!-- TODO: update later when group search results return avatar. -->
                      <!-- <img src="{{object.avatar}}" alt="avatar"> -->
                      <div class="text" [style.flex-direction]="'column'">
                        <div class="user-name">{{ object.name || '無題' }}</div>
                        <div class="grade-info truncate-text">{{ labelConstants.role }}{{ object['members'].length | number }}名</div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div
                class="flex-table-row"
                *ngFor="let object of singleUserResultsSub | async"
                [class.has-hover-effect]="!layoutService.isTabletDownView.value && !object['disabled']"
              >
                <div class="flex-item">
                  <label
                    class="spen-mod-checkbox"
                    data-cy="check-user"
                    [class.is-disabled]="object['disabled']"
                    (click)="onChangeUser(object)"
                  >
                    <input class="checkbox" name="radio1" type="checkbox" disabled [checked]="object['checked'] && !object['disabled']" />
                    <i class="fa"></i>
                    <div class="info-modal">
                      <img src="{{ object.avatar }}" alt="avatar" />
                      <div class="text" [style.flex-direction]="!isTeacher ? 'column' : ''">
                        <span class="user-name">{{ object.name }}</span>
                        <span class="self" *ngIf="isTeacher && creatorId === object.id">（作成者）</span>
                        <div class="grade-info truncate-text" *ngIf="!isTeacher && object.className">
                          {{ object.className }}{{ object.attendanceNumber }}番
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="spen-mod-flex-loading" *ngIf="isLoading">
                <div class="loading-icon"></div>
              </div>
            </div>
          </div>
          <div class="spen-mod-flex-loading loading-select-all" *ngIf="isLoadingSelectAll">
            <div class="loading-icon"></div>
          </div>
        </div>
        <div class="modal-footer space-modal-footer">
          <div class="modal-actions is-right-actions space-modal-action">
            <div class="selection-text">{{ countSelectedLabel() }}</div>
            <div class="buttons">
              <button class="spen-mod-btn is-default js-close-modal-trigger" (click)="onClose()">キャンセル</button>
              <button
                type="button"
                class="spen-mod-btn is-primary"
                data-cy="add-filter"
                (click)="onSubmit()"
                [attr.disabled]="isLoading ? true : null"
              >
                追加する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
