import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { Space } from '@components/space/space';

import { ActivityRecordService } from '@components/activity-record/activity-record.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { LayoutService } from '@components/layout/layout.service';
import { NextPageParams } from '@components/activity-record/activity-record-detail/student/publish-activity-modal/spaces-publish-response';

import * as _ from 'lodash';

@Component({
  selector: 'app-publish-activity-modal',
  templateUrl: './publish-activity-modal.component.html',
  styleUrls: ['./publish-activity-modal.component.scss'],
})
export class PublishActivityModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() activityRecordId: number;
  @Input() publishedSpaces: Array<Space>;
  @Output() eventClosePublishActivityModal = new EventEmitter();
  @Output() eventPublishedToSpaces = new EventEmitter<Array<Space>>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('modalContent') modalContent: ElementRef;

  searchedResults: Array<Space> = new Array<Space>();
  selectingSpaces: Array<Space> = new Array<Space>();
  deletedSpaces: Array<Space> = new Array<Space>();
  nextPageParams: NextPageParams;

  isConfirming = false;
  isSubmitting = false;
  nextUrl: string;
  totalCount = 0;
  isLoading = false;
  errMsg: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private activityRecordService: ActivityRecordService,
    private confirmDialogService: ConfirmDialogService,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.selectingSpaces = this.publishedSpaces.slice();
    this.onSearch();
  }

  ngAfterViewInit() {
    this.resizeModalContent();
  }

  resizeModalContent() {
    if (!this.layoutService.isMobileView.value) {
      return;
    }
    this.modalContent.nativeElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch() {
    if (this.isLoading) {
      return;
    }

    this.nextPageParams = null;
    this.isLoading = true;
    this.nextUrl = null;
    this.totalCount = 0;
    this.searchedResults = [];
    const params = {
      name: !!this.searchInput ? this.searchInput.nativeElement.value : '',
    };

    this.activityRecordService.getListSpacesPublish(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults = response.spaces;
        this.nextPageParams = response.meta;
        this.totalCount = response.total_count;
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;
    const params = {
      name: this.nextPageParams.name,
      page: this.nextPageParams.next_page,
    };
    this.activityRecordService.getListSpacesPublish(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults.push(...response.spaces);
        this.nextPageParams = response.meta;
        this.totalCount = response.total_count;
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  calculateCheckState(space: Space): boolean {
    return _.some(this.selectingSpaces, { id: space.id });
  }

  onCheck(space, $event) {
    if (!space.student_can_publish && $event.target.checked) {
      $event.target.checked = false;
      return;
    }

    switch ($event.target.checked) {
      case true:
        this.addSpace(space);
        break;
      case false:
        this.removeSpace(space);
        break;
    }
  }

  addSpace(space: Space) {
    this.selectingSpaces.push(space);

    _.remove(this.deletedSpaces, { id: space.id });
  }

  removeSpace(space: Space) {
    _.remove(this.selectingSpaces, { id: space.id });

    if (_.some(this.publishedSpaces, { id: space.id })) {
      this.deletedSpaces.push(space);
    }
  }

  onClose() {
    if (this.isConfirming) {
      this.isConfirming = false;
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    const existedIds = this.publishedSpaces.map((space) => space.id).sort();
    const selectedIds = this.selectingSpaces.map((space) => space.id).sort();

    if (_.isEqual(existedIds, selectedIds)) {
      this.eventClosePublishActivityModal.emit();
      return;
    }

    this.confirmDialogService
      .showConfirm({
        title: '確認',
        content: 'このまま戻ると、設定中の内容は破棄されます。<br> 破棄しますか？',
        acceptButton: '破棄する',
        cancelButton: 'キャンセル',
      })
      .subscribe((obj) => {
        if (obj) {
          this.eventClosePublishActivityModal.emit();
        }
      });
  }

  onSubmit() {
    if (this.isConfirming) {
      this.onPublish();
    } else {
      this.isConfirming = true;
    }
  }

  onPublish() {
    const submitParams = this.buildSubmitParam();
    this.isSubmitting = true;

    this.activityRecordService.studentPublishToSpaces(this.activityRecordId, submitParams).subscribe(
      (response) => {
        this.googleAnalytics4Service.sendEvent('活動記録', '公開設定確認モーダル', '設定を完了する');
        this.eventPublishedToSpaces.emit(this.selectingSpaces);
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  buildSubmitParam() {
    const existedIds = this.publishedSpaces.map((space) => space.id);
    const selectedIds = this.selectingSpaces.map((space) => space.id);
    const submitParams = [];

    this.deletedSpaces.forEach((deleteSpace) => {
      submitParams.push({ id: deleteSpace['public_activity_records_space']['id'], _destroy: true });
    });

    const newIds = _.difference(selectedIds, existedIds);
    newIds.forEach((newId) => submitParams.push({ space_id: newId }));

    return submitParams;
  }

  onTouchmove(event) {
    window.scrollTo(0, 0);
  }
}
