import { Component, Input, ElementRef, ViewChild, AfterViewChecked, OnDestroy } from '@angular/core';
import { UploadFile } from '../../models/upload-file';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements AfterViewChecked, OnDestroy {
  readonly KEYCODES = {
    escape: 27,
    left: 37,
    right: 39,
  };

  @ViewChild('gallery_content') gallery_content: ElementRef;
  @ViewChild('gallery_caption') gallery_caption: ElementRef;
  @ViewChild('gallery_inner') gallery_inner: ElementRef;

  @Input() items: Array<UploadFile>;
  currentItem: UploadFile;

  isShow = false;
  isLoading = false;

  constructor() {}

  ngAfterViewChecked() {
    this.updateContentSize();
  }

  ngOnDestroy() {
    this.unSubscribeEvent();
  }

  onNext(event?: Event) {
    if (!!event) {
      event.stopPropagation();
    }
    if (!this.items || this.items.length <= 1) {
      return;
    }
    const nextPosition = this.items.indexOf(this.currentItem) + 1;
    if (nextPosition >= this.items.length) {
      this.onPreview(this.items[0]);
    } else {
      this.onPreview(this.items[nextPosition]);
    }
  }

  onPrevious(event?: Event) {
    if (!!event) {
      event.stopPropagation();
    }
    if (!this.items || this.items.length <= 1) {
      return;
    }
    const previousPosition = this.items.indexOf(this.currentItem) - 1;
    if (previousPosition < 0) {
      this.onPreview(this.items[this.items.length - 1]);
    } else {
      this.onPreview(this.items[previousPosition]);
    }
  }

  onPreview(file: UploadFile) {
    if (!this.items || this.items.length === 0 || this.items.indexOf(file) === -1) {
      return;
    }
    this.isShow = true;
    this.isLoading = true;
    this.currentItem = file;
    this.subscribeEvent();
  }

  onClose = () => {
    this.isShow = false;
    this.unSubscribeEvent();
  };

  onContentLoad() {
    this.isLoading = false;
  }

  canNavigate() {
    return !!this.items && this.items.length > 1;
  }

  preventCloseOnContent(event: Event) {
    event.stopPropagation();
  }

  private handleKeydown = (e) => {
    if (!this.isShow) {
      return;
    }
    switch (e.keyCode) {
      case this.KEYCODES.escape:
        this.onClose();
        break;
      case this.KEYCODES.left:
        this.onPrevious();
        break;
      case this.KEYCODES.right:
        this.onNext();
        break;
    }
  };

  protected updateContentSize() {
    if (this.isShow) {
      const style = this.gallery_content.nativeElement.style;
      style.width = `${this.gallery_inner.nativeElement.clientWidth}px`;
      style.maxWidth = `${this.gallery_inner.nativeElement.clientWidth}px`;
      style.height = `${this.gallery_inner.nativeElement.clientHeight - this.gallery_caption.nativeElement.clientHeight}px`;
      style.maxHeight = `${this.gallery_inner.nativeElement.clientHeight - this.gallery_caption.nativeElement.clientHeight}px`;
    }
  }

  private subscribeEvent() {
    window.addEventListener('keydown', this.handleKeydown, false);
    window.addEventListener('resize', this.updateContentSize, false);
  }

  private unSubscribeEvent() {
    window.removeEventListener('keydown', this.handleKeydown, false);
    window.removeEventListener('resize', this.updateContentSize, false);
  }
}
