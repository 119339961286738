<div class="space-albums-sp" *ngIf="layoutService.isTabletDownView.value; else spaceAlbumPc">
  <div class="filter">
    <div class="box-info">
      <div class="row-info">
        <p class="label-input">検索条件</p>
        <p class="info-data" data-cy="text-filter">{{ filterService.messageFilter }}</p>
      </div>

      <div class="row-info">
        <p class="label-input">並び順</p>
        <p class="info-data" data-cy="text-sort-order">{{ messageSort }}</p>
      </div>
    </div>

    <div class="box-filter">
      <a class="spen-util-link" (click)="changeShowFilterStatus()">
        <span>変更</span>
        <i [class]="isShowFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
      </a>
      <form [formGroup]="form" *ngIf="isShowFilter">
        <div class="selector-group">
          <div class="form-group">
            <p class="label-input">配信者</p>
            <label class="spen-mod-select">
              <select class="select" data-cy="distributor" formControlName="distributor">
                <option class="select-option" [ngValue]="distributor.formValue" *ngFor="let distributor of distributorOptions">
                  {{ distributor.text }}
                </option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>

          <div class="form-group">
            <p class="label-input">キーワード</p>
            <input
              class="spen-mod-input-text"
              data-cy="search-title"
              placeholder="キーワードを入力してください"
              maxlength="50"
              formControlName="search_key"
            />
          </div>

          <div class="form-group">
            <p class="label-input">並び順</p>
            <label class="spen-mod-select">
              <select class="select" data-cy="sort-order" formControlName="sort_value" (change)="onChangeSortValue($event.target.value)">
                <option class="select-option" value="配信日時（降順）">配信日時（降順）</option>
                <option class="select-option" value="配信日時（昇順）">配信日時（昇順）</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </label>
          </div>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="spen-mod-btn is-primary"
            data-cy="submit-filter"
            (click)="onSubmitFilter()"
            [disabled]="isLoadingData"
          >
            適用する
          </button>
          <button
            type="button"
            class="cancel spen-mod-btn is-default"
            data-cy="remove-filter"
            [disabled]="isDefaultForm || isLoadingData"
            (click)="removeFilter()"
          >
            選択解除
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-container *ngIf="contentLoaded; else loadingIcon">
    <p class="paginate-info" data-cy="text-page-info">{{ pages | paginateText }}</p>

    <div class="list-submission" *ngIf="haveListItem; else noAlbum">
      <div
        class="list-item clickable-item"
        data-cy="album-item"
        [class.last]="last"
        *ngFor="
          let album of albums
            | paginate
              : {
                  itemsPerPage: this.pages.per_page,
                  currentPage: this.pages.current_page,
                  totalItems: this.pages.total_count,
                };
          let last = last
        "
        (click)="onClickAlbum(album)"
      >
        <div class="info">
          <div class="album-name">
            <img
              class="album-cover object-fit-cover"
              data-cy="album-image"
              src="{{ album.image }}"
              *ngIf="!!album.image; else defaultImage"
            />
            <ng-template #defaultImage>
              <img class="default-image" data-cy="album-image" src="/assets/images/album.svg" />
            </ng-template>
            <span class="truncate-text" data-cy="album-title"
              ><b>{{ album.title }}</b></span
            >
          </div>
          <p class="distributor-name truncate-text" data-cy="distributor-name">配信者：{{ album.distributor_name }}</p>
          <p class="small-text" data-cy="album-used_count">利用開始数：{{ album.used_count | number }}/{{ spaceMemberNumber | number }}</p>
          <p class="created-at small-text" data-cy="album-created-at">配信日時：{{ album.created_at }}</p>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>

      <div class="spen-mod-paginate pagination" *ngIf="showPagination">
        <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #spaceAlbumPc>
  <div class="space-albums">
    <form [formGroup]="form" class="filter-form">
      <div class="form-contents">
        <div class="form-group">
          <span class="label">配信者</span>
          <label class="spen-mod-radio distributor-label-item" data-cy="distributor" *ngFor="let distributor of distributorOptions">
            <input class="radio" data-cy="distributor-radio" formControlName="distributor" [value]="distributor.formValue" type="radio" />
            <i class="fa"></i><span class="text">{{ distributor.text }}</span>
          </label>
        </div>
        <div class="form-group">
          <span class="label">配信アルバム名</span>
          <input
            type="text"
            class="spen-mod-input-text"
            data-cy="search-title"
            placeholder="配信アルバム名を入力してください"
            maxlength="50"
            formControlName="search_key"
          />
        </div>
      </div>
      <div class="button-group">
        <button
          type="button"
          class="spen-mod-btn is-default"
          data-cy="remove-filter"
          (click)="removeFilter()"
          [disabled]="isDefaultForm || isLoadingData"
        >
          選択解除
        </button>
        <button type="button" class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmitFilter()" [disabled]="isLoadingData">
          検索する
        </button>
      </div>
    </form>

    <div class="data-info" *ngIf="contentLoaded">
      <p class="filter-info" data-cy="text-filter" *ngIf="isSearching || haveListItem">検索条件：{{ filterService.messageFilter }}</p>
      <p class="page-info" data-cy="text-page-info">{{ pages | paginateText }}</p>
    </div>

    <div class="content">
      <ng-container *ngIf="contentLoaded; else loadingIcon">
        <div class="list-submission" *ngIf="haveListItem; else noAlbum">
          <div class="spen-mod-flex-table table-list-full has-link">
            <div class="flex-table-head">
              <div class="flex-item col-size-7 is-first-col">配信アルバム名</div>
              <div class="flex-item col-size-4">配信者</div>
              <div class="flex-item col-size-3">
                <a class="spen-util-link" (click)="changeBySortColumn('created_at')"
                  >配信日時
                  <i [class]="sortStatus['created_at']" data-cy="col-created-at"></i>
                </a>
              </div>
              <div class="flex-item col-size-2 pull-right">利用開始数</div>
              <div class="flex-item redirect-cell"></div>
            </div>
            <a
              class="flex-table-row"
              data-cy="album-item"
              *ngFor="
                let album of albums
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
              (click)="onClickAlbum(album)"
            >
              <div class="flex-item col-size-7 is-first-col truncate-text album-title">
                <img
                  class="album-cover object-fit-cover"
                  data-cy="album-image"
                  src="{{ album.image }}"
                  *ngIf="!!album.image; else defaultImage"
                />
                <ng-template #defaultImage>
                  <img class="default-image" data-cy="album-image" src="/assets/images/album.svg" />
                </ng-template>
                <p class="truncate-text" data-cy="album-title">{{ album.title }}</p>
              </div>
              <div class="flex-item col-size-4 truncate-text" data-cy="distributor-name">{{ album.distributor_name }}</div>
              <div class="flex-item col-size-3" data-cy="album-created-at">{{ album.created_at }}</div>
              <div class="flex-item col-size-2 pull-right" data-cy="album-used_count">
                {{ album.used_count | number }}/{{ spaceMemberNumber | number }}
              </div>
              <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
            </a>
          </div>

          <div class="spen-mod-paginate pagination" *ngIf="showPagination">
            <app-pagination-controls (pageChange)="pageChanged($event)"></app-pagination-controls>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noAlbum>
  <div class="empty-message">
    {{ isSearching ? '検索結果が0件です。' : 'ここにスペースに配信されたアルバムが表示されます。' }}
  </div>
</ng-template>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading">
    <div class="loading-icon"></div>
  </div>
</ng-template>
