import { Injectable } from '@angular/core';
import { Work } from '../work';

@Injectable()
export class WorkEditorService {
  steps = ['入力', '確認', '完了'];
  previousStep: number;
  currentStep: number;
  work: Work;

  constructor() {
    this.resetData();
  }

  resetData() {
    this.previousStep = null;
    this.currentStep = 1;
  }

  setStep2State() {
    this.currentStep = 2;
    this.previousStep = 1;
  }

  setStep1State() {
    this.currentStep = 1;
    this.previousStep = 2;
  }
}
