<div class="tea-create-album" *ngIf="dataLoaded; else loadingIcon">
  <div class="steps">
    <div class="list-steps">
      <app-steps [steps]="creatorService.steps" [currentStep]="isEditAlbum ? 1 : 2"></app-steps>
    </div>
  </div>

  <div class="form-horizontal">
    <h2 class="group-label">配信するアルバム</h2>
    <div class="form-group">
      <div class="row">
        <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
          <label class="label">配信アルバム名</label>
        </div>
        <div class="col-md-9 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
          <p data-cy="title">{{ creatorService.album?.title }}</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
          <label class="label">説明</label>
        </div>
        <div class="col-md-7 col-xs-12" [class.col-sm-7]="!creatorService.isTabletAndDown()">
          <p class="album-comment" data-cy="comment">{{ enteredComment ? creatorService.album?.comment : '未入力' }}</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row" [style.margin-bottom]="hasAttachedFiles ? '-8px' : 0">
        <div class="col-md-3 col-xs-12 form-element" [class.col-sm-3]="!creatorService.isTabletAndDown()">
          <label class="label">表紙画像</label>
        </div>
        <div class="col-md-9 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
          <div class="file-preview" *ngIf="hasAttachedFiles; else noAttachedFile">
            <app-cover-image-uploader
              [canEdit]="false"
              (uploadFilesStatus)="onUploadFilesStatus($event)"
              [attachedFiles]="creatorService.attachedFiles"
              [uploader]="creatorService.fileUploader"
            >
            </app-cover-image-uploader>
          </div>
          <ng-template #noAttachedFile>未登録</ng-template>
        </div>
      </div>
    </div>

    <h2 class="group-label">配信設定</h2>
    <div class="form-group">
      <div class="row">
        <div class="col-md-3 col-xs-12 form-element label-space" [class.col-sm-3]="!creatorService.isTabletAndDown()">
          <label>配信先スペース</label>
        </div>
        <div class="col-md-9 col-xs-12" [class.col-sm-9]="!creatorService.isTabletAndDown()">
          <div class="class-name">
            <div class="block-color" [style.background-color]="creatorService.space?.color_code"></div>
            <span class="truncate-text" data-cy="space-name">{{ creatorService.space?.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="step2-button-submit">
      <button type="button" class="spen-mod-btn" (click)="goBack()" [disabled]="isSavingAlbum">戻る</button>
      <button type="button" class="spen-mod-btn is-primary" (click)="onSubmit()" [disabled]="isSavingAlbum" data-cy="submit-button">
        {{ isEditAlbum ? '変更完了' : '配信完了' }}
      </button>
    </div>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
