import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { MultipleFilterService } from '@services/filter/multiple-filter.service';
import { Category } from '@models/category';
import { Mark } from '@models/mark';
import { CategoriesService } from '@services/categories/categories.service';
import { MarksService } from '@services/marks/marks.service';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthUserService } from '@services/auth-user.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { checkPresentString } from '@functions/check-string';
import { omitBy, pick, isNil } from 'lodash';

@Component({
  selector: 'app-filter-by-multiple',
  templateUrl: './filter-by-multiple.component.html',
  styleUrls: ['./filter-by-multiple.component.scss'],
})
export class FilterByMultipleComponent implements OnInit, OnDestroy {
  readonly ACTIVITY_TYPES = [
    { value: null, text: 'すべて' },
    { value: 'free', text: '自主作成' },
    { value: 'answer', text: '課題回答' },
    { value: 'questionnaire', text: 'アンケート回答' },
  ];
  readonly COMMENT_STATUSES = [
    { value: null, text: 'すべて' },
    { value: 'has_comment', text: 'コメントあり' },
    { value: 'no_comment', text: 'コメントなし' },
  ];
  readonly SUB_COMMENT_STATUSES = [
    { value: 'has_comment', text: 'すべて' },
    { value: 'cross', text: '生徒コメント' },
    { value: 'teacher', text: '先生コメント' },
  ];
  readonly PUBLIC_STATUSES = [
    { value: null, text: 'すべて' },
    { value: 'shared', text: '公開' },
    { value: 'not_shared', text: '非公開' },
  ];
  readonly SUB_PUBLIC_STATUSES = [
    { value: 'shared', text: 'すべて' },
    { value: 'self_publish', text: '自主公開' },
    { value: 'pickup', text: 'ピックアップ' },
  ];

  @Input() showManaVisionOption = false;
  @Output() submitFilter = new EventEmitter<{}>();

  categories: Array<Category> = [];
  marks: Array<Mark> = [];
  form: UntypedFormGroup;
  errMsg: string;
  filterData = {};
  selectedMarkIds = [];

  categoriesLoaded = false;
  marksLoaded = false;

  @ViewChild(MarksSelectorComponent) marksSelector: MarksSelectorComponent;

  constructor(
    private renderer: Renderer2,
    private categoryService: CategoriesService,
    private marksService: MarksService,
    private filterService: MultipleFilterService,
    private formBuilder: UntypedFormBuilder,
    private googleAnalytics4Service: GoogleAnalytics4Service,
    public authUserService: AuthUserService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.filterData = this.filterService.getFilterData() || {};
    this.initForm();
    this.loadCategories();
    this.loadMarks();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        if (this.filterData['mark_ids']) {
          if (!(this.filterData['mark_ids'] instanceof Array)) {
            this.filterData['mark_ids'] = [this.filterData['mark_ids']];
          }
          this.selectedMarkIds = this.filterData['mark_ids'];
        }
        this.marksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  dataLoaded() {
    return this.categoriesLoaded && this.marksLoaded;
  }

  closeFilter() {
    this.filterService.isShowingFilter = false;
  }

  initForm() {
    const comment_status = this.filterData['comment_status'] || null;
    const public_status = this.filterData['public_status'] || null;

    this.form = this.formBuilder.group({
      keyword: this.filterData['keyword'],
      category_id: this.filterData['category_id'],
      activity_type: this.filterData['activity_type'],
      comment_status: this.COMMENT_STATUSES.map((comment) => comment.value).includes(comment_status) ? comment_status : 'has_comment',
      sub_comment_status: [
        {
          value: this.SUB_COMMENT_STATUSES.map((sub_comment) => sub_comment.value).includes(comment_status)
            ? comment_status
            : 'has_comment',
          disabled: !this.SUB_COMMENT_STATUSES.map((sub_comment) => sub_comment.value).includes(comment_status),
        },
      ],
      public_status: this.PUBLIC_STATUSES.map((status) => status.value).includes(public_status) ? public_status : 'shared',
      sub_public_status: [
        {
          value: this.SUB_PUBLIC_STATUSES.map((sub_status) => sub_status.value).includes(public_status) ? public_status : 'shared',
          disabled: !this.SUB_PUBLIC_STATUSES.map((sub_status) => sub_status.value).includes(public_status),
        },
      ],
    });
    if (this.showManaVisionOption) {
      const include_mana_vision = this.filterData.hasOwnProperty('include_mana_vision') ? this.filterData['include_mana_vision'] : false;
      this.form.addControl('include_mana_vision', new UntypedFormControl(include_mana_vision));
    }
  }

  onSubmit() {
    let params = omitBy(Object.assign({ page: 1 }, this.form.value), (value) => isNil(value));

    if (this.includingManaVisions) {
      params = pick(params, ['category_id', 'include_mana_vision']);
    } else {
      params['mark_ids'] = this.marksSelector.selectedMarks.filter((mark) => !!mark.checked).map((mark) => mark.id);
      if (!!params['comment_status'] && !!params['sub_comment_status'] && params['sub_comment_status'] !== 'has_comment') {
        params['comment_status'] = params['sub_comment_status'];
      }
      delete params['sub_comment_status'];
      if (!!params['public_status'] && !!params['sub_public_status'] && params['sub_public_status'] !== 'shared') {
        params['public_status'] = params['sub_public_status'];
      }
      delete params['sub_public_status'];
    }

    this.filterService.setFilter(params);
    this.sendGA4Filter();
    this.submitFilter.emit(params);
    this.filterService.isShowingFilter = false;
  }

  async loadCategories() {
    const categories = await this.categoryService.loadCategories();
    if (!!categories) {
      this.categories = categories;
      this.categoriesLoaded = true;
    }
  }

  private async sendGA4Filter(): Promise<void> {
    let subCategory: Category;
    let category = await this.categoryService.getCategory(this.form.value['category_id']);
    if (category.parent_id) {
      subCategory = category;
      category = await this.categoryService.getCategory(category.parent_id);
    }

    const params: { [key: string]: string } = {
      manabision_flag: this.form.value['include_mana_vision'],
      category_name: category.name || 'すべて',
      sub_category_name: subCategory?.name,
    };
    if (!params.manabision_flag) {
      params.type_name = this.ACTIVITY_TYPES.find((activityType) => activityType.value === this.form.value['activity_type']).text;
      params.mark_name = this.marksSelector.selectedMarks
        .filter((mark) => !!mark.checked)
        .map((mark) => mark.name)
        .join(',');
      params.comment_status = this.COMMENT_STATUSES.find((status) => status.value === this.form.value['comment_status']).text;
      params.sub_comment_status = this.SUB_COMMENT_STATUSES.find((status) => status.value === this.form.value['sub_comment_status'])?.text;
      params.publication_status = this.PUBLIC_STATUSES.find((status) => status.value === this.form.value['public_status']).text;
      params.sub_publication_status = this.SUB_PUBLIC_STATUSES.find(
        (status) => status.value === this.form.value['sub_public_status'],
      )?.text;
    }

    this.googleAnalytics4Service.sendEvent('活動記録', '検索画面', '検索する', params);
  }

  selectCommentStatus(value) {
    if (value !== 'has_comment') {
      this.form.get('sub_comment_status').disable();
    } else {
      this.form.get('sub_comment_status').enable();
    }
    this.form.get('sub_comment_status').patchValue('has_comment');
  }

  selectPublicStatus(value) {
    if (value !== 'shared') {
      this.form.get('sub_public_status').disable();
    } else {
      this.form.get('sub_public_status').enable();
    }
    this.form.get('sub_public_status').patchValue('shared');
  }

  get includingManaVisions(): boolean {
    return this.showManaVisionOption && this.form.get('include_mana_vision').value;
  }
}
