import { ValidatorFn, AbstractControl, ValidationErrors, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export function validateForm(form: UntypedFormGroup, check_dirty = false, validationMessages = {}) {
  const formErrors = {};
  validateFormGroup(formErrors, form, check_dirty, validationMessages);
  return formErrors;
}

function validateFormGroup(formErrors = {}, form: UntypedFormGroup, check_dirty = false, validationMessages = {}) {
  Object.keys(form.controls).forEach((field) => {
    formErrors[field] = null;
    const control = form.get(field);
    let controlIsInvalid = !control.valid;
    const controlValue = control.value;
    const isRequired = (validationMessages[field] || {}).hasOwnProperty('required');
    const isStringControl = typeof controlValue === 'string' || controlValue instanceof String;
    if (control instanceof UntypedFormArray) {
      formErrors[field] = [];
      control.controls.forEach((obj, index) => {
        formErrors[field][index] = {};
        validateFormGroup(formErrors[field][index] || {}, <UntypedFormGroup>obj, check_dirty, validationMessages[field]);
      });
    } else {
      if (isRequired && isStringControl && controlValue.trim() === '') {
        controlIsInvalid = true;
        control.setErrors(Object.assign({ required: true }, control.errors));
      }
      if (check_dirty) {
        controlIsInvalid = controlIsInvalid && control.dirty;
      }
      if (controlIsInvalid) {
        const messages = validationMessages[field];
        for (const key of Object.keys(control.errors)) {
          formErrors[field] = formErrors[field] || [];
          formErrors[field].push(messages[key]);
        }
      }
    }
  });
}

export class CustomValidators {
  static minimumDate(minimumDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) {
        return null;
      }
      const controlDate = new Date(control.value);
      if (controlDate < minimumDate) {
        return {
          dateMinimum: minimumDate,
        };
      } else {
        return null;
      }
    };
  }

  static requiredNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || isNaN(control.value)) {
        return {
          isNumberRequired: true,
        };
      } else {
        return null;
      }
    };
  }

  static mustNotEqual(input: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === input) {
        return {
          equalForbidValue: true,
        };
      } else {
        return null;
      }
    };
  }

  static minimumDateTimeValid(minDateTime: any) {
    return (control: AbstractControl): { [key: string]: any } => {
      const vaildDatetime = typeof minDateTime === 'function' ? minDateTime().getTime() : minDateTime.getTime();
      if (!control.value || new Date(control.value).getTime() >= vaildDatetime) {
        return;
      }
      return { minDateTime: true };
    };
  }
}
