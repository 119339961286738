<div class="stu-work-detail-issue" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <h1 class="heading-text">
      <a (click)="onBack()"><i class="fa fa-chevron-left"></i></a>課題回答
    </h1>
  </div>

  <div class="block-standard" *ngIf="hasWork">
    <div class="spen-mod-heading lv-2 header-title">
      <p class="descr-heading">課題に合わせて、活動記録を作成してください。</p>
      <h2 class="heading-text">{{ activityRecord.work.title }}</h2>
    </div>
    <div class="user-info">
      <img [src]="activityRecord.work.create_user.avatar" />
      <div class="text-info">
        <p>{{ activityRecord.work.delivered_at }}</p>
        <p>{{ activityRecord.work.create_user.name }}</p>
      </div>
    </div>
    <div class="box-comment" *ngIf="activityRecord.work.comment">
      <span class="tip-up"></span>
      <span class="tip-up-large"></span>
      <p appConvertLink>{{ activityRecord.work.comment }}</p>
    </div>
    <div class="thumb" *ngIf="activityRecord.work.upload_files">
      <app-files-view-student [files]="activityRecord.work.upload_files"></app-files-view-student>
    </div>
  </div>

  <div class="template">
    <p class="tem-heading">活動記録編集</p>
    <div class="btn-show-history">
      <a class="spen-util-link" (click)="showModalActivities()">
        <span>過去の活動記録を見る</span>
      </a>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" appScrollToFirstInvalid>
      <div class="form-horizontal">
        <div class="form-group">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <p class="label-input">タイトル</p>
            </div>
            <div class="col-md-9 col-xs-12">
              <ng-container *ngIf="work.answer_title; else selfTitle">
                <p class="title">{{ work.answer_title }}</p>
              </ng-container>
              <ng-template #selfTitle>
                <input
                  class="spen-mod-input-text"
                  type="text"
                  [appPlaceholder]="'入力してください'"
                  formControlName="title"
                  name="title"
                  [appFocus]="focusEvent"
                />
                <div *ngIf="formErrors['title']" class="spen-mod-error-content">
                  {{ formErrors['title'].join(' ') }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div [ngSwitch]="isSingleWork">
          <div *ngSwitchCase="true">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-xs-12">
                  <p class="label-input">本文</p>
                </div>
                <div class="col-md-9 col-xs-12">
                  <label class="spen-mod-select">
                    <select class="select" name="template_id" (change)="onChangeTemplate($event)">
                      <option class="is-all-point select-option" [value]="null">テンプレートを選択</option>
                      <option class="is-part-point select-option" *ngFor="let template of templates" [value]="template.id">
                        {{ template.title }}
                      </option>
                    </select>
                    <i class="fa fa-caret-down"></i>
                  </label>
                </div>
                <div class="col-md-9 col-xs-12">
                  <textarea rows="4" cols="50" class="" [appPlaceholder]="'入力してください'" name="content" formControlName="content">
                  </textarea>
                  <div *ngIf="formErrors['content']" class="spen-mod-error-content">
                    {{ formErrors['content'].join(' ') }}
                  </div>
                  <p class="text-num pull-right">
                    文字数 <span class="num-character">{{ lengthOfContent }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-xs-12">
                  <p class="label-input">添付ファイル</p>
                </div>
                <app-file-uploader
                  class="col-sm-12 col-xs-12"
                  [attachedFiles]="activityRecord.record.upload_files"
                  (eventSelectFile)="onSelectFile($event)"
                  (uploadFilesStatus)="onUploadFilesStatus($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="false">
            <div formArrayName="document_answers_attributes">
              <div class="form-group" *ngFor="let answer of formDocumentAnswers; let i = index">
                <div class="row">
                  <div class="col-md-3 col-xs-12">
                    <div class="label-input">
                      項目{{ i + 1 }}
                      <span *ngIf="work.is_compass_template" class="required">必須</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9 col-xs-12">
                    <p
                      class="work-question-content"
                      [ngClass]="{ 'single-choice-question-content': answer.get('work_question').value['is_single_choice'] }"
                      appConvertLink
                    >
                      {{ answer.get('work_question').value.content.description || answer.get('work_question').value.content }}
                    </p>
                  </div>
                  <div class="col-md-9 col-xs-12 question-image" *ngIf="answer.get('work_question').value['image']">
                    <img src="{{ answer.get('work_question').value['image']['thumbnail'] }}" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9 col-xs-12" [formGroupName]="i">
                    <ng-container *ngIf="!answer.get('work_question').value['is_single_choice']; else singleChoiceOptions">
                      <textarea rows="4" cols="50" class="" [appPlaceholder]="'入力してください'" formControlName="text_content">
                      </textarea>
                      <div *ngIf="getFormErrorsAnswerIndex(i)['text_content']" class="spen-mod-error-content">
                        {{ getFormErrorsAnswerIndex(i)['text_content'].join(' ') }}
                      </div>
                      <p class="text-num pull-right">
                        文字数
                        <span class="num-character">{{ answer.get('text_content').value.length }}</span>
                      </p>
                    </ng-container>
                    <ng-template #singleChoiceOptions>
                      <div class="answer-options">
                        <div *ngFor="let option of answer.get('work_question').value.content.options" class="answer-option">
                          <label class="spen-mod-radio">
                            <input class="radio" formControlName="single_choice_content" type="radio" [value]="option" /><i class="fa"></i>
                            <div class="option-text">{{ option }}</div>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="getFormErrorsAnswerIndex(i)['single_choice_content']" class="spen-mod-error-content">
                        {{ getFormErrorsAnswerIndex(i)['single_choice_content'].join(' ') }}
                      </div>
                    </ng-template>
                    <div
                      class="select-images-multiple-work"
                      [ngClass]="{ 'single-choice-images': answer.get('work_question').value['is_single_choice'] }"
                    >
                      <app-file-uploader
                        [index]="i"
                        [attachedFiles]="answer.get('upload_files').value"
                        (eventSelectFile)="onSelectFile($event)"
                      >
                      </app-file-uploader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group category-block" *ngIf="categoryName; else categorySelect">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-6">
              <div class="category-text">
                {{ categoryName }}
              </div>
            </div>
          </div>
        </div>
        <ng-template #categorySelect>
          <div class="form-group">
            <div class="row">
              <div class="col-md-3 col-xs-12">
                <p class="label-input">カテゴリー</p>
              </div>
              <div class="col-md-9 col-xs-12">
                <app-categories-selection formControlName="category_id" [categories]="categories"> </app-categories-selection>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="block-marks">
        <app-marks-selector [formObject]="activityRecord" [marks]="marks" (eventChangeMark)="onChangeMark()"></app-marks-selector>
      </div>

      <div class="form-group albums" *ngIf="activityRecord.albums_activity_records?.length > 0">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <p class="label-input">アルバム</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <p class="album" *ngFor="let albums_activity_record of activityRecord.albums_activity_records">
              ・ {{ albums_activity_record.album_name }}
              <a (click)="deleteActivityRecordFromAlbum(albums_activity_record)" *ngIf="albums_activity_record.can_delete">取り出す</a>
            </p>
          </div>
        </div>
      </div>

      <div class="block-last">
        <button
          type="submit"
          class="spen-mod-btn is-primary"
          (click)="setPublishedParam(true)"
          [disabled]="isSubmitting || uploadFilesStatus === 'uploading'"
        >
          回答を完了する
        </button>
        <button
          type="submit"
          class="spen-mod-btn"
          (click)="setPublishedParam(false)"
          *ngIf="!activityRecord.published"
          [disabled]="isSubmitting || uploadFilesStatus === 'uploading'"
        >
          一時保存する
        </button>
        <button type="button" class="spen-mod-btn" (click)="onBack()">戻る</button>
      </div>
    </form>
  </div>
</div>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-upload-files-progress *ngIf="uploaderAll.isUploading" [progress]="uploaderAll.progress" (cancelFilesEvent)="cancelUploadingFiles()">
</app-upload-files-progress>

<app-list-activity-modal
  (eventShowActivity)="eventShowActivity($event)"
  (eventSelectActivityModal)="eventSelectActivityModal($event)"
  *ngIf="showListActivityModal"
>
</app-list-activity-modal>

<app-activity-detail-modal [activityId]="activityId" (eventForBackAction)="eventForBackAction($event)" *ngIf="showActivityDetailModal">
</app-activity-detail-modal>

<app-student-mana-vision-detail *ngIf="!!currentManaVision" [manaVision]="currentManaVision" (closeEvent)="hideManaVisionModal()">
</app-student-mana-vision-detail>
