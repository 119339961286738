import { AfterViewChecked, Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { transition, trigger, query, animateChild } from '@angular/animations';
import { Observable } from 'rxjs';

import { Album } from '@models/album';
import { Space } from '@components/space/space';
import { Work } from '../../work';
import { WorkTemplate } from '@models/work-template';

import { CategoriesService } from '@services/categories/categories.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { WorkService } from '@components/work/work.service';
import { WorkCreatorService } from '../work-creator.service';
import { RouterService } from '@services/router.service';
import { LayoutService } from '@components/layout/layout.service';
import { AuthUserService } from '@services/auth-user.service';

import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { validateForm } from '@functions/validate-form';

@Component({
  selector: 'app-work-step1',
  templateUrl: './step1.component.html',
  animations: [trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])],
  styleUrls: ['../create-work.component.scss', './step1.component.scss'],
})
export class Step1Component implements OnInit, AfterViewChecked, OnDestroy {
  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  currentFiles = [];

  categoriesLoaded = false;
  sourceWorkLoaded = false;
  isFocused = false;

  errorMsg: string;
  focusEvent = new EventEmitter<boolean>();

  validationMessages = {
    title: {
      required: '配信課題名を入力してください。',
      maxlength: '配信課題名は50文字以内で入力してください。',
    },
    comment: {
      maxlength: '説明は500文字以内で入力してください。',
    },
    answer_title: {
      required: '活動記録名を入力してください。',
      maxlength: '活動記録名は50文字以内で入力してください。',
    },
    work_template_id: {
      required: '活動記録テンプレートを選択してください。',
    },
    category_id: {
      required: '活動記録カテゴリーを選択してください。',
    },
  };

  isShowWorkTemplateModal = false;
  isShowAnswerPreviewModal = false;
  @ViewChild(FileUploaderComponent) fileUploaderComponent: FileUploaderComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private categoriesService: CategoriesService,
    private workService: WorkService,
    private confirmDialogService: ConfirmDialogService,
    public layoutService: LayoutService,
    public creatorService: WorkCreatorService,
    private authUserService: AuthUserService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const previousStep = this.creatorService.previousStep;
    if (previousStep === 2) {
      this.createWorkForm(this.creatorService.work, true);
      this.setDataLoaded();
    } else {
      this.loadCategories();

      if (!!this.route.snapshot.queryParams['clone_work_id']) {
        this.loadWorkClone(+this.route.snapshot.queryParams['clone_work_id']);
      } else if (!!this.route.snapshot.queryParams['work_template_id']) {
        this.loadWorkTemplate(+this.route.snapshot.queryParams['work_template_id']);
      } else {
        this.sourceWorkLoaded = true;
        this.loadSpaceAndAlbumFromHistory();
        const work = new Work();
        if (!!this.creatorService.album) {
          work.album_id = this.creatorService.album.id;
        }
        this.createWorkForm(work);
      }
    }
  }

  ngAfterViewChecked() {
    if (this.dataLoaded && !this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  loadSpaceAndAlbumFromHistory() {
    if (!history.state) {
      return;
    }

    this.creatorService.album = <Album>history.state.album;
    const space = <Space>history.state.space;
    if (!!space) {
      this.creatorService.spaces.push(space);
    }
  }

  loadCategories() {
    this.categoriesService.loadCategories().then((categories) => {
      if (!!categories) {
        this.creatorService.categories = categories;
        this.categoriesLoaded = true;
      }
    });
  }

  loadWorkClone(cloneWorkId: number) {
    this.workService.loadWorkClone(cloneWorkId).subscribe(
      (work) => {
        this.creatorService.setupFromWorkClone(work);
        this.createWorkForm(work);
        this.sourceWorkLoaded = true;
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  loadWorkTemplate(workTemplateId: number) {
    this.workService.loadWorkTemplateStructure(workTemplateId).subscribe(
      (workTemplate) => {
        this.creatorService.setupFromWorkTemplate(workTemplate);
        const workFromTemplate = {
          work_type: 2,
          work_template_id: workTemplate.id,
          group_question_title: workTemplate.title,
        };
        this.createWorkForm(<Work>(<any>workFromTemplate));
        this.sourceWorkLoaded = true;
      },
      (error) => {
        this.errorMsg = <any>error;
      },
    );
  }

  createWorkForm(work: Work, dirty = false) {
    const answerTitleValidations = this.creatorService.reservedAnswerTitle
      ? [Validators.maxLength(50), Validators.required]
      : [Validators.maxLength(50)];
    const workTemplateValidations = this.creatorService.reservedWorkTemplate ? [Validators.required] : [];
    const categoryValidations = this.creatorService.reservedCategory ? [Validators.required] : [];

    this.form = this.formBuilder.group({
      title: [work.title || '', [Validators.required, Validators.maxLength(50)]],
      comment: [work.comment || '', [Validators.maxLength(500)]],
      album_id: [work.album_id || null],
      answer_title: [work.answer_title || null, answerTitleValidations],
      work_type: [work.work_type || 1],
      work_template_id: [work.work_template_id || null, workTemplateValidations],
      group_question_title: [work.group_question_title || null],
      category_id: [work.category_id || null, categoryValidations],
      upload_files_attributes: [[]],
    });

    this.formErrors = {};
    this.form.valueChanges.subscribe(() => {
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  toggleWorkTemplateModal(isOpen: boolean) {
    this.isShowWorkTemplateModal = isOpen;
  }

  eventSelectWorkTemplate(workTemplate: WorkTemplate) {
    this.isShowWorkTemplateModal = false;
    this.creatorService.workTemplate = workTemplate;
    this.form.get('work_template_id').setValue(workTemplate.id);
    this.form.get('group_question_title').setValue(workTemplate.title);
  }

  changeReservedAnswerTitle(reserved: boolean) {
    this.creatorService.reservedAnswerTitle = reserved;

    if (reserved) {
      this.form.controls['answer_title'].setValidators([Validators.required, Validators.maxLength(50)]);
    } else {
      this.form.controls['answer_title'].setValidators([Validators.maxLength(50)]);
      this.form.patchValue({ answer_title: null });
    }
  }

  changeReservedWorkTemplate(reserved: boolean) {
    this.creatorService.reservedWorkTemplate = reserved;

    if (reserved) {
      this.form.controls['work_template_id'].setValidators([Validators.required]);
    } else {
      this.form.controls['work_template_id'].clearValidators();
      this.form.patchValue({ work_template_id: null, group_question_title: null });
      this.creatorService.workTemplate = null;
    }
  }

  changeReservedCategory(reserved: boolean) {
    this.creatorService.reservedCategory = reserved;

    if (reserved) {
      this.form.controls['category_id'].setValidators([Validators.required]);
    } else {
      this.form.controls['category_id'].clearValidators();
      this.form.patchValue({ category_id: null });
    }
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.creatorService.work = Object.assign(this.creatorService.work, this.form.value);
      this.creatorService.attachedFiles = this.fileUploaderComponent.attachedFiles;
      this.creatorService.fileUploader = this.fileUploaderComponent.uploader;
      this.creatorService.removedAttachFiles = this.fileUploaderComponent.removedAttachFiles;
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  toggleAnswerPreviewModal(isOpen: boolean) {
    this.isShowAnswerPreviewModal = isOpen;
    if (isOpen) {
      this.creatorService.work = Object.assign(this.creatorService.work, this.form.value);
      this.creatorService.work.create_user = this.authUserService.retrieve();
      this.currentFiles = [...this.fileUploaderComponent.attachedFiles, ...this.fileUploaderComponent.uploader.queue];
    }
  }

  get dataLoaded(): boolean {
    return this.categoriesLoaded && this.sourceWorkLoaded;
  }

  setDataLoaded() {
    this.categoriesLoaded = true;
    this.sourceWorkLoaded = true;
  }

  goBack() {
    this.routerService.goBack(this.creatorService.backConfirmMessages);
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep2State();
  }
}
