import { Component, ViewChild, ElementRef } from '@angular/core';
import { GalleryComponent } from '@components/gallery/gallery.component';

@Component({
  selector: 'app-story-gallery',
  templateUrl: './story-gallery.component.html',
  styleUrls: ['../../../shared/components/gallery/gallery.component.scss', './story-gallery.component.scss'],
})
export class StoryGalleryComponent extends GalleryComponent {
  @ViewChild('gallery_title') gallery_title: ElementRef;

  protected updateContentSize() {
    if (this.isShow) {
      const style = this.gallery_content.nativeElement.style;
      const galleryInnerElm = this.gallery_inner.nativeElement;
      const galleryTitleElm = this.gallery_title.nativeElement;
      const galleryCaptionElm = this.gallery_caption.nativeElement;
      style.maxHeight = `${galleryInnerElm.clientHeight - galleryTitleElm.clientHeight - galleryCaptionElm.clientHeight}px`;
    }
  }
}
