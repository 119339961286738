<div class="spen-ly-modal" *ngIf="hasError">
  <div class="modal-dialog error-dialog">
    <div class="inner">
      <div class="modal-heading">
        <p class="heading-text">{{ title }}</p>
      </div>
      <div class="modal-content has-footer">
        <p class="content-inner">
          {{ message }}
        </p>
      </div>
      <div class="modal-footer">
        <div class="modal-actions"><a class="spen-mod-btn" (click)="close()">閉じる</a></div>
      </div>
    </div>
  </div>
</div>
