import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  eventShow = new EventEmitter<Object>();
  eventDone = new EventEmitter<boolean>();
  isShow = false;

  showConfirm(params?: {}): EventEmitter<boolean> {
    this.eventDone = new EventEmitter();
    this.eventShow.emit(params);
    return this.eventDone;
  }

  showConfirmDeactive(params?: {}): Observable<boolean> | boolean {
    const confirm = this.showConfirm(params);
    return confirm.asObservable();
  }

  closeConfirm(value: boolean) {
    this.eventDone.emit(value);
    this.eventDone.complete();
  }
}
