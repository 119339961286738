import { Component, OnInit, Input } from '@angular/core';

const defaultCollapsedLength = 150;

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
})
export class ReadMoreComponent implements OnInit {
  isCollapsed: boolean;

  @Input() content: string;
  @Input() collapsedLength: number;

  constructor() {}

  ngOnInit() {
    this.content = this.content || '';
    this.collapsedLength = this.collapsedLength || defaultCollapsedLength;
    this.isCollapsed = this.content.length > this.collapsedLength ? true : false;
  }

  get collapsedContent() {
    return this.isCollapsed ? this.content.substr(0, this.collapsedLength) : this.content;
  }

  readMore() {
    this.isCollapsed = false;
  }
}
