import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Error {
  error_code: number;
  title: string;
  message: string;
  internalMessage: string;
}

@Injectable()
export class ErrorService {
  private errorSource = new Subject<Error>();
  errorStream = this.errorSource.asObservable();

  constructor() {}

  addError(error: Error) {
    this.errorSource.next(error);
  }
}
