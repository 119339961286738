import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Assessment } from '@components/assessment/assessment';

@Component({
  selector: 'app-student-assessment-detail',
  templateUrl: './student-assessment-detail.component.html',
  styleUrls: ['./student-assessment-detail.component.scss'],
})
export class StudentAssessmentDetailComponent {
  @Input() assessment: Assessment;
  @Input() canEdit: boolean;
  @Input() isFirst: boolean;
  @Input() onPublicActivity: boolean;
  @Output() editAssessment = new EventEmitter<Assessment>();

  constructor() {}

  isTeacher() {
    return !!this.assessment && !!this.assessment.user && this.assessment.user.userTypeId === 1;
  }

  onEditAssessment() {
    this.editAssessment.emit();
  }

  get tailUserName() {
    return this.isTeacher() ? '先生' : this.onPublicActivity ? 'さん' : '';
  }
}
