<div class="timeline-date-box" data-cy="timeline-date-box">
  <p class="date" data-cy="timeline-date">{{ time | dayJapanesesText }}</p>
  <div class="list-action" [class.delete-border-bottom]="nextActionDate">
    <app-action-item
      [action]="action"
      [isLast]="last && !isLoadMoreInnerBox"
      *ngFor="let action of actions; let last = last"
    ></app-action-item>
  </div>

  <div class="load-more" [ngClass]="isLoadMoreInnerBox ? 'load-more-inner-box' : 'load-more-outer-box'" *ngIf="nextActionDate">
    <a class="spen-util-link" (click)="onLoadMore()">
      もっと見る<i [class]="actionsLoading ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
    </a>
  </div>
</div>
