<div class="spen-ly-stu-footer">
  <div class="stu-footer-link">
    <a href="{{ helpPageUrl }}" target="_blank">ヘルプページ</a>
  </div>
  <div class="stu-footer-link">
    <a href="{{ noticeUrl }}" target="_blank">利用に関する注意事項</a>
  </div>
  <div class="stu-footer-copyright">
    <p>Copyright © Classi Corp. All Rights Reserved</p>
  </div>
</div>
