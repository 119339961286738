<div class="block-standard">
  <div class="spen-mod-heading lv-2 header-title">
    <h2 class="heading-text">{{ work.title }}</h2>
  </div>
  <div class="user-info">
    <img [src]="work.create_user?.avatar" alt="avatar" />
    <div class="text-info">
      <p>{{ work.delivered_at }}配信</p>
      <p>{{ work.create_user.name }}先生</p>
    </div>
  </div>
  <div class="box-comment" *ngIf="work.comment">
    <span class="tip-up"></span>
    <span class="tip-up-large"></span>
    <p appConvertLink>{{ work.comment }}</p>
  </div>
  <div class="thumb">
    <app-files-view-student [files]="work.upload_files"></app-files-view-student>
  </div>
</div>
