<ng-container *ngIf="storyCommentsLoaded; else loadingIcon">
  <div class="box-comment">
    <app-story-comment-form-teacher
      *ngIf="canCreateStoryComment()"
      [storyUserId]="storyUserId"
      [grade]="grade"
      (saveNewStoryComment)="onsaveNewStoryComment($event)"
    >
    </app-story-comment-form-teacher>
    <ng-container *ngFor="let storyComment of storyComments" [ngSwitch]="storyComment !== edittingStoryComment">
      <app-story-comment-detail-teacher
        *ngSwitchCase="true"
        [storyComment]="storyComment"
        [canEdit]="canEditStoryComment(storyComment)"
        (editStoryComment)="onEditStoryComment(storyComment)"
      >
      </app-story-comment-detail-teacher>
      <app-story-comment-form-teacher
        *ngSwitchCase="false"
        [storyUserId]="storyUserId"
        [grade]="grade"
        [storyComment]="storyComment"
        (cancelEditStoryComment)="onCancelEditStoryComment()"
        (updateOldStoryComment)="onUpdateOldStoryComment($event, storyComment)"
        (deleteOldStoryComment)="onDeleteOldStoryComment(storyComment)"
      >
      </app-story-comment-form-teacher>
    </ng-container>

    <div class="load-more" *ngIf="canLoadMore">
      <a class="spen-util-link" (click)="loadMoreStoryComments()">
        <span>もっと見る</span>
        <i [class]="isLoadingMore ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-down'"></i>
      </a>
    </div>
  </div>
</ng-container>

<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
