<div *ngIf="!layoutService.isTabletDownView.value; else spView">
  <div class="filter-detail-border-top">
    <form [formGroup]="form" class="full-width">
      <div class="filter-box">
        <div class="filter-condition">
          <span class="label-text">種別</span>
          <label *ngFor="let type of albumType" class="spen-mod-radio" data-cy="album-type">
            <input type="radio" value="{{ type.value }}" formControlName="album_type" class="radio" data-cy="album-type-radio" />
            <i class="fa"></i>
            <span data-cy="album-type-name">{{ type.name }}</span>
          </label>
          <span class="label-text">アルバム名</span>
          <input
            class="spen-mod-input-text input-search"
            data-cy="search-title"
            formControlName="title"
            placeholder="アルバム名を入力してください"
            type="text"
            maxlength="50"
          />
        </div>
        <div class="button-group">
          <button class="spen-mod-btn is-default" data-cy="remove-filter" (click)="onResetFilter()" [disabled]="isLoading || isDefaultForm">
            選択解除
          </button>
          <button class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit()" [disabled]="isLoading">検索する</button>
        </div>
      </div>
    </form>
  </div>

  <div class="section-contents table-items-list" *ngIf="albums?.length > 0; else emptyList">
    <div class="fix-filter-response clearfix">
      <span class="pull-left" data-cy="text-filter">検索条件：{{ filterService.messageFilter }}</span>
      <span class="paginate-result pull-right" data-cy="text-page-info" *ngIf="!isLoading">{{ metaData | paginateText }}</span>
    </div>

    <div class="spen-mod-flex-table table-list-full has-link">
      <div class="flex-table-head">
        <div class="flex-item col-size-8 is-first-col">アルバム名</div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" data-cy="col-sort" (click)="changeBySortColumn('created_at')">
            <span>作成日時 </span>
            <i [class]="sortStatus['created_at']" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item col-size-2 pull-right">
          <a class="spen-util-link" data-cy="col-sort" (click)="changeBySortColumn('activity_records_count')">
            <span>活動記録数 </span>
            <i [class]="sortStatus['activity_records_count']" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item col-size-3">
          <a class="spen-util-link" data-cy="col-sort" (click)="changeBySortColumn('updated_at')">
            <span>更新日時 </span>
            <i [class]="sortStatus['updated_at']" data-cy="col-sort-icon"></i>
          </a>
        </div>
        <div class="flex-item redirect-cell"></div>
      </div>
      <a
        class="flex-table-row"
        data-cy="album-item"
        *ngFor="
          let record of albums
            | paginate
              : {
                  itemsPerPage: this.metaData.per_page,
                  currentPage: this.metaData.current_page,
                  totalItems: this.metaData.total_count,
                }
        "
        (click)="onClickAlbumsRecord(record, $event)"
      >
        <div class="flex-item col-size-8 is-first-col truncate-text album-title">
          <img
            class="record-image"
            data-cy="album-image"
            [class.album-cover]="!!record.image"
            [src]="record.image || 'assets/images/album.svg'"
          />
          <p class="truncate-text" data-cy="album-title">{{ record.title }}</p>
        </div>
        <div class="flex-item col-size-3" data-cy="album-created-at">{{ record.created_at }}</div>
        <div class="flex-item col-size-2 pull-right" data-cy="album-activity-records-count">
          {{ record.activity_records_count | number }}
        </div>
        <div class="flex-item col-size-3" data-cy="album-updated-at">{{ record.updated_at }}</div>
        <div class="flex-item redirect-cell is-icon-item"><i class="fa fa-chevron-right"></i></div>
      </a>
    </div>

    <div class="spen-mod-paginate detail-pagination" *ngIf="showPagination && !isLoading">
      <app-pagination-controls class="paginate-custom" (pageChange)="pageChanged($event)"> </app-pagination-controls>
    </div>
  </div>
</div>

<ng-template #spView>
  <div class="filter-detail-border-top">
    <div class="text-detail-content filter-border-bottom full-width">
      <div class="text-row">
        <p class="label">検索条件</p>
        <p class="filter-result" data-cy="text-filter">{{ filterService.messageFilter }}</p>
      </div>
      <div class="text-row">
        <p class="label">並び順</p>
        <p class="text filter-result" data-cy="text-sort-order">{{ messageSort }}</p>
      </div>
    </div>
  </div>
  <div class="filter-detail-border-top border-top-none">
    <div class="text-detail-content filter-border-bottom full-width">
      <a class="spen-util-link detail-link-settings" (click)="onChangeShowMoreFilter()">
        <span>変更</span>
        <i [ngClass]="isShowMoreFilter ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" data-cy="show-filter"></i>
      </a>
    </div>
    <div class="text-detail-content filter-border-bottom remove-padding-t" *ngIf="isShowMoreFilter">
      <form [formGroup]="form" class="full-width">
        <div class="margin-b-0">
          <p class="filter-detail-name">種別</p>
          <label class="spen-mod-select width-fix-label">
            <select class="select" data-cy="album-type" formControlName="album_type">
              <option value="{{ type.value }}" *ngFor="let type of albumType">{{ type.name }}</option></select
            ><i class="fa fa-caret-down"></i>
          </label>
          <p class="filter-detail-name">アルバム名</p>
          <input
            class="spen-mod-input-text"
            data-cy="search-title"
            maxlength="50"
            formControlName="title"
            placeholder="アルバム名を入力してください"
            type="text"
          />
          <p class="filter-detail-name">並び順</p>
          <label class="spen-mod-select width-fix-label">
            <select class="select" data-cy="sort-order" formControlName="sort_value" (change)="onChangeSortValue($event.target.value)">
              <option class="select-option" [value]="sortOption.sort_value" *ngFor="let sortOption of sortOptions">
                {{ sortOption.text }}
              </option></select
            ><i class="fa fa-caret-down"></i>
          </label>
        </div>
      </form>
    </div>
    <div class="text-center adv-filter-border-top" *ngIf="isShowMoreFilter">
      <button class="spen-mod-btn is-primary" data-cy="submit-filter" (click)="onSubmit(); isShowMoreFilter = false" [disabled]="isLoading">
        適用する
      </button>
      <button class="spen-mod-btn is-default" data-cy="remove-filter" (click)="onResetFilter()" [disabled]="isLoading || isDefaultForm">
        選択解除
      </button>
    </div>
  </div>
  <div class="section-contents table-items-list">
    <div class="spen-mod-table" *ngIf="!isLoading && albums.length > 0; else emptyList">
      <div class="fix-filter-response">
        <span class="paginate-result" data-cy="text-page-info">{{ metaData | paginateText }}</span>
      </div>
      <ul class="border-top">
        <li
          class="li-item-border clickable-item"
          data-cy="album-item"
          *ngFor="
            let record of albums
              | paginate
                : {
                    itemsPerPage: this.metaData.per_page,
                    currentPage: this.metaData.current_page,
                    totalItems: this.metaData.total_count,
                  }
          "
          (click)="onClickAlbumsRecord(record, $event)"
        >
          <div class="info">
            <div class="album-name">
              <img class="user-avatar-fix-width" data-cy="album-image" [src]="record.image || 'assets/images/album.svg'" />
              <span class="albums-title truncate-text"
                ><b data-cy="album-title">{{ record.title }}</b></span
              >
            </div>
            <p class="record-number small-text" data-cy="album-activity-records-count">
              活動記録数：{{ record.activity_records_count | number }}
            </p>
            <p class="muted-text small-text time" data-cy="album-created-at">作成日時：{{ record.created_at }}</p>
            <p class="muted-text small-text time" data-cy="album-updated-at">更新日時：{{ record.updated_at }}</p>
          </div>
          <span class="arrow-items">
            <i class="fa fa-chevron-right" (click)="onClickAlbumsRecord(record, $event)"></i>
          </span>
        </li>
      </ul>
      <div class="spen-mod-paginate detail-pagination" *ngIf="showPagination && !isLoading">
        <app-pagination-controls directionLinks="false" (pageChange)="pageChanged($event)" [stylePaginate]="'small'">
        </app-pagination-controls>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #emptyList>
  <div class="filter-info" *ngIf="!layoutService.isTabletDownView.value">
    <span class="pull-left" *ngIf="isSearching">検索条件：{{ filterService.messageFilter }}</span>
  </div>
  <div colspan="4" [class]="isSearching ? 'empty-search' : 'empty-list'" *ngIf="!isLoading">
    {{ isSearching ? '検索結果が0件です。' : 'ここに生徒のアルバムが表示されます。' }}
  </div>
  <div colspan="4" class="empty-message" *ngIf="isLoading && !inProgressSort">
    <div class="spen-mod-flex-loading">
      <div class="loading-icon"></div>
    </div>
  </div>
</ng-template>
