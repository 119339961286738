import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Space } from '@components/space/space';
import { WorkService } from '@components/work/work.service';
import { LayoutService } from '@components/layout/layout.service';
import { NextPageParams } from './space-picker-response';

@Component({
  selector: 'app-space-picker',
  templateUrl: './space-picker.component.html',
  styleUrls: ['./space-picker.component.scss'],
})
export class SpacePickerComponent implements OnInit, OnDestroy {
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedSpaces: Array<Space>;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  queryParams = {};
  searchedResults: Array<Space> = new Array<Space>();
  newSelectSpaces: Array<Space> = new Array<Space>();
  nextPageParams: NextPageParams;
  isShowError = false;
  isLoading = false;
  errMsg: string;

  constructor(
    private renderer: Renderer2,
    public layoutService: LayoutService,
    public workService: WorkService,
  ) {}

  ngOnInit() {
    this.newSelectSpaces.push(...this.selectedSpaces);
    this.onSearch();
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.nextPageParams = null;
    this.searchedResults = [];
    if (this.isShowError) {
      this.isShowError = false;
    }

    this.queryParams['keyword'] = !!this.searchInput ? this.searchInput.nativeElement.value.trim() : '';

    this.workService.getSpacesPickerList(this.queryParams).subscribe(
      (response) => {
        this.isLoading = false;
        this.searchedResults = response.spaces;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;

    // リクエスト用のキー名に変更する
    const params = {
      ...this.nextPageParams,
      page: this.nextPageParams.next_page,
    };
    delete params.next_page;

    this.workService.getSpacesPickerList(params).subscribe(
      (response) => {
        this.isLoading = false;
        this.searchedResults.push(...response.spaces);
        this.nextPageParams = null;
        if (response.meta) {
          this.nextPageParams = response.meta;
        }
      },
      (error) => {
        this.isLoading = false;
        this.errMsg = <any>error;
      },
    );
  }

  onClose() {
    this.closeEvent.emit();
  }

  onSubmit() {
    if (this.newSelectSpaces.length === 0) {
      this.isShowError = true;
    } else {
      this.submitEvent.emit(this.newSelectSpaces);
    }
  }

  selectSpace(space: Space) {
    if (this.isShowError) {
      this.isShowError = false;
    }

    if (this.isSelectedSpace(space)) {
      this.newSelectSpaces = this.newSelectSpaces.filter((item) => item.id !== space.id);
    } else {
      this.newSelectSpaces.push(space);
    }
  }

  isSelectedSpace(space: Space) {
    return !!this.newSelectSpaces.find((s) => s.id === space.id);
  }
}
