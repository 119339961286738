<div class="step2">
  <div class="list-steps">
    <app-steps [steps]="formService.steps" [currentStep]="1"></app-steps>
  </div>

  <div class="template-name step2-content">
    <div class="label">
      <div class="label-text">活動記録テンプレート名</div>
    </div>

    <div class="break-word">{{ workTemplate.title }}</div>
  </div>

  <div class="step2-content" *ngFor="let question of remainingQuestions; let i = index">
    <div class="label">
      <div class="label-text">設問{{ i + 1 }}</div>
    </div>

    <div class="question-content">
      <div class="question-text break-word preserve-line-break">{{ question.content }}</div>
      <div class="image" *ngIf="question.image_local; else image_s3">
        <img appQuestionImagePreview [image]="question.image_local" />
      </div>
      <ng-template #image_s3>
        <div class="image" *ngIf="hasImageToBeUpdated(question)">
          <div>
            <img src="{{ question.image_attributes['thumbnail'] }}" />
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="buttons-group step2-btn">
    <button type="button" class="spen-mod-btn is-default" (click)="goBack()" [disabled]="isSubmitting">戻る</button>
    <button type="button" class="spen-mod-btn is-primary" (click)="onSubmit()" [disabled]="isSubmitting">作成完了</button>
  </div>
</div>

<app-upload-files-progress *ngIf="uploader.isUploading" [progress]="uploader.progress" (cancelFilesEvent)="cancelUploadingFiles()">
</app-upload-files-progress>
