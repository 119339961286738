<div class="stu-my-album">
  <div class="list-header">
    <button class="spen-mod-btn is-primary" routerLink="/albums/student/new">アルバムの新規作成</button>
  </div>

  <div class="filtering-albums" *ngIf="searchKeyword.length > 0; else notFilteringALbums">
    <div class="change-filter">
      <a class="spen-util-link" (click)="showFilterModal()"><i class="fa fa-search"></i>検索条件を変更</a>
    </div>
    <div class="album-detail">
      <span class="album-name">検索条件：{{ searchKeyword }}</span>
      <a class="remove-filter spen-util-link" (click)="removeAlbumFilter()">解除</a>
    </div>
  </div>
  <ng-template #notFilteringALbums>
    <div class="not-filtering-albums">
      <a class="spen-util-link" (click)="showFilterModal()"><i class="fa fa-search"></i>検索</a>
    </div>
  </ng-template>

  <div class="albums-list">
    <ng-container *ngIf="dataLoaded; else loadingIcon">
      <ng-container *ngIf="albums.length > 0; else emptyList">
        <div class="list-amount">
          <span>{{ albumsCount }}件</span>
        </div>
        <div class="album-content">
          <ul class="album-flex-list">
            <li
              class="list-item"
              routerLink="/albums/student/{{ album.id }}"
              *ngFor="
                let album of albums
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      }
              "
            >
              <a class="list-item-info">
                <img
                  class="default-image album-cover object-fit-cover"
                  src="{{ album.image.thumbnail }}"
                  *ngIf="!!album.image; else defaultImage"
                />
                <ng-template #defaultImage>
                  <img class="default-image" src="/assets/images/album.svg" />
                </ng-template>
                <div class="text-info">
                  <p class="time-album">
                    <span class="spen-mod-badge is-red" *ngIf="!album.is_read">NEW</span>
                    <span>{{ album.created_at | date: 'yyyy/MM/dd' }}</span>
                    <span *ngIf="album.creator_type === 'teacher'" class="album-creator-name"> {{ album.user.name }}先生 </span>
                  </p>
                  <p class="name-file">{{ album.title }}</p>
                </div>
              </a>
              <a class="list-item-icon"><i class="fa fa-chevron-right"></i></a>
            </li>
          </ul>
          <div class="block-footer">
            <div class="pagination" *ngIf="showPagination">
              <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
              </app-pagination-controls>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #emptyList>
        <div class="null-content">
          <p class="text">アルバムはありません。</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingIcon>
      <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
    </ng-template>
  </div>
  <a href="{{ classiUrl }}" class="spen-mod-btn prev-button" *ngIf="!isMobileApp">ホームへ戻る</a>
</div>

<app-filter-album-modal
  *ngIf="isShowingFilterModal"
  [initSearchKeyword]="searchKeyword"
  (submitFilter)="onSubmitFilter($event)"
  (cancelFilter)="onCancelFilter()"
>
</app-filter-album-modal>
