import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FileUploaderModule } from '../../shared/components/file-uploader/file-uploader.module';
import { SharedModule } from '../../shared/components/shared.module';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';

import { WorkService } from './work.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { S3CredentialService } from '@services/s3-credential.service';

import { FormAnswerWorkComponent } from './form-answer-work/form-answer-work.component';
import { WorkDetailStudentComponent } from './work-detail/student/work-detail-student.component';
import { ContentSavingService } from '@services/content-saving.service';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedModule, SharedDirectivesModule, RouterModule, FormsModule, ReactiveFormsModule, FileUploaderModule],
  declarations: [WorkDetailStudentComponent, FormAnswerWorkComponent],
  exports: [WorkDetailStudentComponent],
  providers: [WorkService, UploadFilesService, S3CredentialService, ContentSavingService],
})
export class WorkStudentModule {}
