import { Component, OnInit } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { RouterDataService } from '@services/router-data.service';
import { TeacherNavigationService } from '@services/navigation/teacher-navigation.service';

import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-link-horizontal-transition',
  templateUrl: './link-horizontal-transition.component.html',
  styleUrls: ['./link-horizontal-transition.component.scss'],
})
export class LinkHorizontalTransitionComponent implements OnInit {
  constructor(
    public layoutService: LayoutService,
    public routerDataService: RouterDataService,
    public navigationService: TeacherNavigationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof NavigationEnd) {
            this.navigationService.currentUrl = event.url;
            return true;
          }
        }),
        map(() => this.route.snapshot),
        map((route) => {
          const tempPathArray = [];
          while (route.firstChild) {
            route = route.firstChild;
            tempPathArray.push(route.routeConfig.path);
          }

          this.navigationService.currentPathConfig = tempPathArray.join('/');
          return route;
        }),
      )
      .subscribe((activatedRoute: ActivatedRouteSnapshot) => {
        this.navigationService.updateNavigator(activatedRoute);
      });
  }

  onClickNavigate(position: 'list' | 'prev' | 'next') {
    if (this.navigationService.isLoading) {
      return;
    }
    this.router.navigateByUrl(this.navigationService[position].url, { replaceUrl: position !== 'list' });
  }
}
