import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { SpaceService } from '@components/space/space.service';
import { SpaceCreatorService } from '../space-creator.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { User } from '@models/user';
import { ToastService } from '@services/toast/toast.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherFilterService, StudentFilterService } from '../space-filter/space-filter.service';

@Component({
  selector: 'app-space-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../form-space.component.scss'],
})
export class Step2Component implements OnInit, OnDestroy {
  dataLoaded = false;
  spaceStatus = 'none';
  errMsg: string;

  selectedStudentsSub: Observable<Array<User>>;

  CROSS_REVIEW_PERMISSIONS = [
    { title: '先生のピックアップ', key: 'teacher_can_pick_up' },
    { title: '生徒自主公開', key: 'student_can_publish' },
    { title: '相互評価コメント', key: 'student_can_comment_other' },
  ];

  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };

  constructor(
    private spaceService: SpaceService,
    private analytic4Service: GoogleAnalytics4Service,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private creatorService: SpaceCreatorService,
    public studentFilterService: StudentFilterService,
    public teacherFilterService: TeacherFilterService,
    public layoutService: LayoutService,
    private toastService: ToastService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.dataLoaded = true;
    this.routerService.registerBackConfirm(this.dataConfirm);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.iscompleted) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  get iscompleted() {
    return this.spaceStatus === 'completed';
  }

  get isDisabled() {
    return this.spaceStatus === 'init';
  }

  private onBack() {
    this.creatorService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  private isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }

  onSubmit() {
    !!this.creatorService.space.id ? this.updateSpace() : this.createSpace();
  }

  createSpace() {
    this.spaceStatus = 'init';
    this.spaceService.createSpace(this.creatorService.space).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.analytic4Service.sendEvent('スペース', '作成画面', '作成完了', {
            teacher_can_pick_up: this.creatorService.space.teacher_can_pick_up ? 1 : 0,
            student_can_publish: this.creatorService.space.student_can_publish ? 1 : 0,
            student_can_comment_other: this.creatorService.space.student_can_comment_other ? 1 : 0,
          });
          this.spaceStatus = 'completed';
          this.routerService.resetDataConfirm();
          this.router.navigateByUrl('/spaces/teacher', { replaceUrl: true });
          this.toastService.showToast('作成しました');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.spaceStatus = 'none';
      },
    );
  }

  updateSpace() {
    this.spaceStatus = 'init';
    this.spaceService.updateSpace(this.creatorService.space).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.spaceStatus = 'completed';
          this.routerService.resetDataConfirm();
          this.routerService.goBackSpecific(`/spaces/teacher/${this.creatorService.space.id}`);
          this.toastService.showToast('変更しました');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.spaceStatus = 'none';
      },
    );
  }
}
