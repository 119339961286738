import { Injectable } from '@angular/core';
import { AuthUserService } from '@services/auth-user.service';

@Injectable()
export class SearchConditionSavingService {
  storageKey: string;

  constructor(private authUserService: AuthUserService) {}

  saveSearchConditions(url: string, params: Object, tab?: string) {
    this.setStorageKey(url, tab);
    const schoolYear = this.authUserService.retrieve().schoolYear;

    const searchConditions = {};
    Object.assign(searchConditions, params, { current_year: schoolYear });
    this.setTemporaryData(searchConditions);
  }

  getSearchConditions(url: string, tab?: string) {
    this.setStorageKey(url, tab);
    const schoolYear = this.authUserService.retrieve().schoolYear;
    let temporaryData = this.getTemporaryData();

    if (temporaryData['current_year'] !== schoolYear) {
      this.removeTemporaryData();
      temporaryData = {};
    }

    return temporaryData;
  }

  private getTemporaryData() {
    return JSON.parse(localStorage.getItem(this.storageKey)) || {};
  }

  private setTemporaryData(value) {
    localStorage.setItem(this.storageKey, JSON.stringify(value));
  }

  private removeTemporaryData() {
    localStorage.removeItem(this.storageKey);
  }

  private setStorageKey(url: string, tab?: string) {
    const userId = this.authUserService.retrieve().id;
    this.storageKey = `${url}${tab || ''}_${userId}`;
  }
}
