import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SlideMenuService } from '@services/slide-menu.service';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit, OnDestroy {
  readonly tabOptions = {
    grade_1: '1年',
    grade_2: '2年',
    grade_3: '3年',
    summary: 'まとめ',
  };
  currentTab = 'grade_1';
  private routerSubscriber: Subscription;

  constructor(
    private slideMenuService: SlideMenuService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.routerSubscriber = this.route.queryParams.subscribe((params) => {
      if (Object.keys(this.tabOptions).includes(params['tab'])) {
        this.currentTab = params['tab'];
      } else {
        this.router.navigate(['.'], { queryParams: { tab: this.currentTab }, replaceUrl: true, relativeTo: this.route });
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscriber?.unsubscribe();
  }

  slideMenu() {
    this.slideMenuService.slideIn();
  }
}
