import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastState', [
      transition(':enter', [style({ opacity: 1 })]),
      transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  content = '';
  toastState = false;

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.toastService.eventShow.subscribe((content) => {
      this.toastState = true;
      this.content = content;
      setTimeout(() => {
        this.toastState = false;
      }, 2000);
    });
  }
}
