import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Album } from '@models/album';
import { Space } from '@components/space/space';
import { Work } from '../../work';

import { currentLocaleDate } from '@functions/date-formatter';
import { CustomValidators, validateForm } from '@functions/validate-form';

import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { WorkCreatorService } from '../work-creator.service';
import { RouterService } from '@services/router.service';

@Component({
  selector: 'app-work-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../create-work.component.scss', './step2.component.scss'],
})
export class Step2Component implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  formInited = false;

  defaultDeliveredValue = currentLocaleDate;

  isShowSpacePickerModal = false;
  isShowAlbumPickerModal = false;

  errorMsg: string;

  validationMessages = {
    works_spaces_attributes: {
      required: '配信先スペースを選択してください。',
    },
    delivered_at: {
      minDateTime: '配信日時に過去日時を指定できません。',
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public creatorService: WorkCreatorService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.createWorkForm(this.creatorService.work);
    this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  createWorkForm(work: Work, dirty = false) {
    this.form = this.formBuilder.group({
      works_spaces_attributes: [[], Validators.required],
      album_id: [work.album_id || null],
      delivered_at: [work.delivered_at || null, CustomValidators.minimumDateTimeValid(this.defaultDeliveredValue)],
    });

    this.setSpacesAttributes(false);
    this.formErrors = {};
    this.formInited = true;
    this.form.valueChanges.subscribe(() => {
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
      this.creatorService.work = Object.assign(this.creatorService.work, this.form.value);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.form.get('delivered_at').updateValueAndValidity();
    this.formErrors = validateForm(this.form, false, this.validationMessages);

    if (this.form.valid) {
      this.nextStep();
    }
  }

  goBack() {
    this.creatorService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 3 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep3State();
  }

  toggleSpacesModal(open: boolean) {
    this.isShowSpacePickerModal = open;
  }

  onSelectSpace(spaces) {
    this.isShowSpacePickerModal = false;
    this.creatorService.spaces = spaces;

    if (this.creatorService.album) {
      this.removeAlbum();
    }
    this.setSpacesAttributes();
  }

  removeSpace(space: Space) {
    const removeIndex = this.creatorService.spaces.findIndex((item) => item.id === space.id);
    this.creatorService.spaces.splice(removeIndex, 1);

    if (this.creatorService.album) {
      this.removeAlbum();
    }
    this.setSpacesAttributes();
  }

  private setSpacesAttributes(setDirty = true) {
    this.form.controls['works_spaces_attributes'].setValue(
      this.creatorService.spaces.map((s) => {
        return { space_id: s.id };
      }),
    );
    if (setDirty) {
      this.form.controls['works_spaces_attributes'].markAsDirty();
    }
  }

  toggleAlbumsModal(open: boolean) {
    this.isShowAlbumPickerModal = open;
  }

  onSelectAlbum(album: Album) {
    this.isShowAlbumPickerModal = false;
    this.creatorService.album = album;
    this.form.controls['album_id'].setValue(album.id);
  }

  removeAlbum() {
    this.creatorService.album = null;
    this.form.controls['album_id'].setValue(null);
  }

  changeReservedDeliveryTime(reserved: boolean) {
    this.creatorService.reservedDeliveryTime = reserved;

    if (reserved) {
      this.form.controls['delivered_at'].setValue(this.defaultDeliveredValue());
    } else {
      this.form.controls['delivered_at'].setValue(null);
    }
  }

  get cantSelectAlbum(): boolean {
    return this.creatorService.spaces.length !== 1 || !this.creatorService.spaces[0].has_album;
  }
}
