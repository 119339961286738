import { Component, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { LayoutService } from '@components/layout/layout.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { ToastService } from '@services/toast/toast.service';
import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { Observable } from 'rxjs';
import { GraduationMessageFormService } from '../graduation-message-form.service';

@Component({
  selector: 'app-graduation-message-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['../graduation-message-form.component.scss', './step3.component.scss'],
})
export class Step3Component implements OnInit, OnDestroy {
  graduationMessageCreateStatus: 'none' | 'init' | 'submitting' | 'completed' = 'none';
  categoryName: string;
  errorMsg: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public creatorService: GraduationMessageFormService,
    private graduationMessageService: GraduationMessageService,
    private confirmDialogService: ConfirmDialogService,
    private toastService: ToastService,
    private routerService: RouterService,
    public layoutService: LayoutService,
    private uploadFilesService: UploadFilesService,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 3) {
      this.goToStep1();
      return;
    }
    this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.iscompletedAll) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  goBack() {
    this.creatorService.setStep2State();
    this.goToStep2();
  }

  goToStep1() {
    this.ngZone.run(() => {
      this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    });
  }

  goToStep2() {
    this.router.navigate(['.'], { queryParams: { step: 2 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onSubmit() {
    this.graduationMessageCreateStatus = 'init';
    if (this.creatorService.fileUploader.queue.length > 0) {
      this.uploadAttachFileThenSubmit();
    } else {
      this.prepareForSubmitGraduationMessage();
    }
  }

  uploadAttachFileThenSubmit() {
    const result = this.uploadFilesService.uploadAllFile(this.creatorService.fileUploader);
    if (result === true) {
      this.prepareForSubmitGraduationMessage();
    } else if (result instanceof EventEmitter) {
      result.subscribe((value) => {
        if (value) {
          this.prepareForSubmitGraduationMessage();
        } else {
          this.graduationMessageCreateStatus = 'none';
        }
        result.unsubscribe();
      });
    }
  }

  cancelUploadingFiles() {
    this.graduationMessageCreateStatus = 'none';
    this.uploadFilesService.cancelUploadedFiles(this.creatorService.fileUploader);
  }

  prepareForSubmitGraduationMessage() {
    // Add new files uploaded.
    const newUploadedFiles = this.uploadFilesService.sentUploadedFiles(this.creatorService.fileUploader);
    for (const file of newUploadedFiles) {
      if (file['item_index'] === undefined) {
        this.creatorService.graduationMessage.upload_files_attributes.push(file);
      }
    }

    const trimmedComment = this.creatorService.graduationMessage.message.trim();
    if (!trimmedComment) {
      this.creatorService.graduationMessage.message = trimmedComment;
    }

    this.createGraduationMessage(this.creatorService.graduationMessage);
  }

  createGraduationMessage(graduationMessage: GraduationMessage) {
    this.graduationMessageCreateStatus = 'submitting';

    this.graduationMessageService.createGraduationMessage(graduationMessage).subscribe(
      (response) => {
        this.graduationMessageCreateStatus = 'completed';
        this.routerService.resetDataConfirm();
        this.router.navigateByUrl(`/graduation-messages/teacher`, { replaceUrl: true });
        this.toastService.showToast('配信しました');
        this.sendCreateGraduationMessageGA(graduationMessage);
      },
      (error) => {
        this.errorMsg = <any>error;
        this.graduationMessageCreateStatus = 'none';
      },
    );
  }

  sendCreateGraduationMessageGA(graduationMessage: GraduationMessage) {
    const action = '卒業メッセージ配信完了';
    const label = graduationMessage.space_id;
  }

  get hasAttachedFiles(): boolean {
    return this.creatorService.attachedFiles.length > 0 || this.creatorService.fileUploader.queue.length > 0;
  }

  get disableSubmitButton(): boolean {
    return this.graduationMessageCreateStatus !== 'none';
  }

  get iscompletedAll(): boolean {
    return this.graduationMessageCreateStatus === 'completed';
  }
}
