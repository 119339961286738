import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@components/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploaderModule } from '@components/file-uploader/file-uploader.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssessmentModule } from '@components/assessment/assessment.module';

import { FormAlbumStudentComponent } from './form-album/form-album-student.component';
import { AlbumDetailStudentComponent } from './album-detail/album-detail-student.component';
import { AlbumSelectActivityModalComponent } from './album-select-activity-modal/album-select-activity-modal.component';
import { ActivityAlbumModalComponent } from './activity-album-modal/activity-album-modal.component';
import { FormAddGoalAlbumComponent } from './form-add-goal-album/form-add-goal-album.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FormAlbumStudentComponent,
    AlbumDetailStudentComponent,
    AlbumSelectActivityModalComponent,
    ActivityAlbumModalComponent,
    FormAddGoalAlbumComponent,
  ],
  exports: [
    FormAlbumStudentComponent,
    AlbumDetailStudentComponent,
    AlbumSelectActivityModalComponent,
    ActivityAlbumModalComponent,
    FormAddGoalAlbumComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploaderModule,
    SharedDirectivesModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    AssessmentModule,
  ],
  providers: [],
})
export class AlbumStudentModule {}
