import { Component, OnInit, EventEmitter, OnDestroy, AfterViewChecked, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LayoutService } from '@components/layout/layout.service';
import { Story } from '@components/story/story';
import { StoryCategory } from '@models/story-category';
import { validateForm } from '@functions/validate-form';
import { RouterService } from '@services/router.service';
import { StoryService } from '../../story.service';
import { ToastService } from '@services/toast/toast.service';
import { StoryCategoriesService } from '@services/story-categories/story-categories.service';
import { checkBlankString } from '@functions/check-string';
import { StoryFileUploaderComponent } from '../story-file-uploader/story-file-uploader.component';
import { ErrorService } from '@services/error.service';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { ActivityRecord } from '@components/activity-record/activity-record';
import { pickBy } from 'lodash';

@Component({
  selector: 'app-form-story',
  templateUrl: './form-story.component.html',
  styleUrls: ['./form-story.component.scss'],
})
export class FormStoryComponent implements OnInit, OnDestroy, AfterViewChecked {
  editStory: Story;
  stories: Array<Story>;
  storyCategory: StoryCategory;
  storyParentCategory: StoryCategory;
  form: UntypedFormGroup;
  storyLoaded = false;
  isSubmitting = false;
  categoryLoaded = false;
  isFocused = false;
  errMsg: string;
  anyFileExists = false;
  activityId: number;
  showListActivityModal = false;
  showActivityDetailModal = false;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  uploadFilesStatus: 'none' | 'init' | 'submitting' | 'completed' = 'none';
  validationMessages = {
    archive: {
      maxlength: 'やったこと・考えたことは5,000文字以内で入力してください。',
    },
    vision: {
      maxlength: '見通し・展望は5,000文字以内で入力してください。',
    },
    upload_files_attributes: {
      caption: {
        required: 'キャプションを入力してください。',
        maxlength: 'キャプションは50文字以内で入力してください。',
      },
    },
  };
  dataConfirm = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
    isPrimary: true,
  };
  private readonly allowedGradeValues = ['1', '2', '3'];
  private grade: string;

  @ViewChild(StoryFileUploaderComponent) storyFileUploader: StoryFileUploaderComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private routerService: RouterService,
    private storyService: StoryService,
    private toastService: ToastService,
    private storyCategoriesService: StoryCategoriesService,
    public layoutService: LayoutService,
    private cdRef: ChangeDetectorRef,
    private errorService: ErrorService,
    private analytics4Service: GoogleAnalytics4Service,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  ngOnInit() {
    this.checkValidStoryGrade();

    this.getStudentStories();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
    if (this.dataLoaded && !this.isFocused) {
      this.focusEvent.emit(true);
      this.isFocused = true;
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty && !this.isSubmitting) {
      return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
    }
    return true;
  }

  getStudentStories() {
    const params = pickBy({ grade: this.grade });
    this.storyService.getStudentStories(params).subscribe(
      (response) => {
        this.stories = response;
        this.prepareStoryForm();
      },
      (error) => {
        this.routerService.goBack();
        this.errMsg = <any>error;
      },
    );
  }

  prepareStoryForm() {
    const storyId = +this.route.snapshot.params['id'];

    if (storyId) {
      this.findEditStory(storyId);
      this.getStoryCategory(this.editStory.story_category_id);
      this.getFormStory(this.editStory, this.editStory.story_category_id);
    } else {
      this.storyLoaded = true;
      const storyCategoryId = +this.route.snapshot.queryParams['story_category_id'];

      this.checkValidStoryCategory(storyCategoryId);
      this.getStoryCategory(storyCategoryId);
      this.getFormStory(new Story(), storyCategoryId);
    }
  }

  async checkValidStoryCategory(id: number) {
    const storyCategoryIdCreated = this.stories.map((s) => s.story_category_id);
    if (storyCategoryIdCreated.includes(id)) {
      const story = this.stories.find((s) => s.story_category_id === id);
      const query = this.grade ? `?grade=${this.grade}` : '';
      this.router.navigateByUrl(`/stories/student/${story.id}/edit${query}`, { replaceUrl: true });
      return;
    }

    await this.storyCategoriesService.getCategories();
    const subStoryCategoriesId = this.storyCategoriesService.subCategories.map((category) => category.id);

    if (!subStoryCategoriesId.includes(id)) {
      this.errorService.addError({
        error_code: 500,
        title: 'エラーが発生しました',
        message: 'システムエラーが発生しました',
        internalMessage: 'Invalid story_category_id',
      });
      this.router.navigateByUrl(this.storiesUrl);
    }
  }

  private checkValidStoryGrade(): void {
    const grade: string = this.route.snapshot.queryParams.grade;

    if (!grade || this.allowedGradeValues.includes(grade)) {
      this.grade = grade;
    } else {
      this.errorService.addError({
        error_code: 500,
        title: 'エラーが発生しました',
        message: 'システムエラーが発生しました',
        internalMessage: 'Invalid grade',
      });

      this.router.navigateByUrl(this.storiesUrl);
    }
  }

  getFormStory(story: Story, storyCategoryId: number) {
    this.form = this.formBuilder.group({
      id: [story.id || ''],
      story_category_id: [storyCategoryId],
      archive: [story.archive || '', [Validators.maxLength(5000)]],
      vision: [story.vision || '', [Validators.maxLength(5000)]],
      upload_files_attributes: this.formBuilder.array([]),
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.formErrors = validateForm(this.form, true, this.validationMessages);
      if (this.form.dirty) {
        this.routerService.registerBackConfirm(this.dataConfirm);
      }
    });
  }

  saveStory() {
    this.isSubmitting = true;
    const archive = this.form.get('archive').value;
    const vision = this.form.get('vision').value;
    this.form.get('archive').setValue(checkBlankString(archive) ? '' : archive);
    this.form.get('vision').setValue(checkBlankString(vision) ? '' : vision);

    if (this.editStory) {
      this.updateStory(this.form.value);
    } else {
      this.createStory(this.form.value);
    }
  }

  onSubmit(event: Event = null) {
    if (event) {
      event.preventDefault();
    }

    if (this.form.valid && !this.checkBlankData) {
      this.storyFileUploader.uploadAll();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  onBack() {
    this.routerService.goBack(this.dataConfirm);
  }

  createStory(story: Story) {
    const params: Story = Object.assign({}, story);
    if (this.grade) {
      params.grade = +this.grade;
    }
    this.storyService.createStory(params).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.routerService.resetDataConfirm();
          this.router.navigateByUrl(this.storiesUrl);
          this.toastService.showToast('作成しました');
          this.sendEvent('ストーリー作成画面', '保存');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  updateStory(story: Story) {
    this.storyService.updateStory(story).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.routerService.resetDataConfirm();
          this.router.navigateByUrl(this.storiesUrl);
          this.toastService.showToast('変更しました');
          this.sendEvent('ストーリー編集画面', '保存');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  getStoryCategory(storyCategoryId: number) {
    this.storyCategoriesService.getCategory(storyCategoryId).then((category) => {
      if (!!category) {
        this.storyCategory = category;
        this.storyCategoriesService.getCategory(category.parent_id).then((parent) => {
          this.storyParentCategory = parent;
          this.categoryLoaded = true;
        });
      }
    });
  }

  findEditStory(id: number) {
    this.stories.forEach((story) => {
      if (story.id === id) {
        this.editStory = story;
        this.storyLoaded = true;
      }
    });

    if (!this.editStory) {
      this.routerService.goBack();
    }
  }

  isAttachFile(anyFileExists: boolean) {
    this.anyFileExists = anyFileExists;
  }

  get checkBlankData(): boolean {
    return checkBlankString(this.form.value['archive'].concat(this.form.value['vision'])) && !this.anyFileExists;
  }

  get dataLoaded(): boolean {
    return this.storyLoaded && this.categoryLoaded;
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.dataConfirm);
    }
  }

  onUploadFilesStatus(status) {
    this.uploadFilesStatus = status;
    if (status === 'completed') {
      const uploadedFiles = this.storyFileUploader.sendUploadedFiles();
      uploadedFiles.forEach((file, index) => {
        Object.assign(this.form.value.upload_files_attributes[index], { uuid: file.uuid });
      });
      this.saveStory();
    }
  }

  showModalActivities(): void {
    this.showListActivityModal = true;

    const location = this.route.snapshot.params['id'] ? 'ストーリー編集画面' : 'ストーリー作成画面';
    this.sendEvent(location, '過去の活動記録を見る');
  }

  eventShowActivity(activityRecord: ActivityRecord): void {
    this.activityId = activityRecord.id;
    this.showActivityDetailModal = true;
  }

  eventSelectActivityModal(value: boolean): void {
    this.showListActivityModal = value;
  }

  eventForBackAction(action: string): void {
    this.showActivityDetailModal = false;

    if (action === 'close-modal') {
      this.showListActivityModal = false;
    }
  }

  private sendEvent(location: string, clickParts: string): void {
    this.analytics4Service.sendEvent('ストーリー', location, clickParts, {
      grade: this.grade,
      story_categories_name: this.storyCategory.name,
    });
  }

  private get storiesUrl(): string {
    const url = '/stories/student';
    const tab = `tab=${this.grade ? `grade_${this.grade}` : 'summary'}`;
    return `${url}?${tab}`;
  }
}
