import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivityRecordService } from './activity-record.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';
import { AssessmentModule } from '../assessment/assessment.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploaderModule } from '@components/file-uploader/file-uploader.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { ActivityRecordTeacherModule } from './activity-record-teacher.module';
import { ActivityRecordStudentModule } from './activity-record-student.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedDirectivesModule,
    FileUploaderModule,
    AssessmentModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    SharedPipesModule,
    ActivityRecordTeacherModule,
    ActivityRecordStudentModule,
  ],
  providers: [ActivityRecordService, SearchConditionSavingService],
})
export class ActivityRecordModule {}
