import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Album } from '@models/album';
import { Space } from '@components/space/space';

import { AlbumService } from '@components/album/album.service';
import { AlbumCreatorService } from '../album-creator.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { validateForm } from '@functions/validate-form';

@Component({
  selector: 'app-form-album-teacher-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../form-album-teacher.component.scss'],
})
export class Step2Component implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  dataLoaded = false;
  isShowSearchModal = false;
  validationMessages = {
    space_id: {
      required: '配信先スペースを選択してください。',
    },
  };
  errorMsg: string;
  backConfirmMessages = {
    title: '確認',
    content: 'このまま戻ると、作成中の内容は破棄されます。<br> 破棄しますか？',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    public creatorService: AlbumCreatorService,
    private albumService: AlbumService,
    private confirmDialogService: ConfirmDialogService,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.createAlbumForm(this.creatorService.album);
    this.dataLoaded = true;
    this.routerService.registerBackConfirm(this.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  createAlbumForm(album: Album, dirty = false) {
    this.form = this.formBuilder.group({
      space_id: [album.space_id || this.creatorService.space.id, [Validators.required]],
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe(() => {
      this.routerService.registerBackConfirm(this.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });

    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.backConfirmMessages);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.backConfirmMessages);
    }
    return true;
  }

  goBack() {
    this.creatorService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.nextStep();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 3 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep3State();
  }

  chooseSpace() {
    this.isShowSearchModal = true;
  }

  closeSearch() {
    this.isShowSearchModal = false;
  }

  onSelectSpace(space: Space) {
    this.isShowSearchModal = false;
    this.creatorService.space = space;
    this.form.get('space_id').markAsDirty();
    this.form.get('space_id').setValue(space.id);
  }
}
