import { Component, OnInit } from '@angular/core';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog-student',
  templateUrl: './confirm-dialog-student.component.html',
  styleUrls: ['./confirm-dialog-student.component.scss'],
})
export class ConfirmDialogStudentComponent implements OnInit {
  data = {
    title: '確認',
    content: '',
    acceptButton: '破棄する',
    cancelButton: 'キャンセル',
  };
  acceptButtonClass = 'is-primary';

  constructor(private confirmDialogService: ConfirmDialogService) {}

  ngOnInit() {
    this.confirmDialogService.eventShow.subscribe((obj) => {
      Object.assign(this.data, obj);
      this.setAcceptButtonClass(obj);
      this.confirmDialogService.isShow = true;
    });
  }

  submitModal(value: boolean) {
    this.confirmDialogService.isShow = false;
    this.confirmDialogService.closeConfirm(value);
  }

  get isShow() {
    return this.confirmDialogService.isShow;
  }

  private setAcceptButtonClass(options: any): void {
    if ('isDanger' in options) {
      this.acceptButtonClass = options.isDanger ? 'is-danger' : 'is-primary';
    }
  }
}
