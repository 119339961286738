<div class="stu-document-detail" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page">
    <h1 class="heading-text">
      <a (click)="onBack()"><i class="fa fa-chevron-left"></i></a>活動記録詳細
    </h1>
  </div>

  <div class="segments" *ngIf="isDocumentRecord()">
    <app-segment-view [tabOptions]="tabOptions" [tabBadge]="tabBadge" (switchTab)="switchTab($event)"></app-segment-view>
  </div>

  <ng-container *ngIf="isQuestionnaireRecord()">
    <div class="block-info">
      <div class="row">
        <div class="col-md-9 col-xs-9 block-time">
          <p>作成日時：{{ activityRecord?.created_at }}</p>
          <p *ngIf="!!activityRecord?.updated_at">最終更新：{{ activityRecord?.updated_at | date: 'yyyy/MM/dd HH:mm' }}</p>
          <p>［アンケート］</p>
        </div>
        <div class="col-md-3 col-xs-3" *ngIf="canEditQuestionnaire()" (click)="onEditRecord()">
          <div class="block-edit pull-right">
            <i class="fa fa-pencil"></i>
            <a class="edit">編集</a>
          </div>
        </div>
      </div>
    </div>
    <app-questionnaire-detail-student [questionnaire]="activityRecord.record"></app-questionnaire-detail-student>
  </ng-container>

  <ng-container *ngIf="isDocumentRecord()">
    <ng-container [ngSwitch]="currentTab">
      <ng-container *ngSwitchCase="'self'">
        <div class="block-info">
          <div class="block-time">
            <p>作成日時：{{ activityRecord?.created_at }}</p>
            <p *ngIf="!!activityRecord?.updated_at">最終更新：{{ activityRecord?.updated_at | date: 'yyyy/MM/dd HH:mm' }}</p>
            <p *ngIf="activityRecord?.is_answer">［課題］</p>
          </div>
          <div class="block-actions">
            <label class="spen-mod-select" [class.is-selecting]="isShowDropdownActions">
              <button class="spen-mod-btn is-default dropdown-toggle" (click)="isShowDropdownActions = !isShowDropdownActions">
                <p class="{{ getDevice() }}">&#9679;&#9679;&#9679;</p>
              </button>
              <div class="image-select-box">
                <ul class="option-list">
                  <li *ngIf="canEditRecord()" (click)="onEditRecord()">{{ activityRecord.is_answer ? '編集' : '編集・削除' }}</li>
                  <li (click)="onPublishRecord()">公開設定</li>
                </ul>
              </div>
            </label>
          </div>
        </div>
        <app-document-detail-student
          [document]="activityRecord?.record"
          [categoryName]="activityRecord?.category_name"
          [isCompassAnswer]="activityRecord?.is_compass_answer"
          [workType]="activityRecord?.work_type"
          [marks]="activityRecord?.marks"
          [document_answers]="activityRecord.record.document_answers"
        >
        </app-document-detail-student>
        <div class="block-standard" *ngIf="canShowAdditionalInfoSection()">
          <div class="block-standard-child" *ngIf="activityRecord?.albums?.length > 0">
            <p class="title">アルバム</p>
            <ng-container *ngFor="let album of activityRecord.albums; let last = last">
              <a class="spen-util-link" [routerLink]="['/albums/student', album.id]">{{ album.title }}</a>
              <span *ngIf="!last"> / </span>
            </ng-container>
          </div>
          <div class="block-standard-child" *ngIf="this.activityRecord.is_answer">
            <ng-container *ngIf="!!workPath; else deletedWork">
              <p class="title">課題</p>
              <a id="parent-work" class="spen-util-link" routerLink="{{ workPath }}" [queryParams]="{ isShowAnswer: false }">{{
                activityRecord?.work_name
              }}</a>
            </ng-container>
            <ng-template #deletedWork>
              <span id="parent-work">※課題は削除されました</span>
            </ng-template>
          </div>
          <div class="block-standard-child" *ngIf="activityRecord?.public_spaces?.length > 0">
            <p class="title">自主公開</p>
            <ng-container *ngFor="let space of activityRecord.public_spaces; let last = last">
              <a class="spen-util-link" [routerLink]="['/spaces/student', space.id]">{{ space.name }}</a>
              <span *ngIf="!last"> / </span>
            </ng-container>
          </div>
          <div class="block-standard-child" *ngIf="activityRecord.pickups && activityRecord.pickups.length > 0">
            <p class="title">ピックアップ</p>
            <div class="list-pickup" *ngFor="let pickup of activityRecord.pickups">
              <app-pickup-item-toggle [pickup]="pickup"></app-pickup-item-toggle>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'cross_assessment'">
        <app-student-cross-assessments [crossAssessments]="activityRecord.cross_assessments"> </app-student-cross-assessments>
      </ng-container>
      <ng-container *ngSwitchCase="'teacher_assessment'">
        <h2 class="assessments-header">先生からのコメント</h2>
        <ng-container
          *ngFor="let assessment of activityRecord.assessments; first as isFirst"
          [ngSwitch]="assessment !== edittingAssessment"
        >
          <app-student-assessment-detail
            *ngSwitchCase="true"
            [assessment]="assessment"
            [canEdit]="canEditAssessment(assessment)"
            [isFirst]="isFirst"
            (editAssessment)="onEditAssessment(assessment)"
          >
          </app-student-assessment-detail>
          <ng-container *ngSwitchCase="false">
            <app-assessment-form-student
              *ngIf="isOwnerRecord()"
              [activityRecordId]="activityRecord?.id"
              [assessment]="assessment"
              (cancelEditAssessment)="onCancelEditAssessment()"
              (updateOldAssessment)="onUpdateOldAssessment($event, assessment)"
              (deleteOldAssessment)="onDeleteOldAssessment(assessment)"
            >
            </app-assessment-form-student>
          </ng-container>
        </ng-container>

        <app-assessment-form-student
          *ngIf="isOwnerRecord() && !edittingAssessment"
          [activityRecordId]="activityRecord?.id"
          (saveNewAssessment)="onsaveNewAssessment($event)"
        >
        </app-assessment-form-student>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="block-last">
    <button class="spen-mod-btn" (click)="onBack()">戻る</button>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>

<app-publish-activity-modal
  *ngIf="showPublishActivityModal"
  [activityRecordId]="activityRecord.id"
  [publishedSpaces]="activityRecord.public_spaces"
  (eventClosePublishActivityModal)="closePublishActivityModal()"
  (eventPublishedToSpaces)="publishedToSpaces($event)"
>
</app-publish-activity-modal>
