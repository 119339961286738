import { Component, Input } from '@angular/core';
import { Work } from 'app/components/work/work';

@Component({
  selector: 'app-work-info-student',
  templateUrl: './work-info-student.component.html',
  styleUrls: ['./work-info-student.component.scss'],
})
export class WorkInfoStudentComponent {
  @Input() work: Work;

  constructor() {}
}
