import { Mark } from '@models/mark';
import { User } from '@models/user';
import { UploadFile } from '@models/upload-file';
import { DocumentAnswer } from '@models/document-answer';

export class Document {
  id: number;
  title: string;
  user: User;
  created_at: string;
  updated_at: string;
  content: string;
  category_id: number;
  category_name: string;
  upload_files: Array<UploadFile>;
  template_id: number;
  document_answers: Array<DocumentAnswer>;
  marks?: Array<Mark>;

  isExistedDocument(): boolean {
    return !!+this.id;
  }
}
