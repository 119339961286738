import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssessmentModule } from '../assessment/assessment.module';

import { SpacesListStudentComponent } from './spaces-list/spaces-list-student/spaces-list-student.component';
import { StudentSpaceDetailComponent } from './space-detail/student-space-detail/student-space-detail.component';
import { StudentActivityDetailComponent } from './space-detail/student-activity-detail/student-activity-detail.component';

@NgModule({
  declarations: [SpacesListStudentComponent, StudentSpaceDetailComponent, StudentActivityDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedPipesModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    AssessmentModule,
  ],
})
export class SpaceStudentModule {}
