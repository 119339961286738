import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { LayoutService } from '@components/layout/layout.service';
import { ActivityPickupService } from '@components/activity-pickup/activity-pickup.service';
import { Space } from '@components/space/space';
import { NextPageParams } from '../spaces-to-pickup-response';

@Component({
  selector: 'app-search-multi-space',
  templateUrl: './search-multi-space.component.html',
  styleUrls: ['../search-space.component.scss'],
})
export class SearchMultiSpaceComponent implements OnInit, OnDestroy {
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() activityRecordId: number;
  @Input() selectedSpaces: Array<Space>;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  searchedResults: Array<Space> = new Array<Space>();
  newSelectSpaces: Array<Space> = new Array<Space>();
  nextPageParams: NextPageParams;
  isShowError = false;
  isLoading = false;
  errMsg: string;

  constructor(
    private renderer: Renderer2,
    private activityPickupService: ActivityPickupService,
    public layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.newSelectSpaces.push(...this.selectedSpaces);
    this.onSearch();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onSearch(isSubmit = false) {
    if (this.isLoading) {
      return;
    }
    if (isSubmit) {
      this.searchedResults = [];
    }

    this.isLoading = true;
    this.nextPageParams = null;
    if (this.isShowError) {
      this.isShowError = false;
    }

    this.searchInput.nativeElement.value = this.searchInput.nativeElement.value.trim();
    const params = {
      keyword: !!this.searchInput ? this.searchInput.nativeElement.value : '',
    };
    this.activityPickupService.getListSpacesToPickup(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults = response.spaces_pickup;
        this.nextPageParams = response.meta;
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  onLoadMore() {
    if (this.isLoading || !this.nextPageParams) {
      return;
    }

    this.isLoading = true;

    const params = {
      keyword: this.nextPageParams.keyword,
      page: this.nextPageParams.next_page,
    };

    this.activityPickupService.getListSpacesToPickup(this.activityRecordId, params).subscribe(
      (response) => {
        this.searchedResults.push(...response.spaces_pickup);
        this.nextPageParams = response.meta;
        this.isLoading = false;
      },
      (error) => {
        this.errMsg = <any>error;
        this.isLoading = false;
      },
    );
  }

  onClose() {
    this.closeEvent.emit();
  }

  /** onSubmit()
   * case 1: check selectSpaces empty
   * case 2: selectSpace invalid => this.isShowError = true.
   * Case 3: valid => allow submit event
   **/

  onSubmit() {
    if (this.newSelectSpaces.length === 0) {
      this.isShowError = true;
    } else {
      for (const space of this.newSelectSpaces) {
        if (this.canNotPickup(space)) {
          this.isShowError = true;
          break;
        }
      }
    }

    if (!this.isShowError) {
      this.submitEvent.emit(this.newSelectSpaces);
    }
  }

  selectSpace(space: Space) {
    if (this.isShowError) {
      this.isShowError = false;
    }

    if (this.isSelectedSpace(space)) {
      this.newSelectSpaces = this.newSelectSpaces.filter((item) => item.id !== space.id);
    } else {
      this.newSelectSpaces.push(space);
    }
  }

  canNotPickup(space: Space) {
    return !space.teacher_can_pick_up || space.is_picked_up_by_current_teacher;
  }

  isSelectedSpace(space: Space) {
    return !!this.newSelectSpaces.find((s) => s.id === space.id);
  }
}
