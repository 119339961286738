import { Component, Input, Output, EventEmitter } from '@angular/core';

import { StoryComment } from '@models/story-comment';

@Component({
  selector: 'app-story-comment-detail-teacher',
  templateUrl: './story-comment-detail-teacher.component.html',
  styleUrls: ['./story-comment-detail-teacher.component.scss'],
})
export class StoryCommentDetailTeacherComponent {
  @Input() storyComment: StoryComment;
  @Input() canEdit: boolean;
  @Output() editStoryComment = new EventEmitter<StoryComment>();

  constructor() {}

  onEditStoryComment() {
    this.editStoryComment.emit();
  }
}
