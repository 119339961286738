import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { WorkService } from '../../work.service';
import { LayoutService } from '@components/layout/layout.service';
import { TeacherDeliveryWorkSpaceStudent } from '../teacher-work-detail/teacher-delivery-work-space-student';

@Component({
  selector: 'app-modal-list-student',
  templateUrl: './modal-list-student.component.html',
  styleUrls: ['./modal-list-student.component.scss'],
})
export class ModalListStudentComponent implements OnInit, OnDestroy {
  @Input() spaceId: number;
  @Input() workId: number;
  @Output() eventCancel = new EventEmitter<TeacherDeliveryWorkSpaceStudent>();

  errMsg: string;
  private nextUrl: string;
  firstStudentsLoaded = false;
  moreStudentsLoaded = true;
  students: Array<TeacherDeliveryWorkSpaceStudent>;
  students_count: number;

  constructor(
    public layoutService: LayoutService,
    private workService: WorkService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.workService.getNotAnswerStudents(this.spaceId, this.workId).subscribe(
      (response) => {
        this.students = response.not_answered_students;
        this.nextUrl = response.meta['next_url'];
        this.students_count = response.meta['not_answered_student_count'];
        this.firstStudentsLoaded = true;
      },
      (error) => (this.errMsg = <any>error),
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  onCancel(student?: TeacherDeliveryWorkSpaceStudent) {
    this.eventCancel.emit(student);
  }

  getMoreStudents() {
    if (!this.nextUrl || !this.moreStudentsLoaded) {
      return;
    }
    this.moreStudentsLoaded = false;

    this.workService.getMoreNotAnswerStudents(this.nextUrl).subscribe(
      (response) => {
        this.students = this.students.concat(response.not_answered_students);
        this.nextUrl = response.meta['next_url'];
        this.moreStudentsLoaded = true;
      },
      (error) => (this.errMsg = <any>error),
    );
  }

  getActivityRecordType(student) {
    if (!student.viewer_permission) {
      return 'record-permission';
    } else if (!student.answer_id) {
      return 'record-not-answer-yet';
    } else {
      return 'record-answered';
    }
  }
}
