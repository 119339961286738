import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '@components/layout/layout.service';
import { ActivatedRoute, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClassesFilterService } from '@components/school-grade-class-select/classes-filter.service';
import { TeacherAlbum } from '@components/album/teacher/teacher-albums-list/teacher-album';
import { AlbumService } from '@components/album/album.service';
import { pickBy, omit, mapValues, merge, isEqual, pick, isNil } from 'lodash';
import { SchoolGradeClassSelectComponent } from '@components/school-grade-class-select/school-grade-class-select.component';
import { AuthUserService } from '@services/auth-user.service';
import { ControlLinkService } from '@services/control-link/control-link.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

const IconSort = {
  none: 'fa',
  loader: 'fa fa-spinner fa-spin',
  up: 'fa fa-caret-up',
  down: 'fa fa-caret-down',
};

@Component({
  selector: 'app-teacher-albums-list',
  templateUrl: './teacher-albums-list.component.html',
  styleUrls: ['./teacher-albums-list.component.scss'],
  providers: [ClassesFilterService],
})
export class TeacherAlbumsListComponent implements OnInit {
  @ViewChild(SchoolGradeClassSelectComponent) formChild: SchoolGradeClassSelectComponent;

  filterForm: UntypedFormGroup;
  isMobileFilterOpen = false;
  queryParams = {};
  albums: Array<TeacherAlbum>;
  errMsg: string;
  sortKey = 'created_at';
  sortOrder = 'desc';
  messageFilter: string;
  messageSort: string;

  metaData = {
    per_page: 1,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
    total_count: 0,
    have_record_in_table: true,
  };

  typeSelections = [
    { value: null, text: 'すべて' },
    { value: 'self_create', text: '自主作成' },
    { value: 'teacher_create', text: '先生配信' },
  ];

  sortSelections = [
    { value: 'created_at desc', text: '作成日時 (降順)' },
    { value: 'created_at asc', text: '作成日時 (昇順)' },
    { value: 'updated_at desc', text: '更新日時 (降順)' },
    { value: 'updated_at asc', text: '更新日時 (昇順)' },
    { value: 'activity_records_count desc', text: '活動記録数 (降順)' },
    { value: 'activity_records_count asc', text: '活動記録数 (昇順)' },
  ];

  sortStatus = {
    created_at: IconSort.none,
    updated_at: IconSort.none,
    activity_records_count: IconSort.none,
  };

  formLoaded = false;
  listLoaded = false;
  isSorting = false;
  isSearching = false;
  private readonly allowedParamKeys = ['school_year', 'grade_id', 'class_id', 'album_type', 'keyword', 'page', 'sort_key', 'sort_order'];
  private readonly disallowedUrlParamKeys = ['keyword'];
  readonly saveConditionKeys = ['school_year', 'grade_id', 'class_id', 'sort_key', 'sort_order'];

  constructor(
    public layoutService: LayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private albumService: AlbumService,
    private authUserService: AuthUserService,
    private classFilterService: ClassesFilterService,
    private searchConditionSavingService: SearchConditionSavingService,
    private controlLinkService: ControlLinkService,
  ) {}

  ngOnInit() {
    this.loadList(this.route.snapshot.queryParams);
  }

  private async loadList(params: Params): Promise<void> {
    await this.classFilterService.getSchoolYears();
    await this.classFilterService.getGrades();

    const url = this.router.url.split('?')[0];
    let newParams = {};
    if (Object.keys(params).length === 0) {
      newParams = this.searchConditionSavingService.getSearchConditions(url);
      this.classFilterService.isReinitSelectData = true;
    } else {
      newParams = params;
      const saveConditions = pick(params, this.saveConditionKeys);
      if (!this.layoutService.isTabletDownView.value) {
        delete saveConditions['sort_key'];
        delete saveConditions['sort_order'];
      }
      this.searchConditionSavingService.saveSearchConditions(url, saveConditions);
    }

    this.setQueryParamsFilter(newParams);
    await this.initForm();
    this.initSortIcon();
    this.getAlbumsList(Object.assign(pickBy(this.queryParams), { filter_default: !this.isSearching }));
    this.buildMessageFilter(this.queryParams).then((message) => {
      this.messageFilter = message;
    });
    this.messageSort = this.buildMessageSort();
    this.isSearching = !this.isDefaultForm;
  }

  private navigate(params: Params, paramsHandling?: QueryParamsHandling): void {
    const apiParams = pickBy(params, (value) => !isNil(value));
    this.loadList(apiParams);

    const urlParams = omit(params, this.disallowedUrlParamKeys);
    const extras: NavigationExtras = { queryParams: urlParams, relativeTo: this.route, replaceUrl: true };
    if (paramsHandling !== undefined) {
      extras.queryParamsHandling = paramsHandling;
    }
    this.router.navigate(['.'], extras);
  }

  setQueryParamsFilter(params = {}) {
    this.queryParams = pick(params, this.allowedParamKeys);
    this.queryParams['school_year'] = params['school_year'] || this.getCurrentYear();
    this.queryParams['grade_id'] = +params['grade_id'] || this.classFilterService.grades.value[0].id;
    this.queryParams['class_id'] = +params['class_id'] || null;
  }

  getCurrentYear() {
    const currentUser = this.authUserService.retrieve();
    return this.classFilterService.schoolYears.value.includes(currentUser.schoolYear)
      ? currentUser.schoolYear
      : this.classFilterService.schoolYears.value[0];
  }

  async initForm() {
    const params = this.queryParams;
    await this.classFilterService.getClasses(params);

    this.filterForm = this.formBuilder.group({
      school_year: params['school_year'],
      grade_id: params['grade_id'],
      class_id: params['class_id'],
      album_type: params['album_type'] || this.typeSelections[0].value,
      keyword: params['keyword'] || '',
      sort: params['sort_key'] ? `${params['sort_key']} ${params['sort_order']}` : this.sortSelections[0].value,
    });
    this.formLoaded = true;
  }

  getAlbumsList(params = {}) {
    this.sortStatus = mapValues(this.sortStatus, () => IconSort.none);

    if (!this.isSorting) {
      this.listLoaded = false;
    } else {
      this.sortStatus[this.sortKey] = IconSort.loader;
    }

    this.albumService.getTeacherAlbums(pickBy(params)).subscribe(
      (response) => {
        this.albums = response.albums;
        Object.assign(this.metaData, response.meta);

        if (this.listLoaded) {
          this.isSorting = false;
        } else {
          this.listLoaded = true;
        }

        const icon = this.sortOrder === 'asc' ? 'up' : 'down';
        this.sortStatus[this.sortKey] = IconSort[icon];
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  pageChanged(page: number) {
    const params = merge(this.queryParams, { page: page });
    this.navigate(params, 'merge');
  }

  toggleFilter() {
    this.isMobileFilterOpen = !this.isMobileFilterOpen;
  }

  onSubmit() {
    this.trimTextInput();
    window.scrollTo(0, 0);
    this.isMobileFilterOpen = false;
    this.buildMessageFilter(this.queryParams).then((message) => {
      this.messageFilter = message;
    });
    const params = omit(this.filterForm.value, 'sort');
    params['class_id'] = +params['class_id'] || null;
    params['request_timestamp'] = Date.now();
    this.navigate(merge(params, this.buildSortParams()), 'merge');
  }

  onChangeOrder(field) {
    if (this.isSorting) {
      return;
    }

    if (this.sortKey === field) {
      this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortKey = field;
      this.sortOrder = 'desc';
    }

    this.filterForm.get('sort').setValue(`${this.sortKey} ${this.sortOrder}`);
    this.isSorting = true;

    this.navigate(merge(this.queryParams, this.buildSortParams()), 'merge');
  }

  onResetFilter() {
    if (this.layoutService.isTabletDownView.value) {
      [this.sortKey, this.sortOrder] = this.sortSelections[0].value.split(' ');
    }

    this.filterForm.patchValue(this.defaultFilterValues());
    this.onSubmit();
    this.formChild.onResetForm();
  }

  onClickAlbumItem(album, event: KeyboardEvent) {
    if (album.album_user.viewer_permission) {
      this.controlLinkService.open(`/albums/teacher/${album.id}/students/${album.album_user.id}`, event);
    }
  }

  onClickStudentName(album, event: KeyboardEvent) {
    if (album.album_user.viewer_permission) {
      event.stopPropagation();
      this.controlLinkService.open(`/students/teacher/${album.album_user.id}`, event);
    }
  }

  onChangeSortValue(value: string) {
    [this.sortKey, this.sortOrder] = value.split(' ');
  }

  private trimTextInput() {
    const keyword = this.filterForm.value.keyword;
    this.filterForm.get('keyword').setValue(keyword.trim());
  }

  buildSortParams() {
    const sortParams = { sort_key: this.sortKey, sort_order: this.sortOrder };
    if (this.queryParams['page']) {
      sortParams['page'] = null;
    }
    return sortParams;
  }

  private initSortIcon() {
    this.sortKey = this.queryParams['sort_key'] || this.sortKey;
    this.sortOrder = this.queryParams['sort_order'] || this.sortOrder;
    const icon = this.sortOrder === 'asc' ? 'up' : 'down';
    this.sortStatus[this.sortKey] = IconSort[icon];
  }

  defaultFilterValues() {
    const filterValues = {
      school_year: this.getCurrentYear(),
      grade_id: this.classFilterService.grades.value[0].id,
      class_id: null,
      album_type: this.typeSelections[0].value,
      keyword: '',
    };

    if (this.layoutService.isTabletDownView.value) {
      filterValues['sort'] = this.sortSelections[0].value;
    }

    return filterValues;
  }

  async buildMessageFilter(params = {}) {
    let gradeName = null;
    const textsArray = [];

    const schoolYear = this.filterForm.get('school_year').value + '年度';
    const grade = this.classFilterService.grades.value.find((item) => {
      return item.id === +params['grade_id'];
    });

    if (grade) {
      gradeName = grade.school_stage_name + grade.name;
    } else {
      gradeName = this.classFilterService.grades[0].school_stage_name + this.classFilterService.grades[0].name;
    }
    if (!!this.classFilterService.classes.value && !this.listLoaded) {
      await this.classFilterService.getClasses(params);
    }

    const _class = this.classFilterService.classes.value.find((item) => {
      return item.id === +params['class_id'];
    });
    const className = _class ? _class.classroom_name : 'すべての組';
    textsArray.push(schoolYear, gradeName, className);

    const albumType = this.typeSelections.find((item) => {
      return item.value === this.filterForm.get('album_type').value;
    });
    const typeText = (albumType && albumType.text) || this.typeSelections[0].text;
    if (typeText !== 'すべて') {
      textsArray.push(typeText);
    }

    const keyword = this.filterForm.get('keyword').value;
    if (keyword && keyword.length > 0) {
      textsArray.push(keyword);
    }

    return textsArray.join('/');
  }

  buildMessageSort() {
    const match = this.sortSelections.find((item) => {
      return item.value === `${this.sortKey} ${this.sortOrder}`;
    });
    return match && match.text;
  }

  get isDefaultForm(): boolean {
    const compareValues = this.filterForm.value;

    if (!this.layoutService.isTabletDownView.value) {
      delete compareValues['sort'];
    }

    return isEqual(compareValues, this.defaultFilterValues());
  }

  get showPagination(): boolean {
    return this.listLoaded && this.metaData.total_pages > 1;
  }

  get isEmptyList(): boolean {
    return this.listLoaded && this.metaData.total_count === 0;
  }

  get isLoadingData(): boolean {
    return this.classFilterService.isLoading || !this.listLoaded || this.isSorting;
  }

  get haveAlbumsInDB(): boolean {
    return this.metaData.have_record_in_table;
  }
}
