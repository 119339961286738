import { AbstractControl } from '@angular/forms';

export function minArrayLength(minLength: number, inputArray: Array<Object>) {
  return (control: AbstractControl): { [key: string]: any } => {
    if (inputArray.length >= minLength) {
      return;
    }
    return { minArrayLength: { valid: false } };
  };
}
