import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthUserService } from '@services/auth-user.service';
import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { SlideMenuService } from '@services/slide-menu.service';
import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-student-sidebar',
  templateUrl: './student-sidebar.component.html',
  styleUrls: ['./student-sidebar.component.scss'],
})
export class StudentSidebarComponent implements OnInit {
  statisticsSubscription: Subscription;
  readonly createResultUrl = environment.studentCreateResultUrl;
  isDeliveredGraduationMessage = false;

  constructor(
    private googleAnalytics4Service: GoogleAnalytics4Service,
    public authUserService: AuthUserService,
    public slideMenuService: SlideMenuService,
    private graduationMessageService: GraduationMessageService,
  ) {}

  ngOnInit(): void {
    this.graduationMessageService.getStudentIsDeliverdGraduationMessages().subscribe((response) => {
      this.isDeliveredGraduationMessage = response.is_delivered;
    });
  }

  hideSidebar() {
    this.slideMenuService.slideOut();
  }

  redirectToRegisterResultSystem() {
    let url = this.createResultUrl;
    if (this.authUserService.isSystemAuthenticated) {
      url = url.concat(`?sk=${this.authUserService.sessionKey}`);
    }
    window.open(url, '_blank');
    this.hideSidebar();
  }

  sendGAForResultActivity() {
    this.googleAnalytics4Service.sendEvent('成果の記録', 'サイドメニュー', '成果の記録');
  }
}
