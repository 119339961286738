import version from '../../codegen/version';

export const environment = {
  production: true,
  apiEndpoint: 'https://portfolio.stg.classi.jp/api/v1',
  apiEndpointV2: 'https://portfolio.stg.classi.jp/api/v2',
  googleAnalytics4Key: 'G-7VXZXKEZ8J',
  itemsPerPage: 30,
  maxNumberStories: 14,
  platformUrl: 'https://platform.stg.classi.jp',
  authUrl: 'https://auth.stg.classi.jp',
  helpPageUrl: 'https://support.classi.jp/hc/ja/categories/360000184115',
  sentry: {
    dsn: 'https://c9d8b26126f643c595fb4632eacb2ced@sentry.io/293774',
    environment: 'staging',
    release: version,
  },
  studentCreateResultUrl: 'https://manabi-ut3.benesse.ne.jp/agreement/classi/',
  teacherResultConfirmUrl: 'https://cmps-t.benesse.ne.jp/cmps/classi/pf.html',
  teacherVideoAttached1Url: 'https://platform.stg.classi.jp/api/guide/download/252',
  teacherVideoAttached2Url: 'https://platform.stg.classi.jp/api/guide/download/249',
  teacherVideoAttached3Url: 'https://platform.stg.classi.jp/api/guide/download/250',
  wordCloudImageUrl: 'https://textmining.userlocal.jp/apis/v2/results/iframe/chart/word_cloud',
  timeZone: 9,
  googleTagManagerId: 'GTM-5VG9ZSD',
};
