export function checkPresentString(input) {
  return !!input && !!input.trim();
}

export function checkBlankString(input) {
  return !input || !input.trim();
}

export function getFileExtension(fileName): string {
  return (fileName.toLowerCase().match(/\.([^.]*)$/) || [])[1];
}
