import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@components/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoverImageUploaderModule } from './form-album/cover-image-uploader/cover-image-uploader.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssessmentModule } from '@components/assessment/assessment.module';

import { AlbumDetailTeacherComponent } from './album-detail-teacher/album-detail-teacher.component';
import { FormAlbumTeacherComponent } from './form-album/form-album-teacher.component';
import { Step1Component } from './form-album/step1/step1.component';
import { Step2Component } from './form-album/step2/step2.component';
import { Step3Component } from './form-album/step3/step3.component';
import { TeacherAlbumsListComponent } from './teacher-albums-list/teacher-albums-list.component';
import { TeacherDeliveredAlbumDetailComponent } from './teacher-delivered-album-detail/teacher-delivered-album-detail.component';
import { DeliveredAlbumsListComponent } from '@components/album/teacher/delivered-albums-list/delivered-albums-list.component';
import { ActivityViewDetailComponent } from './album-detail-teacher/activity-view-detail/activity-view-detail.component';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FormAlbumTeacherComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    AlbumDetailTeacherComponent,
    TeacherAlbumsListComponent,
    TeacherDeliveredAlbumDetailComponent,
    DeliveredAlbumsListComponent,
    ActivityViewDetailComponent,
  ],
  exports: [AlbumDetailTeacherComponent, FormAlbumTeacherComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CoverImageUploaderModule,
    SharedDirectivesModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    AssessmentModule,
    SharedPipesModule,
  ],
  providers: [],
})
export class AlbumTeacherModule {}
