<div class="student-story-detail">
  <div class="stories-content" *ngIf="this.dataLoaded; else loadingIcon">
    <div class="category" *ngFor="let category of this.categoriesWithStory">
      <div class="category-heading">
        <div class="icon">
          <img src="{{ category.icon }}" alt="" />
        </div>
        <h3 class="category-name">
          {{ category.name }}
        </h3>
      </div>
      <div class="sub-categories">
        <div class="sub-category" *ngFor="let subCategory of category.sub_categories">
          <div class="sub-category-detail">
            <div class="sub-category-content">
              <div class="sub-category-name">
                {{ subCategory.name }}
              </div>
              <div class="story-time">
                <p>作成日時： {{ !!subCategory.story ? subCategory.story.created_at : '-' }}</p>
                <p>更新日時： {{ !!subCategory.story ? this.getUpdatedAt(subCategory.story) : '-' }}</p>
              </div>
            </div>
            <a
              class="spen-util-link story-icon"
              (click)="sendEvent('作成する', subCategory.name)"
              *ngIf="!subCategory.story; else showStory"
              [routerLink]="['/stories/student/new']"
              [queryParams]="{ grade: this.grade, story_category_id: subCategory.id }"
            >
              {{ layoutService.isTabletDownView.value ? '作成' : '作成する' }}
            </a>
            <ng-template #showStory>
              <span class="story-icon">
                <i
                  [ngClass]="isShowStory[subCategory.story.id] ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
                  (click)="onChangeShowStory(subCategory.story.id)"
                ></i>
              </span>
            </ng-template>
          </div>

          <div class="story-detail" *ngIf="subCategory.story && isShowStory[subCategory.story.id]">
            <div [class.story-vision]="subCategory.story.archive || subCategory.story.upload_files.length" *ngIf="subCategory.story.vision">
              <p class="story-title">目標</p>
              <div class="preserve-line-break break-word story-content">{{ subCategory.story.vision }}</div>
            </div>
            <ng-container *ngIf="subCategory.story.archive || subCategory.story.upload_files.length">
              <p class="story-title">やったこと・考えたこと</p>
              <div class="preserve-line-break break-word story-content" *ngIf="subCategory.story.archive">
                {{ subCategory.story.archive }}
              </div>
              <div class="story-file-views" *ngIf="subCategory.story.upload_files">
                <app-story-files-view [files]="subCategory.story.upload_files"></app-story-files-view>
              </div>
            </ng-container>
            <a
              class="spen-util-link story-icon"
              [routerLink]="['/stories/student/', subCategory.story.id, 'edit']"
              [queryParams]="{ grade: this.grade }"
              (click)="sendEvent('編集する', subCategory.name)"
            >
              {{ layoutService.isTabletDownView.value ? '編集' : '編集する' }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <h2 class="teacher-comment" id="story-comment">先生からのひとこと</h2>
    <app-story-comment-list-student [grade]="this.grade"></app-story-comment-list-student>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
