import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Manavision } from '@models/manavision';
import { CategoriesService } from '@services/categories/categories.service';

@Component({
  selector: 'app-student-mana-vision-detail',
  templateUrl: './student-mana-vision-detail.component.html',
  styleUrls: ['./student-mana-vision-detail.component.scss'],
})
export class StudentManaVisionDetailComponent implements OnInit, OnDestroy {
  @Input() manaVision: Manavision;
  @Input() isPageLayout = false;
  @Output() closeEvent = new EventEmitter();

  categoryNames = [];

  constructor(
    private renderer: Renderer2,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'modal-open');
    this.getCategoryNames();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  getCategoryNames() {
    this.categoriesService.getCategoryNames(this.manaVision.category_ids).then((names) => (this.categoryNames = names));
  }

  onClose() {
    this.closeEvent.emit();
  }
}
