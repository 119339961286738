import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Action } from '@components/timeline/action';
import { timeStringToDateString } from '@functions/date-formatter';
import { SpaceService } from '@components/space/space.service';
import { omit } from 'lodash';

@Component({
  selector: 'app-space-timeline',
  templateUrl: './space-timeline.component.html',
  styleUrls: ['./space-timeline.component.scss'],
})
export class SpaceTimelineComponent implements OnInit, OnDestroy {
  meta = {};
  actionsLoading = false;
  contentLoaded = false;
  timelineMap = {};
  spaceId: number;
  subscriber = null;

  constructor(
    private route: ActivatedRoute,
    private spaceService: SpaceService,
  ) {}

  ngOnInit() {
    this.subscriber = this.route.queryParams.subscribe((params) => {
      // It prevent the case: User click on current space's link in action cassette.
      if (params['tab'] !== 'timeline' || this.contentLoaded) {
        return;
      }

      this.spaceId = +this.route.snapshot.paramMap.get('id');
      this.getActions();
    });
  }

  getActions(params = {}) {
    if (this.actionsLoading) {
      return;
    }
    this.actionsLoading = true;
    this.spaceService.getSpaceTimeline(this.spaceId, params).subscribe(
      (response) => {
        this.updateTimelineMap(response.actions, this.timelineMap);
        this.meta = response.meta;
        this.contentLoaded = true;
        this.actionsLoading = false;
      },
      (error) => {
        // TODO: add error handling.
      },
    );
  }

  updateTimelineMap(actions: Array<Action> = [], timelineMap = {}): void {
    actions.forEach((action) => {
      const createTime = timeStringToDateString(action.last_created_at);

      if (!timelineMap[createTime]) {
        timelineMap[createTime] = [];
      }

      timelineMap[createTime].push(action);
    });
  }

  loadMore() {
    this.getActions(omit(this.meta, 'next_action_date'));
  }

  get timelineDates(): string[] {
    return Object.keys(this.timelineMap);
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }
}
