import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@components/shared.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';

import { TimelineService } from './timeline.service';
import { SearchConditionSavingService } from '@services/search-condition-saving.service';

import { TeacherTimelineComponent } from '@components/timeline/teacher-timeline/teacher-timeline.component';

@NgModule({
  declarations: [TeacherTimelineComponent],
  exports: [TeacherTimelineComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxPaginationModule, SharedModule, SharedDirectivesModule, SharedPipesModule],
  providers: [TimelineService, SearchConditionSavingService],
})
export class TimelineModule {}
