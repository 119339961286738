import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ActivityPickupsResponse } from './activity-pickups.response';

import { environment } from '@environments/environment';

@Injectable()
export class TeacherPickupService {
  apiEndpointV2 = environment.apiEndpointV2;

  constructor(private http: HttpClient) {}

  loadPickups(activityId: number, params = {}): Observable<ActivityPickupsResponse> {
    const url = `${this.apiEndpointV2}/teacher/activity_records/${activityId}/activity_pickups`;
    return this.http.get<ActivityPickupsResponse>(url, { params: params });
  }
}
