import { Component, Input } from '@angular/core';

import { StoryComment } from '@models/story-comment';

@Component({
  selector: 'app-story-comment-detail-student',
  templateUrl: './story-comment-detail-student.component.html',
  styleUrls: ['./story-comment-detail-student.component.scss'],
})
export class StoryCommentDetailStudentComponent {
  @Input() storyComment: StoryComment;

  constructor() {}
}
