import { environment } from '@environments/environment';

const apiEndpointV2 = environment.apiEndpointV2;

export const teacherNavigationConfigs = {
  // PT501: work detail => PT201: activity record detail
  1: {
    path: '/activity-records/teacher/:id',
    aliasId: 'id',
    listText: '配信課題詳細',
    urlAPI: ({ work_id, space_id }) => `${apiEndpointV2}/teacher/delivered_works/${work_id}/spaces/${space_id}/activity_records/navigate`,
  },

  // PT200: activity record list => PT201: activity record detail
  2: {
    path: '/activity-records/teacher/:id?tab=cross_assessment',
    aliasId: 'id',
    listText: '活動記録一覧',
    urlAPI: ({}) => `${apiEndpointV2}/teacher/activity_records/navigate`,
  },

  // PT100: student list => PT101: student detail
  3: {
    path: '/students/teacher/:id',
    aliasId: 'id',
    listText: '生徒一覧',
    urlAPI: ({}) => `${apiEndpointV2}/teacher/students/navigate`,
  },

  // PT800: student story list => PT801: student story detail
  4: {
    path: '/stories/teacher/students/:student_id',
    aliasId: 'student_id',
    listText: 'ストーリー一覧',
    urlAPI: ({}) => `${apiEndpointV2}/teacher/stories/navigate`,
  },

  // PT1000: graduation message list => PT1001: graduation message detail
  5: {
    path: '/graduation-messages/teacher/:id',
    aliasId: 'id',
    listText: '卒業メッセージ一覧',
    urlAPI: ({}) => `${apiEndpointV2}/teacher/graduation_messages/navigate`,
  },

  // PT405: graduation message list => PT1001: graduation message detail
  6: {
    path: '/graduation-messages/teacher/:id',
    aliasId: 'id',
    listText: '卒業メッセージ',
    urlAPI: ({}) => `${apiEndpointV2}/teacher/graduation_messages/navigate`,
  },
};
