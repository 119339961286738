import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkEditorService } from '@components/work/edit-work/work-editor.service';
import { LayoutService } from '@components/layout/layout.service';
import { ToastService } from '@services/toast/toast.service';
import { RouterService } from '@services/router.service';
import { WorkService } from '@components/work/work.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-work-step2',
  templateUrl: './edit-work-step2.component.html',
  styleUrls: ['../edit-work.component.scss'],
})
export class EditWorkStep2Component {
  workUpdateStatus: 'none' | 'submitting' | 'completed' = 'none';

  constructor(
    private workService: WorkService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private routerService: RouterService,
    public creatorService: WorkEditorService,
    public layoutService: LayoutService,
  ) {}

  onBack() {
    this.creatorService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
  }

  onSubmit() {
    this.workUpdateStatus = 'submitting';
    this.workService.updateWork(this.creatorService.work).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.workUpdateStatus = 'completed';
          this.routerService.resetDataConfirm();
          this.routerService.goBackSpecific(`/works/teacher/delivery/${this.creatorService.work.id}`);
          this.toastService.showToast('変更しました');
        }
      },
      (error) => {
        this.workUpdateStatus = 'none';
      },
    );
  }

  get deliveryText() {
    const delivered_at = this.creatorService.work.delivered_at;
    if (!delivered_at) {
      return 'すぐに配信する';
    }
    const dayOfWeek = new Date(delivered_at).toLocaleString('ja-JP', { weekday: 'short' });
    return delivered_at.replace(' ', `（${dayOfWeek}）`);
  }

  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  get disableButtons(): boolean {
    return this.workUpdateStatus !== 'none';
  }

  isTabletAndDown() {
    return this.layoutService.isTabletDownView.value;
  }
}
