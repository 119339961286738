<div class="space-tab" *ngIf="!layoutService.isTabletDownView.value; else tabletView">
  <ul class="spen-mod-tab-lv-1">
    <ng-container *ngFor="let tab of tabKeys">
      <li class="is-current" data-cy="tab" *ngIf="isActiveTab(tab); else deactiveTab">
        <a class="badge-noti" data-cy="tab-badge" *ngIf="tabBadge[tab]"></a>
        <p>{{ tabValue(tab) }}</p>
      </li>
      <ng-template #deactiveTab>
        <li (click)="onChangeTab(tab)" class="tab-change-trigger" data-cy="tab">
          <a class="badge-noti" data-cy="tab-badge" *ngIf="tabBadge[tab]"></a>
          <p>{{ tabValue(tab) }}</p>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</div>

<ng-template #tabletView>
  <div class="space-tab-mobile">
    <ul class="scroll-menu">
      <li
        data-cy="tab"
        *ngFor="let tab of tabKeys"
        [id]="getTabHtmlId(tab)"
        [class.is-current]="isActiveTab(tab)"
        (click)="onChangeTab(tab)"
      >
        <p *ngIf="isActiveTab(tab)">{{ tabValue(tab) }}</p>
        <a class="tab-change-trigger" *ngIf="!isActiveTab(tab)">{{ tabValue(tab) }}</a>
      </li>
    </ul>
  </div>
</ng-template>
