<div *ngIf="routerDataService.routerData.value.isShowLink && studentNavigationService.canDisplayNavigator" class="row">
  <div class="col-12">
    <span class="navigator" [class.disable-link]="studentNavigationService.isLoading">
      <a (click)="onClickNavigate('prev')" *ngIf="studentNavigationService.prev" class="navigator-link">
        <i class="fa fa-chevron-left"></i>
        <span class="spen-util-link truncate-text">
          {{ layoutService.isTabletDownView.value ? '前へ' : studentNavigationService.prev.label }}
        </span>
      </a>
    </span>

    <span class="text-center" [class.link-margin-pc]="!layoutService.isTabletDownView.value">
      <a (click)="onClickNavigate('list')" class="spen-util-link">{{ studentNavigationService.list.label }}</a>
    </span>

    <span class="navigator" [class.disable-link]="studentNavigationService.isLoading">
      <a (click)="onClickNavigate('next')" class="navigator-link" *ngIf="studentNavigationService.next">
        <span class="spen-util-link truncate-text">
          {{ layoutService.isTabletDownView.value ? '次へ' : studentNavigationService.next.label }}
        </span>
        <i class="fa fa-chevron-right"></i>
      </a>
    </span>
  </div>
</div>
