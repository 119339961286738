import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@models/user';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalytics4Service {
  private containerId = environment.googleAnalytics4Key;

  initGA4(user: User) {
    this.setGA4Tag();

    gtag('js', new Date());
    gtag('config', this.containerId);
    gtag('set', 'user_properties', user.ga4);
  }

  sendEvent(subFunction: string, location: string, clickParts: string, params?: { [key: string]: string | number | boolean }): void {
    gtag('event', 'custom_event', {
      function: 'ポートフォリオ',
      sub_function: subFunction,
      location: location,
      click_parts: clickParts,
      ...params,
    });
  }

  private setGA4Tag(): void {
    const ga4Child: HTMLScriptElement = document.createElement('script');
    ga4Child.async = true;
    ga4Child.src = `https://www.googletagmanager.com/gtag/js?id=${this.containerId}&l=gDataLayer`;

    const refChild = document.getElementsByTagName('script')[0];
    refChild.parentNode.insertBefore(ga4Child, refChild);
  }
}
