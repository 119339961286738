import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GraduationMessage } from '@components/graduation-message/graduation-message';
import { GraduationMessageService } from '@components/graduation-message/graduation-message.service';
import { Space } from '@components/space/space';
import { TeacherSpacePickerService } from '@components/teacher/space-picker/teacher-space-picker.service';
import { validateForm } from '@functions/validate-form';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { RouterService } from '@services/router.service';
import { Observable } from 'rxjs';
import { GraduationMessageFormService } from '../graduation-message-form.service';

@Component({
  selector: 'app-graduation-message-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../graduation-message-form.component.scss', './step2.component.scss'],
  providers: [
    {
      provide: TeacherSpacePickerService,
      useClass: GraduationMessageService,
    },
  ],
})
export class Step2Component implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  formErrors: { [key: string]: Array<string> } = {};
  formInited = false;

  isShowSpacePickerModal = false;

  errorMsg: string;

  validationMessages = {
    space_id: {
      required: '配信先スペースを選択してください。',
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public creatorService: GraduationMessageFormService,
    private confirmDialogService: ConfirmDialogService,
    private routerService: RouterService,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    const currentStep = this.creatorService.currentStep;
    if (currentStep !== 2) {
      this.goToStep1();
      return;
    }
    this.createGraduationMessageForm(this.creatorService.graduationMessage);
    this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty) {
      return this.confirmDialogService.showConfirmDeactive(this.creatorService.backConfirmMessages);
    }
    return true;
  }

  createGraduationMessageForm(graduationMessage: GraduationMessage, dirty = false) {
    this.form = this.formBuilder.group({
      space_id: [graduationMessage.space_id || this.creatorService.space.id, [Validators.required]],
    });

    this.formErrors = {};
    this.formInited = true;
    this.form.valueChanges.subscribe(() => {
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
      this.formErrors = validateForm(this.form, true, this.validationMessages);
      this.creatorService.graduationMessage = Object.assign(this.creatorService.graduationMessage, this.form.value);
    });
    if (dirty) {
      this.form.markAsDirty();
      this.routerService.registerBackConfirm(this.creatorService.backConfirmMessages);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.form.get('space_id').updateValueAndValidity();
    this.formErrors = validateForm(this.form, false, this.validationMessages);
    if (this.form.valid) {
      this.nextStep();
    }
  }

  goBack() {
    this.creatorService.setStep1State();
    this.goToStep1();
  }

  goToStep1() {
    this.ngZone.run(() => {
      this.router.navigate(['.'], { queryParams: { step: 1 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    });
  }

  nextStep() {
    this.routerService.disableSetPreviousHref();
    this.router.navigate(['.'], { queryParams: { step: 3 }, queryParamsHandling: 'merge', relativeTo: this.route, replaceUrl: true });
    this.creatorService.setStep3State();
  }

  toggleSpacesModal(open: boolean) {
    this.isShowSpacePickerModal = open;
  }

  onSelectSpace(space: Space) {
    this.isShowSpacePickerModal = false;
    this.creatorService.space = space;
    this.form.get('space_id').markAsDirty();
    this.form.get('space_id').setValue(space.id);
  }

  get spaceUtilLabel() {
    return this.form.value.space_id ? 'スペースを変更する' : 'スペースを選択する';
  }
}
