import { Component, OnInit, EventEmitter, ViewChild, OnDestroy, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { Document } from '@components/document/document';
import { Category } from '@models/category';
import { Template } from '@models/template';
import { Mark } from '@models/mark';

import { validateForm } from '@functions/validate-form';
import { parseJsonList } from '@functions/parse-json-list';

import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { MarksSelectorComponent } from '@components/marks-selector/marks-selector.component';

import { GoogleAnalytics4Service } from '@services/google-analytics-4.service';
import { DocumentService } from '../document.service';
import { CategoriesService } from '@services/categories/categories.service';
import { TemplatesService } from '@services/templates/templates.service';
import { MarksService } from '@services/marks/marks.service';
import { ConfirmDialogService } from '@services/confirm-dialog/confirm-dialog.service';
import { AuthUserService } from '@services/auth-user.service';
import { RouterService } from '@services/router.service';
import { ToastService } from '@services/toast/toast.service';
import { ActivityRecord } from '@components/activity-record/activity-record';

import * as _ from 'lodash';
import { ContentSavingService } from '@services/content-saving.service';

@Component({
  selector: 'app-form-document',
  templateUrl: './form-document.component.html',
  styleUrls: ['./form-document.component.scss'],
  providers: [DocumentService, TemplatesService],
})
export class FormDocumentComponent implements OnInit, AfterViewChecked, OnDestroy {
  document: Document = new Document();
  form: UntypedFormGroup;
  isSubmitting = false;

  errMsg: string;
  focusEvent = new EventEmitter<boolean>();
  formErrors: { [key: string]: Array<string> } = {};
  validationMessages = {
    title: {
      required: 'タイトルを入力してください。',
      maxlength: 'タイトルは50文字以内で入力してください。',
    },
    content: {
      required: '本文を入力してください。',
      maxlength: '本文は5000文字以内で入力してください。',
    },
  };
  categories: Array<Category> = [];
  marks: Array<Mark> = [];
  templates: Array<Template> = [];
  albumId: Number;
  templateTitle: string;
  uploadFilesStatus: string;
  lengthOfContent = 0;
  documentLoaded = false;
  templatesLoaded = false;
  categoriesLoaded = false;
  marksLoaded = false;
  isFocused = false;
  isSubmitted = false;
  dataConfirm = {
    title: '確認',
    content: '入力内容を下書き保存し、画面を移動します。',
    acceptButton: '移動する',
    cancelButton: 'キャンセル',
  };
  dataConfirmForCannotUseLocalStorage = {
    title: '確認',
    content: '推奨環境でないため下書き保存はできませんが、画面を移動しますか？',
    acceptButton: '移動する',
    cancelButton: 'キャンセル',
  };

  showListActivityModal = false;
  showActivityDetailModal = false;
  activityId: number;
  currentManaVision: ActivityRecord;

  @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;
  @ViewChild(MarksSelectorComponent) marksSelector: MarksSelectorComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private documentService: DocumentService,
    private templatesService: TemplatesService,
    private analytics4Service: GoogleAnalytics4Service,
    private categoryService: CategoriesService,
    private confirmDialogService: ConfirmDialogService,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
    private authUserService: AuthUserService,
    private routerService: RouterService,
    private marksService: MarksService,
    private toastService: ToastService,
    private contentSavingService: ContentSavingService,
  ) {}

  ngOnInit() {
    this.prepareDocumentForm();
  }

  ngAfterViewChecked() {
    if (this.dataLoaded() && !this.isFocused) {
      this.contentSavingService.startService(this.router.url, this.form, this.marksSelector, '自主作成');
      if (!this.contentSavingService.haveTemporaryData()) {
        this.focusEvent.emit(true);
        this.isFocused = true;
      } else {
        this.contentSavingService.confirmed$.subscribe(() => {
          this.focusEvent.emit(true);
          this.isFocused = true;
        });
      }
    }
  }

  ngOnDestroy() {
    this.routerService.resetDataConfirm();
    this.contentSavingService.stopService();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.form && this.form.dirty && !this.isSubmitted) {
      if (localStorage) {
        return this.confirmDialogService.showConfirmDeactive(this.dataConfirm);
      } else {
        return this.confirmDialogService.showConfirmDeactive(this.dataConfirmForCannotUseLocalStorage);
      }
    }

    return true;
  }

  async prepareDocumentForm() {
    const documentId = this.route.snapshot.params['document_id'];
    if (documentId) {
      const document = await this.getDocumentInfo(documentId);
      Object.assign(this.document, document);
      if (!document || this.authUserService.retrieve().id !== this.document.user.id) {
        return;
      }
    }
    const albumId = this.route.snapshot.queryParams['album_id'];
    if (!!albumId) {
      this.albumId = +albumId;
    }
    this.documentLoaded = true;
    this.loadTemplates();
    this.loadMarks();
    this.loadCategories();
    this.getFormDocument(this.document);
  }

  async getDocumentInfo(documentId: number) {
    try {
      const document = await this.documentService.getDocument(documentId).toPromise();
      if (!document.content) {
        document.content = '';
      }
      this.lengthOfContent = document.content.length;
      return document;
    } catch (error) {
      this.errMsg = <any>error;
      return null;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.fileUploader.uploadAll();
    } else {
      this.formErrors = validateForm(this.form, false, this.validationMessages);
    }
  }

  getFormDocument(document: Document) {
    this.form = this.formBuilder.group({
      id: [document.id || ''],
      title: [document.title || '', [Validators.required, Validators.maxLength(50)]],
      content: [document.content || '', [Validators.required, Validators.maxLength(5000)]],
      category_id: [document.category_id || null],
      template_id: [null],
      activity_records_marks_attributes: this.formBuilder.array([]),
      upload_files_attributes: this.formBuilder.array([]),
      albums_activity_records_attributes: this.formBuilder.array([]),
    });
    this.formErrors = {};
    this.form.valueChanges.subscribe((data) => {
      this.routerService.registerBackConfirm(this.dataConfirm);
      this.lengthOfContent = this.form.get('content').value.length;
      this.formErrors = validateForm(this.form, true, this.validationMessages);
    });
  }

  onBack() {
    if (localStorage) {
      this.routerService.goBack(this.dataConfirm, true);
    } else {
      this.routerService.goBack(this.dataConfirmForCannotUseLocalStorage, true);
    }
  }

  loadTemplates() {
    this.templatesService.getTemplates().subscribe(
      (response) => {
        this.templates = parseJsonList(Template, response['templates']);
        this.templatesLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  loadMarks() {
    this.marksService.getMarks().subscribe(
      (response) => {
        this.marks = response['marks'];
        this.marksLoaded = true;
      },
      (error) => {
        this.errMsg = <any>error;
      },
    );
  }

  async loadCategories() {
    this.categoryService.loadCategories().then((categories) => {
      if (!!categories) {
        this.categories = categories;
        this.categoriesLoaded = true;
      }
    });
  }

  createDocument(data) {
    if (!!this.albumId) {
      Object.assign(data, { album_id: this.albumId });
    }
    this.documentService.createDocument(data).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.sendGACreateDocument(data);
          this.isSubmitted = true;
          this.routerService.resetDataConfirm();
          const navigateUrl = `/activity-records/student/${response['activity_record_id']}`;
          this.router.navigateByUrl(navigateUrl, { replaceUrl: true });
          this.contentSavingService.removeTemporaryData();
          this.toastService.showToast('作成しました');
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  sendGACreateDocument(data) {
    const markIds: number[] = data['activity_records_marks_attributes'].map((item) => item['mark_id']);

    const params = {
      category_name: this.categories.find((category) => category.id === data.category_id)?.name,
      mark_name: this.marks
        .filter((mark) => markIds.find((id) => id === mark.id) != null)
        .map((mark) => mark.name)
        .join(','),
      template_title: this.templates.find((template) => template.id === data.template_id)?.title,
      album_id: data['album_id'],
    };
    this.analytics4Service.sendEvent('活動記録', '作成画面', '作成を完了する', params);
  }

  updateDocument(data) {
    this.documentService.updateDocument(data).subscribe(
      (response) => {
        if (!response['error_code']) {
          this.sendGAUpdateDocument();
          this.isSubmitted = true;
          this.routerService.resetDataConfirm();
          this.contentSavingService.removeTemporaryData();
          this.routerService.goBackSpecific(`/activity-records/student/${response['activity_record_id']}`);
        }
      },
      (error) => {
        this.errMsg = <any>error;
        this.isSubmitting = false;
      },
    );
  }

  sendGAUpdateDocument() {
    this.analytics4Service.sendEvent('活動記録', '編集画面', '編集を完了する');
  }

  showModalActivities() {
    this.showListActivityModal = true;
    const label = this.document.isExistedDocument() ? '活動記録編集' : '自主作成';

    const location = this.document.isExistedDocument() ? '編集画面' : '作成画面';
    this.analytics4Service.sendEvent('活動記録', location, '過去の活動記録を見る');
  }

  onUploadFilesStatus(status) {
    this.uploadFilesStatus = status;
    if (status === 'completed') {
      const uploadedFiles = this.fileUploader.sendUploadedFiles();
      for (const file of uploadedFiles) {
        this.form.value.upload_files_attributes.push(file);
      }
      this.saveDocument();
    }
  }

  saveDocument() {
    this.isSubmitting = true;
    this.buildMarkParams();
    if (this.document.isExistedDocument()) {
      this.updateDocument(this.form.value);
    } else {
      this.createDocument(this.form.value);
    }
  }

  buildMarkParams() {
    this.form.value.activity_records_marks_attributes = [];

    const selectedIds = this.marksSelector.selectedMarks.filter((mark) => !!mark.checked).map((mark) => mark.id);

    // In case when create a new document
    if (!this.document.isExistedDocument()) {
      selectedIds.forEach((newId) => this.form.value.activity_records_marks_attributes.push({ mark_id: newId }));
      return;
    }

    // In case when update an existed document
    const existedIds = this.document['activity_records_marks'].map((item) => item.mark_id);

    _.difference(existedIds, selectedIds).forEach((deleteId) => {
      const deletedMark = this.document['activity_records_marks'].find((item) => item.mark_id === deleteId);
      this.form.value.activity_records_marks_attributes.push({ ...deletedMark, ...{ _destroy: true } });
    });

    const newIds = _.difference(selectedIds, existedIds);
    newIds.forEach((newId) => this.form.value.activity_records_marks_attributes.push({ mark_id: newId }));
  }

  onChangeTemplate() {
    if (!this.templates) {
      return;
    }
    const newTemplateId = this.form.controls.template_id.value;
    if (newTemplateId === null) {
      this.form.controls['content'].setValue('');
    } else {
      const template = this.templates.find((_template) => _template.id === newTemplateId);
      if (!!template) {
        this.form.controls['content'].setValue(template.content);
      }
    }
  }

  onChangeMark() {
    this.routerService.registerBackConfirm(this.dataConfirm);
  }

  onDeleteDocument() {
    this.confirmDialogService
      .showConfirm({
        title: '削除確認',
        content: 'この内容は削除され、見ることができなくなります。本当に削除しますか？',
        acceptButton: '削除する',
        cancelButton: '　キャンセル',
        isDanger: true,
      })
      .subscribe((answer) => {
        if (answer) {
          this.documentService.deleteDocument(this.document.id).subscribe(
            (response) => {
              this.contentSavingService.removeTemporaryData();
              this.isSubmitted = true;
              this.backToMySpace();
              this.analytics4Service.sendEvent('活動記録', '削除確認モーダル', '削除する');
            },
            (error) => (this.errMsg = <any>error),
          );
        }
      });
  }

  dataLoaded(): boolean {
    return this.documentLoaded && this.templatesLoaded && this.categoriesLoaded && this.marksLoaded;
  }

  get pageTitle(): string {
    return this.document.isExistedDocument() ? '活動記録編集' : '活動記録作成';
  }

  get submitButtonText(): string {
    return this.document.isExistedDocument() ? '編集を完了する' : '作成を完了する';
  }

  get isUnselectTemplate(): boolean {
    return this.form.get('template_id').value === null;
  }

  onSelectFile($event) {
    if ($event) {
      this.routerService.registerBackConfirm(this.dataConfirm);
    }
  }

  deleteDocumentFromAlbum(albums_activity_record) {
    const deletedAlbum = this.document['albums_activity_records'].find((item) => item.id === albums_activity_record.id);
    const index: number = this.document['albums_activity_records'].indexOf(albums_activity_record);
    this.form.value.albums_activity_records_attributes.push({ ...deletedAlbum, ...{ _destroy: true } });
    if (index !== -1) {
      this.document['albums_activity_records'].splice(index, 1);
    }
  }

  private backToMySpace() {
    this.router.navigateByUrl('/my-space');
  }

  eventSelectActivityModal(value: boolean) {
    this.showListActivityModal = value;
  }

  eventForBackAction(action: string) {
    if (action === 'close-modal') {
      this.showListActivityModal = false;
    }
    this.showActivityDetailModal = false;
  }

  eventShowActivity(activityRecord: ActivityRecord) {
    if (activityRecord.record_type !== 'ManaVision') {
      this.activityId = activityRecord.id;
      this.showActivityDetailModal = true;
    } else {
      this.currentManaVision = activityRecord;
    }
  }

  hideManaVisionModal() {
    this.currentManaVision = null;
  }
}
