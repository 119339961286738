<div class="form-group">
  <div class="row">
    <div class="col-md-3 col-xs-12" [class.align-center]="centeredLabel">
      <p class="label-input">マーク</p>
    </div>
    <div class="col-md-9 col-xs-12">
      <label class="spen-mod-checkbox mark-select" *ngFor="let mark of selectedMarks">
        <input class="checkbox" type="checkbox" value="{{ mark.id }}" [checked]="mark.checked" (change)="checkBoxChange(mark)" />
        <i class="fa"></i>
        <img class="mark-icon" [src]="mark.icon" />
        <span class="text">
          {{ mark.name }}
        </span>
      </label>
    </div>
  </div>
</div>
