<div class="stu-space-detail-student" *ngIf="dataLoaded(); else loadingIcon">
  <div class="pre-page spen-mod-heading lv-1">
    <div class="heading-text pre-page-text">
      <div class="pre-first-block">
        <a [routerLink]="['/spaces/student']"><i class="fa fa-chevron-left"></i></a>
        <div class="pre-space-color" [style.background-color]="spaceDetail.color_code"></div>
        <div class="pre-text-info">
          <p>{{ spaceDetail.name }}</p>
          <p>
            <span>生徒{{ spaceDetail.users_count }}</span>
            <span>先生{{ spaceDetail.teachers_count }}</span>
            <img *ngIf="!spaceDetail.student_can_comment_other" src="/assets/images/icons/icon_comment_off.svg" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="filtered-category" *ngIf="filterService.filtered; else notFiltered">
    <div class="change-filter">
      <a class="spen-util-link" (click)="filterService.showFilter()"><i class="fa fa-search"></i>検索条件を変更</a>
    </div>
    <div class="category-detail">
      <span class="category-name">検索条件：{{ filterService.messageFilter }}</span>
      <a class="remove-filter spen-util-link" (click)="removeFilter()">解除</a>
    </div>
  </div>
  <ng-template #notFiltered>
    <div class="not-filtered-category">
      <a class="spen-util-link" (click)="filterService.showFilter()"><i class="fa fa-search"></i>検索</a>
    </div>
  </ng-template>

  <div class="activity-records-list">
    <div class="quantity" *ngIf="activityRecordsCount > 0">
      <p>{{ activityRecordsCount }}件</p>
    </div>
    <ng-container *ngIf="dataLoaded; else loadingIcon">
      <ng-container *ngIf="activityRecordsCount > 0; else emptyList">
        <div class="space-content">
          <ul class="space-flex-list">
            <li
              class="list-item"
              (click)="onClickActitityDetail(activityRecord)"
              *ngFor="
                let activityRecord of activityRecords
                  | paginate
                    : {
                        itemsPerPage: this.pages.per_page,
                        currentPage: this.pages.current_page,
                        totalItems: this.pages.total_count,
                      };
                first as isFirst
              "
              [class.is-first]="isFirst"
            >
              <app-student-public-activity-item [activityRecord]="activityRecord"></app-student-public-activity-item>
            </li>
          </ul>
          <div class="block-footer">
            <div class="pagination" *ngIf="showPagination">
              <app-pagination-controls (pageChange)="pageChanged($event)" directionLinks="false" [stylePaginate]="'small'">
              </app-pagination-controls>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #emptyList>
        <div class="null-content">
          <p class="text">活動記録はありません。</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingIcon>
      <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
    </ng-template>
  </div>
</div>
<ng-template #loadingIcon>
  <div class="spen-mod-flex-loading"><div class="loading-icon"></div></div>
</ng-template>
<app-student-filter-by-cross-review *ngIf="filterService.isShowingFilter" (submitFilter)="changeFilter($event)">
</app-student-filter-by-cross-review>
