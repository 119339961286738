import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssessmentModule } from '../assessment/assessment.module';
import { SharedDirectivesModule } from '@directives/shared-directives.module';
import { SharedModule } from '@components/shared.module';
import { SharedPipesModule } from '@pipes/shared-pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploaderModule } from '@components/file-uploader/file-uploader.module';

import { UploadFilesService } from '@services/upload-files/upload-files.service';
import { S3CredentialService } from '@services/s3-credential.service';
import { ContentSavingService } from '@services/content-saving.service';

import { ActivityRecordDetailStudentComponent } from './activity-record-detail/student/activity-record-detail-student.component';
import { PublishActivityModalComponent } from './activity-record-detail/student/publish-activity-modal/publish-activity-modal.component';
import { FormEditAnswerComponent } from './form-edit-answer/form-edit-answer.component';
import { FormEditQuestionnaireComponent } from './form-edit-questionnaire/form-edit-questionnaire.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ActivityRecordDetailStudentComponent,
    FormEditAnswerComponent,
    FormEditQuestionnaireComponent,
    PublishActivityModalComponent,
  ],
  exports: [ActivityRecordDetailStudentComponent, FormEditAnswerComponent, FormEditQuestionnaireComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SharedDirectivesModule,
    FileUploaderModule,
    AssessmentModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    SharedPipesModule,
  ],
  providers: [UploadFilesService, S3CredentialService, ContentSavingService],
})
export class ActivityRecordStudentModule {}
